import "./exchangesInfo.css";
interface Iimages {
  extension?: any;
  image: any;
  fullWidth?: boolean;
}
export default function ImagesApi(props: Iimages) {
  const { image, fullWidth } = props;
  return <div>{image && <img loading="lazy" className={fullWidth ? "fullWidth-image" : "image-api"} src={image} alt="" />}</div>;
}
