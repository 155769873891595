import redCircle from "../../images/redCircle.svg";
import secondCircle from "../../images/secondCircle.svg";
import grayCircle from "../../images/GrayCircle.svg";
import greenCircle from "../../images/greenCircle.svg";
import purpleCircle from "../../images/purpleCircle.svg";
import yellowCircle from "../../images/yellowCircle.svg";
import blueCircle from "../../images/blueCircle.svg";
import orangeCircle from "../../images/orangeCircle.svg";
import Star from "../../images/Star.png";

import React, { useRef, useState, useEffect } from "react";
import debounce from "lodash.debounce";
import {
  Item,
  Menu,
  Label,
  Field,
  Dropdown,
  Autocomplete,
  Select,
} from "@zendeskgarden/react-dropdowns";
import { Row, Col } from "@zendeskgarden/react-grid";

import "../../constants/global.css";
import { t } from "i18next";
interface IDropdownProps {
  options: string[];
  selectedItem: string;
  onSelect: any;
  width?: string;
  disabled?: boolean;
  backgroud?: string;
}

const DropDownCircles = (props: IDropdownProps) => {
  // const [selectedItem, setSelectedItem] = useState(props.options[0]);
  const [inputValue, setInputValue] = useState("");
  const [matchingOptions, setMatchingOptions] = useState(props.options);

  /**
   * Debounce filtering
   */
  const filterMatchingOptionsRef = useRef(
    debounce((value: string) => {
      const matchedOptions = props.options.filter(
        (option) =>
          option.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1
      );

      setMatchingOptions(matchedOptions);
    }, 300)
  );

  useEffect(() => {
    filterMatchingOptionsRef.current(inputValue);
  }, [inputValue]);

  var filteredDropdown = [""];
  if (props.options) {
    filteredDropdown = props.options.filter((option) => {
      return (
        option.trim().toLowerCase().indexOf(inputValue.trim().toLowerCase()) !==
        -1
      );
    });
  }

  return (
    <Row style={{ justifyContent: "left", width: "100%" }}>
      <Col sm={12}>
        <Dropdown
          inputValue={inputValue}
          selectedItem={props.selectedItem}
          onSelect={props.onSelect}
          onInputValueChange={(value: string) => setInputValue(value)}
          downshiftProps={{ defaultHighlightedIndex: 0 }}
        >

          <Field>
            <Select
              disabled={props.disabled}
              className="dropdownCircle"
              style={
                props.selectedItem === `${t("HOME.MENU.EXCHANGES-REFUSED")}`
                  ? {
                      backgroundColor: "var(--color-refused)",
                      width: `${props.width}`,
                    }
                  : props.selectedItem === `${t("HOME.MENU.EXCHANGES-DRAFT")}`
                  ? {
                      backgroundColor: "var(--color-draft)",
                      width: `${props.width}`,
                    }
                  : props.selectedItem === `${t("HOME.MENU.EXCHANGES-ABANDONNED")}`
                  ? {
                      backgroundColor: "var(--color-abandonned)",
                      width: `${props.width}`,
                    }
                  : props.selectedItem === `${t("HOME.MENU.EXCHANGES-SUBMITTED")}`
                  ? {
                      backgroundColor: "var(--color-submitted)",
                      width: `${props.width}`,
                    }
                  : props.selectedItem === `${t("HOME.MENU.EXCHANGES-EXCHANGED")}`
                  ? {
                      backgroundColor: "var(--color-exchanged)",
                      width: `${props.width}`,
                    }
                  : props.selectedItem === `${t("HOME.MENU.EXCHANGES-ACCEPTED")}`
                  ? {
                      backgroundColor: "var(--color-accepted)",
                      width: `${props.width}`,
                    }
                  : props.selectedItem === `${t("HOME.MENU.EXCHANGES-AVAILABLE")}`
                  ? {
                      backgroundColor: "var(--color-available)",
                      width: `${props.width}`,
                    }
                  : props.selectedItem === `${t("HOME.MENU.EXCHANGES-RECYCLED")}`
                  ? {
                      backgroundColor: "var(--color-recycled)",
                      width: `${props.width}`,
                    }
                  : props.selectedItem === `${t("HOME.MENU.EXCHANGES-ANALYSIS")}`
                  ? {
                      backgroundColor: "var(--color-analysis)",
                      width: `${props.width}`,
                    }
                  : {
                      backgroundColor: "var(--color-white)",
                      width: `${props.width}`,
                    }
              }
            >
              {props.selectedItem === `${t("HOME.MENU.EXCHANGES-REFUSED")}` ? (
                <img
                  alt=""
                  src={redCircle}
                  style={{ paddingRight: "5px" }}
                ></img>
              ) : props.selectedItem === `${t("HOME.MENU.EXCHANGES-DRAFT")}` ? (
                <img
                  alt=""
                  src={grayCircle}
                  style={{ paddingRight: "5px" }}
                ></img>
              ) : props.selectedItem === `${t("HOME.MENU.EXCHANGES-ABANDONNED")}` ? (
                <img
                  alt=""
                  src={secondCircle}
                  style={{ paddingRight: "5px" }}
                ></img>
              ) : props.selectedItem === `${t("HOME.MENU.EXCHANGES-SUBMITTED")}` ? (
                <img
                  alt=""
                  src={purpleCircle}
                  style={{ paddingRight: "5px" }}
                ></img>
              ) : props.selectedItem === `${t("HOME.MENU.EXCHANGES-EXCHANGED")}` ? (
                <img
                  alt=""
                  src={greenCircle}
                  style={{ paddingRight: "5px" }}
                ></img>
              ) : props.selectedItem === `${t("HOME.MENU.EXCHANGES-ACCEPTED")}` ? (
                <img
                  alt=""
                  src={yellowCircle}
                  style={{ paddingRight: "5px" }}
                ></img>
              ) : props.selectedItem === `${t("HOME.MENU.EXCHANGES-AVAILABLE")}` ? (
                <img
                  alt=""
                  src={blueCircle}
                  style={{ paddingRight: "5px" }}
                ></img>
              ) : props.selectedItem === `${t("HOME.MENU.EXCHANGES-RECYCLED")}` ? (
                <img
                  alt=""
                  src={redCircle}
                  style={{ paddingRight: "5px" }}
                ></img>
              ) : props.selectedItem === `${t("HOME.MENU.EXCHANGES-ANALYSIS")}` ? (
                <img
                  alt=""
                  src={orangeCircle}
                  style={{ paddingRight: "5px" }}
                ></img>
              ) : (
                ""
              )}
              {props.selectedItem}
            </Select>
          </Field>
          <Menu
            style={{
              color: "var(--color-input-text)",
              borderRadius: "2px",
              fontFamily: "Arial",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "16px",
            }}
          >
            {filteredDropdown.length ? (
              filteredDropdown.map((option) => (
                <Item
                  key={option}
                  value={option}
                  style={
                    option === `${t("HOME.MENU.EXCHANGES-REFUSED")}`
                      ? { backgroundColor: "rgb(244, 204, 204)" }
                      : option === `${t("HOME.MENU.EXCHANGES-DRAFT")}`
                      ? { backgroundColor: "var(--color-draft)" }
                      : option === `${t("HOME.MENU.EXCHANGES-ABANDONNED")}`
                      ? { backgroundColor: "var(--color-abandonned)" }
                      : option === `${t("HOME.MENU.EXCHANGES-SUBMITTED")}`
                      ? { backgroundColor: "var(--color-submitted)" }
                      : option === `${t("HOME.MENU.EXCHANGES-EXCHANGED")}`
                      ? { backgroundColor: "var(--color-exchanged)" }
                      : option === `${t("HOME.MENU.EXCHANGES-ACCEPTED")}`
                      ? { backgroundColor: "var(--color-accepted)" }
                      : option === `${t("HOME.MENU.EXCHANGES-AVAILABLE")}`
                      ? { backgroundColor: "var(--color-available)" }
                      : option === `${t("HOME.MENU.EXCHANGES-RECYCLED")}`
                      ? { backgroundColor: "var(--color-recycled)" }
                      : option === `${t("HOME.MENU.EXCHANGES-ANALYSIS")}`
                      ? { backgroundColor: "var(--color-analysis)" }
                      : { backgroundColor: "var(--color-white)" }
                  }
                >
                  {option === `${t("HOME.MENU.EXCHANGES-REFUSED")}` ? (
                    <img
                      alt=""
                      src={redCircle}
                      style={{ paddingRight: "5px" }}
                    ></img>
                  ) : option === `${t("HOME.MENU.EXCHANGES-DRAFT")}` ? (
                    <img
                      alt=""
                      src={grayCircle}
                      style={{ paddingRight: "5px" }}
                    ></img>
                  ) : option === `${t("HOME.MENU.EXCHANGES-ABANDONNED")}` ? (
                    <img
                      alt=""
                      src={secondCircle}
                      style={{ paddingRight: "5px" }}
                    ></img>
                  ) : option === `${t("HOME.MENU.EXCHANGES-SUBMITTED")}` ? (
                    <img
                      alt=""
                      src={purpleCircle}
                      style={{ paddingRight: "5px" }}
                    ></img>
                  ) : option === `${t("HOME.MENU.EXCHANGES-EXCHANGED")}` ? (
                    <img
                      alt=""
                      src={greenCircle}
                      style={{ paddingRight: "5px" }}
                    ></img>
                  ) : option === `${t("HOME.MENU.EXCHANGES-ACCEPTED")}` ? (
                    <img
                      alt=""
                      src={yellowCircle}
                      style={{ paddingRight: "5px" }}
                    ></img>
                  ) : option === `${t("HOME.MENU.EXCHANGES-AVAILABLE")}` ? (
                    <img
                      alt=""
                      src={blueCircle}
                      style={{ paddingRight: "5px" }}
                    ></img>
                  ) : option === `${t("HOME.MENU.EXCHANGES-RECYCLED")}` ? (
                    <img
                      alt=""
                      src={redCircle}
                      style={{ paddingRight: "5px" }}
                    ></img>
                  ) : option === `${t("HOME.MENU.EXCHANGES-ANALYSIS")}` ? (
                    <img
                      alt=""
                      src={orangeCircle}
                      style={{ paddingRight: "5px" }}
                    ></img>
                  ) : (
                    ""
                  )}
                  <span>{option}</span>
                </Item>
              ))
            ) : (
              <Item disabled>No matches found</Item>
            )}
          </Menu>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default DropDownCircles;
