import { faHotel, faThLarge, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useContext } from "react";
import ValidationAlert from "../atoms/ValidationAlert";
import { Breadcrumb, Button, ButtonGroup, Card, Container, Dropdown, ListGroup } from "react-bootstrap";
import InputClear from "../atoms/InputClear";
import TextEditor from "../organisms/TextEditor";
import LateralContainer from "../organisms/LateralContainer";

import { CreateGroups } from "../../api/services/group_services";
import { Link, useHistory } from "react-router-dom";
import { ApplicationContext } from "../../context/ApplicationContext";

import "../../constants/responsive.css";
import { useTranslation } from "react-i18next";
import usePictures from "../../hooks/usePictures";
import FileAttachement from "../atoms/FileAttachement/FileAttachement";
import FullLoading from "../atoms/FullLoading";
import useToastMui from "../../hooks/Toasts/useToastMui";
import { useMediaQuery } from "@mui/material";
export default function CreateGroup(props: any) {
  const { setGroupCode, setGroupId, setGroupName, openCreateGroup, setOpenCreateGroup } = useContext(ApplicationContext);
  const { handleCreateToast } = useToastMui();
  let history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  //Info
  const [inputCode, setInputCode] = useState<string>("");
  const [inputName, setInputName] = useState<string>("");
  const [inputDescription, setInputDescription] = useState<string>("");
  const logo = usePictures();
  //Alerts
  const [warningAlertStatus, setWarningAlertStatus] = useState<boolean>(false);

  const matches = useMediaQuery("(max-width:500px)");

  //LateralContainer
  const [showLateralContainer, setShowLateralContainer] = useState(false);
  const lateralContainer = (content: any) => {
    return (
      <LateralContainer
        show={showLateralContainer}
        toggleShow={() => setShowLateralContainer(!showLateralContainer)}
        content={content}
      ></LateralContainer>
    );
  };

  //! ------------------------------API CALLS--------------------------------//

  const createGroup = async (close: boolean) => {
    setLoading(true);
    if (inputName && inputCode) {
      try {
        const response = CreateGroups(
          inputName,
          inputCode,
          logo.picture ? logo.picture : { file_content: null, extension: null },
          inputDescription
        );
        const data = (await response).data;
        handleCreateToast(t("ALERT-GROUPS-SUCCESS-CREATE"), "success");
        if (!close) {
          history.push(`/group-info/${data?.result.id}`);
        } else {
          if (document.referrer) {
            history.go(-1);
          } else {
            history.push("/");
          }
        }
        return data;
      } catch (error: any) {
        console.log("error create group");
        console.log(error);
        if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(t("ALERT-GROUPS-ERROR-CREATE"), "error");
      } finally {
        console.log("end");
        setLoading(false);
      }
    } else {
      alertWarning();
      setLoading(false);
    }
  };

  //Calling api from info page (product info)
  //Don't rediect
  const handleCreated = async () => {
    setLoading(true);
    if (inputName && inputCode) {
      try {
        const response = CreateGroups(
          inputName,
          inputCode,
          logo.picture ? logo.picture : { file_content: null, extension: null },
          inputDescription
        );
        const data = (await response).data;
        setGroupId(data.result.id);
        setGroupCode(data.result.code);
        setGroupName(data.result.name);
        setOpenCreateGroup(!openCreateGroup);
        handleCreateToast(t("ALERT-GROUPS-SUCCESS-CREATE"), "success");
        return data;
      } catch (error: any) {
        console.log("error create group");
        console.log(error);
        if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(t("ALERT-GROUPS-ERROR-CREATE"), "error");
      } finally {
        console.log("end");
        setLoading(false);
      }
    } else {
      alertWarning();
      setLoading(false);
    }
  };

  //! --------------------------END API CALLS--------------------------------//

  //? ------------------------------ALERTS--------------------------------//

  const alertWarning = () => {
    setWarningAlertStatus(true);
  };

  const RenderWarnings = () => {
    return (
      <div>
        <ValidationAlert message={t("ALERT-GLOBAL-FILL-FIELDS")} variant="warning" />
      </div>
    );
  };

  //? ------------------------------END ALERTS--------------------------------//

  return (
    <div>
      <Breadcrumb>
        <Link to="/group">
          <Breadcrumb.Item href="/product">{t("TABLE.GROUP.TITLE.HEADER-LINK")}</Breadcrumb.Item>
        </Link>
        <FontAwesomeIcon icon={faPlay} style={{ width: "10px", margin: "5px 15px" }} />
        <Breadcrumb.Item href="#">{t("CREATE.GROUP.HEADER-SECOND-LINK")}</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header>
          {/* moble */}
          {matches ? (
            <div className="flex-warranty flex-between">
              <div className="flex-stl flex-center">
                <FontAwesomeIcon
                  icon={faHotel}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />

                <h4 className="h4-stl ">
                  <strong>{t("CREATE.GROUP.TITLE-HEADER")}</strong>
                </h4>
              </div>
              <div className="flex-stl flex-center">
                {!props.Created ? (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="primary"
                      style={{
                        marginRight: "0.5rem",
                        // height: "40px",
                      }}
                    >
                      {t("CREATE.ALL.BUTTON-SAVE")}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => createGroup(false)}>{t("CREATE.ALL.BUTTON-SAVE")}</Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to="/group"
                        onClick={() => {
                          createGroup(true);
                          handleCreated();
                        }}
                      >
                        {t("CREATE.ALL.BUTTON-SAVE-CLOSE")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Button
                    variant="outline-secondary"
                    style={{
                      minWidth: "150px",
                      height: "40px",
                    }}
                    onClick={() => handleCreated()}
                  >
                    {t("CREATE.ALL.BUTTON-SAVE-CLOSE")}
                  </Button>
                )}

                {!props.Created ? (
                  <Button
                    variant="outline-secondary"
                    onClick={() => {
                      if (document.referrer) {
                        history.go(-1);
                      } else {
                        history.push("/");
                      }
                    }}
                  >
                    {t("CREATE.ALL.BUTTON-CLOSE")}
                  </Button>
                ) : (
                  <Button onClick={() => setOpenCreateGroup(!openCreateGroup)} variant="outline-secondary">
                    {t("CREATE.ALL.BUTTON-CLOSE")}
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <div className="flex-warranty flex-between">
              {/* pc */}
              <div className="container-warranty flex-start">
                <FontAwesomeIcon
                  icon={faHotel}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />

                <h4 className="h4-stl ">
                  <strong>{t("CREATE.GROUP.TITLE-HEADER")}</strong>
                </h4>
              </div>
              <div className="container-warranty flex-end wrap-warranty">
                {!matches && !props.Created && <Button onClick={() => createGroup(false)}>{t("CREATE.ALL.BUTTON-SAVE")}</Button>}

                {!props.Created ? (
                  <Button
                    variant="outline-secondary"
                    style={{
                      minWidth: "150px",
                      height: "40px",
                    }}
                    onClick={() => createGroup(true)}
                  >
                    {t("CREATE.ALL.BUTTON-SAVE-CLOSE")}
                  </Button>
                ) : (
                  <Button
                    variant="outline-secondary"
                    style={{
                      minWidth: "150px",
                      height: "40px",
                    }}
                    onClick={() => handleCreated()}
                  >
                    {t("CREATE.ALL.BUTTON-SAVE-CLOSE")}
                  </Button>
                )}

                {!props.Created ? (
                  <Button
                    variant="outline-secondary"
                    onClick={() => {
                      if (document.referrer) {
                        history.go(-1);
                      } else {
                        history.push("/");
                      }
                    }}
                  >
                    {t("CREATE.ALL.BUTTON-CLOSE")}
                  </Button>
                ) : (
                  <Button onClick={() => setOpenCreateGroup(!openCreateGroup)} variant="outline-secondary">
                    {t("CREATE.ALL.BUTTON-CLOSE")}
                  </Button>
                )}
              </div>
            </div>
          )}
        </Card.Header>
        <Card.Body>
          <div className="row">
            <div className="col-12 col-lg-8">
              <Card style={{}}>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <div className="row">
                      <div className="c1">
                        <h6 style={{ margin: "28px 0" }}>
                          {t("ALL.GENERAL.TABLE.GROUP.TITLE-CODE")}
                          <span style={{ color: "var(--color-required)" }}>*</span>{" "}
                        </h6>
                        <InputClear
                          noSpaces
                          type="text"
                          name="Code"
                          placeholder=""
                          currentValue={inputCode}
                          handleOnInputValue={(data: string) => {
                            setInputCode(data);
                          }}
                        />
                        {!inputCode && warningAlertStatus && <RenderWarnings />}
                      </div>
                      <div className="c1">
                        <h6 style={{ margin: "28px 0" }}>
                          {t("ALL.GENERAL.TABLE.GROUP.TITLE-NAME")}
                          <span style={{ color: "var(--color-required)" }}>*</span>{" "}
                        </h6>
                        <InputClear
                          type="text"
                          name="Name"
                          placeholder=""
                          currentValue={inputName}
                          handleOnInputValue={(data: string) => {
                            setInputName(data);
                          }}
                        />
                        {!inputName && warningAlertStatus && <RenderWarnings />}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col">
                        <h6 style={{ margin: "28px 0" }}>{t("CREATE.GROUP.INPUT-DESCRIPTION")}</h6>
                        <TextEditor
                          height={"200px"}
                          handleChange={(content: any) => {
                            setInputDescription(content);
                          }}
                        ></TextEditor>
                      </div>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </div>
            <div className="col-12 col-lg-4">
              <Card style={{}}>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <div className="row">
                      <div className="col">
                        <h6 style={{ margin: "28px 0" }}>{t("ALL.GENERAL.TABLE.GROUP.TITLE-LOGO")}</h6>
                        <div className="row" style={{ overflow: "hidden" }}>
                          <FileAttachement fileName={logo.fileNameImage} handleChangeStatus={logo.handleChangeStatus} />

                          {/* <div className="col">
                            <FontAwesomeIcon icon={faEdit} style={{ marginRight: 10 }} />
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </div>
          </div>
          <FullLoading open={loading} />
        </Card.Body>
      </Card>
      {lateralContainer(<Container></Container>)}
    </div>
  );
}
