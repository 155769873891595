import React, { useRef, useState, useEffect } from "react";
import debounce from "lodash.debounce";
import { Item, Menu, Field, Dropdown, Autocomplete, Select } from "@zendeskgarden/react-dropdowns";
import { Row, Col } from "@zendeskgarden/react-grid";

import "../../constants/global.css";
interface IDropdownProps {
  options: string[];
  selectedItem: string;
  onSelect: any;
  width?: string;
  disabled?: boolean;
  backgroud?: string;
  isCompact?: boolean;
  style?: any;
  widthMenu?: string;
}

const DropDown = (props: IDropdownProps) => {
  // const [selectedItem, setSelectedItem] = useState(props.options[0]);
  const [inputValue, setInputValue] = useState("");
  const [matchingOptions, setMatchingOptions] = useState(props.options);
  const { style, widthMenu } = props;
  /**
   * Debounce filtering
   */
  const filterMatchingOptionsRef = useRef(
    debounce((value: string) => {
      const matchedOptions = props.options.filter((option) => option.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1);

      setMatchingOptions(matchedOptions);
    }, 300)
  );

  useEffect(() => {
    filterMatchingOptionsRef.current(inputValue);
  }, [inputValue]);

  var filteredDropdown = [""];
  if (props.options) {
    filteredDropdown = props.options.filter((option) => {
      return option.trim().toLowerCase().indexOf(inputValue.trim().toLowerCase()) !== -1;
    });
  }

  return (
    <Row style={{ justifyContent: "left", width: "100%" }}>
      <Col sm={12}>
        <Dropdown
          inputValue={inputValue}
          selectedItem={props.selectedItem}
          onSelect={props.onSelect}
          onInputValueChange={(value: string) => setInputValue(value)}
          downshiftProps={{ defaultHighlightedIndex: 0 }}
        >
          <Field>
            <Select
              disabled={props.disabled}
              isCompact={props.isCompact}
              style={
                style
                  ? style
                  : {
                      background: `${props.backgroud}`,
                      color: "var(--color-input-text)",
                      borderRadius: "2px",
                      textAlign: "left",
                      fontFamily: "Arial",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "14px",
                      lineHeight: "16px",
                      width: `${props.width}`,
                      maxWidth: "330px",
                      height: "30px",
                      border: "1px solid #ced4da",
                    }
              }
            >
              {props.selectedItem}
            </Select>
          </Field>
          <Menu
            style={
              widthMenu
                ? {
                    backgroundColor: "var(--color-white)",
                    color: "var(--color-input-text)",
                    borderRadius: "2px",
                    fontFamily: "Arial",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "14px",
                    lineHeight: "16px",
                    width: widthMenu ? widthMenu : "100%",
                  }
                : {
                    backgroundColor: "var(--color-white)",
                    color: "var(--color-input-text)",
                    borderRadius: "2px",
                    fontFamily: "Arial",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "14px",
                    lineHeight: "16px",
                  }
            }
          >
            {filteredDropdown.length ? (
              filteredDropdown.map((option) => (
                <Item key={option} value={option}>
                  <span>{option}</span>
                </Item>
              ))
            ) : (
              <Item disabled>No matches found</Item>
            )}
          </Menu>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default DropDown;
