import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxes, faUser } from "@fortawesome/free-solid-svg-icons";
import { IRowBrandFromAPI } from "../../../organisms/TableBrands/TableBrands";
import ImagesApi from "../../../pages/ExchangesInfo/ImagesApi";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IRowCustomersFromAPI } from "../../../organisms/CustomersTable/CustomersTable";
import { fromInfoToTranslatedBussiness } from "../../../../utilities/usetBussinessTransalte";
import { fromInfoToTranslatedCountry } from "../../../../utilities/countryTraslations";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
export default function CustomerCardResponsive({ render }: { render: any }) {
  const { t } = useTranslation();
  const history = useHistory();
  const matchesPhone = useMediaQuery("(max-width:500px)");
  const [expanded, setExpanded] = useState(false);
  return (
    <Accordion
      sx={{
        backgroundColor: "#f7f7f7",
        borderRadius: "0.3rem 0.3rem",
        border: "solid 0.0625rem #e0e0e0",
        margin: "8px",
      }}
      expanded={matchesPhone ? expanded : true}
    >
      <AccordionSummary
        expandIcon={matchesPhone ? <ExpandMoreIcon onClick={() => setExpanded((prev: boolean) => !prev)} /> : <></>}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div
          style={{ maxWidth: "230px", overflow: "hidden" }}
          className="flex-warranty flex-between"
          onClick={() => {
            history.push(`/customer-info/${render?.id}`);
          }}
        >
          <div className="container-warranty flex-start">
            <FontAwesomeIcon className="icon_size" icon={faUser} />

            <div>
              <h6 className="h6-stl" style={{ margin: "15px 5px" }}>
                <strong>
                  {render?.user_data?.first_name} {render?.user_data?.last_name}
                </strong>
              </h6>
            </div>
          </div>
          <div className="container-warranty flex-end wrap-warranty"></div>
        </div>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: "#fff",
          fontSize: "12px",
        }}
      >
        <div>
          <ul>
            <li>
              <h6 className="font-response">
                <strong>{t("SORT.CUSTOMER.INPUT-USERNAME")} </strong>
                {render?.user_data?.username}
              </h6>
            </li>
            <li>
              <h6 className="font-response">
                <strong>{t("SORT.CUSTOMER.INPUT-FIRST-NAME")} </strong>
                {render?.user_data?.first_name}
              </h6>
            </li>
            <li>
              <h6 className="font-response">
                <strong>{t("SORT.CUSTOMER.INPUT-LAST-NAME")} </strong>
                {render?.user_data?.last_name}
              </h6>
            </li>
            <li>
              <h6 className="font-response">
                <strong>{t("SORT.CUSTOMER.INPUT-TYPE")} </strong>
                {render?.type}
              </h6>
            </li>
            <li>
              <h6 className="font-response">
                <strong>{t("SORT.CUSTOMER.INPUT-BUSINESS")} </strong>
                {fromInfoToTranslatedBussiness(render?.business)}
              </h6>
            </li>
            <li>
              <h6 className="font-response">
                <strong>{t("SORT.CUSTOMER.INPUT-ZIPCODE")} </strong>
                {render?.userZipCode}
              </h6>
            </li>
            <li>
              <h6 className="font-response">
                <strong>{t("SORT.CUSTOMER.INPUT-CITY")} </strong>
                {render?.user_data?.address?.city}
              </h6>
            </li>
            <li>
              <h6 className="font-response">
                <strong>{t("SORT.CUSTOMER.INPUT-COUNTRY")} </strong>
                {fromInfoToTranslatedCountry(render?.user_data?.address?.country)}
              </h6>
            </li>
            <li>
              <h6 className="font-response">
                <strong>{t("SORT.CUSTOMER.INPUT-EMAIL")} </strong>
                {render?.user_data?.email}
              </h6>
            </li>
            <li>
              <h6 className="font-response">
                <strong>{t("SORT.CUSTOMER.INPUT-MOBILE-PHONE")} </strong>
                {render?.user_data?.company_phone_number}
              </h6>
            </li>
          </ul>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
