import { t } from "i18next";

 

export const fromTypeProductsTranslatedToKey = (country: string) => {
  if (country === `${t("TABLE.PRODUCTS-STATS.TITLE-OPENINGWRENCH")}`) return "Fixed or variable opening wrench";
  if (country === `${t("TABLE.PRODUCTS-STATS.TITLE-PAWLS")}`) return "Pawls";
  if (country === `${t("TABLE.PRODUCTS-STATS.TITLE-SOCKETS")}`) return "Sockets";
  if (country === `${t("TABLE.PRODUCTS-STATS.TITLE-SCREWDRIVERS")}`) return "Screwdrivers";
  if (country === `${t("TABLE.PRODUCTS-STATS.TITLE-MALEWRENCHS")}`) return "Male wrenchs";
  if (country === `${t("TABLE.PRODUCTS-STATS.TITLE-PLIERS")}`) return "Pliers / Vise pliers";
  if (country === `${t("TABLE.PRODUCTS-STATS.TITLE-TRACING")}`) return "Measuring / Tracing";
  if (country === `${t("TABLE.PRODUCTS-STATS.TITLE-HAMMERS")}`) return "Hammers / Strike";
  if (country === `${t("TABLE.PRODUCTS-STATS.TITLE-SAWING")}`) return "Sawing / Drilling / Cutting";
  if (country === `${t("TABLE.PRODUCTS-STATS.TITLE-EXTRACTION")}`) return "Extraction";
  if (country === `${t("TABLE.PRODUCTS-STATS.TITLE-OTHER")}`) return "Other";
  return "";
};

 
