import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../../constants/global.css";
import InputClear from "../../atoms/InputClear";

import DropDown from "../../atoms/DropDown";
import ValidationAlert from "../../atoms/ValidationAlert";
import { useTranslation } from "react-i18next";
import InputClearGuard from "../../atoms/InputClearGuard";
import { typeUserData } from "../../../constants/userTypes";
import DropDownGuard from "../../atoms/DropDownGuard";

export default function CustomerTableIdentification(props: any) {
  const { t } = useTranslation();
  const { warningStatus } = props;

  const RenderWarnings = () => {
    return (
      <div>
        <ValidationAlert message={t("ALERT-GLOBAL-FILL-FIELDS")} variant="warning" />
      </div>
    );
  };

  return (
    <div className="card m-lg-4 m-2">
      <div className="row my-4 px-lg-5 px-2">
        <div className="col-12 col-lg-12 sectionBoxTop py-2">
          <h4 className="h4-stl">
            {t("ALL.GENERAL.CUSTOMER-HEADER-TITLE-IDENTIFICATION")} <span style={{ color: "var(--color-required)" }}>*</span>
          </h4>
        </div>

        <div className="col-12 col-lg-4 my-4">
          <h4 className="h4-stl">
            <strong>{t("ALL.GENERAL.CUSTOMER.TITLE-USERNAME")}</strong>
          </h4>
          {props.info ? (
            <div style={{}}>
              <InputClear
                type="text"
                readOnly
                name=""
                placeholder=""
                style={{
                  width: "100%",
                  background: "var(--color-input-disable)",
                }}
                currentValue={props.username}
                handleOnInputValue={props.setUserName}
              />
            </div>
          ) : (
            <div style={{}}>
              <InputClearGuard
                type="text"
                name=""
                placeholder=""
                style={{ width: "100%" }}
                currentValue={props.username}
                handleOnInputValue={props.setUserName}
                roles={[`${typeUserData.admin}`]}
              />
            </div>
          )}
        </div>
        <div className="col-12 col-lg-4 my-4">
          <h4 className="h4-stl">
            <strong>
              {t("ALL.GENERAL.CUSTOMER.TITLE-FIRST-NAME")} <span style={{ color: "var(--color-required)" }}>*</span>
            </strong>
          </h4>
          <div style={{}}>
            <InputClearGuard
              type="text"
              name=""
              placeholder=""
              style={{ width: "100%" }}
              currentValue={props.firstname}
              handleOnInputValue={props.setFirstname}
              roles={[`${typeUserData.admin}`]}
            />
            {!props.firstname && warningStatus && <RenderWarnings />}
          </div>
        </div>
        <div className="col-12 col-lg-4 my-4">
          <h4 className="h4-stl">
            <strong>
              {t("ALL.GENERAL.CUSTOMER.TITLE-LAST-NAME")} <span style={{ color: "var(--color-required)" }}>*</span>
            </strong>
          </h4>
          <div style={{}}>
            <InputClearGuard
              type="text"
              name=""
              placeholder=""
              style={{ width: "100%" }}
              currentValue={props.lastname}
              handleOnInputValue={props.setLastname}
              roles={[`${typeUserData.admin}`]}
            />
            {!props.lastname && warningStatus && <RenderWarnings />}
          </div>
        </div>
      </div>
      {!props.info && (
        <div className="row mb-4 px-lg-5 px-2">
          <div className="col-12 col-lg-4 my-4">
            <h4 className="h4-stl">
              <strong>
                {t("CREATE.CUSTOMER.CARD-INPUT-PASSWORD")} <span style={{ color: "var(--color-required)" }}>*</span>
              </strong>
            </h4>
            <div style={{}}>
              <InputClearGuard
                type="password"
                name=""
                placeholder=""
                style={{ width: "100%" }}
                currentValue={props.password}
                handleOnInputValue={props.setPassword}
                roles={[`${typeUserData.admin}`]}
              />
              {!props.password && warningStatus && <RenderWarnings />}
            </div>
          </div>
          <div className="col-12 col-lg-4 my-4"></div>
          <div className="col-12 col-lg-4 my-4"></div>
        </div>
      )}
      <div className="row mb-lg-4 mb-2 px-lg-5 px-2">
        <div className="col-12 col-lg-4 my-4">
          <h4 className="h4-stl">
            <strong>
              {t("ALL.GENERAL.CUSTOMER.TITLE-TYPE")} <span style={{ color: "var(--color-required)" }}>*</span>
            </strong>
          </h4>
          <div style={{ width: "100%" }}>
            <DropDownGuard
              options={props.listTypeOptions}
              selectedItem={props.selectedType}
              onSelect={props.setSelectedType}
              width="100%"
              roles={[`${typeUserData.admin}`]}
            />
            {!props.selectedType && warningStatus && <RenderWarnings />}
          </div>
        </div>
        <div className="col-12 col-lg-4 my-4">
          <h4 className="h4-stl">
            <strong>
              {t("ALL.GENERAL.CUSTOMER.TITLE-BUSINESS")}{" "}
              {props.selectedType === `${t("TABLE-CUSTOMER-STATS-TITLE-INDIVIDUAL")}` ? (
                ""
              ) : (
                <span style={{ color: "var(--color-required)" }}>*</span>
              )}{" "}
            </strong>
          </h4>
          <div style={{ width: "100%" }}>
            <DropDownGuard
              options={props.listBusinessOptions}
              selectedItem={props.selectedTypeBusiness}
              onSelect={props.setSelectedTypeBusiness}
              width="100%"
              roles={[`${typeUserData.admin}`]}
            />
            {!props.selectedTypeBusiness && warningStatus && <RenderWarnings />}
          </div>
        </div>
        <div className="col-12 col-lg-4 my-4">
          <h4 className="h4-stl">
            <strong>
              {t("ALL.GENERAL.CUSTOMER.TITLE-SIRET-CODE")}{" "}
              {props.selectedType === `${t("TABLE-CUSTOMER-STATS-TITLE-INDIVIDUAL")}` ? (
                ""
              ) : (
                <span style={{ color: "var(--color-required)" }}>*</span>
              )}{" "}
            </strong>
          </h4>
          <div style={{}}>
            <InputClearGuard
              type="text"
              name=""
              placeholder=""
              style={{ width: "100%" }}
              currentValue={props.companyName}
              handleOnInputValue={props.setCompanyName}
              roles={[`${typeUserData.admin}`]}
            />
            {!props.companyName && warningStatus && <RenderWarnings />}
            {props.companyName && (
              <div>
                {props.setCompanyName.length ? (
                  <div>
                    {!props.isValidSiret && (
                      <div className="py-4">
                        <ValidationAlert message={t("ALERT-SIRET-CODE")} />
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
