import { Autocomplete, AutocompleteProps, useJsApiLoader } from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../config/settings.json";
interface IGoogleAutocomplete {
  onPlaceChanged?: () => void;
  onLoad?: (autocomplete: google.maps.places.Autocomplete) => void;
  initialValue?: string;
}
export default function GoogleAutocomplete({ onLoad, onPlaceChanged, initialValue }: IGoogleAutocomplete) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `${GOOGLE_API_KEY}`,
    libraries: ["geometry", "drawing", "places"],
  });

  return (
    <>
      {isLoaded && (
        <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
          <input
            defaultValue={initialValue}
            type="text"
            placeholder="Enter Location"
            style={{
              paddingLeft: "1em",
              width: "100%",
              height: "30px",
              borderBottom: "1px solid var(--color-input-border)",
              borderTop: "0",
              borderRight: "0",
              borderLeft: "0",
            }}
          />
        </Autocomplete>
      )}
    </>
  );
}
