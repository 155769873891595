import { useState, useContext } from "react";
import { IBrandsList, IBrandsListPage } from "../../api/interfaces/interfaz_brands";
import { getAllBrands, deleteManyBrands, getExportBrand, getExportBrandByID } from "../../api/services/brand_services";
import { createAddapterBrand } from "./adapters/brandAdapter";
import { ApplicationContext } from "../../context/ApplicationContext";
import useFetchAndLoad from "../useFetchAndLoad";
import useAsync from "../useAsync";
import useToastMui from "../Toasts/useToastMui";
import { useTranslation } from "react-i18next";
import { setInLocalStorage } from "../../helpers/local-storage-manager";

const useGetAllBrands = (inputCodeSearch: string, inputNameSearch: string) => {
  //Variables
  const { setBrandLength, setExportToggle, setBrandsListID, brandPage, setBrandPage, brandDeleteToggle } = useContext(ApplicationContext);
  const { t, i18n } = useTranslation();
  const { handleCreateToast } = useToastMui();
  // const [loading, setLoading] = useState(false);
  const [brandsArray, setBrandsArray] = useState<IBrandsListPage[]>([]);

  const [lastPage, setLastPage] = useState(0);
  const [deletedToogle, setDeletedToogle] = useState(false);
  const [searchToggle, setSearchToggle] = useState(false);
  const limit = 10;

  const [errorDeleteAlert, setErrorDeleteAlert] = useState<boolean[]>([false, false, false]);
  const [countCode, setCountCode] = useState(1);
  const [typeSort, setTypeSort] = useState("");

  const { loading, callEndpoint } = useFetchAndLoad();

  const getQuery = (code?: string, name?: string, sort?: string) => {
    let query = {};
    if (code) {
      query = { ...query, code: code };
    }
    if (name) {
      query = { ...query, name: name };
    }
    if (sort) {
      query = { ...query, sort: sort };
    }
    return query;
  };

  const loadBrands = async (code: string, name: string, nowPage: number) => {
    let query = getQuery(code, name, typeSort);

    query = {
      ...query,
      limit: limit,
      offset: (nowPage - 1) * limit,
    };
    const response = await callEndpoint(getAllBrands({ query }), () => handleCreateToast(t("ALERT-BRAND-ERROR-GET"), "error"));
    return response;
  };

  const adaptInfo = (data: IBrandsList) => {
    const array: IBrandsListPage[] = createAddapterBrand(data?.result?.items);
    setBrandsArray(array);
    setLastPage(data?.result?.total);
    setBrandLength(data?.result?.total);
  };

  useAsync(
    () => loadBrands(inputCodeSearch, inputNameSearch, brandPage),
    adaptInfo,
    () => {},
    [brandPage, searchToggle, deletedToogle, typeSort, brandDeleteToggle]
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setBrandPage(value);
  };

  const exportBrand = async (checkedType: string, brandsListID: string[], checkNeedToExport: boolean[]) => {
    setExportToggle(true);
    try {
      if (checkNeedToExport[0] === true) {
        await getExportBrand(checkedType, i18n.language === "fr" ? "Marque" : "Brand");
      }
      if (checkNeedToExport[1] === true) {
        let query = getQuery(inputCodeSearch, inputNameSearch);

        query = {
          ...query,
        };
        await getExportBrandByID(checkedType, brandsListID, i18n.language === "fr" ? "Marque" : "Brand", query);
      }
    } catch (error: any) {
      console.log("error export brand");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(t("ALERT-BRAND-ERROR-EXPORT"), "error");
    } finally {
      console.log("end");
      setExportToggle(false);
      // setOpenConfirm(false);
    }
  };

  const deleteManyBrand = async (BrandId: string[]) => {
    try {
      await callEndpoint(deleteManyBrands(BrandId), () => handleCreateToast(t("ALERT-BRAND-ERROR-DELETE"), "error"));
      handleCreateToast(t("ALERT-BRAND-SUCCESS-DELETE"), "success");
    } catch (error: any) {
      console.log("error deleted Brands");
      console.log(error);
    } finally {
      console.log("end");
      setBrandsListID([]);
      setBrandPage(1);
      setDeletedToogle(!deletedToogle);
    }
  };

  const onSearch = () => {
    setSearchToggle((value) => !value);
    setBrandPage(1);
  };

  // * ------------------------SORT----------------------------------//

  // sort

  const incrementCount = (key: string) => {
    setCountCode((prev) => prev + 1);

    if (countCode === 1) {
      setTypeSort(`asc(${key})`);
    }
    if (countCode === 2) {
      setTypeSort(`desc(${key})`);
      setCountCode(1);
    }
  };

  return {
    brandsArray,
    loading,
    exportBrand,
    deleteManyBrand,
    errorDeleteAlert,
    setErrorDeleteAlert,
    currentPage: brandPage,
    lastPage,
    loadBrands,
    handleChange,
    setCurrentPage: setBrandPage,
    limit,
    onSearch,
    typeSort,
    incrementCount,
  };
};

const service = {
  useGetAllBrands,
};
export default service;
export { useGetAllBrands };
