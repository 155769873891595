import InputClear from "../../atoms/InputClear";
import React, { useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../../constants/global.css";
import { faPhone, faEnvelope, faSearch, faPlus, faTimes, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ValidationAlert from "../../atoms/ValidationAlert";
import "./style.css";
import Tooltip from "@mui/material/Tooltip";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { Link } from "react-router-dom";
import "../../../constants/responsive.css";

// Translations
import { useTranslation } from "react-i18next";
import GuardNotAuthorized from "../../atoms/ItemPermission/GuardNotAuthorized";

export default function ExchangesDistributorTable(props: any) {
  // Translations
  const { t, i18n } = useTranslation();

  const { warningAlertStatus } = props;

  const RenderWarnings = () => {
    return (
      <div>
        <ValidationAlert message={t("ALERT-GLOBAL-FILL-FIELDS")} variant="warning" />
      </div>
    );
  };

  const {
    setDistributorCode,
    setDistributorName,
    setDistributorGroupCode,
    setDistributorGroupName,
    setDistributorClientAccountNumer,
    setDistributorEmail,
    setDistributorId,
    setDistributorPhone,
    setOpenDistributorTable,
    openDistributorTable,
  } = useContext(ApplicationContext);

  return (
    <div className="card m-2">
      <div>
        <div className="m-3 sectionBoxTop py-2">
          <h4 className="h4-stl">{t("CREATE.EXCHANGES.TITLE-DISTRIBUTOR")}</h4>
        </div>
        {!props.distributorGroupName && warningAlertStatus && <RenderWarnings />}
        <div className="flex-inputs-responsive-exchange-input px-3 pb-3">
          <div className="full-width mt-4">
            <h4 className="h4-stl">
              <strong>{t("ALL.GENERAL-EXCHANGE.TITLE-DISTRIBUTOR-CODE")} </strong>
            </h4>
            <div className="flex-inputs-responsive-info ">
              <div className="full-width">
                <InputClear
                  type="text"
                  name="distributorCode"
                  placeholder=""
                  readOnly
                  style={{
                    background: "var(--color-input-background-secondary)",
                  }}
                  currentValue={props.distributorCode}
                  handleOnInputValue={props.setDistributorCode}
                />
              </div>
              <GuardNotAuthorized>
                <div>
                  {props.Create || props.CreateSubmitted ? (
                    <div style={{ display: "flex" }}>
                      <Tooltip title="Select" placement="bottom">
                        <FontAwesomeIcon
                          className="pointer"
                          icon={faSearch}
                          onClick={props.onClickSearch}
                          style={{
                            width: "2Opx",
                            height: "20px",
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Create" placement="bottom">
                        <FontAwesomeIcon
                          className="pointer"
                          icon={faPlus}
                          onClick={props.onClickCreate}
                          style={{
                            marginLeft: "8px",
                            width: "2Opx",
                            height: "20px",
                          }}
                        />
                      </Tooltip>

                      <Tooltip title="Remove" placement="bottom">
                        <FontAwesomeIcon
                          className="pointer"
                          icon={faTimes}
                          onClick={() => {
                            setDistributorCode("");
                            setDistributorName("");
                            setDistributorGroupCode("");
                            setDistributorGroupName("");
                            setDistributorClientAccountNumer("");
                            setDistributorPhone("");
                            setDistributorEmail("");
                            setDistributorId("");
                          }}
                          style={{
                            marginLeft: "8px",
                            width: "2Opx",
                            height: "20px",
                          }}
                        />
                      </Tooltip>
                      {props.selectedStatus && (
                        <Tooltip title="Open" placement="bottom">
                          <Link to={`${props.openLink}`} style={{ color: "var(--color-main-text)" }}>
                            <FontAwesomeIcon
                              className="pointer"
                              icon={faShare}
                              style={{
                                marginLeft: "8px",
                                width: "2Opx",
                                height: "20px",
                              }}
                            />
                          </Link>
                        </Tooltip>
                      )}
                    </div>
                  ) : (
                    <Tooltip title="Open" placement="bottom">
                      <div>
                        <Link to={`${props.openLink}`} style={{ color: "var(--color-main-text)" }}>
                          <FontAwesomeIcon
                            className="pointer"
                            icon={faShare}
                            style={{
                              marginLeft: "8px",
                              width: "2Opx",
                              height: "20px",
                            }}
                          />
                        </Link>
                      </div>
                    </Tooltip>
                  )}
                </div>
              </GuardNotAuthorized>
            </div>
          </div>
          <div className="full-width mt-4">
            <h4 className="h4-stl">
              <strong>{t("CREATE.EXCHANGES.INPUT-CUSTOMER-DISTRIBUTOR-NAME")} </strong>
            </h4>
            <div style={{ display: "grid", width: "100%", alignItems: "center" }}>
              <InputClear
                type="text"
                name="distributorName"
                placeholder=""
                readOnly
                style={{ background: "var(--color-input-background-secondary)" }}
                currentValue={props.distributorName}
                handleOnInputValue={props.setDistributorName}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-inputs-responsive-exchange-input px-3">
        <div className="c1 mt-4">
          <h4 className="h4-stl">
            <strong>{t("ALL.GENERAL-EXCHANGE.TITLE-DISTRIBUTOR-GROUP-CODE")} </strong>
          </h4>
          <div style={{ display: "grid", width: "100%", alignItems: "center" }}>
            <InputClear
              type="text"
              name="distributorGroupCode"
              placeholder=""
              readOnly
              style={{ background: "var(--color-input-background-secondary)" }}
              currentValue={props.distributorGroupCode}
              handleOnInputValue={props.setDistributorGroupCode}
            />
          </div>
        </div>
        <div className="c1 mt-4">
          <h4 className="h4-stl">
            <strong>{t("CREATE.EXCHANGES.INPUT-CUSTOMER-DISTRIBUTOR-GROUP-NAME")} </strong>
          </h4>
          <div style={{ display: "grid", width: "100%", alignItems: "center" }}>
            <InputClear
              type="text"
              name="distributorGroupName"
              placeholder=""
              readOnly
              style={{ background: "var(--color-input-background-secondary)" }}
              currentValue={props.distributorGroupName}
              handleOnInputValue={props.setDistributorGroupName}
            />
          </div>
        </div>
      </div>
      <div className="flex-inputs-responsive-exchange-input px-3">
        <div className="full-width mt-4">
          <h4 className="h4-stl">
            <strong>{t("CREATE.EXCHANGES.INPUT-CUSTOMER-CLIENT-ACCOUNT-NUMBER")} </strong>
          </h4>
          <InputClear
            type="text"
            name="distributorClientAccountNumber"
            placeholder=""
            readOnly
            style={{ background: "var(--color-input-background-secondary)" }}
            currentValue={props.distributorClientAccountNumber}
            handleOnInputValue={props.setDistributorClientAccountNumber}
          />
        </div>
        <div className="full-width mt-4">
          <h4 className="h4-stl">
            <strong>{t("ALL.GENERAL.DISTRIBUTOR.TITLE-DELIVERY-ACCOUNT-NUMBER")} </strong>
          </h4>
          <InputClear
            type="text"
            name="responsibleEmail"
            placeholder=""
            readOnly
            style={{ background: "var(--color-input-background-secondary)" }}
            currentValue={props.deliveryAccountNumber}
            handleOnInputValue={props.setDeliveryAccountNumber}
          />
        </div>
      </div>
      <div className="flex-inputs-responsive-exchange-input px-3">
        <div className="full-width mt-4">
          <h4 className="h4-stl">
            <strong>{t("CREATE.EXCHANGES.INPUT-CUSTOMER-DISTRIBUTOR-PHONE")} </strong>
          </h4>
          <div className="flex-inputs-responsive-info">
            <div className="full-width">
              <InputClear
                type="text"
                name="distributorPhone"
                placeholder=""
                readOnly
                style={{ background: "var(--color-input-background-secondary)" }}
                currentValue={props.distributorPhone}
                handleOnInputValue={props.setDistributorPhone}
              />
            </div>
            <div>
              <FontAwesomeIcon
                icon={faPhone}
                style={{
                  width: "2Opx",
                  height: "20px",
                }}
              />
            </div>
          </div>
        </div>
        <div className="full-width mt-4">
          <h4 className="h4-stl">
            <strong>{t("CREATE.EXCHANGES.INPUT-CUSTOMER-DISTRIBUTOR-EMAIL")} </strong>
          </h4>
          <div className="flex-inputs-responsive-info">
            <div className="full-width">
              <InputClear
                type="text"
                name="distributorEmail"
                placeholder=""
                readOnly
                style={{ background: "var(--color-input-background-secondary)" }}
                currentValue={props.distributorEmail}
                handleOnInputValue={props.setDistributorEmail}
              />
            </div>
            <div>
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{
                  width: "2Opx",
                  height: "20px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-inputs-responsive mb-5 px-3 mt-4">
        <div className="full-width">
          <h4 className="h4-stl">
            <strong>{t("ALL.GENERAL.DISTRIBUTOR.TITLE-RESPONSIBLE-EMAIL")} </strong>
          </h4>
          <InputClear
            type="text"
            name="responsibleEmail"
            placeholder=""
            readOnly
            style={{ background: "var(--color-input-background-secondary)" }}
            currentValue={props.inputResponsibleEmail}
            handleOnInputValue={props.setInputResponsibleEmail}
          />
        </div>
      </div>
    </div>
  );
}
