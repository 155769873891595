import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxes, faCity } from "@fortawesome/free-solid-svg-icons";
import ImagesApi from "../../../pages/ExchangesInfo/ImagesApi";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { IRowFromAPI } from "../../../organisms/TableDistributorSet/TableDistributorSet";

export default function DistributorSetTableResponsive({
  render,
  createDistributorArrayName,
  isSelected,
  toSelect,
}: {
  render: IRowFromAPI[];
  createDistributorArrayName: Function;
  isSelected: Function;
  toSelect: boolean | undefined;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div>
      {render?.map((row: IRowFromAPI) => {
        const isItemSelected: boolean = isSelected(row.id);
        return (
          <Accordion
            sx={{
              backgroundColor: isItemSelected ? "#ffef8d" : "#f7f7f7",
              borderRadius: "0.3rem 0.3rem",
              border: "solid 0.0625rem #e0e0e0",
              margin: "8px",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <div
                style={{ maxWidth: "230px", overflow: "hidden" }}
                className="flex-warranty flex-between"
                onClick={
                  !toSelect
                    ? () => {
                        history.push(`/distributors-set-info/${row.id}`);
                      }
                    : () => {
                        createDistributorArrayName(row.id, row.name, row.groupData);
                      }
                }
              >
                <div className="container-warranty flex-start">
                  <FontAwesomeIcon className="icon_size" icon={faCity} />

                  <div>
                    <h6 className="h6-stl" style={{ margin: "15px 5px" }}>
                      <strong>{row.name}</strong>
                    </h6>
                  </div>
                </div>
                <div className="container-warranty flex-end wrap-warranty"></div>
              </div>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: "#fff",
                fontSize: "12px",
              }}
            >
              <div>
                <ul>
                  <li>
                    <h6 className="font-response">
                      <strong>{t("ALL.GENERAL.TABLE.DIST-SET.TITLE-CODE")}: </strong>
                      {row?.groupData?.code}
                    </h6>
                  </li>
                  <li>
                    <h6 className="font-response">
                      <strong>{t("ALL.GENERAL.TABLE.DIST-SET.TITLE-NAME")}: </strong>
                      {row.name}
                    </h6>
                  </li>

                  <li>
                    <h6 className="font-response">
                      <strong>{t("ALL.GENERAL.TABLE.DIST-SET.TITLE-ORIGIN")}: </strong>
                      {row.origin}
                    </h6>
                  </li>
                  <li>
                    <h6 className="font-response">
                      <strong>{t("ALL.GENERAL.TABLE.DIST-SET.TITLE-MEMBERS")}: </strong>
                      {row.logo}
                    </h6>
                  </li>
                </ul>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
