import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../../constants/global.css";
import InputClear from "../../atoms/InputClear";
import { faQuestionCircle, faSearch, faPlus, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import "./tableBrand.css";
import { OverlayTrigger, Popover } from "react-bootstrap";
import "../../../constants/responsive.css";
import { useTranslation } from "react-i18next";
import ValidationAlert from "../../atoms/ValidationAlert";
//Redux
import { useSelector } from "react-redux";
import { IRootState } from "../../../store/redux";
import { typeUserData } from "../../../constants/userTypes";

export default function ProductTableBrand(props: any) {
  //Translations
  const { t } = useTranslation();
  //Redux
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected } = profile;

  const { warningAlertStatus } = props;

  const RenderWarnings = () => {
    return (
      <div>
        <ValidationAlert message={t("ALERT-GLOBAL-FILL-FIELDS")} variant="warning" />
      </div>
    );
  };

  return (
    <div className="card mt-4 mx-2">
      <div className="row my-4 px-lg-5 px-2">
        <div className="col-12 col-lg-12 sectionBoxTop py-2">
          <h4 className="h4-stl">{t("ALL.GENERAL.PRODUCT-HEADER-TITLE-BRAND")}</h4>
        </div>
        <div className="flex-inputs-responsive">
          <div className="full-width my-4">
            <h4 className="h4-stl">
              <strong>
                {t("CREATE.PRODUCT.INPUT-BRAND-CODE")} <span style={{ color: "var(--color-required)" }}>*</span>
              </strong>
            </h4>
            <div className="flex-inputs-responsive-info">
              <div className="full-width">
                <InputClear
                  type="text"
                  readOnly={true}
                  name=""
                  placeholder=""
                  style={{
                    background: "var(--color-input-background-secondary)",
                  }}
                  currentValue={props.brandCode}
                  handleOnInputValue={props.setBrandCode}
                />
              </div>
              <div>
                {nameRolSelected === typeUserData.admin && (
                  <div style={{ display: "flex", cursor: "pointer" }}>
                    <OverlayTrigger
                      trigger="hover"
                      key={"bottom"}
                      placement={"bottom"}
                      overlay={
                        <Popover id={`popover-positioned-bottom`}>
                          <Popover.Header as="h3">{t("CREATE.PRODUCT.INPUT-BRAND-CODE")} </Popover.Header>
                          <Popover.Body>This is the code of the brand of the product</Popover.Body>
                        </Popover>
                      }
                    >
                      <FontAwesomeIcon
                        className="pointer"
                        icon={faQuestionCircle}
                        style={{
                          marginLeft: "8px",
                          width: "2Opx",
                          height: "20px",
                        }}
                      />
                    </OverlayTrigger>
                    <Tooltip title={`${t("HOVER.ALL.MENU-BARS-SELECT")}`} placement="bottom">
                      <FontAwesomeIcon
                        className="pointer"
                        icon={faSearch}
                        onClick={props.onClickSearch}
                        style={{
                          marginLeft: "8px",
                          width: "2Opx",
                          height: "20px",
                        }}
                      />
                    </Tooltip>

                    {props.Productinfo && (
                      <Tooltip title={`${t("HOVER.ALL.MENU-BARS-OPEN")}`} placement="bottom">
                        <Link to={`${props.openLink}`} style={{ color: "var(--color-main-text)" }}>
                          <FontAwesomeIcon
                            className="pointer"
                            icon={faShare}
                            style={{
                              marginLeft: "8px",
                              width: "2Opx",
                              height: "20px",
                            }}
                          />
                        </Link>
                      </Tooltip>
                    )}
                    <Tooltip title={`${t("HOVER.ALL.MENU-BARS-CREATE")}`} placement="bottom">
                      <FontAwesomeIcon
                        className="pointer"
                        icon={faPlus}
                        onClick={props.onClickCreate}
                        style={{
                          marginLeft: "8px",
                          width: "2Opx",
                          height: "20px",
                        }}
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
            {!props.brandCode && warningAlertStatus && <RenderWarnings />}
          </div>

          <div className="full-width my-4">
            <h4 className="h4-stl">
              <strong>
                {t("ALL.GENERAL.PRODUCT.TITLE-BRAND-NAME")} <span style={{ color: "var(--color-required)" }}>*</span>
              </strong>
            </h4>
            <div className="flex-inputs-responsive-info">
              <div className="full-width">
                <InputClear
                  type="text"
                  readOnly={true}
                  name=""
                  placeholder=""
                  style={{
                    background: "var(--color-input-background-secondary)",
                  }}
                  currentValue={props.brandName}
                  handleOnInputValue={props.setBrandName}
                />
              </div>

              <div>
                <OverlayTrigger
                  trigger="hover"
                  key={"bottom"}
                  placement={"bottom"}
                  overlay={
                    <Popover id={`popover-positioned-bottom`}>
                      <Popover.Header as="h3">{t("ALL.GENERAL.PRODUCT.TITLE-BRAND-NAME")}</Popover.Header>
                      <Popover.Body>This is the name of the brand of the product</Popover.Body>
                    </Popover>
                  }
                >
                  <div>
                    <FontAwesomeIcon
                      className="pointer"
                      icon={faQuestionCircle}
                      style={{ marginLeft: "8px", width: "2Opx", height: "20px" }}
                    />
                  </div>
                </OverlayTrigger>
              </div>
            </div>
            {!props.brandName && warningAlertStatus && <RenderWarnings />}
          </div>
        </div>
      </div>
    </div>
  );
}
