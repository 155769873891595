import React, { useContext } from "react";
import LateralContainer from "../LateralContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faEllipsisH, faChevronLeft, faSortAlphaDown, faThumbtack } from "@fortawesome/free-solid-svg-icons";
import InputClear from "../../atoms/InputClear";
import { Button } from "react-bootstrap";
import DropDown from "../../atoms/DropDown";
import { t } from "i18next";
import { IDistributorSearch } from "./interfaces";
import Tooltip from "@mui/material/Tooltip";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { useMediaQuery } from "@mui/material";
import "../../../constants/global.css";
import { useFilterContext } from "../../../context";
import useAutocomplete from "../../atoms/AutocopleteInput/useAutocomplete";
import { createAddapterDistributor } from "../../../hooks/Distributors/adapters/distributorAdapter";
import AutoCompleteInput from "../../atoms/AutocopleteInput/AutocompleteInput";

export default function DistributorSearch(props: IDistributorSearch) {
  const {
    inputGroupSearch,
    setInputGroupSearch,
    inputGroupCodeSearch,
    setInputGroupCodeSearch,
    inputGroupNameSearch,
    setInputGroupNameSearch,
    inputCodeSearchDistributor,
    setInputCodeSearchDistributor,
    inputClientAccountNumberSearch,
    setInputClientAccountNumberSearch,
    inputNameSearchDistributor,
    setInputNameSearchDistributor,
    inputVisibleSearch,
    setInputVisibleSearch,
    inputAddressSearch,
    setInputAddressSearch,
    inputEmailSearch,
    setInputEmailSearch,
    inputGeographicalCoordinatesSearch,
    setInputGeographicalCoordinatesSearch,
    inputMapURLSearch,
    setInputMapURLSearch,
    inputEvaluationSearch,
    setInputEvaluationSearch,
    setInputLookupSearch,
    showLateralContainerSearch,
    setShowLateralContainerSearch,
  } = useFilterContext();
  const { handleSearch, SetSearchWindowState, searchWindowState, listSort, dropDownWhySort, setDropDownWhySort, listCheck } = props;
  const { typeOfSearch, setTypeOfSearch } = useContext(ApplicationContext);

  const matches = useMediaQuery("(max-width:500px)");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleSearch();
    setShowLateralContainerSearch(!showLateralContainerSearch);
  };

  //**Filters */
  const groupCode = useAutocomplete(
    "/distributors/get-all?autocomplete=true&group_code=",
    handleSearch,
    inputGroupCodeSearch,
    setInputGroupCodeSearch,
    "group_code",
    createAddapterDistributor
  );
  const groupName = useAutocomplete(
    "/distributors/get-all?autocomplete=true&group_name=",
    handleSearch,
    inputGroupNameSearch,
    setInputGroupNameSearch,
    "group_name",
    createAddapterDistributor
  );

  const code = useAutocomplete(
    "/distributors/get-all?autocomplete=true&code=",
    handleSearch,
    inputCodeSearchDistributor,
    setInputCodeSearchDistributor,
    "code"
  );
  const name = useAutocomplete(
    "/distributors/get-all?autocomplete=true&name=",
    handleSearch,
    inputNameSearchDistributor,
    setInputNameSearchDistributor,
    "name"
  );
  const clientAccountNumber = useAutocomplete(
    "/distributors/get-all?autocomplete=true&client_account_number=",
    handleSearch,
    inputClientAccountNumberSearch,
    setInputClientAccountNumberSearch,
    "client_account_number"
  );

  const email = useAutocomplete(
    "/distributors/get-all?autocomplete=true&email=",
    handleSearch,
    inputEmailSearch,
    setInputEmailSearch,
    "email"
  );
  const address = useAutocomplete(
    "/distributors/get-all?autocomplete=true&address=",
    handleSearch,
    inputAddressSearch,
    setInputAddressSearch,
    "distributorAddress",
    createAddapterDistributor
  );
  const coordinates = useAutocomplete(
    "/distributors/get-all?autocomplete=true&geographicalCoordinates=",
    handleSearch,
    inputGeographicalCoordinatesSearch,
    setInputGeographicalCoordinatesSearch,
    "distributorCoordinates",
    createAddapterDistributor
  );
  const mapUrl = useAutocomplete(
    "/distributors/get-all?autocomplete=true&mapUrl=",
    handleSearch,
    inputMapURLSearch,
    setInputMapURLSearch,
    "distributorMapUrl",
    createAddapterDistributor
  );

  const evaluation = useAutocomplete(
    "/distributors/get-all?autocomplete=true&evaluation=",
    handleSearch,
    inputEvaluationSearch,
    setInputEvaluationSearch,
    "evaluation"
  );

  return (
    <LateralContainer
      show={typeOfSearch === 1 ? showLateralContainerSearch : false}
      toggleShow={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
      content={
        <form action="/" onSubmit={handleSubmit}>
          <div className="row sectionBoxTop">
            <div className="col-2 col-lg-2 pb-3" style={{ display: "flex" }}>
              <FontAwesomeIcon
                icon={faBuilding}
                style={{
                  marginLeft: "50%",
                  marginTop: "0",
                  width: "50px",
                  height: "50px",
                }}
              />
            </div>
            <div className="col-10 col-lg-10">
              <h4 className="h4-stl" style={{ margin: "15px 20px 15px" }}>
                <strong>{t("SEARCH.DISTRIBUTOR.TITLE-SEARCH-DISTRIBUTOR")}</strong>
              </h4>
            </div>
          </div>
          {searchWindowState === "filterList" ? (
            <div className="cssanimation sequence fadeInTop">
              {matches ? (
                <>
                  <div className="filterBox my-4">
                    <div style={{ borderBottom: "1px solid rgba(0,0,0,.125)" }}>
                      <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                        {t("SEARCH.DISTRIBUTOR.TITLE-IDENTIFICATION")}
                      </h4>
                    </div>

                    <div className="flex-stl flex-direction-column px-4 my-2">
                      <div>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.DISTRIBUTOR.TITLE-GROUP-CODE")}</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <AutoCompleteInput {...groupCode} freeSolo />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-direction-column px-4 my-4">
                      <div>
                        <h5 className="h5-stl Right">{t("SEARCH.DISTRIBUTOR.INPUT-GROUP-NAME")}</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <AutoCompleteInput {...groupName} freeSolo />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-direction-column px-4 my-4">
                      <div>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.DISTRIBUTOR.TITLE-CODE")}</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <AutoCompleteInput {...code} freeSolo />
                        </div>
                      </div>
                      {/* <div className="col-2">
                          <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-direction-column px-4 my-4">
                      <div>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.DISTRIBUTOR.TITLE-CLIENT-ACCOUNT-NUMBER")}</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <AutoCompleteInput {...clientAccountNumber} freeSolo />
                        </div>
                      </div>
                      {/* <div className="col-2">
                          <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-direction-column px-4 my-4">
                      <div>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.DISTRIBUTOR.TITLE-NAME")}</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <AutoCompleteInput {...name} freeSolo />
                        </div>
                      </div>
                      {/* <div className="col-2">
                          <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-direction-column px-4 my-4">
                      <div>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.DISTRIBUTOR.TITLE-VISIBLE")}?</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <DropDown
                            options={listCheck}
                            selectedItem={inputVisibleSearch}
                            onSelect={(item: string) => setInputVisibleSearch(item)}
                            width="100%"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex-stl flex-direction-column px-4 my-4">
                      <div>
                        <h5 className="h5-stl Right">{t("SEARCH.DISTRIBUTOR.INPUT-ADDRESS")}</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <AutoCompleteInput {...address} freeSolo />
                        </div>
                      </div>
                    </div>

                    <div className="flex-stl flex-direction-column px-4 my-4">
                      <div>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.DISTRIBUTOR.TITLE-EMAIL")}</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <AutoCompleteInput {...email} freeSolo />
                        </div>
                      </div>
                    </div>

                    <div className="flex-stl flex-direction-column px-4 my-4">
                      <div>
                        <h5 className="h5-stl Right">{t("CREATE.DISTRIBUTOR.INPUT-GEOGRAPHICAL-COORDINATES")}</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <AutoCompleteInput {...coordinates} freeSolo />
                        </div>
                      </div>
                    </div>

                    <div className="flex-stl flex-direction-column px-4 my-4">
                      <div>
                        <h5 className="h5-stl Right">{t("SEARCH.DISTRIBUTOR.INPUT-MAP-URL")}</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <AutoCompleteInput {...mapUrl} freeSolo />
                        </div>
                      </div>
                    </div>

                    <div className="flex-stl flex-direction-column px-4 my-4">
                      <div>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.DISTRIBUTOR.TITLE-EVALUATION")}</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <AutoCompleteInput {...evaluation} freeSolo />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray px-4" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Tooltip title={`${t("FILER-TO-COLUMNS")}`} placement="bottom">
                      <div style={{ width: "60px" }}>
                        <FontAwesomeIcon
                          className="grayBackgr"
                          style={{ width: "47px", height: "47px" }}
                          icon={faThumbtack}
                          onClick={() => {
                            setTypeOfSearch(0);
                          }}
                        />
                      </div>
                    </Tooltip>
                    {/* <FontAwesomeIcon
                      className="grayBackgr"
                      icon={faSortAlphaDown}
                      onClick={() => SetSearchWindowState("SortWindow")}
                      style={{ width: "47px", height: "47px" }}
                    /> */}
                    <Button
                      variant="primary"
                      // style={{ margin: "15px 30px" }}
                      onClick={() => {
                        handleSearch();
                        setShowLateralContainerSearch(!showLateralContainerSearch);
                      }}
                    >
                      {t("SEARCH.ALL.BUTTON-SEARCH")}
                    </Button>
                    <h4
                      className="h4-stl my-0 grayBackgr"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setInputGroupSearch("");
                        setInputGroupCodeSearch("");
                        setInputGroupNameSearch("");
                        setInputCodeSearchDistributor("");
                        setInputClientAccountNumberSearch("");
                        setInputNameSearchDistributor("");
                        setInputVisibleSearch("");
                        setInputAddressSearch("");
                        setInputEmailSearch("");
                        setInputGeographicalCoordinatesSearch("");
                        setInputMapURLSearch("");
                        setInputEvaluationSearch("");
                        setInputLookupSearch("");
                        handleSearch();
                      }}
                    >
                      {t("SEARCH.ALL.BUTTON-RESET")}
                    </h4>

                    <h4
                      className="h4-stl my-0 grayBackgr"
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                    >
                      {t("SEARCH.ALL.BUTTON-CLOSE")}
                    </h4>
                  </div>
                </>
              ) : (
                <>
                  <div className="filterBox my-4">
                    <div style={{ borderBottom: "1px solid rgba(0,0,0,.125)" }}>
                      <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                        {t("SEARCH.DISTRIBUTOR.TITLE-IDENTIFICATION")}
                      </h4>
                    </div>
                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl">{t("ALL.GENERAL.DISTRIBUTOR.TITLE-GROUP-CODE")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <AutoCompleteInput {...groupCode} freeSolo />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl Right">{t("SEARCH.DISTRIBUTOR.INPUT-GROUP-NAME")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <AutoCompleteInput {...groupName} freeSolo />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.DISTRIBUTOR.TITLE-CODE")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <AutoCompleteInput {...code} freeSolo />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.DISTRIBUTOR.TITLE-CLIENT-ACCOUNT-NUMBER")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <AutoCompleteInput {...clientAccountNumber} freeSolo />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.DISTRIBUTOR.TITLE-NAME")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <AutoCompleteInput {...name} freeSolo />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.DISTRIBUTOR.TITLE-VISIBLE")}?</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <DropDown
                            options={listCheck}
                            selectedItem={inputVisibleSearch}
                            onSelect={(item: string) => setInputVisibleSearch(item)}
                            width="100%"
                          />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl Right">{t("SEARCH.DISTRIBUTOR.INPUT-ADDRESS")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <AutoCompleteInput {...address} freeSolo />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.DISTRIBUTOR.TITLE-EMAIL")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <AutoCompleteInput {...email} freeSolo />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl Right">{t("CREATE.DISTRIBUTOR.INPUT-GEOGRAPHICAL-COORDINATES")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <AutoCompleteInput {...coordinates} freeSolo />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl Right">{t("SEARCH.DISTRIBUTOR.INPUT-MAP-URL")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <AutoCompleteInput {...mapUrl} freeSolo />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.DISTRIBUTOR.TITLE-EVALUATION")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <AutoCompleteInput {...evaluation} freeSolo />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>
                  </div>
                  <div className="bg-gray px-4" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Tooltip title={`${t("FILER-TO-COLUMNS")}`} placement="bottom">
                      <div style={{ width: "60px" }}>
                        <FontAwesomeIcon
                          className="grayBackgr"
                          style={{ width: "47px", height: "47px" }}
                          icon={faThumbtack}
                          onClick={() => {
                            setTypeOfSearch(0);
                          }}
                        />
                      </div>
                    </Tooltip>
                    {/* <FontAwesomeIcon
                      className="grayBackgr"
                      icon={faSortAlphaDown}
                      onClick={() => SetSearchWindowState("SortWindow")}
                      style={{ width: "47px", height: "47px" }}
                    /> */}
                    <Button
                      variant="primary"
                      // style={{ margin: "15px 30px" }}
                      onClick={() => {
                        handleSearch();
                        setShowLateralContainerSearch(!showLateralContainerSearch);
                      }}
                    >
                      {t("SEARCH.ALL.BUTTON-SEARCH")}
                    </Button>
                    <h4
                      className="h4-stl my-0 grayBackgr"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setInputGroupSearch("");
                        setInputGroupCodeSearch("");
                        setInputGroupNameSearch("");
                        setInputCodeSearchDistributor("");
                        setInputClientAccountNumberSearch("");
                        setInputNameSearchDistributor("");
                        setInputVisibleSearch("");
                        setInputAddressSearch("");
                        setInputEmailSearch("");
                        setInputGeographicalCoordinatesSearch("");
                        setInputMapURLSearch("");
                        setInputEvaluationSearch("");
                        setInputLookupSearch("");
                        handleSearch();
                      }}
                    >
                      {t("SEARCH.ALL.BUTTON-RESET")}
                    </h4>

                    <h4
                      className="h4-stl my-0 grayBackgr"
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                    >
                      {t("SEARCH.ALL.BUTTON-CLOSE")}
                    </h4>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="cssanimation sequence fadeInBottom">
              <div className="sectionBoxTop pb-4">
                <div
                  className="py-3 my-2"
                  style={{
                    backgroundColor: "var(--color-border)",
                    display: "flex",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    onClick={() => SetSearchWindowState("filterList")}
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "2em",
                      cursor: "pointer",
                    }}
                  />
                  <h4 className="h4-stl" onClick={() => SetSearchWindowState("filterList")} style={{ cursor: "pointer" }}>
                    <strong>Sort order</strong>
                  </h4>
                </div>
                <div>
                  <DropDown
                    options={listSort}
                    selectedItem={dropDownWhySort}
                    onSelect={(item: string) => setDropDownWhySort(item)}
                    width="20vw"
                  />
                </div>
              </div>
              <div>
                <Button
                  variant="primary"
                  style={{
                    margin: "15px 30px",
                    display: "flex",
                    float: "right",
                  }}
                  onClick={() => SetSearchWindowState("filterList")}
                >
                  Apply
                </Button>
              </div>
            </div>
          )}
          <input type="submit" value="" style={{ display: "none" }}></input>
        </form>
      }
    ></LateralContainer>
  );
}
