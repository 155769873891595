import React, { useState } from "react";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Row } from "react-bootstrap";
import LateralContainer from "../organisms/LateralContainer";
import chat from "../../images/chat.svg";
import facomMinLogo from "../../images/facomMinLogo.png";
import "../../constants/responsive.css";
import TexteAreaComment from "../atoms/TextAreaComment";
import LoadingComments from "./LoadingSkeleton/LoadingComments";
import { useTranslation } from "react-i18next";
import { updateComments, createComments, deleteComments } from "../../api/services/comments_services";
import "./styles/comments.css";

//Redux
import { useSelector } from "react-redux";
import { IRootState } from "../../store/redux";
import { typeUserData } from "../../constants/userTypes";
import useToastMui from "../../hooks/Toasts/useToastMui";
import { viewsToTranslation } from "../../utilities/viewsTranslation";
import { AxiosError } from "axios";

interface IComments {
  commentsArray: any;
  showLateralContainer: boolean;
  setShowLateralContainer: any;
  setCommentArray: any;
  idComponent: string;
  whereCreate: string;
  exchange?: boolean;
}

export default function Comments(props: IComments) {
  const { t } = useTranslation();
  const { commentsArray, showLateralContainer, setShowLateralContainer } = props;
  const { idComponent, whereCreate, exchange } = props;
  const { handleCreateToast } = useToastMui();
  //Redux
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { full_name, nameRolSelected } = profile;

  let commetsMap = [{}];
  if (commentsArray) {
    commetsMap = commentsArray?.result?.items;
  }

  const [loading, setLoading] = useState<boolean>(false);
  const [commentToCreate, setCommentToCreate] = useState<string>("");
  const [commentEdited, setCommentEdited] = useState<string>("");
  const [commentId, setCommentId] = useState<string>("");
  const [username, setUsername] = useState<string>("");

  const [editComment, setEditComment] = useState<boolean>(false);

  const toUpdate = (idToUpdate: string, commentToUpdate: string, user: string) => {
    setEditComment(!editComment);
    setCommentId(idToUpdate);
    setCommentEdited(commentToUpdate);
    setUsername(user);
  };

  const finishUpdate = (idToUpdate: string, commentToUpdate: string) => {
    setEditComment(!editComment);
    setCommentId(idToUpdate);
    setCommentEdited(commentToUpdate);
    updateCommentInfo();
  };

  const deleteCommentUpdate = (idToDelete: string) => {
    deleteComment(idToDelete);
  };

  //Update comments
  const updateCommentInfo = async () => {
    setLoading(!loading);
    try {
      const response = updateComments(commentId, commentEdited);
      const data = (await response).data;

      // alertSuccess()
      // window.location.reload()
      return data;
    } catch (error: any) {
      console.log("error update comment");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(t("ALERT-COMMENTS-ERROR-UPDATE"), "error");
    } finally {
      setLoading(false);
      console.log("end");
      props.setCommentArray();
    }
  };

  const createComment = async () => {
    setLoading(!loading);
    try {
      const response = createComments(commentToCreate, whereCreate, idComponent);
      const data = (await response).data;
      // alertSuccess()
      // window.location.reload()
      return data;
    } catch (error: any) {
      console.log("error create comment");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(t("ALERT-COMMENTS-ERROR-CREATE"), "error");
    } finally {
      setLoading(false);
      console.log("end");
      props.setCommentArray();
    }
  };

  const cancelButton = () => {
    setCommentId("");
  };

  const deleteComment = async (itemID: string) => {
    setLoading(!loading);
    try {
      const response = deleteComments(itemID);
      const data = (await response).data;

      // alertSuccess()
      // window.location.reload()
      return data;
    } catch (error: any) {
      console.log("error delete comment");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(t("ALERT-COMMENTS-ERROR-DELETE"), "error");
    } finally {
      setLoading(false);
      console.log("end");
      props.setCommentArray();
    }
  };
  //LateralContainer
  const lateralContainer = (content: any) => {
    return <LateralContainer show={showLateralContainer} toggleShow={setShowLateralContainer} content={content}></LateralContainer>;
  };
  return (
    <div className="exchanges">
      {lateralContainer(
        <Container>
          <div className="row">
            <div className="row">
              <h5>{viewsToTranslation(props.whereCreate)}</h5>
            </div>
            <div className="row mb-3" style={{ borderBottom: "1px solid var(--color-border)" }}>
              <div className="col-3 col-lg-2">
                <img alt="" src={chat} style={{ width: "100%" }} />
              </div>
              <div className="col-9 col-lg-10 mt-2">
                <div className="row">
                  <p style={{ fontSize: "80%" }}>{t("COMMENTS-POSTS-TITLE")}</p>
                </div>
                <div className="row">
                  <p>
                    {commentsArray?.result?.items?.length} / {commentsArray?.result?.items?.length}
                  </p>
                </div>
              </div>
            </div>
            <Row>
              <div className="comments-stl">
                <Col>
                  <Row>
                    <div>
                      <img alt="" src={facomMinLogo} style={{ width: "10%", marginLeft: "-10px" }} />
                    </div>
                  </Row>

                  <Row>
                    <TexteAreaComment
                      name="Code"
                      placeholder=""
                      style={{ height: "100%", minHeight: "40px" }}
                      currentValue={commentToCreate}
                      handleOnInputValue={(data: string) => {
                        setCommentToCreate(data);
                      }}
                    />
                  </Row>
                  <br></br>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <Button onClick={() => createComment()}>{t("COMMENTS.ALL.BUTTON-SHARE")}</Button>
                  </div>
                </Col>
              </div>
            </Row>
            {!loading ? (
              <div>
                {!commentId ? (
                  <div>
                    {commetsMap.map((item: any) => (
                      <Row
                        style={
                          full_name === item.user
                            ? { width: `${exchange ? "100%" : "90%"}` }
                            : {
                                width: `${exchange ? "100%" : "90%"}`,
                                backgroundColor: "var(--color-comments-bg)",
                                float: "right",
                              }
                        }
                      >
                        <div className="comments-stl">
                          <Col>
                            <div className="row">
                              <div className="col-2">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <img alt="" src={facomMinLogo} style={{ width: "80%" }} />
                                  {/* <span style={{ marginLeft: 10 }}>{item.user}</span> */}
                                </div>
                              </div>

                              <div className="col-10">
                                <div>
                                  <span>
                                    <strong>{item.user}</strong>
                                  </span>
                                </div>
                                <div>
                                  <TexteAreaComment
                                    name="Comment"
                                    readOnly
                                    placeholder=""
                                    style={exchange ? { height: "100%", minHeight: "180px" } : { height: "100%", minHeight: "40px" }}
                                    currentValue={item.comment}
                                  />
                                </div>
                              </div>
                            </div>

                            {full_name === item.user ? (
                              <Row>
                                <div className="content-icon">
                                  <div className="icon-stl">
                                    <FontAwesomeIcon
                                      style={{ width: "80%" }}
                                      icon={faEdit}
                                      onClick={() => {
                                        toUpdate(item.id, item.comment, item.user);
                                      }}
                                    />
                                  </div>
                                  <div className="icon-stl">
                                    <FontAwesomeIcon
                                      style={{ width: "80%" }}
                                      icon={faTrashAlt}
                                      onClick={() => {
                                        deleteCommentUpdate(item.id);
                                      }}
                                    />
                                  </div>
                                </div>
                              </Row>
                            ) : nameRolSelected === typeUserData.admin ? (
                              <Row>
                                <div className="content-icon">
                                  <div className="icon-stl">
                                    <FontAwesomeIcon
                                      style={{ width: "80%" }}
                                      icon={faEdit}
                                      onClick={() => {
                                        toUpdate(item.id, item.comment, item.user);
                                      }}
                                    />
                                  </div>
                                  <div className="icon-stl">
                                    <FontAwesomeIcon
                                      style={{ width: "80%" }}
                                      icon={faTrashAlt}
                                      onClick={() => {
                                        deleteCommentUpdate(item.id);
                                      }}
                                    />
                                  </div>
                                </div>
                              </Row>
                            ) : (
                              ""
                            )}
                          </Col>
                        </div>
                      </Row>
                    ))}
                  </div>
                ) : (
                  <div className="comments-stl-2">
                    <Col>
                      <div className="row">
                        <div className="col-2">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <img alt="" src={facomMinLogo} style={{ width: "100%" }} />
                            {/* <span style={{ marginLeft: 10 }}>{user}</span> */}
                          </div>
                        </div>
                        <div className="col-10">
                          <div>
                            <span>
                              <strong>{username}</strong>
                            </span>
                          </div>
                          <div>
                            <TexteAreaComment
                              name="CommetEdit"
                              placeholder=""
                              style={{
                                height: "60px",
                                background: "var(--color-white)",
                              }}
                              currentValue={commentEdited}
                              handleOnInputValue={(data: string) => {
                                setCommentEdited(data);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <Row>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            margin: "2% 0",
                          }}
                        >
                          <div style={{ marginLeft: "2%" }}>
                            <Button
                              onClick={() => {
                                finishUpdate("", "");
                              }}
                              style={{ width: 80 }}
                            >
                              Share
                            </Button>
                          </div>
                          <div style={{ marginLeft: "2%" }}>
                            <Button
                              onClick={() => {
                                cancelButton();
                              }}
                              style={{ width: 80 }}
                            >
                              Close
                            </Button>
                          </div>
                        </div>
                      </Row>
                    </Col>
                  </div>
                )}
              </div>
            ) : (
              <LoadingComments />
            )}
          </div>
        </Container>
      )}
    </div>
  );
}
