import { useContext } from "react";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { faThumbtack, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import Tooltip from "@mui/material/Tooltip";
import InputClear from "../../atoms/InputClear";
import { useFilterContext } from "../../../context";
import useAutocomplete from "../../atoms/AutocopleteInput/useAutocomplete";
import AutoCompleteInput from "../../atoms/AutocopleteInput/AutocompleteInput";

export interface ITableFilters {
  handleSearch: any;
}

export default function GroupTableFilters(props: ITableFilters) {
  const { handleSearch } = props;
  const { typeOfSearch, setTypeOfSearch, groupTableTransferData } = useContext(ApplicationContext);
  const { inputCodeSearchGroup, setInputCodeSearchGroup, inputNameSearchGroup, setInputNameSearchGroup, showLateralContainerSearch } =
    useFilterContext();
  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleSearch();
  };

  const code = useAutocomplete("/groups/get-all?autocomplete=true&code=", handleSearch, inputCodeSearchGroup, setInputCodeSearchGroup, "code");
  const name = useAutocomplete("/groups/get-all?autocomplete=true&name=", handleSearch, inputNameSearchGroup, setInputNameSearchGroup, "name");
  return (
    <>
      {(typeOfSearch === 0 ? showLateralContainerSearch : false) === true && (
        <tr>
          <td>
            <Tooltip title={`${t("FILER-TO-DOCK")}`} placement="bottom">
              <div style={{ width: "60px" }}>
                <FontAwesomeIcon
                  className="grayBackground-icon"
                  size="2x"
                  style={{ cursor: "pointer" }}
                  icon={faThumbtack}
                  onClick={() => {
                    setTypeOfSearch(1);
                  }}
                />
              </div>
            </Tooltip>
          </td>
          {groupTableTransferData.includes(`${t("SORT.GROUP.INPUT-CODE")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <AutoCompleteInput {...code} freeSolo />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputCodeSearchGroup("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {groupTableTransferData.includes(`${t("SORT.GROUP.INPUT-NAME")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <AutoCompleteInput {...name} freeSolo />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputNameSearchGroup("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {groupTableTransferData.includes(`${t("ALL.GENERAL.TABLE.GROUP.TITLE-LOGO")}`) && <td></td>}
        </tr>
      )}
    </>
  );
}
