import { useState, useContext } from "react";
import { IReasonForExchangeList, IReasonForExchangePage } from "../../api/interfaces/interfaz_reasons_for_exchange";
import {
  deleteManyReasonsForExchange,
  getAllReasonsForExchange,
  getExportReasonExchange,
  getExportReasonExchangeByID,
} from "../../api/services/reasons_for_exchange_services";
import { ApplicationContext } from "../../context/ApplicationContext";
import useAsync from "../useAsync";
import useFetchAndLoad from "../useFetchAndLoad";
import { createAddapterReasonForExchange } from "./adapters/reasonForExchangeAdapter";
import { t } from "i18next";
import useToastMui from "../Toasts/useToastMui";
import { useTranslation } from "react-i18next";

const useReasonForExchange = (code: string, language: string, displayOrder: string, elegibleForExchange: string, label: string) => {
  const { setExportToggle, setReasonExchangeLength, setRefusalReasonListID, reasonForExchangePage, setReasonForExchangePage } =
    useContext(ApplicationContext);
  const { handleCreateToast } = useToastMui();
  const [reasonsForExchangeArray, setReasonsForExchangeArray] = useState<IReasonForExchangePage[]>();
  const [lastPage, setLastPage] = useState(0);
  const [searchToggle, setSearchToggle] = useState(false);
  const [deletedToogle, setDeletedToogle] = useState(false);
  const limit = 10;
  const { i18n } = useTranslation();

  const [errorDeleteAlert, setErrorDeleteAlert] = useState<boolean[]>([false, false, false]);

  const [countCode, setCountCode] = useState(1);
  const [typeSort, setTypeSort] = useState("");

  // ! -------------------------------- API CALLS-----------------------------------//

  const { loading, callEndpoint } = useFetchAndLoad();

  const getQuery = (code: string, language: string, displayOrder: string, elegibleForExchange: string, label: string, sort?: string) => {
    const queryKeys: any = {
      language: language ? (language === `${t("LANGUAGE-LIST-ENGLISH")}` ? "English" : "French") : "",
      display_order: displayOrder,
      label: label,
      code_reason: code,
      is_for_change: elegibleForExchange,
      sort: sort,
    };
    let query = {};
    for (let key in queryKeys) {
      if (queryKeys[key]) {
        query = { ...query, [key]: queryKeys[key] };
      }
    }
    return query;
  };

  const loadReasonForExchanges = async (
    code: string,
    language: string,
    displayOrder: string,
    elegibleForExchange: string,
    label: string,
    nowPage: number
  ) => {
    let query = getQuery(code, language, displayOrder, elegibleForExchange, label, typeSort);

    query = {
      ...query,
      limit: limit,
      offset: (nowPage - 1) * limit,
    };
    const response = await callEndpoint(getAllReasonsForExchange({ query }), () =>
      handleCreateToast(t("ALERT-REASON-FOR-EXCHANGE-ERROR-GET"), "error")
    );
    return response;
  };

  const adaptInfo = (data: IReasonForExchangeList) => {
    const array: IReasonForExchangePage[] = createAddapterReasonForExchange(data?.result?.items);
    setReasonsForExchangeArray(array);
    setLastPage(data?.result?.total);
    setReasonExchangeLength(data?.result?.total);
  };

  useAsync(
    () => loadReasonForExchanges(code, language, displayOrder, elegibleForExchange, label, reasonForExchangePage),
    adaptInfo,
    () => {},
    [reasonForExchangePage, searchToggle, deletedToogle, typeSort]
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setReasonForExchangePage(value);
  };

  const onSearch = () => {
    setSearchToggle((value) => !value);
    setReasonForExchangePage(1);
  };

  const deleteManyReasonExchange = async (reasonExchangeId: string[]) => {
    try {
      const response = deleteManyReasonsForExchange(reasonExchangeId);
      const data = (await response).data;
      handleCreateToast(t("ALERT-REASON-FOR-EXCHANGE-SUCCESS-DELETE"), "success");
      return data;
    } catch (error: any) {
      console.log("error deleted reason for exchanges");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(t("ALERT-REASON-FOR-EXCHANGE-ERROR-DELETE"), "error");
    } finally {
      console.log("end");
      // history.push("/reasons-for-exchange");
      // setOpenConfirm(false);
      setRefusalReasonListID([]);
      setReasonForExchangePage(1);
      setDeletedToogle(!deletedToogle);
    }
  };

  const exportReasonsForExchanges = async (checkedType: string, reasonForExchangesListID: string[], checkNeedToExport: boolean[]) => {
    setExportToggle(true);
    try {
      if (checkNeedToExport[0] === true) {
        await getExportReasonExchange(checkedType);
      }
      if (checkNeedToExport[1] === true) {
        let query = getQuery(code, language, displayOrder, elegibleForExchange, label, typeSort);
        await getExportReasonExchangeByID(
          checkedType,
          reasonForExchangesListID,
          i18n.language === "fr" ? "Raison de l'échange" : "Reason For Exchange",
          query
        );
      }
    } catch (error: any) {
      console.log("error export reasons");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(t("ALERT-REASON-FOR-EXCHANGE-ERROR-EXPORT"), "error");
    } finally {
      setExportToggle(false);
      console.log("end");
      // setOpenConfirm(false);
    }
  };
  // ! ------------------------END API CALLS-----------------------------------//

  // * ------------------------SORT----------------------------------//

  // sort

  const incrementCount = (key: string) => {
    setCountCode((prev) => prev + 1);

    if (countCode === 1) {
      setTypeSort(`asc(${key})`);
    }
    if (countCode === 2) {
      setTypeSort(`desc(${key})`);
      setCountCode(1);
    }
  };

  return {
    reasonsForExchangeArray,
    loading,
    exportReasonsForExchanges,
    deleteManyReasonExchange,
    errorDeleteAlert,
    setErrorDeleteAlert,
    currentPage: reasonForExchangePage,
    lastPage,
    loadReasonForExchanges,
    handleChange,
    setCurrentPage: setReasonForExchangePage,
    limit,
    onSearch,
    typeSort,
    incrementCount,
  };
};

const service = {
  useReasonForExchange,
};

export default service;
export { useReasonForExchange };
