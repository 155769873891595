import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHotel, faEllipsisH, faChevronLeft, faSortAlphaDown, faThumbtack } from "@fortawesome/free-solid-svg-icons";
import LateralContainer from "../LateralContainer";
import InputClear from "../../atoms/InputClear";
import { Button } from "react-bootstrap";
import DropDown from "../../atoms/DropDown";
import { t } from "i18next";

import { IDistributorSetSearch } from "./interfaces";
import { useContext } from "react";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { Tooltip, useMediaQuery } from "@mui/material";
import { useFilterContext } from "../../../context";
import useAutocomplete from "../../atoms/AutocopleteInput/useAutocomplete";
import { createAddapterDistributorSet } from "../../../hooks/DistributorSet/adapters/distributorSetAdapter";
import AutoCompleteInput from "../../atoms/AutocopleteInput/AutocompleteInput";

export default function DistributorSetSearch(props: IDistributorSetSearch) {
  const { handleSearch, SetSearchWindowState, searchWindowState, listSort, dropDownWhySort, setDropDownWhySort } = props;

  const {
    inputGroupCodeSearchDistributorSet,
    setInputGroupCodeSearchDistributorSet,
    inputGroupNameSearchDistributorSet,
    setInputGroupNameSearchDistributorSet,
    inputNameSearchDistributorSet,
    setInputNameSearchDistributorSet,
    inputOriginSearch,
    setInputOriginSearch,
    inputMembersSearch,
    setInputMembersSearch,
    showLateralContainerSearch,
    setShowLateralContainerSearch,
  } = useFilterContext();

  const { typeOfSearch, setTypeOfSearch } = useContext(ApplicationContext);

  const matches = useMediaQuery("(max-width:500px)");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleSearch();
    setShowLateralContainerSearch(!showLateralContainerSearch);
  };
  const groupCode = useAutocomplete(
    "/distributor-set/get-all?autocomplete=true&group_code=",
    handleSearch,
    inputGroupCodeSearchDistributorSet,
    setInputGroupCodeSearchDistributorSet,
    "groupCode",
    createAddapterDistributorSet
  );
  const groupName = useAutocomplete(
    "/distributor-set/get-all?autocomplete=true&group_name=",
    handleSearch,
    inputGroupNameSearchDistributorSet,
    setInputGroupNameSearchDistributorSet,
    "groupName",
    createAddapterDistributorSet
  );
  const name = useAutocomplete(
    "/distributor-set/get-all?autocomplete=true&name=",
    handleSearch,
    inputNameSearchDistributorSet,
    setInputNameSearchDistributorSet,
    "name"
  );
  const originSearch = useAutocomplete(
    "/distributor-set/get-all?autocomplete=true&origin=",
    handleSearch,
    inputOriginSearch,
    setInputOriginSearch,
    "origin"
  );
  const membersOfSet = useAutocomplete(
    "/distributors/get-all?autocomplete=true&members_of_set=",
    handleSearch,
    inputMembersSearch,
    setInputMembersSearch,
    "code"
  );

  return (
    <LateralContainer
      show={typeOfSearch === 1 ? showLateralContainerSearch : false}
      toggleShow={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
      content={
        <form action="/" onSubmit={handleSubmit}>
          <div className="row sectionBoxTop">
            <div className="col-2 col-lg-2 pb-3" style={{ display: "flex" }}>
              <FontAwesomeIcon
                icon={faHotel}
                style={{
                  marginLeft: "50%",
                  marginTop: "0",
                  width: "50px",
                  height: "50px",
                }}
              />
            </div>
            <div className="col-10 col-lg-10">
              <h4 className="h4-stl" style={{ margin: "15px 20px 15px" }}>
                <strong>{t("SEARCH.GROUP.TITLE-SEARCH-DISTRIBUTOR-SET")}</strong>
              </h4>
            </div>
          </div>
          {searchWindowState === "filterList" ? (
            <div className="cssanimation sequence fadeInTop">
              {matches ? (
                <div className="filterBox my-4">
                  <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                    {t("CREATE.GROUP.INPUT-DESCRIPTION")}
                  </h4>

                  <div className="flex-stl flex-direction-column px-4 my-2">
                    <div>
                      <h5 className="h5-stl Right">{t("ALL.GENERAL.TABLE.DIST-SET.TITLE-CODE")}</h5>
                    </div>
                    <div className="flex-stl">
                      <div className="full-width">
                        <AutoCompleteInput {...groupCode} freeSolo />
                      </div>
                    </div>
                  </div>

                  <div className="flex-stl flex-direction-column px-4 my-4">
                    <div>
                      <h5 className="h5-stl Right">{t("ALL.GENERAL.TABLE.DIST-SET.TITLE-NAME-GROUP")}</h5>
                    </div>
                    <div className="flex-stl">
                      <div className="full-width">
                        <AutoCompleteInput {...groupName} freeSolo />
                      </div>
                    </div>
                  </div>

                  <div className="flex-stl flex-direction-column px-4 my-4">
                    <div>
                      <h5 className="h5-stl Right">{t("ALL.GENERAL.TABLE.DIST-SET.TITLE-NAME")}</h5>
                    </div>
                    <div className="flex-stl">
                      <div className="full-width">
                        <AutoCompleteInput {...name} freeSolo />
                      </div>
                    </div>
                  </div>

                  <div className="flex-stl flex-direction-column px-4 my-4">
                    <div>
                      <h5 className="h5-stl Right">{t("ALL.GENERAL.TABLE.DIST-SET.TITLE-ORIGIN")}</h5>
                    </div>
                    <div className="flex-stl">
                      <div className="full-width">
                        <AutoCompleteInput {...originSearch} freeSolo />
                      </div>
                    </div>
                    {/*<div className="col-2">
                      <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                    </div>*/}
                  </div>

                  <div className="flex-stl flex-direction-column px-4 my-4">
                    <div>
                      <h5 className="h5-stl Right">{t("ALL.GENERAL.TABLE.DIST-SET.TITLE-MEMBERS")}</h5>
                    </div>
                    <div className="flex-stl">
                      <div className="full-width">
                        <AutoCompleteInput {...membersOfSet} freeSolo />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="filterBox my-4">
                  <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                    {t("CREATE.GROUP.INPUT-DESCRIPTION")}
                  </h4>
                  <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                    <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                      <h5 className="h5-stl">{t("ALL.GENERAL.TABLE.DIST-SET.TITLE-CODE")}</h5>
                    </div>
                    <div className="flex-stl" style={{ width: "70%" }}>
                      <div className="full-width">
                        <AutoCompleteInput {...groupCode} freeSolo />
                      </div>
                    </div>
                  </div>

                  <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                    <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                      <h5 className="h5-stl">{t("ALL.GENERAL.TABLE.DIST-SET.TITLE-NAME-GROUP")}</h5>
                    </div>
                    <div className="flex-stl" style={{ width: "70%" }}>
                      <div className="full-width">
                        <AutoCompleteInput {...groupName} freeSolo />
                      </div>
                    </div>
                  </div>

                  <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                    <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                      <h5 className="h5-stl">{t("ALL.GENERAL.TABLE.DIST-SET.TITLE-NAME")}</h5>
                    </div>
                    <div className="flex-stl" style={{ width: "70%" }}>
                      <div className="full-width">
                        <AutoCompleteInput {...name} freeSolo />
                      </div>
                    </div>
                  </div>

                  <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                    <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                      <h5 className="h5-stl">{t("ALL.GENERAL.TABLE.DIST-SET.TITLE-ORIGIN")}</h5>
                    </div>
                    <div className="flex-stl" style={{ width: "70%" }}>
                      <div className="full-width">
                        <AutoCompleteInput {...originSearch} freeSolo />
                      </div>
                    </div>
                    {/*<div className="col-2">
                      <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                    </div>*/}
                  </div>

                  <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                    <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                      <h5 className="h5-stl">{t("ALL.GENERAL.TABLE.DIST-SET.TITLE-MEMBERS")}</h5>
                    </div>
                    <div className="flex-stl" style={{ width: "70%" }}>
                      <div className="full-width">
                        <AutoCompleteInput {...membersOfSet} freeSolo />
                      </div>
                    </div>
                    {/*<div className="col-2">
                      <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                    </div>*/}
                  </div>
                </div>
              )}
              <div className={`bg-gray px-4 flex-stl flex-center ${matches ? " flex-space-center" : "flex-space-evenly"}`}>
                <Tooltip title={`${t("FILER-TO-COLUMNS")}`} placement="bottom">
                  <div style={{ width: "60px" }}>
                    <FontAwesomeIcon
                      className="grayBackgr"
                      style={{ width: "47px", height: "47px" }}
                      icon={faThumbtack}
                      onClick={() => {
                        setTypeOfSearch(0);
                      }}
                    />
                  </div>
                </Tooltip>
                {/* <FontAwesomeIcon
                  className="grayBackgr"
                  icon={faSortAlphaDown}
                  onClick={() => SetSearchWindowState("SortWindow")}
                  style={{ width: "47px", height: "47px" }}
                /> */}
                <Button
                  variant="primary"
                  // style={{ margin: "15px 30px" }}
                  onClick={() => {
                    handleSearch();
                    setShowLateralContainerSearch(!showLateralContainerSearch);
                  }}
                >
                  {t("SEARCH.ALL.BUTTON-SEARCH")}
                </Button>
                <h4
                  className="h4-stl my-0 grayBackgr"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setInputGroupCodeSearchDistributorSet("");
                    setInputGroupNameSearchDistributorSet("");
                    setInputNameSearchDistributorSet("");
                    setInputOriginSearch("");
                    setInputMembersSearch("");
                    handleSearch();
                  }}
                >
                  {t("SEARCH.ALL.BUTTON-RESET")}
                </h4>
                <h4
                  className="h4-stl mx-0 grayBackgr"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                >
                  {t("SEARCH.ALL.BUTTON-CLOSE")}
                </h4>
              </div>
            </div>
          ) : (
            <div className="cssanimation sequence fadeInBottom">
              <div className="sectionBoxTop pb-4">
                <div
                  className="py-3 my-2"
                  style={{
                    backgroundColor: "var(--color-border)",
                    display: "flex",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    onClick={() => SetSearchWindowState("filterList")}
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "2em",
                      cursor: "pointer",
                    }}
                  />
                  <h4 className="h4-stl" onClick={() => SetSearchWindowState("filterList")} style={{ cursor: "pointer" }}>
                    <strong>Sort order</strong>
                  </h4>
                </div>
                <div>
                  <DropDown
                    options={listSort}
                    selectedItem={dropDownWhySort}
                    onSelect={(item: string) => setDropDownWhySort(item)}
                    width="20vw"
                  />
                </div>
              </div>

              <div>
                <Button
                  variant="primary"
                  style={{
                    margin: "15px 30px",
                    display: "flex",
                    float: "right",
                  }}
                  onClick={() => SetSearchWindowState("filterList")}
                >
                  Apply
                </Button>
              </div>
            </div>
          )}
          <input type="submit" value="" style={{ display: "none" }}></input>
        </form>
      }
    ></LateralContainer>
  );
}
