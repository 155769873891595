import { useState, useContext } from "react";
import { IProductsList, IProductsListDOM } from "../../api/interfaces/interfaz_products";
import { getAllProducts, deleteManyProducts, getExportProducts, getExportProductsByID } from "../../api/services/products_services";

import { ApplicationContext } from "../../context/ApplicationContext";
import useAsync from "../useAsync";
import useFetchAndLoad from "../useFetchAndLoad";
import { createAddapterProduct } from "./adapters/productAdapter";
import { useTranslation } from "react-i18next";
import useToastMui from "../Toasts/useToastMui";

function useProductsGet(
  brandCode: string,
  brandName: string,
  unitPrice: string,
  ean: string,
  old_reference: string,
  reference: string,
  name: string,
  type: string,
  time_warranty: string,
  available: string,
  defaultInput: string,
  brand?: string
) {
  //Variables
  const { setProductLength, setAlertDeleteProduct, setProductsListID, productsPage, setProductsPage, setExportToggle } =
    useContext(ApplicationContext);
  const { t, i18n } = useTranslation();
  //API STATE
  const [productsArray, setProductsArray] = useState<IProductsListDOM[]>();
  const [lastPage, setLastPage] = useState(0);
  const [deletedToogle, setDeletedToogle] = useState(false);
  const [searchToggle, setSearchToggle] = useState(false);
  const limit = 10;

  const [errorDeleteAlert, setErrorDeleteAlert] = useState<boolean[]>([false, false, false]);
  const { loading, callEndpoint } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const [countCode, setCountCode] = useState(1);
  const [typeSort, setTypeSort] = useState("");

  const getQuery = (
    brandCode: string,
    brandName: string,
    unitPrice: string,
    ean: string,
    old_reference: string,
    reference: string,
    name: string,
    type: string,
    time_warranty: string,
    available: string,
    defaultInput: string,
    brand?: string,
    sort?: string
  ) => {
    const queryKeys: any = {
      brand_code: brandCode,
      brand_name: brandName,
      unit_price: unitPrice,
      ean_number: ean,
      old_reference: old_reference,
      reference_end_user: reference,
      name: name,
      type: type,
      time_warranty: time_warranty,
      available: available,
      default: defaultInput,
      brand: brand,
      sort: sort ? (sort === "none" ? "" : sort) : "desc(created_at)",
    };

    let query = {};
    for (let key in queryKeys) {
      if (queryKeys[key]) {
        query = { ...query, [key]: queryKeys[key] };
      }
    }
    return query;
  };

  const loadProducts = async (
    brandCode: string,
    brandName: string,
    unitPrice: string,
    ean: string,
    old_reference: string,
    reference: string,
    name: string,
    type: string,
    time_warranty: string,
    available: string,
    defaultInput: string,
    nowPage: number,
    brand?: string
  ) => {
    let query = getQuery(
      brandCode,
      brandName,
      unitPrice,
      ean,
      old_reference,
      reference,
      name,
      type,
      time_warranty,
      available,
      defaultInput,
      brand,
      typeSort
    );
    query = {
      ...query,
      limit: limit,
      offset: (nowPage - 1) * limit,
    };
    const response = await callEndpoint(
      getAllProducts({ query }),
      () => !brand && handleCreateToast(t("ALERT-PRODUCTS-ERROR-GET"), "error")
    );
    return response;
  };

  const adaptInfo = (data: IProductsList) => {
    const array: IProductsListDOM[] = createAddapterProduct(data?.result?.items);
    setProductsArray(array);
    setLastPage(data?.result?.total);
    setProductLength(data?.result?.total);
  };

  useAsync(
    () =>
      loadProducts(
        brandCode,
        brandName,
        unitPrice,
        ean,
        old_reference,
        reference,
        name,
        type,
        time_warranty,
        available,
        defaultInput,
        productsPage,
        brand
      ),
    adaptInfo,
    () => {},
    [productsPage, searchToggle, deletedToogle, typeSort]
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setProductsPage(value);
  };
  const onSearch = () => {
    setSearchToggle((value) => !value);
    setProductsPage(1);
  };

  const deleteManyProduct = async (productsId: string[]) => {
    try {
      const response = deleteManyProducts(productsId);
      const data = (await response).data;
      handleCreateToast(t("ALERT-PRODUCTS-SUCCESS-DELETE"), "success");
      return data;
    } catch (error: any) {
      console.log("error deleted products");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(t("ALERT-PRODUCTS-ERROR-DELETE"), "error");
    } finally {
      console.log("end");
      setProductsListID([]);
      setProductsPage(1);
      setDeletedToogle(!deletedToogle);
    }
  };

  // Export
  const exportProduct = async (checkedType: string, productsListID: string[], checkNeedToExport: boolean[]) => {
    setExportToggle(true);
    try {
      if (checkNeedToExport[0] === true) {
        await getExportProducts(checkedType, i18n.language === "fr" ? "Produit" : "Product");
      }
      if (checkNeedToExport[1] === true) {
        let query = getQuery(
          brandCode,
          brandName,
          unitPrice,
          ean,
          old_reference,
          reference,
          name,
          type,
          time_warranty,
          available,
          defaultInput,
          brand,
          "none"
        );
        query = {
          ...query,
        };
        await getExportProductsByID(checkedType, productsListID, i18n.language === "fr" ? "Produit" : "Product", query);
      }
    } catch (error: any) {
      console.log("error export products");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(t("ALERT-PRODUCTS-ERROR-EXPORT"), "error");
    } finally {
      console.log("end");
      setExportToggle(false);
      //   setOpenConfirm(false);
    }
  };

  const alertSuccess = () => {
    setAlertDeleteProduct(true);
  };

  // * ------------------------SORT----------------------------------//

  // sort

  const incrementCount = (key: string) => {
    setCountCode((prev) => prev + 1);

    if (countCode === 1) {
      setTypeSort(`asc(${key})`);
    }
    if (countCode === 2) {
      setTypeSort(`desc(${key})`);
      setCountCode(1);
    }
  };

  return {
    productsArray,
    loading,
    exportProduct,
    deleteManyProduct,
    errorDeleteAlert,
    setErrorDeleteAlert,
    currentPage: productsPage,
    lastPage,
    loadProducts,
    handleChange,
    setCurrentPage: setProductsPage,
    limit,
    onSearch,

    typeSort,
    incrementCount,
  };
}

const service = {
  useProductsGet,
};
export default service;
export { useProductsGet };
