import { t } from "i18next";

export const viewsToTranslation = (view: string) => {
  if (view === "Brand") return t("TABLE.BRAND.TITLE.HEADER-LINK");
  if (view === "product") return t("TABLE.PRODUCT.TITLE.HEADER-LINK");
  if (view === "group") return t("TABLE.GROUP.TITLE.HEADER-LINK");
  if (view === "distributor") return t("TABLE.DISTRIBUTOR.TITLE.HEADER-LINK");
  if (view === "distributor Set") return t("HOME.MENU.DISTRIBUTOR-SETS");
  if (view === "customer") return t("TABLE.CUSTOMER.TITLE.HEADER-LINK");
  if (view === "Exchange") return t("HOME.MENU.EXCHANGES");
  if (view === "content") return t("HOME.MENU.CONTENTS");
  if (view === "Reason for exchange") return t("HOME.MENU.REASON-FOR-EXCHANGE");
  if (view === "Refusal reason") return t("HOME.MENU.REFUSAL-REASONS");
  if (view === "user") return t("HOME.MENU.USERS");
  if (view === "Change log") return t("HOME.MENU.CHANGE-LOG");
  return "";
};
