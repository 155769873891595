import React, { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { faCheck, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Container, Dropdown } from "react-bootstrap";
import Pagination from "@mui/material/Pagination";
import Table from "react-bootstrap/Table";
import Image from "../atoms/Image";
import "./styles/TableRefusal.css";
import { Link, useHistory } from "react-router-dom";
import uncheckedpage from "../../images/checkPage.svg";

import { ApplicationContext } from "../../context/ApplicationContext";

import LoadingSkeletonTables from "./LoadingSkeleton/LoadingSkeletonTables";
import { useTranslation } from "react-i18next";

import checkedimg from "../../images/checked.svg";
import uncheckedimg from "../../images/unchecked.svg";
import grayChecked from "../../images/grayCheck.png";
import { Checkbox, useMediaQuery } from "@mui/material";
import ImagesApi from "../pages/ExchangesInfo/ImagesApi";
import ProductTableFilters from "./Search/ProductTableFilters";
import { IRowHeader } from "../../models/table.modal";
import ProductTableResponsive from "../molecules/TransferList/responsiveTables/ProductTableResponsive";
import { fromInfoToTranslatedWarranty, fromInfoToTranslatedWarrantyTable } from "../../utilities/usetBussinessTransalte";
interface IProductsProps {
  searchState: boolean;
  rowsPerPage: number;
  onClick: any;
  UsePlanText: string;
  lastPage: number;
  handleChange: any;
  dropDownWhySort: string;
  Create?: any;
  showSearchTable?: boolean;
  productsArray: any;
  loading: boolean;
  currentPage: number;

  incrementCount?: any;
  typeSort?: string;
  inputToSort?: string;

  //filters
  handleSearch: any;
  setDropDownWhySort: any;
}

export interface IRowProductFromAPI {
  _id: string;
  brand_data: any;
  brand: string;
  reference: string;
  name: string;
  type: string;
  picture: string;
  unit_price: string;
  available: any;
  time_warranty: string;
  [index: string]: string;
}

export default function TableExchanges(props: IProductsProps) {
  const { lastPage, handleChange, currentPage, productsArray, incrementCount, typeSort, inputToSort, setDropDownWhySort } = props;
  const { productTableTransferData, setProductsAllSelected } = useContext(ApplicationContext);
  const {
    setProductReference,
    setProductName,
    setProductBrandCode,
    setProductBrandName,
    setProductPicture,
    setProductType,
    setProductUnitPrice,
    setProductId,
    openProductTable,
    setOpenProductTable,
  } = useContext(ApplicationContext);
  const { setProductsListID, productsListID, setProductToDelete } = useContext(ApplicationContext);
  const matches = useMediaQuery("(max-width:780px)");
  const history = useHistory();
  //filters
  const { handleSearch } = props;

  //Translations
  const { t } = useTranslation();

  const tableProductInitial: IRowHeader[] = [
    {
      field: "dropdown",
      headerName: "",
      sort: "",
      title: "",
    },
    {
      field: "brand_data.code",
      headerName: t("SORT.PRODUCT.INPUT-CODE-BRAND"),
      sort: t("SORT.PRODUCT.INPUT-CODE-BRAND"),
      title: t("ALL.GENERAL.PRODUCT-HEADER-TITLE-BRAND"),
    },
    {
      field: "brand_data.name",
      headerName: t("SORT.PRODUCT.INPUT-NAME-BRAND"),
      sort: t("SORT.PRODUCT.INPUT-NAME-BRAND"),
      title: "",
    },
    {
      field: "reference",
      headerName: t("SORT.PRODUCT.INPUT-REFERENCE"),
      sort: t("SORT.PRODUCT.INPUT-REFERENCE"),
      title: t("ALL.GENERAL.PRODUCT-HEADER-TITLE-IDENTIFICATION"),
    },
    {
      field: "name",
      headerName: t("SORT.PRODUCT.INPUT-NAME"),
      sort: t("SORT.PRODUCT.INPUT-NAME"),
      title: "",
    },
    {
      field: "type",
      headerName: t("SORT.PRODUCT.INPUT-TYPE"),
      sort: t("SORT.PRODUCT.INPUT-TYPE"),
      title: "",
    },
    {
      field: "picture",
      headerName: t("ALL.GENERAL.PRODUCT.TITLE-PICTURE"),
      sort: t("ALL.GENERAL.PRODUCT.TITLE-PICTURE"),
      title: "",
    },
    {
      field: "unit_price",
      headerName: t("SORT.PRODUCT.INPUT-UNIT-PRICE"),
      sort: t("SORT.PRODUCT.INPUT-UNIT-PRICE"),
      title: "",
    },
    {
      field: "available",
      headerName: t("SORT.PRODUCT.INPUT-AVAILABLE"),
      sort: t("SORT.PRODUCT.INPUT-AVAILABLE"),
      title: t("ALL.GENERAL.PRODUCT-HEADER-TITLE-STOCK-AND-PRICING"),
    },
    {
      field: "time_warranty",
      headerName: t("SORT.PRODUCT.INPUT-WARRANTY"),
      sort: t("SORT.PRODUCT.INPUT-WARRANTY"),
      title: "",
    },
  ];

  //GENERATE AN ARRAY WITH SELECTED IDS
  const [defaultChecked, setDefaultChecked] = useState<boolean>(false);
  const createDeleteArray = (idDelete: string, checked: boolean) => {
    if (checked) {
      setProductToDelete(idDelete);
      setProductsListID([...productsListID, idDelete]);
    } else {
      setProductsListID(productsListID.filter((item: any) => item !== idDelete));
    }
  };

  //EXCHANGES
  const handleProducts = (
    productId: string,
    productReference: string,
    productName: string,
    productBrandCode: string,
    productBrandName: string,
    productPicture: string,
    productType: string,
    productUnitPrice: string
  ) => {
    setProductId(productId);
    setProductReference(productReference);
    setProductName(productName);
    setProductBrandCode(productBrandCode);
    setProductBrandName(productBrandName);

    setProductPicture(productPicture);
    setProductType(productType);
    setProductUnitPrice(productUnitPrice);

    setOpenProductTable(!openProductTable);
  };

  //TABLE
  const pageSize = props.rowsPerPage;

  //FILTRO
  let productArraySort: IRowProductFromAPI[] = [];
  if (productsArray) {
    productArraySort = productsArray;
  }

  const [tabla, setTabla] = useState<IRowHeader[]>(tableProductInitial);
  useEffect(() => {
    setTabla(tableProductInitial.filter((item) => productTableTransferData.includes(item.headerName)));
  }, [productTableTransferData]);

  const createHeaderGroups = (row: IRowHeader, index: number) => <th>{row.title}</th>;

  const createHeader = (row: IRowHeader, index: number) => (
    <th
      key={`product-${index}`}
      style={{ cursor: "pointer" }}
      // onClick={() => {
      //   setDropDownWhySort(row.sortKey);
      //   incrementCount(row.field);
      // }}
    >
      {row.headerName}
      {/* {inputToSort === row.sort && (
        <FontAwesomeIcon icon={typeSort === `asc(${row.field})` ? faChevronUp : faChevronDown} style={{ marginLeft: "5px" }} />
      )} */}
    </th>
  );

  const createRow = (row: IRowProductFromAPI, index: number, render: IRowHeader[]) => {
    const isItemSelected: boolean = isSelected(row._id);
    return (
      <tr
        key={row?._id}
        style={{
          backgroundColor: "var(--color-white)",
          textAlign: "left",
          cursor: "pointer",
        }}
      >
        {defaultChecked ? (
          <td style={{ paddingLeft: "15px" }}>
            <img alt="" src={grayChecked} style={{ width: "20px", marginLeft: "10px" }}></img>
          </td>
        ) : (
          <td style={{ paddingLeft: "15px" }} className="check-table">
            <Checkbox
              checked={isItemSelected}
              style={{ display: "inline" }}
              name="gridRadios"
              id={`radios${row._id}`}
              value={`option${row._id}`}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                createDeleteArray(row._id, event.target.checked);
              }}
              aria-label="option 1"
            />
            &nbsp;
            {/* <FontAwesomeIcon icon={faBars} /> */}
          </td>
        )}
        {!props.Create ? (
          <>
            {render?.map((i: IRowHeader) => (
              <>
                {i.field === "brand_data.code" || i.field === "brand_data.name" ? (
                  <td style={{ paddingLeft: "15px" }} onClick={() => history.push(`/brand-info/${row.brand}`)}>
                    <Link
                      to={`/brand-info/${row.brand}`}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      {`${i.field}` === "brand_data.code" ? row.brand_data.code : row.brand_data.name}
                    </Link>
                  </td>
                ) : (
                  <td style={{ paddingLeft: "15px" }} onClick={() => history.push(`/product-info/${row._id}`)}>
                    {`${i.field}` === "picture" ? (
                      <ImagesApi image={row?.picture} />
                    ) : `${i.field}` === "available" ? (
                      <div style={{ paddingLeft: "20px" }}>{row.available === true ? <FontAwesomeIcon icon={faCheck} /> : ""}</div>
                    ) : `${i.field}` === "time_warranty" ? (
                      fromInfoToTranslatedWarrantyTable(row[`${i.field}`])
                    ) : (
                      row[`${i.field}`]
                    )}
                  </td>
                )}
              </>
            ))}
          </>
        ) : (
          <>
            {render?.map((i: IRowHeader) => (
              <>
                {i.field === "brand_data.code" || i.field === "brand_data.name" ? (
                  <td
                    style={{ paddingLeft: "15px" }}
                    onClick={() =>
                      handleProducts(
                        row._id,
                        row.reference,
                        row.name,
                        row.brand_data?.code,
                        row.brand_data?.name,
                        row.picture,
                        row.type,
                        row.unit_price
                      )
                    }
                  >
                    {`${i.field}` === "brand_data.code" ? row.brand_data.code : row.brand_data.name}
                  </td>
                ) : (
                  <td
                    style={{ paddingLeft: "15px" }}
                    onClick={() =>
                      handleProducts(
                        row._id,
                        row.reference,
                        row.name,
                        row.brand_data?.code,
                        row.brand_data?.name,
                        row.picture,
                        row.type,
                        row.unit_price
                      )
                    }
                  >
                    {`${i.field}` === "picture" ? (
                      <ImagesApi image={row?.picture} />
                    ) : `${i.field}` === "available" ? (
                      <>{row.available === true ? <FontAwesomeIcon icon={faCheck} /> : ""}</>
                    ) : (
                      row[`${i.field}`]
                    )}
                  </td>
                )}
              </>
            ))}
          </>
        )}
      </tr>
    );
  };

  //SELECT ALL LIST

  const allSelect = (allchecked: boolean, page: boolean) => {
    setDefaultChecked(allchecked);
    let AllIds: any = [];
    if (page) {
      AllIds = productArraySort.map(function (ids: any) {
        return ids._id;
      });
    } else {
      AllIds = [];
      setProductsAllSelected(false);
    }
    if (allchecked) {
      setProductsAllSelected(true);
    }
    setProductsListID(AllIds);
  };

  const isSelected = (id: string) => productsListID.indexOf(id) !== -1;

  return (
    <div>
      <Card>
        <div style={{ overflowX: "scroll" }}>
          {!props.loading ? (
            <>
              {!matches ? (
                <Table
                  responsive="xl"
                  striped
                  hover
                  style={{
                    border: "2px solid var(--color-input-border)",
                    maxWidth: "100vw",
                  }}
                >
                  {/* TABLE HEADER */}
                  <thead
                    style={{
                      marginBottom: "10px",
                      textAlign: "left",
                      backgroundColor: "rgba(0, 0, 0, 0.03)",
                    }}
                  >
                    <tr>
                      <th></th>
                      {tabla.map(createHeaderGroups)}
                    </tr>
                    <tr>
                      <th className="check-table">
                        <Container style={{ padding: 12 }}>
                          <Dropdown style={{ border: "none" }}>
                            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                              {/* <input type="checkbox" /> */}
                              {defaultChecked ? (
                                <img alt="" src={checkedimg} style={{ width: "20px" }}></img>
                              ) : (
                                <img alt="" src={uncheckedimg} style={{ width: "20px" }}></img>
                              )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <div onClick={() => allSelect(true, false)}>
                                <Dropdown.Item href="#/action-1">
                                  <img alt="" src={checkedimg}></img> {t("TABLES-SELECT-ALL")}
                                </Dropdown.Item>
                              </div>
                              <div onClick={() => allSelect(false, true)}>
                                <Dropdown.Item href="#/action-1">
                                  <img alt="" src={uncheckedpage}></img> {t("TABLES-SELECT-PAGE")}
                                </Dropdown.Item>
                              </div>
                              <div onClick={() => allSelect(false, false)}>
                                <Dropdown.Item href="#/action-3">
                                  <img alt="" src={uncheckedimg}></img> {t("TABLES-UNSELECT-PAGE")}
                                </Dropdown.Item>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Container>
                      </th>
                      {tabla?.map(createHeader)}
                    </tr>
                    <ProductTableFilters handleSearch={handleSearch} />
                    {/* TABLE SEARCH (filters) */}
                  </thead>
                  <tbody style={{ cursor: "pointer" }}>
                    {/* TABLE BODY */}
                    {productArraySort?.map((row: IRowProductFromAPI, index: number) => createRow(row, index, tabla))}
                  </tbody>
                </Table>
              ) : (
                <ProductTableResponsive render={productArraySort} Create={props.Create} handleProducts={handleProducts} />
              )}
            </>
          ) : (
            <LoadingSkeletonTables />
          )}

          {/* count -- numero de páginas
                page -- pagina actual
                onchange cambio de paginacion */}
        </div>
        <div className="pb-2" style={{ display: "flex", justifyContent: "space-around" }}>
          <Pagination
            style={{ paddingTop: 10 }}
            count={Math.ceil(lastPage / pageSize)}
            page={currentPage}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
            color="primary"
            boundaryCount={4} //number of buttons at the beginning and end
            siblingCount={currentPage > 50 ? 6 : 4} //number of buttons at the left and right
          />
        </div>
      </Card>
    </div>
  );
}
