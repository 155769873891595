import React, { useRef } from "react";
import SunEditor from "suneditor-react";
import SunEditorCore from "suneditor/src/lib/core";
import "./styles/TextEditorOriginal.css";
import "./styles/TextEditorCustoms.css";
//plugins
import plugins from "suneditor/src/plugins";
//language
import lang from "suneditor/src/lang";

const TextEditor = (props: any) => {
  const editor = useRef<SunEditorCore>();

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
    // editor.current.toolbar.disabled();
  };

  return (
    <SunEditor
      readOnly={props.readOnly}
      disable={props.readOnly}
      defaultValue={props.defaultValue}
      getSunEditorInstance={getSunEditorInstance}
      lang={lang.fr}
      height={props.height}
      onChange={props.handleChange}
      setOptions={{
        toolbarContainer: "#toolbar_container",
        showPathLabel: false,
        charCounter: true,
        plugins: plugins,
        imageGalleryUrl: "",
        imageUrlInput: false,
        linkNoPrefix: true,
        buttonList: [
          ["undo", "redo", "font", "fontSize", "formatBlock", "lineHeight"],
          ["bold", "underline", "italic", "strike", "subscript", "superscript", "removeFormat"],
          "/",
          ["fontColor", "hiliteColor", "outdent", "indent", "align", "horizontalRule", "list", "table"],
          ["link", "image", "video", "fullScreen", "showBlocks", "codeView", "preview", "print", "save"],
          ["image", "link", "video", "audio"],
        ],
        fontSize: [
          8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60,
        ],

        font: [
          "DIN Pro Cond Light",
          "DIN Pro Cond Medium",
          "DIN Pro Cond Bold",
          "DIN Pro Cond",
          "sans-serif",
          "Arial",
          "tohoma",
          "Courier New,Courier",
        ],
        resizingBar: false,
      }}
    />
  );
};

export default TextEditor;
