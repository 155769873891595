import { t } from "i18next";
import "./styles/notification.css";
export default function ExportNotification() {
  return (
    <div className="notification">
      <p>{t("HOVER.ALL.EXPORTING")}</p>
      <span className="notification__progress"></span>
    </div>
  );
}
