import InputClear from "../../atoms/InputClear";
import { useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../../constants/global.css";
import { faQuestionCircle, faSearch, faPlus, faTh, faTimes, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@mui/material/Tooltip";
import ValidationAlert from "../../atoms/ValidationAlert";
import DropDownCircles from "../../atoms/DropDownCircles";
import "../../../constants/responsive.css";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { Link } from "react-router-dom";
// Translations
import { useTranslation } from "react-i18next";
import ExchangesHistTable from "../ExchangesHistTable/ExchangesHistTable";
import GuardNotAuthorized from "../../atoms/ItemPermission/GuardNotAuthorized";
import { useSelector } from "react-redux";
import { IRootState } from "../../../store/redux";
import { typeUserData } from "../../../constants/userTypes";

export default function ExchangesIdentificationAndStatusTable(props: any) {
  const { setRefusalReasonGivenLabel, setRefusalReasonId } = useContext(ApplicationContext);

  const { warningAlertStatus } = props;
  // Translations
  const { t, i18n } = useTranslation();
  //Redux
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected, sub } = profile;

  const RenderWarnings = () => {
    return (
      <div>
        <ValidationAlert message={t("ALERT-GLOBAL-FILL-FIELDS")} variant="warning" />
      </div>
    );
  };

  return (
    <div className="card m-2">
      <div>
        <div className="m-3 sectionBoxTop py-2">
          <h4 className="h4-stl">{t("CREATE.EXCHANGES.TITLE-IDENTIFICATION-AND-STATUS")}</h4>
        </div>
        <div className="flex-inputs-responsive-exchange-input px-3 pb-3">
          <div className="full-width mt-4">
            <h4 className="h4-stl">
              <strong>{t("ALL.GENERAL-EXCHANGE.TITLE-NUMBER")} *</strong>
            </h4>
            <div className="flex-inputs-responsive-info ">
              <div className="full-width">
                <InputClear
                  type="text"
                  name=""
                  placeholder=""
                  readOnly
                  style={{
                    width: "100%",
                    background: "var(--color-input-disable)",
                  }}
                  currentValue={props.number}
                  handleOnInputValue={props.setNumber}
                />
              </div>
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  style={{
                    width: "2Opx",
                    height: "20px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="full-width mt-4">
            <h4 className="h4-stl">
              <strong>{t("ALL.GENERAL-EXCHANGE.TITLE-DATE")}</strong>
            </h4>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "90% 10%",
                alignItems: "center",
              }}
            >
              <InputClear
                type="text"
                name=""
                readOnly
                placeholder=""
                style={{
                  width: "100%",
                  background: "var(--color-input-disable)",
                }}
                currentValue={props.date}
                handleOnInputValue={props.setDate}
              />
              <FontAwesomeIcon
                icon={faTh}
                style={{
                  marginLeft: "8px",
                  width: "2Opx",
                  height: "20px",
                  float: "right",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-inputs-responsive-exchange-input px-3">
        <div className="full-width">
          <h4 className="h4-stl">
            <strong>{t("ALL.GENERAL-EXCHANGE.TITLE-STATUS")} *</strong>
          </h4>

          <DropDownCircles
            options={props.statusOptions}
            selectedItem={props.selectedStatus}
            onSelect={props.setSelectedStatus}
            width="100%"
            disabled={nameRolSelected === typeUserData.viewOnly}
          />
        </div>
        {props.createItemsRefusal && (
          <div className="full-width">
            <h4 className="h4-stl">
              <strong>{t("TABLE.REFUSAL-REASON.HEADER-LINK")}</strong>
            </h4>
            <div className="flex-inputs-responsive-info">
              <div className="full-width">
                <InputClear
                  type="text"
                  name=""
                  placeholder=""
                  readOnly
                  style={{
                    background: "var(--color-input-background-secondary)",
                  }}
                  currentValue={props.refusalReasonlabel}
                  handleOnInputValue={props.setRefusalReasonlabel}
                />
              </div>
              <GuardNotAuthorized>
                <div>
                  <div style={{ display: "flex", cursor: "pointer" }}>
                    <Tooltip title="Select" placement="bottom">
                      <FontAwesomeIcon
                        className="pointer"
                        icon={faSearch}
                        onClick={props.onClickSearch}
                        style={{
                          width: "2Opx",
                          height: "20px",
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Create" placement="bottom">
                      <FontAwesomeIcon
                        className="pointer"
                        icon={faPlus}
                        onClick={props.onClickCreate}
                        style={{
                          marginLeft: "8px",
                          width: "2Opx",
                          height: "20px",
                        }}
                      />
                    </Tooltip>

                    <Tooltip title="Remove" placement="bottom">
                      <FontAwesomeIcon
                        className="pointer"
                        icon={faTimes}
                        onClick={() => {
                          setRefusalReasonId("");
                          setRefusalReasonGivenLabel("");
                        }}
                        style={{
                          marginLeft: "8px",
                          width: "2Opx",
                          height: "20px",
                        }}
                      />
                    </Tooltip>
                    {props.selectedStatus && (
                      <Tooltip title="Open" placement="bottom">
                        <Link to={`${props.openLink}`} style={{ color: "var(--color-main-text)" }}>
                          <FontAwesomeIcon
                            className="pointer"
                            icon={faShare}
                            style={{
                              marginLeft: "8px",
                              width: "2Opx",
                              height: "20px",
                            }}
                          />
                        </Link>
                      </Tooltip>
                    )}
                  </div>
                </div>
              </GuardNotAuthorized>
            </div>
            {!props.refusalReasonlabel && warningAlertStatus && <RenderWarnings />}
          </div>
        )}
      </div>
      {!props.create ? (
        <div className="px-2 py-4">
          <ExchangesHistTable mainlink={true} exchangesArray={props.exchangesArray?.result.status} />
        </div>
      ) : (
        <div className="py-4"> </div>
      )}
    </div>
  );
}
