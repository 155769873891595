import { AxiosResponse, AxiosRequestConfig } from "axios";
import { axios as http } from "../../frameworks_and_drivers/Axios";
import HelpService from "../HelpService";
import { loadAbort } from "../../helpers/loadAbort";
import { BACKEND_URL } from "../../config/settings.json";
import { IReasonForExchangeList, IReasonForExchangeListById } from "../interfaces/interfaz_reasons_for_exchange";
import { downloadFileFromResponse } from "./helps";
import { fromLanguajeTranslatedToKey } from "../../utilities/languajeTraslations";

export const getAllReasonsForExchange = (props?: any) => {
  const controller = loadAbort();
  const { query } = props;
  const URL = `${BACKEND_URL}/reasons-for-exchange/get-all?` + HelpService.getQueryString(query);
  return {
    call: http.get<IReasonForExchangeList>(URL, { signal: controller.signal }),
    controller,
  };
};

export const getAllReasonsForExchangeByID = async (objectId: string) => {
  const URL = `${BACKEND_URL}/reasons-for-exchange/${objectId}/get-one`;
  const response = await http.get<IReasonForExchangeListById>(URL);
  return response;
};

export const updateReasonsForExchange = async (
  objectId: string,
  body: {
    label?: string;
    code_reason?: string;
    display_order?: string;
    language?: string;
    description?: string;
    is_for_change?: boolean;
  }
  // exchanges: any
) => {
  const URL = `${BACKEND_URL}/reasons-for-exchange/${objectId}/update-one`;
  const response = await http.put(URL, body);
  return response;
};

export const deleteReasonsForExchange = async (objectId: string) => {
  const URL = `${BACKEND_URL}/reasons-for-exchange/${objectId}/delete-one`;
  const response = await http.put(URL);
  return response;
};

export const createReasonsForExchange = async (
  label: string,
  code_reason: string,
  display_order: string,
  is_for_change: boolean,
  language: string,
  description: string
) => {
  const body = {
    label: label,
    code_reason: code_reason,
    display_order: display_order,
    is_for_change: is_for_change,
    language: language,
    description: description,
  };
  const URL = `${BACKEND_URL}/reasons-for-exchange/create-one`;
  const response = await http.post(URL, body);
  return response;
};

export const deleteManyReasonsForExchange = async (ids: string[]) => {
  const URL = `${BACKEND_URL}/reasons-for-exchange/delete-many`;
  const body = {
    items: ids,
  };
  const response = await http.put(URL, body);
  return response;
};

export const getExportReasonExchange = async (type: string) => {
  const URL = `${BACKEND_URL}/reasons-for-exchange/generate-report`;
  const opts: AxiosRequestConfig = {
    params: {
      export_type: type,
    },
    responseType: "blob",
  };
  const response = await http.get(URL, opts);
  const responseDownload = downloadFileFromResponse(response, type, "Reasons_for_exchange");
  return responseDownload;
};

export const getExportReasonExchangeByID = async (type: string, ids: string[], name: string, query?: any) => {
  const URL = `${BACKEND_URL}/reasons-for-exchange/create-report?` + HelpService.getQueryString(query);
  const body = {
    file_type: type,
    search: ids,
  };
  const opts: AxiosRequestConfig = {
    responseType: "blob",
  };
  const response = await http.post(URL, body, opts);
  const responseDownload = downloadFileFromResponse(response, type, name);
  return responseDownload;
};
interface IBulkReasonsExchange {
  language?: string;
  display_order?: string;
  is_for_change: boolean;
  description?: string;
  label:string;
  search?: string[];
}
export const updateInBulkReasonsExchange = async (body: IBulkReasonsExchange) => {
  body.language = fromLanguajeTranslatedToKey(body.language || "");
  const URL = `${BACKEND_URL}/reasons-for-exchange/bulk-update`;

  const response = await http.put(URL, body);
  return response;
};
