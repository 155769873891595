import { t } from "i18next";
import { Dispatch, MouseEventHandler, SetStateAction } from "react";
import { Button, Modal } from "react-bootstrap";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onClick: MouseEventHandler<HTMLButtonElement>;
  onClickCancel: MouseEventHandler<HTMLButtonElement>;
}

export default function ModalConfirm({ open, setOpen, onClick, onClickCancel }: Props) {
  return (
    <Modal
      show={open}
      onHide={() => {
        setOpen(false);
      }}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("MODAL.CUSTOMER-BUTTON-CONFIRM")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="h4-stl">
          {t("MODAL-DELETE-LABEL")}
        </h4>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClick} variant="primary">
          Ok
        </Button>
        <Button onClick={onClickCancel} variant="secondary">
          {t("MODAL.CUSTOMER-BUTTON-CANCEL")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
