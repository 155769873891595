import "bootstrap/dist/css/bootstrap.css";
import "../../../constants/global.css";
import Switch from "react-switch";
import "./ProductTableIdentifications.css";
import ValidationAlert from "../../atoms/ValidationAlert";
import { useTranslation } from "react-i18next";
import FileAttachement from "../../atoms/FileAttachement/FileAttachement";
import DownloadImage from "../../atoms/DownloadImage";
import ImagesApi from "../../pages/ExchangesInfo/ImagesApi";

import LoadingImageSkeleton from "../LoadingSkeleton/LoadingImageSkeleton";
import InputClearGuard from "../../atoms/InputClearGuard";
import { typeUserData } from "../../../constants/userTypes";
import DropDownGuard from "../../atoms/DropDownGuard";
import { useSelector } from "react-redux";
import { IRootState } from "../../../store/redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import DownloadImageUrl from "../../atoms/DownloadImageUrl";

export default function ProductTableIdentification(props: any) {
  //Translations
  const { t } = useTranslation();
  const { warningAlertStatus, fileName, handleChangeStatus, loadingPicture, picture, extension, handleDeleteImage } = props;

  //Redux
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected } = profile;

  const RenderWarnings = () => {
    return (
      <div>
        <ValidationAlert message={t("ALERT-GLOBAL-FILL-FIELDS")} variant="warning" />
      </div>
    );
  };
  return (
    <div className="card my-lg-4 my-0 mr-lg-2 mr-0">
      <div className="row my-4 px-lg-5 px-2">
        <div className="col-12 col-lg-12 sectionBoxTop py-2">
          <h4 className="h4-stl">{t("ALL.GENERAL.PRODUCT-HEADER-TITLE-IDENTIFICATION")}</h4>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 my-4">
            <h4 className="h4-stl">
              <strong>
                {t("ALL.GENERAL.PRODUCT.TITLE-REFERENCE")} <span style={{ color: "var(--color-required)" }}>*</span>
              </strong>
            </h4>
            <div style={{}}>
              <InputClearGuard
                type="text"
                name=""
                placeholder=""
                style={{ width: "100%" }}
                currentValue={props.reference}
                handleOnInputValue={props.setReference}
                roles={[`${typeUserData.admin}`]}
              />
              {!props.reference && warningAlertStatus && <RenderWarnings />}
            </div>
          </div>
          <div className="col-12 col-lg-6 my-4">
            <h4 className="h4-stl">
              <strong>
                {t("ALL.GENERAL.PRODUCT.TITLE-NAME")} <span style={{ color: "var(--color-required)" }}>*</span>
              </strong>
            </h4>
            <div style={{}}>
              <InputClearGuard
                type="text"
                name=""
                placeholder=""
                style={{ width: "100%" }}
                currentValue={props.name}
                handleOnInputValue={props.setName}
                roles={[`${typeUserData.admin}`]}
              />
            </div>
            {!props.name && warningAlertStatus && <RenderWarnings />}
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 my-4">
            <h4 className="h4-stl">
              <strong>{t("CREATE.PRODUCT.INPUT-OLD-REFERENCE")} </strong>
            </h4>
            <div style={{}}>
              <InputClearGuard
                type="text"
                name=""
                placeholder=""
                style={{ width: "100%" }}
                currentValue={props.oldReference}
                handleOnInputValue={props.setOldReference}
                roles={[`${typeUserData.admin}`]}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-7 my-4">
            <h4 className="h4-stl">
              <strong>
                {t("ALL.GENERAL.PRODUCT.TITLE-TYPE")} <span style={{ color: "var(--color-required)" }}>*</span>
              </strong>
            </h4>
            <div style={{ display: "flex", alignItems: "center" }}>
              <DropDownGuard
                options={props.listTypeOptions}
                selectedItem={props.selectedType}
                onSelect={props.setSelectedType}
                width="100%"
                roles={[`${typeUserData.admin}`]}
              />
            </div>
            {!props.selectedType && warningAlertStatus && <RenderWarnings />}
          </div>
          <div className="col-12 col-lg-3 my-4">
            <h4 className="h4-stl">
              <strong>
                {t("CREATE.PRODUCT.INPUT-DEFAULT")}? <span style={{ color: "var(--color-required)" }}>*</span>
              </strong>
            </h4>
            <Switch
              checked={props.checked}
              onChange={props.handleChange}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={20}
              width={48}
              className="react-switch"
              id="material-switch"
              disabled={nameRolSelected !== typeUserData.admin}
            />
          </div>
        </div>
        <div className="row">
          <h4 className="h4-stl">
            <strong>{t("ALL.GENERAL.PRODUCT.TITLE-PICTURE")}</strong>
          </h4>
          <div className="col-12 col-lg-12 my-4" style={{ overflow: "hidden" }}>
            <FileAttachement
              fileName={fileName}
              handleChangeStatus={handleChangeStatus}
              disabled={nameRolSelected !== typeUserData.admin}
            />

            <div className="row my-4 px-4" style={{ textAlign: "center" }}>
              <ImagesApi image={picture} fullWidth />
            </div>
            <div style={{ display: "flex" }}>
              <DownloadImageUrl image={picture} name="picture1" style={{ marginRight: 10 }} />

              <FontAwesomeIcon style={{ cursor: "pointer", marginTop: "4px" }} icon={faTrashAlt} onClick={handleDeleteImage} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
