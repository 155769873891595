import { IBrandsListPage } from "../../../api/interfaces/interfaz_brands";

export const createAddapterBrand = (item: IBrandsListPage[]) => {
  const array: IBrandsListPage[] = [];
  item?.map((item: IBrandsListPage) => {
    return array.push({
      id: item.id,
      name: item.name,
      code: item.code,
      website: item.website,
      description: item.description,
      products: item.products,
      logo: item.logo,
    });
  });
  return array;
};
