import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
interface IDownload {
  extension: any;
  image: any;
  name: string;
  style?: any;
}
export default function DownloadImage(props: IDownload) {
  const { extension, image, name, style } = props;
  return (
    <a
      style={style}
      download={extension === "image/png" ? `${name}.png` : extension === "image/jpg" ? `${name}.jpg` : `${name}.jpeg`}
      href={
        extension === "image/png"
          ? `data:image/png;base64,${image}`
          : extension === "image/jpg"
          ? `data:image/jpg;base64,${image}`
          : `data:image/jpeg;base64,${image}`
      }
    >
      <FontAwesomeIcon
        icon={faDownload}
        style={{
          marginLeft: "20px",
          paddingTop: "5px",
          width: "2Opx",
          height: "20px",
          color: "black",
        }}
      />
    </a>
  );
}
