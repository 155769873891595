import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./style.css";
import { IRowUsersFromAPI } from "../../../organisms/UsersTable/UsersTable";
import { typeUserData } from "../../../../constants/userTypes";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import userIcon from "../../../../images/userIcon.svg";

export default function UsersCardResponsive({ render }: { render: any }) {
  const { t } = useTranslation();
  const history = useHistory();
  const matchesPhone = useMediaQuery("(max-width:500px)");
  const [expanded, setExpanded] = useState(false);
  return (
    <Accordion
      sx={{
        backgroundColor: "#f7f7f7",
        borderRadius: "0.3rem 0.3rem",
        border: "solid 0.0625rem #e0e0e0",
        margin: "8px",
      }}
      expanded={matchesPhone ? expanded : true}
    >
      <AccordionSummary
        expandIcon={matchesPhone ? <ExpandMoreIcon onClick={() => setExpanded((prev: boolean) => !prev)} /> : <></>}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div
          style={{ maxWidth: "230px", overflow: "hidden" }}
          className="flex-warranty flex-between"
          onClick={() => {
            history.push(`/user-info/${render?.id}`);
          }}
        >
          <div className="container-warranty flex-start">
            <img
              alt=""
              src={userIcon}
              style={{
                width: "30px",
                height: "30px",
              }}
            />

            <div>
              <h6 className="h6-stl" style={{ margin: "15px 5px" }}>
                <strong>
                  {render?.first_name} {render?.last_name}
                </strong>
              </h6>
            </div>
          </div>
          <div className="container-warranty flex-end wrap-warranty"></div>
        </div>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: "#fff",
          fontSize: "12px",
        }}
      >
        <div>
          <ul>
            <li>
              <h6 className="font-response">
                <strong>{t("ALL.GENERAL-USER.TITLE-LOGIN")}: </strong>
                {render?.login}
              </h6>
            </li>
            <li>
              <h6 className="font-response">
                <strong>{t("ALL.GENERAL-USER.TITLE-FIRST-NAME")}: </strong>
                {render?.first_name}
              </h6>
            </li>
            <li>
              <h6 className="font-response">
                <strong>{t("ALL.GENERAL-USER.TITLE-NAME")}: </strong>
                {render?.last_name}
              </h6>
            </li>
            <li>
              <h6 className="font-response">
                <strong>{t("ALL.GENERAL-USER.TITLE-EMAIL")}: </strong>
                {render?.email}
              </h6>
            </li>

            <li className="changelog">
              <h6 className="font-response">
                <strong>{t("ALL.GENERAL-USER.TITLE-ACTIVE")}: </strong>
                {render?.status === "Disabled" ? (
                  <>{t("HOME.MENU.USERS-DISABLED")}</>
                ) : render?.status === "Enabled" ? (
                  <>{t("HOME.MENU.USERS-ENABLED")}</>
                ) : (
                  ""
                )}
              </h6>
            </li>
            <li className="changelog">
              <h6 className="font-response">
                <strong>{t("TABLE-GROUP-ROLE")}: </strong>
                <span style={{ marginLeft: "8px" }}>
                  {render?.role_data[0]?.name === typeUserData.admin
                    ? `${t("ROLES-ADMIN")}`
                    : render?.role_data[0]?.name === typeUserData.distributor
                    ? `${t("ROLES-DISTRIBUTOR")}`
                    : render?.role_data[0]?.name === typeUserData.group
                    ? `${t("ROLES-GROUP")}`
                    : render?.role_data[0]?.name === typeUserData.afterSales
                    ? `${t("ROLES-AFTERSALES")}`
                    : `${t("ROLES-CUSTOMERS")}`}
                </span>
              </h6>
            </li>
            <li className="changelog">
              <h6 className="font-response">
                <strong>{t("ALL.GENERAL-USER.TITLE-DISTRIBUTOR-CODE")}: </strong>
                {render?.distributorCode}
              </h6>
            </li>
            <li className="changelog">
              <h6 className="font-response">
                <strong>{t("ALL.GENERAL-USER.TITLE-GROUP-CODE")}: </strong>
                {render?.groupCode}
              </h6>
            </li>
          </ul>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
