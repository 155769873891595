import { faBars, faCity, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useContext } from "react";
import { Breadcrumb, Card, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { Menu, MenuItem, useMediaQuery } from "@mui/material";
import Fade from "@mui/material/Fade";
import Reloadimg from "../../../../images/reload.svg";
import copyImg from "../../../../images/copy.svg";
import exportImg from "../../../../images/export.svg";
import preferencesImg from "../../../../images/preferences.svg";
import deleteImg from "../../../../images/delete.svg";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { IRootState } from "../../../../store/redux";
import { typeUserData } from "../../../../constants/userTypes";
import { ApplicationContext } from "../../../../context/ApplicationContext";
import TableDistributorSet from "../../../organisms/TableDistributorSet/TableDistributorSet";
import ModalExport from "../../../atoms/ModalExport";
import "./style.css";
import DistributorSetSearch from "../../../organisms/Search/DistributorSetSearch";
import { useDistributorSet } from "../../../../hooks/DistributorSet/useDistributorSet";
import DistributorSetTableTransfer from "../../../templates/TableTranfers/DistributorSetTableTransfer";
import bulkimg from "../../../../images/bulk.svg";
import { useFilterContext } from "../../../../context";
import GuardNotAuthorized from "../../../atoms/ItemPermission/GuardNotAuthorized";

export default function DistributorSetList(props: any) {
  const { t } = useTranslation();
  let history = useHistory();

  const { distributorSetLength, distributorSetListId, setOpenMembersOfSetSelect, distributorSetAllSelected, setAlertDeleteDistributorSet } =
    useContext(ApplicationContext);
  //Redux
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected } = profile;

  //filters
  const {
    inputGroupCodeSearchDistributorSet,
    inputGroupNameSearchDistributorSet,
    inputNameSearchDistributorSet,
    inputOriginSearch,
    inputMembersSearch,
    showLateralContainerSearch,
    setShowLateralContainerSearch,
  } = useFilterContext();

  //states
  const [openConfirm, setOpenConfirm] = useState(false);
  const [showSearchTable, setShowSearchTable] = useState(false);
  //menu fade
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  //Modal
  const [openExport, setOpenExport] = useState(false);
  // Checkbox
  const [checkNeedToExport, setCheckNeedToExport] = useState<boolean[]>([false, true, false]);
  const [checkFormatToExport, setCheckFormatToExport] = useState<boolean[]>([true, false, false, false]);
  const [checkedType, setCheckedType] = useState<string>("csv");

  //modal search
  const [searchWindowState, SetSearchWindowState] = useState("filterList");

  const matches = useMediaQuery("(max-width:500px)");

  //Filter Search
  const listSort: string[] = [
    t("ALL.GENERAL.TABLE.DIST-SET.TITLE-CODE"),
    t("ALL.GENERAL.TABLE.DIST-SET.TITLE-NAME"),
    t("ALL.GENERAL.TABLE.DIST-SET.TITLE-ORIGIN"),
    t("ALL.GENERAL.TABLE.DIST-SET.TITLE-MEMBERS"),
  ];

  const [dropDownWhySort, setDropDownWhySort] = useState<string>(listSort[0]);
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const {
    distributorSetArray,
    loading,
    exportDistributorSet,
    deleteDistributorSet,
    wrongAlertStatus,
    setWrongAlertStatus,
    currentPage,
    lastPage,
    loadDistributors,
    handleChange,
    limit,
    onSearch,

    typeSort,
    incrementCount,
  } = useDistributorSet(
    inputGroupCodeSearchDistributorSet,
    inputGroupNameSearchDistributorSet,
    inputNameSearchDistributorSet,
    inputOriginSearch,
    inputMembersSearch,
    props.groupId
  );

  const exportFunction = () => {
    try {
      exportDistributorSet(checkedType, distributorSetListId, checkNeedToExport);
    } finally {
      setOpenConfirm(false);
    }
  };

  const deleteFunction = () => {
    try {
      deleteDistributorSet(distributorSetListId);
    } finally {
      setOpenConfirm(false);
    }
  };

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function copy() {
    const url = document.createElement("input");
    url.value = window.location.href;
    navigator.clipboard.writeText(url.value);
    setAnchorEl(null);
  }

  const renderLateralContainerSearch = () => {
    return (
      <DistributorSetSearch
        handleSearch={() => onSearch()}
        showLateralContainerSearch={showLateralContainerSearch}
        setShowLateralContainerSearch={setShowLateralContainerSearch}
        SetSearchWindowState={SetSearchWindowState}
        searchWindowState={searchWindowState}
        listSort={listSort}
        dropDownWhySort={dropDownWhySort}
        setDropDownWhySort={setDropDownWhySort}
      />
    );
  };

  const showModal = () => {
    return (
      <>
        <Modal
          show={openConfirm}
          onHide={() => {
            setOpenConfirm(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("INFO.USER.RESET-CONFIRM")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-lg-12 p-4">
                <h4 className="h4-stl">{t("ALL-INFO.DELETE-CONFIRM")}</h4>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                deleteFunction();
              }}
              variant="success"
            >
              {t("INFO.USER.RESET-BUTTON-CONFIRM")}
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setOpenConfirm(false);
              }}
            >
              {t("INFO.USER.RESET-BUTTON-CANCEL")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const showModalExport = () => {
    return (
      <ModalExport
        openExport={openExport}
        setOpenExport={setOpenExport}
        checkNeedToExport={checkNeedToExport}
        setCheckNeedToExport={setCheckNeedToExport}
        checkFormatToExport={checkFormatToExport}
        setCheckFormatToExport={setCheckFormatToExport}
        checkedType={checkedType}
        setCheckedType={setCheckedType}
        onClick={() => {
          setOpenExport(false);
          exportFunction();
        }}
      />
    );
  };

  return (
    <div style={{ margin: 0, padding: 0 }}>
      {!props.infoItem && (
        <Breadcrumb>
          <Breadcrumb.Item href="#">{t("HOME.MENU.DISTRIBUTOR-SETS")}</Breadcrumb.Item>
        </Breadcrumb>
      )}
      <Card>
        {matches ? (
          <Card.Header>
            <div className="flex-stl flex-between">
              <div className="flex-stl flex-center">
                <FontAwesomeIcon className="icon_size" icon={faCity} />
                <div style={{ marginLeft: "8px" }}>
                  <h5 className="h5-stl">{t("HOME.MENU.DISTRIBUTOR-SETS")}</h5>
                  <h5 style={{ fontSize: 12 }}>
                    {t("TABLE.DISTRIBUTOR.HEADER-TOTAL")} {`${distributorSetLength}`}
                  </h5>
                </div>
              </div>

              <div className="flex-stl flex-center">
                {nameRolSelected === typeUserData.admin && (
                  <>
                    {!props.toSelect ? (
                      <Link to="/create-distributors-set">
                        <Button variant="primary">{t("LIST.ALL.BUTTON-CREATE")}</Button>
                      </Link>
                    ) : (
                      <Button
                        variant="primary"
                        onClick={() => {
                          setOpenMembersOfSetSelect(false);
                        }}
                      >
                        {t("LIST.ALL.BUTTON-SELECT")}
                      </Button>
                    )}
                  </>
                )}
                <div style={{ cursor: "pointer" }}>
                  <FontAwesomeIcon
                    className="grayBackgr iconbar_size"
                    icon={faSearch}
                    onClick={() => setShowLateralContainerSearch(true)}
                  />
                </div>
                {!props.toSelect && (
                  <div onClick={handleClick}>
                    <FontAwesomeIcon
                      className="grayBackgr"
                      icon={faBars}
                      style={{
                        width: "50px",
                        height: "50px",
                      }}
                    />
                  </div>
                )}
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem
                    onClick={() => {
                      history.go(0);
                    }}
                    style={{ fontSize: "15px" }}
                  >
                    <img alt="" src={Reloadimg} style={{ width: "20px", marginRight: "10px" }}></img>
                    {t("ALL.MENU.RELOAD")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setOpenTransferModal(true);
                    }}
                    style={{ fontSize: "15px" }}
                  >
                    <img alt="" src={preferencesImg} style={{ width: "20px", marginRight: "10px" }}></img>
                    {t("ALL-BUTTON-PREFERENCES")}
                  </MenuItem>
                  <GuardNotAuthorized>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setOpenExport(true);
                      }}
                      style={{ fontSize: "15px" }}
                    >
                      <img alt="" src={exportImg} style={{ width: "20px", marginRight: "10px" }} />
                      {t("NAV-ALL-ITEM3")}
                      <span
                        style={{
                          background: "var( --color-accepted)",
                          marginLeft: "6px",
                          color: "var(--color-main-text)",
                          borderRadius: "20px",
                          padding: "4px 8px",
                          fontSize: "12px",
                        }}
                      >
                        <b>{distributorSetAllSelected ? distributorSetLength : distributorSetListId?.length}</b>
                      </span>
                    </MenuItem>
                  </GuardNotAuthorized>
                  {nameRolSelected === typeUserData.admin && (
                    <div>
                      {distributorSetListId.length || distributorSetAllSelected ? (
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setOpenConfirm(true);
                          }}
                          style={{ fontSize: "15px" }}
                        >
                          <img alt="" src={deleteImg} style={{ width: "20px", marginRight: "10px" }}></img>
                          {t("ALL.MENU.DELETE")}
                          <span
                            style={{
                              background: "var( --color-accepted)",
                              marginLeft: "6px",
                              color: "var(--color-main-text)",
                              borderRadius: "20px",
                              padding: "4px 8px",
                              fontSize: "12px",
                            }}
                          >
                            <b>{distributorSetAllSelected ? distributorSetLength : distributorSetListId?.length}</b>
                          </span>
                        </MenuItem>
                      ) : (
                        <MenuItem disabled style={{ fontSize: "15px" }}>
                          <img alt="" src={deleteImg} style={{ width: "20px", marginRight: "10px" }}></img>
                          {t("ALL.MENU.DELETE")}
                        </MenuItem>
                      )}
                    </div>
                  )}
                  <MenuItem onClick={copy} style={{ fontSize: "15px" }}>
                    <img alt="" src={copyImg} style={{ width: "20px", marginRight: "10px" }}></img>
                    {t("ALL.MENU.COPY-LINK")}
                  </MenuItem>
                  {/* <MenuItem onClick={handleClose} style={{fontSize:'15px'}}>Logout</MenuItem> */}
                </Menu>
              </div>
            </div>
          </Card.Header>
        ) : (
          <>
            <Card.Header>
              <div className="flex-warranty flex-between">
                <div className="container-warranty flex-start">
                  <FontAwesomeIcon className="icon_size" icon={faCity} />
                  <div>
                    <h5 style={{ fontSize: 16 }}>{t("HOME.MENU.DISTRIBUTOR-SETS")}</h5>
                    <h5 style={{ fontSize: 12 }}>Total: {distributorSetLength}</h5>
                  </div>
                </div>
                <div className="container-warranty flex-end wrap-warranty">
                  {nameRolSelected === typeUserData.admin && (
                    <>
                      {!props.toSelect ? (
                        <Link to="/create-distributors-set">
                          <Button variant="primary">{t("LIST.ALL.BUTTON-CREATE")}</Button>
                        </Link>
                      ) : (
                        <Button
                          variant="primary"
                          onClick={() => {
                            setOpenMembersOfSetSelect(false);
                          }}
                        >
                          {t("LIST.ALL.BUTTON-SELECT")}
                        </Button>
                      )}
                    </>
                  )}
                  <div style={{ cursor: "pointer" }}>
                    <FontAwesomeIcon
                      className="grayBackgr iconbar_size"
                      icon={faSearch}
                      onClick={() => setShowLateralContainerSearch(true)}
                    />
                  </div>
                  {!props.toSelect && (
                    <div onClick={handleClick}>
                      <FontAwesomeIcon
                        className="grayBackgr"
                        icon={faBars}
                        style={{
                          width: "50px",
                          height: "50px",
                        }}
                      />
                    </div>
                  )}
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                    <MenuItem
                      onClick={() => {
                        history.go(0);
                      }}
                      style={{ fontSize: "15px" }}
                    >
                      <img alt="" src={Reloadimg} style={{ width: "20px", marginRight: "10px" }}></img>
                      {t("ALL.MENU.RELOAD")}
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setOpenTransferModal(true);
                      }}
                      style={{ fontSize: "15px" }}
                    >
                      <img alt="" src={preferencesImg} style={{ width: "20px", marginRight: "10px" }}></img>
                      {t("ALL-BUTTON-PREFERENCES")}
                    </MenuItem>
                    <GuardNotAuthorized>
                      <div>
                        {distributorSetListId.length ? (
                          <MenuItem
                            onClick={() => {
                              history.replace("/distributor-set-bulk");
                              handleClose();
                            }}
                            style={{ fontSize: "15px" }}
                          >
                            <img
                              alt=""
                              src={bulkimg}
                              style={{
                                width: "20px",
                                marginRight: "10px",
                              }}
                            ></img>
                            {t("TABLE.HEADER-OPTIONS-LIST-BULK")}
                            <span
                              style={{
                                background: "var( --color-accepted)",
                                marginLeft: "6px",
                                color: "var(--color-main-text)",
                                borderRadius: "20px",
                                padding: "4px 8px",

                                fontSize: "12px",
                              }}
                            >
                              <b>{distributorSetListId?.length}</b>
                            </span>
                          </MenuItem>
                        ) : (
                          <MenuItem disabled style={{ fontSize: "15px" }}>
                            <img
                              alt=""
                              src={bulkimg}
                              style={{
                                width: "20px",
                                marginRight: "10px",
                              }}
                            ></img>
                            {t("TABLE.HEADER-OPTIONS-LIST-BULK")}
                          </MenuItem>
                        )}
                      </div>
                    </GuardNotAuthorized>
                    <GuardNotAuthorized>
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          setOpenExport(true);
                        }}
                        style={{ fontSize: "15px" }}
                      >
                        <img alt="" src={exportImg} style={{ width: "20px", marginRight: "10px" }} />
                        {t("NAV-ALL-ITEM3")}
                        <span
                          style={{
                            background: "var( --color-accepted)",
                            marginLeft: "6px",
                            color: "var(--color-main-text)",
                            borderRadius: "20px",
                            padding: "4px 8px",
                            fontSize: "12px",
                          }}
                        >
                          <b>{distributorSetAllSelected ? distributorSetLength : distributorSetListId?.length}</b>
                        </span>
                      </MenuItem>
                    </GuardNotAuthorized>
                    {nameRolSelected === typeUserData.admin && (
                      <div>
                        {distributorSetListId.length || distributorSetAllSelected ? (
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              setOpenConfirm(true);
                            }}
                            style={{ fontSize: "15px" }}
                          >
                            <img alt="" src={deleteImg} style={{ width: "20px", marginRight: "10px" }}></img>
                            {t("ALL.MENU.DELETE")}
                            <span
                              style={{
                                background: "var( --color-accepted)",
                                marginLeft: "6px",
                                color: "var(--color-main-text)",
                                borderRadius: "20px",
                                padding: "4px 8px",
                                fontSize: "12px",
                              }}
                            >
                              <b>{distributorSetAllSelected ? distributorSetLength : distributorSetListId?.length}</b>
                            </span>
                          </MenuItem>
                        ) : (
                          <MenuItem disabled style={{ fontSize: "15px" }}>
                            <img alt="" src={deleteImg} style={{ width: "20px", marginRight: "10px" }}></img>
                            {t("ALL.MENU.DELETE")}
                          </MenuItem>
                        )}
                      </div>
                    )}
                    <MenuItem onClick={copy} style={{ fontSize: "15px" }}>
                      <img alt="" src={copyImg} style={{ width: "20px", marginRight: "10px" }}></img>
                      {t("ALL.MENU.COPY-LINK")}
                    </MenuItem>
                    {/* <MenuItem onClick={handleClose} style={{fontSize:'15px'}}>Logout</MenuItem> */}
                  </Menu>
                </div>
              </div>
            </Card.Header>
          </>
        )}
        <Card.Body>
          <div className="box-warning">
            <h6 style={{ fontWeight: "400" }}>{t("DISTRIBUTOR-SET-ALERT-1")}</h6>
            <h6 style={{ fontWeight: "400" }}>{t("DISTRIBUTOR-SET-ALERT-2")}</h6>
          </div>
          <TableDistributorSet
            distributorSetArray={distributorSetArray}
            showSearchTable={showSearchTable}
            rowsPerPage={limit}
            UsePlanText="false"
            inputToSort={dropDownWhySort}
            loading={loading}
            currentPage={currentPage}
            lastPage={lastPage}
            handleChange={handleChange}
            incrementCount={incrementCount}
            typeSort={typeSort}
            //filerts
            handleSearch={() => onSearch()}
            setDropDownWhySort={setDropDownWhySort}
            toSelect={props.toSelect}
          />
        </Card.Body>
      </Card>
      {showModal()}
      {showModalExport()}
      {renderLateralContainerSearch()}
      <DistributorSetTableTransfer open={openTransferModal} setOpen={setOpenTransferModal} />
    </div>
  );
}
