import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHammer, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import ImagesApi from "../../../pages/ExchangesInfo/ImagesApi";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { IRowRforRefusalFromAPI } from "../../../organisms/TableRef";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";

export default function ReasonForRefusalCardResponsive({ render }: { render: any }) {
  const { t } = useTranslation();
  const history = useHistory();
  const matchesPhone = useMediaQuery("(max-width:500px)");
  const [expanded, setExpanded] = useState(false);
  return (
    <Accordion
      sx={{
        backgroundColor: "#f7f7f7",
        borderRadius: "0.3rem 0.3rem",
        border: "solid 0.0625rem #e0e0e0",
        margin: "8px",
      }}
      expanded={matchesPhone ? expanded : true}
    >
      <AccordionSummary
        expandIcon={matchesPhone ? <ExpandMoreIcon onClick={() => setExpanded((prev: boolean) => !prev)} /> : <></>}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div
          style={{ maxWidth: "230px", overflow: "hidden" }}
          className="flex-warranty flex-between"
          onClick={() => {
            history.push(`/refusal-reasons-info/${render?.id}`);
          }}
        >
          <div className="container-warranty flex-start">
            <FontAwesomeIcon className="icon_size" icon={faTimesCircle} />

            <div>
              <h6 className="h6-stl" style={{ margin: "15px 5px" }}>
                <strong>{render?.code_reason}</strong>
              </h6>
            </div>
          </div>
          <div className="container-warranty flex-end wrap-warranty"></div>
        </div>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: "#fff",
          fontSize: "12px",
        }}
      >
        <div>
          <ul>
            <li>
              <h6 className="font-response">
                <strong>{t("ALL.GENERAL-REFUSAL-REASON.TITLE-CODE")}: </strong>
                {render?.code_reason}
              </h6>
            </li>
            <li>
              <h6 className="font-response">
                <strong>{t("ALL.GENERAL-REFUSAL-REASON.TITLE-LANGUAJE")}: </strong>
                {render?.language}
              </h6>
            </li>

            <li>
              <h6 className="font-response">
                <strong>{t("ALL.GENERAL-REFUSAL-REASON.TITLE-DISPLAY-ORDER")}: </strong>
                {render?.display_order}
              </h6>
            </li>
            <li>
              <h6 className="font-response">
                <strong>{t("ALL.GENERAL-REASON-EXCHANGE.TITLE-LABEL")}: </strong>
                {render?.label}
              </h6>
            </li>
          </ul>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
