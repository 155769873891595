import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight, faBoxes, faHotel } from "@fortawesome/free-solid-svg-icons";
import ImagesApi from "../../../pages/ExchangesInfo/ImagesApi";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import changeLogIcon from "../../../../images/changeLogIcon.svg";
import { IChangeLogFromAPI } from "../../../organisms/ChangeLogTable/ChangelogTable";
import { removeTags } from "../../../../helpers/removeHtml";
import "./style.css";
export default function ChangeLogTableResponsive({ render }: { render: IChangeLogFromAPI[] }) {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div>
      {render?.map((row: IChangeLogFromAPI) => (
        <Accordion
          sx={{
            backgroundColor: "#f7f7f7",
            borderRadius: "0.3rem 0.3rem",
            border: "solid 0.0625rem #e0e0e0",
            margin: "8px",
            maxHeight: "250px",
            overflowY: "scroll",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <div
              style={{ maxWidth: "230px", overflow: "hidden" }}
              className="flex-warranty flex-between"
              onClick={() => {
                history.push(`/change-log-info/${row._id}`);
              }}
            >
              <div className="container-warranty flex-start">
                <img alt="" src={changeLogIcon} style={{ width: "50px" }} />

                <div>
                  <h6 className="h6-stl" style={{ margin: "15px 5px" }}>
                    <strong>{row.date}</strong>
                  </h6>
                </div>
              </div>
              <div className="container-warranty flex-end wrap-warranty"></div>
            </div>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: "#fff",
              fontSize: "12px",
            }}
          >
            <div>
              <ul>
                <li>
                  <h6 className="font-response">
                    <strong>{`${t("ALL.GENERAL-CHANGE-LOG.TITLE-LOGIN")}`}: </strong>
                    {row.login}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{`${t("ALL.GENERAL-CHANGE-LOG.TITLE-USER")}`}: </strong>
                    {row.user}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{`${t("ALL.GENERAL-CHANGE-LOG.TITLE-DATE")}`}: </strong>
                    {row.date}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{`${t("ALL.GENERAL-CHANGE-LOG.TITLE-OBJECT")}`}: </strong>

                    {row.resource === "brand" ? (
                      <div style={{ paddingRight: "0" }}>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.resource}
                        </h6>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.after?.code}
                        </h6>
                      </div>
                    ) : row.resource === "product" ? (
                      <div style={{ paddingRight: "0" }}>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.resource}
                        </h6>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.after?.reference}
                        </h6>
                      </div>
                    ) : row.resource === "group" ? (
                      <div style={{ paddingRight: "0" }}>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.resource}
                        </h6>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.after?.name}
                        </h6>
                      </div>
                    ) : row.resource === "exchange" ? (
                      <div style={{ paddingRight: "0" }}>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.resource}
                        </h6>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.resource}
                        </h6>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.after?.number}
                        </h6>
                      </div>
                    ) : // Card que faltan
                    row.resource === "customer" ? (
                      <div style={{ paddingRight: "0" }}>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.resource}
                        </h6>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.before?.username}
                        </h6>
                      </div>
                    ) : row.resource === "distributor" ? (
                      <div style={{ paddingRight: "0" }}>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.resource}
                        </h6>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.after?.name}
                        </h6>
                      </div>
                    ) : row.resource === "reason_for_refusal" ? (
                      <div style={{ paddingRight: "0" }}>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.resource}
                        </h6>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.after?.codeReason}
                        </h6>
                      </div>
                    ) : row.resource === "reason_for_exchange" ? (
                      <div style={{ paddingRight: "0" }}>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.resource}
                        </h6>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.after?.label}
                        </h6>
                      </div>
                    ) : row.resource === "user" ? (
                      <div style={{ paddingRight: "0" }}>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.resource}
                        </h6>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.after?.username}
                        </h6>
                      </div>
                    ) : row.resource === "group" ? (
                      <div style={{ paddingRight: "0" }}>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.resource}
                        </h6>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.after?.name}
                        </h6>
                      </div>
                    ) : row.resource === "content" ? (
                      <div style={{ paddingRight: "0" }}>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.resource}
                        </h6>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.after?.name}
                        </h6>
                      </div>
                    ) : row.resource === "comment" ? (
                      <div style={{ paddingRight: "0" }}>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.resource}
                        </h6>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.after?.id}
                        </h6>
                      </div>
                    ) : row.resource === "distributor_set" ? (
                      <div style={{ paddingRight: "0" }}>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.resource}
                        </h6>
                        <h6
                          style={{
                            fontSize: "12px",
                            marginLeft: "25px",
                          }}
                        >
                          {row.after?.name}
                        </h6>
                      </div>
                    ) : (
                      <></>
                    )}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{`${t("ALL.GENERAL-CHANGE-LOG.TITLE-ACTION")}`}: </strong>
                    {row.action}
                  </h6>
                </li>
                <li className="changelog">
                  <h6 className="font-response">
                    <strong>{`${t("ALL.GENERAL-CHANGE-LOG.TITLE-ACTIVITY")}`}: </strong>
                    <>
                      <h6 style={{ fontSize: "12px" }}>{row.date}</h6>
                      <h6 style={{ fontSize: "12px" }}>
                        <strong>{row.msg}</strong>
                      </h6>
                      {row.changes?.map((changesToMap: any, index: number) => {
                        const pointIndex = changesToMap.field.indexOf(".") === -1 ? 0 : changesToMap.field.indexOf(".") + 1;
                        return (
                          <div key={`ch${index}-${changesToMap.field}`}>
                            {changesToMap.after === "null" ? (
                              ""
                            ) : changesToMap.after === null ? (
                              ""
                            ) : changesToMap.after === "undefined" ? (
                              ""
                            ) : changesToMap.field === "updatedAt" ? (
                              ""
                            ) : changesToMap.field === "createdAt" ? (
                              ""
                            ) : (
                              <div>
                                {changesToMap.after && (
                                  <tr className="table-stl-td">
                                    <td
                                      style={{
                                        minWidth: "0 !important",
                                      }}
                                    >
                                      <strong>{changesToMap.field.slice(pointIndex, changesToMap.field.length)}</strong>
                                    </td>
                                    <td
                                      style={
                                        changesToMap.before
                                          ? {
                                              minWidth: "0 !important",
                                            }
                                          : {
                                              minWidth: "0 !important",
                                            }
                                      }
                                    >
                                      {changesToMap.before === "null" ? (
                                        ""
                                      ) : changesToMap.before === null ? (
                                        ""
                                      ) : (
                                        <>
                                          {changesToMap.before && (
                                            <>
                                              {changesToMap.field.slice(pointIndex, changesToMap.field.length) === "fileContent" ? (
                                                "file"
                                              ) : changesToMap.field.slice(pointIndex, changesToMap.field.length) === "logo" ? (
                                                <img src={changesToMap.before} alt="img" style={{ maxWidth: "100px" }} />
                                              ) : changesToMap.field.slice(pointIndex, changesToMap.field.length) === "picture" ? (
                                                <img src={changesToMap.before} alt="img" style={{ maxWidth: "100px" }} />
                                              ) : changesToMap.field.slice(pointIndex, changesToMap.field.length) === "brochure" ? (
                                                <img src={changesToMap.before} alt="img" style={{ maxWidth: "100px" }} />
                                              ) : (
                                                <>{removeTags(changesToMap.before)}</>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </td>
                                    <td style={{ minWidth: "0 !important" }}>
                                      <FontAwesomeIcon
                                        icon={faArrowAltCircleRight}
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: "5px",
                                        }}
                                      />
                                    </td>
                                    <td
                                      style={{
                                        minWidth: "0 !important",
                                      }}
                                    >
                                      <>
                                        {changesToMap.after && (
                                          <>
                                            {changesToMap.field.slice(pointIndex, changesToMap.field.length) === "fileContent" ? (
                                              "file"
                                            ) : changesToMap.field.slice(pointIndex, changesToMap.field.length) === "logo" ? (
                                              <img src={changesToMap.after} alt="img" style={{ maxWidth: "100px" }} />
                                            ) : changesToMap.field.slice(pointIndex, changesToMap.field.length) === "picture" ? (
                                              <img src={changesToMap.after} alt="img" style={{ maxWidth: "100px" }} />
                                            ) : changesToMap.field.slice(pointIndex, changesToMap.field.length) === "brochure" ? (
                                              <img src={changesToMap.after} alt="img" style={{ maxWidth: "100px" }} />
                                            ) : (
                                              <>{removeTags(changesToMap.after)}</>
                                            )}
                                          </>
                                        )}
                                      </>
                                    </td>
                                  </tr>
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </>
                  </h6>
                </li>
              </ul>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
