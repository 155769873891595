import { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import './styles/lateralContainer.css'

const LateralContainer = (props:any) => {
    
    return (<Offcanvas show={props.show} onHide={props.toggleShow} placement={'end'} className ="lateralWidth">
        <Offcanvas.Header closeButton style={{display:'flex', justifyContent:'flex-end',paddingBottom:'0'}}>
        </Offcanvas.Header>
        <Offcanvas.Body>
            {props.content}
        </Offcanvas.Body>
    </Offcanvas>)
}

export default LateralContainer;