import React from "react";
import { IRootState } from "../../../store/redux";
import { useSelector } from "react-redux";
import { typeUserData } from "../../../constants/userTypes";
interface IPermission {
  children: React.ReactNode;
}

export default function ItemPermission(props: IPermission) {
  const { children } = props;

  //Redux
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected } = profile;
  return <>{nameRolSelected === typeUserData.admin && children}</>;
}
