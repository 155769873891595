import React, { useContext } from "react";
import LateralContainer from "../LateralContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faSortAlphaDown, faChevronLeft, faBuilding, faThumbtack } from "@fortawesome/free-solid-svg-icons";
import InputClear from "../../atoms/InputClear";
import DropDown from "../../atoms/DropDown";
import Button from "react-bootstrap/Button";
import { IChangeLogSearch } from "./interfaces";
import { useTranslation } from "react-i18next";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { Tooltip } from "@mui/material";
import { useFilterContext } from "../../../context";
import useAutocomplete from "../../atoms/AutocopleteInput/useAutocomplete";
import AutoCompleteInput from "../../atoms/AutocopleteInput/AutocompleteInput";

export default function ChangeLogSearch(props: IChangeLogSearch) {
  const { t } = useTranslation();
  const { typeOfSearch, setTypeOfSearch } = useContext(ApplicationContext);
  const {
    setInputIndexSearch,
    setInputSessionIndexSearch,
    inputSessionLoginSearch,
    setInputSessionLoginSearch,
    setSessionDateSearch,
    inputUserSearch,
    setInputUserSearch,
    inputDateSearch,
    setInputDateSearch,
    setInputPrimaryLoginSearch,
    inputObjectSearch,
    setInputObjectSearch,
    inputActionSearch,
    setInputActionSearch,
    showLateralContainerSearch,
    setShowLateralContainerSearch,
  } = useFilterContext();

  const { listSort, dropDownWhySort, setDropDownWhySort, searchWindowState, SetSearchWindowState, handleSearch, listCheck } = props;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleSearch();
    setShowLateralContainerSearch(!showLateralContainerSearch);
  };

  const sessionLogin = useAutocomplete(
    "/changelogs/get-all?autocomplete=true&login=",
    handleSearch,
    inputSessionLoginSearch,
    setInputSessionLoginSearch,
    "login"
  );
  const user = useAutocomplete("/changelogs/get-all?autocomplete=true&user=", handleSearch, inputUserSearch, setInputUserSearch, "user");

  return (
    <LateralContainer
      show={typeOfSearch === 1 ? showLateralContainerSearch : false}
      toggleShow={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
      content={
        <form action="/" onSubmit={handleSubmit}>
          <div className="row sectionBoxTop">
            <div className="col-2 col-lg-2" style={{ display: "flex" }}>
              <FontAwesomeIcon
                icon={faBuilding}
                style={{
                  marginLeft: "50%",
                  marginTop: "",
                  width: "50px",
                  height: "50px",
                }}
              />
            </div>
            <div className="col-10 col-lg-10 pb-3">
              <h4 className="h4-stl" style={{ margin: "15px 20px 15px" }}>
                <strong>{t("SEARCH.CHANGE-LOG.TITLE-HEADER")}</strong>
              </h4>
            </div>
          </div>
          {searchWindowState === "filterList" ? (
            <div className="cssanimation sequence fadeInTop">
              <div className="filterBox my-4">
                <div style={{ borderBottom: "1px solid rgba(0,0,0,.125)" }}>
                  <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                    {t("SEARCH.REASON-EXCHANGE.TITLE-BOX-USER")}
                  </h4>
                </div>

                <div className="row py-2 mb-4 mt-3">
                  <div className="col-1 col-lg-1"></div>
                  <div className="col-11 col-lg-2">
                    <h5 className="h5-stl Right">{t("ALL.GENERAL-CHANGE-LOG.TITLE-LOGIN")}</h5>
                  </div>
                  <div className="col-8 col-lg-6">
                    <AutoCompleteInput {...sessionLogin} freeSolo />
                  </div>
                  <div className="col-2">
                    <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                  </div>
                </div>

                <div className="row py-2 mb-4">
                  <div className="col-1 col-lg-1"></div>
                  <div className="col-11 col-lg-2">
                    <h5 className="h5-stl Right">{t("ALL.GENERAL-CHANGE-LOG.TITLE-USER")}</h5>
                  </div>
                  <div className="col-8 col-lg-6">
                    <AutoCompleteInput {...user} freeSolo />
                  </div>
                  <div className="col-2">
                    <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                  </div>
                </div>

                {/* <div className="row py-2 mb-4">
                        <div className="col-1 col-lg-1"></div>
                        <div className="col-11 col-lg-2">
                            <h5 className="h5-stl Right">{t('ALL.GENERAL-CHANGE-LOG.TITLE-LAST-NAME')}</h5>
                        </div>
                        <div className="col-8 col-lg-6">
                            <InputClear
                                type="text"
                                name="Code"
                                placeholder=""
                                style={{ heigth: '250px' }}
                                currentValue={inputLastNamSearch}
                                handleOnInputValue={(data: string) => { setInputLastNameSearch(data)}}/>
                        </div>
                        <div className="col-2">
                            <FontAwesomeIcon icon={faEllipsisH} style={{ width: '20px', height: '20px' }} />
                        </div>
                    </div> */}
                <div className="row py-2 mb-4">
                  <div className="col-1 col-lg-1"></div>
                  <div className="col-11 col-lg-2">
                    <h5 className="h5-stl Right">{t("ALL.GENERAL-CHANGE-LOG.TITLE-DATE")}</h5>
                  </div>
                  <div className="col-8 col-lg-6">
                    <InputClear
                      type="date"
                      name="Code"
                      placeholder=""
                      currentValue={inputDateSearch}
                      handleOnInputValue={(data: string) => {
                        setInputDateSearch(data);
                      }}
                    />
                  </div>
                  <div className="col-2">
                    <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                  </div>
                </div>
              </div>

              <div className="filterBox my-4">
                <div style={{ borderBottom: "1px solid rgba(0,0,0,.125)" }}>
                  <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                    {t("ALL.GENERAL-CHANGE-LOG.TITLE-ACTION")}
                  </h4>
                </div>
                <div className="row py-2 mb-4 mt-3">
                  <div className="col-1 col-lg-1"></div>
                  <div className="col-11 col-lg-2">
                    <h5 className="h5-stl Right">{t("ALL.GENERAL-CHANGE-LOG.TITLE-OBJECT")}</h5>
                  </div>
                  <div className="col-8 col-lg-6">
                    <InputClear
                      type="text"
                      name="Code"
                      placeholder=""
                      currentValue={inputObjectSearch}
                      handleOnInputValue={(data: string) => {
                        setInputObjectSearch(data);
                      }}
                    />
                  </div>
                  <div className="col-2">
                    <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                  </div>
                </div>

                <div className="row py-2 mb-4">
                  <div className="col-1 col-lg-1"></div>
                  <div className="col-11 col-lg-2">
                    <h5 className="h5-stl Right">{t("ALL.GENERAL-CHANGE-LOG.TITLE-ACTION")}</h5>
                  </div>
                  <div className="col-8 col-lg-6">
                    <DropDown
                      options={listCheck}
                      selectedItem={inputActionSearch}
                      onSelect={(item: string) => setInputActionSearch(item)}
                      width="13vw"
                    />
                  </div>
                  <div className="col-2">
                    <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                  </div>
                </div>
              </div>
              <div className="bg-gray px-4" style={{ display: "flex" }}>
                <Tooltip title={`${t("FILER-TO-COLUMNS")}`} placement="bottom">
                  <div style={{ width: "60px", margin: "auto 0" }}>
                    <FontAwesomeIcon
                      className="grayBackgr"
                      style={{ width: "47px", height: "47px" }}
                      icon={faThumbtack}
                      onClick={() => {
                        setTypeOfSearch(0);
                      }}
                    />
                  </div>
                </Tooltip>
                {/* <FontAwesomeIcon
                  className="grayBackgr"
                  icon={faSortAlphaDown}
                  onClick={() => SetSearchWindowState("SortWindow")}
                  style={{ width: "47px", height: "47px" }}
                /> */}
                <Button
                  variant="primary"
                  style={{ margin: "15px 30px" }}
                  onClick={() => {
                    handleSearch();
                    setShowLateralContainerSearch(!showLateralContainerSearch);
                  }}
                >
                  {t("SEARCH.ALL.BUTTON-SEARCH")}
                </Button>
                <h4
                  className="h4-stl pt-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setInputIndexSearch("");
                    setInputSessionIndexSearch("");
                    setInputSessionLoginSearch("");
                    setSessionDateSearch("");
                    setInputUserSearch("");
                    setInputDateSearch("");
                    setInputPrimaryLoginSearch("");
                    setInputObjectSearch("");
                    setInputActionSearch("");
                    handleSearch();
                  }}
                >
                  {t("SEARCH.ALL.BUTTON-RESET")}
                </h4>

                <h4
                  className="h4-stl pt-4 mx-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                >
                  {t("SEARCH.ALL.BUTTON-CLOSE")}
                </h4>
              </div>
            </div>
          ) : (
            <div className="cssanimation sequence fadeInBottom">
              <div className="sectionBoxTop pb-4">
                <div
                  className="py-3 my-2"
                  style={{
                    backgroundColor: "var(--color-border)",
                    display: "flex",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    onClick={() => SetSearchWindowState("filterList")}
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "2em",
                      cursor: "pointer",
                    }}
                  />
                  <h4 className="h4-stl" onClick={() => SetSearchWindowState("filterList")} style={{ cursor: "pointer" }}>
                    <strong>Sort order</strong>
                  </h4>
                </div>
                <div>
                  <DropDown
                    options={listSort}
                    selectedItem={dropDownWhySort}
                    onSelect={(item: string) => setDropDownWhySort(item)}
                    width="20vw"
                  />
                </div>
              </div>

              <div>
                <Button
                  variant="primary"
                  style={{
                    margin: "15px 30px",
                    display: "flex",
                    float: "right",
                  }}
                  onClick={() => SetSearchWindowState("filterList")}
                >
                  Apply
                </Button>
              </div>
            </div>
          )}
          <input type="submit" value="" style={{ display: "none" }}></input>
        </form>
      }
    ></LateralContainer>
  );
}
