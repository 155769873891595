import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxes, faBuilding } from "@fortawesome/free-solid-svg-icons";
import ImagesApi from "../../../pages/ExchangesInfo/ImagesApi";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IRowDistributorFromAPI } from "../../../organisms/DistributorTable/DistributorTable";

export default function DistributorTableResponsive({
  render,
  handleDistributor,
  Create,
}: {
  render: IRowDistributorFromAPI[];
  handleDistributor: Function;
  Create?: boolean;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div>
      {render?.map((row: IRowDistributorFromAPI) => (
        <Accordion
          sx={{
            backgroundColor: "#f7f7f7",
            borderRadius: "0.3rem 0.3rem",
            border: "solid 0.0625rem #e0e0e0",
            margin: "8px",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <div
              style={{ maxWidth: "230px", overflow: "hidden" }}
              className="flex-warranty flex-between"
              onClick={() => {
                !Create
                  ? history.push(`/distributor-info/${row.id}`)
                  : handleDistributor(
                      row.id,
                      row.code,
                      row.name,
                      row.group_code,
                      row.group_name,
                      row.client_account_number,
                      row.distributor_phone,
                      row.email,
                      row.responsible_email,
                      row.delivery_account_number
                    );
              }}
            >
              <div className="container-warranty flex-start">
                <FontAwesomeIcon className="icon_size" icon={faBuilding} />

                <div>
                  <h6 className="h6-stl" style={{ margin: "15px 5px" }}>
                    <strong>{row.code}</strong>
                  </h6>
                </div>
              </div>
              <div className="container-warranty flex-end wrap-warranty"></div>
            </div>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: "#fff",
              fontSize: "12px",
            }}
          >
            <div>
              <div style={{ textAlign: "center" }}>
                <ImagesApi image={row?.logo} />
              </div>
              <ul>
                <li>
                  <h6 className="font-response">
                    <strong>{t("ALL.GENERAL.DISTRIBUTOR.TITLE-CODE")}: </strong>
                    {row.code}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{t("ALL.GENERAL.DISTRIBUTOR.TITLE-NAME")}: </strong>
                    {row.name}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong> Web? : </strong>
                    {row.is_web ? "Yes" : "No"}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{t("ALL.GENERAL.DISTRIBUTOR.TITLE-VISIBLE")}: </strong>
                    {row.is_visible ? "Yes" : "No"}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{t("ALL.GENERAL.DISTRIBUTOR.TITLE-LOGO")}: </strong>
                    {row.logo}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{t("ALL.GENERAL.DISTRIBUTOR.TITLE-EVALUATION")}: </strong>
                    {row.evaluation}
                  </h6>
                </li>
              </ul>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
