import { t } from "i18next";

export const fromInfoToTranslatedLanguaje = (country: string) => {
  if (country === "French") return `${t("LANGUAGE-LIST-FRENCH")}`;
  if (country === "English") return `${t("LANGUAGE-LIST-ENGLISH")}`;
  return "";
};
export const fromSiglesToTranslatedLanguaje = (country: string) => {
  if (country === "FR") return `${t("LANGUAGE-LIST-FRENCH")}`;
  if (country === "EN") return `${t("LANGUAGE-LIST-ENGLISH")}`;
  return "";
};
export const fromLanguajeTranslatedToKey = (country: string) => {
  if (country === `${t("LANGUAGE-LIST-FRENCH")}`) return "French";
  if (country === `${t("LANGUAGE-LIST-ENGLISH")}`) return "English";
  return "";
};
