import React, { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
// import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faBars, faComment, faWrench } from "@fortawesome/free-solid-svg-icons";
import ProductTableBrand from "../../organisms/ProductTableBrand/ProductTableBrand";
import ProductTableStockAndPricing from "../../organisms/ProductTableStockAndPricing/ProductTableStockAndPricing";
import "./ProductsInfo.css";
import { Breadcrumb, Button, Card, Dropdown, Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { ApplicationContext } from "../../../context/ApplicationContext";
import BrandsList from "../BrandsList";
import CreateBrand from "../CreateBrand";
import { useTranslation } from "react-i18next";

// bars menu
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import Reloadimg from "../../../images/reload.svg";
import deleteImg from "../../../images/delete.svg";
import copyImg from "../../../images/copy.svg";
import copyItemImg from "../../../images/copy_img.svg";
import exportImg from "../../../images/export.svg";

//API
import { getAllBrandsById } from "../../../api/services/brand_services";
import { IBrandsListID } from "../../../api/interfaces/interfaz_brands";
import { IProductsListId } from "../../../api/interfaces/interfaz_products";
import {
  getProductsById,
  updateProducts,
  deleteProducts,
  enable_disbleProducts,
  CreateProducts,
  getExportProductsByID,
} from "../../../api/services/products_services";
import LoadingSkeletonInfo from "../../organisms/LoadingSkeleton/LoadingSkeletonInfo";

import "../../../constants/responsive.css";
import ChangeLog from "../ChangeLog/ChangeLog";
import Comments from "../../organisms/Comments";
import { getAllCommentsByResourceID } from "../../../api/services/comments_services";
import { IComments } from "../../../api/interfaces/interfaz_comments";

//Redux
import { useSelector } from "react-redux";
import { IRootState } from "../../../store/redux";
import { typeUserData } from "../../../constants/userTypes";
import ProductTableMarketing from "../../organisms/ProductTableMarketing/ProductTableMarketing";
import ProductTableIdentification from "../../organisms/ProductTableIdentification/ProductTableIdentification";
import usePictures from "../../../hooks/usePictures";
import FullLoading from "../../atoms/FullLoading";
import useToastMui from "../../../hooks/Toasts/useToastMui";
import { fromInfoToTranslatedWarranty, fromWaarantyTranslatedToKey } from "../../../utilities/usetBussinessTransalte";
import { useMediaQuery } from "@mui/material";
import ExportInfo from "../../molecules/TransferList/ExportInfo/ExportInfo";
import ModalConfirm from "../../molecules/ModalConfirm/ModalConfirm";
import GuardNotAuthorized from "../../atoms/ItemPermission/GuardNotAuthorized";

export enum listTypeOptionsKeys {
  fixed = "Fixed or variable opening wrench",
  pawls = "Pawls",
  socket = "Sockets",
  screwDrivers = "Screwdrivers",
  mWrench = "Male wrenchs",
  pliers = "Pliers / Vise pliers",
  measuring = "Measuring / Tracing",
  hammers = "Hammers / Strike",
  sawing = "Sawing / Drilling / Cutting",
  extraction = "Extraction",
  other = "Other",
}

export default function ProductsInfo(props: any) {
  let history = useHistory();
  //Translations
  const { t, i18n } = useTranslation();
  const { handleCreateToast } = useToastMui();
  const {
    brandId,
    brandCode,
    brandName,
    setBrandCode,
    setBrandName,
    openBrandTable,
    setOpenBrandTable,
    setBrandId,
    openCreateBrand,
    setOpenCreateBrand,
    setChangeLogPage,
  } = useContext(ApplicationContext);
  const listTypeOptions = [
    "",
    `${t("TABLE.PRODUCTS-STATS.TITLE-OPENINGWRENCH")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-PAWLS")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-SOCKETS")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-SCREWDRIVERS")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-MALEWRENCHS")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-PLIERS")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-TRACING")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-HAMMERS")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-SAWING")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-EXTRACTION")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-OTHER")}`,
  ];
  const listWarranty = [`${t("TYPE-WARRANTY-1")}`, `${t("TYPE-WARRANTY-2")}`, `${t("TYPE-WARRANTY-3")}`];

  //Redux
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected } = profile;

  //Identification
  const [selectedType, setSelectedType] = useState<string>(listTypeOptions[0]);
  const [reference, setReference] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [checkedIdentification, setCheckedIdentification] = useState(false);
  const handleChange = (nextChecked: any) => {
    setCheckedIdentification(nextChecked);
  };
  const [oldReference, setOldReference] = useState<string>("");
  //Stock and pricing
  const [warranty, setWarranty] = useState<string>(listWarranty[0]);
  const [unitPrice, setUnitPrice] = useState<string>("");
  const [checkedAvailable, setCheckedAvailable] = useState(false);
  const handleChangeAvailable = (nextChecked: any) => {
    setCheckedAvailable(nextChecked);
    // enable_disbleProduct(props.id,checkedAvailable)
  };
  //Modal
  const [openExport, setOpenExport] = useState(false);

  //Marketing
  const [inputDescription, setInputDescription] = useState<string>("");
  const [ean, setEan] = useState<string>("");
  const [eanImage, setEanImage] = useState<string>("");
  const [onlineDoc, setOnlineDoc] = useState<string>("");
  const [inputLogo, setInputLogo] = useState<any>("");
  const [inputBrochure, setInputBrochure] = useState<any>("");

  //menu fade
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function copy() {
    const url = document.createElement("input");
    url.value = window.location.href;
    navigator.clipboard.writeText(url.value);
    setAnchorEl(null);
  }
  const [showLateralContainer, setShowLateralContainer] = useState(false);

  // API COMMENTS
  const [commentsArray, setCommentsArray] = useState<IComments>();
  const [loadingComments, setLoadingComments] = useState<boolean>(false);

  //API STATE
  const [brandArray, setBrandArray] = useState<IBrandsListID>();
  const [productArray, setProductArray] = useState<IProductsListId>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
  const [isCopy, setIsCopy] = useState<boolean>(false);

  //Alerts
  const [successAlertCopy, setSuccessAlertCopy] = useState<boolean>(false);
  const [warningAlertStatus, setWarningAlertStatus] = useState<boolean>(false);

  const matches = useMediaQuery("(max-width:500px)");

  //DELETE MODAL
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  //Call api

  let productID = "";

  if (productArray) {
    productID = productArray.result.id;
  }

  const picture = usePictures();
  const brochure = usePictures();

  useEffect(() => {
    (async () => {
      await renderInfo();
      await getComments();
    })();
    /**Restart associated tables to page 1 */
    setChangeLogPage(1);
  }, []);

  //! ------------------------------API CALLS--------------------------------//

  const renderInfo = async () => {
    setLoading(!loading);
    try {
      const apiProductsInfo = await getproductsByID(props.id);
      if (apiProductsInfo) {
        await getBrandsByID(apiProductsInfo.result.brand);
      }
      return apiProductsInfo;
    } catch (error: any) {
      console.log("error getting info");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(t("ALERT-PRODUCTS-ERROR-GET-ONE"), "error");
    } finally {
      console.log("end");
      setLoading(false);
    }
  };
  const exportItem = async (checkedType: string, id: string[]) => {
    try {
      await getExportProductsByID(checkedType, id, i18n.language === "fr" ? "Produit" : "Product");
    } catch (error: any) {
      if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(t("ALERT-PRODUCTS-ERROR-EXPORT"), "error");
    }
  };
  const getproductsByID = async (ProductID: string) => {
    try {
      const response = getProductsById(ProductID);
      const data = (await response).data;
      setProductArray(data);
      setReference(data.result.reference);
      setName(data.result.name);

      setSelectedType(
        data.result.type === listTypeOptionsKeys.fixed
          ? `${t("TABLE.PRODUCTS-STATS.TITLE-OPENINGWRENCH")}`
          : data.result.type === listTypeOptionsKeys.pawls
          ? `${t("TABLE.PRODUCTS-STATS.TITLE-PAWLS")}`
          : data.result.type === listTypeOptionsKeys.socket
          ? `${t("TABLE.PRODUCTS-STATS.TITLE-SOCKETS")}`
          : data.result.type === listTypeOptionsKeys.screwDrivers
          ? `${t("TABLE.PRODUCTS-STATS.TITLE-SCREWDRIVERS")}`
          : data.result.type === listTypeOptionsKeys.mWrench
          ? `${t("TABLE.PRODUCTS-STATS.TITLE-MALEWRENCHS")}`
          : data.result.type === listTypeOptionsKeys.pliers
          ? `${t("TABLE.PRODUCTS-STATS.TITLE-PLIERS")}`
          : data.result.type === listTypeOptionsKeys.measuring
          ? `${t("TABLE.PRODUCTS-STATS.TITLE-TRACING")}`
          : data.result.type === listTypeOptionsKeys.hammers
          ? `${t("TABLE.PRODUCTS-STATS.TITLE-HAMMERS")}`
          : data.result.type === listTypeOptionsKeys.sawing
          ? `${t("TABLE.PRODUCTS-STATS.TITLE-SAWING")}`
          : data.result.type === listTypeOptionsKeys.extraction
          ? `${t("TABLE.PRODUCTS-STATS.TITLE-EXTRACTION")}`
          : data.result.type === listTypeOptionsKeys.other
          ? `${t("TABLE.PRODUCTS-STATS.TITLE-OTHER")}`
          : ""
      );
      setCheckedIdentification(data.result.default);
      setWarranty(fromInfoToTranslatedWarranty(data.result.time_warranty));
      setUnitPrice(data.result.unit_price);
      setCheckedAvailable(data.result.available);
      setInputDescription(data.result.marketing.description);
      setEan(data.result.marketing.ean_number);
      setEanImage(data.result.marketing.ean_image);
      setOnlineDoc(data.result.marketing.online_doc);
      setOldReference(data.result.old_reference);
      setInputLogo(data.result.picture);
      setInputBrochure(data.result.marketing.brochure);
      return data;
    } catch (error: any) {
      console.log("error get product");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(t("ALERT-PRODUCTS-ERROR-GET-ONE"), "error");
    }
  };

  const getBrandsByID = async (BrandId: string) => {
    try {
      const response = getAllBrandsById(BrandId);
      const data = (await response).data;
      setBrandArray(data);
      setBrandId(data.result.id);
      setBrandCode(data.result.code);
      setBrandName(data.result.name);
      return data;
    } catch (error: any) {
      console.log("error get brand");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(t("ALERT-BRAND-ERROR-GET-ONE"), "error");
    }
  };

  const updateProductsInfo = async (productID: string, close: boolean) => {
    setLoadingUpdate(true);
    if (reference && name && selectedType && brandId && warranty && unitPrice) {
      try {
        const response = updateProducts(
          productID,
          brandId,
          reference,
          oldReference,
          name,
          selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-OPENINGWRENCH")}`
            ? listTypeOptionsKeys.fixed
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-PAWLS")}`
            ? listTypeOptionsKeys.pawls
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-SOCKETS")}`
            ? listTypeOptionsKeys.socket
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-SCREWDRIVERS")}`
            ? listTypeOptionsKeys.screwDrivers
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-MALEWRENCHS")}`
            ? listTypeOptionsKeys.mWrench
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-PLIERS")}`
            ? listTypeOptionsKeys.pliers
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-TRACING")}`
            ? listTypeOptionsKeys.measuring
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-HAMMERS")}`
            ? listTypeOptionsKeys.hammers
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-SAWING")}`
            ? listTypeOptionsKeys.sawing
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-EXTRACTION")}`
            ? listTypeOptionsKeys.extraction
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-OTHER")}`
            ? listTypeOptionsKeys.other
            : "",
          checkedIdentification,
          picture.picture ? picture.picture : inputLogo ? inputLogo : { extension: null, file_content: null },
          fromWaarantyTranslatedToKey(warranty),
          unitPrice,
          checkedAvailable,
          "€",
          inputDescription ? inputDescription : "",
          onlineDoc ? onlineDoc : "",
          ean ? ean : "",
          "image",
          brochure.picture ? brochure.picture : inputBrochure ? inputBrochure : { extension: null, file_content: null }
        );

        const data = (await response).data;
        handleCreateToast(t("ALERT-PRODUCTS-SUCCESS-UPDATED"), "success");
        if (!close) return;
        if (document.referrer) {
          history.go(-1);
        } else {
          history.push("/");
        }
        return data;
      } catch (error: any) {
        console.log("error update product");
        console.log(error);
        if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(`${error.response.data.message}`, "error");
      } finally {
        console.log("end");
        setLoadingUpdate(false);
      }
    } else {
      setWarningAlertStatus(true);
      setLoadingUpdate(false);
    }
  };

  const copyProduct = async (close: boolean) => {
    setLoadingUpdate(true);
    if (brandId && reference && name && selectedType && unitPrice && warranty) {
      try {
        const response = CreateProducts(
          brandId,
          reference,
          oldReference,
          name,
          selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-OPENINGWRENCH")}`
            ? listTypeOptionsKeys.fixed
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-PAWLS")}`
            ? listTypeOptionsKeys.pawls
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-SOCKETS")}`
            ? listTypeOptionsKeys.socket
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-SCREWDRIVERS")}`
            ? listTypeOptionsKeys.screwDrivers
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-MALEWRENCHS")}`
            ? listTypeOptionsKeys.mWrench
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-PLIERS")}`
            ? listTypeOptionsKeys.pliers
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-TRACING")}`
            ? listTypeOptionsKeys.measuring
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-HAMMERS")}`
            ? listTypeOptionsKeys.hammers
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-SAWING")}`
            ? listTypeOptionsKeys.sawing
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-EXTRACTION")}`
            ? listTypeOptionsKeys.extraction
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-OTHER")}`
            ? listTypeOptionsKeys.other
            : "",
          checkedIdentification,
          picture.picture ? picture.picture : { file_content: null, extension: null },
          fromWaarantyTranslatedToKey(warranty),
          unitPrice,
          checkedAvailable,
          "€",
          inputDescription ? inputDescription : "",
          onlineDoc ? onlineDoc : "",
          ean ? ean : "",
          "image",
          brochure.picture ? brochure.picture : { file_content: null, extension: null }
        );
        const data = (await response).data;
        setSuccessAlertCopy(true);
        handleCreateToast(t("ALERT-PRODUCT-COPY"), "success");
        if (!close) {
          window.location.assign(`/product-info/${data?.result.id}`);
          setIsCopy(false);
          handleClose();
        } else {
          if (document.referrer) {
            history.go(-1);
          } else {
            history.push("/");
          }
        }
        return data;
      } catch (error: any) {
        console.log("error create product");
        console.log(error);
        if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(`${error.response.data.message}`, "error");
      } finally {
        console.log("end");
        setLoadingUpdate(false);
      }
    } else {
      setWarningAlertStatus(true);
    }
    setLoadingUpdate(false);
  };

  const deleteProduct = async (productID: string) => {
    setLoading(!loading);
    let redirect = false;
    try {
      const response = deleteProducts(productID);
      const data = (await response).data;
      handleCreateToast(t("ALERT-PRODUCTS-SUCCESS-DELETE"), "success");
      redirect = true;
      return data;
    } catch (error: any) {
      console.log("error deleted product");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(t("ALERT-PRODUCTS-ERROR-DELETE"), "error");

      redirect = false;
    } finally {
      console.log("end");
      setLoading(false);
      if (redirect) {
        history.push("/product");
      }
    }
  };

  // Comments

  const getComments = async () => {
    setLoadingComments(!loadingComments);
    try {
      const response = getAllCommentsByResourceID(props.id);
      const data = (await response).data;
      setCommentsArray(data);
      console.log("commet", "updats");
      return data;
    } catch (error: any) {
      console.log("error getting comments");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(t("ALERT-COMMENTS-ERROR-GET"), "error");
    } finally {
      setLoading(false);
      console.log("end");
    }
  };

  //! --------------------------END API CALLS--------------------------------//

  const renderComments = () => {
    return (
      <div>
        <Comments
          commentsArray={commentsArray}
          showLateralContainer={showLateralContainer}
          setShowLateralContainer={() => setShowLateralContainer(!showLateralContainer)}
          setCommentArray={() => {
            getComments();
          }}
          idComponent={props.id}
          whereCreate="product"
        />
      </div>
    );
  };

  const handleCloseBrandTable = () => setOpenBrandTable(false);

  const renderBrandTable = () => {
    return (
      <div>
        <Modal
          show={openBrandTable}
          onHide={handleCloseBrandTable}
          // backdrop="static"
          // keyboard={false}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BrandsList Create={true} />
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  const handleCloseBrandC = () => setOpenCreateBrand(false);
  const renderCreatBrand = () => {
    return (
      <div>
        <Modal
          show={openCreateBrand}
          onHide={handleCloseBrandC}
          // backdrop="static"
          // keyboard={false}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreateBrand Created />
          </Modal.Body>
        </Modal>
      </div>
    );
  };
  return (
    <div>
      {!loading ? (
        <div>
          <Breadcrumb>
            <Link to="/product">
              <Breadcrumb.Item href="/product">{t("TABLE.PRODUCT.TITLE.HEADER-LINK")}</Breadcrumb.Item>
            </Link>
            <FontAwesomeIcon icon={faPlay} style={{ width: "10px", margin: "5px 15px" }} />
            {!isCopy ? (
              <Breadcrumb.Item href="#">
                {t("TABLE.PRODUCT.TITLE.HEADER-LINK")}
                {`: ${productArray?.result.name}`}
              </Breadcrumb.Item>
            ) : (
              <Breadcrumb.Item href="#">{t("PRODUCT-COPY-TITLE")}</Breadcrumb.Item>
            )}
          </Breadcrumb>
          <Card>
            <Card.Header>
              <div className="flex-warranty flex-between">
                <div className="container-warranty flex-start">
                  <FontAwesomeIcon icon={faWrench} className="icon_size" />

                  <div>
                    {!isCopy ? (
                      <h4 className="h4-stl" style={{ margin: "15px 5px" }}>
                        <strong>
                          {t("TABLE.PRODUCT.TITLE-HEADER")}
                          {`: ${productArray?.result.name}`}
                        </strong>
                      </h4>
                    ) : (
                      <h4 className="h4-stl" style={{ margin: "15px 5px" }}>
                        <strong>{t("PRODUCT-COPY-TITLE")}</strong>
                      </h4>
                    )}
                  </div>
                </div>

                <div className="container-warranty flex-end wrap-warranty ">
                  {nameRolSelected === typeUserData.admin &&
                    (matches ? (
                      <Dropdown>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                          {t("CREATE.ALL.BUTTON-SAVE")}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              !isCopy ? updateProductsInfo(productID, false) : copyProduct(false);
                            }}
                          >
                            {t("CREATE.ALL.BUTTON-SAVE")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            style={{ color: "var(--color-main-text)", textDecoration: "none" }}
                            onClick={() => {
                              !isCopy ? updateProductsInfo(productID, true) : copyProduct(true);
                            }}
                          >
                            {t("CREATE.ALL.BUTTON-SAVE-CLOSE")}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      <>
                        <Button
                          variant="primary"
                          onClick={() => {
                            !isCopy ? updateProductsInfo(productID, false) : copyProduct(false);
                          }}
                        >
                          {t("CREATE.ALL.BUTTON-SAVE")}
                        </Button>
                        <Button
                          variant="outline-secondary"
                          style={{ minWidth: "150px", maxHeight: "40px" }}
                          onClick={() => {
                            !isCopy ? updateProductsInfo(productID, true) : copyProduct(true);
                          }}
                        >
                          {t("CREATE.ALL.BUTTON-SAVE-CLOSE")}
                        </Button>
                      </>
                    ))}
                  {!isCopy && (
                    <div className="content-comment">
                      <FontAwesomeIcon
                        className="grayBackgr comment-icon"
                        icon={faComment}
                        style={{ width: "50px", height: "50px" }}
                        onClick={() => setShowLateralContainer(!showLateralContainer)}
                      />
                      <sup className="comment-count">{commentsArray?.result.items.length}</sup>
                    </div>
                  )}

                  {!isCopy && (
                    <div onClick={handleClick}>
                      <FontAwesomeIcon className="grayBackgr" icon={faBars} style={{ width: "50px", height: "50px" }} />
                    </div>
                  )}
                  {!isCopy && (
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      TransitionComponent={Fade}
                    >
                      <MenuItem
                        onClick={() => {
                          history.go(0);
                        }}
                        style={{ fontSize: "15px" }}
                      >
                        <img alt="" src={Reloadimg} style={{ width: "20px", marginRight: "10px" }}></img>
                        {t("ALL.MENU.RELOAD")}
                      </MenuItem>
                      {nameRolSelected === typeUserData.admin && (
                        <MenuItem
                          onClick={() => {
                            setOpenDeleteModal(true);
                            handleClose();
                          }}
                          style={{ fontSize: "15px" }}
                        >
                          <img alt="" src={deleteImg} style={{ width: "20px", marginRight: "10px" }}></img>
                          {t("ALL.MENU.DELETE")}
                        </MenuItem>
                      )}
                      {nameRolSelected === typeUserData.admin && (
                        <MenuItem
                          onClick={() => {
                            setIsCopy(true);
                            setReference("");
                          }}
                          style={{ fontSize: "15px" }}
                        >
                          <img alt="" src={copyItemImg} style={{ width: "20px", marginRight: "10px" }}></img>
                          {t("ALL-BUTTON-COPY-ITEM")}
                        </MenuItem>
                      )}
                      <GuardNotAuthorized>
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setOpenExport(true);
                          }}
                          style={{ fontSize: "15px" }}
                        >
                          <img alt="" src={exportImg} style={{ width: "20px", marginRight: "10px" }} />
                          {t("NAV-ALL-ITEM3")}
                        </MenuItem>
                      </GuardNotAuthorized>
                      <MenuItem onClick={copy} style={{ fontSize: "15px" }}>
                        <img alt="" src={copyImg} style={{ width: "20px", marginRight: "10px" }}></img>
                        {t("ALL.MENU.COPY-LINK")}
                      </MenuItem>
                      {/* <MenuItem onClick={handleClose} style={{fontSize:'15px'}}>Logout</MenuItem> */}
                    </Menu>
                  )}
                  <Button
                    onClick={() => {
                      if (document.referrer) {
                        history.go(-1);
                      } else {
                        history.push("/");
                      }
                    }}
                    variant="outline-secondary"
                  >
                    {t("CREATE.ALL.BUTTON-CLOSE")}
                  </Button>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="row">
                <div className="col-12 col-lg-12">
                  <ProductTableBrand
                    openLink={`/brand-info/${brandId}`}
                    onClickSearch={() => setOpenBrandTable(true)}
                    onClickCreate={() => setOpenCreateBrand(true)}
                    Productinfo={true}
                    warningAlertStatus={warningAlertStatus}
                    brandCode={brandCode}
                    setBrandCode={(data: string) => {
                      setBrandCode(data);
                    }}
                    brandName={brandName}
                    setBrandName={(data: string) => {
                      setBrandName(data);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-6 my-4">
                  <ProductTableIdentification
                    selectedType={selectedType}
                    warningAlertStatus={warningAlertStatus}
                    setSelectedType={(data: string) => {
                      setSelectedType(data);
                    }}
                    reference={reference}
                    setReference={(data: string) => {
                      setReference(data);
                    }}
                    oldReference={oldReference}
                    setOldReference={(data: string) => {
                      setOldReference(data);
                    }}
                    name={name}
                    setName={(data: string) => {
                      setName(data);
                    }}
                    listTypeOptions={listTypeOptions}
                    checked={checkedIdentification}
                    handleChange={handleChange}
                    fileName={picture.fileNameImage}
                    handleChangeStatus={picture.handleChangeStatus}
                    picture={inputLogo}
                    handleDeleteImage={() => {
                      picture.handleDeleteImage();
                      setInputLogo(null);
                    }}
                  />
                </div>
                <div className="col-12 col-lg-6 my-4">
                  <ProductTableStockAndPricing
                    warranty={warranty}
                    setWarranty={(data: string) => {
                      setWarranty(data);
                    }}
                    warningAlertStatus={warningAlertStatus}
                    unitPrice={unitPrice}
                    setUnitPrice={(data: string) => {
                      setUnitPrice(data);
                    }}
                    checkedAvailable={checkedAvailable}
                    handleChangeAvailable={handleChangeAvailable}
                    listWarranty={listWarranty}
                    nameRolSelected={nameRolSelected}
                  />

                  <ProductTableMarketing
                    inputDescription={inputDescription}
                    handleChange={(content: any) => {
                      setInputDescription(content);
                    }}
                    onlineDoc={onlineDoc}
                    setOnlineDoc={(data: string) => {
                      setOnlineDoc(data);
                    }}
                    ean={ean}
                    setEan={(data: string) => {
                      setEan(data);
                    }}
                    eanImage={eanImage}
                    setEanImage={(data: string) => {
                      setEanImage(data);
                    }}
                    loadingPicture={brochure.LoadingPicture}
                    picture={inputBrochure}
                    extension={brochure.extension}
                    handleDeleteImage={() => {
                      brochure.handleDeleteImage();
                      setInputBrochure(null);
                    }}
                    fileName={brochure.fileNameImage}
                    handleChangeStatus={brochure.handleChangeStatus}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
          {!isCopy && (
            <div className="my-4">
              {nameRolSelected === typeUserData.admin && (
                <Card>
                  <div className="containerBox">
                    <div className="row sectionBoxTop">
                      <div className="row my-4">
                        <ChangeLog resourceId={productID} mainlink />
                      </div>
                    </div>
                  </div>
                </Card>
              )}
            </div>
          )}
          {renderBrandTable()}
          {renderCreatBrand()}
          {renderComments()}
        </div>
      ) : (
        <LoadingSkeletonInfo />
      )}
      <ModalConfirm
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        onClick={() => deleteProduct(props.id)}
        onClickCancel={() => setOpenDeleteModal(false)}
      />
      <FullLoading open={loadingUpdate} />
      <ExportInfo openExport={openExport} setOpenExport={setOpenExport} exportFunction={exportItem} id={props.id} />
    </div>
  );
}
