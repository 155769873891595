import { Stack } from "@mui/material";
import { Card } from "react-bootstrap";
import Skeleton from "@mui/material/Skeleton";

export default function LoadingSkeletonTables() {
  return (
    <div>
      <Card>
        <div className="py-4 px-4">
          <Stack spacing={1}>
            <Skeleton variant="rectangular" height={50} animation="wave" />

            <div className="my-3" style={{ display: "flex" }}>
              <div className="mx-3">
                <Skeleton variant="circular" width={50} height={50} animation="wave" />
              </div>
              <div className="full-width ml-5">
                <Skeleton variant="rectangular" height={50} animation="wave" />
              </div>
            </div>
            <div className="my-3" style={{ display: "flex" }}>
              <div className="mx-3">
                <Skeleton variant="circular" width={50} height={50} animation="wave" />
              </div>
              <div className="full-width ml-5">
                <Skeleton variant="rectangular" height={50} animation="wave" />
              </div>
            </div>
            <div className="my-3" style={{ display: "flex" }}>
              <div className="mx-3">
                <Skeleton variant="circular" width={50} height={50} animation="wave" />
              </div>
              <div className="full-width ml-5">
                <Skeleton variant="rectangular" height={50} animation="wave" />
              </div>
            </div>
            <div className="my-3" style={{ display: "flex" }}>
              <div className="mx-3">
                <Skeleton variant="circular" width={50} height={50} animation="wave" />
              </div>
              <div className="full-width ml-5">
                <Skeleton variant="rectangular" height={50} animation="wave" />
              </div>
            </div>
          </Stack>
        </div>
      </Card>
    </div>
  );
}
