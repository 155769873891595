import React, { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Container, Dropdown } from "react-bootstrap";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import Pagination from "@mui/material/Pagination";
import Table from "react-bootstrap/Table";

import franceBrand from "../../../images/franceBrand.svg";
import englishBrand from "../../../images/englishBrand.svg";
import { Link, useHistory } from "react-router-dom";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { useTranslation } from "react-i18next";
import uncheckedpage from "../../../images/checkPage.svg";
import checkedimg from "../../../images/checked.svg";
import uncheckedimg from "../../../images/unchecked.svg";
import grayChecked from "../../../images/grayCheck.png";
import { Checkbox, useMediaQuery } from "@mui/material";
import LoadingSkeletonTables from "../LoadingSkeleton/LoadingSkeletonTables";
import ReasonsForExchangeTableFilter from "../Search/ReasonsForExchangeTableFilter";
import { IRowHeader } from "../../../models/table.modal";
import ReasonForExchangeTableResponsive from "../../molecules/TransferList/responsiveTables/ReasonForExchangeTableResponsive";
interface IRefusalProps {
  searchState: boolean;
  rowsPerPage: number;
  UsePlanText: string;
  linkTo: string;
  Create?: boolean;
  showSearchTable?: boolean;
  reasonsForExchangeArray: any;

  loading: boolean;
  currentPage: number;
  lastPage: number;
  handleChange: any;
  inputToSort: string;
  incrementCount?: any;
  typeSort?: string;
  setDropDownWhySort?: any;
  listLanguajeOptions: any;
  handleSearch: any;
}

export interface IRowRforExchangeFromAPI {
  id: string;
  code_reason: string;
  language: string;
  label: string;
  display_order: string;
  is_for_change: any;
  [index: string]: string;
}

export default function TableReasonForExchanges(props: IRefusalProps) {
  const {
    setReasonExchangeGivenReason,
    setReasonExchangeId,
    setOpenReasonExchangeTable,
    openReasonExchangeTable,
    setReasonForExchangeAllSelected,
  } = useContext(ApplicationContext);
  const { setReasonForExchangesListID, reasonForExchangesListID, reasonForExchangeTableTransferData } = useContext(ApplicationContext);
  const { lastPage, handleChange, currentPage, reasonsForExchangeArray, loading, inputToSort, incrementCount, typeSort } = props;
  //Translations
  const { t } = useTranslation();
  const history = useHistory();
  const matches = useMediaQuery("(max-width:780px)");
  const pageSize = props.rowsPerPage;
  const { listLanguajeOptions, handleSearch, setDropDownWhySort } = props;

  //GENERATE AN ARRAY WITH SELECTED IDS
  const [defaultChecked, setDefaultChecked] = useState<boolean>(false);
  const createDeleteArray = (idDelete: string, checked: boolean) => {
    if (checked) {
      setReasonForExchangesListID([...reasonForExchangesListID, idDelete]);
    } else {
      setReasonForExchangesListID(reasonForExchangesListID.filter((item: any) => item !== idDelete));
    }
  };

  //EXCHANGES
  const handleReasonExchange = (ReasonExhcangeId: string, ReasonExhcangeGivenReason: string) => {
    setReasonExchangeId(ReasonExhcangeId);
    setReasonExchangeGivenReason(ReasonExhcangeGivenReason);

    setOpenReasonExchangeTable(!openReasonExchangeTable);
  };

  const redirectionLink = "/reasons-for-exchange-info";
  const redirectionKey = "id";
  const tableInitial: IRowHeader[] = [
    {
      field: "dropdown",
      headerName: "",
      sort: "",
    },
    {
      field: "code_reason",
      headerName: t("ALL.GENERAL-REASON-EXCHANGE.TITLE-CODE"),
      sort: t("ALL.GENERAL-REASON-EXCHANGE.TITLE-CODE"),
      sortKey: "code_reason",
      link: redirectionLink,
      linkKey: redirectionKey,
    },
    {
      field: "language",
      headerName: t("ALL.GENERAL-REASON-EXCHANGE.TITLE-LANGUAJE"),
      sort: t("ALL.GENERAL-REASON-EXCHANGE.TITLE-LANGUAJE"),
      sortKey: "language",
      link: redirectionLink,
      linkKey: redirectionKey,
    },
    {
      field: "is_for_change",
      headerName: t("ALL.GENERAL-REASON-EXCHANGE.TITLE-ELIGIBLE"),
      sort: t("ALL.GENERAL-REASON-EXCHANGE.TITLE-ELIGIBLE"),
      sortKey: "is_for_change",
      link: redirectionLink,
      linkKey: redirectionKey,
    },
    {
      field: "label",
      headerName: t("ALL.GENERAL-REASON-EXCHANGE.TITLE-LABEL"),
      sort: t("ALL.GENERAL-REASON-EXCHANGE.TITLE-LABEL"),
      sortKey: "label.code",
      link: redirectionLink,
      linkKey: redirectionKey,
    },
    {
      field: "display_order",
      headerName: t("ALL.GENERAL-REASON-EXCHANGE.TITLE-DISPLAY-ORDER"),
      sort: t("ALL.GENERAL-REASON-EXCHANGE.TITLE-DISPLAY-ORDER"),
      sortKey: "display_order",
      link: redirectionLink,
      linkKey: redirectionKey,
    },
  ];

  //FILTRO
  let reasonForExchangeArraySort: IRowRforExchangeFromAPI[] = [];
  if (reasonsForExchangeArray) {
    reasonForExchangeArraySort = reasonsForExchangeArray;
  }

  const [tabla, setTabla] = useState<IRowHeader[]>(tableInitial);
  useEffect(() => {
    setTabla(tableInitial.filter((item) => reasonForExchangeTableTransferData.includes(item.headerName)));
  }, [reasonForExchangeTableTransferData]);

  const createHeader = (row: IRowHeader, index: number) => (
    <th
      key={`distributor-${index}`}
      style={{ cursor: "pointer" }}
      // onClick={() => {
      //   setDropDownWhySort(row.sort);
      //   incrementCount(row.sortKey);
      // }}
    >
      {row.headerName}
      {/* {inputToSort === row.sort && (
        <FontAwesomeIcon icon={typeSort === `asc(${row.sortKey})` ? faChevronUp : faChevronDown} style={{ marginLeft: "5px" }} />
      )} */}
    </th>
  );

  const createRow = (row: IRowRforExchangeFromAPI, index: number, render: IRowHeader[]) => {
    const isItemSelected: boolean = isSelected(row.id);
    return (
      <tr
        key={row?.id}
        style={{
          backgroundColor: "var(--color-white)",
          textAlign: "left",
          cursor: "pointer",
        }}
      >
        {defaultChecked ? (
          <td style={{ paddingLeft: "15px" }}>
            <img alt="" src={grayChecked} style={{ width: "20px", marginLeft: "10px" }}></img>
          </td>
        ) : (
          <td style={{ paddingLeft: "15px" }}>
            <Checkbox
              checked={isItemSelected}
              style={{ display: "inline" }}
              name="gridRadios"
              id={`radios${row._id}`}
              value={`option${row._id}`}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                createDeleteArray(row.id, event.target.checked);
              }}
              aria-label="option 1"
            />
          </td>
        )}
        {render?.map((i: IRowHeader) => (
          <td
            style={{ paddingLeft: "15px" }}
            onClick={() =>
              !props.Create ? history.push(`${i.link}/${row[i.linkKey ? i.linkKey : "id"]}`) : handleReasonExchange(row.id, row.label)
            }
          >
            {`${i.field}` === "language" ? (
              <>
                {row.language === "French" ? (
                  <img alt="" src={franceBrand} style={{ paddingRight: "5px" }}></img>
                ) : row.language === "English" ? (
                  <img alt="" src={englishBrand} style={{ paddingRight: "5px" }}></img>
                ) : (
                  ""
                )}
                {row.language === "English" ? `${t("LANGUAGE-LIST-ENGLISH")}` : `${t("LANGUAGE-LIST-FRENCH")}`}
              </>
            ) : `${i.field}` === "is_for_change" ? (
              <>{row.is_for_change === true ? <FontAwesomeIcon icon={faCheck} /> : ""}</>
            ) : (
              row[`${i.field}`]
            )}
          </td>
        ))}
      </tr>
    );
  };

  //SELECT ALL LIST

  const allSelect = (allchecked: boolean, page: boolean) => {
    setDefaultChecked(allchecked);
    let AllIds: any = [];
    if (page) {
      AllIds = reasonForExchangeArraySort.map(function (ids: any) {
        return ids.id;
      });
    } else {
      AllIds = [];
      setReasonForExchangeAllSelected(false);
    }
    if (allchecked) {
      setReasonForExchangeAllSelected(true);
    }
    setReasonForExchangesListID(AllIds);
  };
  const isSelected = (id: string) => reasonForExchangesListID.indexOf(id) !== -1;

  return (
    <div>
      <Card>
        {!loading ? (
          <>
            {!matches ? (
              <Table responsive="xl" striped hover style={{ border: "2px solid var(--color-input-border)" }}>
                {/* TABLE HEADER */}
                <thead
                  style={{
                    marginBottom: "10px",
                    textAlign: "left",
                    backgroundColor: "rgba(0, 0, 0, 0.03)",
                  }}
                >
                  <tr>
                    <th className="check-table">
                      <Container style={{ padding: 12 }}>
                        <Dropdown style={{ border: "none" }}>
                          <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                            {/* <input type="checkbox" /> */}
                            {defaultChecked ? (
                              <img alt="" src={checkedimg} style={{ width: "20px" }}></img>
                            ) : (
                              <img alt="" src={uncheckedimg} style={{ width: "20px" }}></img>
                            )}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div onClick={() => allSelect(true, false)}>
                              <Dropdown.Item href="#/action-1">
                                <img alt="" src={checkedimg}></img> {t("TABLES-SELECT-ALL")}
                              </Dropdown.Item>
                            </div>
                            <div onClick={() => allSelect(false, true)}>
                              <Dropdown.Item href="#/action-1">
                                <img alt="" src={uncheckedpage}></img> {t("TABLES-SELECT-PAGE")}
                              </Dropdown.Item>
                            </div>
                            <div onClick={() => allSelect(false, false)}>
                              <Dropdown.Item href="#/action-3">
                                <img alt="" src={uncheckedimg}></img> {t("TABLES-UNSELECT-PAGE")}
                              </Dropdown.Item>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Container>
                    </th>
                    {tabla?.map(createHeader)}
                  </tr>
                  {/* TABLE SEARCH */}
                  <ReasonsForExchangeTableFilter listLanguajeOptions={listLanguajeOptions} handleSearch={handleSearch} />
                </thead>
                <tbody style={{ cursor: "pointer !important" }}>
                  {/* TABLE BODY */}
                  {reasonForExchangeArraySort?.map((row: any, index: number) => createRow(row, index, tabla))}
                </tbody>
              </Table>
            ) : (
              <ReasonForExchangeTableResponsive
                render={reasonForExchangeArraySort}
                Create={props.Create}
                handleReasonExchange={handleReasonExchange}
              />
            )}
          </>
        ) : (
          <LoadingSkeletonTables />
        )}
        <div className="pb-2" style={{ display: "flex", justifyContent: "space-around" }}>
          <Pagination
            style={{ paddingTop: 10 }}
            count={Math.ceil(lastPage / pageSize)}
            page={currentPage}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
            color="primary"
            boundaryCount={3}
            siblingCount={4}
          />
        </div>
      </Card>
    </div>
  );
}
