import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import 'bootstrap/dist/css/bootstrap.css'
import { Breadcrumb, Card } from 'react-bootstrap';


export default function LoadingComments() {
    return (
        <div>
            <div>
                <div className="py-4 px-4">
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" height={90} />

                    <div className="row my-3">
                        <Skeleton variant="rectangular" height={50} />
                    </div>
                    <div className="row my-3">
                        <Skeleton variant="rectangular" height={50} />
                    </div>
                    <div className="row my-3">
                        <Skeleton variant="rectangular" height={50} />
                    </div>
                    <div style={{height:'45vh'}}></div>
                    </Stack>
                </div>
            </div>
        </div>
    );
}