import { useState, useContext } from "react";
import { IDistributorList, IDistributorsListDOM } from "../../api/interfaces/interfaz_distributor";
import {
  getAllDistributors,
  deleteManyDistributors,
  getExportDistributors,
  getExportDistributorsByCode,
  getExportDistributorsByGroup,
  getExportDistributorsByID,
} from "../../api/services/distributor_services";
import { getAllUsersById } from "../../api/services/user_services";

import { ApplicationContext } from "../../context/ApplicationContext";

//Redux
import { useSelector } from "react-redux";
import { IRootState } from "../../store/redux";
import useFetchAndLoad from "../useFetchAndLoad";
import useAsync from "../useAsync";
import { createAddapterDistributor } from "./adapters/distributorAdapter";
import { typeUserData } from "../../constants/userTypes";
import useToastMui from "../Toasts/useToastMui";
import { useTranslation } from "react-i18next";
interface ICoordinates {
  lat: number | undefined;
  lng: number | undefined;
}

function useDistributors(
  groupCode: string,
  groupName: string,
  address: string,
  geographicalCoordinates: string,
  mapUrl: string,
  name: string,
  code: string,
  evaluation: string,
  email: string,
  client_account_number: string,
  is_visible: string,
  is_web: string,
  members_of_set: string,
  responsible_email: string,
  delivery_account_number: string,
  //If the data comes from groupInfo, the list will be filtered by the group
  groupId?: string
) {
  const { setDistributorListID, setDistributorLength, setExportToggle, distributorsPage, setDistributorsPage } =
    useContext(ApplicationContext);
  const { t, i18n } = useTranslation();
  //Redux
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected } = profile;
  const { sub } = profile;
  const { handleCreateToast } = useToastMui();
  const [distributorArray, setDistributorArray] = useState<IDistributorsListDOM[]>();

  const [lastPage, setLastPage] = useState(0);
  const [searchToggle, setSearchToggle] = useState(false);
  const [deletedToogle, setDeletedToogle] = useState(false);
  const limit = 10;

  //Alerts
  const [wrongAlertStatus, setWrongAlertStatus] = useState<boolean[]>([false, false, false, false, false]);

  const { loading, callEndpoint } = useFetchAndLoad();
  //to sort list by api

  const [countCode, setCountCode] = useState(1);
  const [typeSort, setTypeSort] = useState("");

  const getQuery = (
    groupCode: string,
    groupName: string,
    address: string,
    geographicalCoordinates: string,
    mapUrl: string,
    name: string,
    code: string,
    evaluation: string,
    email: string,
    client_account_number: string,
    is_visible: string,
    is_web: string,
    members_of_set: string,
    responsible_email: string,
    delivery_account_number: string,
    //If the data comes from groupInfo, the list will be filtered by the group
    groupId?: string,
    sort?: string
  ) => {
    const queryKeys: any = {
      group_code: groupCode,
      group_name: groupName,
      address: address,
      coordinates: geographicalCoordinates,
      map_url: mapUrl,
      name: name,
      code: code,
      evaluation: evaluation,
      email: email,
      client_account_number: client_account_number,
      is_visible: is_visible,
      group: groupId,
      sort: sort,
      members_of_set,
      responsible_email,
      is_web,
      delivery_account_number,
    };
    let query = {};
    for (let key in queryKeys) {
      if (queryKeys[key]) {
        query = { ...query, [key]: queryKeys[key] };
      }
    }
    return query;
  };

  const loadDistributors = async (
    groupCode: string,
    groupName: string,
    address: string,
    geographicalCoordinates: string,
    mapUrl: string,
    name: string,
    code: string,
    evaluation: string,
    email: string,
    client_account_number: string,
    is_visible: string,
    is_web: string,
    members_of_set: string,
    responsible_email: string,
    delivery_account_number: string,
    nowPage: number,
    //If the data comes from groupInfo, the list will be filtered by the group
    groupId?: string
  ) => {
    //! important in groups and users
    //In the use case of distributor if a user is a group or distributor
    //The data will be filtered by the distributor or group assossiated
    let userData: any = {};
    if (sub) {
      const responseUser = getAllUsersById(sub);
      userData = (await responseUser).data;
    }

    let query = getQuery(
      groupCode,
      groupName,
      address,
      geographicalCoordinates,
      mapUrl,
      name,
      nameRolSelected === typeUserData.distributor
        ? userData?.result?.distributors_data?.code
          ? userData?.result?.distributors_data?.code
          : "-.-.-"
        : code,
      evaluation,
      email,
      client_account_number,
      is_visible,
      is_web,
      members_of_set,
      responsible_email,
      delivery_account_number,
      nameRolSelected === typeUserData.group
        ? userData?.result?.groups_data?._id
          ? userData?.result?.groups_data?._id
          : "-.-.-"
        : groupId,
      typeSort
    );
    query = {
      ...query,
      limit: limit,
      offset: (nowPage - 1) * limit,
    };

    const response = await callEndpoint(
      getAllDistributors({ query }),
      () => !groupId && handleCreateToast(t("ALERT-DISTRIBUTORS-ERROR-GET"), "error")
    );
    return response;
  };

  const adaptInfo = (data: IDistributorList) => {
    const array: IDistributorsListDOM[] = createAddapterDistributor(data?.result?.items);
    setDistributorArray(array);
    setLastPage(data?.result?.total);
    setDistributorLength(data?.result?.total);
  };

  useAsync(
    () =>
      loadDistributors(
        groupCode,
        groupName,
        address,
        geographicalCoordinates,
        mapUrl,
        name,
        code,
        evaluation,
        email,
        client_account_number,
        is_visible,
        is_web,
        members_of_set,
        responsible_email,
        delivery_account_number,
        distributorsPage,
        groupId
      ),
    adaptInfo,
    () => {},
    [distributorsPage, searchToggle, deletedToogle, typeSort]
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setDistributorsPage(value);
  };
  const onSearch = () => {
    setSearchToggle((value) => !value);
    setDistributorsPage(1);
  };

  const deleteDistributors = async (DistributorId: string[]) => {
    try {
      const response = deleteManyDistributors(DistributorId);
      const data = (await response).data;
      handleCreateToast(t("ALERT-DISTRIBUTORS-SUCCESS-DELETE"), "success");
      return data;
    } catch (error: any) {
      console.log("error deleted distributor");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(t("ALERT-DISTRIBUTORS-ERROR-DELETE"), "error");
    } finally {
      console.log("end");
      setDistributorListID([]);
      setDistributorsPage(1);
      setDeletedToogle(!deletedToogle);
      //   history.push("/distributor");

      //! this was on component now
      //   setOpenConfirm(false);
    }
  };

  // Export
  const exportDistributor = async (checkedType: string, distributorListID: string[], checkNeedToExport: boolean[]) => {
    setExportToggle(true);
    try {
      if (checkNeedToExport[0] === true) {
        if (sub) {
          const response = getAllUsersById(sub);
          const data = (await response).data;
          if (nameRolSelected === typeUserData.distributor) {
            if (data?.result?.distributors_data?.code) {
              await getExportDistributorsByCode(checkedType, data?.result?.distributors_data?.code);
            }
          } else if (nameRolSelected === typeUserData.group) {
            if (data?.result?.groups_data?._id) {
              await getExportDistributorsByGroup(
                checkedType,
                data?.result?.groups_data?._id,
                i18n.language === "fr" ? "Distributeur" : "Distributor"
              );
            }
          } else {
            await getExportDistributors(checkedType);
          }
          return data;
        }
      }
      if (checkNeedToExport[1] === true) {
        if (distributorListID.length) {
          await getExportDistributorsByID(checkedType, distributorListID, i18n.language === "fr" ? "Distributeur" : "Distributor");
        } else {
          let userData: any = {};
          if (sub) {
            const responseUser = getAllUsersById(sub);
            userData = (await responseUser).data;
          }
          let query = getQuery(
            groupCode,
            groupName,
            address,
            geographicalCoordinates,
            mapUrl,
            name,
            nameRolSelected === typeUserData.distributor
              ? userData?.result?.distributors_data?.code
                ? userData?.result?.distributors_data?.code
                : "-.-.-"
              : code,
            evaluation,
            email,
            client_account_number,
            is_visible,
            is_web,
            members_of_set,
            responsible_email,
            delivery_account_number,
            nameRolSelected === typeUserData.group
              ? userData?.result?.groups_data?._id
                ? userData?.result?.groups_data?._id
                : "-.-.-"
              : groupId
          );
          query = {
            ...query,
          };
          await getExportDistributorsByID(checkedType, distributorListID, i18n.language === "fr" ? "Distributeur" : "Distributor", query);
        }
      }
    } catch (error: any) {
      console.log("error export");
      console.log(error);
      handleCreateToast(t("ALERT-DISTRIBUTORS-ERROR-EXPORT"), "error");
    } finally {
      console.log("end");
      setExportToggle(false);
      //   setOpenConfirm(false);
    }
  };

  // * ------------------------SORT----------------------------------//

  // sort

  const incrementCount = (key: string) => {
    setCountCode((prev) => prev + 1);

    if (countCode === 1) {
      setTypeSort(`asc(${key})`);
    }
    if (countCode === 2) {
      setTypeSort(`desc(${key})`);
      setCountCode(1);
    }
  };

  return {
    distributorArray,
    loading,
    exportDistributor,
    deleteDistributors,
    wrongAlertStatus,
    setWrongAlertStatus,
    currentPage: distributorsPage,
    lastPage,
    loadDistributors,
    handleChange,
    limit,
    onSearch,

    typeSort,
    incrementCount,
  };
}

const service = {
  useDistributors,
};
export default service;
export { useDistributors };
