import { AxiosResponse, AxiosRequestConfig } from "axios";
import { axios as http } from "../../frameworks_and_drivers/Axios";
import { BACKEND_URL } from "../../config/settings.json";
import { IRefusalReasonList, IRefusalReasonListID } from "../interfaces/interfaz_refusal_reasons";
import { downloadFileFromResponse } from "./helps";
import HelpService from "../HelpService";
import { loadAbort } from "../../helpers/loadAbort";
import { fromLanguajeTranslatedToKey } from "../../utilities/languajeTraslations";

export const getAllRefusalReasons = (props?: any) => {
  const controller = loadAbort();
  const { query } = props;
  const URL = `${BACKEND_URL}/reasons-for-refusal/get-all?` + HelpService.getQueryString(query);
  return {
    call: http.get<IRefusalReasonList>(URL, { signal: controller.signal }),
    controller,
  };
};

export const getAllRefusalReasonsByID = async (objectId: string) => {
  const URL = `${BACKEND_URL}/reasons-for-refusal/${objectId}/get-one`;
  const response = await http.get<IRefusalReasonListID>(URL);
  return response;
};

export const updateRefusalReasons = async (
  objectId: string,
  body: {
    label?: string;
    code_reason?: string;
    display_order?: string;
    language?: string;
    description?: string;
  }
) => {
  const URL = `${BACKEND_URL}/reasons-for-refusal/${objectId}/update-one`;
  const response = await http.put(URL, body);
  return response;
};

export const CreateRefusalReasons = async (
  label: string,
  code_reason: string,
  display_order: string,
  language: string,
  description: string
) => {
  const body = {
    label: label,
    code_reason: code_reason,
    display_order: display_order,
    language: language,
    description: description,
  };
  const URL = `${BACKEND_URL}/reasons-for-refusal/create-one`;
  const response = await http.post(URL, body);
  return response;
};

export const deleteRefusalReasons = async (objectId: string) => {
  const URL = `${BACKEND_URL}/reasons-for-refusal/${objectId}/delete-one`;
  const response = await http.put(URL);
  return response;
};
export const deleteManyRefusalReasonse = async (ids: string[]) => {
  const URL = `${BACKEND_URL}/reasons-for-refusal/delete-many`;
  const body = {
    items: ids,
  };
  const response = await http.put(URL, body);
  return response;
};

export const getExportRefusalReasons = async (type: string) => {
  const URL = `${BACKEND_URL}/reasons-for-refusal/generate-report`;
  const opts: AxiosRequestConfig = {
    params: {
      export_type: type,
    },
    responseType: "blob",
  };
  const response = await http.get(URL, opts);
  const responseDownload = downloadFileFromResponse(response, type, "Refusal Reasons");
  return responseDownload;
};

export const getExportRefusalReasonsByID = async (type: string, ids: string[], name: string, query?: any) => {
  const URL = `${BACKEND_URL}/reasons-for-refusal/create-report?` + HelpService.getQueryString(query);
  const body = {
    file_type: type,
    search: ids,
  };
  const opts: AxiosRequestConfig = {
    responseType: "blob",
  };
  const response = await http.post(URL, body, opts);
  const responseDownload = downloadFileFromResponse(response, type, name);
  return responseDownload;
};

interface IBulkRefusalReasons {
  language?: string;
  display_order?: string;
  description?: string;
  search?: string[];
}
export const updateInBulkRefusalReasons = async (body: IBulkRefusalReasons) => {
  body.language = fromLanguajeTranslatedToKey(body.language || "");
  const URL = `${BACKEND_URL}/reasons-for-refusal/bulk-update`;
  const response = await http.put(URL, body);
  return response;
};
