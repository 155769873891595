import React, { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Container, Dropdown } from "react-bootstrap";

import Pagination from "@mui/material/Pagination";
import Table from "react-bootstrap/Table";
import { Link, useHistory } from "react-router-dom";

//API
import { ApplicationContext } from "../../../context/ApplicationContext";
import checkedimg from "../../../images/checked.svg";
import uncheckedimg from "../../../images/unchecked.svg";
import grayChecked from "../../../images/grayCheck.png";
import uncheckedpage from "../../../images/checkPage.svg";
import { Checkbox, useMediaQuery } from "@mui/material";
import LoadingSkeletonTables from "../LoadingSkeleton/LoadingSkeletonTables";
import { useTranslation } from "react-i18next";
import DistributorSetTableFilter from "../Search/DistributorSetTableFilter";
import "./style.css";
import { IDistributorsSetDOM } from "../../../api/interfaces/interfaz_distributor_Set";
import { IRowHeader } from "../../../models/table.modal";
import DistributorSetTableResponsive from "../../molecules/TransferList/responsiveTables/DistributorSetTableResponsive";

export interface IRowFromAPI {
  id: string;
  name: string;
  origin: string;
  groupData: any;
  membersOfSet: any;
  [index: string]: string;
}
interface ITableProps {
  rowsPerPage: number;
  UsePlanText: string;
  inputToSort: string;
  Create?: boolean;
  showSearchTable?: boolean;
  distributorSetArray: any;

  //filerts
  handleSearch: any;

  loading: boolean;
  currentPage: number;
  lastPage: number;
  handleChange: any;
  incrementCount?: any;
  typeSort?: string;
  setDropDownWhySort?: any;
  toSelect?: boolean;
}

export default function TableDistributorSet(props: ITableProps) {
  const { lastPage, handleChange, currentPage, distributorSetArray, loading, incrementCount, typeSort, setDropDownWhySort, toSelect } =
    props;
  const {
    distributorSetListId,
    setDistributorSetListId,
    membersOfSetListMembers,
    membersOfSetListIdSelect,
    setMembersOfSetListMembers,
    setMembersOfSetListIdSelect,
    distributorSetTableTransferData,
    setDistributorSetAllSelected,
  } = useContext(ApplicationContext);
  const matches = useMediaQuery("(max-width:780px)");
  const { t } = useTranslation();
  const history = useHistory();
  //TABLE
  const pageSize = props.rowsPerPage;

  //GENERATE AN ARRAY WITH SELECTED IDS
  const [defaultChecked, setDefaultChecked] = useState<boolean>(false);

  //FILTRO
  let distributorSetArraySort: any = [{}];
  if (distributorSetArray) {
    distributorSetArraySort = distributorSetArray;
  }

  const tableInitial: IRowHeader[] = [
    {
      field: "dropdown",
      headerName: "",
      sort: "",
    },
    {
      field: "group_data.code",
      headerName: t("ALL.GENERAL.TABLE.DIST-SET.TITLE-CODE"),
      sort: t("ALL.GENERAL.TABLE.DIST-SET.TITLE-CODE"),
    },
    {
      field: "name",
      headerName: t("ALL.GENERAL.TABLE.DIST-SET.TITLE-NAME"),
      sort: t("ALL.GENERAL.TABLE.DIST-SET.TITLE-NAME"),
    },
    {
      field: "origin",
      headerName: t("ALL.GENERAL.TABLE.DIST-SET.TITLE-ORIGIN"),
      sort: t("ALL.GENERAL.TABLE.DIST-SET.TITLE-ORIGIN"),
    },
    {
      field: "members_of_set",
      headerName: t("ALL.GENERAL.TABLE.DIST-SET.TITLE-MEMBERS"),
      sort: t("ALL.GENERAL.TABLE.DIST-SET.TITLE-MEMBERS"),
    },
  ];

  const [tabla, setTabla] = useState<IRowHeader[]>(tableInitial);
  useEffect(() => {
    setTabla(tableInitial.filter((item) => distributorSetTableTransferData.includes(item.headerName)));
  }, [distributorSetTableTransferData]);

  const createHeader = (row: IRowHeader, index: number) => (
    <th
      key={`group-${index}`}
      style={{ cursor: "pointer" }}
      // onClick={() => {
      //   setDropDownWhySort(row.sort);
      //   incrementCount(row.field);
      // }}
    >
      {row.headerName}
      {/* {props.inputToSort === row.sort && (
        <FontAwesomeIcon icon={typeSort === `asc(${row.field})` ? faChevronUp : faChevronDown} style={{ marginLeft: "5px" }} />
      )} */}
    </th>
  );

  const createRow = (row: IRowFromAPI, index: number, render: IRowHeader[]) => {
    const isItemSelected: boolean = isSelected(row.id);
    return (
      <tr
        onClick={
          !toSelect
            ? () => {}
            : () => {
                createDistributorArrayName(row.id, row.name, row.groupData);
              }
        }
        key={row?.id}
        style={{
          backgroundColor: "var(--color-white)",
          textAlign: "left",
          cursor: "pointer",
        }}
        className={!toSelect ? "" : `${isItemSelected ? "selected" : ""}`}
      >
        {defaultChecked ? (
          <td style={{ paddingLeft: "15px" }}>
            <img alt="" src={grayChecked} style={{ width: "20px", marginLeft: "10px" }}></img>
          </td>
        ) : (
          <td style={{ paddingLeft: "15px" }} className="check-table">
            <Checkbox
              checked={isItemSelected}
              style={{ display: "inline" }}
              name="gridRadios"
              id={`radios${row.id}`}
              value={`option${row.id}`}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                !toSelect
                  ? createDeleteArray(row.id, event.target.checked)
                  : createSelectArray(row.id, event.target.checked, row.name, row.groupData);
              }}
              aria-label="option 1"
            />
            &nbsp;
            {/* <FontAwesomeIcon icon={faBars} /> */}
          </td>
        )}
        {!toSelect ? (
          <>
            {render?.map((i: IRowHeader) => (
              <td
                style={{ paddingLeft: "15px" }}
                onClick={() => `${i.field}` !== "members_of_set" && history.push(`/distributors-set-info/${row.id}`)}
              >
                {`${i.field}` === "group_data.code"
                  ? row?.groupData?.code
                  : `${i.field}` === "members_of_set"
                  ? row?.membersOfSet?.map((data: any) => (
                      <Link
                        to={`/distributor-info/${data.id}`}
                        style={{
                          textDecoration: "none",
                          color: "var(--color-main-text)",
                        }}
                      >
                        <div className="input-members">
                          <div style={{ display: "flex" }}>
                            <div style={{ margin: "0 8px" }}>{data.code}</div>
                          </div>
                        </div>
                      </Link>
                    ))
                  : row[`${i.field}`]}
              </td>
            ))}
          </>
        ) : (
          <>
            {render?.map((i: IRowHeader) => (
              <td style={{ paddingLeft: "15px" }}>
                {`${i.field}` === "group_data.code"
                  ? row?.groupData?.code
                  : `${i.field}` === "members_of_set"
                  ? row?.membersOfSet?.map((data: any) => (
                      <div className="input-members">
                        <div style={{ display: "flex" }}>
                          <div style={{ margin: "0 8px" }}>{data.code}</div>
                        </div>
                      </div>
                    ))
                  : row[`${i.field}`]}
              </td>
            ))}
          </>
        )}
      </tr>
    );
  };

  //SELECT ALL LIST

  const allSelect = (allchecked: boolean, page: boolean) => {
    setDefaultChecked(allchecked);
    let AllIds: any = [];
    if (page) {
      AllIds = distributorSetArraySort.map(function (ids: any) {
        return ids.id;
      });
    } else {
      AllIds = [];
      setDistributorSetAllSelected(false);
    }
    if (allchecked) {
      setDistributorSetAllSelected(true);
    }
    setDistributorSetListId(AllIds);
  };

  const isSelected = !toSelect
    ? (id: string) => distributorSetListId.indexOf(id) !== -1
    : (id: string) => membersOfSetListIdSelect.indexOf(id) !== -1;

  const createDeleteArray = (idDelete: string, checked: boolean) => {
    if (checked) {
      setDistributorSetListId([...distributorSetListId, idDelete]);
    } else {
      setDistributorSetListId(distributorSetListId.filter((item: any) => item !== idDelete));
    }
  };

  const createSelectArray = (idDelete: string, checked: boolean, name: String, group_data: any) => {
    if (checked) {
      setMembersOfSetListMembers([
        ...membersOfSetListMembers,
        {
          id: idDelete,
          code: name,
          group: group_data.id,
          groupCode: group_data.code,
        },
      ]);
      setMembersOfSetListIdSelect([...membersOfSetListIdSelect, idDelete]);
      setDistributorSetListId([...distributorSetListId, idDelete]);
    } else {
      setMembersOfSetListMembers(membersOfSetListMembers.filter((item: any) => item.id !== idDelete));
      setMembersOfSetListIdSelect(membersOfSetListIdSelect.filter((item: any) => item !== idDelete));
      setDistributorSetListId(distributorSetListId.filter((item: any) => item !== idDelete));
    }
  };

  const createDistributorArrayName = (id: string, name: string, group_data: any) => {
    const isOnTheArray = membersOfSetListIdSelect.indexOf(id) !== -1;
    if (!isOnTheArray) {
      setMembersOfSetListMembers([
        ...membersOfSetListMembers,
        {
          id,
          code: name,
          group: group_data.id,
          groupCode: group_data.code,
        },
      ]);
      setMembersOfSetListIdSelect([...membersOfSetListIdSelect, id]);
    } else {
      setMembersOfSetListMembers(membersOfSetListMembers.filter((item: any) => item.id !== id));
      setMembersOfSetListIdSelect(membersOfSetListIdSelect.filter((item: any) => item !== id));
    }
  };

  return (
    <div>
      <Card>
        {!loading ? (
          <>
            {!matches ? (
              <Table responsive="xl" striped hover style={{ border: "2px solid var(--color-input-border)" }}>
                {/* TABLE HEADER */}
                <thead
                  style={{
                    marginBottom: "10px",
                    textAlign: "left",
                    backgroundColor: "rgba(0, 0, 0, 0.03)",
                  }}
                >
                  <tr>
                    <th className="check-table">
                      <Container style={{ padding: 12 }}>
                        <Dropdown style={{ border: "none" }}>
                          <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                            {defaultChecked ? (
                              <img alt="" src={checkedimg} style={{ width: "20px" }}></img>
                            ) : (
                              <img alt="" src={uncheckedimg} style={{ width: "20px" }}></img>
                            )}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div onClick={() => allSelect(true, false)}>
                              <Dropdown.Item href="#/action-1">
                                <img alt="" src={checkedimg}></img> {t("TABLES-SELECT-ALL")}
                              </Dropdown.Item>
                            </div>
                            <div onClick={() => allSelect(false, true)}>
                              <Dropdown.Item href="#/action-1">
                                <img alt="" src={uncheckedpage}></img> {t("TABLES-SELECT-PAGE")}
                              </Dropdown.Item>
                            </div>
                            <div onClick={() => allSelect(false, false)}>
                              <Dropdown.Item href="#/action-3">
                                <img alt="" src={uncheckedimg}></img> {t("TABLES-UNSELECT-PAGE")}
                              </Dropdown.Item>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Container>
                    </th>

                    {tabla?.map(createHeader)}
                  </tr>

                  <DistributorSetTableFilter handleSearch={props.handleSearch} />
                </thead>
                <tbody style={{ cursor: "pointer !important" }}>
                  {/* TABLE BODY */}
                  {distributorSetArraySort?.map((row: IRowFromAPI, index: number) => createRow(row, index, tabla))}
                </tbody>
              </Table>
            ) : (
              <DistributorSetTableResponsive
                render={distributorSetArraySort}
                createDistributorArrayName={createDistributorArrayName}
                isSelected={isSelected}
                toSelect={toSelect}
              />
            )}
          </>
        ) : (
          <LoadingSkeletonTables />
        )}
        <div className="pb-2" style={{ display: "flex", justifyContent: "space-around" }}>
          <Pagination
            style={{ paddingTop: 10 }}
            count={Math.ceil(lastPage / pageSize)}
            page={currentPage}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
            color="primary"
            boundaryCount={3}
            siblingCount={4}
          />
        </div>
      </Card>
    </div>
  );
}
