import { useContext } from "react";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { faThumbtack, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import Tooltip from "@mui/material/Tooltip";
import InputClear from "../../atoms/InputClear";
import DropDown from "../../atoms/DropDown";
import { useFilterContext } from "../../../context";
import useAutocomplete from "../../atoms/AutocopleteInput/useAutocomplete";
import AutoCompleteInput from "../../atoms/AutocopleteInput/AutocompleteInput";
import { createAddapterCustomer } from "../../../hooks/Customers/adapters/customerAdapter";

interface ITable {
  listTypeOptions: any;
  listBusinessOptions: any;
  handleSearch: any;
}

export default function CustomerTableFilter(props: ITable) {
  const {
    inputUsernameSearch,
    setInputUsernameSearch,
    inputFirstnameSearch,
    setInputFirstnameSearch,
    inputLastnameSearch,
    setInputLastnameSearch,
    inputTypeSearchCustomer,
    setInputTypeSearchCustomer,
    inputBusinessSearch,
    setInputBusinessSearch,
    inputZipCodeSearch,
    setInputZipCodeSearch,
    inputCitySearch,
    setInputCitySearch,
    inputCountrySearch,
    setInputCountrySearch,
    inputMapUrlSearch,
    setInputMapUrlSearch,
    inputGeneralTermsAcceptedSearch,
    setGeneralTermsAcceptedSearch,
    inputPrivacyPolicyAcceptedSearch,
    setPrivacyPolicyAcceptedSearch,
    inputRegistrationDateSearch,
    setRegistrationDateSearch,
    inputCompanyNameSearch,
    setInputCompanyNameSearch,
    inputEmailSearchCustomer,
    setInputEmailSearchCustomer,
    inputPhoneSearch,
    setInputPhoneSearch,
    inputLanguajeSearch,
    setInputLanguajeSearch,
    showLateralContainerSearch,
    setShowLateralContainerSearch,
  } = useFilterContext();
  const { listTypeOptions, listBusinessOptions, handleSearch } = props;

  const { typeOfSearch, setTypeOfSearch, customerTableTransferData } = useContext(ApplicationContext);
  const listCountry = ["", `${t("COUNTRY-FRANCE")}`, `${t("COUNTRY-SPAIN")}`, `${t("COUNTRY-UK")}`, `${t("COUNTRY-GERMANY")}`];
  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleSearch();
  };
  //** Filters */

  const companyName = useAutocomplete(
    "/customers/get-all?autocomplete=true&company_name=",
    handleSearch,
    inputCompanyNameSearch,
    setInputCompanyNameSearch,
    "company_name"
  );
  const username = useAutocomplete(
    "/customers/get-all?autocomplete=true&username=",
    handleSearch,
    inputUsernameSearch,
    setInputUsernameSearch,
    "userUsername",
    createAddapterCustomer
  );

  return (
    <>
      {(typeOfSearch === 0 ? showLateralContainerSearch : false) === true && (
        <tr style={{ overflow: "scroll" }}>
          <td>
            <Tooltip title={`${t("FILER-TO-DOCK")}`} placement="bottom">
              <div style={{ width: "60px" }}>
                <FontAwesomeIcon
                  className="grayBackground-icon"
                  size="2x"
                  style={{ cursor: "pointer" }}
                  icon={faThumbtack}
                  onClick={() => {
                    setTypeOfSearch(1);
                  }}
                />
              </div>
            </Tooltip>
          </td>
          {customerTableTransferData.includes(`${t("SORT.CUSTOMER.INPUT-USERNAME")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <AutoCompleteInput {...username} freeSolo />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputUsernameSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {customerTableTransferData.includes(`${t("SORT.CUSTOMER.INPUT-FIRST-NAME")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <InputClear
                      type="text"
                      name="inputFirstnameSearch"
                      placeholder=""
                      currentValue={inputFirstnameSearch}
                      handleOnInputValue={setInputFirstnameSearch}
                    />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputFirstnameSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {customerTableTransferData.includes(`${t("SORT.CUSTOMER.INPUT-LAST-NAME")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <InputClear
                      type="text"
                      name="inputLastnameSearch"
                      placeholder=""
                      currentValue={inputLastnameSearch}
                      handleOnInputValue={setInputLastnameSearch}
                    />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputLastnameSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {customerTableTransferData.includes(`${t("SORT.CUSTOMER.INPUT-TYPE")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div>
                    <DropDown
                      options={listTypeOptions}
                      selectedItem={inputTypeSearchCustomer}
                      onSelect={(data: string) => {
                        setInputTypeSearchCustomer(data);
                        handleSearch();
                      }}
                      style={{
                        color: "var(--color-input-text)",
                        borderRadius: "2px",
                        textAlign: "left",
                        fontFamily: "Arial",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "14px",
                        lineHeight: "16px",
                        width: `100%`,
                        maxWidth: "100px",
                        overflow: "hidden",
                        height: "30px",
                        border: "1px solid #ced4da",
                        padding: "4px",
                      }}
                      widthMenu="230px"
                      isCompact
                    />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputTypeSearchCustomer("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {customerTableTransferData.includes(`${t("SORT.CUSTOMER.INPUT-BUSINESS")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div>
                    <DropDown
                      options={listBusinessOptions}
                      selectedItem={inputBusinessSearch}
                      onSelect={(data: string) => {
                        setInputBusinessSearch(data);
                        handleSearch();
                      }}
                      style={{
                        color: "var(--color-input-text)",
                        borderRadius: "2px",
                        textAlign: "left",
                        fontFamily: "Arial",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "14px",
                        lineHeight: "16px",
                        width: `100%`,
                        maxWidth: "100px",
                        overflow: "hidden",
                        height: "30px",
                        border: "1px solid #ced4da",
                        padding: "4px",
                      }}
                      widthMenu="230px"
                      isCompact
                    />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputBusinessSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {customerTableTransferData.includes(`${t("SORT.CUSTOMER.INPUT-COMPANY-NAME")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <AutoCompleteInput {...companyName} freeSolo />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputCompanyNameSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {customerTableTransferData.includes(`${t("SORT.CUSTOMER.INPUT-ZIPCODE")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <InputClear
                      type="text"
                      name="inputZipCodeSearch"
                      placeholder=""
                      currentValue={inputZipCodeSearch}
                      handleOnInputValue={setInputZipCodeSearch}
                    />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputZipCodeSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {customerTableTransferData.includes(`${t("SORT.CUSTOMER.INPUT-CITY")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <InputClear
                      type="text"
                      name="inputCitySearch"
                      placeholder=""
                      currentValue={inputCitySearch}
                      handleOnInputValue={setInputCitySearch}
                    />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputCitySearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {customerTableTransferData.includes(`${t("SORT.CUSTOMER.INPUT-COUNTRY")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div>
                    <DropDown
                      options={listCountry}
                      selectedItem={inputCountrySearch}
                      onSelect={(data: string) => {
                        setInputCountrySearch(data);
                        handleSearch();
                      }}
                      style={{
                        color: "var(--color-input-text)",
                        borderRadius: "2px",
                        textAlign: "left",
                        fontFamily: "Arial",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "14px",
                        lineHeight: "16px",
                        width: `100%`,
                        maxWidth: "100px",
                        overflow: "hidden",
                        height: "30px",
                        border: "1px solid #ced4da",
                        padding: "4px",
                      }}
                      widthMenu="230px"
                      isCompact
                    />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputCountrySearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {customerTableTransferData.includes(`${t("SORT.CUSTOMER.INPUT-EMAIL")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <InputClear
                      type="text"
                      name="inputZipCodeSearch"
                      placeholder=""
                      currentValue={inputEmailSearchCustomer}
                      handleOnInputValue={setInputEmailSearchCustomer}
                    />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputEmailSearchCustomer("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {customerTableTransferData.includes(`${t("SORT.CUSTOMER.INPUT-MOBILE-PHONE")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <InputClear
                      type="text"
                      name="inputPhoneSearch"
                      placeholder=""
                      currentValue={inputPhoneSearch}
                      handleOnInputValue={setInputPhoneSearch}
                    />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputPhoneSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {customerTableTransferData.includes(`${t("TABLE.USER.TITLE-LANGUAJE")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div>
                    <DropDown
                      options={["", `${t("LANGUAGE-LIST-ENGLISH")}`, `${t("LANGUAGE-LIST-FRENCH")}`]}
                      selectedItem={inputLanguajeSearch}
                      onSelect={(data: string) => {
                        setInputLanguajeSearch(data);
                        handleSearch();
                      }}
                      style={{
                        color: "var(--color-input-text)",
                        borderRadius: "2px",
                        textAlign: "left",
                        fontFamily: "Arial",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "14px",
                        lineHeight: "16px",
                        width: `100%`,
                        maxWidth: "100px",
                        overflow: "hidden",
                        height: "30px",
                        border: "1px solid #ced4da",
                        padding: "4px",
                      }}
                      widthMenu="230px"
                      isCompact
                    />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputLanguajeSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
        </tr>
      )}
    </>
  );
}
