import { LocalStorageKeys, setInLocalStorageArray } from "./local-storage-manager";

export const clearTables = () => {
  setInLocalStorageArray(LocalStorageKeys.BRAND_TABLE, []);
  setInLocalStorageArray(LocalStorageKeys.BRAND_TABLE_R, []);
  setInLocalStorageArray(LocalStorageKeys.PRODUCT_TABLE, []);
  setInLocalStorageArray(LocalStorageKeys.PRODUCT_TABLE_R, []);
  setInLocalStorageArray(LocalStorageKeys.GROUP_TABLE, []);
  setInLocalStorageArray(LocalStorageKeys.GROUP_TABLE_R, []);
  setInLocalStorageArray(LocalStorageKeys.DISTRIBUTOR_TABLE, []);
  setInLocalStorageArray(LocalStorageKeys.DISTRIBUTOR_TABLE_R, []);
  setInLocalStorageArray(LocalStorageKeys.DISTRIBUTOR_SET_TABLE, []);
  setInLocalStorageArray(LocalStorageKeys.DISTRIBUTOR_SET_TABLE_R, []);
  setInLocalStorageArray(LocalStorageKeys.CUSTOMERS_TABLE, []);
  setInLocalStorageArray(LocalStorageKeys.CUSTOMERS_TABLE_R, []);
  setInLocalStorageArray(LocalStorageKeys.EXCHANGES_TABLE, []);
  setInLocalStorageArray(LocalStorageKeys.EXCHANGES_TABLE_R, []);
  setInLocalStorageArray(LocalStorageKeys.REASON_FOR_EXCHANGE_TABLE, []);
  setInLocalStorageArray(LocalStorageKeys.REASON_FOR_EXCHANGE_TABLE_R, []);
  setInLocalStorageArray(LocalStorageKeys.REASON_FOR_REFUSAL_TABLE, []);
  setInLocalStorageArray(LocalStorageKeys.REASON_FOR_REFUSAL_TABLE_R, []);
  setInLocalStorageArray(LocalStorageKeys.CONTENTS_TABLE, []);
  setInLocalStorageArray(LocalStorageKeys.CONTENTS_TABLE_R, []);
  setInLocalStorageArray(LocalStorageKeys.CHANGELOGS_TABLE, []);
  setInLocalStorageArray(LocalStorageKeys.CHANGELOGS_TABLE_R, []);
  setInLocalStorageArray(LocalStorageKeys.USERS_TABLE, []);
  setInLocalStorageArray(LocalStorageKeys.USERS_TABLE_R, []);
};
