import { GoogleMap, LoadScript, Marker, MarkerProps, useJsApiLoader } from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../config/settings.json";
interface ICoordinates {
  lat: number | undefined;
  lng: number | undefined;
}

interface IMaps {
  positions: ICoordinates[];
}
const Maps = ({ positions }: IMaps) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `${GOOGLE_API_KEY}`,
    libraries: ["geometry", "drawing", "places"],
  });

  const mapStyles = {
    minHeight: "80vh",
    height: "100%",
    width: "100%",
  };
  const center = {
    lat: 0,
    lng: -180,
  };

  return (
    <>
      {isLoaded && (
        <GoogleMap mapContainerStyle={mapStyles} zoom={2} center={center}>
          {positions.map((item: any) => (
            <Marker position={item} />
          ))}
        </GoogleMap>
      )}
    </>
  );
};

export default Maps;
