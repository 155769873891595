import { AxiosRequestConfig } from "axios";
import { axios as http } from "../../frameworks_and_drivers/Axios";
import { BACKEND_URL } from "../../config/settings.json";
import { IDistributorList, IDistributorListByID } from "../interfaces/interfaz_distributor";
import { downloadFileFromResponse } from "./helps";
import HelpService from "../HelpService";
import { loadAbort } from "../../helpers/loadAbort";
import { IPicturesAPI, IPicturesDOM } from "../interfaces/globalInterfaces";

export const getAllDistributors = (props?: any) => {
  const controller = loadAbort();
  const { query } = props;
  const URL = `${BACKEND_URL}/distributors/get-all?` + HelpService.getQueryString(query);
  return {
    call: http.get<IDistributorList>(URL, { signal: controller.signal }),
    controller,
  };
};

export const getAllDistributorsByCode = async (code: string) => {
  const URL = `${BACKEND_URL}/distributors/get-all?code=${code}`;
  const response = await http.get<IDistributorList>(URL);
  return response;
};

export const getAllDistributorsByGroup = async (groupId: string) => {
  const URL = `${BACKEND_URL}/distributors/get-all?group=${groupId}`;
  const response = await http.get<IDistributorList>(URL);
  return response;
};

export const getDistributorsById = async (objectId: string) => {
  const URL = `${BACKEND_URL}/distributors/${objectId}/get-one`;
  const response = await http.get<IDistributorListByID>(URL);
  return response;
};

export const createDistributor = async (
  city_id: string,
  group_id: string,
  name: string,
  code: string,
  client_account_number: string,
  is_visible: boolean,
  logo: any,
  evaluation: number,
  description: string,
  email: string,
  address: string,
  postal_code: string,
  city: string,
  phone: string,
  opening_hours: string,
  coordinates: string,
  map_url: string,
  responsible_email: string,
  delivery_account_number: string,
  is_web: boolean,
  add_members_of_set: string[]
) => {
  // let body= new FormData()
  // body.append("city_id": city_id)
  // body.append("group": group_id)
  // body.append("name": name)
  // body.append("code": code)
  // body.append("client_account_number":client_account_number)
  // body.append("is_visible": is_visible)
  // body.append("logo": logo)
  // body.append("evaluation": evaluation)
  // body.append("email": email)
  // bodyFormData.append("address",JSON.stringify({
  //     "address": address,
  //     "postal_code": postal_code,
  //     "city": city,
  //     "phone": phone,
  //     "opening_hours": opening_hours,
  // }))
  const body = {
    // "city_id": city_id,
    group: group_id,
    name: name,
    code: code,
    client_account_number: client_account_number,
    is_visible: is_visible,
    logo: logo,
    evaluation: evaluation,
    description: description,
    email: email,
    responsible_email,
    delivery_account_number,
    is_web,
    address: {
      address: address,
      postal_code: postal_code,
      city: city,
      phone: phone,
      opening_hours: opening_hours,
      coordinates: coordinates,
      map_url: map_url,
    },
    add_members_of_set,
  };
  const URL = `${BACKEND_URL}/distributors/create-one`;
  const response = await http.post(URL, body);
  // const response = await http.post(URL,body,opts2);
  return response;
};

export const updateDistributors = async (
  objectId: string,
  city_id: string,
  group_id: string,
  name: string,
  code: string,
  client_account_number: string,
  is_visible: boolean,
  logo: any,
  evaluation: number,
  description: string,
  email: string,
  address: string,
  postal_code: string,
  city: string,
  phone: string,
  opening_hours: string,
  coordinates: string,
  map_url: string,
  responsible_email: string,
  delivery_account_number: string,
  is_web: boolean,
  add_members_of_set: string[],
  remove_members_of_set: string[]
) => {
  // let body= new FormData()
  // body.append("city_id": city_id)
  // body.append("group": group_id)
  // body.append("name": name)
  // body.append("code": code)
  // body.append("client_account_number":client_account_number)
  // body.append("is_visible": is_visible)
  // body.append("logo": logo)
  // body.append("evaluation": evaluation)
  // body.append("email": email)
  // bodyFormData.append("address",JSON.stringify({
  //     "address": address,
  //     "postal_code": postal_code,
  //     "city": city,
  //     "phone": phone,
  //     "opening_hours": opening_hours,
  // }))
  let body: any = {
    // "city_id": city_id,
    group: group_id,
    name: name,
    code: code,
    client_account_number: client_account_number,
    is_visible: is_visible,
    evaluation: evaluation,
    description: description,
    email: email,
    responsible_email: responsible_email,
    delivery_account_number,
    is_web,
    address: {
      address: address,
      postal_code: postal_code,
      city: city,
      phone: phone,
      opening_hours: opening_hours,
      coordinates: coordinates,
      map_url: map_url,
    },
    add_members_of_set,
    remove_members_of_set,
    logo: logo,
  };

  const URL = `${BACKEND_URL}/distributors/${objectId}/update-one`;
  const response = await http.put(URL, body);
  // const response = await http.post(URL,body,opts2);
  return response;
};

export const deleteDistributor = async (objectId: string) => {
  const URL = `${BACKEND_URL}/distributors/${objectId}/delete-one`;
  const response = await http.put(URL);
  return response;
};

export const deleteManyDistributors = async (ids: string[]) => {
  const URL = `${BACKEND_URL}/distributors/delete-many`;
  const body = {
    items: ids,
  };
  const response = await http.put(URL, body);
  return response;
};

export const getExportDistributors = async (type: string) => {
  const URL = `${BACKEND_URL}/distributors/generate-report`;
  const opts: AxiosRequestConfig = {
    params: {
      export_type: type,
    },
    responseType: "blob",
  };
  const response = await http.get(URL, opts);
  const responseDownload = downloadFileFromResponse(response, type, "Distributors");
  return responseDownload;
};

export const getExportDistributorsByCode = async (type: string, code: string) => {
  const URL = `${BACKEND_URL}/distributors/generate-report?code=${"distributor_1.1.1"}`;
  const opts: AxiosRequestConfig = {
    params: {
      export_type: type,
    },
    responseType: "blob",
  };
  const response = await http.get(URL, opts);
  const responseDownload = downloadFileFromResponse(response, type, "Distributors");
  return responseDownload;
};

export const getExportDistributorsByGroup = async (type: string, groupId: string, name: string) => {
  const URL = `${BACKEND_URL}/distributors/generate-report?group=${groupId}`;
  const opts: AxiosRequestConfig = {
    params: {
      export_type: type,
    },
    responseType: "blob",
  };
  const response = await http.get(URL, opts);
  const responseDownload = downloadFileFromResponse(response, type, name);
  return responseDownload;
};

export const getExportDistributorsByID = async (type: string, ids: string[], name: string, query?: any) => {
  const URL = `${BACKEND_URL}/distributors/create-report?` + HelpService.getQueryString(query);
  const body = {
    file_type: type,
    search: ids,
  };
  const opts: AxiosRequestConfig = {
    responseType: "blob",
  };
  const response = await http.post(URL, body, opts);
  const responseDownload = downloadFileFromResponse(response, type, name);
  return responseDownload;
};

export const updateInBulkDistributors = async (bodyDistributor: any) => {
  const URL = `${BACKEND_URL}/distributors/bulk-update`;
  const body = {
    group: bodyDistributor.groupId,
    name: bodyDistributor.name,
    client_account_number: bodyDistributor.clientAccountNumber,
    is_visible: bodyDistributor.visible,
    is_web: bodyDistributor.web,
    logo: bodyDistributor.logo,
    evaluation: 0,
    description: bodyDistributor.description,
    email: bodyDistributor.distributorEmailAddress,
    responsible_email: bodyDistributor.responsableEmailAddress,
    delivery_account_number: bodyDistributor.deliveryAccountNumber,
    address: {
      address: bodyDistributor.address,
      postal_code: bodyDistributor.postalCode,
      city: bodyDistributor.city,
      phone: bodyDistributor.phone,
      opening_hours: bodyDistributor.openingHours,
      coordinates: bodyDistributor.geographicalCoordinates,
      map_url: bodyDistributor.mapURL,
    },
    search: bodyDistributor.search,
  };
  const response = await http.put(URL, body);
  return response;
};