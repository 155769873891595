import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxes, faUser } from "@fortawesome/free-solid-svg-icons";
import { IRowBrandFromAPI } from "../../../organisms/TableBrands/TableBrands";
import ImagesApi from "../../../pages/ExchangesInfo/ImagesApi";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IRowCustomersFromAPI } from "../../../organisms/CustomersTable/CustomersTable";
import { fromInfoToTranslatedBussiness } from "../../../../utilities/usetBussinessTransalte";
import { fromInfoToTranslatedCountry } from "../../../../utilities/countryTraslations";
export default function CustomerTableResponsive({
  render,
  handleCustomer,
  Create,
}: {
  render: any[];
  handleCustomer: Function;
  Create?: boolean;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div>
      {render?.map((row: IRowCustomersFromAPI) => (
        <Accordion
          sx={{
            backgroundColor: "#f7f7f7",
            borderRadius: "0.3rem 0.3rem",
            border: "solid 0.0625rem #e0e0e0",
            margin: "8px",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <div
              style={{ maxWidth: "230px", overflow: "hidden" }}
              className="flex-warranty flex-between"
              onClick={() => {
                !Create ? history.push(`/customer-info/${row.id}`) : handleCustomer(row);
              }}
            >
              <div className="container-warranty flex-start">
                <FontAwesomeIcon className="icon_size" icon={faUser} />

                <div>
                  <h6 className="h6-stl" style={{ margin: "15px 5px" }}>
                    <strong>
                      {row.userFirstName} {row.userLastName}
                    </strong>
                  </h6>
                </div>
              </div>
              <div className="container-warranty flex-end wrap-warranty"></div>
            </div>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: "#fff",
              fontSize: "12px",
            }}
          >
            <div>
              <ul>
                <li>
                  <h6 className="font-response">
                    <strong>{t("SORT.CUSTOMER.INPUT-USERNAME")} </strong>
                    {row.userUsername}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{t("SORT.CUSTOMER.INPUT-FIRST-NAME")} </strong>
                    {row.userFirstName}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{t("SORT.CUSTOMER.INPUT-LAST-NAME")} </strong>
                    {row.userLastName}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{t("SORT.CUSTOMER.INPUT-TYPE")} </strong>
                    {row.type}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{t("SORT.CUSTOMER.INPUT-BUSINESS")} </strong>
                    {fromInfoToTranslatedBussiness(row.business)}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{t("SORT.CUSTOMER.INPUT-ZIPCODE")} </strong>
                    {row.userZipCode}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{t("SORT.CUSTOMER.INPUT-CITY")} </strong>
                    {row.userCity}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{t("SORT.CUSTOMER.INPUT-COUNTRY")} </strong>
                    {fromInfoToTranslatedCountry(row.userCountry)}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{t("SORT.CUSTOMER.INPUT-EMAIL")} </strong>
                    {row.userEmail}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{t("SORT.CUSTOMER.INPUT-MOBILE-PHONE")} </strong>
                    {row.userMobile}
                  </h6>
                </li>
              </ul>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
