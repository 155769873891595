import React, { useContext, useEffect, useState } from "react";
import TransferModal from "../../organisms/TransferModal/TransferModal";
import { useTranslation } from "react-i18next";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { LocalStorageKeys, getInLocalStorageArray } from "../../../helpers/local-storage-manager";
export interface IRowHeaderBrand {
  field: string;
  headerName: string;
  sort: string;
}
interface IProps {
  open: boolean;
  setOpen: any;
}

export default function BrandTableTransfer(props: IProps) {
  const { brandTableTransferData, setBrandTableTransferData } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const { open, setOpen } = props;
  const tableBrandInitial: string[] = [
    t("SEARCH.BRAND.CODE"),
    t("SEARCH.BRAND.NAME"),
    t("SEARCH.BRAND.WEBSITE"),
    t("TABLE.BRAND.TITLE-LOGO"),
  ];

  const [dataRight, setDataRight] = useState<string[]>([]);

  useEffect(() => {
    const initialArray = getInLocalStorageArray(LocalStorageKeys.BRAND_TABLE);
    const initialArrayRight = getInLocalStorageArray(LocalStorageKeys.BRAND_TABLE_R);
    setBrandTableTransferData(initialArray?.length > 0 ? initialArray : tableBrandInitial);
    setDataRight(initialArrayRight?.length > 0 ? initialArrayRight : []);
  }, []);
  return (
    <TransferModal
      keyValueRight={`${LocalStorageKeys.BRAND_TABLE_R}`}
      keyValue={`${LocalStorageKeys.BRAND_TABLE}`}
      itemsLeft={brandTableTransferData}
      itemsRight={dataRight}
      setItemsLeft={setBrandTableTransferData}
      setItemsRight={setDataRight}
      openConfirm={open}
      setOpenConfirm={setOpen}
    />
  );
}
