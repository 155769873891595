import { AxiosRequestConfig } from "axios";
import { axios as http } from "../../frameworks_and_drivers/Axios";
import { BACKEND_URL } from "../../config/settings.json";
import { IProductsList, IProductsListId } from "../interfaces/interfaz_products";
import { downloadFileFromResponse } from "./helps";
import HelpService from "../HelpService";
import { loadAbort } from "../../helpers/loadAbort";
import { IPicturesAPI } from "../interfaces/globalInterfaces";
import { fromTypeProductsTranslatedToKey } from "../../utilities/ProductsTypesTraslations";
import { fromWaarantyTranslatedToKey } from "../../utilities/usetBussinessTransalte";

const opts2 = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};
export const getAllProducts = (props?: any) => {
  const controller = loadAbort();
  const { query } = props;
  const URL = `${BACKEND_URL}/products/get-all?` + HelpService.getQueryString(query);
  return {
    call: http.get<IProductsList>(URL, { signal: controller.signal }),
    controller,
  };
};

export const getProductsById = async (objectId: string) => {
  const URL = `${BACKEND_URL}/products/${objectId}/get-one`;
  const response = await http.get<IProductsListId>(URL);
  return response;
};

export const CreateProducts = async (
  brand_id: string,
  reference: string,
  old_reference: string,
  name: string,
  type: string,
  defaultID: boolean,
  picture: IPicturesAPI | undefined,
  time_warranty: string,
  unit_price: string,
  available: boolean,
  currency: string,
  description: string,
  online_doc: string,
  ean_number: string,
  ean_image: string,
  brochure: IPicturesAPI | undefined
) => {
  // let body= new FormData()
  // body.append("brand": brand_id)
  // body.append("old_reference": old_reference)
  // body.append("name": name)
  // body.append("type": type)
  // body.append("default": defaultID)
  // body.append("picture": picture)
  // body.append("time_warranty": time_warranty)
  // body.append("unit_price": unit_price)
  // body.append("available": available)
  // body.append("currency": "€")
  // bodyFormData.append("marketing",JSON.stringify({
  //     "description": description,
  //     "online_doc": online_doc,
  //     "ean_number": ean_number,
  //     "ean_image": ean_image
  // }))
  const body = {
    brand: brand_id,
    reference: reference,
    old_reference: old_reference,
    name: name,
    type: type,
    default: defaultID,
    picture: picture,
    time_warranty: time_warranty,
    unit_price: unit_price,
    available: available,
    currency: "€",
    marketing: {
      description: description,
      online_doc: online_doc,
      ean_number: ean_number,
      ean_image: "image",
      brochure: brochure,
    },
  };
  const URL = `${BACKEND_URL}/products/create-one`;
  const response = await http.post(URL, body);
  // const response = await http.post(URL,body,opts2);
  return response;
};

export const updateProducts = async (
  objectId: string,
  brand_id: string,
  reference: string,
  old_reference: string,
  name: string,
  type: string,
  defaultID: boolean,
  picture: IPicturesAPI | undefined,
  time_warranty: string,
  unit_price: string,
  available: boolean,
  currency: string,
  description: string,
  online_doc: string,
  ean_number: string,
  ean_image: string,
  brochure: IPicturesAPI | undefined
) => {
  // let body= new FormData()
  // body.append("brand": brand_id)
  // body.append("old_reference": old_reference)
  // body.append("name": name)
  // body.append("type": type)
  // body.append("default": defaultID)
  // body.append("picture": picture)
  // body.append("time_warranty": time_warranty)
  // body.append("unit_price": unit_price)
  // body.append("available": available)
  // body.append("currency": "€")
  // bodyFormData.append("marketing",JSON.stringify({
  //     "description": description,
  //     "online_doc": online_doc,
  //     "ean_number": ean_number,
  //     "ean_image": ean_image
  // }))
  let body: any = {
    brand: brand_id,
    reference: reference,
    old_reference: old_reference,
    name: name,
    type: type,
    default: defaultID,
    time_warranty: time_warranty,
    unit_price: unit_price,
    available: available,
    currency: "€",
    picture,
    marketing: {
      description: description,
      online_doc: online_doc,
      ean_number: ean_number,
      ean_image: "image",
      brochure,
    },
  };

  const URL = `${BACKEND_URL}/products/${objectId}/update-one`;
  const response = await http.put(URL, body);
  // const response = await http.post(URL,body,opts2);
  return response;
};

export const deleteProducts = async (objectId: string) => {
  const URL = `${BACKEND_URL}/products/${objectId}/delete-one`;
  const response = await http.put(URL);
  return response;
};
export const enable_disbleProducts = async (objectId: string, available: boolean) => {
  const body = {
    available: available,
  };
  const URL = `${BACKEND_URL}/products/${objectId}/make-available`;
  const response = await http.put(URL, body);
  return response;
};

export const deleteManyProducts = async (ids: string[]) => {
  const URL = `${BACKEND_URL}/products/delete-many`;
  const body = {
    items: ids,
  };
  const response = await http.put(URL, body);
  return response;
};

export const getExportProducts = async (type: string, name: string) => {
  const URL = `${BACKEND_URL}/products/generate-report`;
  const opts: AxiosRequestConfig = {
    params: {
      export_type: type,
    },
    responseType: "blob",
  };
  const response = await http.get(URL, opts);
  const responseDownload = downloadFileFromResponse(response, type, name);
  return responseDownload;
};

export const getExportProductsByID = async (type: string, ids: string[], name: string, query?: any) => {
  const URL = `${BACKEND_URL}/products/create-report?` + HelpService.getQueryString(query);
  const body = {
    file_type: type,
    search: ids,
  };
  const opts: AxiosRequestConfig = {
    responseType: "blob",
  };
  const response = await http.post(URL, body, opts);
  const responseDownload = downloadFileFromResponse(response, type, name);
  return responseDownload;
};

export const updateInBulkProducts = async (bodyProduct: any) => {
  const URL = `${BACKEND_URL}/products/bulk-update`;
  const body = {
    brand: bodyProduct.brandId,
    old_reference: bodyProduct.reference,
    name: bodyProduct.name,
    type: fromTypeProductsTranslatedToKey(bodyProduct.selectedType),
    default: bodyProduct.checkDefault,
    picture: bodyProduct.photo,
    time_warranty: fromWaarantyTranslatedToKey(bodyProduct.warranty),
    unit_price: bodyProduct.price,
    available: bodyProduct.checkedAvailable,
    currency: "€",
    marketing: {
      brochure: bodyProduct.brochure,
      description: bodyProduct.description,
      online_doc: bodyProduct.onlineDoc,
      ean_number: bodyProduct.EAN,
      ean_image: "image",
    },
    search: bodyProduct.search,
  };
  const response = await http.put(URL, body);
  return response;
};
