import React from "react";
import { Hint } from "@zendeskgarden/react-forms";
import { Row, Col } from "@zendeskgarden/react-grid";
import Dropzone, { IDropzoneProps } from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import "./fileAttachement.css";
import { useTranslation } from "react-i18next";

interface IFileProps {
  handleChangeStatus: any;
  fileName: any;
  disabled?: boolean;
}

const FileAttachement = (props: IFileProps) => {
  const { t } = useTranslation();

  const handleSubmit: IDropzoneProps["onSubmit"] = (files, allFiles) => {
    // console.log(files.map((f) => f.meta));
    allFiles.forEach((f) => f.remove());
  };

  return (
    <React.Fragment>
      <Row>
        <Dropzone
          disabled={props.disabled}
          onChangeStatus={props.handleChangeStatus}
          onSubmit={handleSubmit}
          maxFiles={1}
          maxSizeBytes={5242880}
          accept="image/jpeg,image/png,image/jpg"
          // styles={{ dropzone: { minHeight: 150, maxHeight: 200, overflow:'hidden'},
          //   dropzoneReject: { borderColor: 'var(--color-required)', backgroundColor: '#DAA' },
          //   inputLabel: (files, extra) => (extra.reject ? { color: 'var(--color-required)' } : {})}}
          styles={{
            dropzone: {
              minHeight: "50px",
              maxHeight: "200px",
              overflow: "hidden",
              background: "#F2F2F2",
              color: "var(--color-main-text)",
              maxWidth: "100%",
              margin: "5px 0 25px",
              borderRadius: "5px",
              marginLeft: "0",
            },
            dropzoneReject: {
              borderColor: "var(--color-required)",
              backgroundColor: "#DAA",
            },
            inputLabel: (files, extra) => (extra.reject ? { color: "var(--color-required)" } : {}),
          }}
          inputContent={(files, extra) => (extra.reject ? `${t("FILE-INVALID")}` : `${t("FILE-PLACEHOLDER")}`)}
          submitButtonContent={`${t("FILE-BUTTON-REMOVE")}`}
          //maxSizeBytes={102400}
        />
        {/* <Col>
          <div style={{ width: "500px !important", textAlign: "left" }}>
            <Hint style={{ width: "100%", fontWeight: "bold", textAlign: "left" }}>{`${t("FILE-DESCRIPTION")}:${props.fileName}`}</Hint>
          </div>
        </Col> */}
      </Row>
    </React.Fragment>
  );
};

export default FileAttachement;
