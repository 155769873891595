import React, { useContext } from "react";
import LateralContainer from "../LateralContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWrench, faEllipsisH, faSortAlphaDown, faChevronLeft, faThumbtack } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import InputClear from "../../atoms/InputClear";
import DropDown from "../../atoms/DropDown";
import { t } from "i18next";
import { IProductSearch } from "./interfaces";
import { ApplicationContext } from "../../../context/ApplicationContext";
import Tooltip from "@mui/material/Tooltip";
import "../../../constants/global.css";
import { useMediaQuery } from "@mui/material";
import { useFilterContext } from "../../../context";
import useAutocomplete from "../../atoms/AutocopleteInput/useAutocomplete";
import { createAddapterProduct } from "../../../hooks/Products/adapters/productAdapter";
import AutoCompleteInput from "../../atoms/AutocopleteInput/AutocompleteInput";

export default function ProductSearch(props: IProductSearch) {
  const listCheck = ["", t("TABLE.DISTRIBUTORS-TITLE-YES"), t("TABLE.DISTRIBUTORS-TITLE-NO")];
  const listTypeOptionsWarranty = [`${t("TYPE-WARRANTY-1")}`, `${t("TYPE-WARRANTY-2")}`, `${t("TYPE-WARRANTY-3")}`];
  const {
    inputBrandCodeSearch,
    setInputBrandCodeSearch,
    inputBrandNameSearch,
    setInputBrandNameSearch,
    inputBrandSearch,
    setInputBrandSearch,
    inputReferenceSearch,
    setInputReferenceSearch,
    inputOldReferenceSearch,
    setInputOldReferenceSearch,
    inputNameSearchProduct,
    setInputNameSearchProduct,
    inputTypeSearch,
    setInputTypeSearch,
    inputDefaultSearch,
    setInputDefaultSearch,
    inputUnitPriceSearch,
    setInputUnitPriceSearch,
    inputAvailableSearch,
    setInputAvailableSearch,
    inputWarrantySearch,
    setInputWarrantySearch,
    inputBrochureSearch,
    setInputBrochureSearch,
    inputEanSearch,
    setInputEanSearch,
    showLateralContainerSearch,
    setShowLateralContainerSearch,
  } = useFilterContext();
  const { searchWindowState, SetSearchWindowState, listSort, dropDownWhySort, setDropDownWhySort, handleSearch } = props;

  const { typeOfSearch, setTypeOfSearch } = useContext(ApplicationContext);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleSearch();
    setShowLateralContainerSearch(!showLateralContainerSearch);
  };

  const matches = useMediaQuery("(max-width:500px)");
  const brandName = useAutocomplete(
    "/products/get-all?autocomplete=true&brand_name=",
    handleSearch,
    inputBrandNameSearch,
    setInputBrandNameSearch,
    "brandName",
    createAddapterProduct
  );

  const brandCode = useAutocomplete(
    "/products/get-all?autocomplete=true&brand_code=",
    handleSearch,
    inputBrandCodeSearch,
    setInputBrandCodeSearch,
    "brandCode",
    createAddapterProduct
  );
  const reference = useAutocomplete(
    "/products/get-all?autocomplete=true&reference_end_user=",
    handleSearch,
    inputReferenceSearch,
    setInputReferenceSearch,
    "reference"
  );

  const oldReference = useAutocomplete(
    "/products/get-all?autocomplete=true&old_reference=",
    handleSearch,
    inputOldReferenceSearch,
    setInputOldReferenceSearch,
    "old_reference"
  );
  const name = useAutocomplete(
    "/products/get-all?autocomplete=true&name=",
    handleSearch,
    inputNameSearchProduct,
    setInputNameSearchProduct,
    "name"
  );
  const unitPrice = useAutocomplete(
    "/products/get-all?autocomplete=true&unit_price=",
    handleSearch,
    inputUnitPriceSearch,
    setInputUnitPriceSearch,
    "unit_price",
    createAddapterProduct
  );

  const eanNumber = useAutocomplete(
    "/products/get-all?autocomplete=true&ean_number=",
    handleSearch,
    inputEanSearch,
    setInputEanSearch,
    "eanNumber"
  );

  return (
    <LateralContainer
      show={typeOfSearch === 1 ? showLateralContainerSearch : false}
      toggleShow={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
      content={
        <form action="/" onSubmit={handleSubmit}>
          <div className="row sectionBoxTop">
            <div className="col-2 col-lg-2 pb-3" style={{ display: "flex" }}>
              <FontAwesomeIcon
                icon={faWrench}
                style={{
                  marginLeft: "50%",
                  marginTop: "0",
                  width: "50px",
                  height: "50px",
                }}
              />
            </div>
            <div className="col-10 col-lg-10">
              <h4 className="h4-stl" style={{ margin: "15px 20px 15px" }}>
                <strong>{t("SEARCH.PRODUCT.TITLE-SEARCH-PRODUCTS")}</strong>
              </h4>
            </div>
          </div>
          {searchWindowState === "filterList" ? (
            <div className="cssanimation sequence fadeInTop">
              {!matches ? (
                <>
                  <div className="filterBox my-4">
                    <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                      {t("SEARCH.PRODUCT.BRAND")}
                    </h4>
                    {/* <div className="row py-2">
                      <div className="col-1 col-lg-1"></div>
                      <div className="col-11 col-lg-2 Right">
                        <h5 className="h5-stl Right">
                          {t("SEARCH.PRODUCT.INPUT-BRAND")}
                        </h5>
                      </div>
                      <div className="col-8 col-lg-6">
                        <InputClear
                          type="text"
                          name="BrandCode"
                          placeholder=""
                          readOnly
                          style={{
                            heigth: "250px",
                            background: "var(--color-input-background-secondary)",
                          }}
                          currentValue={inputBrandSearch}
                          handleOnInputValue={(data: string) => {
                            setInputBrandSearch(data);
                          }}
                        />
                      </div>
                      <div className="col-2">
                        <FontAwesomeIcon
                          icon={faEllipsisH}
                          style={{ width: "20px", height: "20px" }}
                        />
                      </div>
                    </div> */}
                    <div className="flex-stl flex-center flex-space-evenly my-2" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl">{t("SEARCH.PRODUCT.INPUT-BRAND-CODE")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <AutoCompleteInput {...brandCode} freeSolo />
                        </div>
                      </div>
                      {/* {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.PRODUCT.TITLE-BRAND-NAME")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <AutoCompleteInput {...brandName} freeSolo />
                        </div>
                        {/* <AutocompleteInput
                                              setCurrentValue = {(data:string) =>{setInput(data)}}
                                              label = ""
                                              options = {top100Films}
                                              optionFilter = "title"
                                              // optionFilter = {title}
                                          /> */}
                      </div>
                      {/* {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>
                  </div>
                  <div className="filterBox my-4">
                    <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                      {t("ALL.GENERAL.PRODUCT-HEADER-TITLE-IDENTIFICATION")}
                    </h4>
                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.PRODUCT.TITLE-REFERENCE")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <AutoCompleteInput {...reference} freeSolo />
                        </div>
                      </div>
                      {/* {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>
                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl Right">{t("SEARCH.BRAND.INPUT-OLD-REFERENCE")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <AutoCompleteInput {...oldReference} freeSolo />
                        </div>
                      </div>
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.PRODUCT.TITLE-NAME")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <AutoCompleteInput {...name} freeSolo />
                        </div>
                      </div>
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.PRODUCT.TITLE-TYPE")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <InputClear
                            type="text"
                            name="Name"
                            placeholder=""
                            currentValue={inputTypeSearch}
                            handleOnInputValue={(data: string) => {
                              setInputTypeSearch(data);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl Right">{t("SEARCH.PRODUCT.INPUT-DEFAULT")}?</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <DropDown
                            options={listCheck}
                            selectedItem={inputDefaultSearch}
                            onSelect={(item: string) => setInputDefaultSearch(item)}
                            width="100%"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filterBox my-4">
                    <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                      {t("ALL.GENERAL.PRODUCT-HEADER-TITLE-STOCK-AND-PRICING")}
                    </h4>
                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.PRODUCT.TITLE-UNIT-PRICE")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <AutoCompleteInput {...unitPrice} freeSolo />
                        </div>
                      </div>
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.PRODUCT.TITLE-AVAILABLE")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <DropDown
                            options={listCheck}
                            selectedItem={inputAvailableSearch}
                            onSelect={(item: string) => setInputAvailableSearch(item)}
                            width="13vw"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.PRODUCT.TITLE-WARRANTY")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <DropDown
                            options={listTypeOptionsWarranty}
                            selectedItem={inputWarrantySearch}
                            onSelect={(item: string) => setInputWarrantySearch(item)}
                            width="13vw"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filterBox my-4">
                    <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                      {t("CREATE.PRODUCT.CARD-HEADER-TITLE-MARKETING")}
                    </h4>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl Right">{t("SEARCH.PRODUCT.INPUT-EAN")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <AutoCompleteInput {...eanNumber} freeSolo />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray" style={{ display: "flex" }}>
                    <Tooltip title={`${t("FILER-TO-COLUMNS")}`} placement="bottom">
                      <div style={{ width: "60px", margin: "auto 0" }}>
                        <FontAwesomeIcon
                          className="grayBackgr"
                          style={{ width: "47px", height: "47px" }}
                          icon={faThumbtack}
                          onClick={() => {
                            setTypeOfSearch(0);
                          }}
                        />
                      </div>
                    </Tooltip>
                    {/* <FontAwesomeIcon
                      className="grayBackgr"
                      icon={faSortAlphaDown}
                      onClick={() => SetSearchWindowState("SortWindow")}
                      style={{ width: "47px", height: "47px" }}
                    /> */}
                    <Button
                      variant="primary"
                      style={{ margin: "15px 30px" }}
                      onClick={() => {
                        handleSearch();
                        setShowLateralContainerSearch(!showLateralContainerSearch);
                      }}
                    >
                      {t("SEARCH.ALL.BUTTON-SEARCH")}
                    </Button>
                    <h4
                      className="h4-stl pt-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setInputBrandSearch("");
                        setInputBrandCodeSearch("");
                        setInputBrandNameSearch("");
                        setInputReferenceSearch("");
                        setInputOldReferenceSearch("");
                        setInputNameSearchProduct("");
                        setInputTypeSearch("");
                        setInputAvailableSearch("");
                        setInputWarrantySearch("");
                        setInputBrochureSearch("");
                        setInputEanSearch("");
                        handleSearch();
                      }}
                    >
                      {t("SEARCH.ALL.BUTTON-RESET")}
                    </h4>
                    <h4
                      className="h4-stl pt-4 mx-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                    >
                      {t("SEARCH.ALL.BUTTON-CLOSE")}
                    </h4>
                  </div>
                </>
              ) : (
                <>
                  <div className="filterBox my-4">
                    <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                      {t("SEARCH.PRODUCT.BRAND")}
                    </h4>
                    {/* <div className="row py-2">
                      <div className="col-1 col-lg-1"></div>
                      <div className="col-11 col-lg-2 Right">
                        <h5 className="h5-stl Right">
                          {t("SEARCH.PRODUCT.INPUT-BRAND")}
                        </h5>
                      </div>
                      <div className="col-8 col-lg-6">
                        <InputClear
                          type="text"
                          name="BrandCode"
                          placeholder=""
                          readOnly
                          style={{
                            heigth: "250px",
                            background: "var(--color-input-background-secondary)",
                          }}
                          currentValue={inputBrandSearch}
                          handleOnInputValue={(data: string) => {
                            setInputBrandSearch(data);
                          }}
                        />
                      </div>
                      <div className="col-2">
                        <FontAwesomeIcon
                          icon={faEllipsisH}
                          style={{ width: "20px", height: "20px" }}
                        />
                      </div>
                    </div> */}
                    <div className="flex-stl flex-direction-column px-4 my-2">
                      <h5 className="h5-stl Right">{t("SEARCH.PRODUCT.INPUT-BRAND-CODE")}</h5>
                      <div className="flex-stl">
                        <div className="full-width">
                          <InputClear
                            type="text"
                            name="Brand"
                            placeholder=""
                            currentValue={inputBrandCodeSearch}
                            handleOnInputValue={(data: string) => {
                              setInputBrandCodeSearch(data);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex-stl flex-direction-column px-4 my-4">
                      <h5 className="h5-stl Right">{t("ALL.GENERAL.PRODUCT.TITLE-BRAND-NAME")}</h5>
                      <div className="flex-stl">
                        {/* <AutocompleteInput
                                              setCurrentValue = {(data:string) =>{setInput(data)}}
                                              label = ""
                                              options = {top100Films}
                                              optionFilter = "title"
                                              // optionFilter = {title}
                                          /> */}

                        <div className="full-width">
                          <InputClear
                            type="text"
                            name="BrandName"
                            placeholder=""
                            currentValue={inputBrandNameSearch}
                            handleOnInputValue={(data: string) => {
                              setInputBrandNameSearch(data);
                            }}
                          />
                        </div>
                      </div>
                      {/* {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                          </div> */}
                    </div>
                  </div>
                  {matches ? (
                    <>
                      <div className="filterBox my-4">
                        <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                          {t("ALL.GENERAL.PRODUCT-HEADER-TITLE-IDENTIFICATION")}
                        </h4>
                        <div className="flex-stl flex-direction-column px-4 my-2">
                          <div>
                            <h5 className="h5-stl Right">{t("ALL.GENERAL.PRODUCT.TITLE-REFERENCE")}</h5>
                          </div>
                          <div className="flex-stl">
                            <div className="full-width">
                              <InputClear
                                type="text"
                                name="Reference"
                                placeholder=""
                                currentValue={inputReferenceSearch}
                                handleOnInputValue={(data: string) => {
                                  setInputReferenceSearch(data);
                                }}
                              />
                            </div>
                          </div>
                          {/* <div className="col-2">
                            <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                          </div> */}
                        </div>
                        <div className="flex-stl flex-direction-column px-4 my-4">
                          <div>
                            <h5 className="h5-stl Right">{t("SEARCH.BRAND.INPUT-OLD-REFERENCE")}</h5>
                          </div>
                          <div className="flex-stl">
                            <div className="full-width">
                              <InputClear
                                type="text"
                                name="OldReference"
                                placeholder=""
                                currentValue={inputOldReferenceSearch}
                                handleOnInputValue={(data: string) => {
                                  setInputOldReferenceSearch(data);
                                }}
                              />
                            </div>
                          </div>
                          {/* <div className="col-2">
                            <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                          </div> */}
                        </div>
                        <div className="flex-stl flex-direction-column px-4 my-4">
                          <div>
                            <h5 className="h5-stl Right">{t("ALL.GENERAL.PRODUCT.TITLE-NAME")}</h5>
                          </div>
                          <div className="flex-stl">
                            {/* <AutocompleteInput
                                                  setCurrentValue = {(data:string) =>{setInput(data)}}
                                                  label = ""
                                                  options = {top100Films}
                                                  optionFilter = "title"
                                                  // optionFilter = {title}
                                              /> */}
                            <div className="full-width">
                              <InputClear
                                type="text"
                                name="BrandName"
                                placeholder=""
                                currentValue={inputNameSearchProduct}
                                handleOnInputValue={(data: string) => {
                                  setInputNameSearchProduct(data);
                                }}
                              />
                            </div>
                          </div>
                          {/* <div className="col-2">
                            <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                          </div> */}
                        </div>
                        <div className="flex-stl flex-direction-column px-4 my-4">
                          <div>
                            <h5 className="h5-stl Right">{t("ALL.GENERAL.PRODUCT.TITLE-TYPE")}</h5>
                          </div>
                          <div className="flex-stl">
                            {/* <AutocompleteInput
                                                  setCurrentValue = {(data:string) =>{setInput(data)}}
                                                  label = ""
                                                  options = {top100Films}
                                                  optionFilter = "title"
                                                  // optionFilter = {title}
                                              /> */}
                            <div className="full-width">
                              <InputClear
                                type="text"
                                name="Name"
                                placeholder=""
                                currentValue={inputTypeSearch}
                                handleOnInputValue={(data: string) => {
                                  setInputTypeSearch(data);
                                }}
                              />
                            </div>
                          </div>
                          {/* <div className="col-2">
                            <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                          </div> */}
                        </div>
                        <div className="flex-stl flex-direction-column px-4 my-4">
                          <div>
                            <h5 className="h5-stl Right">{t("SEARCH.PRODUCT.INPUT-DEFAULT")}?</h5>
                          </div>
                          <div className="flex-stl">
                            <div className="full-width">
                              <DropDown
                                options={listCheck}
                                selectedItem={inputDefaultSearch}
                                onSelect={(item: string) => setInputDefaultSearch(item)}
                                width="100%"
                              />
                            </div>
                          </div>
                          {/* <div className="col-2">
                            <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                          </div> */}
                        </div>
                      </div>
                      <div className="filterBox my-4">
                        <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                          {t("ALL.GENERAL.PRODUCT-HEADER-TITLE-STOCK-AND-PRICING")}
                        </h4>
                        <div className="flex-stl flex-direction-column px-4 my-4">
                          <div>
                            <h5 className="h5-stl Right">{t("ALL.GENERAL.PRODUCT.TITLE-UNIT-PRICE")}</h5>
                          </div>
                          <div className="flex-stl">
                            <div className="full-width">
                              <InputClear
                                type="text"
                                name="Available"
                                placeholder=""
                                currentValue={inputUnitPriceSearch}
                                handleOnInputValue={(data: string) => {
                                  setInputUnitPriceSearch(data);
                                }}
                              />
                            </div>
                          </div>
                          {/* <div className="col-2">
                            <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                          </div> */}
                        </div>
                        <div className="flex-stl flex-direction-column px-4 my-4">
                          <div>
                            <h5 className="h5-stl Right">{t("ALL.GENERAL.PRODUCT.TITLE-AVAILABLE")}</h5>
                          </div>
                          <div className="flex-stl">
                            <div className="full-width">
                              <DropDown
                                options={listCheck}
                                selectedItem={inputAvailableSearch}
                                onSelect={(item: string) => setInputAvailableSearch(item)}
                                width="100%"
                              />
                            </div>
                          </div>
                          {/* <div className="col-2">
                            <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                          </div> */}
                        </div>
                        <div className="flex-stl flex-direction-column px-4 my-4">
                          <div>
                            <h5 className="h5-stl Right">{t("ALL.GENERAL.PRODUCT.TITLE-WARRANTY")}</h5>
                          </div>
                          <div className="flex-stl">
                            <div className="full-width">
                              <DropDown
                                options={listTypeOptionsWarranty}
                                selectedItem={inputWarrantySearch}
                                onSelect={(item: string) => setInputWarrantySearch(item)}
                                width="100%"
                              />
                            </div>
                          </div>
                          {/* <div className="col-2">
                            <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                          </div> */}
                        </div>
                      </div>
                      <div className="filterBox my-4">
                        <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                          {t("CREATE.PRODUCT.CARD-HEADER-TITLE-MARKETING")}
                        </h4>

                        <div className="flex-stl flex-direction-column px-4 mt-2 mb-4">
                          <div>
                            <h5 className="h5-stl Right">{t("SEARCH.PRODUCT.INPUT-EAN")}</h5>
                          </div>
                          <div className="flex-stl">
                            <div className="full-width">
                              <InputClear
                                type="text"
                                name="Warranty"
                                placeholder=""
                                currentValue={inputEanSearch}
                                handleOnInputValue={(data: string) => {
                                  setInputEanSearch(data);
                                }}
                              />
                            </div>
                          </div>
                          {/* <div className="col-2">
                            <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                          </div> */}
                        </div>
                      </div>
                      <div className="bg-gray" style={{ display: "flex" }}>
                        <Tooltip title={`${t("FILER-TO-COLUMNS")}`} placement="bottom">
                          <div style={{ width: "60px" }}>
                            <FontAwesomeIcon
                              className="grayBackgr"
                              style={{ width: "47px", height: "47px" }}
                              icon={faThumbtack}
                              onClick={() => {
                                setTypeOfSearch(0);
                              }}
                            />
                          </div>
                        </Tooltip>
                        {/* <FontAwesomeIcon
                          className="grayBackgr"
                          icon={faSortAlphaDown}
                          onClick={() => SetSearchWindowState("SortWindow")}
                          style={{ width: "47px", height: "47px" }}
                        /> */}
                        <Button
                          variant="primary"
                          style={{ margin: "15px 30px" }}
                          onClick={() => {
                            handleSearch();
                            setShowLateralContainerSearch(!showLateralContainerSearch);
                          }}
                        >
                          {t("SEARCH.ALL.BUTTON-SEARCH")}
                        </Button>
                        <h4
                          className="h4-stl pt-4"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setInputBrandSearch("");
                            setInputBrandCodeSearch("");
                            setInputBrandNameSearch("");
                            setInputReferenceSearch("");
                            setInputOldReferenceSearch("");
                            setInputNameSearchProduct("");
                            setInputTypeSearch("");
                            setInputAvailableSearch("");
                            setInputWarrantySearch("");
                            setInputBrochureSearch("");
                            setInputEanSearch("");
                            handleSearch();
                          }}
                        >
                          {t("SEARCH.ALL.BUTTON-RESET")}
                        </h4>
                        <h4
                          className="h4-stl pt-4 mx-4"
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                        >
                          {t("SEARCH.ALL.BUTTON-CLOSE")}
                        </h4>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="filterBox my-4">
                        <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                          {t("ALL.GENERAL.PRODUCT-HEADER-TITLE-IDENTIFICATION")}
                        </h4>
                        <div className="row py-2">
                          <div className="col-1 col-lg-1"></div>
                          <div className="col-11 col-lg-2 Right">
                            <h5 className="h5-stl Right">{t("ALL.GENERAL.PRODUCT.TITLE-REFERENCE")}</h5>
                          </div>
                          <div className="col-8 col-lg-6">
                            <InputClear
                              type="text"
                              name="Reference"
                              placeholder=""
                              currentValue={inputReferenceSearch}
                              handleOnInputValue={(data: string) => {
                                setInputReferenceSearch(data);
                              }}
                            />
                          </div>
                          <div className="col-2">
                            <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                          </div>
                        </div>
                        <div className="row py-2">
                          <div className="col-1 col-lg-1"></div>
                          <div className="col-11 col-lg-2 Right">
                            <h5 className="h5-stl Right">{t("SEARCH.BRAND.INPUT-OLD-REFERENCE")}</h5>
                          </div>
                          <div className="col-8 col-lg-6">
                            <InputClear
                              type="text"
                              name="OldReference"
                              placeholder=""
                              currentValue={inputOldReferenceSearch}
                              handleOnInputValue={(data: string) => {
                                setInputOldReferenceSearch(data);
                              }}
                            />
                          </div>
                          <div className="col-2">
                            <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                          </div>
                        </div>
                        <div className="row py-2">
                          <div className="col-1 col-lg-1"></div>
                          <div className="col-11 col-lg-2">
                            <h5 className="h5-stl Right">{t("ALL.GENERAL.PRODUCT.TITLE-NAME")}</h5>
                          </div>
                          <div className="col-8 col-lg-6">
                            {/* <AutocompleteInput
                                                  setCurrentValue = {(data:string) =>{setInput(data)}}
                                                  label = ""
                                                  options = {top100Films}
                                                  optionFilter = "title"
                                                  // optionFilter = {title}
                                              /> */}
                            <InputClear
                              type="text"
                              name="BrandName"
                              placeholder=""
                              currentValue={inputNameSearchProduct}
                              handleOnInputValue={(data: string) => {
                                setInputNameSearchProduct(data);
                              }}
                            />
                          </div>
                          <div className="col-2">
                            <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                          </div>
                        </div>
                        <div className="row py-2">
                          <div className="col-1 col-lg-1"></div>
                          <div className="col-11 col-lg-2">
                            <h5 className="h5-stl Right">{t("ALL.GENERAL.PRODUCT.TITLE-TYPE")}</h5>
                          </div>
                          <div className="col-8 col-lg-6">
                            {/* <AutocompleteInput
                                                  setCurrentValue = {(data:string) =>{setInput(data)}}
                                                  label = ""
                                                  options = {top100Films}
                                                  optionFilter = "title"
                                                  // optionFilter = {title}
                                              /> */}
                            <InputClear
                              type="text"
                              name="Name"
                              placeholder=""
                              currentValue={inputTypeSearch}
                              handleOnInputValue={(data: string) => {
                                setInputTypeSearch(data);
                              }}
                            />
                          </div>
                          <div className="col-2">
                            <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                          </div>
                        </div>
                        <div className="row py-2 mb-4">
                          <div className="col-1 col-lg-1"></div>
                          <div className="col-11 col-lg-2">
                            <h5 className="h5-stl Right">{t("SEARCH.PRODUCT.INPUT-DEFAULT")}?</h5>
                          </div>
                          <div className="col-8 col-lg-6">
                            <DropDown
                              options={listCheck}
                              selectedItem={inputDefaultSearch}
                              onSelect={(item: string) => setInputDefaultSearch(item)}
                              width="13vw"
                            />
                          </div>
                          <div className="col-2">
                            <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                          </div>
                        </div>
                      </div>
                      <div className="filterBox my-4">
                        <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                          {t("ALL.GENERAL.PRODUCT-HEADER-TITLE-STOCK-AND-PRICING")}
                        </h4>
                        <div className="row py-2">
                          <div className="col-1 col-lg-1"></div>
                          <div className="col-11 col-lg-2 Right">
                            <h5 className="h5-stl Right">{t("ALL.GENERAL.PRODUCT.TITLE-UNIT-PRICE")}</h5>
                          </div>
                          <div className="col-8 col-lg-6">
                            <InputClear
                              type="text"
                              name="Available"
                              placeholder=""
                              currentValue={inputUnitPriceSearch}
                              handleOnInputValue={(data: string) => {
                                setInputUnitPriceSearch(data);
                              }}
                            />
                          </div>
                          <div className="col-2">
                            <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                          </div>
                        </div>
                        <div className="row py-2">
                          <div className="col-1 col-lg-1"></div>
                          <div className="col-11 col-lg-2 Right">
                            <h5 className="h5-stl Right">{t("ALL.GENERAL.PRODUCT.TITLE-AVAILABLE")}</h5>
                          </div>
                          <div className="col-8 col-lg-6">
                            <DropDown
                              options={listCheck}
                              selectedItem={inputAvailableSearch}
                              onSelect={(item: string) => setInputAvailableSearch(item)}
                              width="13vw"
                            />
                          </div>
                          <div className="col-2">
                            <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                          </div>
                        </div>
                        <div className="row py-2">
                          <div className="col-1 col-lg-1"></div>
                          <div className="col-11 col-lg-2 Right">
                            <h5 className="h5-stl Right">{t("ALL.GENERAL.PRODUCT.TITLE-WARRANTY")}</h5>
                          </div>
                          <div className="col-8 col-lg-6">
                            <DropDown
                              options={listTypeOptionsWarranty}
                              selectedItem={inputWarrantySearch}
                              onSelect={(item: string) => setInputWarrantySearch(item)}
                              width="13vw"
                            />
                          </div>
                          <div className="col-2">
                            <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                          </div>
                        </div>
                      </div>
                      <div className="filterBox my-4">
                        <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                          {t("CREATE.PRODUCT.CARD-HEADER-TITLE-MARKETING")}
                        </h4>

                        <div className="row py-2 mb-4">
                          <div className="col-1 col-lg-1"></div>
                          <div className="col-11 col-lg-2 Right">
                            <h5 className="h5-stl Right">{t("SEARCH.PRODUCT.INPUT-EAN")}</h5>
                          </div>
                          <div className="col-8 col-lg-6">
                            <InputClear
                              type="text"
                              name="Warranty"
                              placeholder=""
                              currentValue={inputEanSearch}
                              handleOnInputValue={(data: string) => {
                                setInputEanSearch(data);
                              }}
                            />
                          </div>
                          <div className="col-2">
                            <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray" style={{ display: "flex" }}>
                        <Tooltip title={`${t("FILER-TO-COLUMNS")}`} placement="bottom">
                          <div style={{ width: "60px" }}>
                            <FontAwesomeIcon
                              className="grayBackgr"
                              style={{ width: "47px", height: "47px" }}
                              icon={faThumbtack}
                              onClick={() => {
                                setTypeOfSearch(0);
                              }}
                            />
                          </div>
                        </Tooltip>
                        {/* <FontAwesomeIcon
                          className="grayBackgr"
                          icon={faSortAlphaDown}
                          onClick={() => SetSearchWindowState("SortWindow")}
                          style={{ width: "47px", height: "47px" }}
                        /> */}
                        <Button
                          variant="primary"
                          style={{ margin: "15px 30px" }}
                          onClick={() => {
                            handleSearch();
                            setShowLateralContainerSearch(!showLateralContainerSearch);
                          }}
                        >
                          {t("SEARCH.ALL.BUTTON-SEARCH")}
                        </Button>
                        <h4
                          className="h4-stl pt-4"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setInputBrandSearch("");
                            setInputBrandCodeSearch("");
                            setInputBrandNameSearch("");
                            setInputReferenceSearch("");
                            setInputOldReferenceSearch("");
                            setInputNameSearchProduct("");
                            setInputTypeSearch("");
                            setInputAvailableSearch("");
                            setInputWarrantySearch("");
                            setInputBrochureSearch("");
                            setInputEanSearch("");
                            handleSearch();
                          }}
                        >
                          {t("SEARCH.ALL.BUTTON-RESET")}
                        </h4>
                        <h4
                          className="h4-stl pt-4 mx-4"
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                        >
                          {t("SEARCH.ALL.BUTTON-CLOSE")}
                        </h4>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="cssanimation sequence fadeInBottom">
              <div className="sectionBoxTop pb-4">
                <div
                  className="py-3 my-2"
                  style={{
                    backgroundColor: "var(--color-border)",
                    display: "flex",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    onClick={() => SetSearchWindowState("filterList")}
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "2em",
                      cursor: "pointer",
                    }}
                  />
                  <h4 className="h4-stl" onClick={() => SetSearchWindowState("filterList")} style={{ cursor: "pointer" }}>
                    <strong>Sort order</strong>
                  </h4>
                </div>
                <div>
                  <DropDown
                    options={listSort}
                    selectedItem={dropDownWhySort}
                    onSelect={(item: string) => setDropDownWhySort(item)}
                    width="20vw"
                  />
                </div>
              </div>

              <div>
                <Button
                  variant="primary"
                  style={{
                    margin: "15px 30px",
                    display: "flex",
                    float: "right",
                  }}
                  onClick={() => SetSearchWindowState("filterList")}
                >
                  Apply
                </Button>
              </div>
            </div>
          )}
          <input type="submit" value="" style={{ display: "none" }}></input>
        </form>
      }
    ></LateralContainer>
  );
}
