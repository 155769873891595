import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import "bootstrap/dist/css/bootstrap.css";
import { Breadcrumb, Card } from "react-bootstrap";

export default function LoadingSkeleton() {
  return (
    <div>
      <Card>
        <div className="py-4 px-4">
          <Stack spacing={1}>
            <Skeleton variant="rectangular" height={90} animation="wave" />

            <div className="row my-3">
              <div className="col-1">
                <Skeleton variant="circular" width={50} height={50} animation="wave" />
              </div>
              <div className="col-11">
                <Skeleton variant="rectangular" height={50} animation="wave" />
              </div>
            </div>
            <div className="row my-3">
              <div className="col-1">
                <Skeleton variant="circular" width={50} height={50} animation="wave" />
              </div>
              <div className="col-11">
                <Skeleton variant="rectangular" height={50} animation="wave" />
              </div>
            </div>
            <div className="row my-3">
              <div className="col-1">
                <Skeleton variant="circular" width={50} height={50} animation="wave" />
              </div>
              <div className="col-11">
                <Skeleton variant="rectangular" height={50} animation="wave" />
              </div>
            </div>
            <div style={{ height: "45vh" }}></div>
          </Stack>
        </div>
      </Card>
    </div>
  );
}
