import React, { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faBars, faBuilding, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

import grid from "../../../images/GridIcon.png";
import "../../../constants/global.css";
import "../styles/Refusal.css";
import DistributorTable from "../../organisms/DistributorTable/DistributorTable";
import { Breadcrumb, Card, Modal } from "react-bootstrap";
import { ApplicationContext } from "../../../context/ApplicationContext";
import Stadistics from "../../../images/stadistics.svg";
import Map from "../../../images/mapRed.svg";
import "./distributor.css";
import { Link, useHistory } from "react-router-dom";
import preferencesImg from "../../../images/preferences.svg";
// bars menu
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import Reloadimg from "../../../images/reload.svg";
import deleteImg from "../../../images/delete.svg";
import copyImg from "../../../images/copy.svg";
import { useTranslation } from "react-i18next";
import exportImg from "../../../images/export.svg";
import ModalExport from "../../atoms/ModalExport";
import DistributorSearch from "../../organisms/Search/DistributorSearch";
import { useDistributors } from "../../../hooks/Distributors/useDistributors";
//Redux
import bulkimg from "../../../images/bulk.svg";
import { useSelector } from "react-redux";
import { IRootState } from "../../../store/redux";
import { typeUserData } from "../../../constants/userTypes";
import DistributorTableTransfer from "../../templates/TableTranfers/DistributorTableTransfer";
import { useMediaQuery } from "@mui/material";
import "../../../constants/global.css";
import { useFilterContext } from "../../../context";
import GuardNotAuthorized from "../../atoms/ItemPermission/GuardNotAuthorized";
import ItemPermission from "../../atoms/ItemPermission/ItemPermission";

interface IDistributorProps {
  fromInfo?: boolean;
  groupID?: string;
  Create?: boolean;
}

export default function RefusalReasons(props: IDistributorProps) {
  const { groupID } = props;
  const { distributorLength, distributorListID, distributorsAllSelected } = useContext(ApplicationContext);
  const matches = useMediaQuery("(max-width:500px)");

  const { t, i18n } = useTranslation();
  let history = useHistory();
  const listCheck = ["", t("TABLE.DISTRIBUTORS-TITLE-YES"), t("TABLE.DISTRIBUTORS-TITLE-NO")];

  const listSort: string[] = [
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-GROUP-CODE"),
    t("SEARCH.DISTRIBUTOR.INPUT-GROUP-NAME"),
    t("ALL.GENERAL.TABLE.DIST-SET.TITLE-MEMBERS"),
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-CODE"),
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-CLIENT-ACCOUNT-NUMBER"),
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-NAME"),
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-VISIBLE"),
    "Web?",
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-LOGO"),
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-CITY"),
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-POSTAL-CODE"),
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-PHONE"),
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-EMAIL"),
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-RESPONSIBLE-EMAIL"),
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-DELIVERY-ACCOUNT-NUMBER"),
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-EVALUATION"),
  ];

  //Redux
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected } = profile;

  const [showSearchTable, setShowSearchTable] = useState(false);
  //menu fade
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function copy() {
    const url = document.createElement("input");
    url.value = window.location.href;
    navigator.clipboard.writeText(url.value);
    setAnchorEl(null);
  }
  const [searchState, setSearchState] = useState<boolean>(false);
  const [estado, setEstado] = useState(1);

  //Filter Search
  const [dropDownWhySort, setDropDownWhySort] = useState<string>(listSort[0]);
  const {
    inputGroupCodeSearch,
    inputGroupNameSearch,
    inputCodeSearchDistributor,
    inputClientAccountNumberSearch,
    inputNameSearchDistributor,
    inputVisibleSearch,
    inputWebSearch,
    inputAddressSearch,
    inputEmailSearch,
    inputGeographicalCoordinatesSearch,
    inputMapURLSearch,
    inputEvaluationSearch,
    inputLookupSearch,
    inputMembersOfSetSearch,
    inputResponsibleEmailSearch,
    inputDeliveryAccountNumberSearch,
    showLateralContainerSearch,
    setShowLateralContainerSearch,
  } = useFilterContext();

  const [openTransferModal, setOpenTransferModal] = useState(false);
  // useEffect(() => {
  //   setDistributorListID([]);
  //   setDistributorListMembers([]);
  // }, []);
  //! ------------------------------API CALLS--------------------------------//
  const {
    distributorArray,
    loading,
    exportDistributor,
    deleteDistributors,
    wrongAlertStatus,
    setWrongAlertStatus,
    currentPage,
    lastPage,
    handleChange,
    limit,
    onSearch,

    typeSort,
    incrementCount,
  } = useDistributors(
    inputGroupCodeSearch,
    inputGroupNameSearch,
    inputAddressSearch,
    inputGeographicalCoordinatesSearch,
    inputMapURLSearch,
    inputNameSearchDistributor,
    inputCodeSearchDistributor,
    inputEvaluationSearch,
    inputEmailSearch,
    inputClientAccountNumberSearch,
    inputVisibleSearch ? (inputVisibleSearch === t("TABLE.DISTRIBUTORS-TITLE-YES") ? "true" : "false") : "",
    inputWebSearch ? (inputWebSearch === t("TABLE.DISTRIBUTORS-TITLE-YES") ? "true" : "false") : "",
    inputMembersOfSetSearch,
    inputResponsibleEmailSearch,
    inputDeliveryAccountNumberSearch,
    groupID
  );

  const exportFunction = () => {
    try {
      exportDistributor(checkedType, distributorListID, checkNeedToExport);
    } finally {
      setOpenConfirm(false);
    }
  };

  const deleteFunction = () => {
    try {
      deleteDistributors(distributorListID);
    } finally {
      setOpenConfirm(false);
    }
  };
  //! --------------------------END API CALLS--------------------------------//

  //? ------------------------------END ALERTS--------------------------------//

  const [searchWindowState, SetSearchWindowState] = useState("filterList");

  const renderLateralContainerSearch = () => {
    return (
      <DistributorSearch
        handleSearch={() => onSearch()}
        SetSearchWindowState={SetSearchWindowState}
        searchWindowState={searchWindowState}
        listSort={listSort}
        dropDownWhySort={dropDownWhySort}
        setDropDownWhySort={setDropDownWhySort}
        listCheck={listCheck}
      />
    );
  };

  const RenderTabla1 = () => {
    return (
      <div>
        <DistributorTable
          distributorArray={distributorArray}
          searchState={searchState}
          groupID={props.groupID}
          rowsPerPage={limit}
          UsePlanText="false"
          inputToSort={dropDownWhySort}
          Create={props.Create}
          showSearchTable={showSearchTable}
          loading={loading}
          currentPage={currentPage}
          lastPage={lastPage}
          handleChange={handleChange}
          incrementCount={incrementCount}
          typeSort={typeSort}
          handleSearch={() => onSearch()}
          setDropDownWhySort={setDropDownWhySort}
        />
        <div className="row sectionBoxBottom">
          <div className="col-10 col-lg-11">
            <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
              {t("TABLE.DISTRIBUTOR.HEADER-TOTAL")} {`${distributorLength}`}
            </h4>
          </div>
        </div>
      </div>
    );
  };

  const [openConfirm, setOpenConfirm] = useState(false);
  const showModal = () => {
    return (
      <>
        <Modal
          show={openConfirm}
          onHide={() => {
            setOpenConfirm(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("INFO.USER.RESET-CONFIRM")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-lg-12 p-4">
                <h4 className="h4-stl">{t("ALL-INFO.DELETE-CONFIRM")}</h4>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                deleteFunction();
              }}
              variant="success"
            >
              {t("INFO.USER.RESET-BUTTON-CONFIRM")}
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setOpenConfirm(false);
              }}
            >
              {t("INFO.USER.RESET-BUTTON-CANCEL")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  //Modal
  const [openExport, setOpenExport] = useState(false);
  // Checkbox
  const [checkNeedToExport, setCheckNeedToExport] = useState<boolean[]>([false, true, false]);
  const [checkFormatToExport, setCheckFormatToExport] = useState<boolean[]>([true, false, false, false]);
  const [checkedType, setCheckedType] = useState<string>("csv");

  const showModalExport = () => {
    return (
      <ModalExport
        openExport={openExport}
        setOpenExport={setOpenExport}
        checkNeedToExport={checkNeedToExport}
        setCheckNeedToExport={setCheckNeedToExport}
        checkFormatToExport={checkFormatToExport}
        setCheckFormatToExport={setCheckFormatToExport}
        checkedType={checkedType}
        setCheckedType={setCheckedType}
        onClick={() => {
          setOpenExport(false);
          exportFunction();
        }}
      />
    );
  };

  return (
    <div>
      {!props.fromInfo && (
        <Breadcrumb>
          <Breadcrumb.Item href="#">{t("TABLE.DISTRIBUTOR.TITLE.HEADER-LINK")}</Breadcrumb.Item>
        </Breadcrumb>
      )}
      <Card>
        <Card.Header>
          {matches ? (
            <div className="flex-stl flex-between">
              <div className="flex-stl flex-center">
                <FontAwesomeIcon
                  icon={faBuilding}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />

                <div>
                  <h4 className="h4-stl" style={{ margin: "1vh 5px" }}>
                    <strong>{t("TABLE.DISTRIBUTOR.TITLE-HEADER")}</strong>
                  </h4>
                  <h5 className="h5-stl" style={{ margin: "15px 5px" }}>
                    {t("TABLE.DISTRIBUTOR.HEADER-TOTAL")} {`${distributorLength}`}
                  </h5>
                </div>
              </div>
              <div className="flex-stl flex-center">
                {nameRolSelected === typeUserData.admin && (
                  <Link to="/create-distributor">
                    <Button variant="primary">{t("LIST.ALL.BUTTON-CREATE")}</Button>
                  </Link>
                )}
                <div style={{ cursor: "pointer" }}>
                  {!props.Create ? (
                    <FontAwesomeIcon
                      className="grayBackgr iconbar_size"
                      icon={faSearch}
                      onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="grayBackgr iconbar_size"
                      icon={faSearch}
                      onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                    />
                  )}
                </div>
                {/* <Link
                  to="/distributor-stats"
                  style={{
                    display: "flex",
                    textDecoration: "none",
                    color: "var(--color-main-text)",
                  }}
                >
                  <div className="grayBackgr" style={{ display: "flex" }}>
                    <div>
                      <img alt="" src={Stadistics} style={{ marginTop: "0px", width: "20px", height: "20px" }}></img>
                    </div>
                    <h4 className="h4-stl" style={{ margin: "3px 10px" }}>
                      {t("ALL-STATS-BUTTON")}
                    </h4>
                  </div>
                </Link>
                <Link
                  to="/distributor-map"
                  style={{
                    display: "flex",
                    textDecoration: "none",
                    color: "var(--color-main-text)",
                  }}
                >
                  <div className="grayBackgr" style={{ display: "flex" }}>
                    <div>
                      <img alt="" src={Map} style={{ marginTop: "0px", width: "20px", height: "20px" }}></img>
                    </div>
                    <h4 className="h4-stl" style={{ margin: "4px 10px" }}>
                      {t("DISTRIBUTOR-MAP-TITLE")}
                    </h4>
                  </div>
                </Link> */}

                <div onClick={handleClick}>
                  <FontAwesomeIcon className="grayBackgr" icon={faBars} style={{ width: "50px", height: "50px" }} />
                </div>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem
                    onClick={() => {
                      history.go(0);
                    }}
                    style={{ fontSize: "15px" }}
                  >
                    <img alt="" src={Reloadimg} style={{ width: "20px", marginRight: "10px" }}></img>
                    {t("ALL.MENU.RELOAD")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setOpenTransferModal(true);
                    }}
                    style={{ fontSize: "15px" }}
                  >
                    <img alt="" src={preferencesImg} style={{ width: "20px", marginRight: "10px" }}></img>
                    {t("ALL-BUTTON-PREFERENCES")}
                  </MenuItem>
                  {nameRolSelected === typeUserData.admin && (
                    <div>
                      {distributorListID.length || distributorsAllSelected ? (
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setOpenConfirm(true);
                          }}
                          style={{ fontSize: "15px" }}
                        >
                          <img alt="" src={deleteImg} style={{ width: "20px", marginRight: "10px" }}></img>
                          {t("ALL.MENU.DELETE")}
                          <span
                            style={{
                              background: "var( --color-accepted)",
                              marginLeft: "6px",
                              color: "var(--color-main-text)",
                              borderRadius: "20px",
                              padding: "4px 8px",
                              fontSize: "12px",
                            }}
                          >
                            <b>{distributorsAllSelected ? distributorLength : distributorListID?.length}</b>
                          </span>
                        </MenuItem>
                      ) : (
                        <MenuItem disabled style={{ fontSize: "15px" }}>
                          <img alt="" src={deleteImg} style={{ width: "20px", marginRight: "10px" }}></img>
                          {t("ALL.MENU.DELETE")}
                        </MenuItem>
                      )}
                    </div>
                  )}
                  <GuardNotAuthorized>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setOpenExport(true);
                      }}
                      style={{ fontSize: "15px" }}
                    >
                      <img alt="" src={exportImg} style={{ width: "20px", marginRight: "10px" }} />
                      {t("NAV-ALL-ITEM3")}
                      <span
                        style={{
                          background: "var( --color-accepted)",
                          marginLeft: "6px",
                          color: "var(--color-main-text)",
                          borderRadius: "20px",
                          padding: "4px 8px",
                          fontSize: "12px",
                        }}
                      >
                        <b>{distributorsAllSelected ? distributorLength : distributorListID?.length}</b>
                      </span>
                    </MenuItem>
                  </GuardNotAuthorized>
                  <MenuItem onClick={copy} style={{ fontSize: "15px" }}>
                    <img alt="" src={copyImg} style={{ width: "20px", marginRight: "10px" }}></img>
                    {t("ALL.MENU.COPY-LINK")}
                  </MenuItem>
                  {/* <MenuItem onClick={handleClose} style={{fontSize:'15px'}}>Logout</MenuItem> */}
                </Menu>
              </div>
            </div>
          ) : (
            <div className="flex-warranty flex-between">
              <div className="container-warranty flex-start">
                <FontAwesomeIcon
                  icon={faBuilding}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />

                <div>
                  <h4 className="h4-stl" style={{ margin: "15px 5px" }}>
                    <strong>{t("TABLE.DISTRIBUTOR.TITLE-HEADER")}</strong>
                  </h4>
                  <h5 className="h5-stl" style={{ margin: "15px 5px" }}>
                    {t("TABLE.DISTRIBUTOR.HEADER-TOTAL")} {`${distributorLength}`}
                  </h5>
                </div>
              </div>
              <div className="container-warranty flex-end wrap-warranty">
                {nameRolSelected === typeUserData.admin && (
                  <Link to="/create-distributor">
                    <Button variant="primary">{t("LIST.ALL.BUTTON-CREATE")}</Button>
                  </Link>
                )}
                <div style={{ cursor: "pointer" }}>
                  {!props.Create ? (
                    <FontAwesomeIcon
                      className="grayBackgr iconbar_size"
                      icon={faSearch}
                      onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="grayBackgr iconbar_size"
                      icon={faSearch}
                      onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                    />
                  )}
                </div>
                <GuardNotAuthorized>
                  <Link
                    to="/distributor-stats"
                    style={{
                      display: "flex",
                      textDecoration: "none",
                      color: "var(--color-main-text)",
                    }}
                  >
                    <div className="grayBackgr" style={{ display: "flex" }}>
                      <div>
                        <img alt="" src={Stadistics} style={{ marginTop: "0px", width: "20px", height: "20px" }}></img>
                      </div>
                      <h4 className="h4-stl" style={{ margin: "3px 10px" }}>
                        {t("ALL-STATS-BUTTON")}
                      </h4>
                    </div>
                  </Link>
                </GuardNotAuthorized>
                <Link
                  to="/distributor-map"
                  style={{
                    display: "flex",
                    textDecoration: "none",
                    color: "var(--color-main-text)",
                  }}
                >
                  <div className="grayBackgr" style={{ display: "flex" }}>
                    <div>
                      <img alt="" src={Map} style={{ marginTop: "0px", width: "20px", height: "20px" }}></img>
                    </div>
                    <h4 className="h4-stl" style={{ margin: "4px 10px" }}>
                      {t("DISTRIBUTOR-MAP-TITLE")}
                    </h4>
                  </div>
                </Link>

                <div onClick={handleClick}>
                  <FontAwesomeIcon className="grayBackgr" icon={faBars} style={{ width: "50px", height: "50px" }} />
                </div>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem
                    onClick={() => {
                      history.go(0);
                    }}
                    style={{ fontSize: "15px" }}
                  >
                    <img alt="" src={Reloadimg} style={{ width: "20px", marginRight: "10px" }}></img>
                    {t("ALL.MENU.RELOAD")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setOpenTransferModal(true);
                    }}
                    style={{ fontSize: "15px" }}
                  >
                    <img alt="" src={preferencesImg} style={{ width: "20px", marginRight: "10px" }}></img>
                    {t("ALL-BUTTON-PREFERENCES")}
                  </MenuItem>
                  <ItemPermission>
                    <div>
                      {distributorListID.length ? (
                        <MenuItem
                          onClick={() => {
                            history.replace("/distributor-bulk");
                            handleClose();
                          }}
                          style={{ fontSize: "15px" }}
                        >
                          <img
                            alt=""
                            src={bulkimg}
                            style={{
                              width: "20px",
                              marginRight: "10px",
                            }}
                          ></img>
                          {t("TABLE.HEADER-OPTIONS-LIST-BULK")}
                          <span
                            style={{
                              background: "var( --color-accepted)",
                              marginLeft: "6px",
                              color: "var(--color-main-text)",
                              borderRadius: "20px",
                              padding: "4px 8px",

                              fontSize: "12px",
                            }}
                          >
                            <b>{distributorListID?.length}</b>
                          </span>
                        </MenuItem>
                      ) : (
                        <MenuItem disabled style={{ fontSize: "15px" }}>
                          <img
                            alt=""
                            src={bulkimg}
                            style={{
                              width: "20px",
                              marginRight: "10px",
                            }}
                          ></img>
                          {t("TABLE.HEADER-OPTIONS-LIST-BULK")}
                        </MenuItem>
                      )}
                    </div>
                  </ItemPermission>
                  <GuardNotAuthorized>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setOpenExport(true);
                      }}
                      style={{ fontSize: "15px" }}
                    >
                      <img alt="" src={exportImg} style={{ width: "20px", marginRight: "10px" }} />
                      {t("NAV-ALL-ITEM3")}
                      <span
                        style={{
                          background: "var( --color-accepted)",
                          marginLeft: "6px",
                          color: "var(--color-main-text)",
                          borderRadius: "20px",
                          padding: "4px 8px",
                          fontSize: "12px",
                        }}
                      >
                        <b>{distributorsAllSelected ? distributorLength : distributorListID?.length}</b>
                      </span>
                    </MenuItem>
                  </GuardNotAuthorized>
                  {nameRolSelected === typeUserData.admin && (
                    <div>
                      {distributorListID.length || distributorsAllSelected ? (
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setOpenConfirm(true);
                          }}
                          style={{ fontSize: "15px" }}
                        >
                          <img alt="" src={deleteImg} style={{ width: "20px", marginRight: "10px" }}></img>
                          {t("ALL.MENU.DELETE")}
                          <span
                            style={{
                              background: "var( --color-accepted)",
                              marginLeft: "6px",
                              color: "var(--color-main-text)",
                              borderRadius: "20px",
                              padding: "4px 8px",
                              fontSize: "12px",
                            }}
                          >
                            <b>{distributorsAllSelected ? distributorLength : distributorListID?.length}</b>
                          </span>
                        </MenuItem>
                      ) : (
                        <MenuItem disabled style={{ fontSize: "15px" }}>
                          <img alt="" src={deleteImg} style={{ width: "20px", marginRight: "10px" }}></img>
                          {t("ALL.MENU.DELETE")}
                        </MenuItem>
                      )}
                    </div>
                  )}

                  <MenuItem onClick={copy} style={{ fontSize: "15px" }}>
                    <img alt="" src={copyImg} style={{ width: "20px", marginRight: "10px" }}></img>
                    {t("ALL.MENU.COPY-LINK")}
                  </MenuItem>
                  {/* <MenuItem onClick={handleClose} style={{fontSize:'15px'}}>Logout</MenuItem> */}
                </Menu>
              </div>
            </div>
          )}
        </Card.Header>
        <Card.Body>{RenderTabla1()}</Card.Body>
      </Card>
      <DistributorTableTransfer open={openTransferModal} setOpen={setOpenTransferModal} />
      {renderLateralContainerSearch()}
      {showModal()}
      {showModalExport()}
    </div>
  );
}
