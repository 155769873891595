import { useState, useContext } from "react";

import { getAllUsersById } from "../../api/services/user_services";

import { ApplicationContext } from "../../context/ApplicationContext";

//Redux
import { useSelector } from "react-redux";
import { IRootState } from "../../store/redux";
import useFetchAndLoad from "../useFetchAndLoad";
import useAsync from "../useAsync";

import { typeUserData } from "../../constants/userTypes";
import { createAddapterDistributorSet } from "./adapters/distributorSetAdapter";
import { IDistributorSetListAPI, IDistributorsSetDOM } from "../../api/interfaces/interfaz_distributor_Set";
import {
  deleteManyDistributorsSet,
  getAllDistributorSet,
  getExportDistributorSetByDistributor,
  getExportDistributorSetByGroup,
  getExportDistributorSetByID,
  getExportDistributorsSet,
} from "../../api/services/distributor_set_services";
import useToastMui from "../Toasts/useToastMui";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

function useDistributorSet(
  groupCode: string,
  groupName: string,
  name: string,
  origin: string,
  membersOfSet: string,
  //If the data comes from groupInfo, the list will be filtered by the group
  groupId?: string,
  distributorId?: string
) {
  const {
    setExportToggle,
    setDistributorSetListId,
    setDistributorSetLength,
    setAlertDeleteDistributorSet,
    distributorsSetPage,
    setDistributorsSetPage,
  } = useContext(ApplicationContext);
  //Redux
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected } = profile;
  const { sub } = profile;
  const { i18n } = useTranslation();

  const [distributorSetArray, setDistributorSetArray] = useState<IDistributorsSetDOM[]>();

  const [lastPage, setLastPage] = useState(0);
  const [searchToggle, setSearchToggle] = useState(false);
  const [deletedToogle, setDeletedToogle] = useState(false);
  const limit = 5;
  const { handleCreateToast } = useToastMui();
  //Alerts
  const [wrongAlertStatus, setWrongAlertStatus] = useState<boolean[]>([false, false, false, false, false]);

  const { loading, callEndpoint } = useFetchAndLoad();
  //to sort list by api

  const [countCode, setCountCode] = useState(1);
  const [typeSort, setTypeSort] = useState("");

  const getQuery = (
    groupCode: string,
    groupName: string,
    name: string,
    origin: string,
    membersOfSet: string,
    //If the data comes from groupInfo, the list will be filtered by the group
    groupId?: string,
    distributorId?: string,
    sort?: string
  ) => {
    const queryKeys: any = {
      group_code: groupCode,
      group_name: groupName,
      name,
      origin,
      members_of_set: membersOfSet,
      group_id: groupId,
      distributor_id: distributorId,
      sort: sort,
    };
    let query = {};
    for (let key in queryKeys) {
      if (queryKeys[key]) {
        query = { ...query, [key]: queryKeys[key] };
      }
    }
    return query;
  };

  const loadDistributors = async (
    groupCode: string,
    groupName: string,
    name: string,
    origin: string,
    membersOfSet: string,
    nowPage: number,
    //If the data comes from groupInfo, the list will be filtered by the group
    groupId?: string,
    distributorId?: string
  ) => {
    //! important in groups and users
    //In the use case of distributor if a user is a group or distributor
    //The data will be filtered by the distributor or group assossiated
    let userData: any = {};
    if (sub) {
      const responseUser = getAllUsersById(sub);
      userData = (await responseUser).data;
    }

    let query = getQuery(
      groupCode,
      groupName,
      name,
      origin,
      membersOfSet,
      nameRolSelected === typeUserData.group
        ? userData?.result?.groups_data?._id
          ? userData?.result?.groups_data?._id
          : "-.-.-"
        : groupId,
      nameRolSelected === typeUserData.distributor
        ? userData?.result?.distributors_data?._id
          ? userData?.result?.distributors_data?._id
          : "-.-.-"
        : distributorId,
      typeSort
    );
    query = {
      ...query,
      limit: limit,
      offset: (nowPage - 1) * limit,
    };

    const response = await callEndpoint(getAllDistributorSet({ query }), () =>
      handleCreateToast(t("ALERT-DISTRIBUTORS-ERROR-GET"), "error")
    );
    return response;
  };

  const adaptInfo = (data: IDistributorSetListAPI) => {
    const array: IDistributorsSetDOM[] = createAddapterDistributorSet(data?.result?.items);
    setDistributorSetArray(array);
    setLastPage(data?.result?.total);
    setDistributorSetLength(data?.result?.total);
  };

  useAsync(
    () => loadDistributors(groupCode, groupName, name, origin, membersOfSet, distributorsSetPage, groupId, distributorId),
    adaptInfo,
    () => {},
    [distributorsSetPage, searchToggle, deletedToogle, typeSort]
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setDistributorsSetPage(value);
  };
  const onSearch = () => {
    setSearchToggle((value) => !value);
    setDistributorsSetPage(1);
  };

  const deleteDistributorSet = async (DistributorSetId: string[]) => {
    try {
      const response = deleteManyDistributorsSet(DistributorSetId);
      const data = (await response).data;
      handleCreateToast(t("ALERT-DISTRIBUTORS-SUCCESS-DELETE"), "success");
      return data;
    } catch (error: any) {
      console.log("error deleted distributor");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(t("ALERT-DISTRIBUTORS-ERROR-DELETE"), "error");
    } finally {
      console.log("end");
      setDistributorSetListId([]);
      setDistributorsSetPage(1);
      setDeletedToogle(!deletedToogle);
    }
  };

  // Export
  const exportDistributorSet = async (checkedType: string, distributorListID: string[], checkNeedToExport: boolean[]) => {
    setExportToggle(true);
    try {
      if (checkNeedToExport[0] === true) {
        if (sub) {
          const response = getAllUsersById(sub);
          const data = (await response).data;
          if (nameRolSelected === typeUserData.distributor) {
            if (data?.result?.distributors_data?.code) {
              await getExportDistributorSetByDistributor(checkedType, data?.result?.distributors_data?._id);
            }
          } else if (nameRolSelected === typeUserData.group) {
            if (data?.result?.groups_data?._id) {
              await getExportDistributorSetByGroup(checkedType, data?.result?.groups_data?._id);
            }
          } else {
            await getExportDistributorsSet(checkedType);
          }
          return data;
        }
      }
      if (checkNeedToExport[1] === true) {
        if (distributorListID.length) {
          await getExportDistributorSetByID(
            checkedType,
            distributorListID,
            i18n.language === "fr" ? "Ensembles distributeurs" : "Distributor Set"
          );
        } else {
          let userData: any = {};
          if (sub) {
            const responseUser = getAllUsersById(sub);
            userData = (await responseUser).data;
          }
          let query = getQuery(
            groupCode,
            groupName,
            name,
            origin,
            membersOfSet,
            nameRolSelected === typeUserData.group
              ? userData?.result?.groups_data?._id
                ? userData?.result?.groups_data?._id
                : "-.-.-"
              : groupId,
            nameRolSelected === typeUserData.distributor
              ? userData?.result?.distributors_data?._id
                ? userData?.result?.distributors_data?._id
                : "-.-.-"
              : distributorId
          );
          query = {
            ...query,
          };
          await getExportDistributorSetByID(
            checkedType,
            distributorListID,
            i18n.language === "fr" ? "Ensembles distributeurs" : "Distributor Set",
            query
          );
        }
      }
    } catch (error: any) {
      console.log("error export");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(t("ALERT-DISTRIBUTORS-ERROR-EXPORT"), "error");
    } finally {
      console.log("end");
      setExportToggle(false);
      //   setOpenConfirm(false);
    }
  };

  const alertSuccess = () => {
    setAlertDeleteDistributorSet(true);
  };

  const alertWrong = (Get: boolean, Export: boolean, Delete: boolean) => {
    setWrongAlertStatus([Get, Export, Delete]);
  };

  // * ------------------------SORT----------------------------------//

  // sort

  const incrementCount = (key: string) => {
    setCountCode((prev) => prev + 1);

    if (countCode === 1) {
      setTypeSort(`asc(${key})`);
    }
    if (countCode === 2) {
      setTypeSort(`desc(${key})`);
      setCountCode(1);
    }
  };

  return {
    distributorSetArray,
    loading,
    exportDistributorSet,
    deleteDistributorSet,
    wrongAlertStatus,
    setWrongAlertStatus,
    currentPage: distributorsSetPage,
    lastPage,
    loadDistributors,
    handleChange,
    limit,
    onSearch,

    typeSort,
    incrementCount,
  };
}

const service = {
  useDistributorSet,
};
export default service;
export { useDistributorSet };
