import { Autocomplete, CircularProgress, Divider, TextField, autocompleteClasses } from "@mui/material";
import React, { useContext, useState } from "react";
import { IAutoComplete } from "../../atoms/AutocopleteInput/interface";
import "./style.css";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxes,
  faBuilding,
  faCity,
  faExchangeAlt,
  faHammer,
  faHotel,
  faLaptop,
  faTimes,
  faUser,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import userIcon from "../../../images/userIcon.svg";
import { LocalStorageKeys, setInLocalStorage } from "../../../helpers/local-storage-manager";
import { ApplicationContext } from "../../../context";
import ClearIcon from "@mui/icons-material/Clear";
export default function AutoCompleteInputGlobal(props: IAutoComplete) {
  const {
    value,
    options,
    setValue,
    disablePortal,
    id,
    disableCloseOnSelect,
    clearOnEscape,
    disableClearable,
    includeInputInList,
    disableListWrap,
    openOnFocus,
    autoSelect,
    disabled,
    blurOnSelect,
    clearOnBlur,
    freeSolo,
    multiple,
    getOptionLabel,
    defaultValue,
    variant,
    sx,
    onChangeInput,
    inputLabel,
    onClick,
    loading,
    handleChange,
    fullWidth,
  } = props;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [focus, setFocus] = useState(false);
  const [focusActive, setFocusActive] = useState(false);
  const { setGlobalSearchFocus } = useContext(ApplicationContext);

  return (
    <div className="auto-warranty">
      <Autocomplete
        fullWidth={fullWidth}
        value={value}
        sx={{
          borderRadius: "4px 0 0 4px",
          padding: "0",
          fontSize: "16px !important",
          heigth: "30px !important",
          position: "initial !important",
          backgroundColor: "#555",

          [`& .${autocompleteClasses.inputRoot}`]: {
            position: "relative !important",
            left: "0",
            height: "30px !important",
            borderRadius: "4px",
            padding: "0",
            paddingTop: "-5px !important",
            fontSize: "16px !important",
            marginTop: "2px",
            width: "30vw",
          },

          "& .MuiAutocomplete-popupIndicator": { transform: "none" },
        }}
        disablePortal={disablePortal}
        id={id}
        loading={loading}
        options={options}
        onChange={handleChange}
        autoComplete={false}
        renderInput={(params: any) => (
          <TextField
            name="radom-search"
            autoComplete="nope"
            onMouseEnter={() => {
              setFocus(true);
            }}
            onMouseLeave={() => {
              if (!focusActive) setFocus(false);
            }}
            onFocus={(e: any) => {
              if (e.currentTarget === e.target) {
                setFocus(true);
                setFocusActive(true);
                setGlobalSearchFocus(true);
              }
            }}
            onBlur={(e: any) => {
              if (e.currentTarget === e.target) {
                setFocus(false);
                setFocusActive(false);
                setGlobalSearchFocus(false);
              }
            }}
            {...params}
            sx={{
              paddingLeft: "0",
              width: "10vw",
              height: "30px",
              position: "initial",
              fontSize: "16px",
              borderRadius: "4px",
              maxHeight: "400px",

              minWidth: "50px",
            }}
            className={focus ? "inputfocus inputfocusActive " : "inputfocus"}
            onClick={onClick}
            label={inputLabel}
            variant={variant}
            onChange={onChangeInput}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        noOptionsText={i18n.language === "en" ? "No Options" : "Aucune correspondance trouvée"}
        popupIcon={<SearchIcon />}
        // clearIcon={<ClearIcon className={focus ? "cleariconfocus" : "clearicon"} />}
        defaultValue={defaultValue}
        getOptionLabel={getOptionLabel}
        disableCloseOnSelect={disableCloseOnSelect}
        clearOnEscape={clearOnEscape}
        disableClearable={disableClearable}
        includeInputInList={includeInputInList}
        disableListWrap={disableListWrap}
        openOnFocus={openOnFocus}
        autoSelect={autoSelect}
        disabled={disabled}
        blurOnSelect={blurOnSelect}
        clearOnBlur={clearOnBlur}
        freeSolo={freeSolo}
        renderOption={(props, option, { inputValue }) => {
          return (
            <li {...props} style={{ overflowX: "scroll" }}>
              <div
                style={{
                  fontSize: "14px",
                  paddingTop: "0",
                  paddingBottom: "0",
                  display: "flex",
                  gap: "8px",
                  height: "40px",
                  alignItems: "center",
                }}
                onClick={() => {
                  history.push(option.url);

                  history.go(0);
                }}
              >
                {option.resource === "brand" ? (
                  <FontAwesomeIcon fontSize={20} icon={faBoxes} />
                ) : option.resource === "products" ? (
                  <FontAwesomeIcon fontSize={20} icon={faWrench} />
                ) : option.resource === "groups" ? (
                  <FontAwesomeIcon fontSize={20} icon={faHotel} />
                ) : option.resource === "distributors" ? (
                  <FontAwesomeIcon fontSize={20} icon={faBuilding} />
                ) : option.resource === "distributors-set" ? (
                  <FontAwesomeIcon fontSize={20} icon={faCity} />
                ) : option.resource === "customer" ? (
                  <FontAwesomeIcon fontSize={20} icon={faUser} />
                ) : option.resource === "contents" ? (
                  <FontAwesomeIcon fontSize={20} icon={faLaptop} />
                ) : option.resource === "reasons_for_exchange" ? (
                  <FontAwesomeIcon fontSize={20} icon={faHammer} />
                ) : option.resource === "reasons_for_refusal" ? (
                  <FontAwesomeIcon fontSize={20} icon={faTimes} />
                ) : option.resource === "users" ? (
                  <img
                    alt=""
                    src={userIcon}
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                ) : option.resource === "exchanges" ? (
                  <FontAwesomeIcon fontSize={20} icon={faExchangeAlt} />
                ) : null}{" "}
                <div style={{ width: "max-content" }}>
                  {option.resource === "brand"
                    ? `${t("TABLE.BRAND.TITLE.HEADER-LINK")}: `
                    : option.resource === "products"
                    ? `${t("TABLE.PRODUCT.TITLE.HEADER-LINK")}: `
                    : option.resource === "groups"
                    ? `${t("TABLE.GROUP.TITLE.HEADER-LINK")}: `
                    : option.resource === "distributors"
                    ? `${t("TABLE.DISTRIBUTOR.TITLE.HEADER-LINK")}: `
                    : option.resource === "distributors-set"
                    ? `${t("HOME.MENU.DISTRIBUTOR-SETS")}: `
                    : option.resource === "customer"
                    ? `${t("TABLE.CUSTOMER.TITLE.HEADER-LINK")}: `
                    : option.resource === "contents"
                    ? `${t("HOME.MENU.CONTENTS")}: `
                    : option.resource === "reasons_for_exchange"
                    ? `${t("HOME.MENU.REASON-FOR-EXCHANGE")}: `
                    : option.resource === "reasons_for_refusal"
                    ? `${t("HOME.MENU.REFUSAL-REASONS")}: `
                    : option.resource === "users"
                    ? `${t("HOME.MENU.USERS")}: `
                    : option.resource === "exchanges"
                    ? `${t("HOME.MENU.EXCHANGES")}: `
                    : null}{" "}
                </div>
                <div style={{ width: "max-content" }}>
                  <b>{option.label}</b>
                </div>
                <div style={{ width: "max-content" }}>{option.item1}</div>
                <div style={{ width: "max-content" }}>{option.item2}</div>
                <Divider />
              </div>
            </li>
          );
        }}
        multiple={multiple}
        filterOptions={(options) => {
          return options;
        }}
      />
    </div>
  );
}
