import { VariantType, useSnackbar } from "notistack";
import "./style.css";
export enum ToastVariant {
  success = "success",
  error = "error",
  warning = "warning",
  info = "info",
  default = "default",
}
export default function useToastMui() {
  const { enqueueSnackbar } = useSnackbar();
  const handleCreateToast = (message: string, variant: VariantType) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant: variant, anchorOrigin: { horizontal: "center", vertical: "top" }, className: `${variant}` });
  };

  return { handleCreateToast };
}
