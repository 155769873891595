import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useFetchAndLoad from "../../../hooks/useFetchAndLoad";
import { BACKEND_URL } from "../../../config/settings.json";
import { getApisInfo } from "../../../api/services/user_services";
import { fromInfoToTranslatedStatus } from "../../../utilities/excangesStatusTranslations";
export default function useGlobalList(url: string, value: any, setValue: Dispatch<SetStateAction<any>>) {
  const [options, setOptions] = useState<any[]>([]);
  const { callEndpoint, loading } = useFetchAndLoad();

  useEffect(() => {
    if (!value) return;
    callApis();
  }, []);

  const callApis = async () => {
    const response = callEndpoint(await getApisInfo(`${BACKEND_URL}${url}${value}`));
    const data = (await response).data;

    const brandArray = data.result.brands?.map((item: any) => {
      return {
        item: item,
        resource: "brand",
      };
    });
    const contentArray = data.result.contents?.map((item: any) => {
      return {
        resource: "contents",
        item: item,
      };
    });
    const customersArray = data.result.customers?.map((item: any) => {
      return {
        resource: "customer",
        item: item,
      };
    });
    const distributorsArray = data.result.distributors?.map((item: any) => {
      return {
        resource: "distributors",
        item: item,
      };
    });
    const distributorsSetArray = data.result.distributors_set?.map((item: any) => {
      return {
        resource: "distributors-set",
        item: item,
      };
    });

    const groupArray = data.result.groups?.map((item: any) => {
      return {
        resource: "groups",
        item: item,
      };
    });

    const productsArray = data.result.products?.map((item: any) => {
      return {
        resource: "products",
        item: item,
      };
    });
    const exchangesArray = data.result.exchanges?.map((item: any) => {
      return {
        resource: "exchanges",
        item: item,
      };
    });
    const reasonsForExchangeArray = data.result.reasons_for_exchange?.map((item: any) => {
      return {
        resource: "reasons_for_exchange",
        item: item,
      };
    });

    const reasonsForRefusalArray = data.result.reasons_for_refusal?.map((item: any) => {
      return {
        resource: "reasons_for_refusal",
        item: item,
      };
    });
    const usersArray = data.result.users?.map((item: any) => {
      return {
        resource: "users",
        item: item,
      };
    });
    // const mappedData = data.result.items?.map((item: any) => (item[key] ? item[key] : ""));
    // let setArray: any = new Set(mappedData);
    // setOptions([...setArray]);

    setOptions([
      ...brandArray,
      ...productsArray,
      ...groupArray,
      ...contentArray,
      ...distributorsArray,
      ...distributorsSetArray,
      ...customersArray,
      ...exchangesArray,
      ...reasonsForExchangeArray,
      ...reasonsForRefusalArray,
      ...usersArray,
    ]);
  };

  const handleChange = (event: any, newValue: any | null) => {
    setValue(newValue);
    if (!newValue) return;
  };
  const onChangeInput = (event: any, newValue: any | null) => {
    setValue(event.target.value);
  };

  return { value, options, setValue, handleChange, onChangeInput, loading, callApis };
}
