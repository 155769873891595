import React, { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faExchangeAlt, faComment, faBars, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb, Card, Nav, Tab, Modal } from "react-bootstrap";
import "./exchangesInfo.css";
import { ApplicationContext } from "../../../context/ApplicationContext";
import ExchangesDetailsTable from "../../organisms/ExchangesDetailsTable/ExchangesDetailsTable";
import ExchangesIdentificationAndStatusTable from "../../organisms/ExchangesIdentificationAndStatusTable/ExchangesIdentificationAndStatusTable";
import ExchangesCustomerTable from "../../organisms/ExchangesCustomerTable/ExchangesCustomerTable";
import ExchangesDistributorTable from "../../organisms/ExchangesDistributorTable/ExchangesDistributorTable";
import ExchangesProductTable from "../../organisms/ExchangesProductTable/ExchangesProductTable";

// bars menu
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import Reloadimg from "../../../images/reload.svg";
import deleteImg from "../../../images/delete.svg";
import copyImg from "../../../images/copy.svg";
import copyItemImg from "../../../images/copy_img.svg";

import ChangeLog from "../ChangeLog/ChangeLog";
import blueCircle from "../../../images/blueCircle.svg";
import redCircle from "../../../images/redCircle.svg";
import greenCircle from "../../../images/greenCircle.svg";
import orangeCircle from "../../../images/orangeCircle.svg";
import yellowCircle from "../../../images/yellowCircle.svg";
import changeLogIcon from "../../../images/changeLogIcon.svg";
import exportImg from "../../../images/export.svg";
import Distributor from "../../pages/Distributor/Distributor";
import CreateDistributor from "../../pages/Distributor/CreateDistributor";
import Customers from "../../pages/Customers/Customers";
import CreateCustomer from "../../pages/CreateCustomer/CreateCustomer";
import Products from "../../pages/Products";
import CreateProduct from "../../pages/CreateProduct/CreateProduct";
import ReasonForExchange from "../../pages/ReasonForExchange";
import CreateReasonForExchange from "../../pages//ReasonForExchange/CreateReasonForExchange";
import RefusalReasons from "../RefusalReasons";
import CreateRefualReasons from "../RefusalReasons/CreateRefusalReasons";
import DownloadImage from "../../atoms/DownloadImage";
//API
import {
  getAllExchangesById,
  updateExchangesStatus,
  updateExchanges,
  deleteExchanges,
  getExchangePicture,
  createExchange,
  getExportExchangesByID,
  getExportPdfByID,
  getExportPdfByIDNewPage,
} from "../../../api/services//exchanges_services";
import { IExchangesListId } from "../../../api/interfaces/interfaz_exchanges";
import LoadingSkeletonInfo from "../../organisms/LoadingSkeleton/LoadingSkeletonInfo";
import { Link, useHistory } from "react-router-dom";
import Comments from "../../organisms/Comments";
import { getAllCommentsByResourceID } from "../../../api/services/comments_services";
import { IComments } from "../../../api/interfaces/interfaz_comments";

import { useTranslation } from "react-i18next";
import LoadingImageSkeleton from "../../organisms/LoadingSkeleton/LoadingImageSkeleton";

//Redux
import { useSelector } from "react-redux";
import { IRootState } from "../../../store/redux";
import { useGetExchanges } from "../../../hooks/Exchanges/useGetExchanges";
import { typeUserData } from "../../../constants/userTypes";
import usePictures from "../../../hooks/usePictures";
import FullLoading from "../../atoms/FullLoading";
import ImageExchange from "./ImageExchange";
import useToastMui from "../../../hooks/Toasts/useToastMui";
import {
  fromInfoToTranslatedStatus,
  fromInfoToTranslatedStatusButton,
  fromStatusTranslatedToKey,
} from "../../../utilities/excangesStatusTranslations";
import { fromInfoToTranslatedCountry } from "../../../utilities/countryTraslations";
import ExportInfo from "../../molecules/TransferList/ExportInfo/ExportInfo";
import FileAttachement from "../../atoms/FileAttachement/FileAttachement";
import ImagesApi from "./ImagesApi";
import { getAllUsersById } from "../../../api/services/user_services";
import print from "../../../images/print.svg";
import ModalConfirm from "../../molecules/ModalConfirm/ModalConfirm";
import { useMediaQuery } from "@material-ui/core";
import GuardNotAuthorized from "../../atoms/ItemPermission/GuardNotAuthorized";
interface IExchangesInfo {
  id: string;
}

const listTypeOptions = ["Other", ""];
export enum statusExchangesE {
  Draft = "Draft",
  Submitted = "Submitted",
  Abandonned = "Abandonned",
  Analysis = "Analysis",
  Accepted = "Accepted",
  Available = "Available",
  Exchanged = "Exchanged",
  Refused = "Refused",
  Recycled = "Recycled",
}

export default function ExchangesInfo(props: IExchangesInfo) {
  const { t, i18n } = useTranslation();
  const matches = useMediaQuery("(max-width:600px)");
  let optionsStatus = {
    Draft: `${t("HOME.MENU.EXCHANGES-DRAFT")}`,
    Abandonned: `${t("HOME.MENU.EXCHANGES-ABANDONNED")}`,
    Submitted: `${t("HOME.MENU.EXCHANGES-SUBMITTED")}`,
    Analysis: `${t("HOME.MENU.EXCHANGES-ANALYSIS")}`,
    Accepted: `${t("HOME.MENU.EXCHANGES-ACCEPTED")}`,
    Available: `${t("HOME.MENU.EXCHANGES-AVAILABLE")}`,
    Exchanged: `${t("HOME.MENU.EXCHANGES-EXCHANGED")}`,
    Refused: `${t("HOME.MENU.EXCHANGES-REFUSED")}`,
    Recycled: `${t("HOME.MENU.EXCHANGES-RECYCLED")}`,
  };

  let statusOptions = [
    "",
    `${t("HOME.MENU.EXCHANGES-DRAFT")}`,
    `${t("HOME.MENU.EXCHANGES-ABANDONNED")}`,
    `${t("HOME.MENU.EXCHANGES-SUBMITTED")}`,
    `${t("HOME.MENU.EXCHANGES-ANALYSIS")}`,
    `${t("HOME.MENU.EXCHANGES-ACCEPTED")}`,
    `${t("HOME.MENU.EXCHANGES-AVAILABLE")}`,
    `${t("HOME.MENU.EXCHANGES-EXCHANGED")}`,
    `${t("HOME.MENU.EXCHANGES-REFUSED")}`,
    `${t("HOME.MENU.EXCHANGES-RECYCLED")}`,
  ];

  let history = useHistory();
  const { handleCreateToast } = useToastMui();
  //Redux
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected, sub } = profile;

  //!------------------------- APP CONTEXT -----------------------------------//
  const {
    openDistributorTable,
    setOpenDistributorTable,
    distributorId,
    setDistributorId,
    distributorName,
    setDistributorName,
    distributorClientAccountNumer,
    setDistributorClientAccountNumer,
    distributorDeliveryAccountNumber,
    setDistributorDeliveryAccountNumber,
    distributorCode,
    setDistributorCode,
    distributorEmail,
    setDistributorEmail,
    distributorResponsiveEmail,
    setDistributorResponsiveEmail,
    distributorGroupCode,
    setDistributorGroupCode,
    distributorGroupName,
    setDistributorGroupName,
    distributorPhone,
    setDistributorPhone,
    openCreateDistributor,
    setOpenCreateDistributor,
    setOpenCreateCustomer,
    setOpenCustomerTable,
    openCreateCustomer,
    openCustomerTable,
    setOpenCreateProduct,
    setOpenProductTable,
    openCreateProduct,
    openProductTable,
    openReasonExchangeTable,
    setOpenCreateRefusalReason,
    openCreateRefusalReason,
    setOpenRefusalReasonTable,
    openRefusalReasonTable,
    setOpenReasonExchangeTable,
    openCreateReasonExchange,
    setOpenCreateReasonExchange,
  } = useContext(ApplicationContext);

  const {
    customerId,
    setCustomerId,
    customerUserName,
    setCustomerUserName,
    customerFirstName,
    setCustomerFirstName,
    customerLastName,
    setCustomerLastName,
    customerEmail,
    setCustomerEmail,
    customerMobilePhoneNumber,
    setCustomerMobilePhoneNumber,
    customerZipCode,
    setCustomerZipCode,
    customerCity,
    setCustomerCity,
    customerCountry,
    setCustomerCountry,
    customerLanguage,
    setCustomerLanguage,
    setChangeLogPage,
    changeLogLength,
  } = useContext(ApplicationContext);

  const {
    productId,
    setProductId,
    productReference,
    setProductReference,
    productName,
    setProductName,
    productBrandCode,
    setProductBrandCode,
    productBrandName,
    setProductBrandName,
    productPicture,
    setProductPicture,
    productType,
    setProductType,
    productUnitPrice,
    setProductUnitPrice,
  } = useContext(ApplicationContext);
  const { reasonExchangeGivenReason, reasonExchangeId, setReasonExchangeGivenReason, setReasonExchangeId } = useContext(ApplicationContext);
  const { refusalReasonLabel, refusalReasonId, setRefusalReasonGivenLabel, setRefusalReasonId } = useContext(ApplicationContext);

  //!---------------------- END APP CONTEXT ------------------------------//

  const [tableRender, setTableRender] = useState("Details");
  const [createItems, setCreateItems] = useState(false);
  const [createItemsRefusal, setCreateItemsRefusal] = useState(false);
  const [createItemsDistributor, setCreateItemsDistributor] = useState(false);
  //LateralContainer
  const [showLateralContainer, setShowLateralContainer] = useState(false);

  const [number, setNumber] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string>(statusOptions[0]);
  var [currentStatus, setCurrentStatus] = useState<string>("");

  //DETAILS

  const [qrCode, setQrCode] = useState<string>("");
  const [givenReason, setGivenReason] = useState<string>("");
  const [unitPrice, setUnitPrice] = useState<string>("");
  const [codeForDirectEvaluation, setCodeForDirectEvaluation] = useState<string>("");
  const [totalCost, setTotalCost] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [geometricalCoordinates, setGeometricalCoordinates] = useState<string>("");
  const [rating, setRating] = useState<number>(0);

  const [isCopy, setIsCopy] = useState<boolean>(false);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
  const [productPictureFromApi, setProductPictureFromApi] = useState("");

  //Pictures
  const image1 = usePictures();
  const image2 = usePictures();
  const image3 = usePictures();
  const image4 = usePictures();
  //menu fade
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function copy() {
    const url = document.createElement("input");
    url.value = window.location.href;
    navigator.clipboard.writeText(url.value);
    setAnchorEl(null);
  }
  //API STATE
  const [exchangesArray, setExchangesArray] = useState<IExchangesListId>();
  const [loading, setLoading] = useState<boolean>(false);

  // API COMMENTS
  const [commentsArray, setCommentsArray] = useState<IComments>();
  const [loadingComments, setLoadingComments] = useState<boolean>(false);
  //Modal
  const [openExport, setOpenExport] = useState(false);

  //API STATE

  const [distributorCreated, setDistributorCreated] = useState<any>();

  //Alerts

  const [warningAlertStatus, setWarningAlertStatus] = useState<boolean[]>([false, false]);

  //DELETE MODAL
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  //DROPDOWN OPTIONS

  if (currentStatus === optionsStatus.Draft) {
    if (nameRolSelected === typeUserData.admin) {
      statusOptions = [optionsStatus.Draft, optionsStatus.Submitted];
    } else {
      statusOptions = [optionsStatus.Draft];
    }
  }

  if (currentStatus === optionsStatus.Submitted) {
    if (nameRolSelected === typeUserData.admin || nameRolSelected === typeUserData.afterSales) {
      // statusOptions = [optionsStatus.Submitted, optionsStatus.Analysis, optionsStatus.Accepted, optionsStatus.Refused];
      statusOptions = [optionsStatus.Submitted, optionsStatus.Accepted, optionsStatus.Refused];
    } else {
      statusOptions = [optionsStatus.Submitted];
    }
  }
  if (currentStatus === optionsStatus.Analysis) {
    if (nameRolSelected === typeUserData.admin || nameRolSelected === typeUserData.afterSales) {
      statusOptions = [optionsStatus.Analysis, optionsStatus.Accepted, optionsStatus.Refused];
    } else {
      statusOptions = [optionsStatus.Analysis];
    }
  }
  if (currentStatus === optionsStatus.Accepted) {
    if (nameRolSelected === typeUserData.admin) {
      statusOptions = [optionsStatus.Accepted, optionsStatus.Submitted, optionsStatus.Available];
    } else if (nameRolSelected === typeUserData.group || nameRolSelected === typeUserData.distributor) {
      statusOptions = [optionsStatus.Accepted, optionsStatus.Available];
    } else if (nameRolSelected === typeUserData.afterSales) {
      statusOptions = [optionsStatus.Accepted];
    } else {
      statusOptions = [optionsStatus.Accepted];
    }
  }
  if (currentStatus === optionsStatus.Available) {
    if (nameRolSelected !== typeUserData.afterSales) {
      statusOptions = [optionsStatus.Available, optionsStatus.Exchanged, optionsStatus.Refused];
    } else {
      statusOptions = [optionsStatus.Available, optionsStatus.Recycled, optionsStatus.Refused];
    }
  }
  if (currentStatus === optionsStatus.Exchanged) {
    if (nameRolSelected === typeUserData.admin || nameRolSelected === typeUserData.afterSales) {
      statusOptions = [optionsStatus.Exchanged, optionsStatus.Recycled];
    } else {
      statusOptions = [optionsStatus.Exchanged];
    }
  }
  if (currentStatus === optionsStatus.Refused) {
    if (nameRolSelected === typeUserData.admin) {
      statusOptions = [optionsStatus.Refused, optionsStatus.Submitted];
    } else {
      statusOptions = [optionsStatus.Refused];
    }
  }
  if (currentStatus === optionsStatus.Recycled) {
    statusOptions = [optionsStatus.Recycled];
  }

  useEffect(() => {
    (async () => {
      const responseAPI = await getExchangesByID(props.id);
      await getComments();

      if (responseAPI?.result.product_data?.picture?.extension) {
        picture.getPictureInfo(
          {
            extension: responseAPI?.result.product_data?.picture?.extension,
            file_content: responseAPI?.result.product_data?.picture?.name,
          },
          responseAPI?.result.product_data?._id
        );
      }
    })();
    setChangeLogPage(1);
  }, []);

  const picture = usePictures();

  //! ------------------------------API CALLS--------------------------------//

  const getExchangesByID = async (ExchangeId: string) => {
    setLoading(!loading);
    try {
      const response = getAllExchangesById(ExchangeId);
      const data = (await response).data;

      //If the exchange are not asossiated to a user it will be redirect to another page

      if (nameRolSelected === typeUserData.distributor || nameRolSelected === typeUserData.group) {
        const responseUser = getAllUsersById(`${sub}`);
        const dataUser = (await responseUser).data;
        if (nameRolSelected === typeUserData.distributor) {
          if (dataUser?.result?.distributors_data) {
            if (dataUser?.result?.distributors_data._id !== data?.result?.distributor_id) {
              if (document.referrer) {
                history.go(-1);
              } else {
                history.push("/");
              }
              handleCreateToast(`${t("ALERT-NO-AUTH-EXCHANGE")}`, "info");
              return;
            }
          } else {
            if (document.referrer) {
              history.go(-1);
            } else {
              history.push("/");
            }
            handleCreateToast(`${t("ALERT-NO-AUTH-EXCHANGE")}`, "info");
            return;
          }
        }
        if (nameRolSelected === typeUserData.group) {
          if (dataUser?.result?.groups_data) {
            if (dataUser?.result?.groups_data._id !== data?.result?.group_data?.id) {
              if (document.referrer) {
                history.go(-1);
              } else {
                history.push("/");
              }
              handleCreateToast(`${t("ALERT-NO-AUTH-EXCHANGE")}`, "info");
              return;
            }
          } else {
            if (document.referrer) {
              history.go(-1);
            } else {
              history.push("/");
            }
            handleCreateToast(`${t("ALERT-NO-AUTH-EXCHANGE")}`, "info");
            return;
          }
        }
      }

      //Setup of data
      setExchangesArray(data);
      setNumber(data.result.number);
      setDate(data.result.date);
      setSelectedStatus(fromInfoToTranslatedStatus(data.result.status[0]?.status));
      setCurrentStatus(fromInfoToTranslatedStatus(data.result.status[0]?.status));
      setRating(data.result.evaluation);

      //permissions to type user
      if (fromInfoToTranslatedStatus(data.result.status[0]?.status) === optionsStatus.Draft) {
        if (nameRolSelected === typeUserData.admin || nameRolSelected === typeUserData.afterSales) {
          setCreateItems(true);
        } else {
          setCreateItems(false);
        }
      } else if (fromInfoToTranslatedStatus(data.result.status[0]?.status) === optionsStatus.Submitted) {
        setCreateItemsDistributor(true);
        setCreateItemsRefusal(true);
      } else if (fromInfoToTranslatedStatus(data.result.status[0]?.status) === optionsStatus.Abandonned) {
        if (nameRolSelected === typeUserData.admin || nameRolSelected === typeUserData.afterSales) {
          setCreateItemsDistributor(true);
        }
      } else if (fromInfoToTranslatedStatus(data.result.status[0]?.status) === optionsStatus.Analysis) {
        setCreateItemsRefusal(true);
      } else if (fromInfoToTranslatedStatus(data.result.status[0]?.status) === optionsStatus.Refused) {
        setCreateItemsRefusal(true);
      } else if (fromInfoToTranslatedStatus(data.result.status[0]?.status) === optionsStatus.Available) {
        setCreateItemsRefusal(true);
      } else {
        setCreateItems(false);
        setCreateItemsDistributor(false);
        setCreateItemsRefusal(false);
      }

      setQrCode(data.result.qr_code);
      //Refusal reason
      setRefusalReasonGivenLabel(data.result.refusal_reason_data?.label);
      setRefusalReasonId(data.result.refusal_reason_data?._id);

      //reason for exchange
      setReasonExchangeGivenReason(data.result.exchange_reason_data?.label);
      setReasonExchangeId(data.result.exchange_reason_data?._id);

      setCodeForDirectEvaluation(data.result.code_direct_evaluation);
      setDescription(data.result.comments);
      setUnitPrice(data.result.product_data?.unit_price);
      setTotalCost(data.result.total_cost);
      setGeometricalCoordinates(data.result.geographical_coordinates);
      setProductPicture({
        extension: "",
        fileContent: data.result.product_data?.picture?.name,
      });

      setCustomerId(data.result.customer_id);
      setCustomerUserName(data.result.user_data?.username);
      setCustomerFirstName(data.result.user_data?.first_name);
      setCustomerLastName(data.result.user_data?.last_name);
      setCustomerEmail(data.result.user_data?.email);
      setCustomerMobilePhoneNumber(data.result.user_data.mobile_phone);
      setCustomerZipCode(data.result.user_data.address.zipcode);
      setCustomerCity(data.result.user_data.address.city);

      setCustomerCountry(fromInfoToTranslatedCountry(data.result?.user_data.address?.country));
      setCustomerLanguage(
        data.result.user_data.users_settings.lenguageActive === "English" ? `${t("LANGUAGE-LIST-ENGLISH")}` : `${t("LANGUAGE-LIST-FRENCH")}`
      );

      setDistributorId(data.result.distributor_id);
      setDistributorCode(data.result.distributor_data?.code);
      setDistributorName(data.result.distributor_data?.name);
      setDistributorGroupCode(data.result.group_data?.code);
      setDistributorGroupName(data.result.group_data?.name);
      setDistributorClientAccountNumer(data.result.distributor_data?.client_account_number);
      setDistributorPhone(data.result.distributor_data?.address?.phone);
      setDistributorEmail(data.result.distributor_data?.email);
      setDistributorResponsiveEmail(data.result.distributor_data?.responsible_email);

      setDistributorDeliveryAccountNumber(data.result.distributor_data?.delivery_account_number);

      setProductId(data.result.product_id);
      setProductReference(data.result.product_data?.reference);
      setProductName(data.result.product_data?.name);
      setProductBrandCode(data.result.brand_data?.code);
      setProductBrandName(data.result.brand_data?.name);
      setProductType(data.result.product_data?.type);
      setProductUnitPrice(data.result.product_data?.unit_price);
      setProductPictureFromApi(data.result.product_data?.picture);
      return data;
    } catch (error: any) {
      console.log("error getting one exchanges");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(t("ALERT-EXCHANGES-ERROR-GET-ONE"), "error");
    } finally {
      console.log("end");
      setLoading(false);
    }
  };

  const exportItem = async (checkedType: string, id: string[]) => {
    try {
      await getExportExchangesByID(checkedType, id, i18n.language === "fr" ? "Echange" : "Exchange");
    } catch (error: any) {
      if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(t("ALERT-EXCHANGES-ERROR-EXPORT"), "error");
    }
  };

  const deleteExchange = async (ExchangesId: string) => {
    setLoading(!loading);
    let redirect = false;
    try {
      const response = deleteExchanges(ExchangesId);
      const data = (await response).data;
      handleCreateToast(t("ALERT-EXCHANGES-SUCCESS-DELETE"), "success");

      redirect = true;
      return data;
    } catch (error: any) {
      console.log("error deleted exchange");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(t("ALERT-EXCHANGES-ERROR-DELETE"), "error");

      redirect = false;
    } finally {
      console.log("end");
      setLoading(false);
      if (redirect) {
        history.push("/exchanges");
      }
    }
  };
  const [openConfirm, setOpenConfirm] = useState(false);
  const [statusToUpdate, setStatusToUpdate] = useState("");

  const showModal = () => {
    return (
      <>
        <Modal
          show={openConfirm}
          onHide={() => {
            setOpenConfirm(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("INFO.USER.RESET-CONFIRM")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-lg-12 p-4">
                <h4 className="h4-stl">
                  {t("MODAL-CONFIRM-CHANGE-STATUS-DESCRIPTION")} {fromInfoToTranslatedStatusButton(statusToUpdate)}
                </h4>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                updateToSpecificStatus(statusToUpdate, props.id);
                setOpenConfirm(false);
              }}
              variant="success"
            >
              {t("INFO.USER.RESET-BUTTON-CONFIRM")}
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setOpenConfirm(false);
              }}
            >
              {t("INFO.USER.RESET-BUTTON-CANCEL")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const [base64Picture1, setBase64Picture1] = useState<string | null>("");
  const [base64Picture2, setBase64Picture2] = useState<string | null>("");
  const [base64Picture3, setBase64Picture3] = useState<string | null>("");
  const [base64Picture4, setBase64Picture4] = useState<string | null>("");

  const [loadingPicture1, setLoadingPicture1] = useState(false);
  const [loadingPicture2, setLoadingPicture2] = useState(false);
  const [loadingPicture3, setLoadingPicture3] = useState(false);
  const [loadingPicture4, setLoadingPicture4] = useState(false);

  const getPicture1 = async (ExchangesId: string, update?: boolean) => {
    try {
      if (!base64Picture1 || update) {
        setLoadingPicture1(true);
        const response = getExchangePicture(
          ExchangesId,
          exchangesArray?.result?.pictures[0]?.file_content ? exchangesArray?.result?.pictures[0]?.file_content : ""
        );
        const data = (await response).data;
        setBase64Picture1(data?.result?.base64);
        return data;
      }
    } catch (error: any) {
      console.log("error picture");
    } finally {
      setLoadingPicture1(false);
    }
  };

  const getPicture2 = async (ExchangesId: string, update?: boolean) => {
    try {
      if (!base64Picture2 || update) {
        setLoadingPicture2(true);
        const response = getExchangePicture(
          ExchangesId,
          exchangesArray?.result?.pictures[1]?.file_content ? exchangesArray?.result?.pictures[1]?.file_content : ""
        );
        const data = (await response).data;
        setBase64Picture2(data?.result?.base64);
        return data;
      }
    } catch (error: any) {
      console.log("error picture");
    } finally {
      setLoadingPicture2(false);
    }
  };
  const getPicture3 = async (ExchangesId: string, update?: boolean) => {
    try {
      if (!base64Picture3 || update) {
        setLoadingPicture3(true);
        const response = getExchangePicture(
          ExchangesId,
          exchangesArray?.result?.pictures[2]?.file_content ? exchangesArray?.result?.pictures[2]?.file_content : ""
        );
        const data = (await response).data;
        setBase64Picture3(data?.result?.base64);
        return data;
      }
    } catch (error: any) {
      console.log("error picture");
    } finally {
      setLoadingPicture3(false);
    }
  };
  const getPicture4 = async (ExchangesId: string, update?: boolean) => {
    try {
      if (!base64Picture4 || update) {
        setLoadingPicture4(true);
        const response = getExchangePicture(
          ExchangesId,
          exchangesArray?.result?.pictures[3]?.file_content ? exchangesArray?.result?.pictures[3]?.file_content : ""
        );
        const data = (await response).data;
        setBase64Picture4(data?.result?.base64);
        return data;
      }
    } catch (error: any) {
      console.log("error picture");
    } finally {
      setLoadingPicture4(false);
    }
  };

  const [toggle, setToggle] = useState(false);
  useGetExchanges("", toggle);
  //!----------------------------IMPORTANTE!!!-----------------------------
  //?Se deben cambiar al tiempo handleUpdateStatus y updateToSpecificStatus
  //!----------------------------IMPORTANTE!!!-----------------------------

  //*THIS IS THE UPDATE
  const handleUpdateStatus = async (close: boolean) => {
    //IF STATUS SELECET AND CURRENT STATUS ARE THE SAME
    //UPDATE ALL INFO
    if (currentStatus === selectedStatus) {
      try {
        await updateExchangesInfo(props.id, close);
      } catch (error: any) {
        console.log("error updated status");
        console.log(error);
        if (error?.request?.status !== 401 && error?.request?.status !== 403)
          handleCreateToast(t("ALERT-EXCHANGES-ERROR-UPDATE-STATUS"), "error");
      }
    } else {
      //IF STATUS SELECET AND CURRENT STATUS ARE NOT THE SAME
      //UPDATE ALL INFO AND THEN UPDATE STATUS
      try {
        let response: any = [{}];
        if (selectedStatus === optionsStatus.Refused) {
          //NEED REFUSAL REASON TU UPDATE TO REFUSED
          if (refusalReasonLabel) {
            await updateExchangesInfo(props.id, close);
            response = updateExchangesStatus(props.id, fromStatusTranslatedToKey(selectedStatus));
            const data = (await response).data;
            setDistributorCreated(data);
            return data;
          } else {
            alertWarning(true, false);
          }
        } else if (selectedStatus === optionsStatus.Submitted) {
          //NEED DISTRIBUTOR TU UPDATE TO REFUSED
          if (distributorId) {
            await updateExchangesInfo(props.id, close);
            response = updateExchangesStatus(props.id, fromStatusTranslatedToKey(selectedStatus));
            const data = (await response).data;
            setDistributorCreated(data);
            return data;
          } else {
            alertWarning(false, true);
          }
        } else {
          await updateExchangesInfo(props.id, close);
          response = updateExchangesStatus(props.id, fromStatusTranslatedToKey(selectedStatus));
          const data = (await response).data;
          setDistributorCreated(data);
          return data;
        }
      } catch (error: any) {
        console.log("error updated");
        console.log(error);

        if (error?.request?.status !== 401 && error?.request?.status !== 403)
          handleCreateToast(t("ALERT-EXCHANGES-ERROR-UPDATE-STATUS"), "error");
      } finally {
        setLoading(false);
        console.log("end");
        setToggle(!toggle);
        getExchangesByID(props.id);
      }
    }
  };

  const updateToSpecificStatus = async (statusSpecific: string, id: string) => {
    try {
      let response: any = [{}];
      if (statusSpecific === optionsStatus.Refused) {
        //NEED REFUSAL REASON TU UPDATE TO REFUSED
        if (refusalReasonLabel) {
          await updateExchangesInfo(id, false);
          response = updateExchangesStatus(id, fromStatusTranslatedToKey(statusSpecific));
          const data = (await response).data;
          setDistributorCreated(data);
          return data;
        } else {
          alertWarning(true, false);
        }
      } else if (statusSpecific === optionsStatus.Submitted) {
        //NEED DISTRIBUTOR TU UPDATE TO REFUSED
        if (distributorId) {
          await updateExchangesInfo(id, false);
          response = updateExchangesStatus(id, fromStatusTranslatedToKey(statusSpecific));
          const data = (await response).data;
          setDistributorCreated(data);
          return data;
        } else {
          alertWarning(false, true);
        }
      } else {
        await updateExchangesInfo(id, false);
        response = updateExchangesStatus(id, fromStatusTranslatedToKey(statusSpecific));
        const data = (await response).data;
        setDistributorCreated(data);
        return data;
      }
    } catch (error: any) {
      console.log("error updated");
      console.log(error);

      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(t("ALERT-EXCHANGES-ERROR-UPDATE-STATUS"), "error");
    } finally {
      setLoading(false);
      console.log("end");
      setToggle(!toggle);
      getExchangesByID(props.id);
    }
  };

  //UPDATE ALL INFO
  const updateExchangesInfo = async (ExchangeId: string, close: boolean) => {
    setLoadingUpdate(true);
    try {
      if (productId && customerId) {
        if (image1.picture || image2.picture || image3.picture || image4.picture) {
          const responsePictureApi1 = await getPicture1(props.id, true);
          const responsePictureApi2 = await getPicture2(props.id, true);
          const responsePictureApi3 = await getPicture3(props.id, true);
          const responsePictureApi4 = await getPicture4(props.id, true);

          await updateExchanges(ExchangeId, customerId, distributorId ? distributorId : "", productId, reasonExchangeId, refusalReasonId, [
            image1.picture
              ? image1.picture
              : responsePictureApi1?.result?.base64
              ? responsePictureApi1.result.base64
              : { extension: null, file_content: null },
            image2.picture
              ? image2.picture
              : responsePictureApi2?.result?.base64
              ? responsePictureApi2.result.base64
              : { extension: null, file_content: null },
            image3.picture
              ? image3.picture
              : responsePictureApi3?.result?.base64
              ? responsePictureApi3.result.base64
              : { extension: null, file_content: null },
            image4.picture
              ? image4.picture
              : responsePictureApi4?.result?.base64
              ? responsePictureApi4.result.base64
              : { extension: null, file_content: null },
          ]);
        } else {
          await updateExchanges(ExchangeId, customerId, distributorId ? distributorId : "", productId, reasonExchangeId, refusalReasonId);
        }
        handleCreateToast(t("ALERT-EXCHANGES-SUCCESS-UPDATED"), "success");
        if (!close) return;
        if (document.referrer) {
          history.go(-1);
        } else {
          history.push("/");
        }
        // successUpdated()
      } else {
        alertWarning(false, true);
      }
    } catch (error: any) {
      console.log("error update info");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(t("ALERT-EXCHANGES-ERROR-UPDATE-STATUS"), "error");
    } finally {
      console.log("end");
      setLoadingUpdate(false);
    }
  };

  const copyExchanges = async (close: boolean) => {
    setLoadingUpdate(true);
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const Minutes = date.getMinutes();

    let TodayDate = `${day}/${month}/${year} ${hour}:${Minutes}`;
    if (day <= 9) {
      TodayDate = `0${day}/${month}/${year} ${hour}:${Minutes}`;
    }
    if (month <= 9) {
      TodayDate = `${day}/0${month}/${year} ${hour}:${Minutes}`;
    }
    if (day <= 9 && month <= 9) {
      TodayDate = `0${day}/0${month}/${year} ${hour}:${Minutes}`;
    }
    if (customerUserName && productName && reasonExchangeId) {
      setLoading(!loading);

      try {
        const response = createExchange(
          number,
          TodayDate,
          customerId ? customerId : "",
          distributorId ? distributorId : "",
          productId ? productId : "",
          qrCode ? qrCode : "",
          reasonExchangeId ? reasonExchangeId : "",
          description,
          1,
          totalCost,
          unitPrice,
          geometricalCoordinates,
          codeForDirectEvaluation,
          [
            {
              file_content: exchangesArray?.result?.pictures[0]?.extension ? exchangesArray?.result?.pictures[0]?.extension : null,
              extension: base64Picture1 ? base64Picture1 : null,
            },
            {
              file_content: exchangesArray?.result?.pictures[1]?.extension ? exchangesArray?.result?.pictures[1]?.extension : null,
              extension: base64Picture2 ? base64Picture2 : null,
            },
            {
              file_content: exchangesArray?.result?.pictures[2]?.extension ? exchangesArray?.result?.pictures[2]?.extension : null,
              extension: base64Picture3 ? base64Picture3 : null,
            },
            {
              file_content: exchangesArray?.result?.pictures[3]?.extension ? exchangesArray?.result?.pictures[3]?.extension : null,
              extension: base64Picture4 ? base64Picture4 : null,
            },
          ]
        );
        const data = (await response).data;
        if (currentStatus !== "Draft") {
          await updateToSpecificStatus(currentStatus, data?.result?._id);
        }
        handleCreateToast(t("ALERT-EXCHANGES-COPY"), "success");

        setDistributorCreated(data);

        if (!close) {
          window.location.assign(`/exchange-info/${data?.result._id}`);
          setIsCopy(false);
          handleClose();
        } else {
          if (document.referrer) {
            history.go(-1);
          } else {
            history.push("/");
          }
        }
        return data;
      } catch (error: any) {
        console.log("error create Exchange");
        console.log(error);

        if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(t("ALERT-EXCHANGES-COPY-ERROR"), "error");
      } finally {
        setLoading(false);
        console.log("end");
        setLoadingUpdate(false);
      }
    } else {
      alertWarning(false, true);
      setLoadingUpdate(false);
    }
  };
  // Comments

  const getComments = async () => {
    setLoadingComments(!loadingComments);
    try {
      const response = getAllCommentsByResourceID(props.id);
      const data = (await response).data;
      setCommentsArray(data);
      console.log("commet", "updats");
      return data;
    } catch (error: any) {
      console.log("error getting comments");
      console.log(error);

      if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(t("ALERT-COMMENTS-ERROR-GET"), "error");
    } finally {
      setLoading(false);
      console.log("end");
    }
  };

  //! --------------------------END API CALLS--------------------------------//

  //? ------------------------------ALERTS--------------------------------//

  const alertWarning = (Refuse: boolean, Distributor: boolean) => {
    setWarningAlertStatus([Refuse, Distributor]);
  };

  //? ------------------------------END ALERTS--------------------------------//

  const renderComments = () => {
    return (
      <div>
        <Comments
          commentsArray={commentsArray}
          showLateralContainer={showLateralContainer}
          setShowLateralContainer={() => setShowLateralContainer(!showLateralContainer)}
          setCommentArray={() => {
            getComments();
          }}
          idComponent={props.id}
          whereCreate="Exchange"
          exchange
        />
      </div>
    );
  };

  const handleCloseDistributorTable = () => setOpenDistributorTable(false);

  const renderDistributorTable = () => {
    return (
      <div>
        <Modal
          show={openDistributorTable}
          onHide={handleCloseDistributorTable}
          // backdrop="static"
          // keyboard={false}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Distributor Create={true} />
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  const handleCloseCustomerrTable = () => setOpenCustomerTable(false);

  const renderCustomerTable = () => {
    return (
      <div>
        <Modal
          show={openCustomerTable}
          onHide={handleCloseCustomerrTable}
          // backdrop="static"
          // keyboard={false}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Customers Create={true} />
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  const handleCloseProductTable = () => setOpenProductTable(false);

  const renderProductsTable = () => {
    return (
      <div>
        <Modal
          show={openProductTable}
          onHide={handleCloseProductTable}
          // backdrop="static"
          // keyboard={false}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Products Create={true} />
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  const handleCloseDistributorC = () => setOpenCreateDistributor(false);
  const renderCreatDistributor = () => {
    return (
      <div>
        <Modal
          show={openCreateDistributor}
          onHide={handleCloseDistributorC}
          // backdrop="static"
          // keyboard={false}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <CreateDistributor Created />
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  const handleCloseCustromerC = () => setOpenCreateCustomer(false);
  const RenderCreateCustomer = () => {
    return (
      <div>
        <Modal
          show={openCreateCustomer}
          onHide={handleCloseCustromerC}
          // backdrop="static"
          // keyboard={false}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <CreateCustomer Created />
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  const handleCloseProductC = () => setOpenCreateProduct(false);
  const renderCreateProduct = () => {
    return (
      <div>
        <Modal
          show={openCreateProduct}
          onHide={handleCloseProductC}
          // backdrop="static"
          // keyboard={false}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <CreateProduct Created />
          </Modal.Body>
        </Modal>
      </div>
    );
  };
  const handleCloseReasonExchangeTable = () => setOpenReasonExchangeTable(false);

  const renderReasonExchangeTable = () => {
    return (
      <div>
        <Modal
          show={openReasonExchangeTable}
          onHide={handleCloseReasonExchangeTable}
          // backdrop="static"
          // keyboard={false}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <ReasonForExchange Create={true} />
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  const handleCloseReasonExchangerC = () => setOpenCreateReasonExchange(false);
  const renderCreatReasonExchange = () => {
    return (
      <div>
        <Modal
          show={openCreateReasonExchange}
          onHide={handleCloseReasonExchangerC}
          // backdrop="static"
          // keyboard={false}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <CreateReasonForExchange Created />
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  const handleCloseRefualReasonTable = () => setOpenRefusalReasonTable(false);

  const renderDistrRefualReason = () => {
    return (
      <div>
        <Modal
          show={openRefusalReasonTable}
          onHide={handleCloseRefualReasonTable}
          // backdrop="static"
          // keyboard={false}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <RefusalReasons Create={true} />
          </Modal.Body>
        </Modal>
      </div>
    );
  };
  const handleCloseRefusalReasonC = () => setOpenCreateRefusalReason(false);
  const renderCreatRefusalReason = () => {
    return (
      <div>
        <Modal
          show={openCreateRefusalReason}
          onHide={handleCloseRefusalReasonC}
          // backdrop="static"
          // keyboard={false}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <CreateRefualReasons Created />
          </Modal.Body>
        </Modal>
      </div>
    );
  };
  var linkReason = `#`;
  if (customerId) {
    linkReason = `/customer-info/${customerId}`;
  } else {
    linkReason = `#`;
  }

  const RenderAddressTable = () => {
    return (
      <Card>
        <ExchangesDetailsTable
          defaultValue={description}
          qrCode={number}
          setQrCode={(data: string) => {
            setQrCode(data);
          }}
          givenReason={reasonExchangeGivenReason}
          setGivenReason={(data: string) => {
            setReasonExchangeGivenReason(data);
          }}
          unitPrice={unitPrice}
          setUnitPrice={(data: string) => {
            setUnitPrice(data);
          }}
          codeForDirectEvaluation={codeForDirectEvaluation}
          setCodeForDirectEvaluation={(data: string) => {
            setCodeForDirectEvaluation(data);
          }}
          totalCost={totalCost}
          setTotalCost={(data: string) => {
            setTotalCost(data);
          }}
          handleChange={(content: any) => {
            setDescription(content);
          }}
          geometricalCoordinates={geometricalCoordinates}
          setGeometricalCoordinates={(content: any) => {
            setGeometricalCoordinates(content);
          }}
          Create={createItems}
          selectedStatus={selectedStatus}
          onClickCreate={() => setOpenCreateReasonExchange(true)}
          onClickSearch={() => setOpenReasonExchangeTable(true)}
          evaluation={rating}
        />
      </Card>
    );
  };

  const pictureOne = () => {
    return (
      <Card style={tableRender !== "PictureOne" ? { display: "none" } : { display: "block" }}>
        <div className="row my-4 px-4">
          <h4 className="h4-stl">
            <strong>{t("CREATE.EXCHANGES.INPUT-PICTURE-ONE")}</strong>
            <DownloadImage extension={exchangesArray?.result?.pictures[0]?.extension} image={base64Picture1} name="picture1" />
            {currentStatus === optionsStatus.Draft && (
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                icon={faTrashAlt}
                onClick={() => {
                  image1.handleDeleteImage();
                  setBase64Picture1(null);
                }}
              />
            )}
          </h4>
          {currentStatus === optionsStatus.Draft && (
            <div className="col-12 col-lg-12 my-4">
              <FileAttachement
                fileName={image1.fileNameImage}
                handleChangeStatus={image1.handleChangeStatus}
                disabled={nameRolSelected !== typeUserData.admin && nameRolSelected !== typeUserData.afterSales}
              />
            </div>
          )}
          {!loadingPicture1 ? (
            <ImageExchange extension={exchangesArray?.result?.pictures[0]?.extension} image={base64Picture1} fullWidth />
          ) : (
            <LoadingImageSkeleton />
          )}
        </div>
      </Card>
    );
  };

  const pictureTwo = () => {
    return (
      <Card style={tableRender !== "PictureTwo" ? { display: "none" } : { display: "block" }}>
        <div className="row my-4 px-4">
          <h4 className="h4-stl">
            <strong>{t("CREATE.EXCHANGES.INPUT-PICTURE-TWO")}</strong>
            <DownloadImage extension={exchangesArray?.result?.pictures[1]?.extension} image={base64Picture2} name="picture2" />
            {currentStatus === optionsStatus.Draft && (
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                icon={faTrashAlt}
                onClick={() => {
                  image2.handleDeleteImage();
                  setBase64Picture2(null);
                }}
              />
            )}
          </h4>
          {currentStatus === optionsStatus.Draft && (
            <div className="col-12 col-lg-12 my-4">
              <FileAttachement
                fileName={image2.fileNameImage}
                handleChangeStatus={image2.handleChangeStatus}
                disabled={nameRolSelected !== typeUserData.admin && nameRolSelected !== typeUserData.afterSales}
              />
            </div>
          )}
          {!loadingPicture2 ? (
            <ImageExchange extension={exchangesArray?.result?.pictures[1]?.extension} image={base64Picture2} fullWidth />
          ) : (
            <LoadingImageSkeleton />
          )}
        </div>
      </Card>
    );
  };

  const pictureThree = () => {
    return (
      <Card style={tableRender !== "PictureThree" ? { display: "none" } : { display: "block" }}>
        <div className="row my-4 px-4">
          <h4 className="h4-stl">
            <strong>{t("CREATE.EXCHANGES.INPUT-PICTURE-THREE")}</strong>
            <DownloadImage extension={exchangesArray?.result?.pictures[2]?.extension} image={base64Picture3} name="picture3" />
            {currentStatus === optionsStatus.Draft && (
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                icon={faTrashAlt}
                onClick={() => {
                  image3.handleDeleteImage();
                  setBase64Picture3(null);
                }}
              />
            )}
          </h4>
          {currentStatus === optionsStatus.Draft && (
            <div className="col-12 col-lg-12 my-4">
              <FileAttachement
                fileName={image3.fileNameImage}
                handleChangeStatus={image3.handleChangeStatus}
                disabled={nameRolSelected !== typeUserData.admin && nameRolSelected !== typeUserData.afterSales}
              />
            </div>
          )}
          {!loadingPicture3 ? (
            <ImageExchange extension={exchangesArray?.result?.pictures[2]?.extension} image={base64Picture3} fullWidth />
          ) : (
            <LoadingImageSkeleton />
          )}
        </div>
      </Card>
    );
  };

  const pictureFour = () => {
    return (
      <Card style={tableRender !== "PictureFour" ? { display: "none" } : { display: "block" }}>
        <div className="row my-4 px-4">
          <h4 className="h4-stl">
            <strong>{t("CREATE.EXCHANGES.INPUT-PICTURE-FOUR")}</strong>
            <DownloadImage extension={exchangesArray?.result?.pictures[3]?.extension} image={base64Picture4} name="picture4" />
            {currentStatus === optionsStatus.Draft && (
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                icon={faTrashAlt}
                onClick={() => {
                  image4.handleDeleteImage();
                  setBase64Picture4(null);
                }}
              />
            )}
          </h4>
          {currentStatus === optionsStatus.Draft && (
            <div className="col-12 col-lg-12 my-4">
              <FileAttachement
                fileName={image4.fileNameImage}
                handleChangeStatus={image4.handleChangeStatus}
                disabled={nameRolSelected !== typeUserData.admin && nameRolSelected !== typeUserData.afterSales}
              />
            </div>
          )}
          {!loadingPicture4 ? (
            <ImageExchange extension={exchangesArray?.result?.pictures[3]?.extension} image={base64Picture4} fullWidth />
          ) : (
            <LoadingImageSkeleton />
          )}
        </div>
      </Card>
    );
  };
  var linkCustomer = `#`;
  if (customerId) {
    linkCustomer = `/customer-info/${customerId}`;
  } else {
    linkCustomer = `#`;
  }

  var linkDistributor = `#`;
  if (distributorId) {
    linkDistributor = `/distributor-info/${distributorId}`;
  } else {
    linkDistributor = `#`;
  }

  var linkProduct = `#`;
  if (productId) {
    linkProduct = `/product-info/${productId}`;
  } else {
    linkProduct = `#`;
  }

  return (
    <div>
      {!loading ? (
        <div>
          <Breadcrumb>
            <Link to="/exchanges">
              <Breadcrumb.Item href="/exchanges">{t("HOME.MENU.EXCHANGES")}</Breadcrumb.Item>
            </Link>
            <FontAwesomeIcon icon={faPlay} style={{ width: "10px", margin: "5px 15px" }} />
            {!isCopy ? (
              <Breadcrumb.Item href="#">
                {t("HOME.MENU.EXCHANGES")}: {t("TABLE.EXCHANGE.HEADER-LINK")} #{number}
              </Breadcrumb.Item>
            ) : (
              <Breadcrumb.Item href="#">{t("EXCHANGES-COPY-TITLE")}</Breadcrumb.Item>
            )}
          </Breadcrumb>
          <Card>
            <Card.Header>
              <div className="flex-warranty flex-between">
                <div className="container-warranty flex-start">
                  <FontAwesomeIcon
                    icon={faExchangeAlt}
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                  />
                  {!isCopy ? (
                    <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                      <strong>
                        {t("TABLE.EXCHANGE.HEADER-LINK")}: {t("TABLE.EXCHANGE.HEADER-LINK")} #{number}
                      </strong>
                    </h4>
                  ) : (
                    <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                      <strong>{t("EXCHANGES-COPY-TITLE")}:</strong>
                    </h4>
                  )}
                </div>

                <div className="container-warranty flex-end wrap-warranty wrap-warranty">
                  {selectedStatus != optionsStatus.Abandonned && (
                    <>
                      {nameRolSelected !== typeUserData.viewOnly && (
                        <Button
                          onClick={() => {
                            !isCopy ? handleUpdateStatus(false) : copyExchanges(false);
                          }}
                          variant="primary"
                        >
                          {t("CREATE.ALL.BUTTON-SAVE")}
                        </Button>
                      )}
                    </>
                  )}

                  {selectedStatus != optionsStatus.Abandonned && (
                    <>
                      {nameRolSelected !== typeUserData.viewOnly && (
                        <Button
                          variant="outline-secondary"
                          style={{ minWidth: "150px", maxHeight: "45px" }}
                          onClick={() => {
                            !isCopy ? handleUpdateStatus(true) : copyExchanges(true);
                          }}
                        >
                          {t("CREATE.ALL.BUTTON-SAVE-CLOSE")}
                        </Button>
                      )}
                    </>
                  )}

                  {!isCopy && (
                    <>
                      {(nameRolSelected === typeUserData.admin || nameRolSelected === typeUserData.afterSales) && (
                        <FontAwesomeIcon
                          className="grayBackgr"
                          icon={faComment}
                          style={{ width: "50px", height: "50px" }}
                          onClick={() => setShowLateralContainer(!showLateralContainer)}
                        />
                      )}
                    </>
                  )}
                  {/* Targets  */}
                  <GuardNotAuthorized>
                    {!isCopy && !matches && (
                      <>
                        {nameRolSelected === typeUserData.admin ? (
                          <>
                            {currentStatus === optionsStatus.Submitted && (
                              <>
                                <div
                                  // onClick={() => updateToSpecificStatus(optionsStatus.Analysis)}
                                  // className="statusChangeButton"
                                  className="statusChangeButton disabled analysis"
                                  style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
                                >
                                  <img alt="" src={orangeCircle} style={{ width: "20px", marginRight: "7px" }} />
                                  <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                    {t("EXCHANGES-ANALYZED-BUTTON")}
                                  </h4>
                                </div>
                                <div
                                  onClick={() => {
                                    setOpenConfirm(true);
                                    setStatusToUpdate(optionsStatus.Accepted);
                                  }}
                                  className="statusChangeButton accepted"
                                  style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
                                >
                                  <img alt="" src={yellowCircle} style={{ width: "20px", marginRight: "7px" }} />
                                  <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                    {t("EXCHANGES-ACCEPT-BUTTON")}
                                  </h4>
                                </div>
                                <div
                                  onClick={() => {
                                    setOpenConfirm(true);
                                    setStatusToUpdate(optionsStatus.Refused);
                                  }}
                                  className="statusChangeButton refused"
                                  style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
                                >
                                  <img alt="" src={redCircle} style={{ width: "20px", marginRight: "7px" }} />
                                  <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                    {t("EXCHANGES-REFUSE-BUTTON")}
                                  </h4>
                                </div>
                              </>
                            )}
                          </>
                        ) : nameRolSelected === typeUserData.afterSales ? (
                          <>
                            {currentStatus === optionsStatus.Submitted && (
                              <>
                                <div
                                  className="statusChangeButton disabled analysis"
                                  style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
                                >
                                  <img alt="" src={orangeCircle} style={{ width: "20px", marginRight: "7px" }} />
                                  <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                    {t("EXCHANGES-ANALYZED-BUTTON")}
                                  </h4>
                                </div>
                                <div
                                  onClick={() => {
                                    setOpenConfirm(true);
                                    setStatusToUpdate(optionsStatus.Accepted);
                                  }}
                                  className="statusChangeButton accepted"
                                  style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
                                >
                                  <img alt="" src={yellowCircle} style={{ width: "20px", marginRight: "7px" }} />
                                  <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                    {t("EXCHANGES-ACCEPT-BUTTON")}
                                  </h4>
                                </div>
                                <div
                                  onClick={() => {
                                    setOpenConfirm(true);
                                    setStatusToUpdate(optionsStatus.Refused);
                                  }}
                                  className="statusChangeButton refused"
                                  style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
                                >
                                  <img alt="" src={redCircle} style={{ width: "20px", marginRight: "7px" }} />
                                  <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                    {t("EXCHANGES-REFUSE-BUTTON")}
                                  </h4>
                                </div>
                              </>
                            )}
                          </>
                        ) : null}

                        {nameRolSelected === typeUserData.admin ? (
                          <div style={currentStatus === optionsStatus.Analysis ? {} : { display: "none" }}>
                            {currentStatus === optionsStatus.Analysis && (
                              <div style={{ display: "flex", flexWrap: "wrap" }}>
                                <div
                                  onClick={() => {
                                    setOpenConfirm(true);
                                    setStatusToUpdate(optionsStatus.Accepted);
                                  }}
                                  className="statusChangeButton accepted"
                                  style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
                                >
                                  <img alt="" src={yellowCircle} style={{ width: "20px", marginRight: "7px" }} />
                                  <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                    {t("EXCHANGES-ACCEPT-BUTTON")}
                                  </h4>
                                </div>
                                <div
                                  onClick={() => {
                                    setOpenConfirm(true);
                                    setStatusToUpdate(optionsStatus.Refused);
                                  }}
                                  className="statusChangeButton refused"
                                  style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
                                >
                                  <img alt="" src={redCircle} style={{ width: "20px", marginRight: "7px" }} />
                                  <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                    {t("EXCHANGES-REFUSE-BUTTON")}
                                  </h4>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : nameRolSelected === typeUserData.afterSales ? (
                          <div style={currentStatus === optionsStatus.Analysis ? {} : { display: "none" }}>
                            {currentStatus === optionsStatus.Analysis && (
                              <div style={{ display: "flex", flexWrap: "wrap" }}>
                                <div
                                  onClick={() => {
                                    setOpenConfirm(true);
                                    setStatusToUpdate(optionsStatus.Accepted);
                                  }}
                                  className="statusChangeButton accepted"
                                  style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
                                >
                                  <img alt="" src={yellowCircle} style={{ width: "20px", marginRight: "7px" }} />
                                  <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                    {t("EXCHANGES-ACCEPT-BUTTON")}
                                  </h4>
                                </div>
                                <div
                                  onClick={() => {
                                    setOpenConfirm(true);
                                    setStatusToUpdate(optionsStatus.Refused);
                                  }}
                                  className="statusChangeButton refused"
                                  style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
                                >
                                  <img alt="" src={redCircle} style={{ width: "20px", marginRight: "7px" }} />
                                  <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                    {t("EXCHANGES-REFUSE-BUTTON")}
                                  </h4>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : null}
                        {nameRolSelected !== typeUserData.afterSales && (
                          <div style={currentStatus === optionsStatus.Accepted ? {} : { display: "none" }}>
                            {currentStatus === optionsStatus.Accepted && (
                              <div style={{ display: "flex", flexWrap: "wrap" }}>
                                <div
                                  onClick={() => {
                                    setOpenConfirm(true);
                                    setStatusToUpdate(optionsStatus.Available);
                                  }}
                                  className="statusChangeButton available"
                                  style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
                                >
                                  <img alt="" src={blueCircle} style={{ width: "20px", marginRight: "7px" }} />
                                  <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                    {t("EXCHANGES-AVAILABLE-BUTTON")}
                                  </h4>
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        {nameRolSelected !== typeUserData.afterSales && (
                          <div style={currentStatus === optionsStatus.Available ? {} : { display: "none" }}>
                            {currentStatus === optionsStatus.Available && (
                              <div style={{ display: "flex", flexWrap: "wrap" }}>
                                <div
                                  onClick={() => {
                                    setOpenConfirm(true);
                                    setStatusToUpdate(optionsStatus.Exchanged);
                                  }}
                                  className="statusChangeButton exchanged"
                                  style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
                                >
                                  <img alt="" src={greenCircle} style={{ width: "20px", marginRight: "7px" }} />
                                  <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                    {t("EXCHANGES-EXCHANGE-BUTTON")}
                                  </h4>
                                </div>
                                <div
                                  onClick={() => {
                                    setOpenConfirm(true);
                                    setStatusToUpdate(optionsStatus.Refused);
                                  }}
                                  className="statusChangeButton refused"
                                  style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
                                >
                                  <img alt="" src={redCircle} style={{ width: "20px", marginRight: "7px" }} />
                                  <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                    {t("EXCHANGES-REFUSE-BUTTON")}
                                  </h4>
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        {nameRolSelected === typeUserData.admin ? (
                          <div style={currentStatus === optionsStatus.Exchanged ? {} : { display: "none" }}>
                            {currentStatus === optionsStatus.Exchanged && (
                              <div style={{ display: "flex", flexWrap: "wrap" }}>
                                <div
                                  onClick={() => {
                                    setOpenConfirm(true);
                                    setStatusToUpdate(optionsStatus.Recycled);
                                  }}
                                  className="statusChangeButton recycled"
                                  style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
                                >
                                  <img alt="" src={redCircle} style={{ width: "20px", marginRight: "7px" }} />
                                  <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                    {t("EXCHANGES-RECYCLE-BUTTON")}
                                  </h4>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : nameRolSelected === typeUserData.afterSales ? (
                          <div style={currentStatus === optionsStatus.Exchanged ? {} : { display: "none" }}>
                            {currentStatus === optionsStatus.Exchanged && (
                              <div style={{ display: "flex", flexWrap: "wrap" }}>
                                <div
                                  onClick={() => {
                                    setOpenConfirm(true);
                                    setStatusToUpdate(optionsStatus.Recycled);
                                  }}
                                  className="statusChangeButton recycled"
                                  style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
                                >
                                  <img alt="" src={redCircle} style={{ width: "20px", marginRight: "7px" }} />
                                  <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                    {t("EXCHANGES-RECYCLE-BUTTON")}
                                  </h4>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : null}
                      </>
                    )}
                  </GuardNotAuthorized>
                  {(currentStatus === optionsStatus.Exchanged || currentStatus === optionsStatus.Refused) && (
                    <>
                      {!isCopy && (
                        <GuardNotAuthorized>
                          <div
                            onClick={async () => {
                              try {
                                setLoadingUpdate(true);
                                await getExportPdfByIDNewPage("pdf", [props.id], i18n.language === "en" ? "Exchanges" : "Echanges");
                              } catch {
                              } finally {
                                setLoadingUpdate(false);
                              }
                            }}
                            className="statusChangeButton pdf"
                            style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
                          >
                            <img src={print} alt="print" style={{ paddingLeft: "12px", cursor: "pointer" }} />
                            <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                              PDF
                            </h4>
                          </div>
                        </GuardNotAuthorized>
                      )}
                    </>
                  )}

                  {!isCopy && (
                    <div onClick={handleClick}>
                      <FontAwesomeIcon className="grayBackgr" icon={faBars} style={{ width: "50px", height: "50px" }} />
                    </div>
                  )}
                  {!isCopy && (
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      TransitionComponent={Fade}
                    >
                      <MenuItem
                        onClick={() => {
                          history.go(0);
                        }}
                        style={{ fontSize: "15px" }}
                      >
                        <img alt="" src={Reloadimg} style={{ width: "20px", marginRight: "10px" }}></img>
                        {t("ALL.MENU.RELOAD")}
                      </MenuItem>
                      {nameRolSelected === typeUserData.admin && (
                        <MenuItem
                          onClick={() => {
                            setOpenDeleteModal(true);
                            handleClose();
                          }}
                          style={{ fontSize: "15px" }}
                        >
                          <img alt="" src={deleteImg} style={{ width: "20px", marginRight: "10px" }}></img>
                          {t("ALL.MENU.DELETE")}
                        </MenuItem>
                      )}
                      {nameRolSelected === typeUserData.admin && (
                        <MenuItem
                          onClick={() => {
                            setIsCopy(true);
                            // setReference("");
                          }}
                          style={{ fontSize: "15px" }}
                        >
                          <img alt="" src={copyItemImg} style={{ width: "20px", marginRight: "10px" }}></img>
                          {t("ALL-BUTTON-COPY-ITEM")}
                        </MenuItem>
                      )}
                      {nameRolSelected !== typeUserData.viewOnly && (
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setOpenExport(true);
                          }}
                          style={{ fontSize: "15px" }}
                        >
                          <img alt="" src={exportImg} style={{ width: "20px", marginRight: "10px" }} />
                          {t("NAV-ALL-ITEM3")}
                        </MenuItem>
                      )}
                      <MenuItem onClick={copy} style={{ fontSize: "15px" }}>
                        <img alt="" src={copyImg} style={{ width: "20px", marginRight: "10px" }}></img>
                        {t("ALL.MENU.COPY-LINK")}
                      </MenuItem>
                      {!isCopy && matches && (
                        <>
                          {nameRolSelected === typeUserData.admin ? (
                            <>
                              {currentStatus === optionsStatus.Submitted && (
                                <>
                                  <MenuItem
                                    // onClick={() => updateToSpecificStatus(optionsStatus.Analysis)}
                                    disabled
                                  >
                                    <img alt="" src={orangeCircle} style={{ width: "20px", marginRight: "7px" }} />
                                    <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                      {t("EXCHANGES-ANALYZED-BUTTON")}
                                    </h4>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      setOpenConfirm(true);
                                      setStatusToUpdate(optionsStatus.Accepted);
                                      handleClose();
                                    }}
                                  >
                                    <img alt="" src={yellowCircle} style={{ width: "20px", marginRight: "7px" }} />
                                    <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                      {t("EXCHANGES-ACCEPT-BUTTON")}
                                    </h4>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      setOpenConfirm(true);
                                      setStatusToUpdate(optionsStatus.Refused);
                                      handleClose();
                                    }}
                                  >
                                    <img alt="" src={redCircle} style={{ width: "20px", marginRight: "7px" }} />
                                    <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                      {t("EXCHANGES-REFUSE-BUTTON")}
                                    </h4>
                                  </MenuItem>
                                </>
                              )}
                            </>
                          ) : nameRolSelected === typeUserData.afterSales ? (
                            <>
                              {currentStatus === optionsStatus.Submitted && (
                                <>
                                  <MenuItem>
                                    <img alt="" src={orangeCircle} style={{ width: "20px", marginRight: "7px" }} />
                                    <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                      {t("EXCHANGES-ANALYZED-BUTTON")}
                                    </h4>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      setOpenConfirm(true);
                                      setStatusToUpdate(optionsStatus.Accepted);
                                      handleClose();
                                    }}
                                  >
                                    <img alt="" src={yellowCircle} style={{ width: "20px", marginRight: "7px" }} />
                                    <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                      {t("EXCHANGES-ACCEPT-BUTTON")}
                                    </h4>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      setOpenConfirm(true);
                                      setStatusToUpdate(optionsStatus.Refused);
                                      handleClose();
                                    }}
                                  >
                                    <img alt="" src={redCircle} style={{ width: "20px", marginRight: "7px" }} />
                                    <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                      {t("EXCHANGES-REFUSE-BUTTON")}
                                    </h4>
                                  </MenuItem>
                                </>
                              )}
                            </>
                          ) : null}

                          {nameRolSelected === typeUserData.admin ? (
                            <div style={currentStatus === optionsStatus.Analysis ? {} : { display: "none" }}>
                              {currentStatus === optionsStatus.Analysis && (
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                  <MenuItem
                                    onClick={() => {
                                      setOpenConfirm(true);
                                      setStatusToUpdate(optionsStatus.Accepted);
                                      handleClose();
                                    }}
                                  >
                                    <img alt="" src={yellowCircle} style={{ width: "20px", marginRight: "7px" }} />
                                    <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                      {t("EXCHANGES-ACCEPT-BUTTON")}
                                    </h4>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      setOpenConfirm(true);
                                      setStatusToUpdate(optionsStatus.Refused);
                                      handleClose();
                                    }}
                                  >
                                    <img alt="" src={redCircle} style={{ width: "20px", marginRight: "7px" }} />
                                    <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                      {t("EXCHANGES-REFUSE-BUTTON")}
                                    </h4>
                                  </MenuItem>
                                </div>
                              )}
                            </div>
                          ) : nameRolSelected === typeUserData.afterSales ? (
                            <div style={currentStatus === optionsStatus.Analysis ? {} : { display: "none" }}>
                              {currentStatus === optionsStatus.Analysis && (
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                  <MenuItem
                                    onClick={() => {
                                      setOpenConfirm(true);
                                      setStatusToUpdate(optionsStatus.Accepted);
                                      handleClose();
                                    }}
                                  >
                                    <img alt="" src={yellowCircle} style={{ width: "20px", marginRight: "7px" }} />
                                    <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                      {t("EXCHANGES-ACCEPT-BUTTON")}
                                    </h4>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      setOpenConfirm(true);
                                      setStatusToUpdate(optionsStatus.Refused);
                                      handleClose();
                                    }}
                                  >
                                    <img alt="" src={redCircle} style={{ width: "20px", marginRight: "7px" }} />
                                    <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                      {t("EXCHANGES-REFUSE-BUTTON")}
                                    </h4>
                                  </MenuItem>
                                </div>
                              )}
                            </div>
                          ) : null}
                          {nameRolSelected !== typeUserData.afterSales && (
                            <div style={currentStatus === optionsStatus.Accepted ? {} : { display: "none" }}>
                              {currentStatus === optionsStatus.Accepted && (
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                  <MenuItem
                                    onClick={() => {
                                      setOpenConfirm(true);
                                      setStatusToUpdate(optionsStatus.Available);
                                      handleClose();
                                    }}
                                  >
                                    <img alt="" src={blueCircle} style={{ width: "20px", marginRight: "7px" }} />
                                    <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                      {t("EXCHANGES-AVAILABLE-BUTTON")}
                                    </h4>
                                  </MenuItem>
                                </div>
                              )}
                            </div>
                          )}

                          {nameRolSelected !== typeUserData.afterSales && (
                            <div style={currentStatus === optionsStatus.Available ? {} : { display: "none" }}>
                              {currentStatus === optionsStatus.Available && (
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                  <MenuItem
                                    onClick={() => {
                                      setOpenConfirm(true);
                                      setStatusToUpdate(optionsStatus.Exchanged);
                                      handleClose();
                                    }}
                                  >
                                    <img alt="" src={greenCircle} style={{ width: "20px", marginRight: "7px" }} />
                                    <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                      {t("EXCHANGES-EXCHANGE-BUTTON")}
                                    </h4>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      setOpenConfirm(true);
                                      setStatusToUpdate(optionsStatus.Refused);
                                      handleClose();
                                    }}
                                  >
                                    <img alt="" src={redCircle} style={{ width: "20px", marginRight: "7px" }} />
                                    <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                      {t("EXCHANGES-REFUSE-BUTTON")}
                                    </h4>
                                  </MenuItem>
                                </div>
                              )}
                            </div>
                          )}

                          {nameRolSelected === typeUserData.admin ? (
                            <div style={currentStatus === optionsStatus.Exchanged ? {} : { display: "none" }}>
                              {currentStatus === optionsStatus.Exchanged && (
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                  <MenuItem
                                    onClick={() => {
                                      setOpenConfirm(true);
                                      setStatusToUpdate(optionsStatus.Recycled);
                                      handleClose();
                                    }}
                                  >
                                    <img alt="" src={redCircle} style={{ width: "20px", marginRight: "7px" }} />
                                    <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                      {t("EXCHANGES-RECYCLE-BUTTON")}
                                    </h4>
                                  </MenuItem>
                                </div>
                              )}
                            </div>
                          ) : nameRolSelected === typeUserData.afterSales ? (
                            <div style={currentStatus === optionsStatus.Exchanged ? {} : { display: "none" }}>
                              {currentStatus === optionsStatus.Exchanged && (
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                  <MenuItem
                                    onClick={() => {
                                      setOpenConfirm(true);
                                      setStatusToUpdate(optionsStatus.Recycled);
                                      handleClose();
                                    }}
                                  >
                                    <img alt="" src={redCircle} style={{ width: "20px", marginRight: "7px" }} />
                                    <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
                                      {t("EXCHANGES-RECYCLE-BUTTON")}
                                    </h4>
                                  </MenuItem>
                                </div>
                              )}
                            </div>
                          ) : null}
                        </>
                      )}
                      {/* <MenuItem onClick={handleClose} style={{fontSize:'15px'}}>Logout</MenuItem> */}
                    </Menu>
                  )}

                  <Button
                    variant="outline-secondary"
                    onClick={() => {
                      if (document.referrer) {
                        history.go(-1);
                      } else {
                        history.push("/");
                      }
                    }}
                  >
                    {t("CREATE.ALL.BUTTON-CLOSE")}
                  </Button>
                </div>
              </div>
            </Card.Header>

            <div className="row">
              <div className="col-12 col-lg-6 my-4">
                <div>
                  <ExchangesIdentificationAndStatusTable
                    exchangesArray={exchangesArray}
                    warningAlertStatus={warningAlertStatus[0]}
                    number={number}
                    setNumber={(data: string) => {
                      setNumber(data);
                    }}
                    date={date}
                    setDate={(data: string) => {
                      setDate(data);
                    }}
                    createItemsRefusal={createItemsRefusal}
                    statusOptions={statusOptions}
                    refusalReasonlabel={refusalReasonLabel}
                    setRefusalReasonlabel={(data: string) => {
                      setRefusalReasonGivenLabel(data);
                    }}
                    selectedStatus={selectedStatus}
                    setSelectedStatus={(data: string) => {
                      setSelectedStatus(data);
                    }}
                    onClickCreate={() => setOpenCreateRefusalReason(true)}
                    onClickSearch={() => setOpenRefusalReasonTable(true)}
                  />
                </div>
                <div>
                  <div className="mx-2 my-4">
                    <Nav variant="tabs" defaultActiveKey="1">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="1"
                          style={{ color: "var(--color-main-text)" }}
                          onClick={() => {
                            setTableRender("Details");
                          }}
                        >
                          {t("ALL.GENERAL-EXCHANGE.TITLE-DETAILS")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="2"
                          style={{ color: "var(--color-main-text)" }}
                          onClick={() => {
                            setTableRender("PictureOne");
                            getPicture1(props.id);
                          }}
                        >
                          {t("CREATE.EXCHANGES.INPUT-PICTURE-ONE")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="3"
                          style={{ color: "var(--color-main-text)" }}
                          onClick={() => {
                            setTableRender("PictureTwo");
                            getPicture2(props.id);
                          }}
                        >
                          {t("CREATE.EXCHANGES.INPUT-PICTURE-TWO")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="4"
                          style={{ color: "var(--color-main-text)" }}
                          onClick={() => {
                            setTableRender("PictureThree");
                            getPicture3(props.id);
                          }}
                        >
                          {t("CREATE.EXCHANGES.INPUT-PICTURE-THREE")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="5"
                          style={{ color: "var(--color-main-text)" }}
                          onClick={() => {
                            setTableRender("PictureFour");
                            getPicture4(props.id);
                          }}
                        >
                          {t("CREATE.EXCHANGES.INPUT-PICTURE-FOUR")}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    {tableRender === "Details" && RenderAddressTable()}
                    {pictureOne()}
                    {pictureTwo()}
                    {pictureThree()}
                    {pictureFour()}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 my-4">
                <ExchangesCustomerTable
                  warningAlertStatus={warningAlertStatus[1]}
                  customerUsername={customerUserName}
                  setCustomerUsername={(data: string) => {
                    setCustomerUserName(data);
                  }}
                  customerFirstname={customerFirstName}
                  setCustomerFirstname={(data: string) => {
                    setCustomerFirstName(data);
                  }}
                  customerLastname={customerLastName}
                  setCustomerLastname={(data: string) => {
                    setCustomerLastName(data);
                  }}
                  customerEmail={customerEmail}
                  setCustomerEmail={(data: string) => {
                    setCustomerEmail(data);
                  }}
                  customerMobilePhone={customerMobilePhoneNumber}
                  setCustomerMobilePhone={(data: string) => {
                    setCustomerMobilePhoneNumber(data);
                  }}
                  openLink={linkCustomer}
                  Create={createItems}
                  selectedStatus={selectedStatus}
                  onClickCreate={() => setOpenCreateCustomer(true)}
                  onClickSearch={() => setOpenCustomerTable(true)}
                  customerZipCode={customerZipCode}
                  setCustomerZipCode={(data: string) => setCustomerZipCode(data)}
                  customerCity={customerCity}
                  setCustomerCity={(data: string) => setCustomerCity(data)}
                  customerCountry={customerCountry}
                  setCustomerCountry={(data: string) => setCustomerCountry(data)}
                  customerLanguage={customerLanguage}
                  setCustomerLanguage={(data: string) => setCustomerLanguage(data)}
                />
                <ExchangesDistributorTable
                  warningAlertStatus={warningAlertStatus[1]}
                  distributorCode={distributorCode}
                  setDistributorCode={(data: string) => {
                    setDistributorCode(data);
                  }}
                  distributorName={distributorName}
                  setDistributorName={(data: string) => {
                    setDistributorName(data);
                  }}
                  distributorGroupCode={distributorGroupCode}
                  setDistributorGroupCode={(data: string) => {
                    setDistributorGroupCode(data);
                  }}
                  distributorGroupName={distributorGroupName}
                  setDistributorGroupName={(data: string) => {
                    setDistributorGroupName(data);
                  }}
                  distributorClientAccountNumber={distributorClientAccountNumer}
                  setDistributorClientAccountNumber={(data: string) => {
                    setDistributorClientAccountNumer(data);
                  }}
                  distributorPhone={distributorPhone}
                  setDistributorPhone={(data: string) => {
                    setDistributorPhone(data);
                  }}
                  distributorEmail={distributorEmail}
                  setDistributorEmail={(data: string) => {
                    setDistributorEmail(data);
                  }}
                  openLink={linkDistributor}
                  Create={createItems}
                  CreateSubmitted={createItemsDistributor}
                  selectedStatus={selectedStatus}
                  onClickCreate={() => setOpenCreateDistributor(true)}
                  onClickSearch={() => setOpenDistributorTable(true)}
                  inputResponsibleEmail={distributorResponsiveEmail}
                  setInputResponsibleEmail={(data: string) => setDistributorResponsiveEmail(data)}
                  deliveryAccountNumber={distributorDeliveryAccountNumber}
                  setDeliveryAccountNumber={(data: string) => setDistributorDeliveryAccountNumber(data)}
                />

                <ExchangesProductTable
                  warningAlertStatus={warningAlertStatus[1]}
                  productReference={productReference}
                  setProductReference={(data: string) => {
                    setProductReference(data);
                  }}
                  productName={productName}
                  setProductName={(data: string) => {
                    setProductName(data);
                  }}
                  brandCode={productBrandCode}
                  setBrandCode={(data: string) => {
                    setProductBrandCode(data);
                  }}
                  brandName={productBrandName}
                  setBrandName={(data: string) => {
                    setProductBrandName(data);
                  }}
                  productPicture={productPicture}
                  setProductPicture={(data: string) => {
                    setProductPicture(data);
                  }}
                  selectedType={productType}
                  setSelectedType={(item: string) => {
                    setProductType(item);
                  }}
                  listTypeOptions={listTypeOptions}
                  currentUnitPrice={productUnitPrice}
                  setCurrentUnitPrice={(data: string) => {
                    setProductUnitPrice(data);
                  }}
                  openLink={linkProduct}
                  Create={createItems}
                  selectedStatus={selectedStatus}
                  onClickCreate={() => setOpenCreateProduct(true)}
                  onClickSearch={() => setOpenProductTable(true)}
                  loadingPicture={picture.LoadingPicture}
                  picture={picture.base64Picture}
                  extension={picture.extension}
                  productPictureFromApi={productPictureFromApi}
                />
              </div>
            </div>
          </Card>
          {!isCopy && (
            <Tab.Container id="left-tabs-example" defaultActiveKey="second">
              <div className="row my-4">
                <div className="tabs-info col-12 col-lg-2">
                  <Nav variant="pills" className="flex-column">
                    {/* <Nav.Item>
                    <Nav.Link eventKey="first">
                      <FontAwesomeIcon icon={faExchangeAlt} style={{ width: "20px", marginRight: "8px" }} />
                      Exchanges (hist.){" "}
                      <span className="numberUsers-2">
                        <b>3</b>
                      </span>
                    </Nav.Link>
                  </Nav.Item> */}
                    {nameRolSelected === typeUserData.admin && (
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          <img
                            alt=""
                            src={changeLogIcon}
                            style={{
                              width: "20px",
                              verticalAlign: "middle",
                              marginRight: "8px",
                            }}
                          ></img>
                          {t("HOME.MENU.CHANGE-LOG")}
                          <span className="numberUsers-2">
                            <b>{changeLogLength}</b>
                          </span>
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  </Nav>
                </div>
                <div className="col-12 col-lg-10">
                  <Tab.Content>
                    {/* <Tab.Pane eventKey="first">
                    <Card>
                      <ExchangesHistTable mainlink={true} exchangesArray={exchangesArray?.result.status} />
                    </Card>
                  </Tab.Pane> */}

                    {nameRolSelected === typeUserData.admin && (
                      <Tab.Pane eventKey="second">
                        <Card>
                          <ChangeLog mainlink={true} resourceId={props.id} />
                        </Card>
                      </Tab.Pane>
                    )}
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          )}
          {renderComments()}
        </div>
      ) : (
        <LoadingSkeletonInfo />
      )}
      <FullLoading open={loadingUpdate} />
      <ExportInfo openExport={openExport} setOpenExport={setOpenExport} exportFunction={exportItem} id={props.id} />
      <ModalConfirm
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        onClick={() => deleteExchange(props.id)}
        onClickCancel={() => setOpenDeleteModal(false)}
      />
      {renderDistributorTable()}
      {renderCreatDistributor()}
      {renderCustomerTable()}
      {renderProductsTable()}
      {renderCreateProduct()}
      {RenderCreateCustomer()}
      {renderReasonExchangeTable()}
      {renderCreatReasonExchange()}
      {renderCreatRefusalReason()}
      {renderDistrRefualReason()}
      {showModal()}
    </div>
  );
}
