import React, { useContext, useEffect, useState } from "react";
import TransferModal from "../../organisms/TransferModal/TransferModal";
import { useTranslation } from "react-i18next";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { LocalStorageKeys, getInLocalStorageArray } from "../../../helpers/local-storage-manager";

interface IProps {
  open: boolean;
  setOpen: any;
}

export default function ProductTableTransfer(props: IProps) {
  const { productTableTransferData, setProductTableTransferData } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const { open, setOpen } = props;
  const tableProductInitial: string[] = [
    t("SORT.PRODUCT.INPUT-CODE-BRAND"),
    t("SORT.PRODUCT.INPUT-NAME-BRAND"),
    t("SORT.PRODUCT.INPUT-REFERENCE"),
    t("SORT.PRODUCT.INPUT-NAME"),
    t("SORT.PRODUCT.INPUT-TYPE"),
    t("ALL.GENERAL.PRODUCT.TITLE-PICTURE"),
    t("SORT.PRODUCT.INPUT-UNIT-PRICE"),
    t("SORT.PRODUCT.INPUT-AVAILABLE"),
    t("SORT.PRODUCT.INPUT-WARRANTY"),
  ];

  const [dataRight, setDataRight] = useState<string[]>([]);

  useEffect(() => {
    const initialArray = getInLocalStorageArray(LocalStorageKeys.PRODUCT_TABLE);
    const initialArrayRight = getInLocalStorageArray(LocalStorageKeys.PRODUCT_TABLE_R);
    setProductTableTransferData(initialArray?.length > 0 ? initialArray : tableProductInitial);
    setDataRight(initialArrayRight?.length > 0 ? initialArrayRight : []);
  }, []);
  return (
    <TransferModal
      keyValueRight={`${LocalStorageKeys.PRODUCT_TABLE_R}`}
      keyValue={`${LocalStorageKeys.PRODUCT_TABLE}`}
      itemsLeft={productTableTransferData}
      itemsRight={dataRight}
      setItemsLeft={setProductTableTransferData}
      setItemsRight={setDataRight}
      openConfirm={open}
      setOpenConfirm={setOpen}
    />
  );
}
