import React, { useState } from "react";
import ModalExport from "../../../atoms/ModalExport";

export default function ExportInfo({
  openExport,
  setOpenExport,
  exportFunction,
  id
}: {
  openExport: boolean;
  setOpenExport: React.Dispatch<React.SetStateAction<boolean>>;
  exportFunction: Function;
  id:string
}) {
  // Checkbox
  const [checkNeedToExport, setCheckNeedToExport] = useState<boolean[]>([false, true, false]);
  const [checkFormatToExport, setCheckFormatToExport] = useState<boolean[]>([true, false, false, false]);
  const [checkedType, setCheckedType] = useState<string>("csv");
  return (
    <div>
      <ModalExport
        openExport={openExport}
        setOpenExport={setOpenExport}
        checkNeedToExport={checkNeedToExport}
        setCheckNeedToExport={setCheckNeedToExport}
        checkFormatToExport={checkFormatToExport}
        setCheckFormatToExport={setCheckFormatToExport}
        checkedType={checkedType}
        setCheckedType={setCheckedType}
        onClick={() => {
          setOpenExport(false);
          exportFunction(checkedType,[id]);
        }}
      />
    </div>
  );
}
