import React, { useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSearch, faWrench } from "@fortawesome/free-solid-svg-icons";
import "../../constants/global.css";
import { Breadcrumb, Card, Modal } from "react-bootstrap";
import TableProducts from "../organisms/TableProducts";
import "./styles/Products.css";
import { Link, useHistory } from "react-router-dom";
import { Menu, MenuItem, useMediaQuery } from "@mui/material";
import Fade from "@mui/material/Fade";
import Reloadimg from "../../images/reload.svg";
import deleteImg from "../../images/delete.svg";
import copyImg from "../../images/copy.svg";
import { ApplicationContext } from "../../context/ApplicationContext";
import ModalExport from "../atoms/ModalExport";
import Stadistics from "../../images/stadistics.svg";
import ProductSearch from "../organisms/Search/ProductSearch";
import { useTranslation } from "react-i18next";
import exportImg from "../../images/export.svg";
import preferencesImg from "../../images/preferences.svg";
import bulkimg from "../../images/bulk.svg";

//Redux
import { useSelector } from "react-redux";
import { IRootState } from "../../store/redux";
import { useProductsGet } from "../../hooks/Products/useProductsGet";
import { typeUserData } from "../../constants/userTypes";
import ProductTableTransfer from "../templates/TableTranfers/ProductTableTransfer";
import { fromWaarantyTranslatedToKey } from "../../utilities/usetBussinessTransalte";
import { useFilterContext } from "../../context";
import { listTypeOptionsKeys } from "./ProductsInfo/ProductsInfo";
import GuardNotAuthorized from "../atoms/ItemPermission/GuardNotAuthorized";

export default function Products(props: any) {
  const { productLength, productsListID, productsAllSelected } = useContext(ApplicationContext);
  const {
    inputBrandCodeSearch,
    inputBrandNameSearch,
    inputReferenceSearch,
    inputOldReferenceSearch,
    inputTypeSearch,
    inputDefaultSearch,
    inputUnitPriceSearch,
    inputAvailableSearch,
    inputWarrantySearch,
    inputEanSearch,
    inputNameSearchProduct,
    showLateralContainerSearch,
    setShowLateralContainerSearch,
  } = useFilterContext();
  //Translations
  const { t } = useTranslation();
  const listSort: string[] = [
    t("SORT.PRODUCT.INPUT-CODE-BRAND"),
    t("SORT.PRODUCT.INPUT-NAME-BRAND"),
    t("SORT.PRODUCT.INPUT-REFERENCE"),

    t("SORT.PRODUCT.INPUT-NAME"),
    t("SORT.PRODUCT.INPUT-TYPE"),
    t("SORT.PRODUCT.INPUT-UNIT-PRICE"),
    t("SORT.PRODUCT.INPUT-AVAILABLE"),
    t("SORT.PRODUCT.INPUT-WARRANTY"),
  ];

  const matches = useMediaQuery("(max-width:500px)");

  //Redux
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected } = profile;

  let history = useHistory();

  const [showSearchTable, setShowSearchTable] = useState(false);
  const [searchState, setSearchState] = useState<boolean>(false);
  const [estado, setEstado] = useState(1);
  const [openTransferModal, setOpenTransferModal] = useState(false);

  //Filter Search
  const [dropDownWhySort, setDropDownWhySort] = useState<string>(t("SORT.PRODUCT.INPUT-CODE-BRAND"));

  const [searchWindowState, SetSearchWindowState] = useState("filterList");

  const [input, setInput] = useState<string>("");
  //Alerts
  //menu fade
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function copy() {
    const url = document.createElement("input");
    url.value = window.location.href;
    navigator.clipboard.writeText(url.value);
    setAnchorEl(null);
  }

  //! ------------------------------API CALLS--------------------------------//

  const {
    productsArray,
    loading,
    exportProduct,
    deleteManyProduct,
    lastPage,
    handleChange,
    currentPage,
    limit,
    onSearch,
    typeSort,
    incrementCount,
  } = useProductsGet(
    inputBrandCodeSearch,
    inputBrandNameSearch,
    inputUnitPriceSearch,
    inputEanSearch,
    inputOldReferenceSearch,
    inputReferenceSearch,
    inputNameSearchProduct,
    inputTypeSearch === `${t("TABLE.PRODUCTS-STATS.TITLE-OPENINGWRENCH")}`
      ? listTypeOptionsKeys.fixed
      : inputTypeSearch === `${t("TABLE.PRODUCTS-STATS.TITLE-PAWLS")}`
      ? listTypeOptionsKeys.pawls
      : inputTypeSearch === `${t("TABLE.PRODUCTS-STATS.TITLE-SOCKETS")}`
      ? listTypeOptionsKeys.socket
      : inputTypeSearch === `${t("TABLE.PRODUCTS-STATS.TITLE-SCREWDRIVERS")}`
      ? listTypeOptionsKeys.screwDrivers
      : inputTypeSearch === `${t("TABLE.PRODUCTS-STATS.TITLE-MALEWRENCHS")}`
      ? listTypeOptionsKeys.mWrench
      : inputTypeSearch === `${t("TABLE.PRODUCTS-STATS.TITLE-PLIERS")}`
      ? listTypeOptionsKeys.pliers
      : inputTypeSearch === `${t("TABLE.PRODUCTS-STATS.TITLE-TRACING")}`
      ? listTypeOptionsKeys.measuring
      : inputTypeSearch === `${t("TABLE.PRODUCTS-STATS.TITLE-HAMMERS")}`
      ? listTypeOptionsKeys.hammers
      : inputTypeSearch === `${t("TABLE.PRODUCTS-STATS.TITLE-SAWING")}`
      ? listTypeOptionsKeys.sawing
      : inputTypeSearch === `${t("TABLE.PRODUCTS-STATS.TITLE-EXTRACTION")}`
      ? listTypeOptionsKeys.extraction
      : inputTypeSearch === `${t("TABLE.PRODUCTS-STATS.TITLE-OTHER")}`
      ? listTypeOptionsKeys.other
      : "",
    fromWaarantyTranslatedToKey(inputWarrantySearch),
    inputAvailableSearch ? (inputAvailableSearch === t("TABLE.DISTRIBUTORS-TITLE-YES") ? "true" : "false") : "",
    inputDefaultSearch ? (inputDefaultSearch === t("TABLE.DISTRIBUTORS-TITLE-YES") ? "true" : "false") : "",
    props.brandID
  );

  //! --------------------------END API CALLS--------------------------------//
  const exportFunction = () => {
    try {
      exportProduct(checkedType, productsListID, checkNeedToExport);
    } finally {
      setOpenConfirm(false);
    }
  };

  const deleteFunction = () => {
    try {
      deleteManyProduct(productsListID);
    } finally {
      setOpenConfirm(false);
    }
  };

  const renderLateralContainerSearch = () => {
    return (
      <ProductSearch
        searchWindowState={searchWindowState}
        SetSearchWindowState={SetSearchWindowState}
        listSort={listSort}
        dropDownWhySort={dropDownWhySort}
        setDropDownWhySort={setDropDownWhySort}
        handleSearch={() => onSearch()}
      />
    );
  };

  const renderTabla1 = () => {
    return (
      <div>
        <TableProducts
          searchState={searchState}
          rowsPerPage={limit}
          onClick={() => setEstado(2)}
          UsePlanText="true"
          dropDownWhySort={dropDownWhySort}
          Create={props.Create}
          showSearchTable={showSearchTable}
          productsArray={productsArray}
          loading={loading}
          lastPage={lastPage}
          handleChange={handleChange}
          currentPage={currentPage}
          incrementCount={incrementCount}
          typeSort={typeSort}
          inputToSort={dropDownWhySort}
          handleSearch={() => onSearch()}
          setDropDownWhySort={setDropDownWhySort}
        />
        <div className="row sectionBoxBottom">
          <div className="col-10 col-lg-11">
            <h4 className="h4-stl" style={{ margin: "15px 30px" }}></h4>
          </div>
        </div>
      </div>
    );
  };

  //Modal
  const [openExport, setOpenExport] = useState(false);
  // Checkbox
  const [checkNeedToExport, setCheckNeedToExport] = useState<boolean[]>([false, true, false]);
  const [checkFormatToExport, setCheckFormatToExport] = useState<boolean[]>([true, false, false, false]);
  const [checkedType, setCheckedType] = useState<string>("csv");

  const showModalExport = () => {
    return (
      <ModalExport
        openExport={openExport}
        setOpenExport={setOpenExport}
        checkNeedToExport={checkNeedToExport}
        setCheckNeedToExport={setCheckNeedToExport}
        checkFormatToExport={checkFormatToExport}
        setCheckFormatToExport={setCheckFormatToExport}
        checkedType={checkedType}
        setCheckedType={setCheckedType}
        onClick={() => {
          setOpenExport(false);
          exportFunction();
        }}
      />
    );
  };

  const [openConfirm, setOpenConfirm] = useState(false);
  const showModal = () => {
    return (
      <>
        <Modal
          show={openConfirm}
          onHide={() => {
            setOpenConfirm(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("INFO.USER.RESET-CONFIRM")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-lg-12 p-4">
                <h4 className="h4-stl">{t("ALL-INFO.DELETE-CONFIRM")}</h4>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                deleteFunction();
              }}
              variant="success"
            >
              {t("INFO.USER.RESET-BUTTON-CONFIRM")}
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setOpenConfirm(false);
              }}
            >
              {t("INFO.USER.RESET-BUTTON-CANCEL")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  return (
    <div>
      {props.brand ? (
        ""
      ) : (
        <Breadcrumb>
          <Breadcrumb.Item href="#">{t("TABLE.PRODUCT.TITLE.HEADER-LINK")}</Breadcrumb.Item>
        </Breadcrumb>
      )}

      <Card>
        <Card.Header>
          <div className="flex-warranty flex-between">
            <div className="container-warranty flex-start">
              <FontAwesomeIcon
                icon={faWrench}
                style={{
                  width: "50px",
                  height: "50px",
                }}
              />
              <div>
                <h4 className="h4-stl" style={{ margin: "15px 5px" }}>
                  <strong>{props.brand ? `${t("INFO.BRAND.PRODUCT-TABLE")}` : `${t("TABLE.PRODUCT.TITLE-HEADER")}`}</strong>
                </h4>
                <h5 className="h5-stl" style={{ margin: "15px 5px" }}>
                  {t("TABLE.PRODUCT.HEADER-TOTAL")} {`${productLength}`}
                </h5>
              </div>
            </div>
            <div className="container-warranty flex-end wrap-warranty">
              {nameRolSelected === typeUserData.admin && (
                <Link to="/create-product">
                  <Button variant="primary">{t("LIST.ALL.BUTTON-CREATE")}</Button>
                </Link>
              )}
              <div
                style={{
                  cursor: "pointer",
                }}
              >
                {!props.Create ? (
                  <FontAwesomeIcon
                    className="grayBackgr iconbar_size"
                    icon={faSearch}
                    onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="grayBackgr iconbar_size"
                    icon={faSearch}
                    onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                  />
                )}
              </div>
              {/* <Link
                to="/product-stats"
                style={{
                  display: "flex",
                  textDecoration: "none",
                  color: "var(--color-main-text)",
                }}
              >
                <div className="grayBackgr" style={{ display: "flex" }}>
                  <div>
                    <img alt="" src={Stadistics} style={{ width: "20px", height: "20px" }}></img>
                  </div>
                  <h4 className="h4-stl" style={{ margin: "3px 10px" }}>
                    {t("ALL-STATS-BUTTON")}
                  </h4>
                </div>
              </Link> */}

              {!matches && (
                <GuardNotAuthorized>
                  <Link
                    to="/product-stats"
                    style={{
                      display: "flex",
                      textDecoration: "none",
                      color: "var(--color-main-text)",
                    }}
                  >
                    <div className="grayBackgr" style={{ display: "flex" }}>
                      <div>
                        <img alt="" src={Stadistics} style={{ width: "20px", height: "20px" }}></img>
                      </div>
                      <h4 className="h4-stl" style={{ margin: "3px 10px" }}>
                        {t("ALL-STATS-BUTTON")}
                      </h4>
                    </div>
                  </Link>
                </GuardNotAuthorized>
              )}
              <div onClick={handleClick}>
                <FontAwesomeIcon className="grayBackgr" icon={faBars} style={{ width: "50px", height: "50px" }} />
              </div>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <MenuItem
                  onClick={() => {
                    history.go(0);
                  }}
                  style={{ fontSize: "15px" }}
                >
                  <img alt="" src={Reloadimg} style={{ width: "20px", marginRight: "10px" }}></img>
                  {t("ALL.MENU.RELOAD")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setOpenTransferModal(true);
                  }}
                  style={{ fontSize: "15px" }}
                >
                  <img alt="" src={preferencesImg} style={{ width: "20px", marginRight: "10px" }}></img>
                  {t("ALL-BUTTON-PREFERENCES")}
                </MenuItem>
                {nameRolSelected === typeUserData.admin && (
                  <div>
                    {productsListID.length || productsAllSelected ? (
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          setOpenConfirm(true);
                        }}
                        style={{ fontSize: "15px" }}
                      >
                        <img alt="" src={deleteImg} style={{ width: "20px", marginRight: "10px" }}></img>
                        {t("ALL.MENU.DELETE")}
                        <span
                          style={{
                            background: "var( --color-accepted)",
                            marginLeft: "6px",
                            color: "var(--color-main-text)",
                            borderRadius: "20px",
                            padding: "4px 8px",
                            fontSize: "12px",
                          }}
                        >
                          <b>{productsAllSelected ? productLength : productsListID?.length}</b>
                        </span>
                      </MenuItem>
                    ) : (
                      <MenuItem disabled style={{ fontSize: "15px" }}>
                        <img alt="" src={deleteImg} style={{ width: "20px", marginRight: "10px" }}></img>
                        {t("ALL.MENU.DELETE")}
                      </MenuItem>
                    )}
                  </div>
                )}
                <GuardNotAuthorized>
                  <div>
                    {productsListID.length ? (
                      <MenuItem
                        onClick={() => {
                          history.replace("/product-bulk");
                          handleClose();
                        }}
                        style={{ fontSize: "15px" }}
                      >
                        <img
                          alt=""
                          src={bulkimg}
                          style={{
                            width: "20px",
                            marginRight: "10px",
                          }}
                        ></img>
                        {t("TABLE.HEADER-OPTIONS-LIST-BULK")}
                        <span
                          style={{
                            background: "var( --color-accepted)",
                            marginLeft: "6px",
                            color: "var(--color-main-text)",
                            borderRadius: "20px",
                            padding: "4px 8px",

                            fontSize: "12px",
                          }}
                        >
                          <b>{productsListID?.length}</b>
                        </span>
                      </MenuItem>
                    ) : (
                      <MenuItem disabled style={{ fontSize: "15px" }}>
                        <img
                          alt=""
                          src={bulkimg}
                          style={{
                            width: "20px",
                            marginRight: "10px",
                          }}
                        ></img>
                        {t("TABLE.HEADER-OPTIONS-LIST-BULK")}
                      </MenuItem>
                    )}
                  </div>
                </GuardNotAuthorized>
                <GuardNotAuthorized>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setOpenExport(true);
                    }}
                    style={{ fontSize: "15px" }}
                  >
                    <img alt="" src={exportImg} style={{ width: "20px", marginRight: "10px" }} />
                    {t("NAV-ALL-ITEM3")}
                    <span
                      style={{
                        background: "var( --color-accepted)",
                        marginLeft: "6px",
                        color: "var(--color-main-text)",
                        borderRadius: "20px",
                        padding: "4px 8px",
                        fontSize: "12px",
                      }}
                    >
                      <b>{productsAllSelected ? productLength : productsListID?.length}</b>
                    </span>
                  </MenuItem>
                </GuardNotAuthorized>
                <MenuItem onClick={copy} style={{ fontSize: "15px" }}>
                  <img alt="" src={copyImg} style={{ width: "20px", marginRight: "10px" }}></img>
                  {t("ALL.MENU.COPY-LINK")}
                </MenuItem>
                {/* <MenuItem onClick={handleClose} style={{fontSize:'15px'}}>Logout</MenuItem> */}
              </Menu>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div>{estado === 1 && renderTabla1()}</div>
        </Card.Body>
      </Card>
      <ProductTableTransfer open={openTransferModal} setOpen={setOpenTransferModal} />
      {showModal()}
      {showModalExport()}
      {renderLateralContainerSearch()}
    </div>
  );
}
