import { axios as http } from "../../frameworks_and_drivers/Axios";
import { AxiosRequestConfig } from "axios";
import { downloadFileFromResponse } from "./helps";
import { BACKEND_URL } from "../../config/settings.json";
import HelpService from "../HelpService";
import { loadAbort } from "../../helpers/loadAbort";
import { IDistributorSetListAPI, IDistributorSetListByID } from "../interfaces/interfaz_distributor_Set";

export const getAllDistributorSet = (props?: any) => {
  const controller = loadAbort();
  const { query } = props;
  const URL = `${BACKEND_URL}/distributor-set/get-all?` + HelpService.getQueryString(query);
  return {
    call: http.get<IDistributorSetListAPI>(URL, { signal: controller.signal }),
    controller,
  };
};

export const getDistributorSetById = async (objectId: string) => {
  const URL = `${BACKEND_URL}/distributor-set/${objectId}/get-one`;
  const response = await http.get<IDistributorSetListByID>(URL);
  return response;
};

export const createDistributorSet = async (
  name: string,
  origin: string,
  description: string,
  group_id: string,
  add_members_of_set: string[]
) => {
  const body = {
    name,
    origin,
    description,
    group_id,
    add_members_of_set,
  };
  const URL = `${BACKEND_URL}/distributor-set/create-one`;
  const response = await http.post(URL, body);

  return response;
};

export const updateDistributorSet = async (
  id: string,
  name: string,
  origin: string,
  description: string,
  group_id: string,
  add_members_of_set: string[],
  remove_members_of_set: string[]
) => {
  const body = {
    name,
    origin,
    description,
    group_id,
    add_members_of_set,
    remove_members_of_set,
  };
  const URL = `${BACKEND_URL}/distributor-set/${id}/update-one`;
  const response = await http.put(URL, body);
  return response;
};

export const deleteManyDistributorsSet = async (ids: string[]) => {
  const URL = `${BACKEND_URL}/distributor-set/delete-many`;
  const body = {
    items: ids,
  };
  const response = await http.put(URL, body);
  return response;
};

export const getExportDistributorsSet = async (type: string) => {
  const URL = `${BACKEND_URL}/distributor-set/generate-report`;
  const opts: AxiosRequestConfig = {
    params: {
      export_type: type,
    },
    responseType: "blob",
  };
  const response = await http.get(URL, opts);
  const responseDownload = downloadFileFromResponse(response, type, "Distributors set");
  return responseDownload;
};

export const getExportDistributorSetByGroup = async (type: string, groupId: string) => {
  const URL = `${BACKEND_URL}/distributor-set/generate-report?group=${groupId}`;
  const opts: AxiosRequestConfig = {
    params: {
      export_type: type,
    },
    responseType: "blob",
  };
  const response = await http.get(URL, opts);
  const responseDownload = downloadFileFromResponse(response, type, "Distributors set");
  return responseDownload;
};

export const getExportDistributorSetByDistributor = async (type: string, distributorId: string) => {
  const URL = `${BACKEND_URL}/distributor-set/create-report?distributor_id=${distributorId}`;
  const body = {
    file_type: type,
  };
  const opts: AxiosRequestConfig = {
    responseType: "blob",
  };
  const response = await http.post(URL, body, opts);
  const responseDownload = downloadFileFromResponse(response, type, "Distributors set");
  return responseDownload;
};

export const getExportDistributorSetByID = async (type: string, ids: string[], name: string, query?: any) => {
  const URL = `${BACKEND_URL}/distributor-set/create-report?` + HelpService.getQueryString(query);
  const body = {
    file_type: type,
    search: ids,
  };
  const opts: AxiosRequestConfig = {
    responseType: "blob",
  };
  const response = await http.post(URL, body, opts);
  const responseDownload = downloadFileFromResponse(response, type, name);
  return responseDownload;
};

export const updateInBulkDistributorsSet = async (bodyDistributor: any) => {
  const URL = `${BACKEND_URL}/distributor-set/bulk-update`;
  const body = {
    group: bodyDistributor.groupId,
    name: bodyDistributor.name,
    description: bodyDistributor.description,
    search: bodyDistributor.search,
  };
  const response = await http.put(URL, body);
  return response;
};
