import { t } from "i18next";

export const fromInfoToTranslatedBussiness = (country: string) => {
  if (country === "Industry") return `${t("OPTIONS-PROFESSION-1")}`;
  if (country === "Mechanic") return `${t("OPTIONS-PROFESSION-2")}`;
  if (country === "Exterior renovation") return `${t("OPTIONS-PROFESSION-3")}`;
  if (country === "Interior renovation") return `${t("OPTIONS-PROFESSION-4")}`;
  if (country === "Coachbuilder") return `${t("OPTIONS-PROFESSION-5")}`;
  if (country === "Carpenter") return `${t("OPTIONS-PROFESSION-6")}`;
  if (country === "Coppersmith") return `${t("OPTIONS-PROFESSION-7")}`;
  if (country === "Roofer") return `${t("OPTIONS-PROFESSION-8")}`;
  if (country === "Electrician") return `${t("OPTIONS-PROFESSION-9")}`;
  if (country === "Ironworker") return `${t("OPTIONS-PROFESSION-10")}`;
  if (country === "Builder") return `${t("OPTIONS-PROFESSION-11")}`;
  if (country === "Wood shaper") return `${t("OPTIONS-PROFESSION-12")}`;
  if (country === "Toolmaker") return `${t("OPTIONS-PROFESSION-13")}`;
  if (country === "Building worker") return `${t("OPTIONS-PROFESSION-14")}`;
  if (country === "House painter") return `${t("OPTIONS-PROFESSION-15")}`;
  if (country === "Plumer") return `${t("OPTIONS-PROFESSION-16")}`;
  if (country === "Locksmith") return `${t("OPTIONS-PROFESSION-17")}`;
  if (country === "Welder") return `${t("OPTIONS-PROFESSION-18")}`;
  if (country === "Stonerutter") return `${t("OPTIONS-PROFESSION-19")}`;
  if (country === "Electrician technician") return `${t("OPTIONS-PROFESSION-20")}`;
  if (country === "Lathe milling") return `${t("OPTIONS-PROFESSION-21")}`;
  if (country === "Other craftman") return `${t("OPTIONS-PROFESSION-22")}`;
  if (country === "Others") return `${t("OPTIONS-PROFESSION-23")}`;
  return "";
};

export const fromBusinessTranslatedToKey = (country: string) => {
  if (country === `${t("OPTIONS-PROFESSION-1")}`) return "Industry";
  if (country === `${t("OPTIONS-PROFESSION-2")}`) return "Mechanic";
  if (country === `${t("OPTIONS-PROFESSION-3")}`) return "Exterior renovation";
  if (country === `${t("OPTIONS-PROFESSION-4")}`) return "Interior renovation";
  if (country === `${t("OPTIONS-PROFESSION-5")}`) return "Coachbuilder";
  if (country === `${t("OPTIONS-PROFESSION-6")}`) return "Carpenter";
  if (country === `${t("OPTIONS-PROFESSION-7")}`) return "Coppersmith";
  if (country === `${t("OPTIONS-PROFESSION-8")}`) return "Roofer";
  if (country === `${t("OPTIONS-PROFESSION-9")}`) return "Electrician";
  if (country === `${t("OPTIONS-PROFESSION-10")}`) return "Ironworker";
  if (country === `${t("OPTIONS-PROFESSION-11")}`) return "Builder";
  if (country === `${t("OPTIONS-PROFESSION-12")}`) return "Wood shaper";
  if (country === `${t("OPTIONS-PROFESSION-13")}`) return "Toolmaker";
  if (country === `${t("OPTIONS-PROFESSION-14")}`) return "Building worker";
  if (country === `${t("OPTIONS-PROFESSION-15")}`) return "House painter";
  if (country === `${t("OPTIONS-PROFESSION-16")}`) return "Plumer";
  if (country === `${t("OPTIONS-PROFESSION-17")}`) return "Locksmith";
  if (country === `${t("OPTIONS-PROFESSION-18")}`) return "Welder";
  if (country === `${t("OPTIONS-PROFESSION-19")}`) return "Stonerutter";
  if (country === `${t("OPTIONS-PROFESSION-20")}`) return "Electrician technician";
  if (country === `${t("OPTIONS-PROFESSION-21")}`) return "Lathe milling";
  if (country === `${t("OPTIONS-PROFESSION-22")}`) return "Other craftman";
  if (country === `${t("OPTIONS-PROFESSION-23")}`) return "Others";
  return "";
};

export const fromInfoToTranslatedWarranty = (warranty: string) => {
  if (warranty.toLocaleLowerCase() === "unlimited") return `${t("TYPE-WARRANTY-1")}`;
  if (warranty === "2 years") return `${t("TYPE-WARRANTY-2")}`;
  if (warranty === "10 years") return `${t("TYPE-WARRANTY-3")}`;
  return "";
};

export const fromInfoToTranslatedWarrantyTable = (warranty: string) => {
  if (warranty.toLocaleLowerCase() === "unlimited") return `${t("TYPE-WARRANTY-1")}`;
  if (warranty.toLocaleLowerCase() === "2 years") return `${t("TYPE-WARRANTY-2")}`;
  if (warranty.toLocaleLowerCase() === "10 years") return `${t("TYPE-WARRANTY-3")}`;
  return "";
};

export const fromWaarantyTranslatedToKey = (warranty: string) => {
  if (warranty === `${t("TYPE-WARRANTY-1")}`) return "Unlimited";
  if (warranty === `${t("TYPE-WARRANTY-2")}`) return "2 years";
  if (warranty === `${t("TYPE-WARRANTY-3")}`) return "10 years";
  return "";
};

export const fromProfessionTypeTranslatedToKey = (warranty: string) => {
  if (warranty === `${t("TABLE-CUSTOMER-STATS-TITLE-ARTISAN")}`) return "Professional";
  if (warranty === `${t("TABLE-CUSTOMER-STATS-TITLE-INDIVIDUAL")}`) return "Individual";
  return "";
};

export const fromStateUsersTranslatedToKey = (country: string) => {
  if (country === `${t("HOME.MENU.USERS-ENABLED")}`) return "Enabled";
  if (country === `${t("HOME.MENU.USERS-DISABLED")}`) return "Disabled";
  return "";
};
