import React, { useContext } from "react";
import LateralContainer from "../LateralContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faSortAlphaDown, faChevronLeft, faUsers, faThumbtack } from "@fortawesome/free-solid-svg-icons";
import InputClear from "../../atoms/InputClear";
import DropDown from "../../atoms/DropDown";
import Button from "react-bootstrap/Button";
import { ICustomerSearch } from "./interfaces";
import { useTranslation } from "react-i18next";
import DropDownCities from "../../atoms/DropDownCities";
import { typeUserData } from "../../../constants/userTypes";
import { ApplicationContext } from "../../../context/ApplicationContext";
import Tooltip from "@mui/material/Tooltip";
import { useMediaQuery } from "@mui/material";
import { useFilterContext } from "../../../context";
import useAutocomplete from "../../atoms/AutocopleteInput/useAutocomplete";
import { createAddapterCustomer } from "../../../hooks/Customers/adapters/customerAdapter";
import AutoCompleteInput from "../../atoms/AutocopleteInput/AutocompleteInput";

export default function CustomerSearch(props: ICustomerSearch) {
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width:500px)");
  const listCountry = ["", `${t("COUNTRY-FRANCE")}`, `${t("COUNTRY-SPAIN")}`, `${t("COUNTRY-UK")}`, `${t("COUNTRY-GERMANY")}`];
  const { typeOfSearch, setTypeOfSearch } = useContext(ApplicationContext);
  const listCheck = ["", t("TABLE.DISTRIBUTORS-TITLE-YES"), t("TABLE.DISTRIBUTORS-TITLE-NO")];
  const {
    inputUsernameSearch,
    setInputUsernameSearch,
    inputFirstnameSearch,
    setInputFirstnameSearch,
    inputLastnameSearch,
    setInputLastnameSearch,
    inputTypeSearchCustomer,
    setInputTypeSearchCustomer,
    inputBusinessSearch,
    setInputBusinessSearch,
    inputZipCodeSearch,
    setInputZipCodeSearch,
    inputCitySearch,
    setInputCitySearch,
    inputCountrySearch,
    setInputCountrySearch,
    setInputMapUrlSearch,
    inputGeneralTermsAcceptedSearch,
    setGeneralTermsAcceptedSearch,
    inputPrivacyPolicyAcceptedSearch,
    setPrivacyPolicyAcceptedSearch,
    inputRegistrationDateSearch,
    setRegistrationDateSearch,
    inputLanguajeSearch,
    setInputLanguajeSearch,
    showLateralContainerSearch,
    setShowLateralContainerSearch,
  } = useFilterContext();
  const {
    handleSearch,
    searchWindowState,
    SetSearchWindowState,
    listSort,
    dropDownWhySort,
    setDropDownWhySort,
    listTypeOptions,
    listBusinessOptions,
  } = props;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleSearch();
    setShowLateralContainerSearch(!showLateralContainerSearch);
  };
  const username = useAutocomplete(
    "/customers/get-all?autocomplete=true&username=",
    handleSearch,
    inputUsernameSearch,
    setInputUsernameSearch,
    "userUsername",
    createAddapterCustomer
  );

  const listLanguajeOptions = ["", `${t("LANGUAGE-LIST-ENGLISH")}`, `${t("LANGUAGE-LIST-FRENCH")}`];
  return (
    <LateralContainer
      show={typeOfSearch === 1 ? showLateralContainerSearch : false}
      toggleShow={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
      content={
        <form action="/" onSubmit={handleSubmit}>
          <div className="row sectionBoxTop">
            <div className="col-2 col-lg-2" style={{ display: "flex" }}>
              <FontAwesomeIcon
                icon={faUsers}
                style={{
                  marginLeft: "50%",
                  marginTop: "0",
                  width: "50px",
                  height: "50px",
                }}
              />
            </div>
            <div className="col-10 col-lg-10 pb-3">
              <h4 className="h4-stl" style={{ margin: "15px 20px 15px" }}>
                <strong>{t("SEARCH.CUSTOMER.TITLE-SEARCH-CUSTOMERS")}</strong>
              </h4>
            </div>
          </div>
          {searchWindowState === "filterList" ? (
            <div className="cssanimation sequence fadeInTop">
              {matches ? (
                <>
                  <div className="filterBox my-4">
                    <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                      Identification
                    </h4>
                    <div className="flex-stl flex-direction-column px-4 my-2">
                      <div>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.CUSTOMER.TITLE-USERNAME")}</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <AutoCompleteInput {...username} freeSolo />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>
                    <div className="flex-stl flex-direction-column px-4 my-4">
                      <div>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.CUSTOMER.TITLE-FIRST-NAME")}</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <InputClear
                            type="text"
                            name="Brand"
                            placeholder=""
                            currentValue={inputFirstnameSearch}
                            handleOnInputValue={(data: string) => {
                              setInputFirstnameSearch(data);
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>
                    <div className="flex-stl flex-direction-column px-4 my-4">
                      <div>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.CUSTOMER.TITLE-LAST-NAME")}</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <InputClear
                            type="text"
                            name="BrandName"
                            placeholder=""
                            currentValue={inputLastnameSearch}
                            handleOnInputValue={(data: string) => {
                              setInputLastnameSearch(data);
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>
                    <div className="flex-stl flex-direction-column px-4 my-4">
                      <div>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.CUSTOMER.TITLE-TYPE")}</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <DropDown
                            options={listTypeOptions}
                            selectedItem={inputTypeSearchCustomer}
                            onSelect={(item: string) => setInputTypeSearchCustomer(item)}
                            width="100%"
                          />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>
                    <div className="flex-stl flex-direction-column px-4 my-4">
                      <div>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.CUSTOMER.TITLE-BUSINESS")}</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <DropDown
                            options={listBusinessOptions}
                            selectedItem={inputBusinessSearch}
                            onSelect={(item: string) => setInputBusinessSearch(item)}
                            width="100%"
                          />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>
                  </div>
                  <div className="filterBox my-4">
                    <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                      {t("SEARCH.CUSTOMER.TITLE-ADDRESS")}
                    </h4>
                    <div className="flex-stl flex-direction-column px-4 my-2">
                      <div>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.CUSTOMER.TITLE-ZIP-CODE")}</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <InputClear
                            type="text"
                            name="OldReference"
                            placeholder=""
                            currentValue={inputZipCodeSearch}
                            handleOnInputValue={(data: string) => {
                              setInputZipCodeSearch(data);
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>
                    <div className="flex-stl flex-direction-column px-4 my-4">
                      <div>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.CUSTOMER.TITLE-CITY")}</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <InputClear
                            type="text"
                            name="BrandName"
                            placeholder=""
                            currentValue={inputCitySearch}
                            handleOnInputValue={(data: string) => {
                              setInputCitySearch(data);
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>
                    <div className="flex-stl flex-direction-column px-4 my-4">
                      <div>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL.CUSTOMER.TITLE-COUNTRY")}</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <DropDownCities
                            options={listCountry}
                            selectedItem={inputCountrySearch}
                            onSelect={(item: string) => setInputCountrySearch(item)}
                            width="100%"
                            roles={[`${typeUserData.admin}`]}
                          />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>
                  </div>
                  <div className="filterBox my-4">
                    <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                      {t("SEARCH.CUSTOMER.TITLE-OTHERS")}
                    </h4>
                    <div className="flex-stl flex-direction-column px-4 my-2">
                      <div>
                        <h5 className="h5-stl Right">{t("SEARCH.CUSTOMER.INPUT-GENERAL-TERMS-ACCEPTED")}</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <DropDown
                            options={listCheck}
                            selectedItem={inputGeneralTermsAcceptedSearch}
                            onSelect={(item: string) => setGeneralTermsAcceptedSearch(item)}
                            width="100%"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex-stl flex-direction-column px-4 my-4">
                      <div>
                        <h5 className="h5-stl Right">{t("SEARCH.CUSTOMER.INPUT-PRIVACY-POLICY-ACCEPTED")}</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <DropDown
                            options={listCheck}
                            selectedItem={inputPrivacyPolicyAcceptedSearch}
                            onSelect={(item: string) => setPrivacyPolicyAcceptedSearch(item)}
                            width="100%"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex-stl flex-direction-column px-4 my-4">
                      <div>
                        <h5 className="h5-stl Right">{t("CREATE.CUSTOMER.NAV-INPUT-REGISTRATION-DATE")}</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <InputClear
                            type="date"
                            name="BrandName"
                            placeholder=""
                            currentValue={inputRegistrationDateSearch}
                            handleOnInputValue={(data: string) => {
                              setRegistrationDateSearch(data);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex-stl flex-direction-column px-4 my-4">
                      <div>
                        <h5 className="h5-stl Right">{t("ALL.GENERAL-REASON-EXCHANGE.TITLE-LANGUAJE")}</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <DropDown
                            options={listLanguajeOptions}
                            selectedItem={inputLanguajeSearch}
                            onSelect={(item: string) => setInputLanguajeSearch(item)}
                            width="100%"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="filterBox my-4">
                    <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                      Identification
                    </h4>
                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl">{t("ALL.GENERAL.CUSTOMER.TITLE-USERNAME")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <AutoCompleteInput {...username} freeSolo />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl">{t("ALL.GENERAL.CUSTOMER.TITLE-FIRST-NAME")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <InputClear
                            type="text"
                            name="Brand"
                            placeholder=""
                            currentValue={inputFirstnameSearch}
                            handleOnInputValue={(data: string) => {
                              setInputFirstnameSearch(data);
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl">{t("ALL.GENERAL.CUSTOMER.TITLE-LAST-NAME")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <InputClear
                            type="text"
                            name="BrandName"
                            placeholder=""
                            currentValue={inputLastnameSearch}
                            handleOnInputValue={(data: string) => {
                              setInputLastnameSearch(data);
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl">{t("ALL.GENERAL.CUSTOMER.TITLE-TYPE")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <DropDown
                            options={listTypeOptions}
                            selectedItem={inputTypeSearchCustomer}
                            onSelect={(item: string) => setInputTypeSearchCustomer(item)}
                            width="100%"
                          />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl">{t("ALL.GENERAL.CUSTOMER.TITLE-BUSINESS")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <DropDown
                            options={listBusinessOptions}
                            selectedItem={inputBusinessSearch}
                            onSelect={(item: string) => setInputBusinessSearch(item)}
                            width="100%"
                          />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>
                  </div>
                  <div className="filterBox my-4">
                    <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                      {t("SEARCH.CUSTOMER.TITLE-ADDRESS")}
                    </h4>
                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl">{t("ALL.GENERAL.CUSTOMER.TITLE-ZIP-CODE")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <InputClear
                            type="text"
                            name="OldReference"
                            placeholder=""
                            currentValue={inputZipCodeSearch}
                            handleOnInputValue={(data: string) => setInputZipCodeSearch(data)}
                          />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl">{t("ALL.GENERAL.CUSTOMER.TITLE-CITY")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <InputClear
                            type="text"
                            name="BrandName"
                            placeholder=""
                            currentValue={inputCitySearch}
                            handleOnInputValue={(data: string) => setInputCitySearch(data)}
                          />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl">{t("ALL.GENERAL.CUSTOMER.TITLE-COUNTRY")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <DropDownCities
                            options={listCountry}
                            selectedItem={inputCountrySearch}
                            onSelect={(item: string) => setInputCountrySearch(item)}
                            width="100%"
                            roles={[`${typeUserData.admin}`]}
                          />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>
                  </div>
                  <div className="filterBox my-4">
                    <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                      {t("SEARCH.CUSTOMER.TITLE-OTHERS")}
                    </h4>
                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl">{t("SEARCH.CUSTOMER.INPUT-GENERAL-TERMS-ACCEPTED")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <DropDown
                            options={listCheck}
                            selectedItem={inputGeneralTermsAcceptedSearch}
                            onSelect={(item: string) => setGeneralTermsAcceptedSearch(item)}
                            width="13vw"
                          />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl">{t("SEARCH.CUSTOMER.INPUT-PRIVACY-POLICY-ACCEPTED")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <DropDown
                            options={listCheck}
                            selectedItem={inputPrivacyPolicyAcceptedSearch}
                            onSelect={(item: string) => setPrivacyPolicyAcceptedSearch(item)}
                            width="100%"
                          />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl">{t("CREATE.CUSTOMER.NAV-INPUT-REGISTRATION-DATE")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <InputClear
                            type="date"
                            name="BrandName"
                            placeholder=""
                            currentValue={inputRegistrationDateSearch}
                            handleOnInputValue={(data: string) => setRegistrationDateSearch(data)}
                          />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>

                    <div className="flex-stl flex-center flex-space-evenly my-4" style={{ padding: "0 4rem" }}>
                      <div className="Right" style={{ width: "30%", paddingRight: "20px" }}>
                        <h5 className="h5-stl">{t("ALL.GENERAL-REASON-EXCHANGE.TITLE-LANGUAJE")}</h5>
                      </div>
                      <div className="flex-stl" style={{ width: "70%" }}>
                        <div className="full-width">
                          <DropDown
                            options={listLanguajeOptions}
                            selectedItem={inputLanguajeSearch}
                            onSelect={(item: string) => setInputLanguajeSearch(item)}
                            width="100%"
                          />
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                      </div> */}
                    </div>
                  </div>
                </>
              )}
              <div className="bg-gray" style={{ display: "flex" }}>
                <Tooltip title={`${t("FILER-TO-COLUMNS")}`} placement="bottom">
                  <div style={{ width: "60px", margin: "auto 0" }}>
                    <FontAwesomeIcon
                      className="grayBackgr"
                      style={{ width: "47px", height: "47px" }}
                      icon={faThumbtack}
                      onClick={() => {
                        setTypeOfSearch(0);
                      }}
                    />
                  </div>
                </Tooltip>
                {/* <FontAwesomeIcon
                  className="grayBackgr"
                  icon={faSortAlphaDown}
                  onClick={() => SetSearchWindowState("SortWindow")}
                  style={{ width: "47px", height: "47px" }}
                /> */}
                <Button
                  variant="primary"
                  style={{ margin: "15px 30px" }}
                  onClick={() => {
                    handleSearch();
                    setShowLateralContainerSearch(!showLateralContainerSearch);
                  }}
                >
                  {t("SEARCH.ALL.BUTTON-SEARCH")}
                </Button>
                <h4
                  className="h4-stl pt-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setInputUsernameSearch("");
                    setInputFirstnameSearch("");
                    setInputLastnameSearch("");
                    setInputTypeSearchCustomer("");
                    setInputBusinessSearch("");
                    setInputZipCodeSearch("");
                    setInputCitySearch("");
                    setInputCountrySearch("");
                    setInputMapUrlSearch("");
                    setGeneralTermsAcceptedSearch("");
                    setPrivacyPolicyAcceptedSearch("");
                    setRegistrationDateSearch("");
                    handleSearch();
                  }}
                >
                  {t("SEARCH.ALL.BUTTON-RESET")}
                </h4>
                <h4
                  className="h4-stl pt-4 mx-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                >
                  {t("SEARCH.ALL.BUTTON-CLOSE")}
                </h4>
              </div>
            </div>
          ) : (
            <div className="cssanimation sequence fadeInBottom">
              <div className="sectionBoxTop pb-4">
                <div
                  className="py-3 my-2"
                  style={{
                    backgroundColor: "var(--color-border)",
                    display: "flex",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    onClick={() => SetSearchWindowState("filterList")}
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "2em",
                      cursor: "pointer",
                    }}
                  />
                  <h4 className="h4-stl" onClick={() => SetSearchWindowState("filterList")} style={{ cursor: "pointer" }}>
                    <strong>{t("SEARCH.ALL.BUTTON-SORT-ORDER")}</strong>
                  </h4>
                </div>
                <div>
                  <DropDown
                    options={listSort}
                    selectedItem={dropDownWhySort}
                    onSelect={(item: string) => setDropDownWhySort(item)}
                    width="20vw"
                  />
                </div>
              </div>

              <div>
                <Button
                  variant="primary"
                  style={{
                    margin: "15px 30px",
                    display: "flex",
                    float: "right",
                  }}
                  onClick={() => SetSearchWindowState("filterList")}
                >
                  Apply
                </Button>
              </div>
            </div>
          )}
          <input type="submit" value="" style={{ display: "none" }}></input>
        </form>
      }
    ></LateralContainer>
  );
}
