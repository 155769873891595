import { useState, useContext } from "react";
import { ApplicationContext } from "../../context/ApplicationContext";
import useFetchAndLoad from "../useFetchAndLoad";
import useAsync from "../useAsync";
import { IChangeLogList, IChangeLogListDOM } from "../../api/interfaces/interfaz_change_log";
import { getAllChangeLogs, getExportChangeLog, getExportChangeLogByID } from "../../api/services/change_log_services";
import { createAddapterChangeLogs } from "./adapters/changeLogsAdapter";
import useToastMui from "../Toasts/useToastMui";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

function useChangeLog(user: string, date: string, itemId: string, sessionLogin: string, object: string, action: string) {
  const { setChangeLogLength, setExportToggle, changeLogPage, setChangeLogPage } = useContext(ApplicationContext);
  const { handleCreateToast } = useToastMui();
  const [changeLogArray, setChangeLogArray] = useState<IChangeLogListDOM[]>();
  const [lastPage, setLastPage] = useState(0);
  const [searchToggle, setSearchToggle] = useState(false);
  const limit = 8;
  const { i18n } = useTranslation();
  const [errorDeleteAlert, setErrorDeleteAlert] = useState<boolean>(false);

  const { loading, callEndpoint } = useFetchAndLoad();
  //to sort list by api

  const [countCode, setCountCode] = useState(1);
  const [typeSort, setTypeSort] = useState("");

  //TODO -> MANDAR INPUTS A LOS FILTROS, Y CAMBIAR TABLA BORRAR SORTS POR LOS NUEVOS
  const getQuery = (user: string, date: string, itemId: string, sessionLogin: string, object: string, action: string, sort?: string) => {
    // let filterToSearch = "";
    // if (date) {
    //   const filterDate = date.split("-");
    //   filterToSearch = `${filterDate[0]}/${filterDate[1]}/${filterDate[2]}`;
    // }
    const queryKeys: any = {
      user: user,
      date,
      resource_id: itemId,
      login: sessionLogin,
      resource: object,
      action: action.toLowerCase(),
      until: date ? "" : 7,
      sort: sort ? (sort === "none" ? "" : sort) : "desc(created_at)",
      //TODO CHANGE LOGS BY IDS (BRANDS, GROUPS, ETC)
    };
    let query = {};
    for (let key in queryKeys) {
      if (queryKeys[key]) {
        query = { ...query, [key]: queryKeys[key] };
      }
    }
    return query;
  };

  const loadChangeLogs = async (
    user: string,
    date: string,
    itemId: string,
    nowPage: number,
    sessionLogin: string,
    object: string,
    action: string
  ) => {
    let query = getQuery(user, date, itemId, sessionLogin, object, action, typeSort);
    query = {
      ...query,
      limit: limit,
      offset: (nowPage - 1) * limit,
    };

    const response = await callEndpoint(
      getAllChangeLogs({ query })
      // () => !itemId && handleCreateToast(t("ALERT-CHANGE-LOG-ERROR-GET"), "error")
    );
    return response;
  };

  const adaptInfo = (data: IChangeLogList) => {
    const array: IChangeLogListDOM[] = createAddapterChangeLogs(data?.result?.items);
    array.forEach((item: any) => {
      item.after = JSON.parse(item.after);
      item.before = JSON.parse(item.before);
    });
    setChangeLogArray(array);
    setLastPage(data?.result?.total);
    setChangeLogLength(data?.result?.total);
    if (changeLogPage > Math.ceil(data?.result?.total / limit)) setChangeLogPage(1);
  };

  useAsync(
    () => loadChangeLogs(user, date, itemId, changeLogPage, sessionLogin, object, action),
    adaptInfo,
    () => {},
    [changeLogPage, searchToggle, typeSort]
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setChangeLogPage(value);
  };
  const onSearch = () => {
    setSearchToggle((value) => !value);
    setChangeLogPage(1);
  };

  const exportChangeLogs = async (checkedType: string, changeLogListID: string[], checkNeedToExport: boolean[]) => {
    setExportToggle(true);
    try {
      if (checkNeedToExport[0] === true) {
        await getExportChangeLog(checkedType);
      }
      if (checkNeedToExport[1] === true) {
        let query = getQuery(user, date, itemId, sessionLogin, object, action, "none");
        await getExportChangeLogByID(checkedType, changeLogListID, i18n.language === "fr" ? "Journal des modifications" : "Change Log");
      }
    } catch (error: any) {
      console.log("error export change log");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(t("ALERT-CHANGE-LOG-ERROR-EXPORT"), "error");
    } finally {
      console.log("end");
      setExportToggle(false);
    }
  };

  // * ------------------------SORT----------------------------------//

  // sort

  const incrementCount = (key: string) => {
    setCountCode((prev) => prev + 1);

    if (countCode === 1) {
      setTypeSort(`asc(${key})`);
    }
    if (countCode === 2) {
      setTypeSort(`desc(${key})`);
      setCountCode(1);
    }
  };

  return {
    changeLogArray,
    loading,
    exportChangeLogs,
    errorDeleteAlert,
    setErrorDeleteAlert,
    currentPage: changeLogPage,
    lastPage,
    handleChange,
    limit,
    onSearch,
    typeSort,
    incrementCount,
  };
}

const service = {
  useChangeLog,
};
export default service;
export { useChangeLog };
