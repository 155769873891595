import React, { useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHammer, faPlay } from "@fortawesome/free-solid-svg-icons";
import "../../../constants/global.css";
import "../styles/Refusal.css";
import { Breadcrumb, Card } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import ItemsInfoReasonForExchange from "../../organisms/ItemsInfoReasonForExchange/ItemsInfoReasonForExchange";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { createReasonsForExchange } from "../../../api/services/reasons_for_exchange_services";
import { useTranslation } from "react-i18next";
import useToastMui from "../../../hooks/Toasts/useToastMui";

export default function CreateReasonForExchange(props: any) {
  //Translations
  const { t } = useTranslation();
  const history = useHistory();
  const { handleCreateToast } = useToastMui();
  const { setReasonExchangeGivenReason, setReasonExchangeId, setOpenCreateReasonExchange, openCreateReasonExchange } =
    useContext(ApplicationContext);
  const [inputDescription, setInputDescription] = useState<string>("");
  const listLanguajeOptions = ["", `${t("LANGUAGE-LIST-ENGLISH")}`, `${t("LANGUAGE-LIST-FRENCH")}`];
  //Create
  const [inputCodeCreate, setInputCodeCreate] = useState<string>("");
  const [inputDisplayOrderCreate, setInputDisplayOrderCreate] = useState<string>("");
  const [inputLabelCreate, setInputLabelCreate] = useState<string>("");
  const [DropDownLanguajeCreate, setDropDownLanguajeCreate] = useState<string>(listLanguajeOptions[0]);
  const [checkedCreate, setCheckedCreate] = useState(false);
  const handleChangeCreate = (nextChecked: any) => {
    setCheckedCreate(nextChecked);
  };

  const [alertStatus, setAlertStatus] = useState<boolean>(false);

  //API STATE
  const [loading, setLoading] = useState<boolean>(false);
  const [refusalReasonsCreated, setRefusalReasonsCreated] = useState<any>();

  const CreateReasonsForExchangeInfo = async (close: boolean) => {
    if (inputCodeCreate && inputDisplayOrderCreate && inputLabelCreate && DropDownLanguajeCreate) {
      setLoading(!loading);

      try {
        const response = createReasonsForExchange(
          inputLabelCreate,
          inputCodeCreate,
          inputDisplayOrderCreate,
          checkedCreate,
          DropDownLanguajeCreate === `${t("LANGUAGE-LIST-ENGLISH")}` ? "English" : "French",
          inputDescription
        );
        const data = (await response).data;
        handleCreateToast(t("ALERT-REASON-FOR-EXCHANGE-SUCCESS-CREATE"), "success");

        setRefusalReasonsCreated(data);
        if (!close) {
          history.push(`/reasons-for-exchange-info/${data?.result.id}`);
        } else {
          if (document.referrer) {
            history.go(-1);
          } else {
            history.push("/");
          }
        }
        return data;
      } catch (error: any) {
        console.log("error create reason for exchange");
        console.log(error);

        if (error?.request?.status !== 401 && error?.request?.status !== 403)
          handleCreateToast(t("ALERT-REASON-FOR-EXCHANGE-ERROR-CREATE"), "error");
      } finally {
        setLoading(false);
        console.log("end");
      }
    } else {
      setAlertStatus(true);
    }
  };

  //Calling api from info page (product info)
  //Don't rediect
  const handleCreated = async () => {
    if (inputCodeCreate && inputDisplayOrderCreate && inputLabelCreate && DropDownLanguajeCreate) {
      setLoading(!loading);

      try {
        const response = createReasonsForExchange(
          inputLabelCreate,
          inputCodeCreate,
          inputDisplayOrderCreate,
          checkedCreate,
          DropDownLanguajeCreate === `${t("LANGUAGE-LIST-ENGLISH")}` ? "English" : "French",
          inputDescription
        );
        const data = (await response).data;
        handleCreateToast(t("ALERT-REASON-FOR-EXCHANGE-SUCCESS-CREATE"), "success");
        setReasonExchangeId(data.result.id);
        setReasonExchangeGivenReason(data.result.display_order);

        setOpenCreateReasonExchange(!openCreateReasonExchange);

        return data;
      } catch (error: any) {
        console.log("error create reason for exchange");
        console.log(error);
        if (error?.request?.status !== 401 && error?.request?.status !== 403)
          handleCreateToast(t("ALERT-REASON-FOR-EXCHANGE-ERROR-CREATE"), "error");
      } finally {
        setLoading(false);
        console.log("end");
      }
    } else {
      setAlertStatus(true);
    }
  };

  return (
    <div>
      <Breadcrumb>
        <Link to="/reasons-for-exchange">
          <Breadcrumb.Item href="/reasons-for-exchange">{t("CREATE.REASON-EXCHANGE.HEADER-LINK")}</Breadcrumb.Item>
        </Link>
        <FontAwesomeIcon icon={faPlay} style={{ width: "10px", margin: "5px 15px" }} />
        <Breadcrumb.Item href="#">{t("CREATE.REASON-EXCHANGE.HEADER-SECOND-LINK")}</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header>
          <div className="flex-warranty flex-between">
            <div className="container-warranty flex-start">
              <FontAwesomeIcon icon={faHammer} className="icon_size" />
              <h4 className="h4-stl" style={{ margin: "15px 5px" }}>
                <strong>{t("CREATE.REASON-EXCHANGE.HEADER-SECOND-LINK")}</strong>
              </h4>
            </div>
            <div className="container-warranty flex-end  wrap-warranty">
              {!props.Created && <Button onClick={() => CreateReasonsForExchangeInfo(false)}>{t("CREATE.ALL.BUTTON-SAVE")}</Button>}
              {!props.Created ? (
                <Button
                  variant="outline-secondary"
                  style={{
                    minWidth: "150px",
                    height: "40px",
                  }}
                  onClick={() => CreateReasonsForExchangeInfo(true)}
                >
                  {t("CREATE.ALL.BUTTON-SAVE-CLOSE")}
                </Button>
              ) : (
                <Button
                  variant="outline-secondary"
                  style={{
                    minWidth: "150px",
                    maxHeight: "40px",
                  }}
                  onClick={() => handleCreated()}
                >
                  {t("CREATE.ALL.BUTTON-SAVE-CLOSE")}
                </Button>
              )}
              {!props.Created ? (
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    if (document.referrer) {
                      history.go(-1);
                    } else {
                      history.push("/");
                    }
                  }}
                >
                  {t("CREATE.ALL.BUTTON-CLOSE")}
                </Button>
              ) : (
                <Button variant="outline-secondary" onClick={() => setOpenCreateReasonExchange(!openCreateReasonExchange)}>
                  {t("CREATE.ALL.BUTTON-CLOSE")}
                </Button>
              )}
            </div>
          </div>
        </Card.Header>
        <ItemsInfoReasonForExchange
          warningStatus={alertStatus}
          currentValueCode={inputCodeCreate}
          handleOnInputValueCode={(data: string) => {
            setInputCodeCreate(data);
          }}
          currentValueDisplay={inputDisplayOrderCreate}
          handleOnInputValueDisplay={(data: string) => {
            setInputDisplayOrderCreate(data);
          }}
          currentValueLabel={inputLabelCreate}
          handleOnInputValueLabel={(data: string) => {
            setInputLabelCreate(data);
          }}
          currentValueLanguaje={DropDownLanguajeCreate}
          onSelectDropdown={(item: string) => setDropDownLanguajeCreate(item)}
          languajeOptionsList={listLanguajeOptions}
          section="ReasonExchange"
          checked={checkedCreate}
          handleChangeChecked={handleChangeCreate}
          currentValueDescription={inputDescription}
          handleOnInputValueDescription={(data: string) => {
            setInputDescription(data);
          }}
        />
      </Card>
    </div>
  );
}
