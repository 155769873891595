import "bootstrap/dist/css/bootstrap.css";
import "../../../constants/global.css";
import InputClear from "../../atoms/InputClear";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import ValidationAlert from "../../atoms/ValidationAlert";
import InputClearGuard from "../../atoms/InputClearGuard";
import { typeUserData } from "../../../constants/userTypes";

export default function CustomersMeansOfContactTable(props: any) {
  const { t } = useTranslation();

  const { warningStatus, warningEmailStatus } = props;

  const RenderWarnings = () => {
    return (
      <div>
        <ValidationAlert message={t("ALERT-GLOBAL-FILL-FIELDS")} variant="warning" />
      </div>
    );
  };

  return (
    <div className="my-4 mx-2">
      <div className="flex-inputs-responsive my-lg-4 my-3 px-lg-5 px-2">
        <div className="col-12 col-lg-8 my-lg-4 my-2">
          <h4 className="h4-stl">
            <strong>
              {t("ALL.GENERAL.CUSTOMER.TITLE-EMAIL")} <span style={{ color: "var(--color-required)" }}>*</span>
            </strong>
          </h4>
          <div className="flex-inputs-responsive-info">
            <div className="full-width">
              <InputClearGuard
                type="text"
                name=""
                placeholder=""
                style={{ width: "100%" }}
                currentValue={props.email}
                handleOnInputValue={props.setEmail}
                roles={[]}
              />
            </div>
            <div>
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{
                  marginLeft: "8px",
                  width: "2Opx",
                  height: "20px",
                  float: "right",
                }}
              />
            </div>
            {!props.email && warningStatus && <RenderWarnings />}
            {warningEmailStatus && (
              <div className="py-4">
                <ValidationAlert message={t("ALERT-ERROR-EMAIL-EXIST")} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex-inputs-responsive px-lg-5 px-2">
        <div className="full-width my-3">
          <h4 className="h4-stl">
            <strong>{t("ALL.GENERAL.CUSTOMER.TITLE-MOBILE-PHONE-NUMBER")}</strong>
          </h4>
          <div className="flex-inputs-responsive-info ">
            <div className="full-width">
              <InputClearGuard
                type="text"
                name=""
                placeholder=""
                style={{ width: "100%" }}
                currentValue={props.mobilePhoneNumber}
                handleOnInputValue={props.setMobilePhoneNumber}
                roles={[`${typeUserData.admin}`]}
              />
            </div>
            <div>
              <FontAwesomeIcon
                icon={faPhone}
                style={{
                  width: "2Opx",
                  height: "20px",
                }}
              />
            </div>
          </div>
        </div>
        <div className="full-width my-3">
          <h4 className="h4-stl">
            <strong>{t("CREATE.CUSTOMER.NAV-INPUT-WORK-PHONE-NUMBER")}</strong>
          </h4>
          <div className="flex-inputs-responsive-info">
            <div className="full-width">
              <InputClearGuard
                type="text"
                name=""
                placeholder=""
                style={{ width: "100%" }}
                currentValue={props.workPhoneNumber}
                handleOnInputValue={props.setWorkPhoneNumber}
                roles={[`${typeUserData.admin}`]}
              />
            </div>
            <div>
              <FontAwesomeIcon
                icon={faPhone}
                style={{
                  width: "2Opx",
                  height: "20px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-inputs-responsive px-lg-5 px-2">
        <div className="full-width my-3">
          <h4 className="h4-stl">
            <strong>{t("CREATE.CUSTOMER.NAV-INPUT-HOME-PHONE-NUMBER")}</strong>
          </h4>
          <div className="flex-inputs-responsive-info">
            <div className="full-width">
              <InputClearGuard
                type="text"
                name=""
                placeholder=""
                style={{ width: "100%" }}
                currentValue={props.homePhoneNumber}
                handleOnInputValue={props.setHomePhoneNumber}
                roles={[`${typeUserData.admin}`]}
              />
            </div>
            <div>
              <FontAwesomeIcon
                icon={faPhone}
                style={{
                  width: "2Opx",
                  height: "20px",
                }}
              />
            </div>
          </div>
        </div>
        <div className="full-width my-3">
          <h4 className="h4-stl">
            <strong>{t("CREATE.CUSTOMER.NAV-INPUT-FAX-NUMBER")}</strong>
          </h4>
          <div className="flex-inputs-responsive-info">
            <div className="full-width">
              <InputClearGuard
                type="text"
                name=""
                placeholder=""
                style={{ width: "100%" }}
                currentValue={props.faxNumber}
                handleOnInputValue={props.setFaxNumber}
                roles={[`${typeUserData.admin}`]}
              />
            </div>
            <div>
              <FontAwesomeIcon
                icon={faPhone}
                style={{
                  width: "2Opx",
                  height: "20px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
