import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useFetchAndLoad from "../../../hooks/useFetchAndLoad";
import { BACKEND_URL } from "../../../config/settings.json";
import { getApisInfo } from "../../../api/services/user_services";
export default function useAutocomplete(
  url: string,
  handleSearch: Function,
  value: any,
  setValue: Dispatch<SetStateAction<any>>,
  key: string,
  adapter?: any
) {
  const [options, setOptions] = useState<any[]>([]);
  const { callEndpoint, loading } = useFetchAndLoad();

  useEffect(() => {
    if (value?.length < 3) return;

    const delayDebounceFn = setTimeout(() => {
      callApis();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [value]);

  const callApis = async () => {
    const response = callEndpoint(await getApisInfo(`${BACKEND_URL}${url}${value}&limit=10&offset=0`));
    const data = (await response).data;
    if (adapter) {
      const adaptData = adapter(data?.result?.items);
      const mappedData = adaptData?.map((item: any) => (item[key] ? item[key] : ""));
      let setArray: any = new Set(mappedData);
      setOptions([...setArray]);
    } else {
      const mappedData = data.result.items?.map((item: any) => (item[key] ? item[key] : ""));
      let setArray: any = new Set(mappedData);
      setOptions([...setArray]);
    }
  };

  const handleChange = (event: any, newValue: any | null) => {
    setValue(newValue);
    if (!newValue) return;
    handleSearch();
  };
  const onChangeInput = (event: any, newValue: any | null) => {
    setValue(event.target.value);
  };

  return { value, options, setValue, handleChange, onChangeInput, loading };
}
