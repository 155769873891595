import React, { useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import search from "../../../images/magnifying-glass-solid.svg";
import "../../../constants/global.css";
import "../styles/Refusal.css";
import { Breadcrumb, Card } from "react-bootstrap";
import changeLogIcon from "../../../images/changeLogIcon.svg";
import ChangeLogTable from "../../organisms/ChangeLogTable/ChangelogTable";
import { ApplicationContext } from "../../../context/ApplicationContext";
import "./changelog.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import Fade from "@mui/material/Fade";
import Reloadimg from "../../../images/reload.svg";
import copyImg from "../../../images/copy.svg";
import ModalExport from "../../atoms/ModalExport";
import LoadingLogin from "../../atoms/LoadingLogin";
import exportImg from "../../../images/export.svg";
import preferencesImg from "../../../images/preferences.svg";
import ChangeLogSearch from "../../organisms/Search/ChangeLogSearch";
import { useChangeLog } from "../../../hooks/ChangeLogs/useChangeLogs";
import { useMediaQuery } from "@mui/material";
import "../../../constants/global.css";
import ChangeLogTableTransfer from "../../templates/TableTranfers/ChangeLogTableTransfer";
import { useFilterContext } from "../../../context";
import GuardNotAuthorized from "../../atoms/ItemPermission/GuardNotAuthorized";

const listCheck = ["", "Created", "Updated", "Deleted"];

export default function ChangeLog(props: any) {
  const { changeLogLength, changeLogListID, changeLogAllSelected } = useContext(ApplicationContext);
  //Translations
  const { t } = useTranslation();
  let history = useHistory();

  const { resourceId } = props;

  const [searchState, setSearchState] = useState<boolean>(false);
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const listSort: string[] = [
    t("ALL.GENERAL-CHANGE-LOG.TITLE-LOGIN"),
    t("ALL.GENERAL-CHANGE-LOG.TITLE-USER"),
    t("ALL.GENERAL-CHANGE-LOG.TITLE-DATE"),
    t("ALL.GENERAL-CHANGE-LOG.TITLE-OBJECT"),
    t("ALL.GENERAL-CHANGE-LOG.TITLE-ACTION"),
    t("ALL.GENERAL-CHANGE-LOG.TITLE-ACTIVITY"),
  ];

  const matches = useMediaQuery("(max-width:500px)");

  //Filter Search
  const {
    inputSessionLoginSearch,
    inputUserSearch,
    inputDateSearch,
    inputObjectSearch,
    inputActionSearch,
    showLateralContainerSearch,
    setShowLateralContainerSearch,
  } = useFilterContext();
  const [dropDownWhySort, setDropDownWhySort] = useState<string>(listSort[0]);

  const [searchWindowState, SetSearchWindowState] = useState("filterList");

  // {t("NAV-ALL-ITEM3")}
  const [loadExport, setLoadExport] = useState(false);

  //Modal
  const [openExport, setOpenExport] = useState(false);
  // Checkbox
  const [checkNeedToExport, setCheckNeedToExport] = useState<boolean[]>([false, true, false]);
  const [checkFormatToExport, setCheckFormatToExport] = useState<boolean[]>([true, false, false, false]);
  const [checkedType, setCheckedType] = useState<string>("csv");

  const {
    changeLogArray,
    loading,
    exportChangeLogs,
    errorDeleteAlert,
    setErrorDeleteAlert,
    currentPage,
    lastPage,
    handleChange,
    limit,
    onSearch,

    typeSort,
    incrementCount,
  } = useChangeLog(inputUserSearch, inputDateSearch, resourceId, inputSessionLoginSearch, inputObjectSearch, inputActionSearch);

  const exportFunction = () => {
    try {
      exportChangeLogs(checkedType, changeLogListID, checkNeedToExport);
    } finally {
      setOpenExport(false);
    }
  };

  const showModalExport = () => {
    return (
      <ModalExport
        openExport={openExport}
        setOpenExport={setOpenExport}
        checkNeedToExport={checkNeedToExport}
        setCheckNeedToExport={setCheckNeedToExport}
        checkFormatToExport={checkFormatToExport}
        setCheckFormatToExport={setCheckFormatToExport}
        checkedType={checkedType}
        setCheckedType={setCheckedType}
        onClick={() => {
          setOpenExport(false);
          exportFunction();
        }}
      />
    );
  };

  //menu fade
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function copy() {
    const url = document.createElement("input");
    url.value = window.location.href;
    navigator.clipboard.writeText(url.value);
    setAnchorEl(null);
  }

  const renderLateralContainerSearch = () => {
    return (
      <ChangeLogSearch
        listSort={listSort}
        dropDownWhySort={dropDownWhySort}
        setDropDownWhySort={setDropDownWhySort}
        searchWindowState={searchWindowState}
        SetSearchWindowState={SetSearchWindowState}
        handleSearch={() => onSearch()}
        listCheck={listCheck}
      />
    );
  };
  // section1

  const RenderTabla1 = () => {
    return (
      <div>
        <ChangeLogTable
          searchState={searchState}
          rowsPerPage={limit}
          UsePlanText="false"
          inputToSort={dropDownWhySort}
          changeLogArray={changeLogArray}
          loading={loading}
          currentPage={currentPage}
          lastPage={lastPage}
          handleChange={handleChange}
          incrementCount={incrementCount}
          typeSort={typeSort}
          setDropDownWhySort={setDropDownWhySort}
          listSort={listCheck}
          handleSearch={() => onSearch()}
        />
        <div className="row sectionBoxBottom">
          <div className="col-10 col-lg-11">
            <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
              Total {changeLogLength}
            </h4>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {!props.mainlink && (
        <Breadcrumb>
          <Breadcrumb.Item href="#">{t("TABLE.CHANGE-LOG.HEADER-LINK")}</Breadcrumb.Item>
        </Breadcrumb>
      )}

      <Card>
        <Card.Header>
          <div className="flex-warranty flex-between">
            {!matches ? (
              <>
                <div className="container-warranty flex-start">
                  <img alt="" src={changeLogIcon} style={{ width: "50px" }} />

                  <div>
                    <h4 className="h4-stl" style={{ margin: "15px 5px" }}>
                      <strong>{t("TABLE.CHANGE-LOG.TITLE-HEADER")}</strong>
                    </h4>
                    <h5 className="h5-stl" style={{ margin: "15px 5px" }}>
                      {t("TABLE.CHANGE-LOG.HEADER-TOTAL")} {changeLogLength}
                    </h5>
                  </div>
                </div>
                <div className="container-warranty flex-end wrap-warranty">
                  <div>
                    <img
                      alt=""
                      className="imgSearch"
                      src={search}
                      onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                      style={{ width: "50px", height: "50px" }}
                    ></img>
                  </div>
                  <div onClick={handleClick}>
                    <FontAwesomeIcon className="grayBackgr" icon={faBars} style={{ width: "50px", height: "50px" }} />
                  </div>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                    <MenuItem
                      onClick={() => {
                        history.go(0);
                      }}
                      style={{ fontSize: "15px" }}
                    >
                      <img alt="" src={Reloadimg} style={{ width: "20px", marginRight: "10px" }}></img>
                      {t("ALL.MENU.RELOAD")}
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setOpenTransferModal(true);
                      }}
                      style={{ fontSize: "15px" }}
                    >
                      <img alt="" src={preferencesImg} style={{ width: "20px", marginRight: "10px" }}></img>
                      {t("ALL-BUTTON-PREFERENCES")}
                    </MenuItem>
                    <GuardNotAuthorized>
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          setOpenExport(true);
                        }}
                        style={{ fontSize: "15px" }}
                      >
                        <img alt="" src={exportImg} style={{ width: "20px", marginRight: "10px" }} />
                        {t("NAV-ALL-ITEM3")}
                        <span
                          style={{
                            background: "var( --color-accepted)",
                            marginLeft: "6px",
                            color: "var(--color-main-text)",
                            borderRadius: "20px",
                            padding: "4px 8px",
                            fontSize: "12px",
                          }}
                        >
                          <b>{changeLogAllSelected ? changeLogLength : changeLogListID?.length}</b>
                        </span>
                      </MenuItem>
                    </GuardNotAuthorized>
                    <MenuItem onClick={copy} style={{ fontSize: "15px" }}>
                      <img alt="" src={copyImg} style={{ width: "20px", marginRight: "10px" }}></img>
                      {t("ALL.MENU.COPY-LINK")}
                    </MenuItem>
                    {/* <MenuItem onClick={handleClose} style={{fontSize:'15px'}}>Logout</MenuItem> */}
                  </Menu>
                </div>
              </>
            ) : (
              <>
                <div className="flex-stl flex-center">
                  <img alt="" src={changeLogIcon} style={{ width: "50px" }} />

                  <div>
                    <h4 className="h4-stl" style={{ margin: "15px 5px" }}>
                      <strong>{t("TABLE.CHANGE-LOG.TITLE-HEADER")}</strong>
                    </h4>
                    <h5 className="h5-stl" style={{ margin: "15px 5px" }}>
                      {t("TABLE.CHANGE-LOG.HEADER-TOTAL")} {changeLogLength}
                    </h5>
                  </div>
                </div>
                <div className="flex-stl flex-center">
                  <div>
                    <img
                      alt=""
                      className="imgSearch"
                      src={search}
                      onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                      style={{ width: "50px", height: "50px" }}
                    ></img>
                  </div>

                  <div onClick={handleClick}>
                    <FontAwesomeIcon className="grayBackgr" icon={faBars} style={{ width: "50px", height: "50px" }} />
                  </div>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                    <MenuItem
                      onClick={() => {
                        history.go(0);
                      }}
                      style={{ fontSize: "15px" }}
                    >
                      <img alt="" src={Reloadimg} style={{ width: "20px", marginRight: "10px" }}></img>
                      {t("ALL.MENU.RELOAD")}
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setOpenTransferModal(true);
                      }}
                      style={{ fontSize: "15px" }}
                    >
                      <img alt="" src={preferencesImg} style={{ width: "20px", marginRight: "10px" }}></img>
                      {t("ALL-BUTTON-PREFERENCES")}
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setOpenExport(true);
                      }}
                      style={{ fontSize: "15px" }}
                    >
                      <img alt="" src={exportImg} style={{ width: "20px", marginRight: "10px" }} />
                      {t("NAV-ALL-ITEM3")}
                      <span
                        style={{
                          background: "var( --color-accepted)",
                          marginLeft: "6px",
                          color: "var(--color-main-text)",
                          borderRadius: "20px",
                          padding: "4px 8px",
                          fontSize: "12px",
                        }}
                      >
                        <b>{changeLogAllSelected ? changeLogLength : changeLogListID?.length}</b>
                      </span>
                    </MenuItem>
                    <MenuItem onClick={copy} style={{ fontSize: "15px" }}>
                      <img alt="" src={copyImg} style={{ width: "20px", marginRight: "10px" }}></img>
                      {t("ALL.MENU.COPY-LINK")}
                    </MenuItem>
                    {/* <MenuItem onClick={handleClose} style={{fontSize:'15px'}}>Logout</MenuItem> */}
                  </Menu>
                </div>
              </>
            )}
          </div>
        </Card.Header>
        <Card.Body>{RenderTabla1()}</Card.Body>
      </Card>
      <LoadingLogin open={loadExport} />
      <ChangeLogTableTransfer open={openTransferModal} setOpen={setOpenTransferModal} />
      {showModalExport()}
      {renderLateralContainerSearch()}
    </div>
  );
}
