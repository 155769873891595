import { useContext, useState } from "react";
import useGlobalAutocomplete from "./useGlobalAutocomplete";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import AutocompletelistGlobal from "./AutocompletelistGlobal";
import { Breadcrumb, Card, Nav } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import useGlobalList from "./useGlobalList";
import { Fade, ImageList, Collapse } from "@mui/material";
import { TransitionGroup } from "react-transition-group";
import BrandCardResponsive from "../TransferList/globalCards/BrandCardResponsive";
import { ImageListItem, Zoom, useMediaQuery } from "@material-ui/core";
import ProductCardResponsive from "../TransferList/globalCards/ProductCardResponsive";
import GroupCardResponsive from "../TransferList/globalCards/GroupCardResponsive";
import DistributorCardResponsive from "../TransferList/globalCards/DistributorCardResponsive";
import DistributorSetCardResponsive from "../TransferList/globalCards/DistributorSetCardResponsive";
import CustomerCardResponsive from "../TransferList/globalCards/CustomerCardResponsive";
import ContentsCardResponsive from "../TransferList/globalCards/ContentsCardResposnive";
import ReasonForExchangeCardResponsive from "../TransferList/globalCards/ReasonForExchangeCardResponsive";
import ReasonForRefusalCardResponsive from "../TransferList/globalCards/ReasonForRefusalCardResponsive";
import UsersCardResponsive from "../TransferList/globalCards/UserTableResponsive";
import { ApplicationContext } from "../../../context";
import ExchangesTableResponsive from "../TransferList/globalCards/ExchangesTableResponsive";
export default function GlobalList() {
  const { globalSearch, setGlobalSearch } = useContext(ApplicationContext);
  const matchesHi = useMediaQuery("(max-width:1400px)");
  const matches = useMediaQuery("(max-width:1180px)");
  const matchesPhone = useMediaQuery("(max-width:600px)");
  const [renderView, setRenderView] = useState("");

  const history = useHistory();
  const { t } = useTranslation();
  const global = useGlobalAutocomplete("/common/global-search?global_filter=", globalSearch, setGlobalSearch);
  const { callApis, options } = useGlobalList("/common/global-search?global_filter=", globalSearch, setGlobalSearch);
  return (
    <>
      <Nav variant="tabs" defaultActiveKey="1">
        <Nav.Item>
          <Nav.Link
            eventKey="1"
            style={{ color: "var(--color-main-text)" }}
            onClick={() => {
              setRenderView("Global");
            }}
          >
            {t("GLOBAL-SEARCH")}
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Card style={{ padding: "12px" }}>
        <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
          <AutocompletelistGlobal {...global} />

          <div onClick={callApis} className="statusChangeButton pdf" style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
            <SearchIcon />
            <h4 className="h4-stl" style={{ padding: "13px 10px 5px" }}>
              {t("SEARCH.BRAND.SEARCH")}
            </h4>
          </div>
        </div>
        <ImageList variant="masonry" cols={matchesHi ? (matches ? (matchesPhone ? 1 : 2) : 3) : 5} gap={8} sx={{ marginBottom: "10px" }}>
          {options?.map((row: any, index: number) => (
            <Fade in timeout={400}>
              <ImageListItem style={{ marginBottom: "10px" }}>
                {row.resource === "brand" ? (
                  <BrandCardResponsive render={row.item} />
                ) : row.resource === "products" ? (
                  <ProductCardResponsive render={row.item} />
                ) : row.resource === "groups" ? (
                  <GroupCardResponsive render={row.item} />
                ) : row.resource === "distributors" ? (
                  <DistributorCardResponsive render={row.item} />
                ) : row.resource === "distributors-set" ? (
                  <DistributorSetCardResponsive render={row.item} />
                ) : row.resource === "customer" ? (
                  <CustomerCardResponsive render={row.item} />
                ) : row.resource === "contents" ? (
                  <ContentsCardResponsive render={row.item} />
                ) : row.resource === "reasons_for_exchange" ? (
                  <ReasonForExchangeCardResponsive render={row.item} />
                ) : row.resource === "reasons_for_refusal" ? (
                  <ReasonForRefusalCardResponsive render={row.item} />
                ) : row.resource === "users" ? (
                  <UsersCardResponsive render={row.item} />
                ) : row.resource === "exchanges" ? (
                  <ExchangesTableResponsive render={row.item} />
                ) : null}{" "}
              </ImageListItem>
            </Fade>
          ))}
        </ImageList>
      </Card>
    </>
  );
}
