import { useContext } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faPowerOff, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { changeUserLanguage } from "../../../api/services/user_services";
import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { IRootState } from "../../../store/redux";
import { useDispatch } from "react-redux";
import { clearState, setProfile } from "../../../store/redux/actions/profile_actions";
import FacomMenuLogo from "../../../images/facomMenuLogo.png";
import MenuEnglish from "../../../images/MenuEnglish.png";
import MenuFrench from "../../../images/MenuFrance.png";
import { typeUserData } from "../../../constants/userTypes";
import { clearTables } from "../../../helpers/clearTablesStorage";
import useToastMui from "../../../hooks/Toasts/useToastMui";
import { Avatar, Box, Stack } from "@mui/material";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import CheckIcon from "@mui/icons-material/Check";

interface IProps {
  setOpenSearch: (value: boolean) => void;
  handleClose: any;
  setAnchorEl: any;
  anchorEl: any;
  opend: boolean;
}
export default function NavUserMenu({ setOpenSearch, anchorEl, handleClose, setAnchorEl, opend }: IProps) {
  const { t, i18n } = useTranslation();
  //Redux
  const dispatch = useDispatch();
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { accounts, sub } = profile;
  const { setErrorCheckSession, setChangeLanguage } = useContext(ApplicationContext);

  const { handleCreateToast } = useToastMui();
  const onClick = () => {
    setAnchorEl(null);
    setErrorCheckSession(false);
    dispatch(clearState());
  };
  const changelanguageEnglish = async () => {
    try {
      await changeUserLanguage(`${sub}`, "English");
      i18n.changeLanguage("en");
      clearTables();
      dispatch(
        setProfile({
          languaje: "en",
        })
      );
      window.location.reload();
      setChangeLanguage((prev: boolean) => !prev);
    } catch {
      handleCreateToast(t("ERROR-ALERT"), "error");
    }
  };
  const changelanguageFrench = async () => {
    try {
      await changeUserLanguage(`${sub}`, "French");
      i18n.changeLanguage("fr");
      clearTables();
      dispatch(
        setProfile({
          languaje: "fr",
        })
      );
      window.location.reload();
      setChangeLanguage((prev: boolean) => !prev);
    } catch {
      handleCreateToast(t("ERROR-ALERT"), "error");
    }
  };

  return (
    <Menu
      id="fade-menu"
      MenuListProps={{
        "aria-labelledby": "fade-button",
      }}
      anchorEl={anchorEl}
      open={opend}
      onClose={handleClose}
      TransitionComponent={Fade}
    >
      <Link
        to={`/user-info/${sub}`}
        onClick={() => handleClose()}
        style={{
          textDecoration: "none",
          color: "var(--color-main-text)",
        }}
      >
        <MenuItem
          sx={{ width: 320 }}
          style={{
            fontSize: "15px",
            borderBottom: "solid 1px var(--color-border)",
          }}
        >
          <Box display="flex" flexDirection="column" alignItems="center" width="100%">
            {profile?.profile_picture ? (
              <Avatar alt="usr-img" src={profile.profile_picture} sx={{ width: 64, height: 64 }} />
            ) : (
              <FontAwesomeIcon icon={faUser} style={{ cursor: "pointer", fontSize: 40 }} />
            )}
            <h6 style={{ fontSize: "12px", marginTop: "4px" }}>
              {profile.nameRolSelected === typeUserData.admin
                ? `${t("ROLES-ADMIN")}`
                : profile.nameRolSelected === typeUserData.distributor
                ? `${t("ROLES-DISTRIBUTOR")}`
                : profile.nameRolSelected === typeUserData.group
                ? `${t("ROLES-GROUP")}`
                : profile.nameRolSelected === typeUserData.afterSales
                ? `${t("ROLES-AFTERSALES")}`
                : profile.nameRolSelected === typeUserData.viewOnly
                ? `${t("ROLES-VIEW-ONLY")}`
                : `${t("ROLES-CUSTOMERS")}`}
            </h6>

            <h6 style={{ fontSize: "15px", marginTop: -4 }}>
              <strong>{profile.full_name}</strong>
            </h6>
            <h6 style={{ fontSize: "15px", marginTop: -4 }}>{profile.email}</h6>
          </Box>
        </MenuItem>
      </Link>
      <MenuItem
        style={{
          fontSize: "15px",
          borderBottom: "solid 1px var(--color-border)",
          marginLeft: "4px",
        }}
      >
        <Stack direction="row" spacing={2}>
          <img
            alt=""
            onClick={() => changelanguageEnglish()}
            src={MenuEnglish}
            style={
              i18n.language === "en"
                ? {
                    width: "28px",
                    height: "28px",
                    borderBottom: "solid 1px #333",
                  }
                : { width: "28px", height: "28px" }
            }
          ></img>

          <img
            alt=""
            onClick={() => changelanguageFrench()}
            src={MenuFrench}
            style={
              i18n.language === "en"
                ? { width: "28px", height: "28px" }
                : {
                    width: "28px",
                    height: "28px",
                    borderBottom: "solid 1px #333",
                  }
            }
          ></img>
        </Stack>
      </MenuItem>
      <MenuItem
        style={{
          fontSize: "15px",
          borderBottom: "solid 1px var(--color-border)",
        }}
        onClick={() => {
          handleClose();
          setOpenSearch(true);
        }}
      >
        <Box sx={{ display: "flex", alignContent: "center", alignItems: "center" }}>
          <Box width="35px" display="flex" justifyContent="center">
            <LockOpenRoundedIcon sx={{ fontSize: 28 }} />
          </Box>
          <h6 style={{ marginLeft: "10px", marginBottom: 0, fontSize: "14px" }}>{t("ALL-BUTTON-RESET-PASSWORD")}</h6>
        </Box>
      </MenuItem>
      <Box maxHeight={200} overflow="scroll">
        {accounts?.map((account: any, index: number) => {
          const isItemSelected: boolean = account.sub === sub;
          return (
            <a href="/" style={{ textDecoration: "none", color: "var(--color-main-text)" }}>
              <MenuItem
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
                onClick={() => {
                  try {
                    dispatch(setProfile({ token: account.token }));
                    dispatch(
                      setProfile({
                        email: account.email,
                        full_name: account.full_name,
                        roles: account.roles,
                        roles_data: account.roles_data,
                        sub: account.sub,
                        username: account.username,
                        nameRolSelected: account?.roles_data[0]?.name,
                        profile_picture: account.profile_picture,
                      })
                    );
                    window.location.replace("/");
                  } catch {}
                }}
              >
                <Box display="flex" width="100%" alignItems="center">
                  <Box>
                    {account?.profile_picture ? (
                      <Avatar alt="usr-img" src={account.profile_picture} sx={{ width: 35, height: 35 }} />
                    ) : (
                      <Box width="35px" display="flex" justifyContent="center">
                        <FontAwesomeIcon icon={faUser} style={{ cursor: "pointer" }} />
                      </Box>
                    )}
                  </Box>
                  <Box display="flex" width="100%" alignItems="center">
                    <Box width="90%">
                      <h6 style={{ marginLeft: "10px", marginBottom: 4, fontSize: "14px" }}>
                        <strong>{account.full_name}</strong>
                      </h6>
                      <h6 style={{ marginLeft: "10px", marginBottom: 0, fontSize: "14px" }}>{account.email}</h6>
                    </Box>
                    <Box width="10%">{isItemSelected && <CheckIcon />}</Box>
                  </Box>
                </Box>
              </MenuItem>
            </a>
          );
        })}
      </Box>
      <Link to="/add-account" style={{ textDecoration: "none" }}>
        <MenuItem
          sx={{
            fontSize: "14px",
            borderBottom: "solid 1px var(--color-border)",

            color: "var(--color-main-text)",
          }}
          onClick={handleClose}
        >
          <Box sx={{ display: "flex", alignContent: "center", alignItems: "center" }}>
            <Box width="35px" display="flex" justifyContent="center">
              <AddRoundedIcon sx={{ fontSize: 28 }} />
            </Box>
            <h6 style={{ marginLeft: "10px", marginBottom: 0, fontSize: "14px" }}>{t("MENU-ADD-ACCOUNT")}</h6>
          </Box>
        </MenuItem>
      </Link>

      <MenuItem
        onClick={() => onClick()}
        style={{
          display: "flex",
          alignContent: "center",
        }}
      >
        <Box width="35px" display="flex" justifyContent="center">
          <LogoutRoundedIcon sx={{ fontSize: 28 }} />
        </Box>
        <h6 style={{ marginLeft: "10px", marginBottom: 0, fontSize: "14px" }}>{t("ALL-BUTTON-QUIT")}</h6>
      </MenuItem>
    </Menu>
  );
}
