import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import "bootstrap/dist/css/bootstrap.css";
import { useTranslation } from "react-i18next";
export default function LoadingImageSkeleton() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="col-6">
        {/* {t("ALERT-LOADING-IMAGE")} */}
        <Stack spacing={1}>
          <Skeleton variant="rectangular" height={300} animation="wave" />
        </Stack>
      </div>
    </div>
  );
}
