import { faBoxes, faEdit, faExternalLinkAlt, faQuestionCircle, faPlay, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useContext } from "react";
import { Breadcrumb, Button, Card, Container, Dropdown, ListGroup, OverlayTrigger, Popover, Row } from "react-bootstrap";
import InputClear from "../atoms/InputClear";
import TextEditor from "../organisms/TextEditor";
import LateralContainer from "../organisms/LateralContainer";
import { CreateBrands } from "../../api/services/brand_services";
import FullLoading from "../atoms/FullLoading";
import ValidationAlert from "../atoms/ValidationAlert";
import { Link, useHistory } from "react-router-dom";
import { ApplicationContext } from "../../context/ApplicationContext";
import "../../constants/responsive.css";
//FILE
import FileAttachement from "../atoms/FileAttachement/FileAttachement";
import { IFileWithMeta } from "react-dropzone-uploader";

import { useTranslation } from "react-i18next";
import usePictures from "../../hooks/usePictures";
import useToastMui from "../../hooks/Toasts/useToastMui";
import { useMediaQuery } from "@mui/material";
export default function CreateBrand(props: any) {
  //Translations
  const { handleCreateToast } = useToastMui();
  const { t } = useTranslation();
  const { setBrandCode, setBrandName, openCreateBrand, setBrandId, setOpenCreateBrand } = useContext(ApplicationContext);
  const history = useHistory();
  //Info
  const [inputCode, setInputCode] = useState<string>("");
  const [inputName, setInputName] = useState<string>("");
  const [inputWebsite, setInputWebsite] = useState<string>("");
  const [inputDescription, setInputDescription] = useState<string>("");

  //For FILE ATTACHEMENT
  const [fileName, setFileName] = useState<string>("");
  const [currentReceipt, setCurrentReceipt] = useState<any>("5");
  const matches = useMediaQuery("(max-width:500px)");

  //LateralContainer
  const [showLateralContainer, setShowLateralContainer] = useState(false);
  const lateralContainer = (content: any) => {
    return (
      <LateralContainer
        show={showLateralContainer}
        toggleShow={() => setShowLateralContainer(!showLateralContainer)}
        content={content}
      ></LateralContainer>
    );
  };

  //Alerts
  const [warningAlertStatus, setWarningAlertStatus] = useState<boolean>(false);

  //API STATE
  const [loading, setLoading] = useState<boolean>(false);
  const [brandCreated, setBrandCreated] = useState<any>();

  const logo = usePictures();
  //! ------------------------------API CALLS--------------------------------//

  const createBrand = async (close: boolean) => {
    setLoading(true);
    if (inputName && inputCode) {
      try {
        // const response = CreateBrands(inputName, inputCode, inputWebsite, "Imagen", inputDescription);
        const response = CreateBrands(
          inputName,
          inputCode,
          inputWebsite,
          logo.picture ? logo.picture : { file_content: null, extension: null },
          inputDescription
        );
        const data = (await response).data;
        handleCreateToast(t("ALERT-BRAND-SUCCESS-CREATE"), "success");
        setBrandCreated(data);
        if (!close) {
          history.push(`/brand-info/${data?.result.id}`);
        } else {
          if (document.referrer) {
            history.go(-1);
          } else {
            history.push("/");
          }
        }
        return data;
      } catch (error: any) {
        console.log("error create brand");
        console.log(error);
        if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(`${error.response.data.message}`, "error");
      } finally {
        console.log("end");
        setLoading(false);
      }
    } else {
      alertWarning();
      setLoading(false);
    }
  };

  //Calling api from info page (product info)
  //Don't rediect
  const handleCreated = async () => {
    setLoading(true);
    if (inputName && inputCode) {
      try {
        // const response = CreateBrands(inputName, inputCode, inputWebsite, "Imagen", inputDescription);
        const response = CreateBrands(
          inputName,
          inputCode,
          inputWebsite,
          logo.picture ? logo.picture : { file_content: null, extension: null },
          inputDescription
        );
        const data = (await response).data;
        handleCreateToast(t("ALERT-BRAND-SUCCESS-CREATE"), "success");
        setBrandId(data.result.id);
        setBrandCode(data.result.code);
        setBrandName(data.result.name);
        setOpenCreateBrand(!openCreateBrand);
        return data;
      } catch (error: any) {
        console.log("error create brands");
        console.log(error);
        if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(`${error.response.data.message}`, "error");
      } finally {
        console.log("end");
        setLoading(false);
      }
    } else {
      alertWarning();
      setLoading(false);
    }
  };

  //! --------------------------END API CALLS--------------------------------//

  //? ------------------------------ALERTS--------------------------------//

  const alertWarning = () => {
    setWarningAlertStatus(true);
  };

  const RenderWarnings = () => {
    return (
      <div>
        <ValidationAlert message={t("ALERT-GLOBAL-FILL-FIELDS")} variant="warning" />
      </div>
    );
  };

  //? ------------------------------END ALERTS--------------------------------//

  const handleChangeStatus = async (meta: IFileWithMeta, status: string, files: IFileWithMeta[]) => {
    if (status === "done") {
      setCurrentReceipt(files[0].file);
      setFileName(files[0].file.name);
    }
  };

  return (
    <div>
      {lateralContainer(<Container></Container>)}

      <Breadcrumb>
        <Link to="/brands-list">
          <Breadcrumb.Item href="/product">{t("TABLE.BRAND.TITLE.HEADER-LINK")}</Breadcrumb.Item>
        </Link>
        <FontAwesomeIcon icon={faPlay} style={{ width: "10px", margin: "5px 15px" }} />
        <Breadcrumb.Item href="#">{t("TABLE.BRAND.TITLE.HEADER-SUB-LINK")}</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header>
          <div className="flex-warranty flex-between">
            <div className="container-warranty flex-start">
              <FontAwesomeIcon className="icon_size" icon={faBoxes} />
              <div>
                <h4 className="h4-stl" style={{ margin: "15px 5px" }}>
                  <strong>{t("TABLE.BRAND.TITLE.HEADER-SUB-LINK")}</strong>
                </h4>
              </div>
            </div>

            <div className="container-warranty flex-end  wrap-warranty">
              {!matches && !props.Created && (
                <div>{!props.Created && <Button onClick={() => createBrand(false)}>{t("CREATE.ALL.BUTTON-SAVE")}</Button>}</div>
              )}
              {!props.Created ? (
                !matches ? (
                  <Button
                    variant="outline-secondary"
                    style={{ minWidth: "150px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      createBrand(true);
                    }}
                  >
                    {t("CREATE.ALL.BUTTON-SAVE-CLOSE")}
                  </Button>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle variant="primary">{t("CREATE.ALL.BUTTON-SAVE")}</Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          e.preventDefault();
                          createBrand(false);
                        }}
                      >
                        {t("CREATE.ALL.BUTTON-SAVE")}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => createBrand(true)}>{t("CREATE.ALL.BUTTON-SAVE-CLOSE")}</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )
              ) : (
                <Button variant="outline-secondary" style={{ minWidth: "150px" }} onClick={handleCreated}>
                  {t("CREATE.ALL.BUTTON-SAVE-CLOSE")}
                </Button>
              )}
              <div onClick={() => setShowLateralContainer(!showLateralContainer)}>
                <FontAwesomeIcon className="grayBackgr" icon={faQuestionCircle} style={{ width: "50px", height: "50px" }} />
              </div>

              {!props.Created ? (
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    if (document.referrer) {
                      history.go(-1);
                    } else {
                      history.push("/");
                    }
                  }}
                >
                  {t("CREATE.ALL.BUTTON-CLOSE")}
                </Button>
              ) : (
                <Button variant="outline-secondary" onClick={() => setOpenCreateBrand(!openCreateBrand)}>
                  {t("CREATE.ALL.BUTTON-CLOSE")}
                </Button>
              )}
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="row ">
            <div className="col-12 col-lg-8">
              <Card>
                <ListGroup variant="flush">
                  <ListGroup.Item>{t("TABLE.BRAND-HEADER-TITLE-IDENTIFICATION")}</ListGroup.Item>
                  <ListGroup.Item>
                    <div className="flex-inputs-responsive">
                      <div className="full-width">
                        <h6 style={{ margin: "28px 0" }}>
                          {t("TABLE.BRAND.TITLE-CODE-ON-LIST")}
                          <span style={{ color: "var(--color-required)" }}>*</span>{" "}
                        </h6>
                        <div className="flex-inputs-responsive-info">
                          <div className="full-width">
                            <InputClear
                              type="text"
                              name="Code"
                              placeholder=""
                              currentValue={inputCode}
                              handleOnInputValue={(data: string) => {
                                setInputCode(data);
                              }}
                            />
                          </div>
                          <div>
                            <OverlayTrigger
                              trigger="hover"
                              key={"bottom"}
                              placement={"bottom"}
                              overlay={
                                <Popover id={`popover-positioned-bottom`}>
                                  <Popover.Header as="h3">{t("TABLE.BRAND.TITLE-CODE-ON-LIST")}</Popover.Header>
                                  <Popover.Body>Supplier code help (on form)</Popover.Body>
                                </Popover>
                              }
                            >
                              <div>
                                <FontAwesomeIcon size="1x" className="pointer" icon={faQuestionCircle} />
                              </div>
                            </OverlayTrigger>
                          </div>
                        </div>
                        {!inputCode && warningAlertStatus && <RenderWarnings />}
                      </div>
                      <div className="full-width">
                        <h6 style={{ margin: "28px 0" }}>
                          {t("TABLE.BRAND.TITLE-NAME")}
                          <span style={{ color: "var(--color-required)" }}>*</span>{" "}
                        </h6>
                        <InputClear
                          type="text"
                          name="Name"
                          placeholder=""
                          currentValue={inputName}
                          handleOnInputValue={(data: string) => {
                            setInputName(data);
                          }}
                        />
                        {!inputName && warningAlertStatus && <RenderWarnings />}
                      </div>
                    </div>
                    <div className="c3">
                      <div className="col">
                        <h6 style={{ margin: "28px 0" }}>{t("TABLE.BRAND.TITLE-WEBSITE")}</h6>
                        <div className="flex-inputs-responsive-info">
                          <div className="full-width">
                            <InputClear
                              type="text"
                              name="Website"
                              placeholder=""
                              currentValue={inputWebsite}
                              handleOnInputValue={(data: string) => {
                                setInputWebsite(data);
                              }}
                            />
                          </div>
                          <div>
                            <a href={inputWebsite} target="_blank" style={{ textDecoration: "none", color: "#222" }} rel="noreferrer">
                              <FontAwesomeIcon icon={faExternalLinkAlt} size="1x" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="c3">
                        <h6 style={{ margin: "28px 0" }}>{t("CREATE.BRAND.INPUT-DESCRIPTION")}</h6>
                        <TextEditor
                          height={"200px"}
                          handleChange={(content: any) => {
                            setInputDescription(content);
                          }}
                        ></TextEditor>
                      </div>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </div>
            <div className="col-12 col-lg-4">
              <Card style={{}}>
                <ListGroup variant="flush">
                  <ListGroup.Item>{t("CREATE.BRAND.CARD-HEADER-TITLE-LOGO")}</ListGroup.Item>
                  <ListGroup.Item>
                    <div className="row">
                      <div className="col">
                        <h6 style={{ margin: "28px 0" }}>{t("CREATE.BRAND.INPUT-LOGO")}</h6>
                        <div className="row">
                          {/* <div className="col"><input type="file" name="" id="" /></div> */}
                          <FileAttachement fileName={logo.fileNameImage} handleChangeStatus={logo.handleChangeStatus} />
                          {/* <div className="col">
                            <FontAwesomeIcon icon={faEdit} style={{ marginRight: 10 }} />
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </div>
          </div>
          <FullLoading open={loading} />
        </Card.Body>
      </Card>
    </div>
  );
}
