import * as React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { setInLocalStorage, setInLocalStorageArray } from "../../../helpers/local-storage-manager";

function not(a: readonly any[], b: readonly any[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly any[], b: readonly any[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function TransferList(props: any) {
  const [checked, setChecked] = React.useState<readonly number[]>([]);

  const { itemsLeft, itemsRight, setItemsLeft, setItemsRight, keyValue, keyValueRight } = props;

  const leftChecked = intersection(checked, itemsLeft);
  const rightChecked = intersection(checked, itemsRight);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setItemsRight(itemsRight.concat(itemsLeft));
    setItemsLeft([]);
    setInLocalStorageArray(keyValue, []);
    setInLocalStorageArray(keyValueRight, itemsRight.concat(itemsLeft));
  };

  const handleCheckedRight = () => {
    setItemsRight(itemsRight.concat(leftChecked));
    setItemsLeft(not(itemsLeft, leftChecked));
    setChecked(not(checked, leftChecked));
    setInLocalStorageArray(keyValue, not(itemsLeft, leftChecked));
    setInLocalStorageArray(keyValueRight,itemsRight.concat(leftChecked));
  };

  const handleCheckedLeft = () => {
    setItemsLeft(itemsLeft.concat(rightChecked));
    setItemsRight(not(itemsRight, rightChecked));
    setChecked(not(checked, rightChecked));
    setInLocalStorageArray(keyValue, itemsLeft.concat(rightChecked));
    setInLocalStorageArray(keyValueRight,not(itemsRight, rightChecked));
  };

  const handleAllLeft = () => {
    setItemsLeft(itemsLeft.concat(itemsRight));
    setItemsRight([]);
    setInLocalStorageArray(keyValue, itemsLeft.concat(itemsRight));
    setInLocalStorageArray(keyValueRight,[]);
  };

  const customList = (items: readonly any[]) => (
    <Paper sx={{ width: 200, height: 230, overflow: "auto" }}>
      <List dense component="div" role="list">
        {items.map((value: any) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>{customList(itemsLeft)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={itemsLeft.length === 0}
            aria-label="move all itemsRight"
          >
            ≫
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected itemsRight"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected itemsLeft"
          >
            &lt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={itemsRight.length === 0}
            aria-label="move all itemsLeft"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(itemsRight)}</Grid>
    </Grid>
  );
}
