import { AxiosRequestConfig, AxiosResponse } from "axios";
import { axios as http } from "../../frameworks_and_drivers/Axios";
import { BACKEND_URL } from "../../config/settings.json";
import { IBrandsList, IBrandsListID } from "../interfaces/interfaz_brands";
import { downloadFileFromResponse } from "./helps";
import HelpService from "../HelpService";
import { loadAbort } from "../../helpers/loadAbort";
import { IPicturesAPI } from "../interfaces/globalInterfaces";
const opts2 = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};
// export const getAllBrands = async (props?: any) => {
//   const { query } = props;
//   const URL =
//     `${BACKEND_URL}/brands/get-all?` + HelpService.getQueryString(query);
//   const response = await http.get<IBrandsList>(URL);
//   return response;
// };
export const getAllBrands = (props?: any) => {
  const controller = loadAbort();
  const { query } = props;
  const URL = `${BACKEND_URL}/brands/get-all?` + HelpService.getQueryString(query);
  return {
    call: http.get<IBrandsList>(URL, { signal: controller.signal }),
    controller,
  };
};

export const getAllBrandsById = async (objectId: string) => {
  const URL = `${BACKEND_URL}/brands/${objectId}/get-one`;
  const response = await http.get<IBrandsListID>(URL);
  return response;
};

export const getAllCoolBrandsById = (objectId: string) => {
  const controller = loadAbort();
  const URL = `${BACKEND_URL}/brands/${objectId}/get-one`;
  return {
    call: http.get<IBrandsListID>(URL, { signal: controller.signal }),
    controller,
  };
};

export const updateBrands = async (
  // let body= new FormData()
  // body.append("name", name)
  // body.append("code", code)
  // body.append("website", website)
  // body.append( "logo", logo)
  // body.append( "description", description)

  objectId: string,
  name: string,
  code: string,
  website: string,
  logo: IPicturesAPI,
  description: string
) => {
  const body = {
    name: name,
    code: code,
    website: website,
    logo: logo,
    description: description,
  };
  const URL = `${BACKEND_URL}/brands/${objectId}/update-one`;
  // const response = await http.post(URL,body,opts2);
  const response = await http.put(URL, body);
  return response;
};

export const updateCoolBrands = (
  objectId: string,
  name: string,
  code: string,
  website: string,
  logo: IPicturesAPI | undefined,
  description: string
) => {
  let body: any = {
    name: name,
    code: code,
    website: website,
    description: description,
    logo: logo,
  };
  const controller = loadAbort();
  const URL = `${BACKEND_URL}/brands/${objectId}/update-one`;
  return {
    call: http.put(URL, body, { signal: controller.signal }),
    controller,
  };
};

export const CreateBrands = async (name: string, code: string, website: string, logo: IPicturesAPI, description: string) => {
  // let body= new FormData()
  // body.append("name", name)
  // body.append("code", code)
  // body.append("website", website)
  // body.append( "logo", logo)
  // body.append( "description", description)

  const body = {
    name: name,
    code: code,
    website: website,
    logo: logo,
    description: description,
  };
  const URL = `${BACKEND_URL}/brands/create-one`;
  const response = await http.post(URL, body);
  // const response = await http.post(URL,body,opts2);
  return response;
};

export const deleteBrands = async (objectId: string) => {
  const URL = `${BACKEND_URL}/brands/${objectId}/delete-one`;
  const response = await http.put(URL);
  return response;
};

export const deleteCoolBrands = (objectId: string) => {
  const controller = loadAbort();
  const URL = `${BACKEND_URL}/brands/${objectId}/delete-one`;
  return {
    call: http.put(URL, {}, { signal: controller.signal }),
    controller,
  };
};

export const deleteManyBrands = (ids: string[]) => {
  const controller = loadAbort();
  const URL = `${BACKEND_URL}/brands/delete-many`;
  const body = {
    items: ids,
  };
  return {
    call: http.put(URL, body, { signal: controller.signal }),
    controller,
  };
};

export const getExportGroup = async (type: string) => {
  const URL = `${BACKEND_URL}/groups/generate-report`;
  const opts: AxiosRequestConfig = {
    params: {
      export_type: type,
    },
    responseType: "blob",
  };
  const response = await http.get(URL, opts);
  const responseDownload = downloadFileFromResponse(response, type, "Groups");
  return responseDownload;
};

export const getExportBrand = async (type: string, name: string) => {
  const URL = `${BACKEND_URL}/brands/generate-report`;
  const opts: AxiosRequestConfig = {
    params: {
      export_type: type,
    },
    responseType: "blob",
  };
  const response = await http.get(URL, opts);
  const responseDownload = downloadFileFromResponse(response, type, name);
  return responseDownload;
};

export const getExportBrandByID = async (type: string, ids: string[], name: string, query?: any) => {
  const URL = `${BACKEND_URL}/brands/create-report?` + HelpService.getQueryString(query);
  const body = {
    file_type: type,
    search: ids,
  };
  const opts: AxiosRequestConfig = {
    responseType: "blob",
  };
  const response = await http.post(URL, body, opts);
  const responseDownload = downloadFileFromResponse(response, type, name);
  return responseDownload;
};
//Bulks

interface IBulkBrand {
  name?: string;
  website?: string;
  logo?: any;
  description?: string;
  search?: string[];
}
export const updateInBulkBrands = async (body: IBulkBrand) => {
  const URL = `${BACKEND_URL}/brands/bulk-update`;

  const response = await http.put(URL, body);
  return response;
};
