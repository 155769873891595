import { Profile } from "../actions/profile_actions";
import { profileReducer } from "./profile_reducers";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const PERSIST_CONFIG = {
  key: "root",
  storage,
};

export type InitialState = {
  profile: Profile;
};

const INITIAL_STATE: InitialState = {
  profile: {
    token: "",
    email: "",
    languaje: "fr",
    full_name: "",
    roles: [],
    roles_data: [
      {
        id: "",
        name: "",
        views_enabled: "",
      },
    ],
    sub: "",
    username: "",
    nameRolSelected: "",
    profile_picture: "",
  },
};

const REDUCERS = {
  profileReducer: persistReducer(PERSIST_CONFIG, profileReducer),
};

export { INITIAL_STATE, PERSIST_CONFIG };
export default REDUCERS;
