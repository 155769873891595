import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Pagination from "@mui/material/Pagination";

import Table from "react-bootstrap/Table";

import redCircle from "../../../images/redCircle.svg";
import secondCircle from "../../../images/secondCircle.svg";
import grayCircle from "../../../images/GrayCircle.svg";
import greenCircle from "../../../images/greenCircle.svg";
import purpleCircle from "../../../images/purpleCircle.svg";
import yellowCircle from "../../../images/yellowCircle.svg";
import blueCircle from "../../../images/blueCircle.svg";
import orangeCircle from "../../../images/orangeCircle.svg";

import "./exchangesHistInfo.css";
import { t } from "i18next";
import { fromInfoToTranslatedStatus } from "../../../utilities/excangesStatusTranslations";

interface ITableExchangesProps {
  searchState: boolean;
  type?: string;
  UsePlanText?: string;
  inputVersion?: string;
  inputStatus?: string;
  inputUsername?: string;
  inputProduct?: string;
  inputBrand?: string;
  inputEvaluation?: string;
  inputDCode?: string;
  inputDGroupCode?: string;
  inputDClientCode?: string;
  inputDate?: string;
  inputToSort?: string;
  exchangesArray: any;
}

export default function ExchangesHistInfo(props: ITableExchangesProps) {
  //Inputs

  const [typeSort, setTypeSort] = useState("");

  //TABLE
  const [currentPage, setCurrentPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };
  const [rowsPerPage, setRowsPerPage] = useState(5);

  //FILTRO
  let removeAccents = (str: string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  let filteredTableByStatus = [{}];
  if (props.exchangesArray) {
    filteredTableByStatus = props.exchangesArray;
  }
  // Sort

  const [countVersion, setCountVersion] = useState(1);
  const incrementCountUsername = () => {
    setCountVersion(countVersion + 1);

    if (countVersion === 1) {
      setTypeSort("sortVersionUp");
    }
    if (countVersion === 2) {
      setTypeSort("sortVersionDown");
      setCountVersion(1);
    }
  };

  let iconVersion = faChevronDown;

  if (typeSort === "sortVersionDown") {
    filteredTableByStatus = filteredTableByStatus.sort((a: any, b: any) => (a.version > b.version ? 1 : -1));
    iconVersion = faChevronUp;
  }
  if (typeSort === "sortVersionUp") {
    filteredTableByStatus = filteredTableByStatus.sort((a: any, b: any) => (a.version < b.version ? 1 : -1));
    iconVersion = faChevronDown;
  }

  // let versionArray = new Array(props.exchangesArray.length-1)
  // for (var i = 1; i < props.exchangesArray.length; i++) {
  //     n += i;
  //     mifuncion(n);
  //  }
  return (
    <div>
      <div className="row ">
        <Table
          responsive="xl"
          striped
          hover
          style={{
            fontSize: "12px",
            border: "2px solid var(--color-input-border)",
            maxWidth: "100vw",
          }}
        >
          {/* TABLE HEADER */}
          <thead
            style={{
              marginBottom: "10px",
              textAlign: "left",
              backgroundColor: "rgba(0, 0, 0, 0.03)",
            }}
          >
            <tr>
              <th>
                Version{" "}
                {props.inputToSort === "version" && (
                  <FontAwesomeIcon
                    onClick={() => {
                      incrementCountUsername();
                    }}
                    icon={iconVersion}
                    style={{ cursor: "pointer", marginLeft: "5px" }}
                  />
                )}
              </th>
              <th>{t("ALL.GENERAL-CHANGE-LOG.TITLE-USER")}</th>
              <th>{t("ALL.GENERAL-EXCHANGE.TITLE-DATE")}</th>
              <th>{t("ALL.GENERAL-EXCHANGE.TITLE-STATUS")}</th>
            </tr>
          </thead>
          <tbody>
            {/* TABLE BODY */}
            {(currentPage === 1
              ? filteredTableByStatus.slice(currentPage - 1, rowsPerPage)
              : filteredTableByStatus.slice(currentPage * rowsPerPage - rowsPerPage, currentPage * rowsPerPage)
            ).map((row: any) => (
              <tr
                style={{
                  backgroundColor: "var(--color-white)",
                  textAlign: "left",
                }}
              >
                <td style={{ paddingLeft: "15px" }}>{row.version}</td>
                <td style={{ paddingLeft: "15px" }}>{row.user}</td>

                <td style={{ paddingLeft: "15px" }}>{row.created_at}</td>

                <td style={{ paddingLeft: "15px" }}>
                  {row.status === "Refused" ? (
                    <img alt="" src={redCircle} style={{ paddingRight: "5px" }}></img>
                  ) : row.status === "Draft" ? (
                    <img alt="" src={grayCircle} style={{ paddingRight: "5px" }}></img>
                  ) : row.status === "Abandonned" ? (
                    <img alt="" src={secondCircle} style={{ paddingRight: "5px" }}></img>
                  ) : row.status === "Submitted" ? (
                    <img alt="" src={purpleCircle} style={{ paddingRight: "5px" }}></img>
                  ) : row.status === "Exchanged" ? (
                    <img alt="" src={greenCircle} style={{ paddingRight: "5px" }}></img>
                  ) : row.status === "Accepted" ? (
                    <img alt="" src={yellowCircle} style={{ paddingRight: "5px" }}></img>
                  ) : row.status === "Available" ? (
                    <img alt="" src={blueCircle} style={{ paddingRight: "5px" }}></img>
                  ) : row.status === "Recycled" ? (
                    <img alt="" src={redCircle} style={{ paddingRight: "5px" }}></img>
                  ) : row.status === "Analysis" ? (
                    <img alt="" src={orangeCircle} style={{ paddingRight: "5px" }}></img>
                  ) : (
                    ""
                  )}
                  {fromInfoToTranslatedStatus(row.status)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Pagination
            style={{ paddingTop: 10 }}
            count={Math.ceil(filteredTableByStatus.length / rowsPerPage)}
            page={currentPage}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
            color="primary"
            boundaryCount={3}
            siblingCount={4}
          />
        </div>
        {/* count -- numero de páginas
                        page -- pagina actual
                        onchange cambio de paginacion */}
      </div>
    </div>
  );
}
