import { IDistributorsListDOM, IDistributorsListPage } from "../../../api/interfaces/interfaz_distributor";

export const createAddapterDistributor = (item: IDistributorsListPage[]): IDistributorsListDOM[] => {
  const array: IDistributorsListDOM[] = [];
  item?.map((item: IDistributorsListPage) => {
    return array.push({
      address: item.address,
      city_id: item.city_id,
      client_account_number: item.client_account_number,
      code: item.code,
      description: item.description,
      email: item.email,
      evaluation: item.evaluation,
      exchanges: item.exchanges,
      group: item.group,
      group_data: item.group_data,
      id: item.id,
      is_visible: item.is_visible,
      members_of_set: item.members_of_set,
      logo: item.logo,
      name: item.name,
      responsible_email: item.responsible_email,
      delivery_account_number: item.delivery_account_number,
      is_web: item.is_web,
      group_code: item.group_data.code,
      group_name: item.group_data.name,
      distributor_city: item.address.city,
      distributor_postal_code: item.address.postal_code,
      distributor_phone: item.address.phone,
      distributorAddress: item.address.address,
      distributorCoordinates: item.address.coordinates,
      distributorMapUrl: item.address.map_url
    });
  });
  return array;
};
