import React, { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Container, Dropdown } from "react-bootstrap";

import Pagination from "@mui/material/Pagination";
import Table from "react-bootstrap/Table";
import { Link, useHistory } from "react-router-dom";
import facomMinLogo from "../../../images/facomMinLogo.png";

//API
import { ApplicationContext } from "../../../context/ApplicationContext";
import { t } from "i18next";
import checkedimg from "../../../images/checked.svg";
import uncheckedimg from "../../../images/unchecked.svg";
import uncheckedpage from "../../../images/checkPage.svg";
import grayChecked from "../../../images/grayCheck.png";
import { Checkbox, useMediaQuery } from "@mui/material";
import LoadingSkeletonTables from "../LoadingSkeleton/LoadingSkeletonTables";
import BrandsTableFilters from "../Search/BrandsTableFilters";
import ImagesApi from "../../pages/ExchangesInfo/ImagesApi";
import { IRowHeaderBrand } from "../../templates/TableTranfers/BrandTableTransfer";
import BrandTableResponsive from "../../molecules/TransferList/responsiveTables/BrandTableResponsive";

export interface IRowBrandFromAPI {
  id: string;
  code: string;
  name: string;
  logo: string;
  website: string;
  [index: string]: string;
}

interface IRefusalProps {
  rowsPerPage: number;
  onClick?: any;
  UsePlanText: string;
  inputToSort: string;
  linkTo: string;
  Create?: boolean;
  showSearchTable?: boolean;
  brandsArray: any;
  load: boolean;
  totalBrands: number;
  currentPage: number;
  handleChange: any;

  //filerts
  handleSearch: any;

  incrementCount?: any;
  typeSort?: string;
  setDropDownWhySort?: any;
}

export default function TableBrands(props: IRefusalProps) {
  const { brandsArray, totalBrands, currentPage, handleChange, incrementCount, typeSort, inputToSort, setDropDownWhySort } = props;
  const { handleSearch } = props;
  const {
    setBrandCode,
    setBrandId,
    setBrandName,
    setOpenBrandTable,
    openBrandTable,
    setBrandToDelete,
    brandsListID,
    setBrandsListID,
    brandTableTransferData,
    setBrandsAllSelected,
  } = useContext(ApplicationContext);
  const history = useHistory();
  const matches = useMediaQuery("(max-width:780px)");
  const [defaultChecked, setDefaultChecked] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  //TABLE

  const pageSize = props.rowsPerPage;

  const tableBrandInitial: IRowHeaderBrand[] = [
    {
      field: "dropdown",
      headerName: "",
      sort: "",
    },
    {
      field: "code",
      headerName: t("SEARCH.BRAND.CODE"),
      sort: t("SEARCH.BRAND.CODE"),
    },
    {
      field: "name",
      headerName: t("SEARCH.BRAND.NAME"),
      sort: t("SEARCH.BRAND.NAME"),
    },
    {
      field: "website",
      headerName: t("SEARCH.BRAND.WEBSITE"),
      sort: t("SEARCH.BRAND.WEBSITE"),
    },
    {
      field: "logo",
      headerName: t("TABLE.BRAND.TITLE-LOGO"),
      sort: t("SEARCH.BRAND.LOGO"),
    },
  ];
  const [tabla, setTabla] = useState<IRowHeaderBrand[]>(tableBrandInitial);
  useEffect(() => {
    setTabla(tableBrandInitial.filter((item) => brandTableTransferData.includes(item.headerName)));
  }, [brandTableTransferData]);

  const createHeader = (row: IRowHeaderBrand, index: number) => (
    <th
      key={`brand-${index}`}
      style={{ cursor: "pointer" }}
      // onClick={() => {
      //   setDropDownWhySort(row.sort);
      //   incrementCount(row.field);
      // }}
    >
      {row.headerName}
      {/* {inputToSort === row.sort && (
        <FontAwesomeIcon icon={typeSort === `asc(${row.field})` ? faChevronUp : faChevronDown} style={{ marginLeft: "5px" }} />
      )} */}
    </th>
  );

  const createRow = (row: IRowBrandFromAPI, index: number, render: IRowHeaderBrand[]) => {
    const isItemSelected: boolean = isSelected(row.id);
    return (
      <tr
        key={row?.id}
        style={{
          backgroundColor: "var(--color-white)",
          textAlign: "left",
          cursor: "pointer",
        }}
      >
        {defaultChecked ? (
          <td style={{ paddingLeft: "15px" }}>
            <img alt="" src={grayChecked} style={{ width: "20px", marginLeft: "10px" }}></img>
          </td>
        ) : (
          <td style={{ paddingLeft: "15px" }} className="check-table">
            <Checkbox
              checked={isItemSelected}
              style={{ display: "inline" }}
              name="gridRadios"
              id={`radios${row.id}`}
              value={`option${row.id}`}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                createDeleteArray(row.id, event.target.checked);
              }}
              aria-label="option 1"
            />
            &nbsp;
            {/* <FontAwesomeIcon icon={faBars} /> */}
          </td>
        )}
        {!props.Create ? (
          <>
            {render?.map((i: IRowHeaderBrand) => (
              <td style={{ paddingLeft: "15px" }} onClick={() => history.push(`/brand-info/${row.id}`)}>
                {`${i.field}` !== "logo" ? row[`${i.field}`] : <ImagesApi image={row?.logo} />}
              </td>
            ))}
          </>
        ) : (
          <>
            {render?.map((i: IRowHeaderBrand) => (
              <td style={{ paddingLeft: "15px" }} onClick={() => handleBrand(row.id, row.code, row.name)}>
                {`${i.field}` !== "logo" ? row[`${i.field}`] : <ImagesApi image={row?.logo} />}
              </td>
            ))}
          </>
        )}
      </tr>
    );
  };

  //GENERATE AN ARRAY WITH SELECTED IDS
  const createDeleteArray = (idDelete: string, checked: boolean) => {
    if (checked) {
      setBrandToDelete(idDelete);
      setBrandsListID([...brandsListID, idDelete]);
    } else {
      setBrandsListID(brandsListID.filter((item: any) => item !== idDelete));
    }
  };

  const handleBrand = (brandId: string, BrandCode: string, brandName: string) => {
    setBrandId(brandId);
    setBrandCode(BrandCode);
    setBrandName(brandName);
    setOpenBrandTable(!openBrandTable);
  };
  //Call api

  var brandArraySort: IRowBrandFromAPI[] = [];
  if (brandsArray) {
    brandArraySort = brandsArray;
  }

  //SELECT ALL LIST
  const allSelect = (allchecked: boolean, page: boolean) => {
    setDefaultChecked(allchecked);
    let AllIds: any = [];
    if (page) {
      AllIds = brandArraySort.map(function (ids: any) {
        return ids.id;
      });
      setIsDisabled(true);
    } else {
      AllIds = [];
      setIsDisabled(false);
      setBrandsAllSelected(false);
    }
    if (allchecked) {
      setBrandsAllSelected(true);
    }
    setBrandsListID(AllIds);
  };

  const isSelected = (id: string) => brandsListID.indexOf(id) !== -1;

  return (
    <div>
      <Card>
        {!props.load ? (
          <>
            {!matches ? (
              <Table responsive="xl" striped hover style={{ border: "2px solid var(--color-input-border)" }}>
                {/* TABLE HEADER */}
                <thead
                  style={{
                    marginBottom: "10px",
                    textAlign: "left",
                    backgroundColor: "rgba(0, 0, 0, 0.03)",
                  }}
                >
                  <tr>
                    <th className="check-table">
                      <Container style={{ padding: 12 }}>
                        <Dropdown style={{ border: "none" }}>
                          <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                            {/* <input type="checkbox" /> */}
                            {defaultChecked ? (
                              <img alt="" src={checkedimg} style={{ width: "20px" }}></img>
                            ) : (
                              <img alt="" src={uncheckedimg} style={{ width: "20px" }}></img>
                            )}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div onClick={() => allSelect(true, false)}>
                              <Dropdown.Item href="#/action-1">
                                <img alt="" src={checkedimg}></img> {t("TABLES-SELECT-ALL")}
                              </Dropdown.Item>
                            </div>
                            <div onClick={() => allSelect(false, true)}>
                              <Dropdown.Item href="#/action-1">
                                <img alt="" src={uncheckedpage}></img> {t("TABLES-SELECT-PAGE")}
                              </Dropdown.Item>
                            </div>
                            <div onClick={() => allSelect(false, false)}>
                              <Dropdown.Item href="#/action-3">
                                <img alt="" src={uncheckedimg}></img> {t("TABLES-UNSELECT-PAGE")}
                              </Dropdown.Item>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Container>
                    </th>
                    {tabla?.map(createHeader)}
                  </tr>
                  <BrandsTableFilters handleSearch={handleSearch} />
                </thead>
                <tbody style={{ cursor: "pointer !important" }}>
                  {/* TABLE BODY */}
                  {brandArraySort?.map((row: IRowBrandFromAPI, index: number) => createRow(row, index, tabla))}
                </tbody>
              </Table>
            ) : (
              <BrandTableResponsive render={brandArraySort} Create={props.Create} handleBrand={handleBrand} />
            )}
          </>
        ) : (
          <LoadingSkeletonTables />
        )}

        <div className="pb-2" style={{ display: "flex", justifyContent: "space-around" }}>
          <Pagination
            style={{ paddingTop: 10 }}
            count={Math.ceil(totalBrands / pageSize)}
            page={currentPage}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
            color="primary"
            boundaryCount={3}
            siblingCount={4}
          />
        </div>
        {/* count -- numero de páginas
                page -- pagina actual
                onchange cambio de paginacion */}
      </Card>
    </div>
  );
}
