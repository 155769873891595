import { useContext, useState } from "react";
import useGlobalAutocomplete from "./useGlobalAutocomplete";
import AutoCompleteInputGlobal from "./AutocompleteInputGlobal";
import { LocalStorageKeys, getInLocalStorage } from "../../../helpers/local-storage-manager";
import { ApplicationContext } from "../../../context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowMaximize } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
export default function GlobalSearch() {
  const { globalSearchFocus } = useContext(ApplicationContext);
  const history = useHistory();
  const matchesPhone = useMediaQuery("(max-width:600px)");
  const previousSearch = getInLocalStorage(LocalStorageKeys.GLOBAL_SEARCH);
  const [globalSearch, setGlobalSearch] = useState(previousSearch ? previousSearch : "");
  const global = useGlobalAutocomplete("/common/global-search?global_filter=", globalSearch, setGlobalSearch);
  if (matchesPhone) {
    return (
      <SearchIcon
        onClick={() => {
          history.push("/");
        }}
      />
    );
  }
  return (
    <div style={{ display: "flex", gap: "4px" }}>
      <AutoCompleteInputGlobal {...global} />
      <div
        className={globalSearchFocus ? "cardSearch focus" : "cardSearch"}
        onClick={() => {
          history.push("/");
        }}
      >
        <FontAwesomeIcon
          className="icon_size"
          style={{ width: "20px", height: "20px" }}
          color={globalSearchFocus ? "#000" : "#888"}
          icon={faWindowMaximize}
        />
      </div>
    </div>
  );
}
