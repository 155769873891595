import InputClear from "../../atoms/InputClear";
import { useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../../constants/global.css";
import { faPhone, faEnvelope, faSearch, faPlus, faTimes, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ValidationAlert from "../../atoms/ValidationAlert";
import Tooltip from "@mui/material/Tooltip";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { Link } from "react-router-dom";
import "../../../constants/responsive.css";
import "./exchangesCustomerTable.css";

// Translations
import { useTranslation } from "react-i18next";
import DropDownCities from "../../atoms/DropDownCities";
import { typeUserData } from "../../../constants/userTypes";
import DropDownGuard from "../../atoms/DropDownGuard";

export default function ExchangesCustomerTable(props: any) {
  // Translations
  const { t, i18n } = useTranslation();
  const { warningAlertStatus } = props;
  const listCountry = ["", `${t("COUNTRY-FRANCE")}`, `${t("COUNTRY-SPAIN")}`, `${t("COUNTRY-UK")}`, `${t("COUNTRY-GERMANY")}`];

  const RenderWarnings = () => {
    return (
      <div>
        <ValidationAlert message={t("ALERT-GLOBAL-FILL-FIELDS")} variant="warning" />
      </div>
    );
  };

  const {
    setCustomerFirstName,
    setCustomerLastName,
    setCustomerUserName,
    setCustomerId,
    setCustomerEmail,
    setCustomerMobilePhoneNumber,
    setCustomerZipCode,
    setCustomerCity,
    setCustomerCountry,
    setCustomerLanguage,
  } = useContext(ApplicationContext);
  return (
    <div className="card m-2">
      <div className="m-3 sectionBoxTop py-2">
        <h4 className="h4-stl">{t("CREATE.EXCHANGES.TITLE-CUSTOMER")}</h4>
      </div>
      <div className="flex-inputs-responsive-exchange-input mt-2 px-2">
        <div className="full-width ">
          {!props.customerUsername && warningAlertStatus && <RenderWarnings />}
          <div className="flex-inputs-responsive p-3">
            <div className="full-width ">
              <h4 className="h4-stl">
                <strong>{t("ALL.GENERAL-EXCHANGE.TITLE-CUSTOMER-USERNAME")} *</strong>
              </h4>
              <div className="flex-inputs-responsive-info">
                <div className="full-width">
                  <InputClear
                    type="text"
                    name=""
                    placeholder=""
                    readOnly
                    style={{
                      background: "var(--color-input-background-secondary)",
                    }}
                    currentValue={props.customerUsername}
                    handleOnInputValue={props.setCustomerUsername}
                  />
                </div>
                <div>
                  {props.Create ? (
                    <div style={{ display: "flex", cursor: "pointer" }}>
                      <Tooltip title="Select" placement="bottom">
                        <FontAwesomeIcon
                          className="pointer"
                          icon={faSearch}
                          onClick={props.onClickSearch}
                          style={{
                            width: "2Opx",
                            height: "20px",
                          }}
                        />
                      </Tooltip>

                      <Tooltip title="Create" placement="bottom">
                        <FontAwesomeIcon
                          className="pointer"
                          icon={faPlus}
                          onClick={props.onClickCreate}
                          style={{
                            marginLeft: "8px",
                            width: "2Opx",
                            height: "20px",
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Remove" placement="bottom">
                        <FontAwesomeIcon
                          className="pointer"
                          icon={faTimes}
                          onClick={() => {
                            setCustomerFirstName("");
                            setCustomerLastName("");
                            setCustomerUserName("");
                            setCustomerId("");
                            setCustomerEmail("");
                            setCustomerMobilePhoneNumber("");
                            setCustomerZipCode("");
                            setCustomerCity("");
                            setCustomerCountry("");
                            setCustomerLanguage("");
                          }}
                          style={{
                            marginLeft: "8px",
                            width: "2Opx",
                            height: "20px",
                          }}
                        />
                      </Tooltip>
                      {props.selectedStatus && (
                        <Tooltip title="Open" placement="bottom">
                          <Link to={`${props.openLink}`} style={{ color: "var(--color-main-text)" }}>
                            <FontAwesomeIcon
                              className="pointer"
                              icon={faShare}
                              style={{
                                marginLeft: "8px",
                                width: "2Opx",
                                height: "20px",
                              }}
                            />
                          </Link>
                        </Tooltip>
                      )}
                    </div>
                  ) : (
                    <Tooltip title="Open" placement="bottom">
                      <div>
                        <Link to={`${props.openLink}`} style={{ color: "var(--color-main-text)" }}>
                          <FontAwesomeIcon
                            className="pointer"
                            icon={faShare}
                            style={{
                              marginLeft: "8px",
                              width: "2Opx",
                              height: "20px",
                            }}
                          />
                        </Link>
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-inputs-responsive-exchange-input px-4">
        <div className="full-width mt-2">
          <h4 className="h4-stl">
            <strong>{t("CREATE.EXCHANGES.INPUT-CUSTOMER-FIRST-NAME")} *</strong>
          </h4>
          <InputClear
            type="text"
            name=""
            placeholder=""
            readOnly
            style={{ background: "var(--color-input-background-secondary)" }}
            currentValue={props.customerFirstname}
            handleOnInputValue={props.setCustomerFirstname}
          />
        </div>
        <div className="full-width mt-2">
          <h4 className="h4-stl">
            <strong>{t("CREATE.EXCHANGES.INPUT-CUSTOMER-LAST-NAME")} *</strong>
          </h4>
          <InputClear
            type="text"
            name=""
            placeholder=""
            readOnly
            style={{ background: "var(--color-input-background-secondary)" }}
            currentValue={props.customerLastname}
            handleOnInputValue={props.setCustomerLastname}
          />
        </div>
      </div>

      <div className="flex-inputs-responsive-exchange-input mt-3 px-4">
        <div className="full-width mt-2">
          <h4 className="h4-stl">
            <strong>{t("CREATE.EXCHANGES.INPUT-CUSTOMER-EMAIL")} *</strong>
          </h4>
          <div className="flex-inputs-responsive-info">
            <div className="full-width ">
              <InputClear
                type="email"
                name=""
                placeholder=""
                readOnly
                style={{ background: "var(--color-input-background-secondary)" }}
                currentValue={props.customerEmail}
                handleOnInputValue={props.setCustomerEmail}
              />
            </div>
            <div>
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{
                  width: "2Opx",
                  height: "20px",
                }}
              />
            </div>
          </div>
        </div>
        <div className="full-width mt-2">
          <h4 className="h4-stl">
            <strong>{t("CREATE.EXCHANGES.INPUT-CUSTOMER-MOBILE-PHONE-NUMBER")} *</strong>
          </h4>
          <div className="flex-inputs-responsive-info">
            <div className="full-width ">
              <InputClear
                type="text"
                name=""
                placeholder=""
                readOnly
                style={{ background: "var(--color-input-background-secondary)" }}
                currentValue={props.customerMobilePhone}
                handleOnInputValue={props.setCustomerMobilePhone}
              />
            </div>
            <div>
              <FontAwesomeIcon
                icon={faPhone}
                style={{
                  width: "2Opx",
                  height: "20px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-inputs-responsive-exchange-input mt-3 mb-5 px-4 ">
        <div className="full-width">
          <div className="flex-inputs-responsive-exchange">
            <div className="full-width">
              <h4 className="h4-stl">
                <strong>{t("CUSTOMER-TABLE-EXCHANGES-INPUT-POSTAL.CODE")}</strong>
              </h4>
              <div>
                <InputClear
                  type="text"
                  name=""
                  placeholder=""
                  readOnly
                  style={{ background: "var(--color-input-background-secondary)" }}
                  currentValue={props.customerZipCode}
                  handleOnInputValue={props.setCustomerZipCode}
                />
              </div>
            </div>
            <div className="full-width ">
              <h4 className="h4-stl">
                <strong>{t("CUSTOMER-TABLE-EXCHANGES-INPUT-CITY")}</strong>
              </h4>
              <div>
                <InputClear
                  type="text"
                  name=""
                  placeholder=""
                  readOnly
                  style={{ background: "var(--color-input-background-secondary)" }}
                  currentValue={props.customerCity}
                  handleOnInputValue={props.setCustomerCity}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="full-width">
          <div className="flex-inputs-responsive-exchange">
            <div className="full-width ">
              <h4 className="h4-stl">
                <strong>{t("CUSTOMER-TABLE-EXCHANGES-INPUT-COUNTRY")}</strong>
              </h4>
              <div>
                <DropDownCities
                  options={listCountry}
                  selectedItem={props.customerCountry}
                  onSelect={props.setCustomerCountry}
                  width="100%"
                  roles={[]}
                />
              </div>
            </div>
            <div className="full-width ">
              <h4 className="h4-stl">
                <strong>{t("CUSTOMER-TABLE-EXCHANGES-INPUT-LANGUAGE")} *</strong>
              </h4>
              <div>
                <DropDownGuard
                  options={listCountry}
                  selectedItem={props.customerLanguage}
                  onSelect={props.setCustomerLanguage}
                  width="100%"
                  roles={[]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
