import React, { useContext } from "react";
import LateralContainer from "../LateralContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputClear from "../../atoms/InputClear";
import { Button } from "react-bootstrap";
import { faTimesCircle, faEllipsisH, faChevronLeft, faSortAlphaDown, faThumbtack } from "@fortawesome/free-solid-svg-icons";
import DropDown from "../../atoms/DropDown";
import { useTranslation } from "react-i18next";
import { IReasonExchangeSearch } from "./interfaces";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { Tooltip } from "@mui/material";
import { useFilterContext } from "../../../context";
import AutoCompleteInput from "../../atoms/AutocopleteInput/AutocompleteInput";
import useAutocomplete from "../../atoms/AutocopleteInput/useAutocomplete";

export default function ReasonForExchangeSearch(props: IReasonExchangeSearch) {
  const { t } = useTranslation();
  const listElegibleOptions = ["", t("TABLE.DISTRIBUTORS-TITLE-YES"), t("TABLE.DISTRIBUTORS-TITLE-NO")];
  const {
    inputCodeSearchRR,
    setInputCodeSearchRR,
    inputLanguageSearch,
    setInputLanguageSearch,
    inputDisplayOrderSearch,
    setInputDisplayOrderSearch,
    inputFacRreLabelSearch,
    setInputFacRreLabelSearch,
    inputEligibleSearch,
    setInputEligibleSearch,
    showLateralContainerSearch,
    setShowLateralContainerSearch,
  } = useFilterContext();
  const { listSort, dropDownWhySort, setDropDownWhySort, searchWindowState, SetSearchWindowState, handleSearch, listLanguajeOptions } =
    props;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleSearch();
    setShowLateralContainerSearch(!showLateralContainerSearch);
  };
  const { typeOfSearch, setTypeOfSearch } = useContext(ApplicationContext);

  //**Filters */
  const code = useAutocomplete(
    "/reasons-for-exchange/get-all?autocomplete=true&code_reason=",
    handleSearch,
    inputCodeSearchRR,
    setInputCodeSearchRR,
    "code_reason"
  );
  const label = useAutocomplete(
    "/reasons-for-exchange/get-all?autocomplete=true&label=",
    handleSearch,
    inputFacRreLabelSearch,
    setInputFacRreLabelSearch,
    "label"
  );
  const displayOrder = useAutocomplete(
    "/reasons-for-exchange/get-all?autocomplete=true&display_order=",
    handleSearch,
    inputDisplayOrderSearch,
    setInputDisplayOrderSearch,
    "display_order"
  );

  return (
    <LateralContainer
      show={typeOfSearch === 1 ? showLateralContainerSearch : false}
      toggleShow={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
      content={
        <form action="/" onSubmit={handleSubmit}>
          <div className="row sectionBoxTop">
            <div className="col-2 col-lg-2 pb-2" style={{ display: "flex" }}>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{
                  marginLeft: "50%",
                  marginTop: "0px",
                  width: "50px",
                  height: "50px",
                }}
              />
            </div>
            <div className="col-10 col-lg-10">
              <h4 className="h4-stl" style={{ margin: "15px 20px 15px" }}>
                <strong>{t("SEARCH.REASON-EXCHANGE.TITLE-HEADER")}</strong>
              </h4>
            </div>
          </div>
          {searchWindowState === "filterList" ? (
            <div className="cssanimation sequence fadeInTop">
              <div className="filterBox my-4">
                <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                  {t("SEARCH.REASON-EXCHANGE.TITLE-BOX-DEFINITION")}
                </h4>
                <div className="row py-2">
                  <div className="col-1 col-lg-1"></div>
                  <div className="col-11 col-lg-2 Right">
                    <h5 className="h5-stl Right">{t("ALL.GENERAL-REASON-EXCHANGE.TITLE-CODE")}</h5>
                  </div>
                  <div className="col-8 col-lg-6">
                    <AutoCompleteInput {...code} freeSolo />
                  </div>
                  <div className="col-2">
                    <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                  </div>
                </div>
                <div className="row py-2">
                  <div className="col-1 col-lg-1"></div>
                  <div className="col-11 col-lg-2">
                    <h5 className="h5-stl Right">{t("ALL.GENERAL-REASON-EXCHANGE.TITLE-LANGUAJE")}</h5>
                  </div>
                  <div className="col-8 col-lg-6">
                    <DropDown
                      options={listLanguajeOptions}
                      selectedItem={inputLanguageSearch}
                      onSelect={(item: string) => setInputLanguageSearch(item)}
                      width="13vw"
                    />
                  </div>
                  <div className="col-2">
                    <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                  </div>
                </div>
                <div className="row py-2">
                  <div className="col-1 col-lg-1"></div>
                  <div className="col-11 col-lg-2">
                    <h5 className="h5-stl Right">{t("ALL.GENERAL-REASON-EXCHANGE.TITLE-DISPLAY-ORDER")}</h5>
                  </div>
                  <div className="col-8 col-lg-6">
                    <AutoCompleteInput {...displayOrder} freeSolo />
                  </div>
                  <div className="col-2">
                    <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                  </div>
                </div>
                <div className="row py-2">
                  <div className="col-1 col-lg-1"></div>
                  <div className="col-11 col-lg-2 Right">
                    <h5 className="h5-stl Right">{t("ALL.GENERAL-REASON-EXCHANGE.TITLE-ELIGIBLE")}?</h5>
                  </div>
                  <div className="col-8 col-lg-6">
                    <DropDown
                      options={listElegibleOptions}
                      selectedItem={inputEligibleSearch}
                      onSelect={(item: string) => setInputEligibleSearch(item)}
                      width="13vw"
                    />
                  </div>
                  <div className="col-2">
                    <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                  </div>
                </div>
                <div className="row py-2 mb-4">
                  <div className="col-1 col-lg-1"></div>
                  <div className="col-11 col-lg-2">
                    <h5 className="h5-stl Right">{t("ALL.GENERAL-REASON-EXCHANGE.TITLE-LABEL")}</h5>
                  </div>
                  <div className="col-8 col-lg-6">
                    <AutoCompleteInput {...label} freeSolo />
                  </div>
                  <div className="col-2">
                    <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                  </div>
                </div>
              </div>
              <div className="bg-gray px-4" style={{ display: "flex", alignItems: "center" }}>
                <Tooltip title={`${t("FILER-TO-COLUMNS")}`} placement="bottom">
                  <div style={{ width: "60px" }}>
                    <FontAwesomeIcon
                      className="grayBackgr"
                      style={{ width: "47px", height: "47px" }}
                      icon={faThumbtack}
                      onClick={() => {
                        setTypeOfSearch(0);
                      }}
                    />
                  </div>
                </Tooltip>
                {/* <FontAwesomeIcon
                  className="grayBackgr"
                  icon={faSortAlphaDown}
                  onClick={() => SetSearchWindowState("SortWindow")}
                  style={{ width: "47px", height: "47px" }}
                /> */}
                <Button
                  variant="primary"
                  style={{ margin: "15px 30px" }}
                  onClick={() => {
                    handleSearch();
                    setShowLateralContainerSearch(!showLateralContainerSearch);
                  }}
                >
                  {t("SEARCH.ALL.BUTTON-SEARCH")}
                </Button>
                <h4
                  className="h4-stl grayBackgr"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setInputCodeSearchRR("");
                    setInputLanguageSearch("");
                    setInputFacRreLabelSearch("");
                    setInputDisplayOrderSearch("");
                    setInputFacRreLabelSearch("");
                    handleSearch();
                  }}
                >
                  {t("SEARCH.ALL.BUTTON-RESET")}
                </h4>
                <h4
                  className="h4-stl mx-2 grayBackgr"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                >
                  {t("SEARCH.ALL.BUTTON-CLOSE")}
                </h4>
              </div>
            </div>
          ) : (
            <div className="cssanimation sequence fadeInBottom">
              <div className="sectionBoxTop pb-4">
                <div
                  className="py-3 my-2"
                  style={{
                    backgroundColor: "var(--color-border)",
                    display: "flex",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    onClick={() => SetSearchWindowState("filterList")}
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "2em",
                      cursor: "pointer",
                    }}
                  />
                  <h4 className="h4-stl" onClick={() => SetSearchWindowState("filterList")} style={{ cursor: "pointer" }}>
                    <strong>Sort order</strong>
                  </h4>
                </div>
                <div>
                  <DropDown
                    options={listSort}
                    selectedItem={dropDownWhySort}
                    onSelect={(item: string) => setDropDownWhySort(item)}
                    width="20vw"
                  />
                </div>
              </div>

              <div>
                <Button
                  type="submit"
                  variant="primary"
                  style={{
                    margin: "15px 30px",
                    display: "flex",
                    float: "right",
                  }}
                  onClick={() => SetSearchWindowState("filterList")}
                >
                  Apply
                </Button>
              </div>
            </div>
          )}
          <input type="submit" value="" style={{ display: "none" }}></input>
        </form>
      }
    ></LateralContainer>
  );
}
