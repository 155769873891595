import React, { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import {
  faChevronDown,
  faChevronUp,
  faUser,
  faArrowAltCircleRight,
  faTimesCircle,
  faWrench,
  faHammer,
  faBoxes,
  faHotel,
  faLaptop,
  faComment,
  faCity,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Container, Dropdown } from "react-bootstrap";

import Pagination from "@mui/material/Pagination";
import Table from "react-bootstrap/Table";
import { Link, useHistory } from "react-router-dom";
import exchangeScan from "../../../images/exchangeScan.png";
import "./changeLogTable.css";
import GroupLogo1 from "../../../images/GroupLogo1.png";
import userIcon from "../../../images/userIcon.svg";
import uncheckedpage from "../../../images/checkPage.svg";

//Api
import { ApplicationContext } from "../../../context/ApplicationContext";
import LoadingSkeleton from "../LoadingSkeleton/LoadingSkeleton";
import { useTranslation } from "react-i18next";
import { Checkbox, useMediaQuery } from "@mui/material";
import checkedimg from "../../../images/checked.svg";
import uncheckedimg from "../../../images/unchecked.svg";
import grayChecked from "../../../images/grayCheck.png";
import { removeTags } from "../../../helpers/removeHtml";
import ImageExchange from "../../pages/ExchangesInfo/ImageExchange";
import ChangeLogTableFilter from "../Search/ChangeLogTableFilter";
import { IRowHeader } from "../../../models/table.modal";
import { IRowExchangesFromAPI } from "../TableExchanges";
import ChangeLogTableResponsive from "../../molecules/TransferList/responsiveTables/ChangeLogTableResponsive";
interface IRefusalProps {
  searchState: boolean;
  rowsPerPage: number;
  UsePlanText: string;
  inputToSort: string;

  loading: boolean;
  currentPage: number;
  lastPage: number;
  handleChange: any;
  changeLogArray: any;

  incrementCount?: any;
  typeSort?: string;
  setDropDownWhySort?: any;

  listSort: string[];
  handleSearch: any;
}

export interface IChangeLogFromAPI {
  _id: string;
  login: string;
  user: string;
  date: string;
  resource: string;
  action: any;
  after: any;
  changes: any;
  before: any;
  [index: string]: string;
}

export default function ChangeLogTable(props: IRefusalProps) {
  const { lastPage, handleChange, currentPage, changeLogArray, loading, incrementCount, typeSort, setDropDownWhySort } = props;
  const { listSort, handleSearch, inputToSort } = props;
  //Translations
  const { t } = useTranslation();
  const history = useHistory();
  const matches = useMediaQuery("(max-width:780px)");

  const { changeLogListID, setChangeLogListID, changeLogsTableTransferData, setChangeLogAllSelected } = useContext(ApplicationContext);

  //GENERATE AN ARRAY WITH SELECTED IDS
  const [defaultChecked, setDefaultChecked] = useState<boolean>(false);
  const createDeleteArray = (idDelete: string, checked: boolean) => {
    if (checked) {
      setChangeLogListID([...changeLogListID, idDelete]);
    } else {
      setChangeLogListID(changeLogListID.filter((item: any) => item !== idDelete));
    }
  };

  const redirectionLink = "/change-log-info";
  const redirectionKey = "_id";
  const tableInitial: IRowHeader[] = [
    {
      field: "dropdown",
      headerName: "",
      sort: "",
    },
    {
      field: "login",
      headerName: t("ALL.GENERAL-CHANGE-LOG.TITLE-LOGIN"),
      sort: t("ALL.GENERAL-CHANGE-LOG.TITLE-LOGIN"),
      sortKey: "login",
      link: redirectionLink,
      linkKey: redirectionKey,
      title: t("TABLE.CHANGE-LOG.HEADER-TITLE-USER"),
    },
    {
      field: "user",
      headerName: t("ALL.GENERAL-CHANGE-LOG.TITLE-USER"),
      sort: t("ALL.GENERAL-CHANGE-LOG.TITLE-USER"),
      sortKey: "user",
      link: redirectionLink,
      linkKey: redirectionKey,
    },

    {
      field: "date",
      headerName: t("ALL.GENERAL-CHANGE-LOG.TITLE-DATE"),
      sort: t("ALL.GENERAL-CHANGE-LOG.TITLE-DATE"),
      sortKey: "date",
      link: redirectionLink,
      linkKey: redirectionKey,
    },
    {
      field: "resource",
      headerName: t("ALL.GENERAL-CHANGE-LOG.TITLE-OBJECT"),
      sort: t("ALL.GENERAL-CHANGE-LOG.TITLE-OBJECT"),
      sortKey: "resource",
      link: redirectionLink,
      linkKey: redirectionKey,
      title: t("TABLE.CHANGE-LOG.HEADER-TITLE-ACTION"),
    },
    {
      field: "action",
      headerName: t("ALL.GENERAL-CHANGE-LOG.TITLE-ACTION"),
      sort: t("ALL.GENERAL-CHANGE-LOG.TITLE-ACTION"),
      sortKey: "action",
      link: redirectionLink,
      linkKey: redirectionKey,
    },
    {
      field: "changes",
      headerName: t("ALL.GENERAL-CHANGE-LOG.TITLE-ACTIVITY"),
      sort: t("ALL.GENERAL-CHANGE-LOG.TITLE-ACTIVITY"),
      sortKey: "changes",
      link: redirectionLink,
      linkKey: redirectionKey,
    },
  ];
  //TABLE
  const pageSize = props.rowsPerPage;

  //FILTRO
  //FILTRO
  let changeLogArraySort: IChangeLogFromAPI[] = [];
  if (changeLogArray) {
    changeLogArraySort = changeLogArray;
  }

  const [tabla, setTabla] = useState<IRowHeader[]>(tableInitial);
  useEffect(() => {
    setTabla(tableInitial.filter((item) => changeLogsTableTransferData.includes(item.headerName)));
  }, [changeLogsTableTransferData]);
  const createHeaderGroups = (row: IRowHeader, index: number) => <th>{row.title ? row.title : ""}</th>;

  const createHeader = (row: IRowHeader, index: number) => (
    <th
      key={`distributor-${index}`}
      style={{ cursor: "pointer" }}
      // onClick={() => {
      //   setDropDownWhySort(row.sort);
      //   incrementCount(row.sortKey);
      // }}
    >
      {row.headerName}
      {/* {inputToSort === row.sort && (
        <FontAwesomeIcon icon={typeSort === `asc(${row.sortKey})` ? faChevronUp : faChevronDown} style={{ marginLeft: "5px" }} />
      )} */}
    </th>
  );

  const createRow = (row: IChangeLogFromAPI, index: number, render: IRowHeader[]) => {
    const isItemSelected: boolean = isSelected(row._id);
    return (
      <>
        {row?.changes?.length === 0 ? (
          <></>
        ) : (
          <tr
            key={row?._id}
            style={{
              backgroundColor: "var(--color-white)",
              textAlign: "left",
              cursor: "pointer",
            }}
          >
            {defaultChecked ? (
              <td style={{ paddingLeft: "15px" }}>
                <img alt="" src={grayChecked} style={{ width: "20px", marginLeft: "10px" }}></img>
              </td>
            ) : (
              <td style={{ paddingLeft: "15px" }}>
                <Checkbox
                  checked={isItemSelected}
                  style={{ display: "inline" }}
                  name="gridRadios"
                  id={`radios${row._id}`}
                  value={`option${row._id}`}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    createDeleteArray(row._id, event.target.checked);
                  }}
                  aria-label="option 1"
                />
              </td>
            )}
            {render?.map((i: IRowHeader) => (
              <td
                style={`${i.field}` === "resource" ? { minWidth: "270px" } : { paddingLeft: "15px" }}
                onClick={() => `${i.field}` !== "resource" && history.push(`${i.link}/${row[i.linkKey ? i.linkKey : "id"]}`)}
              >
                {`${i.field}` === "date" ? (
                  row.date ? (
                    row.date.slice(0, 10)
                  ) : (
                    ""
                  )
                ) : `${i.field}` === "resource" ? (
                  <Link
                    to={`/change-log-info/${row._id}`}
                    style={{
                      textDecoration: "none",
                      color: "var(--color-main-text)",
                    }}
                  >
                    {row.resource === "brand" ? (
                      <Link
                        style={{
                          color: "var(--color-main-text)",
                          textDecoration: "none",
                        }}
                        to={`/brand-info/${row.after?.id}`}
                      >
                        <Card style={{ minWidth: "250px" }}>
                          <div
                            className="row py-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div className="col-2">
                              <FontAwesomeIcon
                                icon={faBoxes}
                                style={{
                                  cursor: "pointer",
                                  width: "40px",
                                  height: "40px",
                                }}
                              />
                            </div>
                            <div className="col-10" style={{ paddingRight: "0" }}>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.resource}
                              </h6>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.after?.code}
                              </h6>
                            </div>
                          </div>
                        </Card>
                      </Link>
                    ) : row.resource === "product" ? (
                      <Link
                        style={{
                          color: "var(--color-main-text)",
                          textDecoration: "none",
                        }}
                        to={`/product-info/${row.after?.id}`}
                      >
                        <Card>
                          <div
                            className="row py-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div className="col-2">
                              <FontAwesomeIcon
                                icon={faWrench}
                                style={{
                                  cursor: "pointer",
                                  width: "40px",
                                  height: "40px",
                                }}
                              />
                            </div>
                            <div className="col-10" style={{ paddingRight: "0" }}>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.resource}
                              </h6>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.after?.reference}
                              </h6>
                            </div>
                          </div>
                        </Card>
                      </Link>
                    ) : row.resource === "group" ? (
                      <Link
                        style={{
                          color: "var(--color-main-text)",
                          textDecoration: "none",
                        }}
                        to={`/product-info/${row.after?.id}`}
                      >
                        <Card>
                          <div
                            className="row py-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div className="col-2">
                              <FontAwesomeIcon
                                icon={faWrench}
                                style={{
                                  cursor: "pointer",
                                  width: "40px",
                                  height: "40px",
                                }}
                              />
                            </div>
                            <div className="col-10" style={{ paddingRight: "0" }}>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.resource}
                              </h6>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.after?.name}
                              </h6>
                            </div>
                          </div>
                        </Card>
                      </Link>
                    ) : row.resource === "exchange" ? (
                      <Link
                        style={{
                          color: "var(--color-main-text)",
                          textDecoration: "none",
                        }}
                        to={`/exchange-info/${row.after?.id}`}
                      >
                        <Card style={{ minWidth: "250px" }}>
                          <div
                            className="row py-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div className="col-2">
                              <img alt="" src={exchangeScan} />
                            </div>
                            <div className="col-10" style={{ paddingRight: "0" }}>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.resource}
                              </h6>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.resource}
                              </h6>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.after?.number}
                              </h6>
                            </div>
                          </div>
                        </Card>
                      </Link>
                    ) : // Card que faltan
                    row.resource === "customer" ? (
                      <Link
                        style={{
                          color: "var(--color-main-text)",
                          textDecoration: "none",
                        }}
                        to={`/customer-info/${row.after?.id}`}
                      >
                        <Card>
                          <div
                            className="row py-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div className="col-2">
                              <FontAwesomeIcon
                                icon={faUser}
                                style={{
                                  cursor: "pointer",
                                  width: "40px",
                                  height: "40px",
                                }}
                              />
                            </div>
                            <div className="col-10" style={{ paddingRight: "0" }}>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.resource}
                              </h6>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.before?.username}
                              </h6>
                            </div>
                          </div>
                        </Card>
                      </Link>
                    ) : row.resource === "distributor" ? (
                      <Link
                        style={{
                          color: "var(--color-main-text)",
                          textDecoration: "none",
                        }}
                        to={`/distributor-info/${row.after?.id}`}
                      >
                        <Card>
                          <div
                            className="row py-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div className="col-2">
                              <img alt="" src={GroupLogo1} style={{ width: "40px" }} />
                            </div>
                            <div className="col-10" style={{ paddingRight: "0" }}>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.resource}
                              </h6>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.after?.name}
                              </h6>
                            </div>
                          </div>
                        </Card>
                      </Link>
                    ) : row.resource === "reason_for_refusal" ? (
                      <Link
                        style={{
                          color: "var(--color-main-text)",
                          textDecoration: "none",
                        }}
                        to={`/refusal-reasons-info/${row.after?.id}`}
                      >
                        <Card>
                          <div
                            className="row py-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div className="col-2">
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                style={{
                                  cursor: "pointer",
                                  width: "40px",
                                  height: "40px",
                                }}
                              />
                            </div>
                            <div className="col-10" style={{ paddingRight: "0" }}>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.resource}
                              </h6>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.after?.codeReason}
                              </h6>
                            </div>
                          </div>
                        </Card>
                      </Link>
                    ) : row.resource === "reason_for_exchange" ? (
                      <Link
                        style={{
                          color: "var(--color-main-text)",
                          textDecoration: "none",
                        }}
                        to={`/reasons-for-exchange-info-info/${row.after?.id}`}
                      >
                        <Card>
                          <div
                            className="row py-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div className="col-2">
                              <FontAwesomeIcon
                                icon={faHammer}
                                style={{
                                  cursor: "pointer",
                                  width: "40px",
                                  height: "40px",
                                }}
                              />
                            </div>
                            <div className="col-10" style={{ paddingRight: "0" }}>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.resource}
                              </h6>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.after?.label}
                              </h6>
                            </div>
                          </div>
                        </Card>
                      </Link>
                    ) : row.resource === "user" ? (
                      <Link
                        style={{
                          color: "var(--color-main-text)",
                          textDecoration: "none",
                        }}
                        to={`/user-info/${row.after?.id}`}
                      >
                        <Card>
                          <div
                            className="row py-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div className="col-2">
                              <img alt="" src={userIcon} style={{ width: "40px" }} />
                            </div>
                            <div className="col-10" style={{ paddingRight: "0" }}>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.resource}
                              </h6>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.after?.username}
                              </h6>
                            </div>
                          </div>
                        </Card>
                      </Link>
                    ) : row.resource === "group" ? (
                      <Link
                        style={{
                          color: "var(--color-main-text)",
                          textDecoration: "none",
                        }}
                        to={`/group-info/${row.after?.id}`}
                      >
                        <Card>
                          <div
                            className="row py-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div className="col-2">
                              <FontAwesomeIcon
                                icon={faHotel}
                                style={{
                                  cursor: "pointer",
                                  width: "40px",
                                  height: "40px",
                                }}
                              />
                            </div>
                            <div className="col-10" style={{ paddingRight: "0" }}>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.resource}
                              </h6>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.after?.name}
                              </h6>
                            </div>
                          </div>
                        </Card>
                      </Link>
                    ) : row.resource === "content" ? (
                      <Link
                        style={{
                          color: "var(--color-main-text)",
                          textDecoration: "none",
                        }}
                        to={`/content-info/${row.after?.id}`}
                      >
                        <Card>
                          <div
                            className="row py-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div className="col-2">
                              <FontAwesomeIcon
                                icon={faLaptop}
                                style={{
                                  cursor: "pointer",
                                  width: "40px",
                                  height: "40px",
                                }}
                              />
                            </div>
                            <div className="col-10" style={{ paddingRight: "0" }}>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.resource}
                              </h6>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.after?.name}
                              </h6>
                            </div>
                          </div>
                        </Card>
                      </Link>
                    ) : row.resource === "comment" ? (
                      <Card>
                        <div
                          className="row py-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-2">
                            <FontAwesomeIcon
                              icon={faComment}
                              style={{
                                cursor: "pointer",
                                width: "40px",
                                height: "40px",
                              }}
                            />
                          </div>
                          <div className="col-10" style={{ paddingRight: "0" }}>
                            <h6
                              style={{
                                fontSize: "12px",
                                marginLeft: "25px",
                              }}
                            >
                              {row.resource}
                            </h6>
                            <h6
                              style={{
                                fontSize: "12px",
                                marginLeft: "25px",
                              }}
                            >
                              {row.after?.id}
                            </h6>
                          </div>
                        </div>
                      </Card>
                    ) : row.resource === "distributor_set" ? (
                      <Link
                        style={{
                          color: "var(--color-main-text)",
                          textDecoration: "none",
                        }}
                        to={`/distributors-set-info/${row.after?.id}`}
                      >
                        <Card>
                          <div
                            className="row py-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div className="col-2">
                              <FontAwesomeIcon
                                icon={faCity}
                                style={{
                                  cursor: "pointer",
                                  width: "40px",
                                  height: "40px",
                                }}
                              />
                            </div>
                            <div className="col-10" style={{ paddingRight: "0" }}>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.resource}
                              </h6>
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "25px",
                                }}
                              >
                                {row.after?.name}
                              </h6>
                            </div>
                          </div>
                        </Card>
                      </Link>
                    ) : (
                      ""
                    )}
                  </Link>
                ) : `${i.field}` === "changes" ? (
                  <>
                    <h6 style={{ fontSize: "12px" }}>{row.date}</h6>
                    <h6 style={{ fontSize: "12px" }}>
                      <strong>{row.msg}</strong>
                    </h6>
                    {row.changes?.map((changesToMap: any, index: number) => {
                      const pointIndex = changesToMap.field.indexOf(".") === -1 ? 0 : changesToMap.field.indexOf(".") + 1;
                      return (
                        <div key={`ch${index}-${changesToMap.field}`}>
                          {changesToMap.after === "null" ? (
                            ""
                          ) : changesToMap.after === null ? (
                            ""
                          ) : changesToMap.after === "undefined" ? (
                            ""
                          ) : changesToMap.field === "updatedAt" ? (
                            ""
                          ) : changesToMap.field === "createdAt" ? (
                            ""
                          ) : (
                            <div>
                              {changesToMap.after && (
                                <tr className="table-stl-td">
                                  <td
                                    style={{
                                      paddingLeft: "15px",
                                      width: "215px",
                                      minWidth: "215px",
                                      maxWidth: "215px",
                                    }}
                                  >
                                    <strong>{changesToMap.field.slice(pointIndex, changesToMap.field.length)}</strong>
                                  </td>
                                  <td
                                    style={
                                      changesToMap.before
                                        ? {
                                            paddingLeft: "15px",

                                            minWidth: "200px",
                                            maxWidth: "450px",
                                          }
                                        : {
                                            paddingLeft: "15px",
                                            width: "10px",
                                          }
                                    }
                                  >
                                    {changesToMap.before === "null" ? (
                                      ""
                                    ) : changesToMap.before === null ? (
                                      ""
                                    ) : (
                                      <>
                                        {changesToMap.before && (
                                          <>
                                            {changesToMap.field.slice(pointIndex, changesToMap.field.length) === "fileContent" ? (
                                              "file"
                                            ) : changesToMap.field.slice(pointIndex, changesToMap.field.length) === "logo" ? (
                                              <img src={changesToMap.before} alt="img" style={{ maxWidth: "100px" }} />
                                            ) : changesToMap.field.slice(pointIndex, changesToMap.field.length) === "picture" ? (
                                              <img src={changesToMap.before} alt="img" style={{ maxWidth: "100px" }} />
                                            ) : changesToMap.field.slice(pointIndex, changesToMap.field.length) === "brochure" ? (
                                              <img src={changesToMap.before} alt="img" style={{ maxWidth: "100px" }} />
                                            ) : (
                                              <>{removeTags(changesToMap.before)}</>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </td>
                                  <td style={{ width: "50px" }}>
                                    <FontAwesomeIcon
                                      icon={faArrowAltCircleRight}
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: "5px",
                                      }}
                                    />
                                  </td>
                                  <td
                                    style={{
                                      paddingLeft: "15px",
                                      minWidth: "200px",
                                      maxWidth: "450px",
                                    }}
                                  >
                                    <>
                                      {changesToMap.after && (
                                        <>
                                          {changesToMap.field.slice(pointIndex, changesToMap.field.length) === "fileContent" ? (
                                            "file"
                                          ) : changesToMap.field.slice(pointIndex, changesToMap.field.length) === "logo" ? (
                                            <img src={changesToMap.after} alt="img" style={{ maxWidth: "100px" }} />
                                          ) : changesToMap.field.slice(pointIndex, changesToMap.field.length) === "picture" ? (
                                            <img src={changesToMap.after} alt="img" style={{ maxWidth: "100px" }} />
                                          ) : changesToMap.field.slice(pointIndex, changesToMap.field.length) === "brochure" ? (
                                            <img src={changesToMap.after} alt="img" style={{ maxWidth: "100px" }} />
                                          ) : (
                                            <>{removeTags(changesToMap.after)}</>
                                          )}
                                        </>
                                      )}
                                    </>
                                  </td>
                                </tr>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  row[`${i.field}`]
                )}
              </td>
            ))}
          </tr>
        )}
      </>
    );
  };

  //SELECT ALL LIST

  const allSelect = (allchecked: boolean, page: boolean) => {
    setDefaultChecked(allchecked);
    let AllIds: any = [];
    if (page) {
      AllIds = changeLogArraySort.map(function (ids: any) {
        return ids._id;
      });
    } else {
      AllIds = [];
      setChangeLogAllSelected(false);
    }
    if (allchecked) {
      setChangeLogAllSelected(true);
    }
    setChangeLogListID(AllIds);
  };
  const isSelected = (id: string) => changeLogListID.indexOf(id) !== -1;

  return (
    <div>
      <Card>
        {!loading ? (
          <>
            {!matches ? (
              <Table responsive="xl" striped hover style={{ border: "2px solid var(--color-input-border)" }}>
                {/* TABLE HEADER */}
                <thead
                  style={{
                    marginBottom: "10px",
                    textAlign: "left",
                    backgroundColor: "rgba(0, 0, 0, 0.03)",
                  }}
                >
                  <tr>
                    <th></th>
                    {tabla.map(createHeaderGroups)}
                  </tr>
                  <tr>
                    <th>
                      <Container style={{ padding: 12 }}>
                        <Dropdown style={{ border: "none" }}>
                          <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                            {/* <input type="checkbox" /> */}
                            {defaultChecked ? (
                              <img alt="" src={checkedimg} style={{ width: "20px", marginRight: "10px" }}></img>
                            ) : (
                              <img alt="" src={uncheckedimg} style={{ width: "20px", marginRight: "10px" }}></img>
                            )}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div onClick={() => allSelect(true, false)}>
                              <Dropdown.Item href="#/action-1">
                                <img alt="" src={checkedimg}></img> {t("TABLES-SELECT-ALL")}
                              </Dropdown.Item>
                            </div>
                            <div onClick={() => allSelect(false, true)}>
                              <Dropdown.Item href="#/action-1">
                                <img alt="" src={uncheckedpage}></img> {t("TABLES-SELECT-PAGE")}
                              </Dropdown.Item>
                            </div>
                            <div onClick={() => allSelect(false, false)}>
                              <Dropdown.Item href="#/action-3">
                                <img alt="" src={uncheckedimg}></img> {t("TABLES-UNSELECT-PAGE")}
                              </Dropdown.Item>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Container>
                    </th>
                    {tabla?.map(createHeader)}
                  </tr>
                  <ChangeLogTableFilter listSort={listSort} handleSearch={handleSearch} />
                </thead>
                <tbody style={{ cursor: "pointer !important" }}>
                  {/* TABLE BODY */}
                  {changeLogArraySort?.map((row: any, index: number) => createRow(row, index, tabla))}
                </tbody>
              </Table>
            ) : (
              <ChangeLogTableResponsive render={changeLogArraySort} />
            )}
          </>
        ) : (
          <LoadingSkeleton />
        )}
        <div className="pb-2" style={{ display: "flex", justifyContent: "space-around" }}>
          <Pagination
            style={{ paddingTop: 10 }}
            count={Math.ceil(lastPage / pageSize)}
            page={currentPage}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
            color="primary"
            boundaryCount={3}
            siblingCount={4}
          />
        </div>
      </Card>
    </div>
  );
}
