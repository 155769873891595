import { AxiosRequestConfig } from "axios";
import { axios as http } from "../../frameworks_and_drivers/Axios";
import { BACKEND_URL } from "../../config/settings.json";
import { IChangeLogList, IChangeLogListById } from "../interfaces/interfaz_change_log";
import { downloadFileFromResponse } from "./helps";
import { loadAbort } from "../../helpers/loadAbort";

import HelpService from "../HelpService";

export const getAllChangeLogs = (props?: any) => {
  const controller = loadAbort();
  const { query } = props;
  const URL = `${BACKEND_URL}/changelogs/get-all?` + HelpService.getQueryString(query);
  return {
    call: http.get<IChangeLogList>(URL, { signal: controller.signal }),
    controller,
  };
};

export const getAllChangeLogsByID = async (objectId: string) => {
  const URL = `${BACKEND_URL}/changelogs/${objectId}/get-one`;
  const response = await http.get<IChangeLogListById>(URL);
  return response;
};

export const getAllChangeLogsByResourceId = async (objectId: string) => {
  const URL = `${BACKEND_URL}/changelogs/get-all?resource_id=${objectId}&until=7`;
  const response = await http.get<IChangeLogList>(URL);
  return response;
};

export const getExportChangeLog = async (type: string) => {
  const URL = `${BACKEND_URL}/changelogs/generate-report?until=7`;
  const opts: AxiosRequestConfig = {
    params: {
      export_type: type,
    },
    responseType: "blob",
  };
  const response = await http.get(URL, opts);
  const responseDownload = downloadFileFromResponse(response, type, "ChangeLog");
  return responseDownload;
};

export const getExportChangeLogByID = async (type: string, ids: string[], name: string, query?: any) => {
  const URL = `${BACKEND_URL}/changelogs/create-report'` + HelpService.getQueryString(query);
  const body = {
    file_type: type,
    search: ids,
  };
  const opts: AxiosRequestConfig = {
    responseType: "blob",
  };
  const response = await http.post(URL, body, opts);
  const responseDownload = downloadFileFromResponse(response, type, name);
  return responseDownload;
};
