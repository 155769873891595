import React, { Dispatch, SetStateAction, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../../constants/global.css";
import "../../pages/styles/Refusal.css";
import TextEditor from "../TextEditor";
import InputClear from "../../atoms/InputClear";
import Switch from "react-switch";
import { ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrashAlt,
  faSearch,
  faPlus,
  faTimes,
  faShare,
  faMapMarkerAlt,
  faExternalLinkAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { Link } from "react-router-dom";
import "../../../constants/responsive.css";
import Star from "../../../images/Star.png";
import { useTranslation } from "react-i18next";
//Redux
import { useSelector } from "react-redux";
import { IRootState } from "../../../store/redux";
import ValidationAlert from "../../atoms/ValidationAlert";
import { typeUserData } from "../../../constants/userTypes";
import InputClearGuard from "../../atoms/InputClearGuard";
import FileAttachement from "../../atoms/FileAttachement/FileAttachement";
import usePictures from "../../../hooks/usePictures";
import DownloadImage from "../../atoms/DownloadImage";
import ImagesApi from "../../pages/ExchangesInfo/ImagesApi";
import LoadingImageSkeleton from "../LoadingSkeleton/LoadingImageSkeleton";
import FullLoading from "../../atoms/FullLoading";
import GoogleAutocomplete from "../../atoms/GoogleAutocomplete";
import TextAreaGuard from "../../atoms/TextAreaGuard";
import DownloadImageUrl from "../../atoms/DownloadImageUrl";

interface IDistributorProps {
  section: string;
  checked: boolean;
  handleChangeChecked: any;
  inputGroupCodeCreate: string;
  handleOnInputValueGroupCode: any;
  inputGroupNameCreate: string;
  handleOnInputValueGroupName: any;
  inputCodeCreate: string;
  handleOnInputValueCode: any;
  inputClientNumberCreate: string;
  handleOnInputValueClientNumber: any;
  inputNameCreate: string;
  handleOnInputValueName: any;
  inputAddressCreate: string;
  handleOnInputValueAddress: any;
  inputPhoneCreate: string;
  handleOnInputValuePhone: any;
  inputPostalCodeCreate: string;
  handleOnInputValuePostalCode: any;
  inputCityCreate: string;
  handleOnInputValueCity: any;
  inputEmailCreate: string;
  handleOnInputValueEmail: any;
  inputOpeningHoursCreate: string;
  handleOnInputValueHoursCreate: any;
  inputGeographicalCoordinatesCreate: string;
  handleOnInputValueGeographicalCoordinates: any;
  inputMapURLCreate: string;
  handleOnInputValueMapUR: any;
  inputResponsibleEmailCreate: string;
  setInputResponsibleEmailCreate: any;
  onClickSearch?: any;
  onClickCreate?: any;
  handleChange?: any;
  inputDescription?: any;
  infoCreate?: boolean;
  Distributorinfo?: boolean;
  openLink?: string;
  evaluation?: number;
  warningAlertStatus?: boolean;
  logo: any;
  inputLogo?: string;
  update?: boolean;
  loadingUpdate?: boolean;
  onPlaceChanged?: () => void;
  onLoad?: (autocomplete: google.maps.places.Autocomplete) => void;
  handleClickMap: () => void;
  initialValue?: string;
  inputDeliveryNumberCreate: string;
  setInputDeliveryNumberCreate: Dispatch<SetStateAction<string>>;
  isWeb: boolean;
  setIsWeb: (value: React.SetStateAction<boolean>) => void;
  onClickMembers?: any;
  setInputLogo?: any;
}

export default function DistribuotItemsCreate(props: IDistributorProps) {
  const { t, i18n } = useTranslation();
  const {
    setGroupCode,
    setGroupId,
    setGroupName,
    membersOfSetListMembers,
    membersOfSetListIdSelect,
    setMembersOfSetListMembers,
    setMembersOfSetListIdSelect,
  } = useContext(ApplicationContext);
  const { onLoad, onPlaceChanged, handleClickMap, onClickMembers, inputLogo } = props;
  //Redux
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected } = profile;
  const { warningAlertStatus, logo, inputResponsibleEmailCreate, setInputResponsibleEmailCreate, initialValue } = props;

  const RenderWarnings = () => {
    return (
      <div>
        <ValidationAlert message={t("ALERT-GLOBAL-FILL-FIELDS")} variant="warning" />
      </div>
    );
  };

  return (
    <div style={{ padding: "30px", margin: "3% 2.5%", width: "95%" }} className="containerBoxHead">
      <div style={{ borderBottom: "1px solid rgba(0,0,0,.125)" }}>
        <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
          {t("SEARCH.DISTRIBUTOR.TITLE-IDENTIFICATION")}
        </h4>
      </div>
      <div className="flex-inputs-responsive">
        <div className="full-width">
          <h6 style={{ margin: "28px 0" }}>{t("ALL.GENERAL.DISTRIBUTOR.TITLE-GROUP-CODE")}</h6>
          <div className="flex-inputs-responsive-info">
            <div className="full-width">
              <InputClearGuard
                readOnly
                type="text"
                name="GroupCode"
                placeholder=""
                style={{
                  heigth: "250px",
                  background: "var(--color-input-background-secondary)",
                }}
                currentValue={props.inputGroupCodeCreate}
                handleOnInputValue={props.handleOnInputValueGroupCode}
                roles={[`${typeUserData.admin}`, `${typeUserData.group}`]}
              />
            </div>
            <div>
              {nameRolSelected === typeUserData.admin && (
                <div style={{ display: "flex" }}>
                  <Tooltip title="Select" placement="bottom">
                    <div>
                      <FontAwesomeIcon
                        className="pointer"
                        icon={faSearch}
                        onClick={props.onClickSearch}
                        style={{
                          marginLeft: "8px",
                          width: "2Opx",
                          height: "20px",
                        }}
                      />
                    </div>
                  </Tooltip>
                  {props.Distributorinfo && (
                    <Tooltip title="Open" placement="bottom">
                      <div>
                        <Link to={`${props.openLink}`} style={{ color: "var(--color-main-text)" }}>
                          <FontAwesomeIcon
                            className="pointer"
                            icon={faShare}
                            style={{
                              marginLeft: "8px",
                              width: "2Opx",
                              height: "20px",
                            }}
                          />
                        </Link>
                      </div>
                    </Tooltip>
                  )}

                  <Tooltip title="Create" placement="bottom">
                    <div>
                      <FontAwesomeIcon
                        className="pointer"
                        icon={faPlus}
                        onClick={props.onClickCreate}
                        style={{
                          marginLeft: "8px",
                          width: "2Opx",
                          height: "20px",
                        }}
                      />
                    </div>
                  </Tooltip>
                  <Tooltip title="Remove" placement="bottom">
                    <div>
                      <FontAwesomeIcon
                        className="pointer"
                        icon={faTimes}
                        onClick={() => {
                          setGroupCode("");
                          setGroupName("");
                          setGroupId("");
                        }}
                        style={{
                          marginLeft: "8px",
                          width: "2Opx",
                          height: "20px",
                        }}
                      />
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="full-width">
          <h6 style={{ margin: "28px 0" }}>{t("SEARCH.DISTRIBUTOR.INPUT-GROUP-NAME")}</h6>
          <InputClearGuard
            readOnly
            type="text"
            name="GroupName"
            placeholder=""
            style={{
              heigth: "250px",
              background: "var(--color-input-background-secondary)",
            }}
            currentValue={props.inputGroupNameCreate}
            handleOnInputValue={props.handleOnInputValueGroupName}
            roles={[`${typeUserData.admin}`, `${typeUserData.group}`]}
          />
          {!props.inputGroupNameCreate && warningAlertStatus && <RenderWarnings />}
        </div>
      </div>
      <div>
        <h6 style={{ margin: "28px 0" }}>{t("ALL.GENERAL.TABLE.DIST-SET.TITLE-MEMBERS")}</h6>

        <div className="pillbox-completion ">
          {membersOfSetListMembers.map((item: any) => (
            <div className="input-members">
              <div style={{ display: "flex" }}>
                <div style={{ marginLeft: "8px" }}>
                  <Link to={`/distributor-info/${item.id}`} style={{ textDecoration: "none", color: "#000" }}>
                    {item.code}
                  </Link>
                </div>
                {nameRolSelected !== typeUserData.viewOnly && (
                  <div className="icon-members">
                    <FontAwesomeIcon
                      className="pointer icon-color"
                      icon={faTimesCircle}
                      onClick={() => {
                        setMembersOfSetListMembers(membersOfSetListMembers.filter((i: any) => i.id !== item.id));
                        setMembersOfSetListIdSelect(membersOfSetListIdSelect.filter((i: any) => i !== item.id));
                      }}
                      style={{
                        cursor: "pointer",
                        marginLeft: "8px",
                        marginRight: "8px",
                        marginTop: "4px",
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
          {nameRolSelected !== typeUserData.viewOnly && (
            <div className="input-members">
              <div className="icon-members">
                <FontAwesomeIcon
                  className="pointer icon-color"
                  icon={faSearch}
                  onClick={onClickMembers}
                  style={{
                    cursor: "pointer",
                    marginLeft: "8px",
                    marginTop: "4px",
                    width: "15px",
                    height: "15px",
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex-inputs-responsive">
        <div className="full-width">
          <h6 style={{ margin: "28px 0" }}>
            {t("ALL.GENERAL.DISTRIBUTOR.TITLE-CODE")}
            <span style={{ color: "var(--color-required)" }}>*</span>{" "}
          </h6>
          <InputClearGuard
            noSpaces
            type="text"
            name="Code"
            placeholder=""
            currentValue={props.inputCodeCreate}
            handleOnInputValue={props.handleOnInputValueCode}
            roles={[`${typeUserData.admin}`, `${typeUserData.group}`]}
          />
          {!props.inputCodeCreate && warningAlertStatus && <RenderWarnings />}
        </div>

        <div className="full-width">
          <h6 style={{ margin: "28px 0" }}>
            {t("ALL.GENERAL.DISTRIBUTOR.TITLE-NAME")}
            <span style={{ color: "var(--color-required)" }}>*</span>
          </h6>
          <InputClearGuard
            type="text"
            name="Name"
            placeholder=""
            currentValue={props.inputNameCreate}
            handleOnInputValue={props.handleOnInputValueName}
            roles={[`${typeUserData.admin}`, `${typeUserData.group}`]}
          />
          {!props.inputNameCreate && warningAlertStatus && <RenderWarnings />}
        </div>
        <div className="flex-inputs-responsive-info">
          <div style={{ width: "80px" }}>
            <h6 style={{ margin: "28px 0" }}>
              {t("ALL.GENERAL.DISTRIBUTOR.TITLE-VISIBLE")}?<span style={{ color: "var(--color-required)" }}>*</span>
            </h6>
            <Switch
              checked={props.checked}
              onChange={props.handleChangeChecked}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={20}
              width={48}
              className="react-switch"
              id="material-switch"
              disabled={
                nameRolSelected === typeUserData.distributor ||
                nameRolSelected === typeUserData.afterSales ||
                nameRolSelected === typeUserData.viewOnly
              }
            />
          </div>
          <div>
            <h6 style={{ margin: "28px 0" }}>
              Web?<span style={{ color: "var(--color-required)" }}>*</span>
            </h6>
            <Switch
              checked={props.isWeb}
              onChange={props.setIsWeb}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={20}
              width={48}
              className="react-switch"
              id="material-switch"
              disabled={
                nameRolSelected === typeUserData.distributor ||
                nameRolSelected === typeUserData.afterSales ||
                nameRolSelected === typeUserData.viewOnly
              }
            />
          </div>
        </div>
      </div>
      <div className="flex-inputs-responsive">
        <div className="full-width">
          <h6 style={{ margin: "28px 0" }}>{t("ALL.GENERAL.DISTRIBUTOR.TITLE-CLIENT-ACCOUNT-NUMBER")}</h6>
          <InputClearGuard
            type="text"
            name="ClientAccount"
            placeholder=""
            currentValue={props.inputClientNumberCreate}
            handleOnInputValue={props.handleOnInputValueClientNumber}
            roles={[`${typeUserData.admin}`, `${typeUserData.group}`]}
          />
        </div>

        <div className="full-width">
          <h6 style={{ margin: "28px 0" }}>{t("ALL.GENERAL.DISTRIBUTOR.TITLE-DELIVERY-ACCOUNT-NUMBER")}</h6>
          <InputClearGuard
            type="text"
            name="DeliveryAccount"
            placeholder=""
            currentValue={props.inputDeliveryNumberCreate}
            handleOnInputValue={props.setInputDeliveryNumberCreate}
            roles={[`${typeUserData.admin}`, `${typeUserData.group}`]}
          />
        </div>
      </div>
      <div className="flex-inputs-responsive align-start">
        <div className="c6">
          <ListGroup variant="flush">
            <ListGroup.Item>
              <div className="row">
                <div className="col">
                  <h6 style={{ margin: "28px 0" }}>{t("ALL.GENERAL.DISTRIBUTOR.TITLE-LOGO")}</h6>

                  <div>
                    <FileAttachement
                      fileName={logo.fileNameImage}
                      handleChangeStatus={logo.handleChangeStatus}
                      disabled={nameRolSelected !== typeUserData.admin}
                    />
                    {props.update && (
                      <div className="row my-4 px-4" style={{ textAlign: "center" }}>
                        {!logo.LoadingPicture ? <ImagesApi image={inputLogo} fullWidth /> : <LoadingImageSkeleton />}
                      </div>
                    )}
                    {props.update && (
                      <div className="col">
                        <DownloadImageUrl image={inputLogo} name="picture1" style={{ marginRight: 10 }} />

                        <FontAwesomeIcon
                          style={{ cursor: "pointer" }}
                          icon={faTrashAlt}
                          onClick={() => {
                            logo.handleDeleteImage();
                            props.setInputLogo(null);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
          </ListGroup>
        </div>
        <div className="c5">
          <h6 style={{ margin: "28px 0 10px" }}>
            <strong>{t("ALL.GENERAL.DISTRIBUTOR.TITLE-EVALUATION")}</strong>
          </h6>
          {props.evaluation === 1 && (
            <div>
              <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
            </div>
          )}
          {props.evaluation === 2 && (
            <div>
              <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
              <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
            </div>
          )}
          {props.evaluation === 3 && (
            <div>
              <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
              <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
              <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
            </div>
          )}
          {props.evaluation === 4 && (
            <div>
              <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
              <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
              <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
              <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
            </div>
          )}
          {props.evaluation === 5 && (
            <div>
              <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
              <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
              <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
              <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
              <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
            </div>
          )}
          <h6 style={{ margin: "28px 0" }}>
            <strong>Description</strong>
          </h6>
          <TextEditor
            height={"200px"}
            handleChange={props.handleChange}
            defaultValue={props.inputDescription}
            readOnly={nameRolSelected === typeUserData.distributor || nameRolSelected === typeUserData.afterSales}
          />
        </div>
      </div>
      <div className="flex-inputs-responsive">
        <div className="full-width">
          <h6 style={{ margin: "28px 0" }}>
            {t("CREATE.DISTRIBUTOR.INPUT-ADDRESS")}
            <span style={{ color: "var(--color-required)" }}>*</span>
          </h6>

          <GoogleAutocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} initialValue={initialValue} />
          {!props.inputAddressCreate && warningAlertStatus && <RenderWarnings />}
        </div>
        <div className="full-width">
          <h6 style={{ margin: "28px 0" }}>{t("ALL.GENERAL.DISTRIBUTOR.TITLE-PHONE")}</h6>
          <InputClearGuard
            type="text"
            name="Phone"
            placeholder=""
            currentValue={props.inputPhoneCreate}
            handleOnInputValue={props.handleOnInputValuePhone}
            roles={[`${typeUserData.admin}`, `${typeUserData.group}`]}
          />
        </div>
      </div>

      <div className="flex-inputs-responsive">
        <div className="full-width flex-inputs-responsive">
          <div className="full-width">
            <h6 style={{ margin: "28px 0" }}>{t("ALL.GENERAL.DISTRIBUTOR.TITLE-POSTAL-CODE")}</h6>
            <InputClearGuard
              type="text"
              name="PostalCode"
              placeholder=""
              currentValue={props.inputPostalCodeCreate}
              handleOnInputValue={props.handleOnInputValuePostalCode}
              roles={[`${typeUserData.admin}`, `${typeUserData.group}`]}
            />
          </div>
          <div className="full-width">
            <h6 style={{ margin: "28px 0" }}>{t("ALL.GENERAL.DISTRIBUTOR.TITLE-CITY")}</h6>
            <InputClearGuard
              type="text"
              name="City"
              placeholder=""
              currentValue={props.inputCityCreate}
              handleOnInputValue={props.handleOnInputValueCity}
              roles={[`${typeUserData.admin}`, `${typeUserData.group}`]}
            />
          </div>
        </div>
        <div className="full-width">
          <h6 style={{ margin: "28px 0" }}>{t("ALL.GENERAL.DISTRIBUTOR.TITLE-EMAIL")}</h6>
          <InputClearGuard
            type="email"
            name="Email"
            placeholder=""
            currentValue={props.inputEmailCreate}
            handleOnInputValue={props.handleOnInputValueEmail}
            roles={[`${typeUserData.admin}`, `${typeUserData.group}`]}
          />
        </div>
      </div>
      <div className="flex-inputs-responsive">
        <div className="full-width"></div>
        <div className="full-width">
          <h6 style={{ margin: "28px 0" }}>{t("ALL.GENERAL.DISTRIBUTOR.TITLE-RESPONSIBLE-EMAIL")}</h6>
          <InputClearGuard
            type="text"
            name="responsibleEmail"
            placeholder=""
            currentValue={inputResponsibleEmailCreate}
            handleOnInputValue={setInputResponsibleEmailCreate}
            roles={[`${typeUserData.admin}`, `${typeUserData.group}`]}
          />
          <h6 style={{ margin: "28px 0" }}>{t("CREATE.DISTRIBUTOR.INPUT-OPENING-HOURS")}</h6>
          <TextAreaGuard
            name="OpeningHours"
            placeholder=""
            style={{ height: "100px", padding: "10px" }}
            currentValue={props.inputOpeningHoursCreate}
            handleOnInputValue={props.handleOnInputValueHoursCreate}
            roles={[`${typeUserData.admin}`, `${typeUserData.group}`]}
          />
        </div>
      </div>

      <div className="flex-inputs-responsive">
        <div className="full-width">
          <h6 style={{ margin: "28px 0 0 0" }}>{t("CREATE.DISTRIBUTOR.INPUT-GEOGRAPHICAL-COORDINATES")}</h6>
          <div className="flex-inputs-responsive-info">
            <div className="full-width">
              <InputClearGuard
                type="text"
                name="GeographicalCoordinates"
                placeholder=""
                currentValue={props.inputGeographicalCoordinatesCreate}
                handleOnInputValue={props.handleOnInputValueGeographicalCoordinates}
                roles={[`${typeUserData.admin}`, `${typeUserData.group}`]}
              />
            </div>
            <div>
              <Tooltip title="Map" placement="bottom">
                <div>
                  <FontAwesomeIcon
                    onClick={handleClickMap}
                    className="grayBackgr"
                    icon={faMapMarkerAlt}
                    style={{ width: "45px", height: "50px" }}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="full-width">
          <h6 style={{ margin: "28px 0 0 0" }}>{t("CREATE.DISTRIBUTOR.INPUT-MAP-URL")}</h6>
          <div className="flex-inputs-responsive-info">
            <div className="full-width">
              <InputClearGuard
                type="text"
                name="MapURL"
                placeholder=""
                currentValue={props.inputMapURLCreate}
                handleOnInputValue={props.handleOnInputValueMapUR}
                roles={[`${typeUserData.admin}`, `${typeUserData.group}`]}
              />
            </div>
            <div>
              <Tooltip title="Open" placement="bottom">
                <div>
                  <a href={`${props.inputMapURLCreate}`} target="_blank" style={{ textDecoration: "none", color: "#222" }}>
                    <FontAwesomeIcon className="grayBackgr" icon={faExternalLinkAlt} style={{ width: "45px", height: "50px" }} />
                  </a>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      <FullLoading open={props.loadingUpdate} />
    </div>
  );
}
