import { useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import "../../../constants/global.css";
import "../styles/Refusal.css";
import ItemsInfo from "../../organisms/ItemsInfo";
import { Breadcrumb, Card } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

import { CreateRefusalReasons } from "../../../api/services/refusal_reasons_services";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { useTranslation } from "react-i18next";
import useToastMui from "../../../hooks/Toasts/useToastMui";

export default function CreateRefualReasons(props: any) {
  let history = useHistory();
  //Translations
  const { t } = useTranslation();
  const { handleCreateToast } = useToastMui();
  const { setRefusalReasonGivenLabel, setRefusalReasonId, setOpenCreateRefusalReason, openCreateRefusalReason } =
    useContext(ApplicationContext);
  const listLanguajeOptions = ["", `${t("LANGUAGE-LIST-ENGLISH")}`, `${t("LANGUAGE-LIST-FRENCH")}`];
  const [alertStatus, setAlertStatus] = useState<boolean>(false);
  //Create
  const [inputCodeCreate, setInputCodeCreate] = useState<string>("");
  const [inputDisplayOrderCreate, setInputDisplayOrderCreate] = useState<string>("");
  const [inputLabelCreate, setInputLabelCreate] = useState<string>("");
  const [inputDescription, setInputDescription] = useState<string>("");
  const [DropDownLanguajeCreate, setDropDownLanguajeCreate] = useState<string>(listLanguajeOptions[0]);
  const [checkedCreate, setCheckedCreate] = useState(false);

  // const { setRefulsalReasonsCode,setRefulsalReasonsDisplayOrder , openCreateRefusalReasons,setRefusalReasonsId,setOpenCreateRefusalReasons} = useContext(ApplicationContext)

  const handleChangeCreate = (nextChecked: any) => {
    setCheckedCreate(nextChecked);
  };

  //API STATE
  const [refusalReasonsCreated, setRefusalReasonsCreated] = useState<any>();
  const createRefusalReason = async (close: boolean) => {
    if (inputCodeCreate && inputDisplayOrderCreate && inputLabelCreate && DropDownLanguajeCreate) {
      try {
        const response = CreateRefusalReasons(
          inputLabelCreate,
          inputCodeCreate,
          inputDisplayOrderCreate,
          DropDownLanguajeCreate === `${t("LANGUAGE-LIST-ENGLISH")}` ? "English" : "French",
          inputDescription
        );
        const data = (await response).data;
        handleCreateToast(t("ALERT-REFUSAL-REASON-SUCCESS-CREATE"), "success");
        setRefusalReasonsCreated(data);
        if (!close) {
          history.push(`/refusal-reasons-info/${data?.result.id}`);
        } else {
          if (document.referrer) {
            history.go(-1);
          } else {
            history.push("/");
          }
        }
        return data;
      } catch (error: any) {
        console.log("error create refusal reason");
        console.log(error);
        if (error?.request?.status !== 401 && error?.request?.status !== 403)
          handleCreateToast(t("ALERT-REFUSAL-REASON-ERROR-CREATE"), "error");
      } finally {
        console.log("end");
      }
    } else {
      setAlertStatus(true);
    }
  };
  //Calling api from info page (product info)
  //Don't rediect
  const handleCreated = async () => {
    if (inputCodeCreate && inputDisplayOrderCreate && inputLabelCreate && DropDownLanguajeCreate) {
      try {
        const response = CreateRefusalReasons(
          inputLabelCreate,
          inputCodeCreate,
          inputDisplayOrderCreate,
          DropDownLanguajeCreate === `${t("LANGUAGE-LIST-ENGLISH")}` ? "English" : "French",
          inputDescription
        );
        const data = (await response).data;
        handleCreateToast(t("ALERT-REFUSAL-REASON-SUCCESS-CREATE"), "success");
        setRefusalReasonId(data.result.id);
        setRefusalReasonGivenLabel(data.result.label);
        setOpenCreateRefusalReason(!openCreateRefusalReason);

        return data;
      } catch (error: any) {
        console.log("error create refusal reason");
        console.log(error);
        if (error?.request?.status !== 401 && error?.request?.status !== 403)
          handleCreateToast(t("ALERT-REFUSAL-REASON-ERROR-CREATE"), "error");
      } finally {
        console.log("end");
      }
    } else {
      setAlertStatus(true);
    }
  };

  return (
    <div>
      <Breadcrumb>
        <Link to="/refusal-reasons">
          <Breadcrumb.Item href="/refusal-reasons">{t("CREATE.REFUSAL-REASON.HEADER-LINK")}</Breadcrumb.Item>
        </Link>
        <FontAwesomeIcon icon={faPlay} style={{ width: "10px", margin: "5px 15px" }} />
        <Breadcrumb.Item href="#">{t("CREATE.REFUSAL-REASON.HEADER-SECOND-LINK")}</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header>
          <div className="flex-warranty flex-between">
            <div className="container-warranty flex-start">
              <FontAwesomeIcon icon={faTimesCircle} className="icon_size" />
              <div>
                <h4 className="h4-stl" style={{ margin: "15px 5px" }}>
                  <strong>{t("CREATE.REFUSAL-REASON.TITLE-HEADER")}</strong>
                </h4>
              </div>
            </div>
            <div className="container-warranty flex-end  wrap-warranty">
              {!props.Created && <Button onClick={() => createRefusalReason(false)}>{t("CREATE.ALL.BUTTON-SAVE")}</Button>}
              {!props.Created ? (
                <Button
                  variant="outline-secondary"
                  style={{
                    height: "40px",
                  }}
                  onClick={() => {
                    createRefusalReason(true);
                  }}
                >
                  {t("CREATE.ALL.BUTTON-SAVE-CLOSE")}
                </Button>
              ) : (
                <Button
                  variant="outline-secondary"
                  style={{
                    height: "40px",
                  }}
                  onClick={() => handleCreated()}
                >
                  {t("CREATE.ALL.BUTTON-SAVE-CLOSE")}
                </Button>
              )}

              {!props.Created ? (
                <Button
                  variant="outline-secondary"
                  style={{
                    height: "40px",
                  }}
                  onClick={() => {
                    if (document.referrer) {
                      history.go(-1);
                    } else {
                      history.push("/");
                    }
                  }}
                >
                  {t("CREATE.ALL.BUTTON-CLOSE")}
                </Button>
              ) : (
                <Button
                  variant="outline-secondary"
                  style={{
                    height: "40px",
                  }}
                  onClick={() => setOpenCreateRefusalReason(!openCreateRefusalReason)}
                >
                  {t("CREATE.ALL.BUTTON-CLOSE")}
                </Button>
              )}
            </div>
          </div>
        </Card.Header>
        <ItemsInfo
          warningStatus={alertStatus}
          currentValueCode={inputCodeCreate}
          handleOnInputValueCode={(data: string) => {
            setInputCodeCreate(data);
          }}
          currentValueDisplay={inputDisplayOrderCreate}
          handleOnInputValueDisplay={(data: string) => {
            setInputDisplayOrderCreate(data);
          }}
          currentValueLabel={inputLabelCreate}
          handleOnInputValueLabel={(data: string) => {
            setInputLabelCreate(data);
          }}
          currentValueLanguaje={DropDownLanguajeCreate}
          onSelectDropdown={(item: string) => setDropDownLanguajeCreate(item)}
          languajeOptionsList={listLanguajeOptions}
          section="RefusalReasons"
          checked={checkedCreate}
          handleChangeChecked={handleChangeCreate}
          currentValueDescription={inputDescription}
          handleOnInputValueDescription={(data: string) => {
            setInputDescription(data);
          }}
        />
      </Card>
    </div>
  );
}
