import { t } from "i18next";
import React from "react";
import { Form, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
interface IModal {
  openExport: boolean;
  setOpenExport: any;
  checkNeedToExport: boolean[];
  setCheckNeedToExport: any;
  checkFormatToExport: boolean[];
  setCheckFormatToExport: any;
  checkedType: string;
  setCheckedType: any;
  onClick: any;
  exchange?: boolean;
  path?: string;
}
export default function ModalExport(props: IModal) {
  const {
    openExport,
    setOpenExport,
    checkNeedToExport,
    setCheckNeedToExport,
    checkFormatToExport,
    setCheckFormatToExport,
    checkedType,
    setCheckedType,
  } = props;
  const { onClick } = props;

  //Need To {t("NAV-ALL-ITEM3")}
  const handleChangeCheck1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === true) {
      setCheckNeedToExport([event.target.checked, false, false]);
    }
  };
  const handleChangeCheck2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === true) {
      setCheckNeedToExport([false, event.target.checked, false]);
    }
  };
  const handleChangeCheck3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === true) {
      setCheckNeedToExport([false, false, event.target.checked]);
    }
  };

  //Format export

  const handleChangeCheckExport1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckFormatToExport([event.target.checked, false, false, false]);
    setCheckedType("csv");
  };
  const handleChangeCheckExport2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckFormatToExport([false, event.target.checked, false, false]);
    setCheckedType("xlsx");
  };
  const handleChangeCheckExport3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckFormatToExport([false, false, event.target.checked, false]);
    setCheckedType("zip");
  };
  const handleChangeCheckExport4 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckFormatToExport([false, false, false, event.target.checked]);
    setCheckedType("pdf");
  };

  return (
    <>
      <Modal
        show={openExport}
        onHide={() => {
          setOpenExport(false);
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("NAV-ALL-ITEM3")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-lg-12 pt-4 pb-2" style={{ display: "none" }}>
              <h4 className="h4-stl">
                <strong>What do you need to export ?</strong>
              </h4>
            </div>
            {/* <label style={{display:'flex'}}>
                        <div className="col-1 col-lg-1">
                            <Form.Check checked={checkNeedToExport[0]} onChange={handleChangeCheck1}  name="gridRadios" style={{ display: 'inline' }} aria-label="option 1" />
                        </div>
                        <div className="col-11 col-lg-11">
                            <h6 style={{fontSize:'12px', margin:'8px 0 0'}}>Full data of object</h6>
                        </div>
                    </label> */}
            <label style={{ display: "none" }}>
              <div className="col-1 col-lg-1">
                <Form.Check
                  checked={checkNeedToExport[0]}
                  onChange={handleChangeCheck2}
                  name="gridRadios"
                  style={{ display: "inline" }}
                  aria-label="option 2"
                />
              </div>
              <div className="col-11 col-lg-11">
                <h6 style={{ fontSize: "12px", margin: "8px 0 0" }}>Only data displayed on this list</h6>
              </div>
            </label>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12 px-2 pt-4 pb-2">
              <h4 className="h4-stl">
                <strong>{t("ALL-EXPORT-FORMAT")}</strong>
              </h4>
            </div>
            <label style={{ display: "flex" }}>
              <div className="col-1 col-lg-1">
                <Form.Check
                  checked={checkFormatToExport[0]}
                  onChange={handleChangeCheckExport1}
                  type="radio"
                  name="gridRadios"
                  style={{ display: "inline" }}
                  aria-label="option 4"
                />
              </div>
              <div className="col-11 col-lg-11">
                <h6 style={{ fontSize: "12px", margin: "8px 0 0" }}>CSV</h6>
              </div>
            </label>
            <label style={{ display: "flex" }}>
              <div className="col-1 col-lg-1">
                <Form.Check
                  checked={checkFormatToExport[1]}
                  onChange={handleChangeCheckExport2}
                  type="radio"
                  name="gridRadios"
                  style={{ display: "inline" }}
                  aria-label="option 5"
                />
              </div>
              <div className="col-11 col-lg-11">
                <h6 style={{ fontSize: "12px", margin: "8px 0 0" }}>Microsoft Excel</h6>
              </div>
            </label>
            {props.exchange && (props?.path?.includes("Exchanged") || props?.path?.includes("Refused")) && (
              <label style={{ display: "flex" }}>
                <div className="col-1 col-lg-1">
                  <Form.Check
                    checked={checkFormatToExport[3]}
                    onChange={handleChangeCheckExport4}
                    type="radio"
                    name="gridRadios"
                    style={{ display: "inline" }}
                    aria-label="option 6"
                  />
                </div>
                <div className="col-11 col-lg-11">
                  <h6 style={{ fontSize: "12px", margin: "8px 0 0" }}>Pdf</h6>
                </div>
              </label>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClick} variant="primary">
            {t("NAV-ALL-ITEM3")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
