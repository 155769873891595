import React, { useContext, useEffect, useState } from "react";
import TransferModal from "../../organisms/TransferModal/TransferModal";
import { useTranslation } from "react-i18next";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { LocalStorageKeys, getInLocalStorageArray } from "../../../helpers/local-storage-manager";
export interface IRowHeaderBrand {
  field: string;
  headerName: string;
  sort: string;
}
interface IProps {
  open: boolean;
  setOpen: any;
}

export default function ReasonForExchangeTableTransfer(props: IProps) {
  const { reasonForExchangeTableTransferData, setReasonForExchangeTableTransferData } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const { open, setOpen } = props;

  const tableReasonExchangeInitial: string[] = [
    t("ALL.GENERAL-REASON-EXCHANGE.TITLE-CODE"),
    t("ALL.GENERAL-REASON-EXCHANGE.TITLE-LANGUAJE"),
    t("ALL.GENERAL-REASON-EXCHANGE.TITLE-ELIGIBLE"),
    t("ALL.GENERAL-REASON-EXCHANGE.TITLE-LABEL"),
    t("ALL.GENERAL-REASON-EXCHANGE.TITLE-DISPLAY-ORDER"),
  ];

  const [dataRight, setDataRight] = useState<string[]>([]);

  useEffect(() => {
    const initialArray = getInLocalStorageArray(LocalStorageKeys.REASON_FOR_EXCHANGE_TABLE);
    const initialArrayRight = getInLocalStorageArray(LocalStorageKeys.REASON_FOR_EXCHANGE_TABLE_R);
    setReasonForExchangeTableTransferData(initialArray?.length > 0 ? initialArray : tableReasonExchangeInitial);
    setDataRight(initialArrayRight?.length > 0 ? initialArrayRight : []);
  }, []);
  return (
    <TransferModal
      keyValueRight={`${LocalStorageKeys.REASON_FOR_EXCHANGE_TABLE_R}`}
      keyValue={`${LocalStorageKeys.REASON_FOR_EXCHANGE_TABLE}`}
      itemsLeft={reasonForExchangeTableTransferData}
      itemsRight={dataRight}
      setItemsLeft={setReasonForExchangeTableTransferData}
      setItemsRight={setDataRight}
      openConfirm={open}
      setOpenConfirm={setOpen}
    />
  );
}
