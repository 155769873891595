import React, { useState } from "react";
import "./styles/input.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

interface IInputProps {
  type: string;
  name: string;
  placeholder: string;
  style?: any;
  currentValue: any;
  handleOnInputValue: any;
  disabled?: boolean;
  readOnly?: boolean;
  defaultValue?: any;
  onKeyDown?: any;
  onKeyPress?: any;
  max?: number;
  min?: number;
  onSelect?: any;
  noSpaces?: boolean;
}
function InputPassword(props: IInputProps) {
  const { noSpaces } = props;
  const handleOnInputChange = (event: any) => {
    const data = event.target.value;
    if (!noSpaces) {
      props.handleOnInputValue(data);
    } else {
      props.handleOnInputValue(data.replace(/ /g, ""));
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => setShowPassword((prev) => !prev);

  return (
    <div className="h4-stl-form input-flex ">
      <input
        className="input-stlClear"
        onKeyDown={props.onKeyDown}
        onKeyPress={props.onKeyPress}
        readOnly={props.readOnly}
        disabled={props.disabled}
        type={showPassword ? "text" : "password"}
        max={props.max}
        min={props.min}
        name={props.name}
        placeholder={props.placeholder}
        style={props.style}
        onChange={(value) => handleOnInputChange(value)}
        value={props.currentValue}
        defaultValue={props.defaultValue}
        onSelect={props.onSelect}
      ></input>
      <div className="password-icon">
        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={handleShowPassword} style={{ width: "18px" }} />
      </div>
    </div>
  );
}
export default InputPassword;
