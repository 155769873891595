import { faThLarge, faUsers, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useContext } from "react";
import { Breadcrumb, Button, ButtonGroup, Card, Container, Dropdown, Nav } from "react-bootstrap";

import LateralContainer from "../../organisms/LateralContainer";
import "./CreateCustomer.css";
import CustomerTableIdentification from "../../organisms/CustomerTableIdentifiaction/CustomerTableIdentifiaction";
import CustomersAddressTable from "../../organisms/CustomersAddressTable/CustomersAddressTable";
import CustomersMeansOfContactTable from "../../organisms/CustomersMeansOfContactTable/CustomersMeansOfContactTable";
import CustomersOthersTable from "../../organisms/CustomersOthersTable/CustomersOthersTable";

import { CreateCustomer as customerCreate } from "../../../api/services/customer_services";
import { getAllUsersById } from "../../../api/services/user_services";
import { Link, useHistory } from "react-router-dom";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { useTranslation } from "react-i18next";
import FloatAlert from "../../atoms/FloatAlert";
const listTypeOptions = ["", "Professional", "Individual"];

const listBusinessOptions = [
  "",
  "Industry",
  "Mechanic",
  "Exterior renovation",
  "Interior renovation",
  "Coachbuilder",
  "Carpenter",
  "Coppersmith",
];

const listCountry = ["", "France", "United Kingdom", "Spain", "Germany"];

export default function CreateCustomer(props: any) {
  let history = useHistory();
  const { t, i18n } = useTranslation();

  const [checked, setChecked] = useState(false);
  const handleChange = (nextChecked: any) => {
    setChecked(nextChecked);
  };

  const {
    setCustomerId,
    setCustomerUserName,
    setCustomerFirstName,
    setCustomerLastName,
    setCustomerEmail,
    setCustomerMobilePhoneNumber,
    setOpenCreateCustomer,
    openCreateCustomer,
  } = useContext(ApplicationContext);

  const [tableRender, setTableRender] = useState("Address");

  const [username, setUsername] = useState<string>("");
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string>(listTypeOptions[0]);
  const [selectedTypeBusiness, setSelectedTypeBusiness] = useState<string>(listBusinessOptions[0]);
  const [isValidSiret, setIsValidSiret] = useState<boolean>(true);
  //ADDRESS
  const [address, setAddress] = useState<string>("");
  const [addressComplement, setAddressComplement] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [coordinates, setCoordinates] = useState<string>("");
  const [mapUrl, setMapUrl] = useState<string>("");
  const [selectedCountry, setSelectedCountry] = useState<string>(listTypeOptions[0]);

  //CONTACT
  const [email, setEmail] = useState<string>("");
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState<string>("");
  const [workPhoneNumber, setWorkPhoneNumber] = useState<string>("");
  const [homePhoneNumber, setHomePhoneNumber] = useState<string>("");
  const [faxNumber, setFaxNumber] = useState<string>("");

  //ALERTS
  const [successUpdated, setSuccessUpdated] = useState<boolean>(false);
  const [wrongAlertStatus, setWrongAlertStatus] = useState<boolean>(false);

  //others
  const [checkedAvailableGeneralTermsAccepted, setCheckedAvailableGeneralTermsAccepted] = useState(false);
  const handleChangeAvailableGeneralTermsAccepted = (nextCheckedAvailableGeneralTermsAccepted: any) => {
    setCheckedAvailableGeneralTermsAccepted(nextCheckedAvailableGeneralTermsAccepted);
  };
  const [checkedAvailablePrivacyPolicyAccepted, setCheckedAvailablePrivacyPolicyAccepted] = useState(false);
  const handleChangeAvailablePrivacyPolicyAccepted = (nextCheckedPrivacyPolicyAccepted: any) => {
    setCheckedAvailablePrivacyPolicyAccepted(nextCheckedPrivacyPolicyAccepted);
  };
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState<string>("");
  const [comments, setComments] = useState<string>("");

  //LateralContainer
  const [showLateralContainer, setShowLateralContainer] = useState(false);
  const lateralContainer = (content: any) => {
    return (
      <LateralContainer
        show={showLateralContainer}
        toggleShow={() => setShowLateralContainer(!showLateralContainer)}
        content={content}
      ></LateralContainer>
    );
  };

  //API STATE
  const [loading, setLoading] = useState<boolean>(false);
  const [brandCreated, setBrandCreated] = useState<any>();

  const [alertStatus, setAlertStatus] = useState<boolean>(false);

  const [alertEmailStatus, setAlertEmailStatus] = useState<boolean>(false);

  const RenderError = () => {
    return (
      <div>
        {wrongAlertStatus && (
          <FloatAlert
            message={t("ALERT-CUSTOMERS-ERROR-CREATE")}
            show={wrongAlertStatus}
            setShow={() => {
              setTimeout(function () {
                setWrongAlertStatus(false);
              }, 4000);
            }}
          />
        )}
      </div>
    );
  };

  const alertSuccess = () => {
    setSuccessUpdated(true);
  };

  const alertWrongGet = () => {
    setWrongAlertStatus(true);
  };

  const RenderSuccess = () => {
    return (
      <div>
        {successUpdated && (
          <FloatAlert
            message={t("ALERT-CUSTOMERS-SUCCESS-CREATE")}
            show={successUpdated}
            setShow={() => {
              setTimeout(function () {
                setSuccessUpdated(false);
              }, 4000);
            }}
            variant="success"
          />
        )}
      </div>
    );
  };

  const createCustomers = async () => {
    if (username && firstname && lastname && selectedType && email) {
      setLoading(!loading);
      if (selectedType === "Individual") {
        apiCreateCustomer();
      } else {
        if (selectedTypeBusiness && companyName) {
          apiCreateCustomer();
        } else {
          setAlertStatus(true);
        }
      }
    } else {
      setAlertStatus(true);
    }
  };

  const apiCreateCustomer = async () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const Minutes = date.getMinutes();

    let TodayDate = `${day}/${month}/${year} ${hour}:${Minutes}`;
    if (day <= 9) {
      TodayDate = `0${day}/${month}/${year} ${hour}:${Minutes}`;
    }
    if (month <= 9) {
      TodayDate = `${day}/0${month}/${year} ${hour}:${Minutes}`;
    }
    if (day <= 9 && month <= 9) {
      TodayDate = `0${day}/0${month}/${year} ${hour}:${Minutes}`;
    }
    try {
      const response = customerCreate(
        selectedType,
        selectedTypeBusiness,
        companyName,
        checkedAvailableGeneralTermsAccepted,
        checkedAvailablePrivacyPolicyAccepted,
        comments,
        username,
        firstname,
        lastname,
        email,
        "lasts_login",
        TodayDate,
        "image",
        workPhoneNumber,
        homePhoneNumber,
        mobilePhoneNumber,
        password,
        "English",
        true,
        "FacClientHome",
        address,
        addressComplement,
        coordinates,
        mapUrl,
        coordinates,
        city,
        selectedCountry,
        zipCode
      );
      const data = (await response).data;
      alertSuccess();
      setAlertEmailStatus(false);
      setBrandCreated(data);
      return data;
    } catch (error: any) {
      console.log("error createCustomers");
      console.log(error.response.data.message);
      if (error.response.data.message === `email: ${email} already exist`) {
        setAlertEmailStatus(true);
      }
      alertWrongGet();
    } finally {
      setLoading(false);
      console.log("end");
    }
  };

  //Calling api from info page (product info)
  //Don't rediect
  const handleCreated = async () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const Minutes = date.getMinutes();

    let TodayDate = `${day}/${month}/${year} ${hour}:${Minutes}`;
    if (day <= 9) {
      TodayDate = `0${day}/${month}/${year} ${hour}:${Minutes}`;
    }
    if (month <= 9) {
      TodayDate = `${day}/0${month}/${year} ${hour}:${Minutes}`;
    }
    if (day <= 9 && month <= 9) {
      TodayDate = `0${day}/0${month}/${year} ${hour}:${Minutes}`;
    }

    if (username && firstname && lastname && selectedType && email) {
      setLoading(!loading);
      if (selectedType === "Individual") {
        apiCreateHandleCustomer();
      } else {
        if (selectedTypeBusiness && companyName) {
          apiCreateHandleCustomer();
        } else {
          setAlertStatus(true);
        }
      }
    } else {
      // alertFillTheFields("Please fill in all the fields");
      setAlertStatus(true);
    }
  };

  const apiCreateHandleCustomer = async () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const Minutes = date.getMinutes();

    let TodayDate = `${day}/${month}/${year} ${hour}:${Minutes}`;
    if (day <= 9) {
      TodayDate = `0${day}/${month}/${year} ${hour}:${Minutes}`;
    }
    if (month <= 9) {
      TodayDate = `${day}/0${month}/${year} ${hour}:${Minutes}`;
    }
    if (day <= 9 && month <= 9) {
      TodayDate = `0${day}/0${month}/${year} ${hour}:${Minutes}`;
    }
    try {
      const response = customerCreate(
        selectedType,
        selectedTypeBusiness,
        companyName,
        checkedAvailableGeneralTermsAccepted,
        checkedAvailablePrivacyPolicyAccepted,
        comments,
        username,
        firstname,
        lastname,
        email,
        "lasts_login",
        TodayDate,
        "image",
        workPhoneNumber,
        homePhoneNumber,
        mobilePhoneNumber,
        password,
        "English",
        true,
        "FacClientHome",
        address,
        addressComplement,
        coordinates,
        mapUrl,
        coordinates,
        city,
        selectedCountry,
        zipCode
      );
      const data = (await response).data;
      alertSuccess();
      setCustomerId(data.result.id);
      if (data.result?.user) {
        const responseUser = getAllUsersById(data.result?.user);
        const dataUser = (await responseUser).data;
        setCustomerUserName(dataUser.result.username);
        setCustomerFirstName(dataUser.result.first_name);
        setCustomerLastName(dataUser.result.last_name);
        setCustomerEmail(dataUser.result.email);
        setCustomerMobilePhoneNumber(dataUser.result.mobile_phone);
      }
      setOpenCreateCustomer(!openCreateCustomer);
      return data;
    } catch (error: any) {
      console.log("error get user and customer");
      console.log(error);
      setAlertStatus(true);
    } finally {
      setLoading(false);
      console.log("end");
    }
  };

  const RenderAddressTable = () => {
    return (
      <Card>
        <CustomersAddressTable
          warningStatus={alertStatus}
          address={address}
          setAddress={(data: string) => {
            setAddress(data);
          }}
          addressComplement={addressComplement}
          setAddressComplement={(data: string) => {
            setAddressComplement(data);
          }}
          zipCode={zipCode}
          setZipCode={(data: string) => {
            setZipCode(data);
          }}
          city={city}
          setCity={(data: string) => {
            setCity(data);
          }}
          coordinates={coordinates}
          setCoordinates={(data: string) => {
            setCoordinates(data);
          }}
          mapUrl={mapUrl}
          setMapUrl={(data: string) => {
            setMapUrl(data);
          }}
          selectedCountry={selectedCountry}
          setSelectedCountry={(data: string) => {
            setSelectedCountry(data);
          }}
          listCountry={listCountry}
        />
      </Card>
    );
  };

  const RenderMeansOfContactTable = () => {
    return (
      <Card>
        <CustomersMeansOfContactTable
          warningEmailStatus={alertEmailStatus}
          warningStatus={alertStatus}
          email={email}
          setEmail={(data: string) => {
            setEmail(data);
          }}
          mobilePhoneNumber={mobilePhoneNumber}
          setMobilePhoneNumber={(data: string) => {
            setMobilePhoneNumber(data);
          }}
          workPhoneNumber={workPhoneNumber}
          setWorkPhoneNumber={(data: string) => {
            setWorkPhoneNumber(data);
          }}
          homePhoneNumber={homePhoneNumber}
          setHomePhoneNumber={(data: string) => {
            setHomePhoneNumber(data);
          }}
          faxNumber={faxNumber}
          setFaxNumber={(data: string) => {
            setFaxNumber(data);
          }}
        />
      </Card>
    );
  };

  const RenderOthersTable = () => {
    return (
      <Card>
        <CustomersOthersTable
          checkedAvailableGeneralTermsAccepted={checkedAvailableGeneralTermsAccepted}
          handleChangeAvailableGeneralTermsAccepted={handleChangeAvailableGeneralTermsAccepted}
          checkedAvailablePrivacyPolicyAccepted={checkedAvailablePrivacyPolicyAccepted}
          handleChangeAvailablePrivacyPolicyAccepted={handleChangeAvailablePrivacyPolicyAccepted}
          privacyPolicyAccepted={privacyPolicyAccepted}
          setPrivacyPolicyAccepted={(data: string) => {
            setPrivacyPolicyAccepted(data);
          }}
          comments={comments}
          setComments={(data: string) => {
            setComments(data);
          }}
        />
      </Card>
    );
  };

  return (
    <div>
      {lateralContainer(<Container></Container>)}

      <Breadcrumb>
        <Link to="/customers">
          <Breadcrumb.Item href="/customers">{t("CREATE.CUSTOMER.HEADER-SECOND-LINK")}</Breadcrumb.Item>
        </Link>
        <FontAwesomeIcon icon={faPlay} style={{ width: "10px", margin: "5px 15px" }} />
        <Breadcrumb.Item href="#">{t("CREATE.CUSTOMER.TITLE-HEADER")}</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div
          className="row"
          style={{
            borderBottom: "2px solid var(--color-border)",
            marginLeft: "0",
          }}
        >
          <div className="col-1 col-lg-1" style={{ display: "flex" }}>
            <FontAwesomeIcon
              icon={faUsers}
              style={{
                marginLeft: "50%",
                marginTop: "15px",
                width: "50px",
                height: "50px",
              }}
            />
          </div>
          <div className="col-11 col-lg-3">
            <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
              <strong>{t("CREATE.CUSTOMER.TITLE-HEADER")}</strong>
            </h4>
            <h5 className="h5-stl" style={{ margin: "15px 30px" }}>
              {t("TABLE.CUSTOMER.HEADER-TOTAL")}
            </h5>
          </div>
          <div
            className="col-12 col-lg-8 "
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "4%",
            }}
          >
            {!props.Created && (
              <Dropdown as={ButtonGroup} style={{ height: "40px", margin: "20px 20px" }}>
                <Button onClick={() => createCustomers()}>{t("CREATE.ALL.BUTTON-SAVE")}</Button>

                <Dropdown.Toggle split id="dropdown-split-basic" />

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      createCustomers();
                      history.go(0);
                    }}
                    href="#/action-1"
                  >
                    <FontAwesomeIcon icon={faThLarge} />
                    {t("CREATE.ALL.BUTTON-SAVE-MENU")}
                  </Dropdown.Item>
                  {/* <Dropdown.Item href="#/action-2"><FontAwesomeIcon icon={faThLarge} /> Save & Copy</Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            )}
            {!props.Created ? (
              <div
                onClick={() => {
                  createCustomers();
                  history.push("/customers");
                }}
                style={{ cursor: "pointer" }}
              >
                <h4 className="h4-stl px-3" style={{ margin: "28px 0" }}>
                  {t("CREATE.ALL.BUTTON-SAVE-CLOSE")}
                </h4>
              </div>
            ) : (
              <div
                onClick={() => {
                  handleCreated();
                }}
                style={{ cursor: "pointer" }}
              >
                <h4 className="h4-stl px-3" style={{ margin: "28px 0" }}>
                  {t("CREATE.ALL.BUTTON-SAVE-CLOSE")}
                </h4>
              </div>
            )}

            {!props.Created ? (
              <div style={{ cursor: "pointer" }}>
                <h4
                  className="h4-stl"
                  style={{ margin: "28px 0" }}
                  onClick={() => {
                    history.push("/customers");
                  }}
                >
                  {t("CREATE.ALL.BUTTON-CLOSE")}
                </h4>
              </div>
            ) : (
              <div style={{ cursor: "pointer" }}>
                <h4
                  className="h4-stl"
                  style={{ margin: "28px 0" }}
                  onClick={() => {
                    setOpenCreateCustomer(!openCreateCustomer);
                  }}
                >
                  {t("CREATE.ALL.BUTTON-CLOSE")}
                </h4>
              </div>
            )}
          </div>
        </div>
        <CustomerTableIdentification
          warningStatus={alertStatus}
          username={username}
          setUserName={(data: string) => {
            setUsername(data);
          }}
          firstname={firstname}
          setFirstname={(data: string) => {
            setFirstname(data);
          }}
          lastname={lastname}
          setLastname={(data: string) => {
            setLastname(data);
          }}
          password={password}
          setPassword={(data: string) => {
            setPassword(data);
          }}
          companyName={companyName}
          setCompanyName={(data: string) => {
            setCompanyName(data);
            // const postalCodeRegex = /^([0-9]{10})?$/;
            // setIsValidSiret(postalCodeRegex.test(data));
          }}
          selectedType={selectedType}
          setSelectedType={(data: string) => {
            setSelectedType(data);
          }}
          selectedTypeBusiness={selectedTypeBusiness}
          setSelectedTypeBusiness={(data: string) => {
            setSelectedTypeBusiness(data);
          }}
          listTypeOptions={listTypeOptions}
          listBusinessOptions={listBusinessOptions}
          isValidSiret={isValidSiret}
          setIsValidSiret={(data: boolean) => setIsValidSiret(data)}
        />
        <div className="m-4">
          <Nav variant="tabs" defaultActiveKey="1">
            <Nav.Item>
              <Nav.Link
                eventKey="1"
                style={{ color: "var(--color-main-text)" }}
                onClick={() => {
                  setTableRender("Address");
                }}
              >
                {t("ALL.GENERAL.CUSTOMER-HEADER-TITLE-ADDRESS")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="2"
                style={{ color: "var(--color-main-text)" }}
                onClick={() => {
                  setTableRender("MeansOfContact");
                }}
              >
                {t("ALL.GENERAL.CUSTOMER-HEADER-TITLE-MEANS-OF-CONTACT")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="3"
                style={{ color: "var(--color-main-text)" }}
                onClick={() => {
                  setTableRender("Others");
                }}
              >
                {t("CREATE.CUSTOMER.NAV-HEADER-TITLE-OTHERS")}
              </Nav.Link>
            </Nav.Item>
          </Nav>
          {tableRender === "Address" && <RenderAddressTable />}
          {tableRender === "MeansOfContact" && <RenderMeansOfContactTable />}
          {tableRender === "Others" && <RenderOthersTable />}
        </div>
      </Card>
      {RenderSuccess() }
     {RenderError()}
    </div>
  );
}
