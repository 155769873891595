import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../../constants/global.css";
import Switch from "react-switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEuroSign } from "@fortawesome/free-solid-svg-icons";
import "../../../constants/responsive.css";
import ValidationAlert from "../../atoms/ValidationAlert";
import { useTranslation } from "react-i18next";
import DropDownGuard from "../../atoms/DropDownGuard";
import { typeUserData } from "../../../constants/userTypes";
import InputClearGuard from "../../atoms/InputClearGuard";

export default function ProductTableStockAndPricing(props: any) {
  //Translations
  const { t, i18n } = useTranslation();
  const { warningAlertStatus, nameRolSelected } = props;

  const RenderWarnings = () => {
    return (
      <div>
        <ValidationAlert message={t("ALERT-GLOBAL-FILL-FIELDS")} variant="warning" />
      </div>
    );
  };
  return (
    <div className="card mt-lg-4 mt-0 ml-lg-2 ml-0">
      <div className="row my-4 px-lg-5 px-2">
        <div className="col-12 col-lg-12 sectionBoxTop py-2">
          <h4 className="h4-stl">{t("ALL.GENERAL.PRODUCT-HEADER-TITLE-STOCK-AND-PRICING")}</h4>
        </div>
        <div className="row">
          <div className="c1 my-4">
            <h4 className="h4-stl">
              <strong>
                {t("ALL.GENERAL.PRODUCT.TITLE-WARRANTY")} <span style={{ color: "var(--color-required)" }}>*</span>
              </strong>
            </h4>
            <div style={{ display: "flex", alignItems: "center" }}>
              <DropDownGuard
                options={props.listWarranty}
                selectedItem={props.warranty}
                onSelect={props.setWarranty}
                width="100%"
                roles={[`${typeUserData.admin}`]}
              />
            </div>
            {!props.warranty && warningAlertStatus && <RenderWarnings />}
          </div>

          <div className="c1 my-4">
            <h4 className="h4-stl">
              <strong>
                {t("ALL.GENERAL.PRODUCT.TITLE-AVAILABLE")} <span style={{ color: "var(--color-required)" }}>*</span>
              </strong>
            </h4>
            <Switch
              checked={props.checkedAvailable}
              onChange={props.handleChangeAvailable}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={20}
              width={48}
              className="react-switch"
              id="material-switch"
              disabled={nameRolSelected !== typeUserData.admin}
            />
          </div>
        </div>

        <div className="flex-inputs-responsive px-4">
          <div className="full-width">
            <h4 className="h4-stl">
              <strong>
                {t("ALL.GENERAL.PRODUCT.TITLE-UNIT-PRICE")} <span style={{ color: "var(--color-required)" }}>*</span>
              </strong>
            </h4>
            <div className="flex-inputs-responsive-info">
              <div className="full-width">
                <InputClearGuard
                  type="text"
                  name=""
                  placeholder="0.00"
                  style={{ width: "100%" }}
                  currentValue={props.unitPrice}
                  handleOnInputValue={props.setUnitPrice}
                  roles={[`${typeUserData.admin}`]}
                />
              </div>

              <div>
                <FontAwesomeIcon icon={faEuroSign} style={{ marginLeft: "8px", width: "2Opx", height: "20px" }} />
              </div>
              {!props.unitPrice && warningAlertStatus && <RenderWarnings />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
