import "./App.scss";
import React, { useState, useContext, useEffect, Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.css";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faUser } from "@fortawesome/free-solid-svg-icons";
import ValidationAlert from "./components/atoms/ValidationAlert";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./constants/global.css";
import "./components/pages/styles/Mainpage.css";
import LogoHeader from "./images/logo.png";
import { useMediaQuery } from "@mui/material";
import { ApplicationContext } from "./context/ApplicationContext";
import AppNavbar from "./AppNavbar";
import HeaderReturnF from "./components/atoms/HeaderReturnF";
import "./constants/responsive.css";
import LoadingStart from "./components/atoms/LoadingStart/LoadingStart";
import { updatePassword } from "./api/services/user_services";
import { useTranslation } from "react-i18next";
import { IRootState } from "./store/redux";
import InputClear from "./components/atoms/InputClear";
import Button from "react-bootstrap/Button";

import { useGetExchanges } from "./hooks/Exchanges/useGetExchanges";
import { typeUserData } from "./constants/userTypes";
import CompleteLoader from "./components/organisms/CompleteLoader/CompleteLoader";
import Login from "./components/pages/Login/Login";
import { ErrorBoundary } from "./helpers/ErrorBoundaries";
import GlobalSearch from "./components/molecules/GlobalSearch/GlobalSearch";
import GlobalList from "./components/molecules/GlobalSearch/GlobalList";
import ExportNotification from "./components/atoms/ExportNotification";
import SureRoute from "./components/pages/sureRoute/SureRoute";
import LoginNewAccount from "./components/pages/Login/LoginNewAccount";
import NavUserMenu from "./components/molecules/NavUserMenu/NavUserMenu";
import InputPassword from "./components/atoms/InputPassword";
//Pages
const ContentList = lazy(() => import("./components/pages/ContentList"));
const CreateContent = lazy(() => import("./components/pages/CreateContent"));
const Refusalreasons = lazy(() => import("./components/pages/RefusalReasons"));
const ContentInfo = lazy(() => import("./components/pages/ContentInfo"));
const BrandsList = lazy(() => import("./components/pages/BrandsList"));
const CreateBrand = lazy(() => import("./components/pages/CreateBrand"));
const BrandInfo = lazy(() => import("./components/pages/BrandInfo"));
const CreateGroup = lazy(() => import("./components/pages/CreateGroup"));
const GroupInfo = lazy(() => import("./components/pages/GroupInfo"));
const Group = lazy(() => import("./components/pages/Group/Group"));
const Distributor = lazy(() => import("./components/pages/Distributor/Distributor"));
const ReasonForExchange = lazy(() => import("./components/pages/ReasonForExchange"));
const ReasonForExchangeInfo = lazy(() => import("./components/pages/ReasonForExchange/ReasonForExchangeInfo"));
const Product = lazy(() => import("./components/pages/Products"));
const CreateReasonForExchange = lazy(() => import("./components/pages/ReasonForExchange/CreateReasonForExchange"));
const ProductsInfo = lazy(() => import("./components/pages/ProductsInfo/ProductsInfo"));
const RefusalReasonsInfo = lazy(() => import("./components/pages/RefusalReasons/RefusalReasonsInfo"));
const CreateRefualReasons = lazy(() => import("./components/pages/RefusalReasons/CreateRefusalReasons"));
const CreateDistributor = lazy(() => import("./components/pages/Distributor/CreateDistributor"));
const DistributorInfo = lazy(() => import("./components/pages/Distributor/Distributorinfo"));
const ChangeLogInfo = lazy(() => import("./components/pages/ChangeLogInfo/ChangeLogInfo"));
const Customers = lazy(() => import("./components/pages/Customers/Customers"));
const CreateProduct = lazy(() => import("./components/pages/CreateProduct/CreateProduct"));
const ChangeLog = lazy(() => import("./components/pages/ChangeLog/ChangeLog"));
const Users = lazy(() => import("./components/pages/Users/Users"));
const UserInfo = lazy(() => import("./components/pages/Users/UserInfo"));
const CreateUsers = lazy(() => import("./components/pages/Users/CreateUsers"));
const CreateCustomer = lazy(() => import("./components/pages/CreateCustomer/CreateCustomer"));
const CustomerInfo = lazy(() => import("./components/pages/CustomerInfo/CustomerInfo"));
const Exchanges = lazy(() => import("./components/pages/Exhanges/Exchanges"));
const CreateExchanges = lazy(() => import("./components/organisms/CreateExchanges/CreateExchanges"));
const ExchangesInfo = lazy(() => import("./components/pages/ExchangesInfo/ExchangesInfo"));

const ProductStates = lazy(() => import("./components/pages/ProductStates/ProductStates"));
const DistributorStats = lazy(() => import("./components/pages/DistributorStats/DistributorStasts"));
const CustomerStats = lazy(() => import("./components/pages/CustomerStats/CustomerStats"));
const ExchangesActivityStats = lazy(() => import("./components/pages/ExchangesStats/ExchangesActivityStats"));
const ExchangesPerProductsStats = lazy(() => import("./components/pages/ExchangesStats/ExchangesPerProducts"));
const ExchangesProductsPerDistrbutorsStats = lazy(() => import("./components/pages/ExchangesStats/ExchangesTableProductsPerDistributors"));
const UpdatePassword = lazy(() => import("./components/pages/UpdatePassword/UpdatePassword"));
const EmailValidation = lazy(() => import("./components/pages/EmailVerification/EmailVerification"));
const DistributorMap = lazy(() => import("./components/pages/DistributorMap/DistributorMap"));
const DistributorSetList = lazy(() => import("./components/pages/DistributorSet/DistributorSetList/DistributorSetList"));
const CreateDistributorSet = lazy(() => import("./components/pages/DistributorSet/DistributorSetCreate/DistributorSetCreate"));
const DistributorSetInfo = lazy(() => import("./components/pages/DistributorSet/DistributorSetInfo/DistributorSetInfo"));
const VerifyIdentity = lazy(() => import("./components/pages/VerifyIdentity/VerifyIdentity"));

//Bulks
const BrandBulk = lazy(() => import("./components/pages/Bulks/brands/BrandBulk"));
const ProductBulk = lazy(() => import("./components/pages/Bulks/products/ProductsBulk"));
const GroupBulk = lazy(() => import("./components/pages/Bulks/groups/GroupsBulk"));
const RefusalReasonsBulk = lazy(() => import("./components/pages/Bulks/refusalReasons/RefusalReasonsBulk"));
const ReasonsForExchangeBulk = lazy(() => import("./components/pages/Bulks/ReasonForExchangesBulk/ReasonForExchangeBulk"));
const ContentBulk = lazy(() => import("./components/pages/Bulks/contents/ContentsBulk"));
const CustomersBulk = lazy(() => import("./components/pages/Bulks/customers/CustomersBulk"));
const DistributorBulk = lazy(() => import("./components/pages/Bulks/distributors/DistributorBulk"));
const UserBulk = lazy(() => import("./components/pages/Bulks/users/UsersBulk"));
const DistributorSetBulk = lazy(() => import("./components/pages/Bulks/distributorSet/DistributorSetBulk"));
const ExchangesBulk = lazy(() => import("./components/pages/Bulks/exchanges/ExchangesBulk"));

function MainComponent() {
  const { t, i18n } = useTranslation();
  //Redux
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { token } = profile;
  const { nameRolSelected, profile_picture } = profile;
  const [curretNavigator, setCurretNavigator] = useState("");
  //Languaje

  const [open, setOpen] = useState<boolean>(true);
  const { typeUser, setTypeOfSearch, exportToggle } = useContext(ApplicationContext);

  const matchesMenu = useMediaQuery("(max-width:1150px)");
  const matches = useMediaQuery("(max-width:600px)");
  const matchesMobile = useMediaQuery("(max-width:550px)");
  const matchesFilter = useMediaQuery("(max-width:780px)");

  //menu fade
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const opend = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    i18n.changeLanguage(profile.languaje);

    let browser,
      usrAg = navigator.userAgent;
    usrAg = usrAg.toLowerCase();

    if (usrAg.indexOf("safari") > -1) {
      browser = "Apple Safari";
    }
    if (usrAg.indexOf("chrome") > -1) {
      browser = "Google chrome";
    }
    if (usrAg.indexOf("firefox") > -1) {
      browser = "Firefox";
    }
    if (`${browser}` !== "Firefox") {
      if (!document.referrer) {
        window.location.reload();
      }
    }

    setCurretNavigator(`${browser}`);
  }, []);
  useEffect(() => {
    if (matchesFilter) {
      setTypeOfSearch(1);
    }
  }, [matchesFilter]);

  const { full_name, sub, email } = profile;

  const handelOpen = () => {
    if (window.innerWidth < 1100) {
      setOpen(true);
    }
  };

  const pageWidth = window.innerWidth;

  //Alerts
  const [wrongAlertStatus, setWrongAlertStatus] = useState<boolean[]>([false, false]);
  const [warningAlertStatus, setWarningAlertStatus] = useState<boolean>(false);
  const alertWrongGet = (GetExchanges: boolean, Password: boolean) => {
    setWrongAlertStatus([GetExchanges, Password]);
  };

  const RenderWarningsSpecific = (props: any) => {
    const { message, variant = "warning" } = props;
    return (
      <div>
        <ValidationAlert message={message} variant={variant} />
      </div>
    );
  };
  const { loading } = useGetExchanges(token ? token : "");

  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");

  const generatePassword = async () => {
    if (newPassword === newPasswordConfirm) {
      try {
        if (sub) {
          const response = updatePassword(newPassword, `${email}`);
          const data = (await response).data;
          setNewPassword(data.result?.generated_password);
          return data;
        }
      } catch (error: any) {
        console.log("error update password");
        console.log(error);
        alertWrongGet(false, true);
      } finally {
        console.log("end");
        setOpenSearch(false);
      }
    } else {
      setWarningAlertStatus(true);
    }
  };

  const handleCloseBar = () => {
    setOpen(true);
  };

  const [openSearch, setOpenSearch] = useState(false);
  const showModal = () => {
    return (
      <>
        <Modal
          show={openSearch}
          onHide={() => {
            setOpenSearch(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("MODAL.CUSTOMER-TITLE")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-lg-12 p-4">
                <h4 className="h4-stl">
                  <strong>{t("LOGIN.ALL.PASSWORD")}</strong>
                </h4>
                <div
                  className="pb-4"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "90% 10%",
                    alignItems: "center",
                  }}
                >
                  <InputPassword
                    type="password"
                    name=""
                    placeholder=""
                    style={{ width: "100%" }}
                    currentValue={newPassword}
                    handleOnInputValue={(data: string) => {
                      setNewPassword(data);
                    }}
                  />
                </div>
                <h4 className="h4-stl">
                  <strong>{t("RESET-PASSWORD-INPUT-PASSWORD-CONFIRM")}</strong>
                </h4>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "90% 10%",
                    alignItems: "center",
                  }}
                >
                  <InputPassword
                    type="password"
                    name=""
                    placeholder=""
                    style={{ width: "100%" }}
                    currentValue={newPasswordConfirm}
                    handleOnInputValue={(data: string) => {
                      setNewPasswordConfirm(data);
                    }}
                  />
                </div>
                {warningAlertStatus && <RenderWarningsSpecific message={`${t("ALERT-GLOBAL-MATCH-PASSWORD")}`} />}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => generatePassword()} variant="success">
              {t("MODAL.CUSTOMER-BUTTON-CONFIRM")}
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setOpenSearch(false);
              }}
            >
              {t("MODAL.CUSTOMER-BUTTON-CANCEL")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const hideNavUrls = ["/forgot-password/validate", "/confirm-email", "/add-account", "/verify-identity"];
  const urlToHideNav = hideNavUrls.find((url) => window.location.pathname.includes(url));

  return (
    <BrowserRouter>
      {token != "" ? (
        <div>
          <div>
            <div
              className={!!urlToHideNav ? "" : "header-stl flex-between "}
              style={!!urlToHideNav ? { display: "none" } : { alignItems: "center" }}
            >
              <div className="container-warranty-header ">
                <FontAwesomeIcon
                  className="menuMain"
                  onClick={() => {
                    setOpen(!open);
                  }}
                  icon={faBars}
                  style={{ cursor: "pointer" }}
                />
                <img alt="" className="img-facom1" src={LogoHeader}></img>
              </div>
              <div>
                <GlobalSearch />
              </div>
              <div className="container-warranty-header">
                {!matches && <HeaderReturnF />}
                <div
                  style={{
                    cursor: "pointer",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    gap: "14px",
                  }}
                  onClick={handleClick}
                >
                  {}
                  <div>
                    {profile_picture ? (
                      <div
                        style={{
                          background: "var(--color-white)",
                          borderRadius: "3px",
                          cursor: "pointer",
                        }}
                      >
                        <img alt="" src={profile_picture} style={{ width: "30px" }} />
                      </div>
                    ) : (
                      <FontAwesomeIcon icon={faUser} style={{ cursor: "pointer" }} />
                    )}
                  </div>
                  {!matchesMobile && (
                    <div>
                      <h6 style={{ fontSize: "14px", margin: "0" }}>
                        <b>{full_name}</b>
                      </h6>
                    </div>
                  )}
                </div>
                <NavUserMenu
                  setOpenSearch={setOpenSearch}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  setAnchorEl={setAnchorEl}
                  opend={opend}
                />
              </div>
            </div>
            <div className={!!urlToHideNav ? "" : "container_stl header-pad"} style={{ display: "flex" }}>
              <div
                className={
                  !!urlToHideNav
                    ? ""
                    : curretNavigator === "Apple Safari"
                    ? !matchesMenu
                      ? `staticMenu nav1 side-menu-safari ${open ? "active" : ""}`
                      : `staticMenu nav1 side-menu-safari ${!open ? "active" : ""}`
                    : !matchesMenu
                    ? `staticMenu nav1 side-menu ${open ? "active" : ""}`
                    : `staticMenu nav1 side-menu ${!open ? "active" : ""}`
                }
                style={!!urlToHideNav ? { display: "none" } : { backgroundColor: "var(--color-nav-bg)", padding: 0 }}
              >
                <AppNavbar open={open} typeUser={typeUser} handleCloseBar={handleCloseBar} />
              </div>

              <div
                onClick={() => handelOpen()}
                className={
                  !!urlToHideNav
                    ? ""
                    : curretNavigator === "Apple Safari"
                    ? `px-3 py-3 c_body-safari ${open ? "active" : ""}`
                    : `px-3 py-3 c_body ${open ? "active" : ""}`
                }
                style={{ background: "var(--color-loading-bg-primary)" }}
              >
                <Suspense fallback={<CompleteLoader show />}>
                  <ErrorBoundary fallBackComponent={<>Something went wrong</>}>
                    <Switch>
                      <Route exact path="/" component={GlobalList} />
                      <Route exact path="/add-account" component={LoginNewAccount} />
                      <Route exact path="/confirm-email" component={EmailValidation} />
                      <Route exact path="/forgot-password/validate" component={UpdatePassword} />
                      <Route exact path="/akamai/sureroute-test-object.html" component={SureRoute} />
                      <Route exact path="/update-password" component={UpdatePassword} />
                      <Route exact path="/verify-identity" component={VerifyIdentity} />
                      {/* <Route exact path="/confirm-email" component={EmailValidation} />
                        <Route exact path="/update-password" component={UpdatePassword} /> */}
                      {(nameRolSelected === typeUserData.admin || nameRolSelected === typeUserData.viewOnly) && (
                        <Route exact path="/refusal-reasons" component={Refusalreasons} />
                      )}
                      {(nameRolSelected === typeUserData.admin || nameRolSelected === typeUserData.viewOnly) && (
                        <Route
                          exact
                          path="/refusal-reasons-info/:id"
                          render={(routerProps) => <RefusalReasonsInfo id={routerProps.match.params.id} />}
                        />
                      )}
                      {nameRolSelected === typeUserData.admin && (
                        <Route exact path="/create-refusal-reasons" component={CreateRefualReasons} />
                      )}

                      {(nameRolSelected === typeUserData.admin || nameRolSelected === typeUserData.viewOnly) && (
                        <Route exact path="/reasons-for-exchange" component={ReasonForExchange} />
                      )}
                      {(nameRolSelected === typeUserData.admin || nameRolSelected === typeUserData.viewOnly) && (
                        <Route
                          exact
                          path="/reasons-for-exchange-info/:id"
                          render={(routerProps) => <ReasonForExchangeInfo id={routerProps.match.params.id} />}
                        />
                      )}
                      {nameRolSelected === typeUserData.admin && (
                        <Route exact path="/create-reasons-for-exchange" component={CreateReasonForExchange} />
                      )}

                      {(nameRolSelected === typeUserData.admin || nameRolSelected === typeUserData.viewOnly) && (
                        <Route exact path="/content-list" component={ContentList} />
                      )}
                      {(nameRolSelected === typeUserData.admin || nameRolSelected === typeUserData.viewOnly) && (
                        <Route exact path="/content-info/:id" render={(routerProps) => <ContentInfo id={routerProps.match.params.id} />} />
                      )}
                      {nameRolSelected === typeUserData.admin && <Route exact path="/create-content" component={CreateContent} />}

                      <Route exact path="/brands-list" component={BrandsList} />
                      {nameRolSelected === typeUserData.admin && <Route exact path="/create-brand" component={CreateBrand} />}
                      <Route exact path="/brand-info/:id" render={(routerProps) => <BrandInfo id={routerProps.match.params.id} />} />

                      {nameRolSelected === typeUserData.admin && <Route exact path="/create-group" component={CreateGroup} />}
                      <Route exact path="/group-info" component={GroupInfo} />
                      <Route exact path="/group-info/:id" render={(routerProps) => <GroupInfo id={routerProps.match.params.id} />} />
                      {nameRolSelected === typeUserData.admin ? (
                        <Route exact path="/group" component={Group} />
                      ) : nameRolSelected === typeUserData.afterSales ? (
                        <Route exact path="/group" component={Group} />
                      ) : nameRolSelected === typeUserData.viewOnly ? (
                        <Route exact path="/group" component={Group} />
                      ) : (
                        ""
                      )}

                      <Route exact path="/distributor" component={Distributor} />
                      {nameRolSelected === typeUserData.admin && <Route exact path="/create-distributor" component={CreateDistributor} />}
                      <Route
                        exact
                        path="/distributor-info/:id"
                        render={(routerProps) => <DistributorInfo id={routerProps.match.params.id} />}
                      />
                      <Route exact path="/distributor-stats" component={DistributorStats} />
                      <Route exact path="/distributor-map" component={DistributorMap} />

                      <Route exact path="/product" component={Product} />
                      {nameRolSelected === typeUserData.admin && <Route exact path="/create-product" component={CreateProduct} />}
                      <Route exact path="/product-info/:id" render={(routerProps) => <ProductsInfo id={routerProps.match.params.id} />} />
                      <Route exact path="/product-stats" component={ProductStates} />

                      <Route exact path="/customers" component={Customers} />
                      {/* {nameRolSelected === typeUserData.admin && (
                          <Route
                            exact
                            path="/create-customer"
                            component={CreateCustomer}
                          />
                        )} */}
                      <Route exact path="/customer-info/:id" render={(routerProps) => <CustomerInfo id={routerProps.match.params.id} />} />
                      <Route exact path="/customer-stats" component={CustomerStats} />

                      {(nameRolSelected === typeUserData.admin || nameRolSelected === typeUserData.viewOnly) && (
                        <Route exact path="/change-log" component={ChangeLog} />
                      )}
                      <Route
                        exact
                        path="/change-log-info/:id"
                        render={(routerProps) => <ChangeLogInfo id={routerProps.match.params.id} />}
                      />

                      <Route exact path="/exchanges" component={Exchanges} />
                      {nameRolSelected === typeUserData.admin && <Route exact path="/create-exchanges" component={CreateExchanges} />}
                      <Route exact path="/exchange-info/:id" render={(routerProps) => <ExchangesInfo id={routerProps.match.params.id} />} />
                      <Route exact path="/exchanges/:type" render={(routerProps) => <Exchanges type={routerProps.match.params.type} />} />
                      <Route exact path="/exchanges-stats-activity" component={ExchangesActivityStats} />
                      <Route exact path="/exchanges-products-per-distributors" component={ExchangesProductsPerDistrbutorsStats} />
                      <Route exact path="/exchanges-products-per-date" component={ExchangesPerProductsStats} />
                      <Route exact path="/distributors-set-list" component={DistributorSetList} />
                      <Route exact path="/create-distributors-set" component={CreateDistributorSet} />
                      <Route
                        exact
                        path="/distributors-set-info/:id"
                        render={(routerProps) => <DistributorSetInfo id={routerProps.match.params.id} />}
                      />

                      {(nameRolSelected === typeUserData.admin || nameRolSelected === typeUserData.viewOnly) && (
                        <Route exact path="/users" component={Users} />
                      )}
                      {/* <Route exact path="/users/:type" render={(routerProps) => <Users type={routerProps.match.params.type} />} /> */}
                      <Route exact path="/user-info/:id" render={(routerProps) => <UserInfo id={routerProps.match.params.id} />} />
                      {nameRolSelected === typeUserData.admin && <Route exact path="/create-user" component={CreateUsers} />}
                      <Route exact path="/load" component={LoadingStart} />
                      {nameRolSelected === typeUserData.admin && <Route exact path="/brand-bulk" component={BrandBulk} />}
                      {nameRolSelected === typeUserData.admin && <Route exact path="/product-bulk" component={ProductBulk} />}
                      {nameRolSelected === typeUserData.admin && <Route exact path="/group-bulk" component={GroupBulk} />}
                      {nameRolSelected === typeUserData.admin && <Route exact path="/refusal-bulk" component={RefusalReasonsBulk} />}
                      {nameRolSelected === typeUserData.admin && (
                        <Route exact path="/reasons-exchange-bulk" component={ReasonsForExchangeBulk} />
                      )}
                      {nameRolSelected === typeUserData.admin && <Route exact path="/content-bulk" component={ContentBulk} />}
                      {nameRolSelected === typeUserData.admin && <Route exact path="/customer-bulk" component={CustomersBulk} />}
                      {nameRolSelected === typeUserData.admin && <Route exact path="/distributor-bulk" component={DistributorBulk} />}
                      {nameRolSelected === typeUserData.admin && <Route exact path="/user-bulk" component={UserBulk} />}
                      {nameRolSelected === typeUserData.admin && (
                        <Route exact path="/distributor-set-bulk" component={DistributorSetBulk} />
                      )}
                      {nameRolSelected === typeUserData.admin && <Route exact path="/exchange-bulk" component={ExchangesBulk} />}
                    </Switch>
                  </ErrorBoundary>
                </Suspense>
              </div>
            </div>
          </div>
          {loading && <LoadingStart />}
        </div>
      ) : (
        <Suspense fallback={<CompleteLoader show />}>
          <ErrorBoundary fallBackComponent={<>Something went wrong</>}>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/confirm-email" component={EmailValidation} />
              <Route exact path="/forgot-password/validate" component={UpdatePassword} />
              <Route exact path="/update-password" component={UpdatePassword} />
              <Route exact path="/verify-identity" component={VerifyIdentity} />
              <Route exact path="/akamai/sureroute-test-object.html" component={SureRoute} />
              <Route exact path="/refusal-reasons" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/refusal-reasons-info/:id" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/create-refusal-reasons" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>

              <Route exact path="/reasons-for-exchange" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/reasons-for-exchange-info/:id" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/create-reasons-for-exchange" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/content-list" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/content-info" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/content-info/:id" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/create-content" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>

              <Route exact path="/brands-list" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/create-brand" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/brand-info/:id" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>

              <Route exact path="/create-group" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/group-info" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/group-info/:id" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/group" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>

              <Route exact path="/distributor" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/create-distributor" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/distributor-info/:id" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/distributor-stats" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/distributor-map" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>

              <Route exact path="/product" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/create-product" component={Login} />
              <Route exact path="/product-info/:id" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/product-stats" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>

              <Route exact path="/customers" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/create-customer" component={Login}>
                {/* <Redirect to="/"> </Redirect> */}
              </Route>
              <Route exact path="/customer-info/:id" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>

              <Route exact path="/change-log" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/change-log-info" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>

              <Route exact path="/exchanges" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/create-exchanges" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/exchange-info" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/exchange-info/:id" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/exchanges/:type" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/exchanges-stats-activity" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/exchanges-products-per-distributors" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/exchanges-products-per-date" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>

              <Route exact path="/users" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/users/:type" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/user-info" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/user-info/:id" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/create-user" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/load" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>

              <Route exact path="/distributors-set-list" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/create-distributors-set" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/distributors-set-info/:id" component={Login}>
                {/* <Redirect push to="/">
                  {" "}
                </Redirect> */}
              </Route>
              <Route exact path="/brand-bulk" component={Login}></Route>
              <Route exact path="/product-bulk" component={Login} />
              <Route exact path="/group-bulk" component={Login}></Route>
              <Route exact path="/content-bulk" component={Login}></Route>
              <Route exact path="/customer-bulk" component={Login}></Route>
              <Route exact path="/refusal-bulk" component={Login}></Route>
              <Route exact path="/reasons-exchange-bulk" component={Login}></Route>
              <Route exact path="/distributor-bulk" component={Login}></Route>
              <Route exact path="/user-bulk" component={Login}></Route>
              <Route exact path="/distributor-set-bulk" component={Login}></Route>
              <Route exact path="/*" component={Login}></Route>
            </Switch>
          </ErrorBoundary>
        </Suspense>
      )}

      {exportToggle && <ExportNotification />}
      {showModal()}
    </BrowserRouter>
  );
}
export default MainComponent;
