import React, { useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../../constants/global.css";
import InputClear from "../../atoms/InputClear";
import { faMapMarkerAlt, faSearch, faEuroSign, faPlus, faTimes, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SliderTable from "../../atoms/SliderForm";
import Tooltip from "@mui/material/Tooltip";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { Link } from "react-router-dom";
import "../../../constants/responsive.css";
import ValidationAlert from "../../atoms/ValidationAlert";
// Translations
import { useTranslation } from "react-i18next";
import Star from "../../../images/Star.png";
import QRGenerator from "../../atoms/QRGenerator";
import TextAreaComment from "../../atoms/TextAreaComment";

export default function ExchangesDetailsTable(props: any) {
  // Translations
  const { t, i18n } = useTranslation();

  const { setReasonExchangeGivenReason, setReasonExchangeId } = useContext(ApplicationContext);
  const [qrCode, setQrCode] = useState<string>("");
  const { warningAlertStatus } = props;
  // Translations

  const RenderWarnings = () => {
    return (
      <div>
        <ValidationAlert message={t("ALERT-GLOBAL-FILL-FIELDS")} variant="warning" />
      </div>
    );
  };

  return (
    <div className="mx-2">
      <div className="p-3">
        <h4 className="h4-stl">
          <strong>{t("CREATE.EXCHANGES.INPUT-QR-CODE")}</strong>
        </h4>

        <div style={{}}>
          <InputClear
            type="text"
            name=""
            placeholder=""
            readOnly
            style={{
              width: "100%",
              background: "var(--color-input-disable)",
            }}
            currentValue={props.qrCode}
            handleOnInputValue={props.setQrCode}
          />
        </div>
        {!props.Create && (
          <div className="py-4">
            <QRGenerator value={`${props.number}`} />
          </div>
        )}
      </div>
      <div className="flex-inputs-responsive-exchange-input px-3 pb-3">
        <div className="full-width mt-4">
          <h4 className="h4-stl">
            <strong>{t("ALL.GENERAL-EXCHANGE.TITLE-GIVEN-REASON")}</strong>
          </h4>
          <div className="flex-inputs-responsive-info ">
            <div className="full-width">
              <InputClear
                type="text"
                name=""
                placeholder=""
                readOnly
                style={{
                  background: "var(--color-input-background-secondary)",
                }}
                currentValue={props.givenReason}
                handleOnInputValue={props.setGivenReason}
              />
            </div>

            <div>
              {props.Create ? (
                <div style={{ display: "flex" }}>
                  <Tooltip title="Select" placement="bottom">
                    <FontAwesomeIcon
                      className="pointer"
                      icon={faSearch}
                      onClick={props.onClickSearch}
                      style={{
                        width: "2Opx",
                        height: "20px",
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Create" placement="bottom">
                    <FontAwesomeIcon
                      className="pointer"
                      icon={faPlus}
                      onClick={props.onClickCreate}
                      style={{
                        marginLeft: "8px",
                        width: "2Opx",
                        height: "20px",
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Remove" placement="bottom">
                    <FontAwesomeIcon
                      className="pointer"
                      icon={faTimes}
                      onClick={() => {
                        setReasonExchangeId("");
                        setReasonExchangeGivenReason("");
                      }}
                      style={{
                        marginLeft: "8px",
                        width: "2Opx",
                        height: "20px",
                      }}
                    />
                  </Tooltip>
                  {props.selectedStatus && (
                    <Tooltip title="Open" placement="bottom">
                      <Link to={`${props.openLink}`} style={{ color: "var(--color-main-text)" }}>
                        <FontAwesomeIcon
                          className="pointer"
                          icon={faShare}
                          style={{
                            marginLeft: "8px",
                            width: "2Opx",
                            height: "20px",
                          }}
                        />
                      </Link>
                    </Tooltip>
                  )}
                </div>
              ) : (
                <Tooltip title="Open" placement="bottom">
                  <Link to={`${props.openLink}`} style={{ color: "var(--color-main-text)" }}>
                    <FontAwesomeIcon
                      className="pointer"
                      icon={faShare}
                      style={{
                        marginLeft: "8px",
                        width: "2Opx",
                        height: "20px",
                      }}
                    />
                  </Link>
                </Tooltip>
              )}
            </div>
          </div>
          {warningAlertStatus && <RenderWarnings />}
        </div>
        <div className="full-width mt-4">
          <h4 className="h4-stl">
            <strong>{t("CREATE.EXCHANGES.INPUT-CODE-FOR-DIRECT-EVALUATION")}</strong>
          </h4>
          <div style={{ display: "grid", width: "100%", alignItems: "center" }}>
            <InputClear
              type="text"
              name="codeForDirectEvaluation"
              placeholder=""
              readOnly
              style={{
                width: "100%",
                background: "var(--color-input-disable)",
              }}
              currentValue={props.codeForDirectEvaluation}
              handleOnInputValue={props.setCodeForDirectEvaluation}
            />
          </div>
        </div>
      </div>
      <div className="flex-inputs-responsive my-2 px-2">
        <h6 style={{ margin: "28px 0 10px" }}>
          <strong>Evaluation</strong>
        </h6>
        {props.evaluation === 1 && (
          <div>
            <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
          </div>
        )}
        {props.evaluation === 2 && (
          <div>
            <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
            <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
          </div>
        )}
        {props.evaluation === 3 && (
          <div>
            <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
            <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
            <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
          </div>
        )}
        {props.evaluation === 4 && (
          <div>
            <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
            <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
            <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
            <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
          </div>
        )}
        {props.evaluation === 5 && (
          <div>
            <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
            <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
            <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
            <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
            <img alt="" src={Star} style={{ paddingRight: "5px", width: "25px" }}></img>
          </div>
        )}
      </div>
      <div className="flex-inputs-responsive-exchange-input my-2 px-2">
        <div className="col-12 col-lg-12 mt-4">
          <h4 className="h4-stl">
            <strong>{t("CREATE.EXCHANGES.INPUT-DETAILS-COMMENTS")}</strong>
          </h4>
          <TextAreaComment
            name=""
            placeholder=""
            readOnly={!props.create}
            style={
              !props.create
                ? {
                    width: "100%",
                    background: "var(--color-input-disable)",
                    height: "194px",
                  }
                : {
                    width: "100%",
                    height: "194px",
                  }
            }
            currentValue={props.defaultValue}
            handleOnInputValue={props.handleChange}
          />
        </div>
      </div>
      <div className="flex-inputs-responsive-exchange-input my-2 px-2 mb-4">
        <div className="full-width mt-4 px-3">
          <h4 className="h4-stl">
            <strong>{t("ALL.GENERAL-EXCHANGE.TITLE-QUANTITY")}</strong>
          </h4>
          <SliderTable disabled={false} />
        </div>
        <div className="full-width mt-4">
          <h4 className="h4-stl">
            <strong>{t("CREATE.EXCHANGES.INPUT-UNIT-PRICE")}</strong>
          </h4>
          <div className="flex-inputs-responsive-info ">
            <div className="full-width">
              <InputClear
                type="text"
                name=""
                placeholder=""
                readOnly
                style={{
                  width: "100%",
                  background: "var(--color-input-disable)",
                }}
                currentValue={props.unitPrice}
                handleOnInputValue={props.setUnitPrice}
              />
            </div>
            <div>
              <FontAwesomeIcon
                icon={faEuroSign}
                style={{
                  width: "2Opx",
                  height: "20px",
                }}
              />
            </div>
          </div>
        </div>
        <div className="full-width mt-4">
          <h4 className="h4-stl">
            <strong>{t("CREATE.EXCHANGES.INPUT-TOTAL-COST")}</strong>
          </h4>
          <div className="flex-inputs-responsive-info ">
            <div className="full-width">
              <InputClear
                type="text"
                name=""
                placeholder=""
                readOnly
                style={{
                  width: "100%",
                  background: "var(--color-input-disable)",
                }}
                currentValue={props.totalCost}
                handleOnInputValue={props.setTotalCost}
              />{" "}
            </div>
            <div>
              <FontAwesomeIcon
                icon={faEuroSign}
                style={{
                  width: "2Opx",
                  height: "20px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
