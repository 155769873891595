import { useState, useContext } from "react";
import { IRefusalReasonList, IRefusalReasonsDOM } from "../../api/interfaces/interfaz_refusal_reasons";
import {
  deleteManyRefusalReasonse,
  getAllRefusalReasons,
  getExportRefusalReasons,
  getExportRefusalReasonsByID,
} from "../../api/services/refusal_reasons_services";
import { ApplicationContext } from "../../context/ApplicationContext";
import useAsync from "../useAsync";
import useFetchAndLoad from "../useFetchAndLoad";
import { createAddapterRefusalReason } from "./adapter/refusalReasonadapter";
import { t } from "i18next";
import useToastMui from "../Toasts/useToastMui";
import { useTranslation } from "react-i18next";

const useRefusalReasons = (code: string, language: string, displayOrder: string, label: string) => {
  const { setRefusalReasonListID, setExportToggle, setRefusalReasonLength, reasonForRefusalPage, setReasonForRefusalPage } =
    useContext(ApplicationContext);
  const { handleCreateToast } = useToastMui();
  const { i18n } = useTranslation();
  const [refusalReasonsArray, setRefusalReasonsArray] = useState<IRefusalReasonsDOM[]>();
  const [lastPage, setLastPage] = useState(0);
  const [searchToggle, setSearchToggle] = useState(false);
  const [deletedToogle, setDeletedToogle] = useState(false);
  const limit = 10;

  const [errorDeleteAlert, setErrorDeleteAlert] = useState<boolean[]>([false, false, false]);

  const [countCode, setCountCode] = useState(1);
  const [typeSort, setTypeSort] = useState("");

  const { loading, callEndpoint } = useFetchAndLoad();

  const getQuery = (code: string, language: string, displayOrder: string, label: string, sort?: string) => {
    const queryKeys: any = {
      language: language ? (language === `${t("LANGUAGE-LIST-ENGLISH")}` ? "English" : "French") : "",
      label: label,
      code_reason: code,
      display_order: displayOrder,
      sort: sort,
    };
    let query = {};
    for (let key in queryKeys) {
      if (queryKeys[key]) {
        query = { ...query, [key]: queryKeys[key] };
      }
    }
    return query;
  };

  const loadRefusalReasons = async (code: string, language: string, displayOrder: string, label: string, nowPage: number) => {
    let query = getQuery(code, language, displayOrder, label, typeSort);
    query = {
      ...query,
      limit: limit,
      offset: (nowPage - 1) * limit,
    };
    const response = await callEndpoint(getAllRefusalReasons({ query }), () =>
      handleCreateToast(t("ALERT-REFUSAL-REASON-ERROR-GET"), "error")
    );
    return response;
  };

  const adaptInfo = (data: IRefusalReasonList) => {
    const array: IRefusalReasonsDOM[] = createAddapterRefusalReason(data?.result?.items);
    setRefusalReasonsArray(array);
    setLastPage(data?.result?.total);
    setRefusalReasonLength(data?.result?.total);
  };

  useAsync(
    () => loadRefusalReasons(code, language, displayOrder, label, reasonForRefusalPage),
    adaptInfo,
    () => {},
    [reasonForRefusalPage, searchToggle, deletedToogle, typeSort]
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setReasonForRefusalPage(value);
  };

  const onSearch = () => {
    setSearchToggle((value) => !value);
    setReasonForRefusalPage(1);
  };

  const deleteManyRefusal = async (productsId: string[]) => {
    try {
      const response = deleteManyRefusalReasonse(productsId);
      const data = (await response).data;
      handleCreateToast(t("ALERT-REFUSAL-REASON-SUCCESS-DELETE"), "success");
      return data;
    } catch (error: any) {
      console.log("error deleted refusal reasons");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(t("ALERT-REFUSAL-REASON-ERROR-DELETE"), "error");
    } finally {
      console.log("end");
      // history.push("/refusal-reasons");
      // setOpenConfirm(false);
      setRefusalReasonListID([]);
      setReasonForRefusalPage(1);
      setDeletedToogle(!deletedToogle);
    }
  };

  const exportRefusalReason = async (checkedType: string, refusalReasonListID: string[], checkNeedToExport: boolean[]) => {
    setExportToggle(true);
    try {
      if (checkNeedToExport[0] === true) {
        await getExportRefusalReasons(checkedType);
      }
      if (checkNeedToExport[1] === true) {
        let query = getQuery(code, language, displayOrder, label);
        await getExportRefusalReasonsByID(
          checkedType,
          refusalReasonListID,
          i18n.language === "fr" ? "Raisons du refus" : "Refusal Reason",
          query
        );
      }
    } catch (error: any) {
      console.log("error deleted refusal reasons");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(t("ALERT-REFUSAL-REASON-ERROR-EXPORT"), "error");
    } finally {
      console.log("end");
      setExportToggle(false);
    }
  };

  // * ------------------------SORT----------------------------------//

  // sort

  const incrementCount = (key: string) => {
    setCountCode((prev) => prev + 1);

    if (countCode === 1) {
      setTypeSort(`asc(${key})`);
    }
    if (countCode === 2) {
      setTypeSort(`desc(${key})`);
      setCountCode(1);
    }
  };

  return {
    refusalReasonsArray,
    loading,
    exportRefusalReason,
    deleteManyRefusal,
    errorDeleteAlert,
    setErrorDeleteAlert,
    currentPage: reasonForRefusalPage,
    lastPage,
    loadRefusalReasons,
    handleChange,
    setCurrentPage: setReasonForRefusalPage,
    limit,
    onSearch,
    typeSort,
    incrementCount,
  };
};

const service = {
  useRefusalReasons,
};
export default service;
export { useRefusalReasons };
