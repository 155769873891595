import React from "react";
import "./styles/input.css";
import { useSelector } from "react-redux";
import { IRootState } from "../../store/redux";

interface IInputProps {
  name: string;
  placeholder: string;
  style: any;
  currentValue: any;
  handleOnInputValue?: any;
  readOnly?: boolean;
  roles: string[];
}
function TextAreaGuard(props: IInputProps) {
  //Redux
  const { roles } = props;
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected } = profile;
  const isValidRole: boolean = !!roles.find((item) => item === nameRolSelected);
  const handleOnInputChange = (event: any) => {
    const data = event.target.value;
    props.handleOnInputValue(data);
  };

  return (
    <div className="h4-stl-form">
      <form action="">
        <textarea
          className="input-stl"
          readOnly={!isValidRole}
          disabled={!isValidRole}
          name={props.name}
          placeholder={props.placeholder}
          style={props.style}
          onChange={(value) => handleOnInputChange(value)}
          value={props.currentValue}
        ></textarea>
      </form>
    </div>
  );
}
export default TextAreaGuard;
