import "./exchangesInfo.css";
interface Iimages {
  extension?: any;
  image: any;
  fullWidth?: boolean;
}
export default function ImageExchange(props: Iimages) {
  const { extension,image, fullWidth } = props;
  return (
    <div>
      <img className={fullWidth ? "fullWidth-image" : "image-api"} src={
        extension === "image/png"
        ? `data:image/png;base64,${image}`
        : extension === "image/jpg"
        ? `data:image/jpg;base64,${image}`
        : `data:image/jpeg;base64,${image}`
      } alt="" />
    </div>
  );
}
