import React, { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Button from "react-bootstrap/Button";
import { Dropdown, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faBuilding } from "@fortawesome/free-solid-svg-icons";
import "../../../constants/global.css";
import "../styles/Refusal.css";
import { Breadcrumb, Card } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import DistribuotItemsCreate from "../../organisms/DistributorItemsCreate/DistributorItemsCreate";
import Group from "../Group/Group";
import CreateGroup from "../CreateGroup";
import { ApplicationContext } from "../../../context/ApplicationContext";

import { createDistributor } from "../../../api/services/distributor_services";
import { getAllGroupsById } from "../../../api/services/group_services";
import "../../../constants/responsive.css";
import { useTranslation } from "react-i18next";
import usePictures from "../../../hooks/usePictures";
import FullLoading from "../../atoms/FullLoading";
import MapModal from "../../atoms/MapModal";
import DistributorSetList from "../DistributorSet/DistributorSetList/DistributorSetList";
import useToastMui from "../../../hooks/Toasts/useToastMui";
import { useMediaQuery } from "@mui/material";
import "../../../constants/global.css";

export default function CreateDistributor(props: any) {
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width:500px)");
  const { handleCreateToast } = useToastMui();
  const history = useHistory();
  const {
    groupId,
    groupCode,
    groupName,
    setGroupCode,
    setGroupId,
    setGroupName,
    openGroupTable,
    setOpenGroupTable,
    openCreateGroup,
    setOpenCreateGroup,
    setOpenCreateDistributor,
    openCreateDistributor,
    setOpenMembersOfSetSelect,
    openMembersOfSetSelect,
    membersOfSetListIdSelect,
    membersOfSetListMembers,
  } = useContext(ApplicationContext);
  const {
    setDistributorCode,
    setDistributorName,
    setDistributorGroupCode,
    setDistributorGroupName,
    setDistributorClientAccountNumer,
    setDistributorEmail,
    setDistributorId,
    setDistributorPhone,
  } = useContext(ApplicationContext);
  //Create
  const [inputCodeCreate, setInputCodeCreate] = useState<string>("");
  const [inputClientNumberCreate, setInputClientNumberCreate] = useState<string>("");
  const [inputDeliveryNumberCreate, setInputDeliveryNumberCreate] = useState<string>("");
  const [inputNameCreate, setInputNameCreate] = useState<string>("");
  const [inputAddressCreate, setInputAddressCreate] = useState<string>("");
  const [inputPhoneCreate, setInputPhoneCreate] = useState<string>("");
  const [inputPostalCodeCreate, setInputPostalCodeCreate] = useState<string>("");
  const [inputCityCreate, setInputCityCreate] = useState<string>("");
  const [inputEmailCreate, setInputEmailCreate] = useState<string>("");
  const [inputResponsibleEmailCreate, setInputResponsibleEmailCreate] = useState<string>("");
  const [inputOpeningHoursCreate, setInputOpeningHoursCreate] = useState<string>("");
  const [inputGeographicalCoordinatesCreate, setInputGeographicalCoordinatesCreate] = useState<string>("");
  const [inputMapURLCreate, setInputMapURLCreate] = useState<string>("");
  const [inputDescription, setInputDescription] = useState<string>("");
  const [isWeb, setIsWeb] = useState<boolean>(false);

  //Alerts

  const [warningAlertStatus, setWarningAlertStatus] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const logo = usePictures();

  useEffect(() => {
    setGroupCode("");
    setGroupName("");
    setGroupId("");
  }, []);

  const [checkedVisible, setCheckedVisible] = useState(true);
  const handleChangeCreate = (nextChecked: any) => {
    setCheckedVisible(nextChecked);
  };

  const handleCloseGroupsTable = () => setOpenGroupTable(false);
  const renderGroupsTable = () => {
    return (
      <div>
        <Modal
          show={openGroupTable}
          onHide={handleCloseGroupsTable}
          // backdrop="static"
          // keyboard={false}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Group Create={true} />
          </Modal.Body>
        </Modal>
      </div>
    );
  };
  const handleCloseGroupC = () => setOpenCreateGroup(false);
  const renderCreatGroup = () => {
    return (
      <div>
        <Modal
          show={openCreateGroup}
          onHide={handleCloseGroupC}
          // backdrop="static"
          // keyboard={false}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <CreateGroup Created={true} />
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  const handleCloseDistSelect = () => setOpenMembersOfSetSelect(false);
  const renderDistributorSelect = () => {
    return (
      <div>
        <Modal
          show={openMembersOfSetSelect}
          onHide={handleCloseDistSelect}
          // backdrop="static"
          // keyboard={false}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <DistributorSetList toSelect />
          </Modal.Body>
        </Modal>
      </div>
    );
  };
  //API STATE
  const [place, setPlace] = useState<any>();
  const [coordinates, setCoordinates] = useState<any>();
  const [openMap, setOpenMap] = useState<boolean>(false);

  //! ------------------------------GOOGLE MAPS --------------------------------//
  const onLoad = (autocomplete: any) => {
    setPlace(autocomplete);
  };

  const onPlaceChanged = () => {
    if (place !== null) {
      const data = place.getPlace();
      setInputMapURLCreate(data?.url);
      setInputAddressCreate(data?.formatted_address);
      setCoordinates({
        lat: data?.geometry.location.lat(),
        lng: data?.geometry.location.lng(),
      });
      setInputGeographicalCoordinatesCreate(`${data?.geometry.location.lat()};${data?.geometry.location.lng()} `);

      for (var i = 0; i < data.address_components.length; i++) {
        for (var j = 0; j < data.address_components[i].types.length; j++) {
          if (data.address_components[i].types[j] == "postal_code") {
            setInputPostalCodeCreate(data.address_components[i].long_name);
          }
          if (data.address_components[i].types[j] == "locality") {
            setInputCityCreate(data.address_components[i].long_name);
          }
        }
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const handleClickMap = () => {
    if (coordinates) {
      setOpenMap(true);
    }
  };
  const [distributorCreated, setDistributorCreated] = useState<any>();

  //! ------------------------------API CALLS--------------------------------//
  const createDistributors = async (close: boolean) => {
    setLoading(true);
    if (inputCodeCreate && inputNameCreate && inputAddressCreate) {
      try {
        const response = createDistributor(
          "111111111111111",
          groupId,
          inputNameCreate,
          inputCodeCreate,
          inputClientNumberCreate,
          checkedVisible,
          logo.picture ? logo.picture : { file_content: null, extension: null },
          0,
          inputDescription,
          inputEmailCreate,
          inputAddressCreate,
          inputPostalCodeCreate,
          inputCityCreate,
          inputPhoneCreate,
          inputOpeningHoursCreate,
          inputGeographicalCoordinatesCreate,
          inputMapURLCreate,
          inputResponsibleEmailCreate,
          inputDeliveryNumberCreate,
          isWeb,
          membersOfSetListIdSelect
        );
        const data = (await response).data;
        handleCreateToast(t("ALERT-DISTRIBUTORS-SUCCESS-CREATE"), "success");

        setDistributorCreated(data);

        if (!close) {
          history.push(`/distributor-info/${data?.result.id}`);
        } else {
          if (document.referrer) {
            history.go(-1);
          } else {
            history.push("/");
          }
        }
        return data;
      } catch (error: any) {
        console.log("error create Distributor");
        console.log(error);
        if (error?.request?.status !== 401 && error?.request?.status !== 403)
          handleCreateToast(t("ALERT-DISTRIBUTORS-ERROR-CREATE"), "error");
      } finally {
        console.log("end");
        setLoading(false);
      }
    } else {
      alertWarning();
      setLoading(false);
    }
  };

  //Calling api from info page (product info)
  //Don't rediect
  const handleCreated = async () => {
    setLoading(true);
    if (inputCodeCreate && inputNameCreate && inputAddressCreate) {
      try {
        const response = createDistributor(
          "111111111111111",
          groupId,
          inputNameCreate,
          inputCodeCreate,
          inputClientNumberCreate,
          checkedVisible,
          logo.picture ? logo.picture : { file_content: null, extension: null },
          0,
          inputDescription,
          inputEmailCreate,
          inputAddressCreate,
          inputPostalCodeCreate,
          inputCityCreate,
          inputPhoneCreate,
          inputOpeningHoursCreate,
          inputGeographicalCoordinatesCreate,
          inputMapURLCreate,
          inputResponsibleEmailCreate,
          inputDeliveryNumberCreate,
          isWeb,
          membersOfSetListIdSelect
        );
        const data = (await response).data;
        handleCreateToast(t("ALERT-DISTRIBUTORS-SUCCESS-CREATE"), "success");
        setDistributorId(data.result.id);
        setDistributorCode(data.result.code);
        setDistributorName(data.result.name);
        setDistributorClientAccountNumer(data.result.client_account_number);
        setDistributorPhone(data.result.address?.phone);
        setDistributorEmail(data.result.email);
        setOpenCreateDistributor(!openCreateDistributor);

        if (data.result?.group) {
          const responseGroup = getAllGroupsById(data.result?.group);
          const dataGroup = (await responseGroup).data;
          setDistributorGroupCode(dataGroup.result.code);
          setDistributorGroupName(dataGroup.result.name);
        }
        return data;
      } catch (error: any) {
        console.log("error create distributor");
        console.log(error);
        if (error?.request?.status !== 401 && error?.request?.status !== 403)
          handleCreateToast(t("ALERT-DISTRIBUTORS-ERROR-CREATE"), "error");
      } finally {
        console.log("end");
        setLoading(false);
      }
    } else {
      alertWarning();
      setLoading(false);
    }
  };
  //! --------------------------END API CALLS--------------------------------//

  //? ------------------------------ALERTS--------------------------------//

  const alertWarning = () => {
    setWarningAlertStatus(true);
  };

  //? ------------------------------END ALERTS--------------------------------//

  return (
    <div>
      <Breadcrumb>
        <Link to="/distributor">
          <Breadcrumb.Item href="/distributor">{t("TABLE.DISTRIBUTOR.TITLE.HEADER-LINK")}</Breadcrumb.Item>
        </Link>
        <FontAwesomeIcon icon={faPlay} style={{ width: "10px", margin: "5px 15px" }} />
        <Breadcrumb.Item href="#">{t("CREATE.DISTRIBUTOR.HEADER-SECOND-LINK")}</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header>
          <div className={matches ? "flex-stl flex-center flex-between" : "flex-warranty flex-between"}>
            <div className={matches ? "flex-stl flex-center" : "container-warranty flex-start"}>
              <FontAwesomeIcon icon={faBuilding} className="icon_size" />
              <h4 className={matches ? "h4-stl mx-2" : "h4-stl m-title"}>
                <strong>{t("CREATE.DISTRIBUTOR.HEADER-SECOND-LINK")}</strong>
              </h4>
            </div>

            <div className={matches ? "flex-stl flex-center" : "container-warranty flex-end  wrap-warranty"}>
              {matches ? (
                <Dropdown>
                  <Dropdown.Toggle variant="primary" style={{ marginRight: "0.5rem" }}>
                    {props.Created ? t("CREATE.ALL.BUTTON-SAVE-CLOSE") : t("CREATE.ALL.BUTTON-SAVE")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {!props.Created && (
                      <Dropdown.Item
                        onClick={() => {
                          createDistributors(false);
                        }}
                      >
                        {t("CREATE.ALL.BUTTON-SAVE")}
                      </Dropdown.Item>
                    )}

                    <Dropdown.Item onClick={() => createDistributors(true)}>{t("CREATE.ALL.BUTTON-SAVE-CLOSE")}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <div style={{ display: "flex" }}>
                  {!props.Created && (
                    <div style={{ height: "40px", marginRight: "10px" }}>
                      <Button
                        onClick={() => {
                          createDistributors(false);
                        }}
                      >
                        {t("CREATE.ALL.BUTTON-SAVE")}
                      </Button>
                    </div>
                  )}
                  {!props.Created ? (
                    <Button
                      variant="outline-secondary"
                      style={{
                        minWidth: "150px",
                        height: "40px",
                      }}
                      onClick={() => createDistributors(true)}
                    >
                      {t("CREATE.ALL.BUTTON-SAVE-CLOSE")}
                    </Button>
                  ) : (
                    <Button
                      variant="outline-secondary"
                      style={{
                        minWidth: "150px",
                        maxHeight: "40px",
                      }}
                      onClick={() => handleCreated()}
                    >
                      {t("CREATE.ALL.BUTTON-SAVE-CLOSE")}
                    </Button>
                  )}
                </div>
              )}

              {!props.Created ? (
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    if (document.referrer) {
                      history.go(-1);
                    } else {
                      history.push("/");
                    }
                  }}
                >
                  {t("CREATE.ALL.BUTTON-CLOSE")}
                </Button>
              ) : (
                <Button variant="outline-secondary" onClick={() => setOpenCreateDistributor(!openCreateDistributor)}>
                  {t("CREATE.ALL.BUTTON-CLOSE")}
                </Button>
              )}
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <DistribuotItemsCreate
            handleClickMap={handleClickMap}
            warningAlertStatus={warningAlertStatus}
            handleChange={(content: any) => {
              setInputDescription(content);
            }}
            onClickSearch={() => setOpenGroupTable(true)}
            onClickCreate={() => setOpenCreateGroup(true)}
            section="Distributor"
            checked={checkedVisible}
            handleChangeChecked={handleChangeCreate}
            infoCreate
            inputLogo=""
            inputGroupCodeCreate={groupCode}
            inputGroupNameCreate={groupName}
            inputCodeCreate={inputCodeCreate}
            inputClientNumberCreate={inputClientNumberCreate}
            inputNameCreate={inputNameCreate}
            inputAddressCreate={inputAddressCreate}
            inputPhoneCreate={inputPhoneCreate}
            inputPostalCodeCreate={inputPostalCodeCreate}
            inputCityCreate={inputCityCreate}
            inputEmailCreate={inputEmailCreate}
            inputOpeningHoursCreate={inputOpeningHoursCreate}
            inputGeographicalCoordinatesCreate={inputGeographicalCoordinatesCreate}
            inputMapURLCreate={inputMapURLCreate}
            inputResponsibleEmailCreate={inputResponsibleEmailCreate}
            setInputResponsibleEmailCreate={setInputResponsibleEmailCreate}
            handleOnInputValueGroupCode={(data: string) => {
              setGroupCode(data);
            }}
            handleOnInputValueGroupName={(data: string) => {
              setGroupName(data);
            }}
            handleOnInputValueCode={(data: string) => {
              setInputCodeCreate(data);
            }}
            handleOnInputValueClientNumber={(data: string) => {
              setInputClientNumberCreate(data);
            }}
            handleOnInputValueName={(data: string) => {
              setInputNameCreate(data);
            }}
            handleOnInputValueAddress={(data: string) => {
              setInputAddressCreate(data);
            }}
            handleOnInputValuePhone={(data: string) => {
              setInputPhoneCreate(data);
            }}
            handleOnInputValuePostalCode={(data: string) => {
              setInputPostalCodeCreate(data);
            }}
            handleOnInputValueCity={(data: string) => {
              setInputCityCreate(data);
            }}
            handleOnInputValueEmail={(data: string) => {
              setInputEmailCreate(data);
            }}
            handleOnInputValueHoursCreate={(data: string) => {
              setInputOpeningHoursCreate(data);
            }}
            handleOnInputValueGeographicalCoordinates={(data: string) => {
              setInputGeographicalCoordinatesCreate(data);
            }}
            handleOnInputValueMapUR={(data: string) => {
              setInputMapURLCreate(data);
            }}
            logo={logo}
            onLoad={onLoad}
            onPlaceChanged={onPlaceChanged}
            inputDeliveryNumberCreate={inputDeliveryNumberCreate}
            setInputDeliveryNumberCreate={setInputDeliveryNumberCreate}
            isWeb={isWeb}
            setIsWeb={(data: React.SetStateAction<boolean>) => setIsWeb(data)}
            onClickMembers={() => setOpenMembersOfSetSelect(true)}
          />
        </Card.Body>
      </Card>
      <FullLoading open={loading} />
      <MapModal show={openMap} setShow={setOpenMap} positions={[coordinates]} />
      {renderGroupsTable()}
      {renderCreatGroup()}
      {renderDistributorSelect()}
    </div>
  );
}
