import React from 'react';
import './styles/input.css'


interface IInputProps  {
    name:string,
    placeholder:string,
    style:any,
    currentValue:any,
    handleOnInputValue?:any
    readOnly?:boolean
}
function  TextAreaComment(props:IInputProps){
    const handleOnInputChange = (event:any)=>{
        const cliendData = event.target.value;
        props.handleOnInputValue(cliendData)
    }  
    return(
        <div className="h4-stl-form">
            <form action ="">
                <textarea className="input-stl"
                    readOnly = {props.readOnly}
                    name = {props.name} 
                    placeholder = {props.placeholder}
                    style = {props.style}
                    onChange = {(value) =>handleOnInputChange(value)}
                    value = {props.currentValue}
                    >
                    
                </textarea>
            </form>     
        </div>
    );
}
export default TextAreaComment;