import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWrench } from "@fortawesome/free-solid-svg-icons";
import ImagesApi from "../../../pages/ExchangesInfo/ImagesApi";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IRowProductFromAPI } from "../../../organisms/TableProducts";

export default function ProductTableResponsive({
  render,
  Create,
  handleProducts,
}: {
  render: IRowProductFromAPI[];
  Create?: boolean;
  handleProducts: Function;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div>
      {render?.map((row: IRowProductFromAPI) => (
        <Accordion
          sx={{
            backgroundColor: "#f7f7f7",
            borderRadius: "0.3rem 0.3rem",
            border: "solid 0.0625rem #e0e0e0",
            margin: "8px",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <div
              style={{ maxWidth: "230px", overflow: "hidden" }}
              className="flex-warranty flex-between"
              onClick={() => {
                !Create
                  ? history.push(`/product-info/${row._id}`)
                  : handleProducts(
                      row._id,
                      row.reference,
                      row.name,
                      row.brand_data?.code,
                      row.brand_data?.name,
                      row.picture,
                      row.type,
                      row.unit_price
                    );
              }}
            >
              <div className="container-warranty flex-start">
                <FontAwesomeIcon className="icon_size" icon={faWrench} />

                <div>
                  <h6 className="h6-stl" style={{ margin: "15px 5px" }}>
                    <strong>{row.reference}</strong>
                  </h6>
                </div>
              </div>
              <div className="container-warranty flex-end wrap-warranty"></div>
            </div>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: "#fff",
              fontSize: "12px",
            }}
          >
            <div>
              <div style={{ textAlign: "center" }}>
                <ImagesApi image={row?.picture} />
              </div>
              <ul>
                <li>
                  <h6 className="font-response">
                    <strong>{t("SORT.PRODUCT.INPUT-CODE-BRAND")}: </strong>
                    {row.brand_data.code}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{t("SORT.PRODUCT.INPUT-NAME-BRAND")}: </strong>
                    {row.brand_data.name}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{t("SORT.PRODUCT.INPUT-REFERENCE")}: </strong>
                    {row.reference}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{t("SORT.PRODUCT.INPUT-NAME")}: </strong>
                    {row.name}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{t("SORT.PRODUCT.INPUT-TYPE")}: </strong>
                    {row.type}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{t("ALL.GENERAL.PRODUCT.TITLE-PICTURE")}: </strong>
                    {row.picture}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{t("SORT.PRODUCT.INPUT-UNIT-PRICE")}: </strong>
                    {row.unit_price}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{t("SORT.PRODUCT.INPUT-AVAILABLE")}: </strong>
                    {row.available}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{t("SORT.PRODUCT.INPUT-WARRANTY")}: </strong>
                    {row.time_warranty}
                  </h6>
                </li>
              </ul>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
