import React, { useContext } from "react";
import LateralContainer from "../LateralContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputClear from "../../atoms/InputClear";
import { Button } from "react-bootstrap";
import DropDown from "../../atoms/DropDown";
import { faBoxes, faEllipsisH, faSortAlphaDown, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import { IBrandSearch } from "./interfaces";
import { ApplicationContext } from "../../../context/ApplicationContext";
import Tooltip from "@mui/material/Tooltip";
import { faThumbtack, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "@mui/material";
import "../../../constants/global.css";
import { useFilterContext } from "../../../context";
import AutoCompleteInput from "../../atoms/AutocopleteInput/AutocompleteInput";
import useAutocomplete from "../../atoms/AutocopleteInput/useAutocomplete";

export default function BrandSearch(props: IBrandSearch) {
  const {
    inputCodeSearch,
    setInputCodeSearch,
    inputNameSearch,
    setInputNameSearch,
    showLateralContainerSearch,
    setShowLateralContainerSearch,
  } = useFilterContext();
  const { searchWindowState, SetSearchWindowState, listSort, dropDownWhySort, setDropDownWhySort, handleSearch } = props;
  const { typeOfSearch, setTypeOfSearch } = useContext(ApplicationContext);
  const matches = useMediaQuery("(max-width:500px)");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleSearch();
    setShowLateralContainerSearch(!showLateralContainerSearch);
  };

  const name = useAutocomplete("/brands/get-all?autocomplete=true&name=", handleSearch, inputNameSearch, setInputNameSearch, "name");
  const code = useAutocomplete("/brands/get-all?autocomplete=true&code=", handleSearch, inputCodeSearch, setInputCodeSearch, "code");

  return (
    <LateralContainer
      show={typeOfSearch === 1 ? showLateralContainerSearch : false}
      toggleShow={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
      content={
        <form action="/" onSubmit={handleSubmit}>
          <div className="row sectionBoxTop">
            <div className="col-2 col-lg-2 pb-2" style={{ display: "flex" }}>
              <FontAwesomeIcon
                icon={faBoxes}
                style={{
                  marginLeft: "50%",
                  marginTop: "0px",
                  width: "50px",
                  height: "50px",
                }}
              />
            </div>
            <div className="col-10 col-lg-10">
              <h4 className="h4-stl" style={{ margin: "15px 20px 15px" }}>
                <strong>{t("SEARCH.BRAND.TITLE-SEARCH-BRANDS")}</strong>
              </h4>
            </div>
          </div>
          {searchWindowState === "filterList" ? (
            <div className="cssanimation sequence fadeInTop">
              {matches ? (
                <>
                  <div className="filterBox my-4">
                    <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                      {t("SEARCH.BRAND.DEFINITION")}
                    </h4>
                    <div className="flex-stl flex-direction-column px-4 my-2">
                      <div>
                        <h5 className="h5-stl Right my-2">{t("SEARCH.BRAND.CODE")}</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <AutoCompleteInput {...code} freeSolo />
                        </div>
                        {/* <div className="mx-2">
                            <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} />
                          </div> */}
                      </div>
                    </div>
                    <div className="flex-stl flex-direction-column px-4 my-4">
                      <div>
                        <h5 className="h5-stl Right">{t("SEARCH.BRAND.NAME")}</h5>
                      </div>
                      <div className="flex-stl">
                        <div className="full-width">
                          <AutoCompleteInput {...name} freeSolo />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray px-4" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Tooltip title={`${t("FILER-TO-COLUMNS")}`} placement="bottom">
                      <div style={{ width: "60px" }}>
                        <FontAwesomeIcon
                          className="grayBackgr"
                          style={{ width: "47px", height: "47px" }}
                          icon={faThumbtack}
                          onClick={() => {
                            setTypeOfSearch(0);
                          }}
                        />
                      </div>
                    </Tooltip>
                    {/* <FontAwesomeIcon
                      className="grayBackgr"
                      icon={faSortAlphaDown}
                      onClick={() => SetSearchWindowState("SortWindow")}
                      style={{ width: "47px", height: "47px" }}
                    /> */}
                    <Button
                      variant="primary"
                      // style={{ margin: "15px 30px" }}
                      onClick={() => {
                        handleSearch();
                        setShowLateralContainerSearch(!showLateralContainerSearch);
                      }}
                    >
                      {t("SEARCH.BRAND.SEARCH")}
                    </Button>
                    <h4
                      className="h4-stl my-0 grayBackgr"
                      style={{ cursor: "pointer", marginLeft: "8px" }}
                      onClick={() => {
                        setInputCodeSearch("");
                        setInputNameSearch("");
                        handleSearch();
                      }}
                    >
                      {t("SEARCH.BRAND.RESET")}
                    </h4>
                    <h4
                      className="h4-stl my-0 mx-2 grayBackgr"
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                    >
                      {t("SEARCH.BRAND.CLOSE")}
                    </h4>
                  </div>
                </>
              ) : (
                <>
                  <div className="filterBox my-4">
                    <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
                      {t("SEARCH.BRAND.DEFINITION")}
                    </h4>
                    <div className="row py-2">
                      <div className="col-1 col-lg-1"></div>
                      <div className="col-11 col-lg-2 Right">
                        <h5 className="h5-stl Right">{t("SEARCH.BRAND.CODE")}</h5>
                      </div>
                      <div className="col-8 col-lg-6">
                        <AutoCompleteInput {...code} freeSolo />
                      </div>
                      <div className="col-2">{/* <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} /> */}</div>
                    </div>
                    <div className="row py-2 mb-4">
                      <div className="col-1 col-lg-1"></div>
                      <div className="col-11 col-lg-2">
                        <h5 className="h5-stl Right">{t("SEARCH.BRAND.NAME")}</h5>
                      </div>
                      <div className="col-8 col-lg-6">
                        <AutoCompleteInput {...name} freeSolo />
                      </div>

                      <div className="col-2">{/* <FontAwesomeIcon icon={faEllipsisH} style={{ width: "20px", height: "20px" }} /> */}</div>
                    </div>
                  </div>
                  <div className="bg-gray px-4" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Tooltip title={`${t("FILER-TO-COLUMNS")}`} placement="bottom">
                      <div style={{ width: "60px" }}>
                        <FontAwesomeIcon
                          className="grayBackgr"
                          style={{ width: "47px", height: "47px" }}
                          icon={faThumbtack}
                          onClick={() => {
                            setTypeOfSearch(0);
                          }}
                        />
                      </div>
                    </Tooltip>
                    {/* <FontAwesomeIcon
                      className="grayBackgr"
                      icon={faSortAlphaDown}
                      onClick={() => SetSearchWindowState("SortWindow")}
                      style={{ width: "47px", height: "47px" }}
                    /> */}
                    <Button
                      variant="primary"
                      style={{ margin: "15px 30px" }}
                      onClick={() => {
                        handleSearch();
                        setShowLateralContainerSearch(!showLateralContainerSearch);
                      }}
                    >
                      {t("SEARCH.BRAND.SEARCH")}
                    </Button>
                    <h4
                      className="h4-stl grayBackgr"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setInputCodeSearch("");
                        setInputNameSearch("");
                        handleSearch();
                      }}
                    >
                      {t("SEARCH.BRAND.RESET")}
                    </h4>
                    <h4
                      className="h4-stl mx-2 grayBackgr"
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                    >
                      {t("SEARCH.BRAND.CLOSE")}
                    </h4>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="cssanimation sequence fadeInBottom">
              <div className="sectionBoxTop pb-4">
                <div
                  className="py-3 my-2"
                  style={{
                    backgroundColor: "var(--color-border)",
                    display: "flex",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    onClick={() => SetSearchWindowState("filterList")}
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "2em",
                      cursor: "pointer",
                    }}
                  />
                  <h4 className="h4-stl" onClick={() => SetSearchWindowState("filterList")} style={{ cursor: "pointer" }}>
                    <strong>{t("SEARCH.BRAND.SORT-ORDER")}</strong>
                  </h4>
                </div>
                <div>
                  <DropDown
                    options={listSort}
                    selectedItem={dropDownWhySort}
                    onSelect={(item: string) => setDropDownWhySort(item)}
                    width="20vw"
                  />
                </div>
              </div>
              <div>
                <Button
                  variant="primary"
                  style={{
                    margin: "15px 30px",
                    display: "flex",
                    float: "right",
                  }}
                  onClick={() => SetSearchWindowState("filterList")}
                >
                  Apply
                </Button>
              </div>
            </div>
          )}
          <input type="submit" value="" style={{ display: "none" }}></input>
        </form>
      }
    ></LateralContainer>
  );
}
