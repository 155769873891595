import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useFetchAndLoad from "../../../hooks/useFetchAndLoad";
import { BACKEND_URL } from "../../../config/settings.json";
import { getApisInfo } from "../../../api/services/user_services";
import { LocalStorageKeys, setInLocalStorage } from "../../../helpers/local-storage-manager";
import { fromInfoToTranslatedStatus } from "../../../utilities/excangesStatusTranslations";
export default function useGlobalAutocomplete(url: string, value: any, setValue: Dispatch<SetStateAction<any>>) {
  const [options, setOptions] = useState<any[]>([]);
  const { callEndpoint, loading } = useFetchAndLoad();

  useEffect(() => {
    if (value?.length < 3) return;

    const delayDebounceFn = setTimeout(() => {
      callApis();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [value]);

  const callApis = async () => {
    const response = callEndpoint(await getApisInfo(`${BACKEND_URL}${url}${value}`));
    const data = (await response).data;

    const brandArray = data.result.brands?.map((item: any) => {
      return {
        label: item.name,
        resource: "brand",
        item1: item.code,
        item2: item.website,
        url: `/brand-info/${item.id}`,
      };
    });
    const productsArray = data.result.products?.map((item: any) => {
      return {
        label: `${item.reference} - ${item.name}`,
        resource: "products",
        item1: ` ${item?.time_warranty}`,
        item2: item.old_reference,
        url: `/product-info/${item.id}`,
      };
    });

    const groupArray = data.result.groups?.map((item: any) => {
      return {
        label: item.name,
        resource: "groups",
        item1: `${item?.code}`,
        item2: ``,
        url: `/group-info/${item.id}`,
      };
    });
    const distributorsArray = data.result.distributors?.map((item: any) => {
      return {
        label: item.name,
        resource: "distributors",
        item1: `${item?.code} `,
        item2: `${item?.address?.postal_code} ${item?.address?.phone}`,
        url: `/distributor-info/${item.id}`,
      };
    });
    const distributorsSetArray = data.result.distributors_set?.map((item: any) => {
      return {
        label: item.name,
        resource: "distributors-set",
        item1: `${item?.origin}`,
        item2: ``,
        url: `/distributors-set-info/${item.id}`,
      };
    });
    const contentArray = data.result.contents?.map((item: any) => {
      return {
        label: item.placeholder,
        resource: "contents",
        item1: "",
        item2: "",
        url: `/content-info/${item.id}`,
      };
    });
    const customersArray = data.result.customers?.map((item: any) => {
      return {
        label: `${item?.user_data.username}`,
        resource: "customer",
        item1: `${item?.account_type} ${item?.business}`,
        item2: `${item?.user_data?.address?.zipcode} ${item?.user_data?.address?.address}`,
        url: `/customer-info/${item.id}`,
      };
    });

    const exchangesArray = data.result.exchanges?.map((item: any) => {
      return {
        label: `No. ${item.number}`,
        resource: "exchanges",
        item1: `${item?.status[0]?.created_at} ${fromInfoToTranslatedStatus(item?.status[0].status)}`,
        item2: item.exchange_reason_data?.code_reason,
        url: `/exchange-info/${item._id}`,
      };
    });
    const reasonsForExchangeArray = data.result.reasons_for_exchange?.map((item: any) => {
      return {
        label: item.code_reason,
        resource: "reasons_for_exchange",
        item1: `${item?.label}`,
        item2: item.language,
        url: `/reasons-for-exchange-info/${item.id}`,
      };
    });

    const reasonsForRefusalArray = data.result.reasons_for_refusal?.map((item: any) => {
      return {
        label: item.code_reason,
        resource: "reasons_for_refusal",
        item1: `${item?.label}`,
        item2: item.language,
        url: `/refusal-reasons-info/${item.id}`,
      };
    });
    const usersArray = data.result.users?.map((item: any) => {
      return {
        label: `${item?.username}`,
        resource: "users",
        item1: `${item?.address?.zipcode} ${item?.address?.address}`,
        item2: `${item?.email}`,
        url: `/user-info/${item.id}`,
      };
    });
    // const mappedData = data.result.items?.map((item: any) => (item[key] ? item[key] : ""));
    // let setArray: any = new Set(mappedData);
    // setOptions([...setArray]);
    setOptions([
      ...brandArray,
      ...productsArray,
      ...groupArray,
      ...distributorsArray,
      ...distributorsSetArray,
      ...contentArray,
      ...customersArray,
      ...exchangesArray,
      ...reasonsForExchangeArray,
      ...reasonsForRefusalArray,
      ...usersArray,
    ]);
  };

  const handleChange = (event: any, newValue: any | null) => {
    setValue(newValue);
    setInLocalStorage(LocalStorageKeys.GLOBAL_SEARCH, value);
    if (!newValue) return;
  };
  const onChangeInput = (event: any, newValue: any | null) => {
    setValue(event.target.value);
  };

  return { value, options, setValue, handleChange, onChangeInput, loading };
}
