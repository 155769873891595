import { faBars, faBoxes, faQuestionCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useContext } from "react";
import { Breadcrumb, Button, Card, Modal } from "react-bootstrap";
import TableBrands from "../organisms/TableBrands/TableBrands";
import { Link, useHistory } from "react-router-dom";
import { ApplicationContext } from "../../context/ApplicationContext";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import Reloadimg from "../../images/reload.svg";
import deleteImg from "../../images/delete.svg";
import copyImg from "../../images/copy.svg";
import Tooltip from "@mui/material/Tooltip";
import "../../constants/responsive.css";
import exportImg from "../../images/export.svg";
import ModalExport from "../atoms/ModalExport";
import preferencesImg from "../../images/preferences.svg";
//Redux
import { useTranslation } from "react-i18next";
import { useGetAllBrands } from "../../hooks/Brands/useGetBrands";
import BrandSearch from "../organisms/Search/BrandSearch";
import ItemPermission from "../atoms/ItemPermission/ItemPermission";
import BrandTableTransfer from "../templates/TableTranfers/BrandTableTransfer";
import bulkimg from "../../images/bulk.svg";
import { useMediaQuery } from "@mui/material";
import "../../constants/global.css";
import { useFilterContext } from "../../context";
import { useSelector } from "react-redux";
import { IRootState } from "../../store/redux";
import { typeUserData } from "../../constants/userTypes";

const BrandsList = (props: any) => {
  let history = useHistory();
  const { t } = useTranslation();
  const { brandLength, brandsListID, brandsAllSelected } = useContext(ApplicationContext);
  const listSort: string[] = [t("SEARCH.BRAND.CODE"), t("SEARCH.BRAND.NAME"), t("SEARCH.BRAND.WEBSITE")];
  //Redux
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected, sub } = profile;
  //Filters
  const { inputCodeSearch, inputNameSearch, showLateralContainerSearch, setShowLateralContainerSearch } = useFilterContext();

  const [dropDownWhySort, setDropDownWhySort] = useState<string>(listSort[0]);

  const [showSearchTable, setShowSearchTable] = useState(false);
  const [searchWindowState, SetSearchWindowState] = useState("filterList");

  const [openTransferModal, setOpenTransferModal] = useState(false);

  const matches = useMediaQuery("(max-width:500px)");

  //menu fade
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //! ------------------------------API CALLS--------------------------------//
  const {
    brandsArray,
    loading,
    exportBrand,
    deleteManyBrand,
    lastPage,
    handleChange,
    currentPage,
    limit,
    onSearch,
    typeSort,
    incrementCount,
  } = useGetAllBrands(inputCodeSearch, inputNameSearch);

  //! --------------------------END API CALLS--------------------------------//
  const exportFunction = () => {
    try {
      exportBrand(checkedType, brandsListID, checkNeedToExport);
    } finally {
      setOpenConfirm(false);
    }
  };

  const deleteFunction = () => {
    try {
      deleteManyBrand(brandsListID);
    } finally {
      setOpenConfirm(false);
    }
  };

  function copy() {
    const url = document.createElement("input");
    url.value = window.location.href;
    navigator.clipboard.writeText(url.value);
    setAnchorEl(null);
  }

  const RenderTabla1 = () => {
    return (
      <div>
        <TableBrands
          rowsPerPage={limit}
          onClick={props.onClick}
          UsePlanText="false"
          inputToSort={dropDownWhySort}
          linkTo="/brand-info"
          Create={props.Create}
          showSearchTable={showSearchTable}
          brandsArray={brandsArray}
          load={loading}
          totalBrands={lastPage}
          currentPage={currentPage}
          handleChange={handleChange}
          incrementCount={incrementCount}
          typeSort={typeSort}
          handleSearch={() => onSearch()}
          setDropDownWhySort={setDropDownWhySort}
          // brandArray = {brandsArray}
        />
        <div className="row sectionBoxBottom">
          <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
            Total {`${brandLength}`}
          </h4>
        </div>
      </div>
    );
  };

  const renderLateralContainerSearch = () => {
    return (
      <BrandSearch
        searchWindowState={searchWindowState}
        handleSearch={() => onSearch()}
        SetSearchWindowState={SetSearchWindowState}
        listSort={listSort}
        dropDownWhySort={dropDownWhySort}
        setDropDownWhySort={setDropDownWhySort}
      />
    );
  };

  const [openConfirm, setOpenConfirm] = useState(false);
  const showModal = () => {
    return (
      <>
        <Modal
          show={openConfirm}
          onHide={() => {
            setOpenConfirm(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("INFO.USER.RESET-CONFIRM")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-lg-12 p-4">
                <h4 className="h4-stl">{t("ALL-INFO.DELETE-CONFIRM")}</h4>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                deleteFunction();
              }}
              variant="success"
            >
              {t("INFO.USER.RESET-BUTTON-CONFIRM")}
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setOpenConfirm(false);
              }}
            >
              {t("INFO.USER.RESET-BUTTON-CANCEL")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  //Modal
  const [openExport, setOpenExport] = useState(false);
  // Checkbox
  const [checkNeedToExport, setCheckNeedToExport] = useState<boolean[]>([false, true, false]);
  const [checkFormatToExport, setCheckFormatToExport] = useState<boolean[]>([true, false, false, false]);
  const [checkedType, setCheckedType] = useState<string>("csv");

  const showModalExport = () => {
    return (
      <ModalExport
        openExport={openExport}
        setOpenExport={setOpenExport}
        checkNeedToExport={checkNeedToExport}
        setCheckNeedToExport={setCheckNeedToExport}
        checkFormatToExport={checkFormatToExport}
        setCheckFormatToExport={setCheckFormatToExport}
        checkedType={checkedType}
        setCheckedType={setCheckedType}
        onClick={() => {
          setOpenExport(false);
          exportFunction();
        }}
      />
    );
  };
  return (
    <div style={{ margin: 0, padding: 0 }}>
      <div>
        {renderLateralContainerSearch()}
        {!props.Create && (
          <Breadcrumb>
            <Breadcrumb.Item href="#">{t("TABLE.BRAND.TITLE.HEADER-LINK")}</Breadcrumb.Item>
          </Breadcrumb>
        )}
        <Card>
          <Card.Header>
            {matches ? (
              <div className="flex-stl flex-between">
                <div className="flex-stl flex-center">
                  <FontAwesomeIcon className="icon_size" icon={faBoxes} />

                  <div style={{ marginLeft: "8px" }}>
                    <h4 className="h4-stl" style={{ margin: "1vh 5px" }}>
                      <strong>{t("TABLE.BRAND.TITLE-HEADER")}</strong>
                    </h4>
                    <h5 className="h5-stl" style={{ margin: "1vh 5px" }}>
                      {t("TABLE.BRAND.HEADER-TOTAL")} {`${brandLength}`}
                    </h5>
                  </div>
                </div>
                <div className="flex-stl flex-center">
                  <ItemPermission>
                    <Link to="/create-brand">
                      <Button variant="primary">{t("TABLE.BRAND.HEADER-BUTTON-CREATE")}</Button>
                    </Link>
                  </ItemPermission>

                  <Tooltip title={`${t("HOVER.ALL.BUTTON-SEARCH")} `} placement="bottom">
                    <div>
                      {!props.Create ? (
                        <FontAwesomeIcon
                          className="grayBackgr iconbar_size"
                          style={{ cursor: "pointer", marginLeft: "8px" }}
                          icon={faSearch}
                          onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="grayBackgr iconbar_size"
                          icon={faSearch}
                          style={{ cursor: "pointer", marginLeft: "8px" }}
                          onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                        />
                      )}
                    </div>
                  </Tooltip>
                  {!matches && <FontAwesomeIcon className="grayBackgr iconbar_size" icon={faQuestionCircle} />}

                  <div onClick={handleClick}>
                    <FontAwesomeIcon className="grayBackgr iconbar_size" style={{ cursor: "pointer", marginLeft: "8px" }} icon={faBars} />
                  </div>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                    <MenuItem
                      onClick={() => {
                        history.go(0);
                      }}
                      style={{ fontSize: "15px" }}
                    >
                      <img alt="" src={Reloadimg} style={{ width: "20px", marginRight: "10px" }}></img>
                      {t("TABLE.BRAND.HEADER-OPTIONS-LIST-RELOAD")}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setOpenTransferModal(true);
                      }}
                      style={{ fontSize: "15px" }}
                    >
                      <img alt="" src={preferencesImg} style={{ width: "20px", marginRight: "10px" }}></img>
                      {t("ALL-BUTTON-PREFERENCES")}
                    </MenuItem>
                    <ItemPermission>
                      <div>
                        {brandsListID.length || brandsAllSelected ? (
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              setOpenConfirm(true);
                            }}
                            style={{ fontSize: "15px" }}
                          >
                            <img
                              alt=""
                              src={deleteImg}
                              style={{
                                width: "20px",
                                marginRight: "10px",
                              }}
                            ></img>
                            {t("TABLE.BRAND.HEADER-OPTIONS-LIST-DELETE")}
                            <span
                              style={{
                                background: "var( --color-accepted)",
                                marginLeft: "6px",
                                color: "var(--color-main-text)",
                                borderRadius: "20px",
                                padding: "4px 8px",

                                fontSize: "12px",
                              }}
                            >
                              <b>{brandsAllSelected ? brandLength : brandsListID?.length}</b>
                            </span>
                          </MenuItem>
                        ) : (
                          <MenuItem disabled style={{ fontSize: "15px" }}>
                            <img
                              alt=""
                              src={deleteImg}
                              style={{
                                width: "20px",
                                marginRight: "10px",
                              }}
                            ></img>
                            {t("TABLE.BRAND.HEADER-OPTIONS-LIST-DELETE")}
                          </MenuItem>
                        )}
                      </div>
                    </ItemPermission>
                    <ItemPermission>
                      <div>
                        {brandsListID.length ? (
                          <MenuItem
                            onClick={() => {
                              history.push("/brand-bulk");
                              handleClose();
                            }}
                            style={{ fontSize: "15px" }}
                          >
                            <img
                              alt=""
                              src={bulkimg}
                              style={{
                                width: "20px",
                                marginRight: "10px",
                              }}
                            ></img>
                            {t("TABLE.HEADER-OPTIONS-LIST-BULK")}
                            <span
                              style={{
                                background: "var( --color-accepted)",
                                marginLeft: "6px",
                                color: "var(--color-main-text)",
                                borderRadius: "20px",
                                padding: "4px 8px",

                                fontSize: "12px",
                              }}
                            >
                              <b>{brandsListID?.length}</b>
                            </span>
                          </MenuItem>
                        ) : (
                          <MenuItem disabled style={{ fontSize: "15px" }}>
                            <img
                              alt=""
                              src={bulkimg}
                              style={{
                                width: "20px",
                                marginRight: "10px",
                              }}
                            ></img>
                            {t("TABLE.HEADER-OPTIONS-LIST-BULK")}
                          </MenuItem>
                        )}
                      </div>
                    </ItemPermission>

                    {nameRolSelected !== typeUserData.viewOnly && (
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          setOpenExport(true);
                        }}
                        style={{ fontSize: "15px" }}
                      >
                        <img alt="" src={exportImg} style={{ width: "20px", marginRight: "10px" }} />
                        {t("NAV-ALL-ITEM3")}
                        <span
                          style={{
                            background: "var( --color-accepted)",
                            marginLeft: "6px",
                            color: "var(--color-main-text)",
                            borderRadius: "20px",
                            padding: "4px 8px",
                            fontSize: "12px",
                          }}
                        >
                          <b>{brandsAllSelected ? brandLength : brandsListID?.length}</b>
                        </span>
                      </MenuItem>
                    )}

                    <MenuItem onClick={copy} style={{ fontSize: "15px" }}>
                      <img alt="" src={copyImg} style={{ width: "20px", marginRight: "10px" }}></img>
                      {t("TABLE.BRAND.HEADER-OPTIONS-LIST-COPY-LINK")}
                    </MenuItem>
                    {/* <MenuItem onClick={handleClose} style={{fontSize:'15px'}}>Logout</MenuItem> */}
                  </Menu>
                </div>
              </div>
            ) : (
              <div className="flex-warranty flex-between">
                <div className="container-warranty flex-start">
                  <FontAwesomeIcon className="icon_size" icon={faBoxes} />

                  <div>
                    <h4 className="h4-stl" style={{ margin: "15px 5px" }}>
                      <strong>{t("TABLE.BRAND.TITLE-HEADER")}</strong>
                    </h4>
                    <h5 className="h5-stl" style={{ margin: "15px 5px" }}>
                      {t("TABLE.BRAND.HEADER-TOTAL")} {`${brandLength}`}
                    </h5>
                  </div>
                </div>
                <div className="container-warranty flex-end wrap-warranty">
                  <ItemPermission>
                    <Link to="/create-brand">
                      <Button variant="primary">{t("TABLE.BRAND.HEADER-BUTTON-CREATE")}</Button>
                    </Link>
                  </ItemPermission>

                  <Tooltip title={`${t("HOVER.ALL.BUTTON-SEARCH")} `} placement="bottom">
                    <div>
                      {!props.Create ? (
                        <FontAwesomeIcon
                          className="grayBackgr iconbar_size"
                          style={{ cursor: "pointer" }}
                          icon={faSearch}
                          onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="grayBackgr iconbar_size"
                          icon={faSearch}
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                        />
                      )}
                    </div>
                  </Tooltip>
                  <FontAwesomeIcon className="grayBackgr iconbar_size" icon={faQuestionCircle} />

                  <div onClick={handleClick}>
                    <FontAwesomeIcon className="grayBackgr iconbar_size" icon={faBars} />
                  </div>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                    <MenuItem
                      onClick={() => {
                        history.go(0);
                      }}
                      style={{ fontSize: "15px" }}
                    >
                      <img alt="" src={Reloadimg} style={{ width: "20px", marginRight: "10px" }}></img>
                      {t("TABLE.BRAND.HEADER-OPTIONS-LIST-RELOAD")}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setOpenTransferModal(true);
                      }}
                      style={{ fontSize: "15px" }}
                    >
                      <img alt="" src={preferencesImg} style={{ width: "20px", marginRight: "10px" }}></img>
                      {t("ALL-BUTTON-PREFERENCES")}
                    </MenuItem>
                    <ItemPermission>
                      <div>
                        {brandsListID.length || brandsAllSelected ? (
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              setOpenConfirm(true);
                            }}
                            style={{ fontSize: "15px" }}
                          >
                            <img
                              alt=""
                              src={deleteImg}
                              style={{
                                width: "20px",
                                marginRight: "10px",
                              }}
                            ></img>
                            {t("TABLE.BRAND.HEADER-OPTIONS-LIST-DELETE")}
                            <span
                              style={{
                                background: "var( --color-accepted)",
                                marginLeft: "6px",
                                color: "var(--color-main-text)",
                                borderRadius: "20px",
                                padding: "4px 8px",

                                fontSize: "12px",
                              }}
                            >
                              <b>{brandsAllSelected ? brandLength : brandsListID?.length}</b>
                            </span>
                          </MenuItem>
                        ) : (
                          <MenuItem disabled style={{ fontSize: "15px" }}>
                            <img
                              alt=""
                              src={deleteImg}
                              style={{
                                width: "20px",
                                marginRight: "10px",
                              }}
                            ></img>
                            {t("TABLE.BRAND.HEADER-OPTIONS-LIST-DELETE")}
                          </MenuItem>
                        )}
                      </div>
                    </ItemPermission>
                    <ItemPermission>
                      <div>
                        {brandsListID.length ? (
                          <MenuItem
                            onClick={() => {
                              history.push("/brand-bulk");
                              handleClose();
                            }}
                            style={{ fontSize: "15px" }}
                          >
                            <img
                              alt=""
                              src={bulkimg}
                              style={{
                                width: "20px",
                                marginRight: "10px",
                              }}
                            ></img>
                            {t("TABLE.HEADER-OPTIONS-LIST-BULK")}
                            <span
                              style={{
                                background: "var( --color-accepted)",
                                marginLeft: "6px",
                                color: "var(--color-main-text)",
                                borderRadius: "20px",
                                padding: "4px 8px",

                                fontSize: "12px",
                              }}
                            >
                              <b>{brandsListID?.length}</b>
                            </span>
                          </MenuItem>
                        ) : (
                          <MenuItem disabled style={{ fontSize: "15px" }}>
                            <img
                              alt=""
                              src={bulkimg}
                              style={{
                                width: "20px",
                                marginRight: "10px",
                              }}
                            ></img>
                            {t("TABLE.HEADER-OPTIONS-LIST-BULK")}
                          </MenuItem>
                        )}
                      </div>
                    </ItemPermission>

                    {nameRolSelected !== typeUserData.viewOnly && (
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          setOpenExport(true);
                        }}
                        style={{ fontSize: "15px" }}
                      >
                        <img alt="" src={exportImg} style={{ width: "20px", marginRight: "10px" }} />
                        {t("NAV-ALL-ITEM3")}
                        <span
                          style={{
                            background: "var( --color-accepted)",
                            marginLeft: "6px",
                            color: "var(--color-main-text)",
                            borderRadius: "20px",
                            padding: "4px 8px",
                            fontSize: "12px",
                          }}
                        >
                          <b>{brandsAllSelected ? brandLength : brandsListID?.length}</b>
                        </span>
                      </MenuItem>
                    )}

                    <MenuItem onClick={copy} style={{ fontSize: "15px" }}>
                      <img alt="" src={copyImg} style={{ width: "20px", marginRight: "10px" }}></img>
                      {t("TABLE.BRAND.HEADER-OPTIONS-LIST-COPY-LINK")}
                    </MenuItem>
                    {/* <MenuItem onClick={handleClose} style={{fontSize:'15px'}}>Logout</MenuItem> */}
                  </Menu>
                </div>
              </div>
            )}
          </Card.Header>
          <Card.Body>{RenderTabla1()}</Card.Body>
        </Card>
      </div>
      <BrandTableTransfer open={openTransferModal} setOpen={setOpenTransferModal} />
      {showModal()}
      {showModalExport()}
    </div>
  );
};

export default BrandsList;
