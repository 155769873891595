import React, { useContext, useEffect, useState } from "react";
import TransferModal from "../../organisms/TransferModal/TransferModal";
import { useTranslation } from "react-i18next";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { LocalStorageKeys, getInLocalStorageArray } from "../../../helpers/local-storage-manager";
export interface IRowHeaderBrand {
  field: string;
  headerName: string;
  sort: string;
}
interface IProps {
  open: boolean;
  setOpen: any;
}

export default function DistributorSetTableTransfer(props: IProps) {
  const { distributorSetTableTransferData, setDistributorSetTableTransferData } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const { open, setOpen } = props;
  const tableDistributorInitial: string[] = [
    t("ALL.GENERAL.TABLE.DIST-SET.TITLE-CODE"),
    t("ALL.GENERAL.TABLE.DIST-SET.TITLE-NAME"),
    t("ALL.GENERAL.TABLE.DIST-SET.TITLE-ORIGIN"),
    t("ALL.GENERAL.TABLE.DIST-SET.TITLE-MEMBERS"),
  ];

  const [dataRight, setDataRight] = useState<string[]>([]);

  useEffect(() => {
    const initialArray = getInLocalStorageArray(LocalStorageKeys.DISTRIBUTOR_SET_TABLE);
    const initialArrayRight = getInLocalStorageArray(LocalStorageKeys.DISTRIBUTOR_SET_TABLE_R);
    setDistributorSetTableTransferData(initialArray?.length > 0 ? initialArray : tableDistributorInitial);
    setDataRight(initialArrayRight?.length > 0 ? initialArrayRight : []);
  }, []);
  return (
    <TransferModal
      keyValueRight={`${LocalStorageKeys.DISTRIBUTOR_SET_TABLE_R}`}
      keyValue={`${LocalStorageKeys.DISTRIBUTOR_SET_TABLE}`}
      itemsLeft={distributorSetTableTransferData}
      itemsRight={dataRight}
      setItemsLeft={setDistributorSetTableTransferData}
      setItemsRight={setDataRight}
      openConfirm={open}
      setOpenConfirm={setOpen}
    />
  );
}
