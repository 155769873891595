import { t } from "i18next";
import { IProductsListPage, IProductsListDOM } from "../../../api/interfaces/interfaz_products";
import { listTypeOptionsKeys } from "../../../components/pages/ProductsInfo/ProductsInfo";

export const createAddapterProduct = (item: IProductsListPage[]): IProductsListDOM[] => {
  const array: IProductsListDOM[] = [];
  item?.map((item: IProductsListPage) => {
    return array.push({
      _id: item.id,
      available: item.available,
      brand: item.brand,
      picture: item.picture,
      brandCode: item?.brand_data?.code,
      brandName: item?.brand_data?.name,
      brand_data: {
        _id: item.brand_data._id,
        code: item.brand_data.code,
        description: item.brand_data.description,
        name: item.brand_data.name,
        website: item.brand_data.website,
        products: item.brand_data.products,
        updated_at: item.brand_data.updated_at,
        created_at: item.brand_data.created_at,
        deleted_at: item.brand_data.deleted_at,
      },
      currency: item.currency,
      default: item.default,
      marketing: {
        description: item.marketing.description,
        ean_image: item.marketing.ean_image,
        ean_number: item.marketing.ean_number,
        online_doc: item.marketing.online_doc,
      },
      name: item.name,
      old_reference: item.old_reference,
      reference: item.reference,
      time_warranty: item.time_warranty,
      type:
        item.type === listTypeOptionsKeys.fixed
          ? `${t("TABLE.PRODUCTS-STATS.TITLE-OPENINGWRENCH")}`
          : item.type === listTypeOptionsKeys.pawls
          ? `${t("TABLE.PRODUCTS-STATS.TITLE-PAWLS")}`
          : item.type === listTypeOptionsKeys.socket
          ? `${t("TABLE.PRODUCTS-STATS.TITLE-SOCKETS")}`
          : item.type === listTypeOptionsKeys.screwDrivers
          ? `${t("TABLE.PRODUCTS-STATS.TITLE-SCREWDRIVERS")}`
          : item.type === listTypeOptionsKeys.mWrench
          ? `${t("TABLE.PRODUCTS-STATS.TITLE-MALEWRENCHS")}`
          : item.type === listTypeOptionsKeys.pliers
          ? `${t("TABLE.PRODUCTS-STATS.TITLE-PLIERS")}`
          : item.type === listTypeOptionsKeys.measuring
          ? `${t("TABLE.PRODUCTS-STATS.TITLE-TRACING")}`
          : item.type === listTypeOptionsKeys.hammers
          ? `${t("TABLE.PRODUCTS-STATS.TITLE-HAMMERS")}`
          : item.type === listTypeOptionsKeys.sawing
          ? `${t("TABLE.PRODUCTS-STATS.TITLE-SAWING")}`
          : item.type === listTypeOptionsKeys.extraction
          ? `${t("TABLE.PRODUCTS-STATS.TITLE-EXTRACTION")}`
          : item.type === listTypeOptionsKeys.other
          ? `${t("TABLE.PRODUCTS-STATS.TITLE-OTHER")}`
          : "",
      unit_price: item.unit_price,
      eanNumber: item.marketing?.ean_number ? item.marketing.ean_number : "",
    });
  });
  return array;
};
