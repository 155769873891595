import { useContext } from "react";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { faThumbtack, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import Tooltip from "@mui/material/Tooltip";
import InputClear from "../../atoms/InputClear";
import DropDown from "../../atoms/DropDown";
import { useFilterContext } from "../../../context";
import useAutocomplete from "../../atoms/AutocopleteInput/useAutocomplete";
import AutoCompleteInput from "../../atoms/AutocopleteInput/AutocompleteInput";

export interface ITableFilters {
  listSort: string[];
  handleSearch: any;
}

export default function ChangeLogTableFilter(props: ITableFilters) {
  const {
    inputSessionLoginSearch,
    setInputSessionLoginSearch,
    inputUserSearch,
    setInputUserSearch,
    inputDateSearch,
    setInputDateSearch,
    inputObjectSearch,
    setInputObjectSearch,
    inputActionSearch,
    setInputActionSearch,
    showLateralContainerSearch,
  } = useFilterContext();
  const { listSort, handleSearch } = props;
  const { typeOfSearch, setTypeOfSearch, changeLogsTableTransferData } = useContext(ApplicationContext);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleSearch();
  };

  const sessionLogin = useAutocomplete(
    "/changelogs/get-all?autocomplete=true&login=",
    handleSearch,
    inputSessionLoginSearch,
    setInputSessionLoginSearch,
    "login"
  );
  const user = useAutocomplete("/changelogs/get-all?autocomplete=true&user=", handleSearch, inputUserSearch, setInputUserSearch, "user");

  return (
    <>
      {(typeOfSearch === 0 ? showLateralContainerSearch : false) === true && (
        <tr>
          <td>
            <Tooltip title={`${t("FILER-TO-DOCK")}`} placement="bottom">
              <div style={{ width: "60px" }}>
                <FontAwesomeIcon
                  className="grayBackground-icon"
                  size="2x"
                  style={{ cursor: "pointer" }}
                  icon={faThumbtack}
                  onClick={() => {
                    setTypeOfSearch(1);
                  }}
                />
              </div>
            </Tooltip>
          </td>
          {changeLogsTableTransferData.includes(`${`${t("ALL.GENERAL-CHANGE-LOG.TITLE-LOGIN")}`}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <AutoCompleteInput {...sessionLogin} freeSolo />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputSessionLoginSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {changeLogsTableTransferData.includes(`${`${t("ALL.GENERAL-CHANGE-LOG.TITLE-USER")}`}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <AutoCompleteInput {...user} freeSolo />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputUserSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {changeLogsTableTransferData.includes(`${`${t("ALL.GENERAL-CHANGE-LOG.TITLE-DATE")}`}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <InputClear
                      type="date"
                      name="inputDateSearch"
                      placeholder=""
                      currentValue={inputDateSearch}
                      handleOnInputValue={setInputDateSearch}
                    />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputDateSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {changeLogsTableTransferData.includes(`${`${t("ALL.GENERAL-CHANGE-LOG.TITLE-OBJECT")}`}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <InputClear
                      type="text"
                      name="inputObjectSearch"
                      placeholder=""
                      currentValue={inputObjectSearch}
                      handleOnInputValue={setInputObjectSearch}
                    />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputObjectSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {changeLogsTableTransferData.includes(`${`${t("ALL.GENERAL-CHANGE-LOG.TITLE-ACTION")}`}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div>
                    <DropDown
                      options={listSort}
                      selectedItem={inputActionSearch}
                      onSelect={(data: string) => {
                        setInputActionSearch(data);
                        handleSearch();
                      }}
                      style={{
                        color: "var(--color-input-text)",
                        borderRadius: "2px",
                        textAlign: "left",
                        fontFamily: "Arial",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "14px",
                        lineHeight: "16px",
                        width: `100%`,
                        maxWidth: "100px",
                        overflow: "hidden",
                        height: "30px",
                        border: "1px solid #ced4da",
                        padding: "4px",
                      }}
                      widthMenu="230px"
                      isCompact
                    />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputActionSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {changeLogsTableTransferData.includes(`${`${t("ALL.GENERAL-CHANGE-LOG.TITLE-ACTIVITY")}`}`) && <td></td>}
        </tr>
      )}
    </>
  );
}
