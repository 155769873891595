import { t } from "i18next";

export const fromInfoToTranslatedCountry = (country: string) => {
  if (country === "FR") return `${t("COUNTRY-FRANCE")}`;
  if (country === "ES") return `${t("COUNTRY-SPAIN")}`;
  if (country === "UK") return `${t("COUNTRY-UK")}`;
  if (country === "DE") return `${t("COUNTRY-GERMANY")}`;
  return "";
};

export const fromCountryTranslatedToKey = (country: string) => {
  if (country === `${t("COUNTRY-FRANCE")}`) return "FR";
  if (country === `${t("COUNTRY-SPAIN")}`) return "ES";
  if (country === `${t("COUNTRY-UK")}`) return "UK";
  if (country === `${t("COUNTRY-GERMANY")}`) return "DE";
  return "";
};
