import React from "react";
import { useSelector } from "react-redux";
import { IRootState } from "../../store/redux";
import "./styles/input.css";

interface IInputProps {
  type: string;
  name: string;
  placeholder: string;
  style?: any;
  currentValue: any;
  handleOnInputValue: any;
  roles: string[];
  disabled?: boolean;
  readOnly?: boolean;
  defaultValue?: any;
  onKeyDown?: any;
  onKeyPress?: any;
  max?: number;
  min?: number;
  noSpaces?:boolean;
}
function InputClearGuard(props: IInputProps) {
  //Redux
  const { roles,noSpaces } = props;
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected } = profile;
  const isValidRole: boolean = !!roles.find((item) => item === nameRolSelected);
  const handleOnInputChange = (event: any) => {
    const data = event.target.value;
    if(!noSpaces){
      props.handleOnInputValue(data);
    }else{
      props.handleOnInputValue(data.replace(/ /g,""));

    }
  };
  return (
    <div className="h4-stl-form">
      {/* <form action =""> */}
      <input
        className={isValidRole ? "input-stlClear" : "input-stlClear warranty-input-disabled"}
        onKeyDown={props.onKeyDown}
        onKeyPress={props.onKeyPress}
        readOnly={!isValidRole || props.readOnly}
        disabled={props.disabled}
        type={props.type}
        max={props.max}
        min={props.min}
        name={props.name}
        placeholder={props.placeholder}
        style={props.style}
        onChange={(value) => handleOnInputChange(value)}
        value={props.currentValue}
        defaultValue={props.defaultValue}
      ></input>
      {/* </form>      */}
    </div>
  );
}
export default InputClearGuard;
