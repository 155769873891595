import { Modal, Button } from "react-bootstrap";
import TransferList from "../../molecules/TransferList/TransferList";
import { useTranslation } from "react-i18next";
interface IProps {
  itemsLeft: any;
  itemsRight: any;
  setItemsLeft: any;
  setItemsRight: any;
  openConfirm: boolean;
  setOpenConfirm: any;
  keyValue: string;
  keyValueRight: string;
}
export default function TransferModal(props: IProps) {
  const { itemsLeft, itemsRight, setItemsLeft, setItemsRight, openConfirm, setOpenConfirm, keyValue, keyValueRight } = props;
  const { t } = useTranslation();
  return (
    <Modal
      size="lg"
      show={openConfirm}
      onHide={() => {
        setOpenConfirm(false);
      }}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("ALL-BUTTON-PREFERENCES")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <TransferList
            itemsLeft={itemsLeft}
            itemsRight={itemsRight}
            setItemsLeft={setItemsLeft}
            setItemsRight={setItemsRight}
            keyValue={keyValue}
            keyValueRight={keyValueRight}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            setOpenConfirm(false);
          }}
          variant="success"
        >
          {t("INFO.USER.RESET-BUTTON-CONFIRM")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
