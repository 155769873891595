import { Autocomplete, CircularProgress, TextField, autocompleteClasses } from "@mui/material";
import React from "react";
import { IAutoComplete } from "./interface";
import "./style.css";
export default function AutoCompleteInput(props: IAutoComplete) {
  const {
    value,
    options,
    setValue,
    disablePortal,
    id,
    disableCloseOnSelect,
    clearOnEscape,
    disableClearable,
    includeInputInList,
    disableListWrap,
    openOnFocus,
    autoSelect,
    disabled,
    blurOnSelect,
    clearOnBlur,
    freeSolo,
    multiple,
    getOptionLabel,
    defaultValue,
    variant,
    sx,
    onChangeInput,
    inputLabel,
    onClick,
    loading,
    handleChange,
    fullWidth,
  } = props;

  return (
    <div className="auto-warranty">
      <Autocomplete
        fullWidth={fullWidth}
        value={value}
        sx={{
          borderRadius: "0",
          padding: "0",
          fontSize: "12px !important",
          heigth: "30px !important",
          position: "initial !important",
          border: "0",

          [`& .${autocompleteClasses.inputRoot}`]: {
            position: "relative !important",
            width: "100% !important",
            height: "30px !important",
            borderRadius: "0",
            border: "0",
            padding: "0",
            paddingTop: "-5px !important",
            fontSize: "12px !important",
            marginTop: "2px",
          },
        }}
        disablePortal={disablePortal}
        id={id}
        loading={loading}
        options={options}
        onChange={handleChange}
        renderInput={(params: any) => (
          <TextField
            {...params}
            sx={{
              paddingLeft: "0",
              width: "100%",
              height: "30px",
              borderBottom: "1px solid var(--color-input-border)",
              borderTop: "0",
              borderRight: "0",
              borderLeft: "0",
              backgroundColor: "#fff",
              position: "initial",
              fontSize: "16px",
              "& input": {
                border: "0",
              },
            }}
            onClick={onClick}
            label={inputLabel}
            variant={variant}
            onChange={onChangeInput}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        defaultValue={defaultValue}
        getOptionLabel={getOptionLabel}
        disableCloseOnSelect={disableCloseOnSelect}
        clearOnEscape={clearOnEscape}
        disableClearable={disableClearable}
        includeInputInList={includeInputInList}
        disableListWrap={disableListWrap}
        openOnFocus={openOnFocus}
        autoSelect={autoSelect}
        disabled={disabled}
        blurOnSelect={blurOnSelect}
        clearOnBlur={clearOnBlur}
        freeSolo={freeSolo}
        renderOption={(props, option, { inputValue }) => {
          return (
            <>
              <li {...props}>
                <div style={{ fontSize: "12px", paddingTop: "0", paddingBottom: "0" }}>{option}</div>
              </li>
            </>
          );
        }}
        multiple={multiple}
      />
    </div>
  );
}
