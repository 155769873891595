import { AxiosCall } from "../models/axiosCall";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";

type TCallEndpoint = <T>(
  axiosCall: AxiosCall<T>,
  errorFunction?: any,
  adapter?: (data: any) => any,
  successFunction?: any
) => Promise<AxiosResponse<T, any>>;

const useFetchAndLoad = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  let controller: AbortController;

  const callEndpoint: TCallEndpoint = async (axiosCall, errorFunction, adapter, successFunction) => {
    if (axiosCall.controller) controller = axiosCall.controller;
    setLoading(true);

    try {
      const result = await axiosCall?.call;
      if (successFunction) {
        successFunction && successFunction();
      }

      return adapter ? adapter(result) : result;
    } catch (err: any) {
      setLoading(false);
      setErrorMessage(`${err?.response?.data?.message}`);
      console.log("error");
      if (err?.request?.status !== 401 && err?.request?.status !== 403) errorFunction && errorFunction();
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const cancelEndpoint = () => {
    setLoading(false);
    controller && controller.abort();
  };

  useEffect(() => {
    return () => {
      cancelEndpoint();
    };
  }, []);

  return { loading, callEndpoint, errorMessage };
};

export default useFetchAndLoad;
