import {
  IRefusalReasonsDOM,
  IRefusalReasonsPage,
} from "../../../api/interfaces/interfaz_refusal_reasons";

export const createAddapterRefusalReason = (
  item: IRefusalReasonsPage[]
): IRefusalReasonsDOM[] => {
  const array: IRefusalReasonsDOM[] = [];
  item?.map((item: IRefusalReasonsPage) => {
    return array.push({
      id: item.id,
      code_reason: item.code_reason,
      description: item.description,
      display_order: item.display_order,
      exchanges: item.exchanges,
      label: item.label,
      language: item.language,
    });
  });
  return array;
};
