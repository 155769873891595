import {
  IReasonForExchangeDOM,
  IReasonForExchangePage,
} from "../../../api/interfaces/interfaz_reasons_for_exchange";

export const createAddapterReasonForExchange = (
  item: IReasonForExchangePage[]
): IReasonForExchangeDOM[] => {
  const array: IReasonForExchangeDOM[] = [];
  item?.map((item: IReasonForExchangePage) => {
    return array.push({
      id: item.id,
      code_reason: item.code_reason,
      description: item.description,
      display_order: item.display_order,
      exchanges: item.exchanges,
      is_for_change: item.is_for_change,
      label: item.label,
      language: item.language,
    });
  });
  return array;
};
