import { useState } from "react";
import { IFileWithMeta } from "react-dropzone-uploader";
import { IPicturesAPI } from "../api/interfaces/globalInterfaces";
import { getExchangePicture } from "../api/services/exchanges_services";

export default function usePictures(size?: number) {
  const [alertImage, setAlertImage] = useState(false);
  const [fileNameImage, setFileNameImage] = useState("");
  const [picture, setPicture] = useState<IPicturesAPI>();
  const [base64Picture, setBase64Picture] = useState<string>("");
  const [LoadingPicture, setLoadingPicture] = useState<boolean>(false);
  const [extension, setExtension] = useState<string>("");

  const handleDeleteImage = () => {
    setPicture({ file_content: null, extension: null });
    setBase64Picture("");
    setExtension("");
  };

  const getBase64 = (file: File) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL: string | ArrayBuffer | null;
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleChangeStatus = async (meta: IFileWithMeta, status: string, files: IFileWithMeta[]) => {
    if (files[0].file.size > (size ? size : 1000000)) {
      files[0].meta.name = "-";
      setAlertImage(true);
    } else {
      setAlertImage(false);
    }
    if (status === "done") {
      const base64File = await getBase64(files[0].file);
      setFileNameImage(files[0]?.file?.name);

      let base64noType = "";
      if (files[0]?.file?.type === "image/png" || files[0]?.file?.type === "image/jpg") {
        if (typeof base64File === "string") {
          base64noType = base64File.slice(22);
        }
      } else if (files[0]?.file?.type === "image/jpeg") {
        if (typeof base64File === "string") {
          base64noType = base64File.slice(23);
        }
      }
      setPicture({ file_content: base64noType, extension: files[0]?.file?.type });
    }
    if (status === "removed") {
      setPicture(undefined);
      setBase64Picture("");
      setExtension("");
    }
  };

  const getPicture = async (pictureObject: IPicturesAPI | undefined, resourseId: string) => {
    try {
      if (!base64Picture && pictureObject) {
        setLoadingPicture(true);
        const response = getExchangePicture(resourseId, `${pictureObject?.file_content}`);
        //needs file_content on name
        const data = (await response).data;
        setExtension(`${pictureObject?.extension}`);
        setBase64Picture(data?.result?.base64);
        return data;
      }
    } catch (error: any) {
      console.log("error picture");
    } finally {
      setLoadingPicture(false);
    }
  };

  const getPictureInfo = async (pictureObject: IPicturesAPI | undefined, resourseId: string) => {
    try {
      if (!base64Picture && pictureObject) {
        setLoadingPicture(true);
        const response = getExchangePicture(resourseId, `${pictureObject?.file_content}`);
        //needs file_content on name
        const data = (await response).data;
        setExtension(`${pictureObject?.extension}`);
        setBase64Picture(data?.result?.base64);
        setPicture({ file_content: data?.result?.base64, extension: `${pictureObject?.extension}` });
        return data;
      }
    } catch (error: any) {
      console.log("error picture");
    } finally {
      setLoadingPicture(false);
    }
  };

  return {
    handleChangeStatus,
    alertImage,
    fileNameImage,
    picture,
    getPicture,
    base64Picture,
    LoadingPicture,
    extension,
    handleDeleteImage,
    getPictureInfo,
  };
}
