import franceBrand from "../../images/franceBrand.svg";
import englishBrand from "../../images/englishBrand.svg";
import spainBrand from "../../images/spainBrand.svg";
import germanyBrand from "../../images/germanyBrand.png";

import React, { useRef, useState, useEffect } from "react";
import debounce from "lodash.debounce";
import { Item, Menu, Label, Field, Dropdown, Autocomplete, Select } from "@zendeskgarden/react-dropdowns";
import { Row, Col } from "@zendeskgarden/react-grid";

import "../../constants/global.css";
import { useSelector } from "react-redux";
import { IRootState } from "../../store/redux";
import { t } from "i18next";
interface IDropdownProps {
  options: string[];
  selectedItem: string;
  onSelect: any;
  width?: string;
  disabled?: boolean;
  backgroud?: string;
  roles?: string[];
}

const DropDownCities = (props: IDropdownProps) => {
  // const [selectedItem, setSelectedItem] = useState(props.options[0]);
  const [inputValue, setInputValue] = useState("");
  const [matchingOptions, setMatchingOptions] = useState(props.options);

  const { roles, disabled } = props;
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected } = profile;
  const isValidRole: boolean = !!roles?.find((item) => item === nameRolSelected);

  /**
   * Debounce filtering
   */
  const filterMatchingOptionsRef = useRef(
    debounce((value: string) => {
      const matchedOptions = props.options.filter((option) => option.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1);

      setMatchingOptions(matchedOptions);
    }, 300)
  );

  useEffect(() => {
    filterMatchingOptionsRef.current(inputValue);
  }, [inputValue]);

  var filteredDropdown = [""];
  if (props.options) {
    filteredDropdown = props.options.filter((option) => {
      return option.trim().toLowerCase().indexOf(inputValue.trim().toLowerCase()) !== -1;
    });
  }

  return (
    <Row style={{ justifyContent: "left", width: "100%" }}>
      <Col sm={12}>
        <Dropdown
          inputValue={inputValue}
          selectedItem={props.selectedItem}
          onSelect={props.onSelect}
          onInputValueChange={(value: string) => setInputValue(value)}
          downshiftProps={{ defaultHighlightedIndex: 0 }}
        >
          <Field>
            <Select
              disabled={!isValidRole || disabled}
              style={{
                background: `${props.backgroud}`,
                color: "var(--color-input-text)",
                borderRadius: "2px",
                textAlign: "left",
                fontFamily: "Arial",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "14px",
                lineHeight: "16px",
                width: `${props.width}`,

                height: "30px",
                border: "1px solid #ced4da",
              }}
            >
              {props.selectedItem === `${t("COUNTRY-FRANCE")}` ? (
                <img alt="" src={franceBrand} style={{ paddingRight: "5px" }}></img>
              ) : props.selectedItem === `${t("COUNTRY-UK")}` ? (
                <img alt="" src={englishBrand} style={{ paddingRight: "5px" }}></img>
              ) : props.selectedItem === `${t("COUNTRY-SPAIN")}` ? (
                <img alt="" src={spainBrand} style={{ paddingRight: "5px" }}></img>
              ) : props.selectedItem === `${t("COUNTRY-GERMANY")}` ? (
                <img alt="" src={germanyBrand} style={{ paddingRight: "5px" }}></img>
              ) : (
                ""
              )}
              {props.selectedItem === `${t("LANGUAGE-LIST-FRENCH")}` ? (
                <img alt="" src={franceBrand} style={{ paddingRight: "5px" }}></img>
              ) : props.selectedItem === `${t("LANGUAGE-LIST-ENGLISH")}` ? (
                <img alt="" src={englishBrand} style={{ paddingRight: "5px" }}></img>
              ) : (
                ""
              )}
              {props.selectedItem}
            </Select>
          </Field>
          <Menu
            style={{
              backgroundColor: "var(--color-white)",
              color: "var(--color-input-text)",
              borderRadius: "2px",
              fontFamily: "Arial",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "16px",
            }}
          >
            {filteredDropdown.length ? (
              filteredDropdown.map((option) => (
                <Item key={option} value={option}>
                  {option === `${t("COUNTRY-FRANCE")}` ? (
                    <img alt="" src={franceBrand} style={{ paddingRight: "5px" }}></img>
                  ) : option === `${t("COUNTRY-UK")}` ? (
                    <img alt="" src={englishBrand} style={{ paddingRight: "5px" }}></img>
                  ) : option === `${t("COUNTRY-SPAIN")}` ? (
                    <img alt="" src={spainBrand} style={{ paddingRight: "5px" }}></img>
                  ) : option === `${t("COUNTRY-GERMANY")}` ? (
                    <img alt="" src={germanyBrand} style={{ paddingRight: "5px" }}></img>
                  ) : (
                    ""
                  )}

                  {option === `${t("LANGUAGE-LIST-FRENCH")}` ? (
                    <img alt="" src={franceBrand} style={{ paddingRight: "5px" }}></img>
                  ) : option === `${t("LANGUAGE-LIST-ENGLISH")}` ? (
                    <img alt="" src={englishBrand} style={{ paddingRight: "5px" }}></img>
                  ) : (
                    ""
                  )}
                  <span>{option}</span>
                </Item>
              ))
            ) : (
              <Item disabled>No matches found</Item>
            )}
          </Menu>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default DropDownCities;
