import React, { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Container, Dropdown } from "react-bootstrap";
import Pagination from "@mui/material/Pagination";
import Table from "react-bootstrap/Table";
import "./CustomersTable.css";
import { Link, useHistory } from "react-router-dom";
import franceBrand from "../../../images/franceBrand.svg";
import englishBrand from "../../../images/englishBrand.svg";
import spainBrand from "../../../images/spainBrand.svg";
import germanyBrand from "../../../images/germanyBrand.png";
import uncheckedpage from "../../../images/checkPage.svg";

//API
import { ApplicationContext } from "../../../context/ApplicationContext";
import { useTranslation } from "react-i18next";

import checkedimg from "../../../images/checked.svg";
import uncheckedimg from "../../../images/unchecked.svg";
import grayChecked from "../../../images/grayCheck.png";
import { Checkbox, useMediaQuery } from "@mui/material";
import LoadingSkeletonTables from "../LoadingSkeleton/LoadingSkeletonTables";
import CustomerTableFilter from "../Search/CustomerTableFilter";
import { fromInfoToTranslatedCountry } from "../../../utilities/countryTraslations";
import { fromInfoToTranslatedBussiness } from "../../../utilities/usetBussinessTransalte";
import { IRowHeader } from "../../../models/table.modal";
import CustomerTableResponsive from "../../molecules/TransferList/responsiveTables/CustomerTableResponsive";

export interface IRowCustomersFromAPI {
  id: string;
  userUsername: string;
  userFirstName: string;
  userLastName: string;
  userZipCode: string;
  userCity: string;
  userCountry: string;
  userEmail: string;
  userMobile: string;
  userLanguaje: string;
  type: string;
  business: string;
  company_name: string;
  [index: string]: string;
}
interface ICustomersProps {
  searchState: boolean;
  rowsPerPage: number;
  UsePlanText: string;
  inputToSort: string;
  customerArray: any;
  Create?: boolean;
  showSearchTable?: boolean;

  loading: boolean;
  currentPage: number;
  lastPage: number;
  handleChange: any;
  incrementCount?: any;
  typeSort?: string;

  listTypeOptions: any;
  listBusinessOptions: any;
  handleSearch: any;
  setDropDownWhySort: any;
}

export default function TableExchanges(props: ICustomersProps) {
  const { listTypeOptions, listBusinessOptions, handleSearch, setDropDownWhySort } = props;
  const {
    setCustomerUserName,
    setCustomerFirstName,
    setCustomerLastName,
    setCustomerEmail,
    setCustomerId,
    setCustomerMobilePhoneNumber,
    setOpenCustomerTable,
    setCustomerZipCode,
    setCustomerCity,
    setCustomerCountry,
    setCustomerLanguage,
    openCustomerTable,
    customerTableTransferData,
    setCustomerAllSelected,
  } = useContext(ApplicationContext);
  const { customerListID, setCustomerListID } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const history = useHistory();
  const { lastPage, handleChange, currentPage, customerArray, loading, incrementCount, typeSort, inputToSort } = props;
  const matches = useMediaQuery("(max-width:780px)");
  //EXCHANGES
  const handleCustomer = (rowData: any) => {
    setCustomerId(rowData.id);
    setCustomerUserName(rowData.user_data.username);
    setCustomerFirstName(rowData.user_data.first_name);
    setCustomerLastName(rowData.user_data.last_name);
    setCustomerEmail(rowData.user_data.email);
    setCustomerMobilePhoneNumber(rowData.user_data.mobile_phone);
    setOpenCustomerTable(!openCustomerTable);
    setCustomerZipCode(rowData.user_data.address.zipcode);
    setCustomerCity(rowData.user_data.address.city);
    setCustomerCountry(fromInfoToTranslatedCountry(rowData.user_data.address?.country));
    setCustomerLanguage(
      rowData.user_data.users_settings.lenguageActive === "English" ? `${t("LANGUAGE-LIST-ENGLISH")}` : `${t("LANGUAGE-LIST-FRENCH")}`
    );
  };

  //GENERATE AN ARRAY WITH SELECTED IDS
  const [defaultChecked, setDefaultChecked] = useState<boolean>(false);
  const createDeleteArray = (idDelete: string, checked: boolean) => {
    if (checked) {
      setCustomerListID([...customerListID, idDelete]);
    } else {
      setCustomerListID(customerListID.filter((item: any) => item !== idDelete));
    }
  };

  const redirectionLink = "/customer-info";
  const redirectionKey = "id";
  const tableInitial: IRowHeader[] = [
    {
      field: "dropdown",
      headerName: "",
      sort: "",
    },
    {
      field: "userUsername",
      headerName: t("SORT.CUSTOMER.INPUT-USERNAME"),
      sort: t("SORT.CUSTOMER.INPUT-USERNAME"),
      sortKey: "user_data.username",
      title: t("ALL.GENERAL.DISTRIBUTOR-HEADER-TITLE-IDENTIFICATION"),
      link: redirectionLink,
      linkKey: redirectionKey,
    },
    {
      field: "userFirstName",
      headerName: t("SORT.CUSTOMER.INPUT-FIRST-NAME"),
      sort: t("SORT.CUSTOMER.INPUT-FIRST-NAME"),
      sortKey: "user_data.first_name",
      link: redirectionLink,
      linkKey: redirectionKey,
    },
    {
      field: "userLastName",
      headerName: t("SORT.CUSTOMER.INPUT-LAST-NAME"),
      sort: t("SORT.CUSTOMER.INPUT-LAST-NAME"),
      sortKey: "user_data.last_name",
      link: redirectionLink,
      linkKey: redirectionKey,
    },
    {
      field: "account_type",
      headerName: t("SORT.CUSTOMER.INPUT-TYPE"),
      sort: t("SORT.CUSTOMER.INPUT-TYPE"),
      sortKey: "account_type",
      link: redirectionLink,
      linkKey: redirectionKey,
    },
    {
      field: "business",
      headerName: t("SORT.CUSTOMER.INPUT-BUSINESS"),
      sort: t("SORT.CUSTOMER.INPUT-BUSINESS"),
      sortKey: "business",
      link: redirectionLink,
      linkKey: redirectionKey,
    },
    {
      field: "company_name",
      headerName: t("SORT.CUSTOMER.INPUT-COMPANY-NAME"),
      sort: t("SORT.CUSTOMER.INPUT-COMPANY-NAME"),
      sortKey: "company_name",
      link: redirectionLink,
      linkKey: redirectionKey,
    },
    {
      field: "userZipCode",
      headerName: t("SORT.CUSTOMER.INPUT-ZIPCODE"),
      sort: t("SORT.CUSTOMER.INPUT-ZIPCODE"),
      sortKey: "user_data.address.zipcode",
      title: t("ALL.GENERAL.CUSTOMER-HEADER-TITLE-ADDRESS"),
      link: redirectionLink,
      linkKey: redirectionKey,
    },
    {
      field: "userCity",
      headerName: t("SORT.CUSTOMER.INPUT-CITY"),
      sort: t("SORT.CUSTOMER.INPUT-CITY"),
      sortKey: "user_data.address.city",
      link: redirectionLink,
      linkKey: redirectionKey,
    },
    {
      field: "userCountry",
      headerName: t("SORT.CUSTOMER.INPUT-COUNTRY"),
      sort: t("SORT.CUSTOMER.INPUT-COUNTRY"),
      sortKey: "user_data.address.country",
      link: redirectionLink,
      linkKey: redirectionKey,
    },
    {
      field: "userEmail",
      headerName: t("SORT.CUSTOMER.INPUT-EMAIL"),
      sort: t("SORT.CUSTOMER.INPUT-EMAIL"),
      sortKey: "user_data.email",
      title: t("ALL.GENERAL.CUSTOMER-HEADER-TITLE-MEANS-OF-CONTACT"),
      link: redirectionLink,
      linkKey: redirectionKey,
    },
    {
      field: "userMobile",
      headerName: t("SORT.CUSTOMER.INPUT-MOBILE-PHONE"),
      sort: t("SORT.CUSTOMER.INPUT-MOBILE-PHONE"),
      sortKey: "user_data.mobile_phone",
      link: redirectionLink,
      linkKey: redirectionKey,
    },
    {
      field: "userLanguaje",
      headerName: t("TABLE.USER.TITLE-LANGUAJE"),
      sort: t("TABLE.USER.TITLE-LANGUAJE"),
      sortKey: "user_data.users_settings.lenguage_active",
      title: t("HOME.MENU.OTHERS"),
      link: redirectionLink,
      linkKey: redirectionKey,
    },
  ];

  //API STATE
  const pageSize = props.rowsPerPage;
  //FILTRO
  let customersArraySort = [{}];
  if (customerArray) {
    customersArraySort = customerArray;
  }

  const [tabla, setTabla] = useState<IRowHeader[]>(tableInitial);
  useEffect(() => {
    setTabla(tableInitial.filter((item) => customerTableTransferData.includes(item.headerName)));
  }, [customerTableTransferData]);

  const createHeaderGroups = (row: IRowHeader, index: number) => <th>{row.title ? row.title : ""}</th>;

  const createHeader = (row: IRowHeader, index: number) => (
    <th
      key={`distributor-${index}`}
      style={{ cursor: "pointer" }}
      // onClick={() => {
      //   setDropDownWhySort(row.sort);
      //   incrementCount(row.sortKey);
      // }}
    >
      {row.headerName}
      {/* {inputToSort === row.sort && (
        <FontAwesomeIcon icon={typeSort === `asc(${row.sortKey})` ? faChevronUp : faChevronDown} style={{ marginLeft: "5px" }} />
      )} */}
    </th>
  );

  const createRow = (row: IRowCustomersFromAPI, index: number, render: IRowHeader[]) => {
    const isItemSelected: boolean = isSelected(row.id);
    return (
      <tr
        key={row?.id}
        style={{
          backgroundColor: "var(--color-white)",
          textAlign: "left",
          cursor: "pointer",
        }}
      >
        {defaultChecked ? (
          <td style={{ paddingLeft: "15px" }}>
            <img alt="" src={grayChecked} style={{ width: "20px", marginLeft: "10px" }}></img>
          </td>
        ) : (
          <td style={{ paddingLeft: "15px" }} className="check-table">
            <Checkbox
              checked={isItemSelected}
              style={{ display: "inline" }}
              name="gridRadios"
              id={`radios${row.id}`}
              value={`option${row.id}`}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                createDeleteArray(row.id, event.target.checked);
              }}
              aria-label="option 1"
            />
            &nbsp;
            {/* <FontAwesomeIcon icon={faBars} /> */}
          </td>
        )}
        {!props.Create ? (
          <>
            {render?.map((i: IRowHeader) => (
              <td style={{ paddingLeft: "15px" }} onClick={() => history.push(`${i.link}/${row[i.linkKey ? i.linkKey : "id"]}`)}>
                <Link
                  to="#"
                  style={{
                    color: "var(--color-main-text)",
                    textDecoration: "none",
                  }}
                >
                  {`${i.field}` === "userCountry" ? (
                    <>
                      {row.userCountry === "FR" ? (
                        <img alt="" src={franceBrand} style={{ paddingRight: "5px" }}></img>
                      ) : row.userCountry === "UK" ? (
                        <img alt="" src={englishBrand} style={{ paddingRight: "5px" }}></img>
                      ) : row.userCountry === "ES" ? (
                        <img alt="" src={spainBrand} style={{ paddingRight: "5px" }}></img>
                      ) : row.userCountry === "DE" ? (
                        <img alt="" src={germanyBrand} style={{ paddingRight: "5px" }}></img>
                      ) : (
                        ""
                      )}
                    </>
                  ) : `${i.field}` === "business" ? (
                    fromInfoToTranslatedBussiness(row[`${i.field}`])
                  ) : `${i.field}` === "userLanguaje" ? (
                    row[`${i.field}`] === "English" ? (
                      `${t("LANGUAGE-LIST-ENGLISH")}`
                    ) : (
                      `${t("LANGUAGE-LIST-FRENCH")}`
                    )
                  ) : `${i.field}` === "account_type" ? (
                    row[`${i.field}`] === "Individual" ? (
                      `${t("TABLE-CUSTOMER-STATS-TITLE-INDIVIDUAL")}`
                    ) : (
                      `${t("TABLE-CUSTOMER-STATS-TITLE-ARTISAN")}`
                    )
                  ) : (
                    row[`${i.field}`]
                  )}
                </Link>
              </td>
            ))}
          </>
        ) : (
          <>
            {render?.map((i: IRowHeader) => (
              <td style={{ paddingLeft: "15px" }} onClick={() => handleCustomer(row)}>
                {`${i.field}` === "userCountry" ? (
                  <>
                    {row.userCountry === "FR" ? (
                      <img alt="" src={franceBrand} style={{ paddingRight: "5px" }}></img>
                    ) : row.userCountry === "UK" ? (
                      <img alt="" src={englishBrand} style={{ paddingRight: "5px" }}></img>
                    ) : row.userCountry === "ES" ? (
                      <img alt="" src={spainBrand} style={{ paddingRight: "5px" }}></img>
                    ) : row.userCountry === "DE" ? (
                      <img alt="" src={germanyBrand} style={{ paddingRight: "5px" }}></img>
                    ) : (
                      ""
                    )}
                  </>
                ) : `${i.field}` === "business" ? (
                  fromInfoToTranslatedBussiness(row[`${i.field}`])
                ) : `${i.field}` === "userLanguaje" ? (
                  row[`${i.field}`] === "English" ? (
                    `${t("LANGUAGE-LIST-ENGLISH")}`
                  ) : (
                    `${t("LANGUAGE-LIST-FRENCH")}`
                  )
                ) : `${i.field}` === "account_type" ? (
                  row[`${i.field}`] === "Individual" ? (
                    `${t("TABLE-CUSTOMER-STATS-TITLE-INDIVIDUAL")}`
                  ) : (
                    `${t("TABLE-CUSTOMER-STATS-TITLE-ARTISAN")}`
                  )
                ) : (
                  row[`${i.field}`]
                )}
              </td>
            ))}
          </>
        )}
      </tr>
    );
  };

  //SELECT ALL LIST
  const allSelect = (allchecked: boolean, page: boolean) => {
    setDefaultChecked(allchecked);
    let AllIds: any = [];
    if (page) {
      AllIds = customersArraySort.map(function (ids: any) {
        return ids.id;
      });
    } else {
      AllIds = [];
      setCustomerAllSelected(false);
    }
    if (allchecked) {
      setCustomerAllSelected(true);
    }
    setCustomerListID(AllIds);
  };

  const isSelected = (id: string) => customerListID.indexOf(id) !== -1;

  return (
    <div>
      <Card>
        <div style={{ overflow: "scroll" }}>
          {!loading ? (
            <>
              {!matches ? (
                <Table
                  responsive="xl"
                  striped
                  hover
                  style={{
                    border: "2px solid var(--color-input-border)",
                    maxWidth: "100vw",
                  }}
                >
                  {/* TABLE HEADER */}
                  <thead
                    style={{
                      marginBottom: "10px",
                      textAlign: "left",
                      backgroundColor: "rgba(0, 0, 0, 0.03)",
                    }}
                  >
                    <tr>
                      <th></th>
                      {tabla.map(createHeaderGroups)}
                    </tr>
                    <tr>
                      <th>
                        <Container style={{ padding: 12 }}>
                          <Dropdown style={{ border: "none" }}>
                            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                              {/* <input type="checkbox" /> */}
                              {defaultChecked ? (
                                <img alt="" src={checkedimg} style={{ width: "20px" }}></img>
                              ) : (
                                <img alt="" src={uncheckedimg} style={{ width: "20px" }}></img>
                              )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <div onClick={() => allSelect(true, false)}>
                                <Dropdown.Item href="#/action-1">
                                  <img alt="" src={checkedimg}></img> {t("TABLES-SELECT-ALL")}
                                </Dropdown.Item>
                              </div>
                              <div onClick={() => allSelect(false, true)}>
                                <Dropdown.Item href="#/action-1">
                                  <img alt="" src={uncheckedpage}></img> {t("TABLES-SELECT-PAGE")}
                                </Dropdown.Item>
                              </div>
                              <div onClick={() => allSelect(false, false)}>
                                <Dropdown.Item href="#/action-3">
                                  <img alt="" src={uncheckedimg}></img> {t("TABLES-UNSELECT-PAGE")}
                                </Dropdown.Item>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Container>
                      </th>
                      {tabla?.map(createHeader)}
                    </tr>
                    <CustomerTableFilter
                      listTypeOptions={listTypeOptions}
                      listBusinessOptions={listBusinessOptions}
                      handleSearch={handleSearch}
                    />
                  </thead>

                  <tbody style={{ cursor: "pointer" }}>
                    {/* TABLE BODY */}
                    {customersArraySort?.map((row: any, index: number) => createRow(row, index, tabla))}
                  </tbody>
                </Table>
              ) : (
                <CustomerTableResponsive render={customersArraySort} Create={props.Create} handleCustomer={handleCustomer} />
              )}
            </>
          ) : (
            <LoadingSkeletonTables />
          )}
        </div>
        <div className="pb-2" style={{ display: "flex", justifyContent: "space-around" }}>
          <Pagination
            style={{ paddingTop: 10 }}
            count={Math.ceil(lastPage / pageSize)}
            page={currentPage}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
            color="primary"
            boundaryCount={3}
            siblingCount={4}
          />
        </div>
      </Card>
    </div>
  );
}
