import React, { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { faCheck, faChevronDown, faChevronUp, faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Container, Dropdown } from "react-bootstrap";

import Pagination from "@mui/material/Pagination";
import Table from "react-bootstrap/Table";
import LoadingSkeletonTables from "../LoadingSkeleton/LoadingSkeletonTables";

import "./distributorTable.css";
import { Link, useHistory } from "react-router-dom";
import uncheckedpage from "../../../images/checkPage.svg";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { useTranslation } from "react-i18next";

import checkedimg from "../../../images/checked.svg";
import uncheckedimg from "../../../images/unchecked.svg";
import grayChecked from "../../../images/grayCheck.png";
import { Checkbox, Tooltip, useMediaQuery } from "@mui/material";
//Redux
import { useSelector } from "react-redux";
import { IRootState } from "../../../store/redux";
import { typeUserData } from "../../../constants/userTypes";
import ImagesApi from "../../pages/ExchangesInfo/ImagesApi";
import DistributorTableFilter from "../Search/DistributorTableFilter";
import { IRowHeader } from "../../../models/table.modal";
import ImageStar from "./ImageStar";
import DistributorTableResponsive from "../../molecules/TransferList/responsiveTables/DistributorTableResponsive";

interface IRefusalProps {
  searchState: boolean;
  rowsPerPage: number;
  UsePlanText: string;

  inputToSort: string;

  groupID?: string;
  Create?: boolean;
  showSearchTable?: boolean;
  distributorArray: any;

  loading: boolean;
  currentPage: number;
  lastPage: number;
  handleChange: any;
  incrementCount?: any;
  typeSort?: string;

  //filters
  handleSearch: any;
  setDropDownWhySort: any;
}

export interface IRowDistributorFromAPI {
  id: string;
  group_code: string;
  group_name: string;
  members_of_set: any;
  code: string;
  name: string;
  client_account_number: string;
  delivery_account_number: string;
  is_visible: any;
  is_web: any;
  logo: string;
  distributor_city: string;
  distributor_postal_code: string;
  distributor_phone: string;
  email: string;
  responsible_email: string;
  evaluation: string;
  [index: string]: string;
}

export default function DistributorGroup(props: IRefusalProps) {
  const {
    setDistributorCode,
    setDistributorName,
    setDistributorGroupCode,
    setDistributorGroupName,
    setDistributorClientAccountNumer,
    setDistributorEmail,
    setDistributorId,
    setDistributorPhone,
    setOpenDistributorTable,
    openDistributorTable,

    setDistributorResponsiveEmail,
    setDistributorDeliveryAccountNumber,
    distributorTableTransferData,
    setDistributorsAllSelected,
  } = useContext(ApplicationContext);
  const { handleSearch, setDropDownWhySort } = props;
  const { distributorListID, setDistributorListID } = useContext(ApplicationContext);
  const { lastPage, handleChange, currentPage, distributorArray, loading, typeSort, inputToSort, incrementCount } = props;
  const { t, i18n } = useTranslation();
  const matches = useMediaQuery("(max-width:780px)");
  const history = useHistory();
  //Redux
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected } = profile;

  //TABLE
  const pageSize = props.rowsPerPage;

  const tableInitial: IRowHeader[] = [
    {
      field: "dropdown",
      headerName: "",
      sort: "",
    },
    {
      field: "group_code",
      headerName: t("ALL.GENERAL.DISTRIBUTOR.TITLE-GROUP-CODE"),
      sort: t("ALL.GENERAL.DISTRIBUTOR.TITLE-GROUP-CODE"),
      sortKey: "group_data.code",
      title: t("ALL.GENERAL.DISTRIBUTOR-HEADER-TITLE-IDENTIFICATION"),
      link: nameRolSelected === typeUserData.distributor || nameRolSelected === typeUserData.group ? "/distributor-info" : "/group-info",
      linkKey: nameRolSelected === typeUserData.distributor || nameRolSelected === typeUserData.group ? "id" : "group",
    },
    {
      field: "group_name",
      headerName: t("SEARCH.DISTRIBUTOR.INPUT-GROUP-NAME"),
      sort: t("SEARCH.DISTRIBUTOR.INPUT-GROUP-NAME"),
      sortKey: "group_data.name",
      link: nameRolSelected === typeUserData.distributor || nameRolSelected === typeUserData.group ? "/distributor-info" : "/group-info",
      linkKey: nameRolSelected === typeUserData.distributor || nameRolSelected === typeUserData.group ? "id" : "group",
    },
    {
      field: "members_of_set",
      headerName: t("ALL.GENERAL.TABLE.DIST-SET.TITLE-MEMBERS"),
      sort: t("ALL.GENERAL.TABLE.DIST-SET.TITLE-MEMBERS"),
      sortKey: "members_of_set",
      link: "/distributor-info",
      linkKey: "id",
    },
    {
      field: "code",
      headerName: t("ALL.GENERAL.DISTRIBUTOR.TITLE-CODE"),
      sort: t("ALL.GENERAL.DISTRIBUTOR.TITLE-CODE"),
      sortKey: "code",
      link: "/distributor-info",
      linkKey: "id",
    },
    {
      field: "name",
      headerName: t("ALL.GENERAL.DISTRIBUTOR.TITLE-NAME"),
      sort: t("ALL.GENERAL.DISTRIBUTOR.TITLE-NAME"),
      sortKey: "name",
      link: "/distributor-info",
      linkKey: "id",
    },
    {
      field: "client_account_number",
      headerName: t("ALL.GENERAL.DISTRIBUTOR.TITLE-CLIENT-ACCOUNT-NUMBER"),
      sort: t("ALL.GENERAL.DISTRIBUTOR.TITLE-CLIENT-ACCOUNT-NUMBER"),
      sortKey: "client_account_number",
      link: "/distributor-info",
      linkKey: "id",
    },
    {
      field: "delivery_account_number",
      headerName: t("ALL.GENERAL.DISTRIBUTOR.TITLE-DELIVERY-ACCOUNT-NUMBER"),
      sort: t("ALL.GENERAL.DISTRIBUTOR.TITLE-DELIVERY-ACCOUNT-NUMBER"),
      sortKey: "delivery_account_number",
      link: "/distributor-info",
      linkKey: "id",
    },
    {
      field: "is_visible",
      headerName: t("ALL.GENERAL.DISTRIBUTOR.TITLE-VISIBLE"),
      sort: t("ALL.GENERAL.DISTRIBUTOR.TITLE-VISIBLE"),
      sortKey: "is_visible",
      link: "/distributor-info",
      linkKey: "id",
    },
    {
      field: "is_web",
      headerName: "Web?",
      sort: "Web?",
      sortKey: "is_web",
      link: "/distributor-info",
      linkKey: "id",
    },
    {
      field: "logo",
      headerName: t("ALL.GENERAL.DISTRIBUTOR.TITLE-LOGO"),
      sort: t("ALL.GENERAL.DISTRIBUTOR.TITLE-LOGO"),
      sortKey: "logo",
      link: "/distributor-info",
      linkKey: "id",
    },
    {
      field: "distributor_city",
      headerName: t("ALL.GENERAL.DISTRIBUTOR.TITLE-CITY"),
      sort: t("ALL.GENERAL.DISTRIBUTOR.TITLE-CITY"),
      sortKey: "address.city",
      link: "/distributor-info",
      linkKey: "id",
    },
    {
      field: "distributor_postal_code",
      headerName: t("ALL.GENERAL.DISTRIBUTOR.TITLE-POSTAL-CODE"),
      sort: t("ALL.GENERAL.DISTRIBUTOR.TITLE-POSTAL-CODE"),
      sortKey: "address.postal_code",
      link: "/distributor-info",
      linkKey: "id",
    },
    {
      field: "distributor_phone",
      headerName: t("ALL.GENERAL.DISTRIBUTOR.TITLE-PHONE"),
      sort: t("ALL.GENERAL.DISTRIBUTOR.TITLE-PHONE"),
      sortKey: "address.phone",
      link: "/distributor-info",
      linkKey: "id",
    },
    {
      field: "email",
      headerName: t("ALL.GENERAL.DISTRIBUTOR.TITLE-EMAIL"),
      sort: t("ALL.GENERAL.DISTRIBUTOR.TITLE-EMAIL"),
      sortKey: "email",
      link: "/distributor-info",
      linkKey: "id",
    },
    {
      field: "responsible_email",
      headerName: t("ALL.GENERAL.DISTRIBUTOR.TITLE-RESPONSIBLE-EMAIL"),
      sort: t("ALL.GENERAL.DISTRIBUTOR.TITLE-RESPONSIBLE-EMAIL"),
      sortKey: "responsible_email",
      link: "/distributor-info",
      linkKey: "id",
    },
    {
      field: "evaluation",
      headerName: t("ALL.GENERAL.DISTRIBUTOR.TITLE-EVALUATION"),
      sort: t("ALL.GENERAL.DISTRIBUTOR.TITLE-EVALUATION"),
      sortKey: "evaluation",
      link: "/distributor-info",
      linkKey: "id",
    },
  ];

  //EXCHANGES
  const handleDistributor = (
    distributorId: string,
    distributorCode: string,
    distributorName: string,
    distributorGroupCode: string,
    distributorGroupName: string,
    distributorClientAccountNumber: string,
    distributorPhone: string,
    distributorEmail: string,
    distributorResponsibleEmail: string,
    distributorDeliveryAccountNumber: string
  ) => {
    setDistributorId(distributorId);
    setDistributorCode(distributorCode);
    setDistributorName(distributorName);
    setDistributorGroupCode(distributorGroupCode);
    setDistributorGroupName(distributorGroupName);
    setDistributorClientAccountNumer(distributorClientAccountNumber);
    setDistributorPhone(distributorPhone);
    setDistributorEmail(distributorEmail);
    setOpenDistributorTable(!openDistributorTable);
    setDistributorResponsiveEmail(distributorResponsibleEmail);
    setDistributorDeliveryAccountNumber(distributorDeliveryAccountNumber);
  };

  //GENERATE AN ARRAY WITH SELECTED IDS
  const [defaultChecked, setDefaultChecked] = useState<boolean>(false);
  const createDeleteArray = (idDelete: string, checked: boolean) => {
    if (checked) {
      setDistributorListID([...distributorListID, idDelete]);
    } else {
      setDistributorListID(distributorListID.filter((item: any) => item !== idDelete));
    }
  };

  //FILTRO
  let distributorsArraySort: IRowDistributorFromAPI[] = [];
  if (distributorArray) {
    distributorsArraySort = distributorArray;
  }

  const [tabla, setTabla] = useState<IRowHeader[]>(tableInitial);
  useEffect(() => {
    setTabla(tableInitial.filter((item) => distributorTableTransferData.includes(item.headerName)));
  }, [distributorTableTransferData]);

  const createHeaderGroups = (row: IRowHeader, index: number) => <th>{row.title ? row.title : ""}</th>;

  const createHeader = (row: IRowHeader, index: number) => (
    <th
      key={`distributor-${index}`}
      style={{ cursor: "pointer" }}
      // onClick={() => {
      //   setDropDownWhySort(row.sort);
      //   incrementCount(row.sortKey);
      // }}
    >
      {row.headerName}
      {/* {inputToSort === row.sort && (
        <FontAwesomeIcon icon={typeSort === `asc(${row.sortKey})` ? faChevronUp : faChevronDown} style={{ marginLeft: "5px" }} />
      )} */}
    </th>
  );

  const createRow = (row: IRowDistributorFromAPI, index: number, render: IRowHeader[]) => {
    const isItemSelected: boolean = isSelected(row.id);
    return (
      <tr
        key={row?.id}
        style={{
          backgroundColor: "var(--color-white)",
          textAlign: "left",
          cursor: "pointer",
        }}
      >
        {defaultChecked ? (
          <td style={{ paddingLeft: "15px" }}>
            <img alt="" src={grayChecked} style={{ width: "20px", marginLeft: "10px" }}></img>
          </td>
        ) : (
          <td style={{ paddingLeft: "15px" }} className="check-table">
            <Checkbox
              checked={isItemSelected}
              style={{ display: "inline" }}
              name="gridRadios"
              id={`radios${row.id}`}
              value={`option${row.id}`}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                createDeleteArray(row.id, event.target.checked);
              }}
              aria-label="option 1"
            />
            &nbsp;
            {/* <FontAwesomeIcon icon={faBars} /> */}
          </td>
        )}
        {!props.Create ? (
          <>
            {render?.map((i: IRowHeader) => (
              <td
                style={{ paddingLeft: "15px" }}
                onClick={() => `${i.field}` !== "members_of_set" && history.push(`${i.link}/${row[i.linkKey ? i.linkKey : "id"]}`)}
              >
                <Link
                  to="#"
                  style={{
                    color: `${i.field}` === "group_code" || `${i.field}` === "group_name" ? "" : "var(--color-main-text)",
                    textDecoration: "none",
                  }}
                >
                  {`${i.field}` === "logo" ? (
                    <ImagesApi image={row?.logo} />
                  ) : `${i.field}` === "is_visible" ? (
                    <div style={{ paddingLeft: "20px" }}> {row.is_visible === true && <FontAwesomeIcon icon={faCheck} />}</div>
                  ) : `${i.field}` === "members_of_set" ? (
                    <div>
                      {row?.members_of_set?.map((data: any) => (
                        <Link to={`/distributors-set-info/${data.id}`} style={{ textDecoration: "none", color: "#000" }}>
                          <div className="input-members">
                            <div style={{ display: "flex" }}>
                              <div style={{ margin: "0 8px" }}>{data.code}</div>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  ) : `${i.field}` === "is_web" ? (
                    <div style={{ paddingLeft: "20px" }}>
                      {" "}
                      {row.is_web === true && (
                        <Tooltip title="Yes" placement="bottom">
                          <FontAwesomeIcon icon={faGlobe} />
                        </Tooltip>
                      )}
                    </div>
                  ) : `${i.field}` === "evaluation" ? (
                    <div>
                      {row.evaluation == "1" ? (
                        <ImageStar />
                      ) : row.evaluation == "2" ? (
                        <div>
                          <ImageStar />
                          <ImageStar />
                        </div>
                      ) : row.evaluation == "3" ? (
                        <div>
                          <ImageStar />
                          <ImageStar />
                          <ImageStar />
                        </div>
                      ) : row.evaluation == "4" ? (
                        <div>
                          <ImageStar />
                          <ImageStar />
                          <ImageStar />
                        </div>
                      ) : row.evaluation == "5" ? (
                        <div>
                          <ImageStar />
                          <ImageStar />
                          <ImageStar />
                          <ImageStar />
                          <ImageStar />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    row[`${i.field}`]
                  )}
                </Link>
              </td>
            ))}
          </>
        ) : (
          <>
            {render?.map((i: IRowHeader) => (
              <td
                style={{ paddingLeft: "15px" }}
                onClick={() =>
                  handleDistributor(
                    row.id,
                    row.code,
                    row.name,
                    row.group_code,
                    row.group_name,
                    row.client_account_number,
                    row.distributor_phone,
                    row.email,
                    row.responsible_email,
                    row.delivery_account_number
                  )
                }
              >
                {`${i.field}` === "logo" ? (
                  <ImagesApi image={row?.logo} />
                ) : `${i.field}` === "is_visible" ? (
                  <div style={{ paddingLeft: "20px" }}> {row.is_visible === true && <FontAwesomeIcon icon={faCheck} />}</div>
                ) : `${i.field}` === "members_of_set" ? (
                  <div>
                    {row?.members_of_set?.map((data: any) => (
                      <Link to={`/distributors-set-info/${data.id}`} style={{ textDecoration: "none", color: "#000" }}>
                        <div className="input-members">
                          <div style={{ display: "flex" }}>
                            <div style={{ margin: "0 8px" }}>{data.code}</div>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                ) : `${i.field}` === "is_web" ? (
                  <div style={{ paddingLeft: "20px" }}>
                    {" "}
                    {row.is_web === true && (
                      <Tooltip title="Yes" placement="bottom">
                        <FontAwesomeIcon icon={faGlobe} />
                      </Tooltip>
                    )}
                  </div>
                ) : `${i.field}` === "evaluation" ? (
                  <div>
                    {row.evaluation == "1" ? (
                      <ImageStar />
                    ) : row.evaluation == "2" ? (
                      <div>
                        <ImageStar />
                        <ImageStar />
                      </div>
                    ) : row.evaluation == "3" ? (
                      <div>
                        <ImageStar />
                        <ImageStar />
                        <ImageStar />
                      </div>
                    ) : row.evaluation == "4" ? (
                      <div>
                        <ImageStar />
                        <ImageStar />
                        <ImageStar />
                        <ImageStar />
                      </div>
                    ) : row.evaluation == "5" ? (
                      <div>
                        <ImageStar />
                        <ImageStar />
                        <ImageStar />
                        <ImageStar />
                        <ImageStar />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  row[`${i.field}`]
                )}
              </td>
            ))}
          </>
        )}
      </tr>
    );
  };

  //SELECT ALL LIST
  const allSelect = (allchecked: boolean, page: boolean) => {
    setDefaultChecked(allchecked);
    let AllIds: any = [];
    if (page) {
      AllIds = distributorsArraySort.map(function (ids: any) {
        return ids.id;
      });
    } else {
      AllIds = [];
      setDistributorsAllSelected(false);
    }
    if (allchecked) {
      setDistributorsAllSelected(true);
    }
    setDistributorListID(AllIds);
  };

  const isSelected = (id: string) => distributorListID.indexOf(id) !== -1;
  return (
    <div>
      <Card>
        <div style={{ overflowX: "scroll" }}>
          {!props.loading ? (
            <>
              {!matches ? (
                <Table responsive="xl" striped hover style={{ border: "2px solid var(--color-input-border)" }}>
                  {/* TABLE HEADER */}
                  <thead
                    style={{
                      marginBottom: "10px",
                      textAlign: "left",
                      backgroundColor: "rgba(0, 0, 0, 0.03)",
                    }}
                  >
                    <tr>
                      <th></th>
                      {tabla.map(createHeaderGroups)}
                    </tr>
                    <tr>
                      <th className="check-table">
                        <Container style={{ padding: 12 }}>
                          <Dropdown style={{ border: "none" }}>
                            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                              {/* <input type="checkbox" /> */}
                              {defaultChecked ? (
                                <img alt="" src={checkedimg} style={{ width: "20px" }}></img>
                              ) : (
                                <img alt="" src={uncheckedimg} style={{ width: "20px" }}></img>
                              )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <div onClick={() => allSelect(true, false)}>
                                <Dropdown.Item href="#/action-1">
                                  <img alt="" src={checkedimg}></img> {t("TABLES-SELECT-ALL")}
                                </Dropdown.Item>
                              </div>
                              <div onClick={() => allSelect(false, true)}>
                                <Dropdown.Item href="#/action-1">
                                  <img alt="" src={uncheckedpage}></img> {t("TABLES-SELECT-PAGE")}
                                </Dropdown.Item>
                              </div>
                              <div onClick={() => allSelect(false, false)}>
                                <Dropdown.Item href="#/action-3">
                                  <img alt="" src={uncheckedimg}></img> {t("TABLES-UNSELECT-PAGE")}
                                </Dropdown.Item>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Container>
                      </th>
                      {tabla?.map(createHeader)}
                    </tr>
                    <DistributorTableFilter handleSearch={handleSearch} />
                  </thead>
                  <tbody style={{ cursor: "pointer !important" }}>
                    {/* TABLE BODY */}
                    {distributorsArraySort?.map((row: IRowDistributorFromAPI, index: number) => createRow(row, index, tabla))}
                  </tbody>
                </Table>
              ) : (
                <DistributorTableResponsive render={distributorsArraySort} Create={props.Create} handleDistributor={handleDistributor} />
              )}
            </>
          ) : (
            <LoadingSkeletonTables />
          )}
        </div>
        <div className="pb-2" style={{ display: "flex", justifyContent: "space-around" }}>
          <Pagination
            style={{ paddingTop: 10 }}
            count={Math.ceil(lastPage / pageSize)}
            page={currentPage}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
            color="primary"
            boundaryCount={3}
            siblingCount={4}
          />
        </div>
      </Card>
    </div>
  );
}
