import React, { useContext, useEffect, useState } from "react";
import TransferModal from "../../organisms/TransferModal/TransferModal";
import { useTranslation } from "react-i18next";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { LocalStorageKeys, getInLocalStorageArray } from "../../../helpers/local-storage-manager";
export interface IRowHeaderBrand {
  field: string;
  headerName: string;
  sort: string;
}
interface IProps {
  open: boolean;
  setOpen: any;
}

export default function DistributorTableTransfer(props: IProps) {
  const { distributorTableTransferData, setDistributorTableTransferData } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const { open, setOpen } = props;
  const tableDistributorInitial: string[] = [
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-GROUP-CODE"),
    t("SEARCH.DISTRIBUTOR.INPUT-GROUP-NAME"),
    t("ALL.GENERAL.TABLE.DIST-SET.TITLE-MEMBERS"),
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-CODE"),
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-NAME"),
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-CLIENT-ACCOUNT-NUMBER"),
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-DELIVERY-ACCOUNT-NUMBER"),
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-VISIBLE"),
    "Web?",
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-LOGO"),
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-CITY"),
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-POSTAL-CODE"),
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-PHONE"),
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-EMAIL"),
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-RESPONSIBLE-EMAIL"),
    t("ALL.GENERAL.DISTRIBUTOR.TITLE-EVALUATION"),
  ];

  const [dataRight, setDataRight] = useState<string[]>([]);

  useEffect(() => {
    const initialArray = getInLocalStorageArray(LocalStorageKeys.DISTRIBUTOR_TABLE);
    const initialArrayRight = getInLocalStorageArray(LocalStorageKeys.DISTRIBUTOR_TABLE_R);
    setDistributorTableTransferData(initialArray?.length > 0 ? initialArray : tableDistributorInitial);
    setDataRight(initialArrayRight?.length > 0 ? initialArrayRight : []);
  }, []);
  return (
    <TransferModal
      keyValueRight={`${LocalStorageKeys.DISTRIBUTOR_TABLE_R}`}
      keyValue={`${LocalStorageKeys.DISTRIBUTOR_TABLE}`}
      itemsLeft={distributorTableTransferData}
      itemsRight={dataRight}
      setItemsLeft={setDistributorTableTransferData}
      setItemsRight={setDataRight}
      openConfirm={open}
      setOpenConfirm={setOpen}
    />
  );
}
