import { Dispatch, SetStateAction } from "react";
import { Modal } from "react-bootstrap";
import Maps from "./Maps";

interface ICoordinates {
  lat: number | undefined;
  lng: number | undefined;
}

interface IMapModal {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  positions: ICoordinates[];
  fullWidth?: any;
}
export default function MapModal({ show, setShow, positions, fullWidth }: IMapModal) {
  return (
    <Modal
      fullscreen={fullWidth}
      show={show}
      onHide={() => {
        setShow(false);
      }}
      size="xl"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="row">
          <Maps positions={positions} />
        </div>
      </Modal.Body>
    </Modal>
  );
}
