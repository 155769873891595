import "bootstrap/dist/css/bootstrap.css";
import "../../../constants/global.css";
import TextEditor from "../../organisms/TextEditor";
import InputClear from "../../atoms/InputClear";
import "./ProductTableMarketing.css";
import { faExternalLinkAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import FileAttachement from "../../atoms/FileAttachement/FileAttachement";
import DownloadImage from "../../atoms/DownloadImage";
import ImagesApi from "../../pages/ExchangesInfo/ImagesApi";
import LoadingImageSkeleton from "../LoadingSkeleton/LoadingImageSkeleton";
import { useSelector } from "react-redux";
import { IRootState } from "../../../store/redux";
import InputClearGuard from "../../atoms/InputClearGuard";
import { typeUserData } from "../../../constants/userTypes";
import DownloadImageUrl from "../../atoms/DownloadImageUrl";
export default function ProductTableMarketing(props: any) {
  //Translations
  const { t, i18n } = useTranslation();
  const { fileName, handleChangeStatus, loadingPicture, picture, extension, handleDeleteImage } = props;

  //Redux
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected } = profile;

  return (
    <div className="card my-4 ml-lg-2 ml-0">
      <div className="row my-4 px-lg-5 px-2" style={{ justifyContent: "center" }}>
        <div className="col-12 col-lg-12 sectionBoxTop py-2">
          <h4 className="h4-stl">{t("CREATE.PRODUCT.CARD-HEADER-TITLE-MARKETING")}</h4>
        </div>
        <div className="col-12 col-lg-12 my-4">
          <h4 className="h4-stl">
            <strong>{t("CREATE.PRODUCT.INPUT-DESCRIPTION")}</strong>
          </h4>
          <div>
            <TextEditor
              height={"200px"}
              handleChange={props.handleChange}
              readOnly={nameRolSelected !== typeUserData.admin}
              defaultValue={props.inputDescription}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 my-4">
            <h4 className="h4-stl">
              <strong>{t("CREATE.PRODUCT.INPUT-BROCHURE")}</strong>
            </h4>
            <div style={{ width: "100%" }} className="px-3">
              <FileAttachement
                fileName={fileName}
                handleChangeStatus={handleChangeStatus}
                disabled={nameRolSelected !== typeUserData.admin}
              />
              <div className="row my-4 px-4" style={{ textAlign: "center" }}>
                <ImagesApi extension={extension} image={picture} fullWidth />
              </div>
              <div style={{ display: "flex" }}>
                <DownloadImageUrl image={picture} name="picture1" style={{ marginRight: 10 }} />

                <FontAwesomeIcon style={{ cursor: "pointer", marginTop: "4px" }} icon={faTrashAlt} onClick={handleDeleteImage} />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 my-4">
            <h4 className="h4-stl">
              <strong>{t("CREATE.PRODUCT.INPUT-ONLINE-DOC")}</strong>
            </h4>
            <div className="flex-inputs-responsive-info">
              <div className="full-width">
                <InputClearGuard
                  type="text"
                  name=""
                  placeholder=""
                  style={{ width: "100%" }}
                  currentValue={props.onlineDoc}
                  handleOnInputValue={props.setOnlineDoc}
                  roles={[`${typeUserData.admin}`]}
                />
              </div>
              <div>
                <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: "8px", width: "2Opx", height: "20px" }} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12  my-4">
            <h4 className="h4-stl">
              <strong>{t("CREATE.PRODUCT.INPUT-EAN")}</strong>
            </h4>
            <div style={{}}>
              <InputClearGuard
                type="text"
                name=""
                placeholder=""
                style={{ width: "100%" }}
                currentValue={props.ean}
                handleOnInputValue={props.setEan}
                roles={[`${typeUserData.admin}`]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
