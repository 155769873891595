import { useContext } from "react";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { faThumbtack, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import Tooltip from "@mui/material/Tooltip";
import InputClear from "../../atoms/InputClear";
import DropDown from "../../atoms/DropDown";
import { useFilterContext } from "../../../context";
import useAutocomplete from "../../atoms/AutocopleteInput/useAutocomplete";
import { createAddapterDistributor } from "../../../hooks/Distributors/adapters/distributorAdapter";
import AutoCompleteInput from "../../atoms/AutocopleteInput/AutocompleteInput";

interface ITable {
  handleSearch: any;
}

export default function DistributorTableFilter(props: ITable) {
  const listTypeOptions = ["", t("TABLE.DISTRIBUTORS-TITLE-YES"), t("TABLE.DISTRIBUTORS-TITLE-NO")];
  const {
    inputGroupCodeSearch,
    setInputGroupCodeSearch,
    inputGroupNameSearch,
    setInputGroupNameSearch,
    inputCodeSearchDistributor,
    setInputCodeSearchDistributor,
    inputClientAccountNumberSearch,
    setInputClientAccountNumberSearch,
    inputNameSearchDistributor,
    setInputNameSearchDistributor,
    inputVisibleSearch,
    setInputVisibleSearch,
    inputWebSearch,
    setInputWebSearch,
    inputEmailSearch,
    setInputEmailSearch,
    inputEvaluationSearch,
    setInputEvaluationSearch,
    inputMembersOfSetSearch,
    setInputMembersOfSetSearch,
    inputResponsibleEmailSearch,
    setInputResponsibleEmailSearch,
    inputDeliveryAccountNumberSearch,
    setInputDeliveryAccountNumberSearch,
    showLateralContainerSearch,
  } = useFilterContext();
  const { handleSearch } = props;
  const { typeOfSearch, setTypeOfSearch, distributorTableTransferData } = useContext(ApplicationContext);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleSearch();
  };

  //**Filters */
  const groupCode = useAutocomplete(
    "/distributors/get-all?autocomplete=true&group_code=",
    handleSearch,
    inputGroupCodeSearch,
    setInputGroupCodeSearch,
    "group_code",
    createAddapterDistributor
  );
  const groupName = useAutocomplete(
    "/distributors/get-all?autocomplete=true&group_name=",
    handleSearch,
    inputGroupNameSearch,
    setInputGroupNameSearch,
    "group_name",
    createAddapterDistributor
  );
  const membersOfSet = useAutocomplete(
    "/distributor-set/get-all?autocomplete=true&name=",
    handleSearch,
    inputMembersOfSetSearch,
    setInputMembersOfSetSearch,
    "name"
  );
  const code = useAutocomplete(
    "/distributors/get-all?autocomplete=true&code=",
    handleSearch,
    inputCodeSearchDistributor,
    setInputCodeSearchDistributor,
    "code"
  );
  const name = useAutocomplete(
    "/distributors/get-all?autocomplete=true&name=",
    handleSearch,
    inputNameSearchDistributor,
    setInputNameSearchDistributor,
    "name"
  );
  const clientAccountNumber = useAutocomplete(
    "/distributors/get-all?autocomplete=true&client_account_number=",
    handleSearch,
    inputClientAccountNumberSearch,
    setInputClientAccountNumberSearch,
    "client_account_number"
  );
  const deliveryAccountNumber = useAutocomplete(
    "/distributors/get-all?autocomplete=true&delivery_account_number=",
    handleSearch,
    inputDeliveryAccountNumberSearch,
    setInputDeliveryAccountNumberSearch,
    "delivery_account_number"
  );
  const email = useAutocomplete("/distributors/get-all?autocomplete=true&email=", handleSearch, inputEmailSearch, setInputEmailSearch, "email");
  const responsibleEmail = useAutocomplete(
    "/distributors/get-all?autocomplete=true&responsible_email=",
    handleSearch,
    inputResponsibleEmailSearch,
    setInputResponsibleEmailSearch,
    "responsible_email"
  );
  const evaluation = useAutocomplete(
    "/distributors/get-all?autocomplete=true&evaluation=",
    handleSearch,
    inputEvaluationSearch,
    setInputEvaluationSearch,
    "evaluation"
  );

  return (
    <>
      {(typeOfSearch === 0 ? showLateralContainerSearch : false) === true && (
        <tr style={{ overflow: "scroll" }}>
          <td>
            <Tooltip title={`${t("FILER-TO-DOCK")}`} placement="bottom">
              <div style={{ width: "60px" }}>
                <FontAwesomeIcon
                  className="grayBackground-icon"
                  size="2x"
                  style={{ cursor: "pointer" }}
                  icon={faThumbtack}
                  onClick={() => {
                    setTypeOfSearch(1);
                  }}
                />
              </div>
            </Tooltip>
          </td>

          {distributorTableTransferData.includes(`${t("ALL.GENERAL.DISTRIBUTOR.TITLE-GROUP-CODE")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <AutoCompleteInput {...groupCode} freeSolo />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputGroupCodeSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {distributorTableTransferData.includes(`${t("SEARCH.DISTRIBUTOR.INPUT-GROUP-NAME")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <AutoCompleteInput {...groupName} freeSolo />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputGroupNameSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {distributorTableTransferData.includes(`${t("ALL.GENERAL.TABLE.DIST-SET.TITLE-MEMBERS")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <AutoCompleteInput {...membersOfSet} freeSolo />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputMembersOfSetSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {distributorTableTransferData.includes(`${t("ALL.GENERAL.DISTRIBUTOR.TITLE-CODE")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <AutoCompleteInput {...code} freeSolo />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputCodeSearchDistributor("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {distributorTableTransferData.includes(`${t("ALL.GENERAL.DISTRIBUTOR.TITLE-NAME")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <AutoCompleteInput {...name} freeSolo />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputNameSearchDistributor("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {distributorTableTransferData.includes(`${t("ALL.GENERAL.DISTRIBUTOR.TITLE-CLIENT-ACCOUNT-NUMBER")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <AutoCompleteInput {...clientAccountNumber} freeSolo />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputClientAccountNumberSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {distributorTableTransferData.includes(`${t("ALL.GENERAL.DISTRIBUTOR.TITLE-DELIVERY-ACCOUNT-NUMBER")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <AutoCompleteInput {...deliveryAccountNumber} freeSolo />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputDeliveryAccountNumberSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {distributorTableTransferData.includes(`${t("ALL.GENERAL.DISTRIBUTOR.TITLE-VISIBLE")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div>
                    <DropDown
                      options={listTypeOptions}
                      selectedItem={inputVisibleSearch}
                      onSelect={(data: string) => {
                        setInputVisibleSearch(data);
                        handleSearch();
                      }}
                      style={{
                        color: "var(--color-input-text)",
                        borderRadius: "2px",
                        textAlign: "left",
                        fontFamily: "Arial",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "14px",
                        lineHeight: "16px",
                        width: `100%`,
                        maxWidth: "100px",
                        overflow: "hidden",
                        height: "30px",
                        border: "1px solid #ced4da",
                        padding: "4px",
                      }}
                      widthMenu="230px"
                      isCompact
                    />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputVisibleSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {distributorTableTransferData.includes("Web?") && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div>
                    <DropDown
                      options={listTypeOptions}
                      selectedItem={inputWebSearch}
                      onSelect={(data: string) => {
                        setInputWebSearch(data);
                        handleSearch();
                      }}
                      style={{
                        color: "var(--color-input-text)",
                        borderRadius: "2px",
                        textAlign: "left",
                        fontFamily: "Arial",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "14px",
                        lineHeight: "16px",
                        width: `100%`,
                        maxWidth: "100px",
                        overflow: "hidden",
                        height: "30px",
                        border: "1px solid #ced4da",
                        padding: "4px",
                      }}
                      widthMenu="230px"
                      isCompact
                    />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputWebSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {distributorTableTransferData.includes(`${t("ALL.GENERAL.DISTRIBUTOR.TITLE-LOGO")}`) && <td></td>}
          {distributorTableTransferData.includes(`${t("ALL.GENERAL.DISTRIBUTOR.TITLE-CITY")}`) && <td></td>}
          {distributorTableTransferData.includes(`${t("ALL.GENERAL.DISTRIBUTOR.TITLE-POSTAL-CODE")}`) && <td></td>}
          {distributorTableTransferData.includes(`${t("ALL.GENERAL.DISTRIBUTOR.TITLE-PHONE")}`) && <td></td>}
          {distributorTableTransferData.includes(`${t("ALL.GENERAL.DISTRIBUTOR.TITLE-EMAIL")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <AutoCompleteInput {...email} freeSolo />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputEmailSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {distributorTableTransferData.includes(`${t("ALL.GENERAL.DISTRIBUTOR.TITLE-RESPONSIBLE-EMAIL")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <AutoCompleteInput {...responsibleEmail} freeSolo />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputResponsibleEmailSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {distributorTableTransferData.includes(`${t("ALL.GENERAL.DISTRIBUTOR.TITLE-EVALUATION")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "80%" }}>
                    <AutoCompleteInput {...evaluation} freeSolo />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputEvaluationSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
        </tr>
      )}
    </>
  );
}
