import { useContext } from "react";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { faThumbtack, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import Tooltip from "@mui/material/Tooltip";
import InputClear from "../../atoms/InputClear";
import { useFilterContext } from "../../../context";
import useAutocomplete from "../../atoms/AutocopleteInput/useAutocomplete";
import { createAddapterDistributorSet } from "../../../hooks/DistributorSet/adapters/distributorSetAdapter";
import AutoCompleteInput from "../../atoms/AutocopleteInput/AutocompleteInput";

export interface ITableFilters {
  handleSearch: any;
}

export default function DistributorSetTableFilter(props: ITableFilters) {
  const {
    inputGroupCodeSearchDistributorSet,
    setInputGroupCodeSearchDistributorSet,
    inputGroupNameSearchDistributorSet,
    setInputGroupNameSearchDistributorSet,
    inputNameSearchDistributorSet,
    setInputNameSearchDistributorSet,
    inputOriginSearch,
    setInputOriginSearch,
    inputMembersSearch,
    setInputMembersSearch,
    showLateralContainerSearch,
  } = useFilterContext();
  const { handleSearch } = props;
  const { typeOfSearch, setTypeOfSearch, distributorSetTableTransferData } = useContext(ApplicationContext);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleSearch();
  };
  const groupCode = useAutocomplete(
    "/distributor-set/get-all?autocomplete=true&group_code=",
    handleSearch,
    inputGroupCodeSearchDistributorSet,
    setInputGroupCodeSearchDistributorSet,
    "groupCode",
    createAddapterDistributorSet
  );
  const name = useAutocomplete(
    "/distributor-set/get-all?autocomplete=true&name=",
    handleSearch,
    inputNameSearchDistributorSet,
    setInputNameSearchDistributorSet,
    "name"
  );
  const origin = useAutocomplete(
    "/distributor-set/get-all?autocomplete=true&origin=",
    handleSearch,
    inputOriginSearch,
    setInputOriginSearch,
    "origin"
  );
  const membersOfSet = useAutocomplete(
    "/distributors/get-all?autocomplete=true&members_of_set=",
    handleSearch,
    inputMembersSearch,
    setInputMembersSearch,
    "code"
  );
  return (
    <>
      {(typeOfSearch === 0 ? showLateralContainerSearch : false) === true && (
        <tr>
          <td>
            <Tooltip title={`${t("FILER-TO-DOCK")}`} placement="bottom">
              <div style={{ width: "60px" }}>
                <FontAwesomeIcon
                  className="grayBackground-icon"
                  size="2x"
                  style={{ cursor: "pointer" }}
                  icon={faThumbtack}
                  onClick={() => {
                    setTypeOfSearch(1);
                  }}
                />
              </div>
            </Tooltip>
          </td>
          {distributorSetTableTransferData.includes(`${t("ALL.GENERAL.TABLE.DIST-SET.TITLE-CODE")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "90%" }}>
                    <AutoCompleteInput {...groupCode} freeSolo />
                  </div>
                  <div style={{ width: "10%" }}>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputGroupCodeSearchDistributorSet("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {distributorSetTableTransferData.includes(`${t("ALL.GENERAL.TABLE.DIST-SET.TITLE-NAME")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "90%" }}>
                    <AutoCompleteInput {...name} freeSolo />
                  </div>
                  <div style={{ width: "10%" }}>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputNameSearchDistributorSet("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {distributorSetTableTransferData.includes(`${t("ALL.GENERAL.TABLE.DIST-SET.TITLE-ORIGIN")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "90%" }}>
                    <AutoCompleteInput {...origin} freeSolo />
                  </div>
                  <div style={{ width: "10%" }}>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputOriginSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {distributorSetTableTransferData.includes(`${t("ALL.GENERAL.TABLE.DIST-SET.TITLE-MEMBERS")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "90%" }}>
                    <AutoCompleteInput {...membersOfSet} freeSolo />
                  </div>
                  <div style={{ width: "10%" }}>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputMembersSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
        </tr>
      )}
    </>
  );
}
