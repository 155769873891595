import { t } from "i18next";
import React, { useState, createContext, useContext, SetStateAction, Dispatch } from "react";

/*
The idea of this context it is allow a mechanism to pass the information between the components of the page.
*/

type contextValues = {
  inputCodeSearch: string;
  setInputCodeSearch: Dispatch<SetStateAction<string>>;
  inputNameSearch: string;
  setInputNameSearch: Dispatch<SetStateAction<string>>;
  showLateralContainerSearch: boolean;
  setShowLateralContainerSearch: Dispatch<SetStateAction<boolean>>;
  //Products
  inputBrandCodeSearch: string;
  setInputBrandCodeSearch: Dispatch<SetStateAction<string>>;
  inputBrandNameSearch: string;
  setInputBrandNameSearch: Dispatch<SetStateAction<string>>;
  inputBrandSearch: string;
  setInputBrandSearch: Dispatch<SetStateAction<string>>;
  inputReferenceSearch: string;
  setInputReferenceSearch: Dispatch<SetStateAction<string>>;
  inputOldReferenceSearch: string;
  setInputOldReferenceSearch: Dispatch<SetStateAction<string>>;
  inputNameSearchProduct: string;
  setInputNameSearchProduct: Dispatch<SetStateAction<string>>;
  inputTypeSearch: string;
  setInputTypeSearch: Dispatch<SetStateAction<string>>;
  inputDefaultSearch: string;
  setInputDefaultSearch: Dispatch<SetStateAction<string>>;
  inputUnitPriceSearch: string;
  setInputUnitPriceSearch: Dispatch<SetStateAction<string>>;
  inputAvailableSearch: string;
  setInputAvailableSearch: Dispatch<SetStateAction<string>>;
  inputWarrantySearch: string;
  setInputWarrantySearch: Dispatch<SetStateAction<string>>;
  inputBrochureSearch: string;
  setInputBrochureSearch: Dispatch<SetStateAction<string>>;
  inputEanSearch: string;
  setInputEanSearch: Dispatch<SetStateAction<string>>;

  //Groups
  inputCodeSearchGroup: string;
  setInputCodeSearchGroup: Dispatch<SetStateAction<string>>;
  inputNameSearchGroup: string;
  setInputNameSearchGroup: Dispatch<SetStateAction<string>>;

  //Distributors
  inputGroupSearch: string;
  setInputGroupSearch: Dispatch<SetStateAction<string>>;
  inputGroupCodeSearch: string;
  setInputGroupCodeSearch: Dispatch<SetStateAction<string>>;
  inputGroupNameSearch: string;
  setInputGroupNameSearch: Dispatch<SetStateAction<string>>;
  inputCodeSearchDistributor: string;
  setInputCodeSearchDistributor: Dispatch<SetStateAction<string>>;
  inputClientAccountNumberSearch: string;
  setInputClientAccountNumberSearch: Dispatch<SetStateAction<string>>;
  inputNameSearchDistributor: string;
  setInputNameSearchDistributor: Dispatch<SetStateAction<string>>;
  inputVisibleSearch: string;
  setInputVisibleSearch: Dispatch<SetStateAction<string>>;
  inputWebSearch: string;
  setInputWebSearch: Dispatch<SetStateAction<string>>;
  inputAddressSearch: string;
  setInputAddressSearch: Dispatch<SetStateAction<string>>;
  inputEmailSearch: string;
  setInputEmailSearch: Dispatch<SetStateAction<string>>;
  inputGeographicalCoordinatesSearch: string;
  setInputGeographicalCoordinatesSearch: Dispatch<SetStateAction<string>>;
  inputMapURLSearch: string;
  setInputMapURLSearch: Dispatch<SetStateAction<string>>;
  inputEvaluationSearch: string;
  setInputEvaluationSearch: Dispatch<SetStateAction<string>>;
  inputLookupSearch: string;
  setInputLookupSearch: Dispatch<SetStateAction<string>>;
  inputMembersOfSetSearch: string;
  setInputMembersOfSetSearch: Dispatch<SetStateAction<string>>;
  inputResponsibleEmailSearch: string;
  setInputResponsibleEmailSearch: Dispatch<SetStateAction<string>>;
  inputDeliveryAccountNumberSearch: string;
  setInputDeliveryAccountNumberSearch: Dispatch<SetStateAction<string>>;

  //Distributor sets
  inputGroupCodeSearchDistributorSet: string;
  setInputGroupCodeSearchDistributorSet: Dispatch<SetStateAction<string>>;
  inputGroupNameSearchDistributorSet: string;
  setInputGroupNameSearchDistributorSet: Dispatch<SetStateAction<string>>;
  inputNameSearchDistributorSet: string;
  setInputNameSearchDistributorSet: Dispatch<SetStateAction<string>>;
  inputOriginSearch: string;
  setInputOriginSearch: Dispatch<SetStateAction<string>>;
  inputMembersSearch: string;
  setInputMembersSearch: Dispatch<SetStateAction<string>>;

  //Customer
  inputUsernameSearch: string;
  setInputUsernameSearch: Dispatch<SetStateAction<string>>;
  inputFirstnameSearch: string;
  setInputFirstnameSearch: Dispatch<SetStateAction<string>>;
  inputLastnameSearch: string;
  setInputLastnameSearch: Dispatch<SetStateAction<string>>;
  inputTypeSearchCustomer: string;
  setInputTypeSearchCustomer: Dispatch<SetStateAction<string>>;
  inputBusinessSearch: string;
  setInputBusinessSearch: Dispatch<SetStateAction<string>>;
  inputZipCodeSearch: string;
  setInputZipCodeSearch: Dispatch<SetStateAction<string>>;
  inputCitySearch: string;
  setInputCitySearch: Dispatch<SetStateAction<string>>;
  inputCountrySearch: string;
  setInputCountrySearch: Dispatch<SetStateAction<string>>;
  inputMapUrlSearch: string;
  setInputMapUrlSearch: Dispatch<SetStateAction<string>>;
  inputGeneralTermsAcceptedSearch: string;
  setGeneralTermsAcceptedSearch: Dispatch<SetStateAction<string>>;
  inputPrivacyPolicyAcceptedSearch: string;
  setPrivacyPolicyAcceptedSearch: Dispatch<SetStateAction<string>>;
  inputRegistrationDateSearch: string;
  setRegistrationDateSearch: Dispatch<SetStateAction<string>>;
  inputCompanyNameSearch: string;
  setInputCompanyNameSearch: Dispatch<SetStateAction<string>>;
  inputEmailSearchCustomer: string;
  setInputEmailSearchCustomer: Dispatch<SetStateAction<string>>;
  inputPhoneSearch: string;
  setInputPhoneSearch: Dispatch<SetStateAction<string>>;
  inputLanguajeSearch: string;
  setInputLanguajeSearch: Dispatch<SetStateAction<string>>;

  //Exchanges
  inputNumber: string;
  setInputNumber: Dispatch<SetStateAction<string>>;
  inputProduct: string;
  setInputProduct: Dispatch<SetStateAction<string>>;
  checkedDraft: boolean;
  setCheckedDraft: Dispatch<SetStateAction<boolean>>;
  checkedAbandonned: boolean;
  setCheckedAbandonned: Dispatch<SetStateAction<boolean>>;
  checkedSubmitted: boolean;
  setCheckedSubmitted: Dispatch<SetStateAction<boolean>>;
  checkedAnalysis: boolean;
  setCheckedAnalysis: Dispatch<SetStateAction<boolean>>;
  checkedAccepted: boolean;
  setCheckedAccepted: Dispatch<SetStateAction<boolean>>;
  checkedAvailable: boolean;
  setCheckedAvailable: Dispatch<SetStateAction<boolean>>;
  checkedExchanged: boolean;
  setCheckedExchanged: Dispatch<SetStateAction<boolean>>;
  checkedRefused: boolean;
  setCheckedRefused: Dispatch<SetStateAction<boolean>>;
  checkedRecycled: boolean;
  setCheckedRecycled: Dispatch<SetStateAction<boolean>>;
  inputRefusalReason: string;
  setInputRefusalReason: Dispatch<SetStateAction<string>>;
  inputRefusalReasonDos: string;
  setInputRefusalReasonDos: Dispatch<SetStateAction<string>>;
  inputDateFrom: string;
  setInputDateFrom: Dispatch<SetStateAction<string>>;
  inputDateTo: string;
  setInputDateTo: Dispatch<SetStateAction<string>>;
  inputProductType: string;
  setInputProductType: Dispatch<SetStateAction<string>>;
  inputProductReference: string;
  setInputProductReference: Dispatch<SetStateAction<string>>;
  inputProductName: string;
  setInputProductName: Dispatch<SetStateAction<string>>;
  inputBrandCode: string;
  setInputBrandCode: Dispatch<SetStateAction<string>>;
  inputBrandName: string;
  setInputBrandName: Dispatch<SetStateAction<string>>;
  inputCustomer: string;
  setInputCustomer: Dispatch<SetStateAction<string>>;
  inputCustomerUsername: string;
  setInputCustomerUsername: Dispatch<SetStateAction<string>>;
  inputCustomerFirstname: string;
  setInputCustomerFirstname: Dispatch<SetStateAction<string>>;
  inputCustomerLastname: string;
  setInputCustomerLastname: Dispatch<SetStateAction<string>>;
  inputDistributor: string;
  setInputDistributor: Dispatch<SetStateAction<string>>;
  inputDistributorCode: string;
  setInputDistributorCode: Dispatch<SetStateAction<string>>;
  inputDistributorName: string;
  setInputDistributorName: Dispatch<SetStateAction<string>>;
  inputDistributorGroupCode: string;
  setInputDistributorGroupCode: Dispatch<SetStateAction<string>>;
  inputDistributorGroupName: string;
  setInputDistributorGroupName: Dispatch<SetStateAction<string>>;
  inputDistributorClientAccountNumber: string;
  setInputDistributorClientAccountNumber: Dispatch<SetStateAction<string>>;
  inputGivenReason: string;
  setInputGivenReason: Dispatch<SetStateAction<string>>;
  inputGivenReasonDos: string;
  setInputGivenReasonDos: Dispatch<SetStateAction<string>>;
  inputEvaluation: string;
  setInputEvaluation: Dispatch<SetStateAction<string>>;
  inputQuantityMin: string;
  setInputQuantityMin: Dispatch<SetStateAction<string>>;
  inputQuantityMax: string;
  setInputQuantityMax: Dispatch<SetStateAction<string>>;
  inputComments: string;
  setInputComments: Dispatch<SetStateAction<string>>;
  inputTotalCost: string;
  setInputTotalCost: Dispatch<SetStateAction<string>>;
  inputGeographicalCoordinates: string;
  setInputGeographicalCoordinates: Dispatch<SetStateAction<string>>;
  inputCodeforDirectEvaluation: string;
  setInputCodeforDirectEvaluation: Dispatch<SetStateAction<string>>;
  inputDistributorDeliveryAccountNumber: string;
  setInputDistributorDeliveryAccountNumber: Dispatch<SetStateAction<string>>;
  inputDistributorDeliveryEmail: string;
  setInputDistributorDeliveryEmail: Dispatch<SetStateAction<string>>;

  //Contents
  placeholder: string;
  SetPlaceholder: Dispatch<SetStateAction<string>>;
  variantEnglish: string;
  SetVariantEnglish: Dispatch<SetStateAction<string>>;
  variantFrench: string;
  SetVariantFrench: Dispatch<SetStateAction<string>>;
  distributorGroup: string;
  setDistributorGroup: Dispatch<SetStateAction<string>>;

  //Reason for exchange
  inputCodeSearchRR: string;
  setInputCodeSearchRR: Dispatch<SetStateAction<string>>;
  inputLanguageSearch: string;
  setInputLanguageSearch: Dispatch<SetStateAction<string>>;
  inputDisplayOrderSearch: string;
  setInputDisplayOrderSearch: Dispatch<SetStateAction<string>>;
  inputFacRreLabelSearch: string;
  setInputFacRreLabelSearch: Dispatch<SetStateAction<string>>;
  inputEligibleSearch: string;
  setInputEligibleSearch: Dispatch<SetStateAction<string>>;

  //Refusal reasons
  inputCodeSearchRefusal: string;
  setInputCodeSearchRefusal: Dispatch<SetStateAction<string>>;
  inputLanguageSearchRefusal: string;
  setInputLanguageSearchRefusal: Dispatch<SetStateAction<string>>;
  inputDisplayOrderSearchRefusal: string;
  setInputDisplayOrderSearchRefusal: Dispatch<SetStateAction<string>>;
  inputFacRreLabelSearchRefusal: string;
  setInputFacRreLabelSearchRefusal: Dispatch<SetStateAction<string>>;

  //Change log
  inputIndexSearch: string;
  setInputIndexSearch: Dispatch<SetStateAction<string>>;
  inputSessionIndexSearch: string;
  setInputSessionIndexSearch: Dispatch<SetStateAction<string>>;
  inputSessionLoginSearch: string;
  setInputSessionLoginSearch: Dispatch<SetStateAction<string>>;
  inputSessionDateSearch: string;
  setSessionDateSearch: Dispatch<SetStateAction<string>>;
  inputUserSearch: string;
  setInputUserSearch: Dispatch<SetStateAction<string>>;
  inputDateSearch: string;
  setInputDateSearch: Dispatch<SetStateAction<string>>;
  inputPrimaryLoginSearch: string;
  setInputPrimaryLoginSearch: Dispatch<SetStateAction<string>>;
  inputObjectSearch: string;
  setInputObjectSearch: Dispatch<SetStateAction<string>>;
  inputActionSearch: string;
  setInputActionSearch: Dispatch<SetStateAction<string>>;

  //users
  inputLogin: string;
  setInputLogin: Dispatch<SetStateAction<string>>;
  inputActiveLenguaje: string;
  setInputActiveLenguaje: Dispatch<SetStateAction<string>>;
  checkedDisabled: boolean;
  setCheckedDisabled: Dispatch<SetStateAction<boolean>>;
  checkedEnabled: boolean;
  setCheckedEnabled: Dispatch<SetStateAction<boolean>>;
  checkedPending: boolean;
  setCheckedPending: Dispatch<SetStateAction<boolean>>;
  checkedWebServicesOnly: boolean;
  setCheckedWebServicesOnly: Dispatch<SetStateAction<boolean>>;
  checkedAdmin: boolean;
  setCheckedAdmin: Dispatch<SetStateAction<boolean>>;
  checkedDistributor: boolean;
  setCheckedDistributor: Dispatch<SetStateAction<boolean>>;
  checkedGroup: boolean;
  setCheckedGroup: Dispatch<SetStateAction<boolean>>;
  checkedViewOnly: boolean;
  setCheckedViewOnly: Dispatch<SetStateAction<boolean>>;
  checkedAftersales: boolean;
  setCheckedAftersales: Dispatch<SetStateAction<boolean>>;
  checkedCustomer: boolean;
  setCheckedCustomer: Dispatch<SetStateAction<boolean>>;
  inputHomePage: string;
  setInputHomePage: Dispatch<SetStateAction<string>>;
  inputModuleName: string;
  setInputModuleName: Dispatch<SetStateAction<string>>;
  inputDateFormat: string;
  setInputDateFormat: Dispatch<SetStateAction<string>>;
  inputPreferrendLenguaje: string;
  setInputPreferrendLenguaje: Dispatch<SetStateAction<string>>;
  inputTitle: string;
  setInputTitle: Dispatch<SetStateAction<string>>;
  inputName: string;
  setInputName: Dispatch<SetStateAction<string>>;
  inputFirstName: string;
  setInputFirstName: Dispatch<SetStateAction<string>>;
  inputEmail: string;
  setInputEmail: Dispatch<SetStateAction<string>>;
  inputWorkPhone: string;
  setInputWorkPhone: Dispatch<SetStateAction<string>>;
  inputHomePhone: string;
  setInputHomePhone: Dispatch<SetStateAction<string>>;
  inputMobilePhone: string;
  setInputMobilePhone: Dispatch<SetStateAction<string>>;
  inputZipCode: string;
  setInputZipCode: Dispatch<SetStateAction<string>>;
  inputCity: string;
  setInputCity: Dispatch<SetStateAction<string>>;
  inputState: string;
  setInputState: Dispatch<SetStateAction<string>>;
  inputCountry: string;
  setInputCountry: Dispatch<SetStateAction<string>>;
  inputDefaultOpenedDomain: string;
  setInputDefaultOpenedDomain: Dispatch<SetStateAction<string>>;
  inputDistributorUser: string;
  setInputDistributorUser: Dispatch<SetStateAction<string>>;
  inputDistributorCodeUser: string;
  setInputDistributorCodeUser: Dispatch<SetStateAction<string>>;
  inputDistributorNameUser: string;
  setInputDistributorNameUser: Dispatch<SetStateAction<string>>;
  inputCustomerUser: string;
  setInputCustomerUser: Dispatch<SetStateAction<string>>;
  inputCustomerUserName: string;
  setInputCustomerUserName: Dispatch<SetStateAction<string>>;
  inputCustomerFirstName: string;
  setInputCustomerFirstName: Dispatch<SetStateAction<string>>;
  inputCustomerLastName: string;
  setInputCustomerLastName: Dispatch<SetStateAction<string>>;
  inputGroup: string;
  setInputGroup: Dispatch<SetStateAction<string>>;
  inputGroupCode: string;
  setInputGroupCode: Dispatch<SetStateAction<string>>;
  inputGroupName: string;
  setInputGroupName: Dispatch<SetStateAction<string>>;
};

const defaultValues = {
  inputCodeSearch: "",
  setInputCodeSearch: () => {},
  inputNameSearch: "",
  setInputNameSearch: () => {},
  showLateralContainerSearch: false,
  setShowLateralContainerSearch: () => {},
  inputBrandCodeSearch: "",
  setInputBrandCodeSearch: () => {},
  inputBrandNameSearch: "",
  setInputBrandNameSearch: () => {},
  inputBrandSearch: "",
  setInputBrandSearch: () => {},
  inputReferenceSearch: "",
  setInputReferenceSearch: () => {},
  inputOldReferenceSearch: "",
  setInputOldReferenceSearch: () => {},
  inputNameSearchProduct: "",
  setInputNameSearchProduct: () => {},
  inputTypeSearch: "",
  setInputTypeSearch: () => {},
  inputDefaultSearch: "",
  setInputDefaultSearch: () => {},
  inputUnitPriceSearch: "",
  setInputUnitPriceSearch: () => {},
  inputAvailableSearch: "",
  setInputAvailableSearch: () => {},
  inputWarrantySearch: "",
  setInputWarrantySearch: () => {},
  inputBrochureSearch: "",
  setInputBrochureSearch: () => {},
  inputEanSearch: "",
  setInputEanSearch: () => {},

  //Groups
  inputCodeSearchGroup: "",
  setInputCodeSearchGroup: () => {},
  inputNameSearchGroup: "",
  setInputNameSearchGroup: () => {},

  //Distributors
  inputGroupSearch: "",
  setInputGroupSearch: () => {},
  inputGroupCodeSearch: "",
  setInputGroupCodeSearch: () => {},
  inputGroupNameSearch: "",
  setInputGroupNameSearch: () => {},
  inputCodeSearchDistributor: "",
  setInputCodeSearchDistributor: () => {},
  inputClientAccountNumberSearch: "",
  setInputClientAccountNumberSearch: () => {},
  inputNameSearchDistributor: "",
  setInputNameSearchDistributor: () => {},
  inputVisibleSearch: "",
  setInputVisibleSearch: () => {},
  inputWebSearch: "",
  setInputWebSearch: () => {},
  inputAddressSearch: "",
  setInputAddressSearch: () => {},
  inputEmailSearch: "",
  setInputEmailSearch: () => {},
  inputGeographicalCoordinatesSearch: "",
  setInputGeographicalCoordinatesSearch: () => {},
  inputMapURLSearch: "",
  setInputMapURLSearch: () => {},
  inputEvaluationSearch: "",
  setInputEvaluationSearch: () => {},
  inputLookupSearch: "",
  setInputLookupSearch: () => {},
  inputMembersOfSetSearch: "",
  setInputMembersOfSetSearch: () => {},
  inputResponsibleEmailSearch: "",
  setInputResponsibleEmailSearch: () => {},
  inputDeliveryAccountNumberSearch: "",
  setInputDeliveryAccountNumberSearch: () => {},

  //Distributor sets
  inputGroupCodeSearchDistributorSet: "",
  setInputGroupCodeSearchDistributorSet: () => {},
  inputGroupNameSearchDistributorSet: "",
  setInputGroupNameSearchDistributorSet: () => {},
  inputNameSearchDistributorSet: "",
  setInputNameSearchDistributorSet: () => {},
  inputOriginSearch: "",
  setInputOriginSearch: () => {},
  inputMembersSearch: "",
  setInputMembersSearch: () => {},

  //Customer

  inputUsernameSearch: "",
  setInputUsernameSearch: () => {},
  inputFirstnameSearch: "",
  setInputFirstnameSearch: () => {},
  inputLastnameSearch: "",
  setInputLastnameSearch: () => {},
  inputTypeSearchCustomer: "",
  setInputTypeSearchCustomer: () => {},
  inputBusinessSearch: "",
  setInputBusinessSearch: () => {},
  inputZipCodeSearch: "",
  setInputZipCodeSearch: () => {},
  inputCitySearch: "",
  setInputCitySearch: () => {},
  inputCountrySearch: "",
  setInputCountrySearch: () => {},
  inputMapUrlSearch: "",
  setInputMapUrlSearch: () => {},
  inputGeneralTermsAcceptedSearch: "",
  setGeneralTermsAcceptedSearch: () => {},
  inputPrivacyPolicyAcceptedSearch: "",
  setPrivacyPolicyAcceptedSearch: () => {},
  inputRegistrationDateSearch: "",
  setRegistrationDateSearch: () => {},
  inputCompanyNameSearch: "",
  setInputCompanyNameSearch: () => {},
  inputEmailSearchCustomer: "",
  setInputEmailSearchCustomer: () => {},
  inputPhoneSearch: "",
  setInputPhoneSearch: () => {},
  inputLanguajeSearch: "",
  setInputLanguajeSearch: () => {},

  //Exchanges
  inputNumber: "",
  setInputNumber: () => {},
  inputProduct: "",
  setInputProduct: () => {},
  checkedDraft: false,
  setCheckedDraft: () => {},
  checkedAbandonned: false,
  setCheckedAbandonned: () => {},
  checkedSubmitted: false,
  setCheckedSubmitted: () => {},
  checkedAnalysis: false,
  setCheckedAnalysis: () => {},
  checkedAccepted: false,
  setCheckedAccepted: () => {},
  checkedAvailable: false,
  setCheckedAvailable: () => {},
  checkedExchanged: false,
  setCheckedExchanged: () => {},
  checkedRefused: false,
  setCheckedRefused: () => {},
  checkedRecycled: false,
  setCheckedRecycled: () => {},
  inputRefusalReason: "",
  setInputRefusalReason: () => {},
  inputRefusalReasonDos: "",
  setInputRefusalReasonDos: () => {},
  inputDateFrom: "",
  setInputDateFrom: () => {},
  inputDateTo: "",
  setInputDateTo: () => {},
  inputProductType: "",
  setInputProductType: () => {},
  inputProductReference: "",
  setInputProductReference: () => {},
  inputProductName: "",
  setInputProductName: () => {},
  inputBrandCode: "",
  setInputBrandCode: () => {},
  inputBrandName: "",
  setInputBrandName: () => {},
  inputCustomer: "",
  setInputCustomer: () => {},
  inputCustomerUsername: "",
  setInputCustomerUsername: () => {},
  inputCustomerFirstname: "",
  setInputCustomerFirstname: () => {},
  inputCustomerLastname: "",
  setInputCustomerLastname: () => {},
  inputDistributor: "",
  setInputDistributor: () => {},
  inputDistributorCode: "",
  setInputDistributorCode: () => {},
  inputDistributorName: "",
  setInputDistributorName: () => {},
  inputDistributorGroupCode: "",
  setInputDistributorGroupCode: () => {},
  inputDistributorGroupName: "",
  setInputDistributorGroupName: () => {},
  inputDistributorClientAccountNumber: "",
  setInputDistributorClientAccountNumber: () => {},
  inputGivenReason: "",
  setInputGivenReason: () => {},
  inputGivenReasonDos: "",
  setInputGivenReasonDos: () => {},
  inputEvaluation: "",
  setInputEvaluation: () => {},
  inputQuantityMin: "",
  setInputQuantityMin: () => {},
  inputQuantityMax: "",
  setInputQuantityMax: () => {},
  inputComments: "",
  setInputComments: () => {},
  inputTotalCost: "",
  setInputTotalCost: () => {},
  inputGeographicalCoordinates: "",
  setInputGeographicalCoordinates: () => {},
  inputCodeforDirectEvaluation: "",
  setInputCodeforDirectEvaluation: () => {},
  inputDistributorDeliveryAccountNumber: "",
  setInputDistributorDeliveryAccountNumber: () => {},
  inputDistributorDeliveryEmail: "",
  setInputDistributorDeliveryEmail: () => {},

  //Contents
  placeholder: "",
  SetPlaceholder: () => {},
  variantEnglish: "",
  SetVariantEnglish: () => {},
  variantFrench: "",
  SetVariantFrench: () => {},
  distributorGroup: "",
  setDistributorGroup: () => {},

  //Reason for exchange
  inputCodeSearchRR: "",
  setInputCodeSearchRR: () => {},
  inputLanguageSearch: "",
  setInputLanguageSearch: () => {},
  inputDisplayOrderSearch: "",
  setInputDisplayOrderSearch: () => {},
  inputFacRreLabelSearch: "",
  setInputFacRreLabelSearch: () => {},
  inputEligibleSearch: "",
  setInputEligibleSearch: () => {},

  //Refusal reason
  inputCodeSearchRefusal: "",
  setInputCodeSearchRefusal: () => {},
  inputLanguageSearchRefusal: "",
  setInputLanguageSearchRefusal: () => {},
  inputDisplayOrderSearchRefusal: "",
  setInputDisplayOrderSearchRefusal: () => {},
  inputFacRreLabelSearchRefusal: "",
  setInputFacRreLabelSearchRefusal: () => {},

  //Change log
  inputIndexSearch: "",
  setInputIndexSearch: () => {},
  inputSessionIndexSearch: "",
  setInputSessionIndexSearch: () => {},
  inputSessionLoginSearch: "",
  setInputSessionLoginSearch: () => {},
  inputSessionDateSearch: "",
  setSessionDateSearch: () => {},
  inputUserSearch: "",
  setInputUserSearch: () => {},
  inputDateSearch: "",
  setInputDateSearch: () => {},
  inputPrimaryLoginSearch: "",
  setInputPrimaryLoginSearch: () => {},
  inputObjectSearch: "",
  setInputObjectSearch: () => {},
  inputActionSearch: "",
  setInputActionSearch: () => {},

  //users
  inputLogin: "",
  setInputLogin: () => {},
  inputActiveLenguaje: "",
  setInputActiveLenguaje: () => {},
  checkedDisabled: false,
  setCheckedDisabled: () => {},
  checkedEnabled: false,
  setCheckedEnabled: () => {},
  checkedPending: false,
  setCheckedPending: () => {},
  checkedWebServicesOnly: false,
  setCheckedWebServicesOnly: () => {},
  checkedAdmin: false,
  setCheckedAdmin: () => {},
  checkedDistributor: false,
  setCheckedDistributor: () => {},
  checkedGroup: false,
  setCheckedGroup: () => {},
  checkedViewOnly: false,
  setCheckedViewOnly: () => {},
  checkedAftersales: false,
  setCheckedAftersales: () => {},
  checkedCustomer: false,
  setCheckedCustomer: () => {},
  inputHomePage: "",
  setInputHomePage: () => {},
  inputModuleName: "",
  setInputModuleName: () => {},
  inputDateFormat: "",
  setInputDateFormat: () => {},
  inputPreferrendLenguaje: "",
  setInputPreferrendLenguaje: () => {},
  inputTitle: "",
  setInputTitle: () => {},
  inputName: "",
  setInputName: () => {},
  inputFirstName: "",
  setInputFirstName: () => {},
  inputEmail: "",
  setInputEmail: () => {},
  inputWorkPhone: "",
  setInputWorkPhone: () => {},
  inputHomePhone: "",
  setInputHomePhone: () => {},
  inputMobilePhone: "",
  setInputMobilePhone: () => {},
  inputZipCode: "",
  setInputZipCode: () => {},
  inputCity: "",
  setInputCity: () => {},
  inputState: "",
  setInputState: () => {},
  inputCountry: "",
  setInputCountry: () => {},
  inputDefaultOpenedDomain: "",
  setInputDefaultOpenedDomain: () => {},
  inputDistributorUser: "",
  setInputDistributorUser: () => {},
  inputDistributorCodeUser: "",
  setInputDistributorCodeUser: () => {},
  inputDistributorNameUser: "",
  setInputDistributorNameUser: () => {},
  inputCustomerUser: "",
  setInputCustomerUser: () => {},
  inputCustomerUserName: "",
  setInputCustomerUserName: () => {},
  inputCustomerFirstName: "",
  setInputCustomerFirstName: () => {},
  inputCustomerLastName: "",
  setInputCustomerLastName: () => {},
  inputGroup: "",
  setInputGroup: () => {},
  inputGroupCode: "",
  setInputGroupCode: () => {},
  inputGroupName: "",
  setInputGroupName: () => {},
};

const FilterContext = createContext<contextValues>(defaultValues);

const FilterContextProvider = (props: any) => {
  const { children } = props;

  //Global
  const [showLateralContainerSearch, setShowLateralContainerSearch] = useState(false);
  //Brand Filters
  const [inputCodeSearch, setInputCodeSearch] = useState<string>("");
  const [inputNameSearch, setInputNameSearch] = useState<string>("");

  //Products Filters
  const [inputBrandCodeSearch, setInputBrandCodeSearch] = useState<string>("");
  const [inputBrandNameSearch, setInputBrandNameSearch] = useState<string>("");
  const [inputBrandSearch, setInputBrandSearch] = useState<string>("");
  const [inputReferenceSearch, setInputReferenceSearch] = useState<string>("");
  const [inputOldReferenceSearch, setInputOldReferenceSearch] = useState<string>("");
  const [inputNameSearchProduct, setInputNameSearchProduct] = useState<string>("");
  const [inputTypeSearch, setInputTypeSearch] = useState<string>("");
  const [inputDefaultSearch, setInputDefaultSearch] = useState<string>("");
  const [inputUnitPriceSearch, setInputUnitPriceSearch] = useState<string>("");
  const [inputAvailableSearch, setInputAvailableSearch] = useState<string>("");
  const [inputWarrantySearch, setInputWarrantySearch] = useState<string>("");
  const [inputBrochureSearch, setInputBrochureSearch] = useState<string>("");
  const [inputEanSearch, setInputEanSearch] = useState<string>("");

  //Group Filters
  const [inputCodeSearchGroup, setInputCodeSearchGroup] = useState<string>("");
  const [inputNameSearchGroup, setInputNameSearchGroup] = useState<string>("");

  //Distributor Filters
  const [inputGroupSearch, setInputGroupSearch] = useState<string>("");
  const [inputGroupCodeSearch, setInputGroupCodeSearch] = useState<string>("");
  const [inputGroupNameSearch, setInputGroupNameSearch] = useState<string>("");
  const [inputCodeSearchDistributor, setInputCodeSearchDistributor] = useState<string>("");
  const [inputClientAccountNumberSearch, setInputClientAccountNumberSearch] = useState<string>("");
  const [inputNameSearchDistributor, setInputNameSearchDistributor] = useState<string>("");
  const [inputVisibleSearch, setInputVisibleSearch] = useState<string>("");
  const [inputWebSearch, setInputWebSearch] = useState<string>("");
  const [inputAddressSearch, setInputAddressSearch] = useState<string>("");
  const [inputEmailSearch, setInputEmailSearch] = useState<string>("");
  const [inputGeographicalCoordinatesSearch, setInputGeographicalCoordinatesSearch] = useState<string>("");
  const [inputMapURLSearch, setInputMapURLSearch] = useState<string>("");
  const [inputEvaluationSearch, setInputEvaluationSearch] = useState<string>("");
  const [inputLookupSearch, setInputLookupSearch] = useState<string>("");
  const [inputMembersOfSetSearch, setInputMembersOfSetSearch] = useState<string>("");
  const [inputResponsibleEmailSearch, setInputResponsibleEmailSearch] = useState<string>("");
  const [inputDeliveryAccountNumberSearch, setInputDeliveryAccountNumberSearch] = useState<string>("");

  //Distributor set
  const [inputGroupCodeSearchDistributorSet, setInputGroupCodeSearchDistributorSet] = useState<string>("");
  const [inputGroupNameSearchDistributorSet, setInputGroupNameSearchDistributorSet] = useState<string>("");
  const [inputNameSearchDistributorSet, setInputNameSearchDistributorSet] = useState<string>("");
  const [inputOriginSearch, setInputOriginSearch] = useState<string>("");
  const [inputMembersSearch, setInputMembersSearch] = useState<string>("");

  //Customer Filters

  const listTypeOptions = ["", `${t("TABLE-CUSTOMER-STATS-TITLE-ARTISAN")}`, `${t("TABLE-CUSTOMER-STATS-TITLE-INDIVIDUAL")}`];
  const listBusinessOptions = [
    "",
    `${t("OPTIONS-PROFESSION-1")}`,
    `${t("OPTIONS-PROFESSION-2")}`,
    `${t("OPTIONS-PROFESSION-3")}`,
    `${t("OPTIONS-PROFESSION-4")}`,
    `${t("OPTIONS-PROFESSION-5")}`,
    `${t("OPTIONS-PROFESSION-6")}`,
    `${t("OPTIONS-PROFESSION-7")}`,
    `${t("OPTIONS-PROFESSION-8")}`,
    `${t("OPTIONS-PROFESSION-9")}`,
    `${t("OPTIONS-PROFESSION-10")}`,
    `${t("OPTIONS-PROFESSION-11")}`,
    `${t("OPTIONS-PROFESSION-12")}`,
    `${t("OPTIONS-PROFESSION-13")}`,
    `${t("OPTIONS-PROFESSION-14")}`,
    `${t("OPTIONS-PROFESSION-15")}`,
    `${t("OPTIONS-PROFESSION-16")}`,
    `${t("OPTIONS-PROFESSION-17")}`,
    `${t("OPTIONS-PROFESSION-18")}`,
    `${t("OPTIONS-PROFESSION-19")}`,
    `${t("OPTIONS-PROFESSION-20")}`,
    `${t("OPTIONS-PROFESSION-21")}`,
    `${t("OPTIONS-PROFESSION-22")}`,
    `${t("OPTIONS-PROFESSION-23")}`,
  ];

  const [inputUsernameSearch, setInputUsernameSearch] = useState<string>("");
  const [inputFirstnameSearch, setInputFirstnameSearch] = useState<string>("");
  const [inputLastnameSearch, setInputLastnameSearch] = useState<string>("");
  const [inputTypeSearchCustomer, setInputTypeSearchCustomer] = useState<string>(listTypeOptions[0]);
  const [inputBusinessSearch, setInputBusinessSearch] = useState<string>(listBusinessOptions[0]);
  const [inputZipCodeSearch, setInputZipCodeSearch] = useState<string>("");
  const [inputCitySearch, setInputCitySearch] = useState<string>("");
  const [inputCountrySearch, setInputCountrySearch] = useState<string>("");
  const [inputMapUrlSearch, setInputMapUrlSearch] = useState<string>("");
  const [inputGeneralTermsAcceptedSearch, setGeneralTermsAcceptedSearch] = useState<string>("");
  const [inputPrivacyPolicyAcceptedSearch, setPrivacyPolicyAcceptedSearch] = useState<string>("");
  const [inputRegistrationDateSearch, setRegistrationDateSearch] = useState<string>("");
  const [inputCompanyNameSearch, setInputCompanyNameSearch] = useState<string>("");
  const [inputEmailSearchCustomer, setInputEmailSearchCustomer] = useState<string>("");
  const [inputPhoneSearch, setInputPhoneSearch] = useState<string>("");
  const [inputLanguajeSearch, setInputLanguajeSearch] = useState<string>("");

  //Exchanges
  const [inputNumber, setInputNumber] = useState<string>("");
  const [inputProduct, setInputProduct] = useState<string>("");
  const [checkedDraft, setCheckedDraft] = useState<boolean>(false);
  const [checkedAbandonned, setCheckedAbandonned] = useState<boolean>(false);
  const [checkedSubmitted, setCheckedSubmitted] = useState<boolean>(false);
  const [checkedAnalysis, setCheckedAnalysis] = useState<boolean>(false);
  const [checkedAccepted, setCheckedAccepted] = useState<boolean>(false);
  const [checkedAvailable, setCheckedAvailable] = useState<boolean>(false);
  const [checkedExchanged, setCheckedExchanged] = useState<boolean>(false);
  const [checkedRefused, setCheckedRefused] = useState<boolean>(false);
  const [checkedRecycled, setCheckedRecycled] = useState<boolean>(false);
  const [inputRefusalReason, setInputRefusalReason] = useState<string>("");
  const [inputRefusalReasonDos, setInputRefusalReasonDos] = useState<string>("");
  const [inputDateFrom, setInputDateFrom] = useState<string>("");
  const [inputDateTo, setInputDateTo] = useState<string>("");
  const [inputProductType, setInputProductType] = useState<string>("");
  const [inputProductReference, setInputProductReference] = useState<string>("");
  const [inputProductName, setInputProductName] = useState<string>("");
  const [inputBrandCode, setInputBrandCode] = useState<string>("");
  const [inputBrandName, setInputBrandName] = useState<string>("");
  const [inputCustomer, setInputCustomer] = useState<string>("");
  const [inputCustomerUsername, setInputCustomerUsername] = useState<string>("");
  const [inputCustomerFirstname, setInputCustomerFirstname] = useState<string>("");
  const [inputCustomerLastname, setInputCustomerLastname] = useState<string>("");
  const [inputDistributor, setInputDistributor] = useState<string>("");
  const [inputDistributorCode, setInputDistributorCode] = useState<string>("");
  const [inputDistributorName, setInputDistributorName] = useState<string>("");
  const [inputDistributorGroupCode, setInputDistributorGroupCode] = useState<string>("");
  const [inputDistributorGroupName, setInputDistributorGroupName] = useState<string>("");
  const [inputDistributorClientAccountNumber, setInputDistributorClientAccountNumber] = useState<string>("");
  const [inputGivenReason, setInputGivenReason] = useState<string>("");
  const [inputGivenReasonDos, setInputGivenReasonDos] = useState<string>("");
  const [inputEvaluation, setInputEvaluation] = useState<string>("");
  const [inputQuantityMin, setInputQuantityMin] = useState<string>("");
  const [inputQuantityMax, setInputQuantityMax] = useState<string>("");
  const [inputComments, setInputComments] = useState<string>("");
  const [inputTotalCost, setInputTotalCost] = useState<string>("");
  const [inputGeographicalCoordinates, setInputGeographicalCoordinates] = useState<string>("");
  const [inputCodeforDirectEvaluation, setInputCodeforDirectEvaluation] = useState<string>("");
  const [inputDistributorDeliveryAccountNumber, setInputDistributorDeliveryAccountNumber] = useState<string>("");
  const [inputDistributorDeliveryEmail, setInputDistributorDeliveryEmail] = useState<string>("");

  //Contents
  const [placeholder, SetPlaceholder] = useState("");
  const [variantEnglish, SetVariantEnglish] = useState("");
  const [variantFrench, SetVariantFrench] = useState("");
  const [distributorGroup, setDistributorGroup] = useState("");

  //Reason for exchange
  const [inputCodeSearchRR, setInputCodeSearchRR] = useState<string>("");
  const [inputLanguageSearch, setInputLanguageSearch] = useState<string>("");
  const [inputDisplayOrderSearch, setInputDisplayOrderSearch] = useState<string>("");
  const [inputFacRreLabelSearch, setInputFacRreLabelSearch] = useState<string>("");
  const [inputEligibleSearch, setInputEligibleSearch] = useState<string>("");

  //Refusal reasons
  const [inputCodeSearchRefusal, setInputCodeSearchRefusal] = useState<string>("");
  const [inputLanguageSearchRefusal, setInputLanguageSearchRefusal] = useState<string>("");
  const [inputDisplayOrderSearchRefusal, setInputDisplayOrderSearchRefusal] = useState<string>("");
  const [inputFacRreLabelSearchRefusal, setInputFacRreLabelSearchRefusal] = useState<string>("");

  //Change logs
  const [inputIndexSearch, setInputIndexSearch] = useState<string>("");
  const [inputSessionIndexSearch, setInputSessionIndexSearch] = useState<string>("");
  const [inputSessionLoginSearch, setInputSessionLoginSearch] = useState<string>("");
  const [inputSessionDateSearch, setSessionDateSearch] = useState<string>("");
  const [inputUserSearch, setInputUserSearch] = useState<string>("");
  const [inputDateSearch, setInputDateSearch] = useState<string>("");
  const [inputPrimaryLoginSearch, setInputPrimaryLoginSearch] = useState<string>("");
  const [inputObjectSearch, setInputObjectSearch] = useState<string>("");
  const [inputActionSearch, setInputActionSearch] = useState<string>("");

  //Users
  const [inputLogin, setInputLogin] = useState<string>("");
  const [inputActiveLenguaje, setInputActiveLenguaje] = useState<string>("");
  const [checkedDisabled, setCheckedDisabled] = useState<boolean>(false);
  const [checkedEnabled, setCheckedEnabled] = useState<boolean>(false);
  const [checkedPending, setCheckedPending] = useState<boolean>(false);
  const [checkedWebServicesOnly, setCheckedWebServicesOnly] = useState<boolean>(false);
  const [checkedAdmin, setCheckedAdmin] = useState<boolean>(false);
  const [checkedViewOnly, setCheckedViewOnly] = useState<boolean>(false);
  const [checkedDistributor, setCheckedDistributor] = useState<boolean>(false);
  const [checkedGroup, setCheckedGroup] = useState<boolean>(false);
  const [checkedAftersales, setCheckedAftersales] = useState<boolean>(false);
  const [checkedCustomer, setCheckedCustomer] = useState<boolean>(false);
  const [inputHomePage, setInputHomePage] = useState<string>("");
  const [inputModuleName, setInputModuleName] = useState<string>("");
  const [inputDateFormat, setInputDateFormat] = useState<string>("");
  const [inputPreferrendLenguaje, setInputPreferrendLenguaje] = useState<string>("");
  const [inputTitle, setInputTitle] = useState<string>("");
  const [inputName, setInputName] = useState<string>("");
  const [inputFirstName, setInputFirstName] = useState<string>("");
  const [inputEmail, setInputEmail] = useState<string>("");
  const [inputWorkPhone, setInputWorkPhone] = useState<string>("");
  const [inputHomePhone, setInputHomePhone] = useState<string>("");
  const [inputMobilePhone, setInputMobilePhone] = useState<string>("");
  const [inputZipCode, setInputZipCode] = useState<string>("");
  const [inputCity, setInputCity] = useState<string>("");
  const [inputState, setInputState] = useState<string>("");
  const [inputCountry, setInputCountry] = useState<string>("");
  const [inputDefaultOpenedDomain, setInputDefaultOpenedDomain] = useState<string>("");
  const [inputDistributorUser, setInputDistributorUser] = useState<string>("");
  const [inputDistributorCodeUser, setInputDistributorCodeUser] = useState<string>("");
  const [inputDistributorNameUser, setInputDistributorNameUser] = useState<string>("");
  const [inputCustomerUser, setInputCustomerUser] = useState<string>("");
  const [inputCustomerUserName, setInputCustomerUserName] = useState<string>("");
  const [inputCustomerFirstName, setInputCustomerFirstName] = useState<string>("");
  const [inputCustomerLastName, setInputCustomerLastName] = useState<string>("");
  const [inputGroup, setInputGroup] = useState<string>("");
  const [inputGroupCode, setInputGroupCode] = useState<string>("");
  const [inputGroupName, setInputGroupName] = useState<string>("");

  const contextValues = {
    inputCodeSearch,
    setInputCodeSearch,
    inputNameSearch,
    setInputNameSearch,
    showLateralContainerSearch,
    setShowLateralContainerSearch,
    //Products
    inputBrandCodeSearch,
    setInputBrandCodeSearch,
    inputBrandNameSearch,
    setInputBrandNameSearch,
    inputBrandSearch,
    setInputBrandSearch,
    inputReferenceSearch,
    setInputReferenceSearch,
    inputOldReferenceSearch,
    setInputOldReferenceSearch,
    inputNameSearchProduct,
    setInputNameSearchProduct,
    inputTypeSearch,
    setInputTypeSearch,
    inputDefaultSearch,
    setInputDefaultSearch,
    inputUnitPriceSearch,
    setInputUnitPriceSearch,
    inputAvailableSearch,
    setInputAvailableSearch,
    inputWarrantySearch,
    setInputWarrantySearch,
    inputBrochureSearch,
    setInputBrochureSearch,
    inputEanSearch,
    setInputEanSearch,

    //Groups
    inputCodeSearchGroup,
    setInputCodeSearchGroup,
    inputNameSearchGroup,
    setInputNameSearchGroup,

    //Distributor
    inputGroupSearch,
    setInputGroupSearch,
    inputGroupCodeSearch,
    setInputGroupCodeSearch,
    inputGroupNameSearch,
    setInputGroupNameSearch,
    inputCodeSearchDistributor,
    setInputCodeSearchDistributor,
    inputClientAccountNumberSearch,
    setInputClientAccountNumberSearch,
    inputNameSearchDistributor,
    setInputNameSearchDistributor,
    inputVisibleSearch,
    setInputVisibleSearch,
    inputWebSearch,
    setInputWebSearch,
    inputAddressSearch,
    setInputAddressSearch,
    inputEmailSearch,
    setInputEmailSearch,
    inputGeographicalCoordinatesSearch,
    setInputGeographicalCoordinatesSearch,
    inputMapURLSearch,
    setInputMapURLSearch,
    inputEvaluationSearch,
    setInputEvaluationSearch,
    inputLookupSearch,
    setInputLookupSearch,
    inputMembersOfSetSearch,
    setInputMembersOfSetSearch,
    inputResponsibleEmailSearch,
    setInputResponsibleEmailSearch,
    inputDeliveryAccountNumberSearch,
    setInputDeliveryAccountNumberSearch,

    //Distributor sets
    inputGroupCodeSearchDistributorSet,
    setInputGroupCodeSearchDistributorSet,
    inputGroupNameSearchDistributorSet,
    setInputGroupNameSearchDistributorSet,
    inputNameSearchDistributorSet,
    setInputNameSearchDistributorSet,
    inputOriginSearch,
    setInputOriginSearch,
    inputMembersSearch,
    setInputMembersSearch,

    //Customers
    inputUsernameSearch,
    setInputUsernameSearch,
    inputFirstnameSearch,
    setInputFirstnameSearch,
    inputLastnameSearch,
    setInputLastnameSearch,
    inputTypeSearchCustomer,
    setInputTypeSearchCustomer,
    inputBusinessSearch,
    setInputBusinessSearch,
    inputZipCodeSearch,
    setInputZipCodeSearch,
    inputCitySearch,
    setInputCitySearch,
    inputCountrySearch,
    setInputCountrySearch,
    inputMapUrlSearch,
    setInputMapUrlSearch,
    inputGeneralTermsAcceptedSearch,
    setGeneralTermsAcceptedSearch,
    inputPrivacyPolicyAcceptedSearch,
    setPrivacyPolicyAcceptedSearch,
    inputRegistrationDateSearch,
    setRegistrationDateSearch,
    inputCompanyNameSearch,
    setInputCompanyNameSearch,
    inputEmailSearchCustomer,
    setInputEmailSearchCustomer,
    inputPhoneSearch,
    setInputPhoneSearch,
    inputLanguajeSearch,
    setInputLanguajeSearch,

    //Exchanges
    inputNumber,
    setInputNumber,
    inputProduct,
    setInputProduct,
    checkedDraft,
    setCheckedDraft,
    checkedAbandonned,
    setCheckedAbandonned,
    checkedSubmitted,
    setCheckedSubmitted,
    checkedAnalysis,
    setCheckedAnalysis,
    checkedAccepted,
    setCheckedAccepted,
    checkedAvailable,
    setCheckedAvailable,
    checkedExchanged,
    setCheckedExchanged,
    checkedRefused,
    setCheckedRefused,
    checkedRecycled,
    setCheckedRecycled,
    inputRefusalReason,
    setInputRefusalReason,
    inputRefusalReasonDos,
    setInputRefusalReasonDos,
    inputDateFrom,
    setInputDateFrom,
    inputDateTo,
    setInputDateTo,
    inputProductType,
    setInputProductType,
    inputProductReference,
    setInputProductReference,
    inputProductName,
    setInputProductName,
    inputBrandCode,
    setInputBrandCode,
    inputBrandName,
    setInputBrandName,
    inputCustomer,
    setInputCustomer,
    inputCustomerUsername,
    setInputCustomerUsername,
    inputCustomerFirstname,
    setInputCustomerFirstname,
    inputCustomerLastname,
    setInputCustomerLastname,
    inputDistributor,
    setInputDistributor,
    inputDistributorCode,
    setInputDistributorCode,
    inputDistributorName,
    setInputDistributorName,
    inputDistributorGroupCode,
    setInputDistributorGroupCode,
    inputDistributorGroupName,
    setInputDistributorGroupName,
    inputDistributorClientAccountNumber,
    setInputDistributorClientAccountNumber,
    inputGivenReason,
    setInputGivenReason,
    inputGivenReasonDos,
    setInputGivenReasonDos,
    inputEvaluation,
    setInputEvaluation,
    inputQuantityMin,
    setInputQuantityMin,
    inputQuantityMax,
    setInputQuantityMax,
    inputComments,
    setInputComments,
    inputTotalCost,
    setInputTotalCost,
    inputGeographicalCoordinates,
    setInputGeographicalCoordinates,
    inputCodeforDirectEvaluation,
    setInputCodeforDirectEvaluation,
    inputDistributorDeliveryAccountNumber,
    setInputDistributorDeliveryAccountNumber,
    inputDistributorDeliveryEmail,
    setInputDistributorDeliveryEmail,

    //Contents
    placeholder,
    SetPlaceholder,
    variantEnglish,
    SetVariantEnglish,
    variantFrench,
    SetVariantFrench,
    distributorGroup,
    setDistributorGroup,

    //Reason for Exchange
    inputCodeSearchRR,
    setInputCodeSearchRR,
    inputLanguageSearch,
    setInputLanguageSearch,
    inputDisplayOrderSearch,
    setInputDisplayOrderSearch,
    inputFacRreLabelSearch,
    setInputFacRreLabelSearch,
    inputEligibleSearch,
    setInputEligibleSearch,

    //Refusal reasons
    inputCodeSearchRefusal,
    setInputCodeSearchRefusal,
    inputLanguageSearchRefusal,
    setInputLanguageSearchRefusal,
    inputDisplayOrderSearchRefusal,
    setInputDisplayOrderSearchRefusal,
    inputFacRreLabelSearchRefusal,
    setInputFacRreLabelSearchRefusal,

    //Change log
    inputIndexSearch,
    setInputIndexSearch,
    inputSessionIndexSearch,
    setInputSessionIndexSearch,
    inputSessionLoginSearch,
    setInputSessionLoginSearch,
    inputSessionDateSearch,
    setSessionDateSearch,
    inputUserSearch,
    setInputUserSearch,
    inputDateSearch,
    setInputDateSearch,
    inputPrimaryLoginSearch,
    setInputPrimaryLoginSearch,
    inputObjectSearch,
    setInputObjectSearch,
    inputActionSearch,
    setInputActionSearch,

    //users
    inputLogin,
    setInputLogin,
    inputActiveLenguaje,
    setInputActiveLenguaje,
    checkedDisabled,
    setCheckedDisabled,
    checkedEnabled,
    setCheckedEnabled,
    checkedPending,
    setCheckedPending,
    checkedViewOnly,
    setCheckedViewOnly,
    checkedWebServicesOnly,
    setCheckedWebServicesOnly,
    checkedAdmin,
    setCheckedAdmin,
    checkedDistributor,
    setCheckedDistributor,
    checkedGroup,
    setCheckedGroup,
    checkedAftersales,
    setCheckedAftersales,
    checkedCustomer,
    setCheckedCustomer,
    inputHomePage,
    setInputHomePage,
    inputModuleName,
    setInputModuleName,
    inputDateFormat,
    setInputDateFormat,
    inputPreferrendLenguaje,
    setInputPreferrendLenguaje,
    inputTitle,
    setInputTitle,
    inputName,
    setInputName,
    inputFirstName,
    setInputFirstName,
    inputEmail,
    setInputEmail,
    inputWorkPhone,
    setInputWorkPhone,
    inputHomePhone,
    setInputHomePhone,
    inputMobilePhone,
    setInputMobilePhone,
    inputZipCode,
    setInputZipCode,
    inputCity,
    setInputCity,
    inputState,
    setInputState,
    inputCountry,
    setInputCountry,
    inputDefaultOpenedDomain,
    setInputDefaultOpenedDomain,
    inputDistributorUser,
    setInputDistributorUser,
    inputDistributorCodeUser,
    setInputDistributorCodeUser,
    inputDistributorNameUser,
    setInputDistributorNameUser,
    inputCustomerUser,
    setInputCustomerUser,
    inputCustomerUserName,
    setInputCustomerUserName,
    inputCustomerFirstName,
    setInputCustomerFirstName,
    inputCustomerLastName,
    setInputCustomerLastName,
    inputGroup,
    setInputGroup,
    inputGroupCode,
    setInputGroupCode,
    inputGroupName,
    setInputGroupName,
  };

  return <FilterContext.Provider value={contextValues}>{children}</FilterContext.Provider>;
};

export const useFilterContext = () => {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error("FilterContext must be used within a FilterContextProvider");
  }
  return context;
};

export { FilterContext, FilterContextProvider };
