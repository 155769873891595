import { AxiosResponse, AxiosRequestConfig } from "axios";
import { axios as http } from "../../frameworks_and_drivers/Axios";
import { BACKEND_URL } from "../../config/settings.json";
import { ICustomerList, ICustomerListID } from "../interfaces/interfaz_customer";
import { downloadFileFromResponse } from "./helps";
import { loadAbort } from "../../helpers/loadAbort";
import HelpService from "../HelpService";
import { t } from "i18next";
import { fromCountryTranslatedToKey } from "../../utilities/countryTraslations";
import { fromLanguajeTranslatedToKey } from "../../utilities/languajeTraslations";
import { fromBusinessTranslatedToKey, fromProfessionTypeTranslatedToKey, fromWaarantyTranslatedToKey } from "../../utilities/usetBussinessTransalte";

export const getAllCustomers = (props?: any) => {
  const controller = loadAbort();
  const { query } = props;
  const URL = `${BACKEND_URL}/customers/get-all?` + HelpService.getQueryString(query);
  return {
    call: http.get<ICustomerList>(URL, { signal: controller.signal }),
    controller,
  };
};

export const getAllCustomersAssosiatedDist = async (distributor: string) => {
  const URL = `${BACKEND_URL}/customers/get-all?associate_distributor=${distributor}`;
  const response = await http.get<ICustomerList>(URL);
  return response;
};
export const getAllCustomersAssosiatedGroup = async (group: string) => {
  const URL = `${BACKEND_URL}/customers/get-all?associate_group=${group}`;
  const response = await http.get<ICustomerList>(URL);
  return response;
};

export const getAllCustomersByDistributorID = async (DistributorID: string) => {
  const URL = `${BACKEND_URL}/customers/get-all?associate_distributor=${DistributorID}`;
  const response = await http.get<ICustomerList>(URL);
  return response;
};

export const getAllCustomersByGroupID = async (GroupID: string) => {
  const URL = `${BACKEND_URL}/customers/get-all?associate_group=${GroupID}`;
  const response = await http.get<ICustomerList>(URL);
  return response;
};

export const getAllCustomersById = async (objectId: string) => {
  const URL = `${BACKEND_URL}/customers/${objectId}/get-one`;
  const response = await http.get<ICustomerListID>(URL);
  return response;
};

export const CreateCustomer = async (
  type: string,
  business: string,
  company_name: string,
  terms_accepted: boolean,
  privacy_policy_accepted: boolean,
  comments: string,
  username: string,
  first_name: string,
  last_name: string,
  email: string,
  lasts_login: string,
  birth_date: string,
  profile_picture: string,
  work_phone: string,
  home_phone: string,
  mobile_phone: string,
  password: string,
  lenguage_active: string,
  status: boolean,
  homepage: string,
  address: string,
  address_complement: string,
  coordinates: string,
  map_url: string,
  geographical_coordinates: string,
  city: string,
  country: string,
  zipcode: string
) => {
  // let body= new FormData()
  // body.append("account_type": type)
  // body.append("business": business)
  // body.append("siret_code": siret_code)
  // body.append("terms_accepted": terms_accepted)
  // body.append("privacy_policy_accepted": privacy_policy_accepted)
  // body.append("comments": comments)
  // body.append("username": username)
  // body.append("first_name": first_name)
  // body.append("last_name": last_name)
  // body.append("email": email)
  // body.append("lasts_login": lasts_login)
  // body.append("birth_date": birth_date)
  // body.append("profile_picture": profile_picture)
  // body.append("work_phone": work_phone)
  // body.append("home_phone": home_phone)
  // body.append("mobile_phone": mobile_phone)
  // body.append("password": password)
  // body.append("role": roleID)

  // bodyFormData.append("users_settings",JSON.stringify({
  //      "lenguage_active": lenguage_active,
  //      "status": status,
  //      "homepage": homepage
  // }))

  // bodyFormData.append("address",JSON.stringify({
  //     "address": address,
  //     "address_complement": address_complement,
  //     "coordinates": coordinates,
  //     "map_url":map_url,
  //     "geographical_coordinates": geographical_coordinates,
  //     "city": city,
  //     "country": country,
  //     "zipcode": zipcode
  // }))
  const body = {
    account_type: type,
    business: business,
    company_name: company_name,
    terms_accepted: terms_accepted,
    privacy_policy_accepted: privacy_policy_accepted,
    comments: comments,
    username: username,
    first_name: first_name,
    last_name: last_name,
    email: email,
    lasts_login: lasts_login,
    birth_date: birth_date,
    profile_picture: profile_picture,
    work_phone: work_phone,
    home_phone: home_phone,
    mobile_phone: mobile_phone,
    password: password,
    users_settings: {
      lenguage_active: lenguage_active,
      status: status,
      homepage: homepage,
    },
    address: {
      address: address,
      address_complement: address_complement,
      coordinates: coordinates,
      map_url: map_url,
      geographical_coordinates: geographical_coordinates,
      city: city,
      country: country,
      zipcode: zipcode,
    },
  };
  const URL = `${BACKEND_URL}/customers/create-one`;
  const response = await http.post(URL, body);
  // const response = await http.post(URL,body,opts2);
  return response;
};

export const updateCustomer = async (
  objectId: string,
  account_type: string,
  business: string,
  company_name: string,
  terms_accepted: boolean,
  privacy_policy_accepted: boolean,
  comments: string,
  first_name: string,
  last_name: string,
  email: string,
  work_phone: string,
  home_phone: string,
  mobile_phone: string,
  fax_number: string,
  address: string,
  address_complement: string,
  coordinates: string,
  map_url: string,
  city: string,
  country: string,
  zipcode: string,
  lenguage_active: string
) => {
  const body = {
    account_type: account_type,
    business: business,
    company_name: company_name,
    terms_accepted: terms_accepted,
    privacy_policy_accepted: privacy_policy_accepted,
    comments: comments,
    first_name: first_name,
    last_name: last_name,
    email: email,
    work_phone: work_phone,
    home_phone: home_phone,
    company_phone_number: mobile_phone,
    fax_number: fax_number,
    address: {
      address: address,
      address_complement: address_complement,
      coordinates: coordinates,
      map_url: map_url,
      city: city,
      country: country,
      zipcode: zipcode,
    },
    customers_settings: {
      lenguage_active,
    },
  };
  const URL = `${BACKEND_URL}/customers/${objectId}/update-one`;
  const response = await http.put(URL, body);
  return response;
};

export const updateCustomerFromUser = async (
  objectId: string,
  account_type: string,
  business: string,
  company_name: string,
  terms_accepted: boolean,
  privacy_policy_accepted: boolean,
  address: string,
  address_complement: string,
  city: string,
  country: string,
  zipcode: string
) => {
  const body = {
    account_type: account_type,
    business: business,
    company_name: company_name,
    terms_accepted: terms_accepted,
    privacy_policy_accepted: privacy_policy_accepted,
    address: {
      address: address,
      address_complement: address_complement,
      city: city,
      country: country,
      zipcode: zipcode,
    },
  };
  const URL = `${BACKEND_URL}/customers/${objectId}/update-one`;
  const response = await http.put(URL, body);
  return response;
};

export const deleteCustomer = async (objectId: string) => {
  const URL = `${BACKEND_URL}/customers/${objectId}/delete-one`;
  const response = await http.put(URL);
  return response;
};

export const deleteManyCustomers = async (ids: string[]) => {
  const URL = `${BACKEND_URL}/customers/delete-many`;
  const body = {
    items: ids,
  };
  const response = await http.put(URL, body);
  return response;
};

export const getExportCustomers = async (type: string) => {
  const URL = `${BACKEND_URL}/customers/generate-report`;
  const opts: AxiosRequestConfig = {
    params: {
      export_type: type,
    },
    responseType: "blob",
  };
  const response = await http.get(URL, opts);
  const responseDownload = downloadFileFromResponse(response, type, "Customer");
  return responseDownload;
};

export const getExportCustomersByDistributorID = async (type: string, DistributorID: string) => {
  const URL = `${BACKEND_URL}/customers/generate-report?associate_distributor=${DistributorID}`;
  const opts: AxiosRequestConfig = {
    params: {
      export_type: type,
    },
    responseType: "blob",
  };
  const response = await http.get(URL, opts);
  const responseDownload = downloadFileFromResponse(response, type, "Customer");
  return responseDownload;
};

export const getExportCustomersByGroupId = async (type: string, GroupID: string) => {
  const URL = `${BACKEND_URL}/customers/generate-report?associate_group=${GroupID}`;
  const opts: AxiosRequestConfig = {
    params: {
      export_type: type,
    },
    responseType: "blob",
  };
  const response = await http.get(URL, opts);
  const responseDownload = downloadFileFromResponse(response, type, "Customer");
  return responseDownload;
};

export const getExportCustomersByID = async (type: string, ids: string[], name: string, query?: any) => {
  const URL = `${BACKEND_URL}/customers/create-report?` + HelpService.getQueryString(query);
  const body = {
    file_type: type,
    search: ids,
  };
  const opts: AxiosRequestConfig = {
    responseType: "blob",
  };
  const response = await http.post(URL, body, opts);
  const responseDownload = downloadFileFromResponse(response, type, name);
  return responseDownload;
};

export const updateInBulkCustomers = async (bodyCustomer: any) => {
  const URL = `${BACKEND_URL}/customers/bulk-update`;
  const body = {
    account_type: fromProfessionTypeTranslatedToKey(bodyCustomer.DropDownTypesCreate),
    company_name: bodyCustomer.companyName,
    first_name: bodyCustomer.firstName,
    last_name: bodyCustomer.lastName,
    work_phone: bodyCustomer.workPhone,
    home_phone: bodyCustomer.homePhone,
    mobile_phone: bodyCustomer.mobilePhone,
    fax_number: bodyCustomer.faxNumber,
    business:fromBusinessTranslatedToKey(bodyCustomer.DropDownBussinessCreate),
    address: {
      address: bodyCustomer.address,
      address_complement: bodyCustomer.addressComplement,
      city: bodyCustomer.city,
      country: fromCountryTranslatedToKey(bodyCustomer.DropDownCitiesCreate),
      zipcode: bodyCustomer.zipCode,
    },
    /*  customers_settings: {
      lenguage_active: fromLanguajeTranslatedToKey(bodyCustomer.DropDownLanguajeCreate),
    },*/
    search: bodyCustomer.search,
  };
  const response = await http.put(URL, body);
  return response;
};
