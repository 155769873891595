import { faWrench, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useContext, useEffect } from "react";
import { Breadcrumb, Button, Card, Container, Modal } from "react-bootstrap";
import LateralContainer from "../../organisms/LateralContainer";
import ProductTableBrand from "../../organisms/ProductTableBrand/ProductTableBrand";
import ProductTableIdentification from "../../organisms/ProductTableIdentification/ProductTableIdentification";
import ProductTableStockAndPricing from "../../organisms/ProductTableStockAndPricing/ProductTableStockAndPricing";
import ProductTableMarketing from "../../organisms/ProductTableMarketing/ProductTableMarketing";
import "./CreateProduct.css";
import BrandsList from "../BrandsList";
import { ApplicationContext } from "../../../context/ApplicationContext";
import CreateBrand from "../CreateBrand";

import { CreateProducts } from "../../../api/services/products_services";
import { getAllBrandsById } from "../../../api/services/brand_services";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IRootState } from "../../../store/redux";
import usePictures from "../../../hooks/usePictures";
import FullLoading from "../../atoms/FullLoading";
import useToastMui from "../../../hooks/Toasts/useToastMui";
import { listTypeOptionsKeys } from "../ProductsInfo/ProductsInfo";
import { fromWaarantyTranslatedToKey } from "../../../utilities/usetBussinessTransalte";

export default function CreateProduct(props: any) {
  //Translations
  const { handleCreateToast } = useToastMui();
  const { t } = useTranslation();
  const listTypeOptions = [
    "",
    `${t("TABLE.PRODUCTS-STATS.TITLE-OPENINGWRENCH")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-PAWLS")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-SOCKETS")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-SCREWDRIVERS")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-MALEWRENCHS")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-PLIERS")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-TRACING")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-HAMMERS")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-SAWING")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-EXTRACTION")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-OTHER")}`,
  ];

  const listWarranty = [`${t("TYPE-WARRANTY-1")}`, `${t("TYPE-WARRANTY-2")}`, `${t("TYPE-WARRANTY-3")}`];

  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected } = profile;

  const history = useHistory();

  const {
    brandId,
    setBrandId,
    brandCode,
    brandName,
    setBrandCode,
    setBrandName,
    openBrandTable,
    setOpenBrandTable,
    openCreateBrand,
    setOpenCreateBrand,
  } = useContext(ApplicationContext);
  const {
    setProductId,
    setProductReference,
    setProductName,
    setProductBrandCode,
    setProductBrandName,
    setProductPicture,
    setProductType,
    setProductUnitPrice,
    setOpenCreateProduct,
    openCreateProduct,
  } = useContext(ApplicationContext);

  //Identification
  const [selectedType, setSelectedType] = useState<string>(listTypeOptions[0]);
  const [reference, setReference] = useState<string>("");
  const [oldReference, setOldReference] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [checkedIdentification, setCheckedIdentification] = useState(false);
  const handleChange = (nextChecked: any) => {
    setCheckedIdentification(nextChecked);
  };

  //Stock and pricing
  const [warranty, setWarranty] = useState<string>(listWarranty[0]);
  const [unitPrice, setUnitPrice] = useState<string>("0.00");
  const [checkedAvailable, setCheckedAvailable] = useState(true);
  const handleChangeAvailable = (nextChecked: any) => {
    setCheckedAvailable(nextChecked);
  };

  //Marketing
  const [inputDescription, setInputDescription] = useState<string>("");
  const [onlineDoc, setOnlineDoc] = useState<string>("");
  const [ean, setEan] = useState<string>("");
  const [eanImage, setEanImage] = useState<string>("");

  useEffect(() => {
    setProductBrandCode("");
    setBrandCode("");
    setBrandName("");
    setProductBrandName("");
    setBrandId("");
  }, []);

  //LateralContainer
  const [showLateralContainer, setShowLateralContainer] = useState(false);
  const lateralContainer = (content: any) => {
    return (
      <LateralContainer
        show={showLateralContainer}
        toggleShow={() => setShowLateralContainer(!showLateralContainer)}
        content={content}
      ></LateralContainer>
    );
  };

  //Get brand Table

  const handleCloseBrandTable = () => setOpenBrandTable(false);

  const renderBrandTable = () => {
    return (
      <div>
        <Modal
          show={openBrandTable}
          onHide={handleCloseBrandTable}
          // backdrop="static"
          // keyboard={false}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <BrandsList Create={true} />
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  const handleCloseBrandC = () => setOpenCreateBrand(false);

  const renderCreatBrand = () => {
    return (
      <div>
        <Modal
          show={openCreateBrand}
          onHide={handleCloseBrandC}
          // backdrop="static"
          // keyboard={false}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <CreateBrand Created={true} />
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  //API STATE
  const [loading, setLoading] = useState<boolean>(false);

  //Alerts
  const [warningAlertStatus, setWarningAlertStatus] = useState<boolean>(false);
  //? IMAGES
  const picture = usePictures();
  const brochure = usePictures();
  //! ------------------------------API CALLS--------------------------------//
  const createProduct = async (close: boolean) => {
    setLoading(true);
    if (brandId && reference && name && selectedType && unitPrice && warranty) {
      try {
        const response = CreateProducts(
          brandId,
          reference,
          oldReference,
          name,
          selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-OPENINGWRENCH")}`
            ? listTypeOptionsKeys.fixed
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-PAWLS")}`
            ? listTypeOptionsKeys.pawls
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-SOCKETS")}`
            ? listTypeOptionsKeys.socket
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-SCREWDRIVERS")}`
            ? listTypeOptionsKeys.screwDrivers
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-MALEWRENCHS")}`
            ? listTypeOptionsKeys.mWrench
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-PLIERS")}`
            ? listTypeOptionsKeys.pliers
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-TRACING")}`
            ? listTypeOptionsKeys.measuring
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-HAMMERS")}`
            ? listTypeOptionsKeys.hammers
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-SAWING")}`
            ? listTypeOptionsKeys.sawing
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-EXTRACTION")}`
            ? listTypeOptionsKeys.extraction
            : selectedType === `${t("TABLE.PRODUCTS-STATS.TITLE-OTHER")}`
            ? listTypeOptionsKeys.other
            : "",
          checkedIdentification,
          picture.picture ? picture.picture : { file_content: null, extension: null },
          fromWaarantyTranslatedToKey(warranty),
          unitPrice,
          checkedAvailable,
          "€",
          inputDescription,
          onlineDoc,
          ean,
          "image",
          brochure.picture ? brochure.picture : { file_content: null, extension: null }
        );
        const data = (await response).data;
        handleCreateToast(t("ALERT-PRODUCTS-SUCCESS-CREATE"), "success");
        if (!close) {
          history.push(`/product-info/${data?.result.id}`);
        } else {
          if (document.referrer) {
            history.go(-1);
          } else {
            history.push("/");
          }
        }
        return data;
      } catch (error: any) {
        console.log("error create product");
        console.log(error);
        if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(`${error.response.data.message}`, "error");
      } finally {
        console.log("end");
        setLoading(false);
      }
    } else {
      alertWarning();
    }
    setLoading(false);
  };

  //Calling api from info page (product info)
  //Don't rediect
  const handleCreated = async () => {
    setLoading(true);
    if (brandId && reference && name && selectedType && unitPrice && warranty) {
      try {
        const response = CreateProducts(
          brandId,
          reference,
          oldReference,
          name,
          selectedType,
          checkedIdentification,
          picture.picture ? picture.picture : { file_content: null, extension: null },
          warranty,
          unitPrice,
          checkedAvailable,
          "€",
          inputDescription,
          onlineDoc,
          ean,
          "image",
          brochure.picture ? brochure.picture : { file_content: null, extension: null }
        );
        const data = (await response).data;
        handleCreateToast(t("ALERT-PRODUCTS-SUCCESS-CREATE"), "success");
        setProductId(data.result._id);
        setProductPicture(data.result.picture);
        setProductType(data.result.type);
        setProductUnitPrice(data.result.unit_price);
        setProductReference(data.result.reference);
        setProductName(data.result.name);
        if (data.result?.brand) {
          const responseBrand = getAllBrandsById(data.result?.brand);
          const dataBrand = (await responseBrand).data;
          setProductBrandCode(dataBrand.result.code);
          setProductBrandName(dataBrand.result.name);
        }

        setOpenCreateProduct(!openCreateProduct);

        return data;
      } catch (error: any) {
        console.log("error create product");
        console.log(error);
        if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(`${error.response.data.message}`, "error");
      } finally {
        console.log("end");
      }
    } else {
      alertWarning();
    }
    setLoading(false);
  };
  //! --------------------------END API CALLS--------------------------------//

  const alertWarning = () => {
    setWarningAlertStatus(true);
  };

  return (
    <div>
      {lateralContainer(<Container></Container>)}

      <Breadcrumb>
        <Link to="/product">
          <Breadcrumb.Item href="/product">{t("TABLE.PRODUCT.TITLE-HEADER")}</Breadcrumb.Item>
        </Link>
        <FontAwesomeIcon icon={faPlay} style={{ width: "10px", margin: "5px 15px" }} />

        <Breadcrumb.Item href="#">{t("CREATE.PRODUCT.TITLE-HEADER")}</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header>
          <div className="flex-warranty flex-between">
            <div className="container-warranty flex-start">
              <FontAwesomeIcon icon={faWrench} className="icon_size" />

              <h4 className="h4-stl" style={{ margin: "25px 5px" }}>
                <strong>{t("CREATE.PRODUCT.TITLE-HEADER")}</strong>
              </h4>
            </div>
            <div className="container-warranty flex-end wrap-warranty">
              {!props.Created && (
                <Button variant="primary" onClick={() => createProduct(false)}>
                  {t("CREATE.ALL.BUTTON-SAVE")}
                </Button>
              )}

              {!props.Created ? (
                <Button variant="outline-secondary" onClick={() => createProduct(true)}>
                  {t("CREATE.ALL.BUTTON-SAVE-CLOSE")}
                </Button>
              ) : (
                <Button onClick={() => handleCreated()} variant="outline-secondary">
                  {t("CREATE.ALL.BUTTON-SAVE-CLOSE")}
                </Button>
              )}
              {!props.Created ? (
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    if (document.referrer) {
                      history.go(-1);
                    } else {
                      history.push("/");
                    }
                  }}
                >
                  {t("CREATE.ALL.BUTTON-CLOSE")}
                </Button>
              ) : (
                <Button onClick={() => setOpenCreateProduct(!openCreateProduct)} variant="outline-secondary">
                  {t("CREATE.ALL.BUTTON-CLOSE")}
                </Button>
              )}
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <ProductTableBrand
            onClickSearch={() => setOpenBrandTable(true)}
            onClickCreate={() => setOpenCreateBrand(true)}
            warningAlertStatus={warningAlertStatus}
            brandCode={brandCode}
            setBrandCode={(data: string) => {
              setBrandCode(data);
            }}
            brandName={brandName}
            setBrandName={(data: string) => {
              setBrandName(data);
            }}
          />

          <div className="row">
            <div className="col-12 col-lg-6 my-4">
              <ProductTableIdentification
                selectedType={selectedType}
                warningAlertStatus={warningAlertStatus}
                setSelectedType={(data: string) => {
                  setSelectedType(data);
                }}
                reference={reference}
                setReference={(data: string) => {
                  setReference(data);
                }}
                oldReference={oldReference}
                setOldReference={(data: string) => {
                  setOldReference(data);
                }}
                name={name}
                setName={(data: string) => {
                  setName(data);
                }}
                listTypeOptions={listTypeOptions}
                checked={checkedIdentification}
                handleChange={handleChange}
                fileName={picture.fileNameImage}
                handleChangeStatus={picture.handleChangeStatus}
              />
            </div>
            <div className="col-12 col-lg-6 my-4">
              <ProductTableStockAndPricing
                warningAlertStatus={warningAlertStatus}
                warranty={warranty}
                setWarranty={(data: string) => {
                  setWarranty(data);
                }}
                unitPrice={unitPrice}
                setUnitPrice={(data: string) => {
                  setUnitPrice(data);
                }}
                checkedAvailable={checkedAvailable}
                handleChangeAvailable={handleChangeAvailable}
                listWarranty={listWarranty}
                nameRolSelected={nameRolSelected}
              />
              <ProductTableMarketing
                handleChange={(content: any) => {
                  setInputDescription(content);
                }}
                onlineDoc={onlineDoc}
                setOnlineDoc={(data: string) => {
                  setOnlineDoc(data);
                }}
                ean={ean}
                setEan={(data: string) => {
                  setEan(data);
                }}
                eanImage={eanImage}
                setEanImage={(data: string) => {
                  setEanImage(data);
                }}
                fileName={brochure.fileNameImage}
                handleChangeStatus={brochure.handleChangeStatus}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
      <FullLoading open={loading} />
      {renderBrandTable()}
      {renderCreatBrand()}
    </div>
  );
}
