import { IDistributorsSetAPI, IDistributorsSetDOM } from "../../../api/interfaces/interfaz_distributor_Set";

export const createAddapterDistributorSet = (item: IDistributorsSetAPI[]): IDistributorsSetDOM[] => {
  const array: IDistributorsSetDOM[] = [];
  item?.map((item: IDistributorsSetAPI) => {
    return array.push({
      id: item?.id,
      description: item?.description,
      groupId: item?.group_id,
      membersOfSet: item?.members_of_set,
      name: item?.name,
      origin: item?.origin,
      groupData: {
        code: item?.group_data?.code,
        description: item?.group_data?.description,
        id: item?.group_data?.id,
        logo: {
          extension: item?.group_data?.logo?.extension,
          fileContent: item?.group_data?.logo?.file_content,
        },
        name: item?.group_data?.name,
        users: item?.group_data?.users,
      },
      groupCode:item.group_data?.code,
      groupName:item.group_data?.name
    });
  });
  return array;
};
