import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxes } from "@fortawesome/free-solid-svg-icons";
import { IRowBrandFromAPI } from "../../../organisms/TableBrands/TableBrands";
import ImagesApi from "../../../pages/ExchangesInfo/ImagesApi";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export default function BrandTableResponsive({
  render,
  handleBrand,
  Create,
}: {
  render: IRowBrandFromAPI[];
  handleBrand: Function;
  Create?: boolean;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div>
      {render?.map((row: IRowBrandFromAPI) => (
        <Accordion
          sx={{
            backgroundColor: "#f7f7f7",
            borderRadius: "0.3rem 0.3rem",
            border: "solid 0.0625rem #e0e0e0",
            margin: "8px",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <div
              style={{ maxWidth: "230px", overflow: "hidden" }}
              className="flex-warranty flex-between"
              onClick={() => {
                !Create ? history.push(`/brand-info/${row.id}`) : handleBrand(row.id, row.code, row.name);
              }}
            >
              <div className="container-warranty flex-start">
                <FontAwesomeIcon className="icon_size" icon={faBoxes} />

                <div>
                  <h6 className="h6-stl" style={{ margin: "15px 5px" }}>
                    <strong>{row.code}</strong>
                  </h6>
                </div>
              </div>
              <div className="container-warranty flex-end wrap-warranty"></div>
            </div>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: "#fff",
              fontSize: "12px",
            }}
          >
            <div>
              <div style={{ textAlign: "center" }}>
                <ImagesApi image={row?.logo} />
              </div>
              <ul>
                <li>
                  <h6 className="font-response">
                    <strong>{t("SEARCH.BRAND.CODE")}: </strong>
                    {row.code}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{t("SEARCH.BRAND.NAME")}: </strong>
                    {row.name}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{t("SEARCH.BRAND.WEBSITE")}: </strong>
                    {row.website}
                  </h6>
                </li>
                <li>
                  <h6 className="font-response">
                    <strong>{t("TABLE.BRAND.TITLE-LOGO")}: </strong>
                    {row.logo}
                  </h6>
                </li>
              </ul>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
