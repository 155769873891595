import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../constants/global.css";
import "../pages/styles/Refusal.css";
import TextEditor from "../organisms/TextEditor";
import InputClear from "../atoms/InputClear";
import DropDown from "../atoms/DropDown";
import DropDownCities from "../atoms/DropDownCities";
import Switch from "react-switch";
import "../../constants/responsive.css";
import { useTranslation } from "react-i18next";
import ValidationAlert from "../atoms/ValidationAlert";
import { typeUserData } from "../../constants/userTypes";
import InputClearGuard from "../atoms/InputClearGuard";
import { useSelector } from "react-redux";
import { IRootState } from "../../store/redux";
interface IRefusalProps {
  currentValueCode: string;
  handleOnInputValueCode: any;
  currentValueDisplay: string;
  handleOnInputValueDisplay: any;
  currentValueLabel: string;
  handleOnInputValueLabel: any;
  currentValueLanguaje: string;
  languajeOptionsList: string[];
  onSelectDropdown: any;
  section: string;
  checked: boolean;
  handleChangeChecked: any;
  currentValueDescription: string;
  handleOnInputValueDescription: any;
  warningStatus?: boolean;
}

export default function ItemsInfo(props: IRefusalProps) {
  const {
    currentValueCode,
    handleOnInputValueCode,
    currentValueDisplay,
    handleOnInputValueDisplay,
    currentValueLabel,
    handleOnInputValueLabel,
    currentValueLanguaje,
    onSelectDropdown,
    section,
    languajeOptionsList,
    currentValueDescription,
    handleOnInputValueDescription,
  } = props;
  const { checked, handleChangeChecked, warningStatus } = props;
  //Translations
  const { t, i18n } = useTranslation();
  // const {} = props;
  //Redux
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected } = profile;

  const RenderWarnings = () => {
    return (
      <div>
        <ValidationAlert message={t("ALERT-GLOBAL-FILL-FIELDS")} variant="warning" />
      </div>
    );
  };

  return (
    <div style={{ padding: "30px", margin: "3% 2.5%", width: "95%" }} className="containerBoxHead">
      <div className="row">
        <div className="c40">
          <h6 style={{ margin: "28px 0" }}>
            {t("ALL.GENERAL-REFUSAL-REASON.TITLE-CODE")}
            <span style={{ color: "var(--color-required)" }}>*</span>{" "}
          </h6>
          <InputClearGuard
            roles={[`${typeUserData.admin}`]}
            type="text"
            name="Code"
            placeholder=""
            currentValue={currentValueCode}
            handleOnInputValue={handleOnInputValueCode}
          />
          {!currentValueCode && warningStatus && <RenderWarnings />}
        </div>
        <div className="c40">
          <h6 style={{ margin: "28px 0" }}>
            {t("ALL.GENERAL-REFUSAL-REASON.TITLE-DISPLAY-ORDER")}
            <span style={{ color: "var(--color-required)" }}>*</span>
          </h6>
          <InputClearGuard
            roles={[`${typeUserData.admin}`]}
            type="text"
            name="Code"
            placeholder=""
            currentValue={currentValueDisplay}
            handleOnInputValue={handleOnInputValueDisplay}
          />
          {!currentValueDisplay && warningStatus && <RenderWarnings />}
        </div>

        {section === "ReasonExchange" && (
          <div className="c20">
            <h6 style={{ margin: "28px 0" }}>
              Eligible for exchange?
              <span style={{ color: "var(--color-required)" }}>*</span>
            </h6>
            <Switch
              checked={checked}
              onChange={handleChangeChecked}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={20}
              width={48}
              className="react-switch"
              id="material-switch"
              disabled={nameRolSelected === typeUserData.viewOnly}
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="c1">
          <h6 style={{ margin: "28px 0" }}>
            {t("ALL.GENERAL-REFUSAL-REASON.TITLE-LABEL")}
            <span style={{ color: "var(--color-required)" }}>*</span>
          </h6>
          <InputClearGuard
            roles={[`${typeUserData.admin}`]}
            type="text"
            name="Code"
            placeholder=""
            currentValue={currentValueLabel}
            handleOnInputValue={handleOnInputValueLabel}
          />
          {!currentValueLabel && warningStatus && <RenderWarnings />}
        </div>
        <div className="c1">
          <h6 style={{ margin: "28px 0" }}>
            {t("ALL.GENERAL-REFUSAL-REASON.TITLE-LANGUAJE")}
            <span style={{ color: "var(--color-required)" }}>*</span>
          </h6>
          <DropDownCities
            options={languajeOptionsList}
            selectedItem={currentValueLanguaje}
            onSelect={onSelectDropdown}
            width="100%"
            roles={[`${typeUserData.admin}`]}
          />
          {!currentValueLanguaje && warningStatus && <RenderWarnings />}
        </div>
      </div>
      <h6 style={{ margin: "28px 0" }}>
        <strong>{t("ALL.GENERAL-REFUSAL-REASON.TITLE-DESCRIPTION")}</strong>
      </h6>

      <TextEditor
        height={200}
        defaultValue={currentValueDescription}
        handleChange={(content: any) => {
          handleOnInputValueDescription(content);
        }}
        readOnly={nameRolSelected === typeUserData.viewOnly}
      />
    </div>
  );
}
