import React, { useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Alert from "@material-ui/lab/Alert";

import "./styles/ValidationAlert.css";

interface Ivalidation {
  message?: string;
  variant?: any;
  show: boolean;
  setShow: any;
}

export default function FloatAlert(props: Ivalidation) {
  const { message, variant = "error", show, setShow } = props;
  if (show) {
    setShow();
  }
  return (
    <div
      style={{
        position: "absolute",
        top: " 2%",
        left: "20%",
        width: "60%",
      }}
    >
      <div className={show ? "alert-stl fade-in" : "alert-stl"}>{show && <Alert severity={variant}>{message}</Alert>}</div>
    </div>
  );
}
