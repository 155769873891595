import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import "../../../constants/global.css";
import "./exchangesHistTable.css";
import { Card } from "react-bootstrap";
import ExchangesHistInfo from "../ExchangesHistInfo/ExchangesHistInfo";
import { t } from "i18next";

interface IRefusalProps {
  mainlink: boolean;
  exchangesArray: any;
}

export default function ExchangesHistTable(props: IRefusalProps) {
  return (
    <Card>
      <Card.Header>
        <div className="flex-warranty flex-between">
          <div className="container-warranty flex-start">
            <FontAwesomeIcon icon={faExchangeAlt} className="icon_size" />
            <div>
              <h5 className="h5-stl">
                <strong>{t("HOME.MENU.EXCHANGES")}</strong>
              </h5>
              <h5 className="h5-stl">Total {props.exchangesArray?.length}</h5>
            </div>
          </div>
          <div className="container-warranty flex-end wrap-warranty">
            <FontAwesomeIcon className="grayBackgr" icon={faBars} style={{ width: "50px", height: "50px" }} />
          </div>
        </div>
      </Card.Header>
      <Card.Body className="my-2 py-2">
        <ExchangesHistInfo searchState={false} exchangesArray={props.exchangesArray} />
      </Card.Body>
      <div className="row sectionBoxBottom">
        <div className="col-10 col-lg-11">
          <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
            Total {props.exchangesArray?.length}
          </h4>
        </div>
      </div>
    </Card>
  );
}
