import {
  IChangeLogListDOM,
  IChangeLogListPage,
} from "../../../api/interfaces/interfaz_change_log";

export const createAddapterChangeLogs = (
  item: IChangeLogListPage[]
): IChangeLogListDOM[] => {
  const array: IChangeLogListDOM[] = [];
  item?.map((item: IChangeLogListPage) => {
    return array.push({
      _id: item._id,
      action: item.action,
      after: item.after,
      before: item.before,
      changes: item.changes,
      date: item.date,
      login: item.login,
      msg: item.msg,
      resource: item.resource,
      resource_id: item.resource_id,
      user: item.user,
      visible: item.visible,
    });
  });
  return array;
};
