import { axios as http } from "../../frameworks_and_drivers/Axios";
import {BACKEND_URL} from "../../config/settings.json"
import { IComments } from "../interfaces/interfaz_comments";
import { loadAbort } from "../../helpers/loadAbort";
// Comments
export const getAllComments = async () => {
  const URL = `${BACKEND_URL}/comments/get-all`;
  const response = await http.get<IComments>(URL);
  return response;
};

export const getAllCommentsByResourceID = async (ResourceID: string) => {
  const URL = `${BACKEND_URL}/comments/get-all?resource_id=${ResourceID}`;
  const response = await http.get<IComments>(URL);
  return response;
};

export const getAllCommentsCoolByResourceID = (ResourceID: string) => {
  const controller = loadAbort();
  const URL = `${BACKEND_URL}/comments/get-all?resource_id=${ResourceID}`;
  return {
    call: http.get<IComments>(URL, { signal: controller.signal }),
    controller,
  };
};

export const updateComments = async (objectId: string, comment: string) => {
  const body = {
    comment: comment,
  };
  const URL = `${BACKEND_URL}/comments/${objectId}/update-one`;
  const response = await http.put(URL, body);
  return response;
};

export const createComments = async (comment: string, resource: string, resource_id: string) => {
  const body = {
    comment: comment,
    resource: resource,
    resource_id: resource_id,
  };
  const URL = `${BACKEND_URL}/comments/create-one`;
  const response = await http.post(URL, body);
  return response;
};

export const deleteComments = async (objectId: string) => {
  const URL = `${BACKEND_URL}/comments/${objectId}/delete-one`;
  const response = await http.put(URL);
  return response;
};
