import { AxiosRequestConfig, AxiosResponse } from "axios";
import { axios as http } from "../../frameworks_and_drivers/Axios";
import { BACKEND_URL } from "../../config/settings.json";
import { IGroupList, IGroupListID } from "../interfaces/interfaz_group";
import { downloadFileFromResponse } from "./helps";
import { loadAbort } from "../../helpers/loadAbort";
import HelpService from "../HelpService";
import { IPicturesAPI } from "../interfaces/globalInterfaces";

export const getAllGroups = (props?: any) => {
  const controller = loadAbort();
  const { query } = props;
  const URL = `${BACKEND_URL}/groups/get-all?` + HelpService.getQueryString(query);
  return {
    call: http.get<IGroupList>(URL, { signal: controller.signal }),
    controller,
  };
};

export const getAllGroupsById = async (objectId: string) => {
  const URL = `${BACKEND_URL}/groups/${objectId}/get-one`;
  const response = await http.get<IGroupListID>(URL);
  return response;
};
export const CreateGroups = async (name: string, code: string, logo: any | null, description: string) => {
  // let body= new FormData()
  // body.append("name": name)
  // body.append("code": code)
  // body.append("logo": logo)
  // body.append("description": description)
  const body = {
    name: name,
    code: code,
    logo: logo,
    description: description,
  };
  const URL = `${BACKEND_URL}/groups/create-one`;
  const response = await http.post(URL, body);
  // const response = await http.post(URL,body,opts2);
  return response;
};

export const updateGroups = async (
  // let body= new FormData()
  // body.append("name": name)
  // body.append("code": code)
  // body.append("logo": logo)
  // body.append("description": description)
  objectId: string,
  name: string,
  code: string,
  logo: any,
  description: string
) => {
  let body: any = {
    name: name,
    code: code,
    description: description,
    logo: logo,
  };
  const URL = `${BACKEND_URL}/groups/${objectId}/update-one`;
  const response = await http.put(URL, body);
  // const response = await http.post(URL,body,opts2);
  return response;
};

export const deleteGroups = async (objectId: string) => {
  const URL = `${BACKEND_URL}/groups/${objectId}/delete-one`;
  const response = await http.put(URL);
  return response;
};
export const deleteManyGroups = async (ids: string[]) => {
  const URL = `${BACKEND_URL}/groups/delete-many`;
  const body = {
    items: ids,
  };
  const response = await http.put(URL, body);
  return response;
};

export const getExportGroup = async (type: string, name: string) => {
  const URL = `${BACKEND_URL}/groups/generate-report`;
  const opts: AxiosRequestConfig = {
    params: {
      export_type: type,
    },
    responseType: "blob",
  };
  const response = await http.get(URL, opts);
  const responseDownload = downloadFileFromResponse(response, type, name);
  return responseDownload;
};

export const getExportGroupByID = async (type: string, ids: string[], name: string, query?: any) => {
  const URL = `${BACKEND_URL}/groups/create-report?` + HelpService.getQueryString(query);
  const body = {
    file_type: type,
    search: ids,
  };
  const opts: AxiosRequestConfig = {
    responseType: "blob",
  };
  const response = await http.post(URL, body, opts);
  const responseDownload = downloadFileFromResponse(response, type, name);
  return responseDownload;
};
interface IBulkGroup {
  name?: string;
  logo?: any;
  description?: string;
  search?: string[];
}
export const updateInBulkGroups = async (body: IBulkGroup) => {
  const URL = `${BACKEND_URL}/groups/bulk-update`;

  const response = await http.put(URL, body);
  return response;
};
