import React, { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { faChevronDown, faChevronUp, faThumbtack } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Container, Dropdown } from "react-bootstrap";

import Pagination from "@mui/material/Pagination";
import Table from "react-bootstrap/Table";
import { Link, useHistory } from "react-router-dom";

//API
import { ApplicationContext } from "../../../context/ApplicationContext";
import { useTranslation } from "react-i18next";
import uncheckedpage from "../../../images/checkPage.svg";
import checkedimg from "../../../images/checked.svg";
import uncheckedimg from "../../../images/unchecked.svg";
import grayChecked from "../../../images/grayCheck.png";
import { Checkbox, useMediaQuery } from "@mui/material";
import LoadingSkeletonTables from "../LoadingSkeleton/LoadingSkeletonTables";
import ImagesApi from "../../pages/ExchangesInfo/ImagesApi";
import GroupTableFilters from "../Search/GroupTableFilters";
import { IRowHeader } from "../../../models/table.modal";
import GroupTableResponsive from "../../molecules/TransferList/responsiveTables/GroupTableResponsive";

export interface IRowGroupFromAPI {
  id: string;
  code: string;
  name: string;
  logo: string;
  distributors_data: any;
  [index: string]: string;
}

interface IRefusalProps {
  searchState: boolean;
  rowsPerPage: number;
  UsePlanText: string;
  inputToSort: string;
  Create?: boolean;
  showSearchTable?: boolean;
  groupArray: any;

  //filerts
  handleSearch: any;

  loading: boolean;
  currentPage: number;
  lastPage: number;
  handleChange: any;
  incrementCount?: any;
  typeSort?: string;
  setDropDownWhySort?: any;
}

export default function TableGroup(props: IRefusalProps) {
  const { setGroupCode, setGroupId, setGroupName, setOpenGroupTable, openGroupTable, setGroupsAllSelected } =
    useContext(ApplicationContext);
  const matches = useMediaQuery("(max-width:780px)");
  const history = useHistory();
  const { lastPage, handleChange, currentPage, groupArray, loading, incrementCount, typeSort, setDropDownWhySort } = props;
  const { groupsListID, setGroupsListID, groupTableTransferData } = useContext(ApplicationContext);
  const { t } = useTranslation();

  //TABLE
  const pageSize = props.rowsPerPage;

  //API STATE

  const handleGroup = (groupId: string, groupCode: string, groupName: string) => {
    setGroupId(groupId);
    setGroupCode(groupCode);
    setGroupName(groupName);
    setOpenGroupTable(!openGroupTable);
  };

  //GENERATE AN ARRAY WITH SELECTED IDS
  const [defaultChecked, setDefaultChecked] = useState<boolean>(false);
  const createDeleteArray = (idDelete: string, checked: boolean) => {
    if (checked) {
      setGroupsListID([...groupsListID, idDelete]);
    } else {
      setGroupsListID(groupsListID.filter((item: any) => item !== idDelete));
    }
  };

  //FILTRO
  let groupArraySort: IRowGroupFromAPI[] = [];
  if (groupArray) {
    groupArraySort = groupArray;
  }

  const tableInitial: IRowHeader[] = [
    {
      field: "dropdown",
      headerName: "",
      sort: "",
    },
    {
      field: "code",
      headerName: t("SORT.GROUP.INPUT-CODE"),
      sort: t("SORT.GROUP.INPUT-CODE"),
    },
    {
      field: "name",
      headerName: t("SORT.GROUP.INPUT-NAME"),
      sort: t("SORT.GROUP.INPUT-NAME"),
    },
    {
      field: "logo",
      headerName: t("ALL.GENERAL.TABLE.GROUP.TITLE-LOGO"),
      sort: t("ALL.GENERAL.TABLE.GROUP.TITLE-LOGO"),
    },
  ];
  const [tabla, setTabla] = useState<IRowHeader[]>(tableInitial);
  useEffect(() => {
    setTabla(tableInitial.filter((item) => groupTableTransferData.includes(item.headerName)));
  }, [groupTableTransferData]);

  const createHeader = (row: IRowHeader, index: number) => (
    <th
      key={`group-${index}`}
      style={{ cursor: "pointer" }}
      // onClick={() => {
      //   setDropDownWhySort(row.sort);
      //   incrementCount(row.field);
      // }}
    >
      {row.headerName}
      {/* {props.inputToSort === row.sort && (
        <FontAwesomeIcon icon={typeSort === `asc(${row.field})` ? faChevronUp : faChevronDown} style={{ marginLeft: "5px" }} />
      )} */}
    </th>
  );

  const createRow = (row: IRowGroupFromAPI, index: number, render: IRowHeader[]) => {
    const isItemSelected: boolean = isSelected(row.id);
    return (
      <tr
        key={row?.id}
        style={{
          backgroundColor: "var(--color-white)",
          textAlign: "left",
          cursor: "pointer",
        }}
      >
        {defaultChecked ? (
          <td style={{ paddingLeft: "15px" }}>
            <img alt="" src={grayChecked} style={{ width: "20px", marginLeft: "10px" }}></img>
          </td>
        ) : (
          <td style={{ paddingLeft: "15px" }} className="check-table">
            <Checkbox
              checked={isItemSelected}
              style={{ display: "inline" }}
              name="gridRadios"
              id={`radios${row.id}`}
              value={`option${row.id}`}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                createDeleteArray(row.id, event.target.checked);
              }}
              aria-label="option 1"
            />
            &nbsp;
            {/* <FontAwesomeIcon icon={faBars} /> */}
          </td>
        )}
        {!props.Create ? (
          <>
            {render?.map((i: IRowHeader) => (
              <td style={{ paddingLeft: "15px" }} onClick={() => history.push(`/group-info/${row.id}`)}>
                {`${i.field}` !== "logo" ? row[`${i.field}`] : <ImagesApi image={row?.logo} />}
              </td>
            ))}
          </>
        ) : (
          <>
            {render?.map((i: IRowHeader) => (
              <td style={{ paddingLeft: "15px" }} onClick={() => handleGroup(row.id, row.code, row.name)}>
                {`${i.field}` !== "logo" ? row[`${i.field}`] : <ImagesApi image={row?.logo} />}
              </td>
            ))}
          </>
        )}
      </tr>
    );
  };

  //SELECT ALL LIST
  const allSelect = (allchecked: boolean, page: boolean) => {
    setDefaultChecked(allchecked);
    let AllIds: any = [];
    if (page) {
      AllIds = groupArraySort.map(function (ids: any) {
        return ids.id;
      });
    } else {
      AllIds = [];
      setGroupsAllSelected(false);
    }
    if (allchecked) {
      setGroupsAllSelected(true);
    }
    setGroupsListID(AllIds);
  };
  const isSelected = (id: string) => groupsListID.indexOf(id) !== -1;
  return (
    <div>
      <Card>
        {!loading ? (
          <>
            {!matches ? (
              <Table responsive="xl" striped hover style={{ border: "2px solid var(--color-input-border)" }}>
                {/* TABLE HEADER */}
                <thead
                  style={{
                    marginBottom: "10px",
                    textAlign: "left",
                    backgroundColor: "rgba(0, 0, 0, 0.03)",
                  }}
                >
                  <tr>
                    <th className="check-table">
                      <Container style={{ padding: 12 }}>
                        <Dropdown style={{ border: "none" }}>
                          <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                            {/* <input type="checkbox" /> */}
                            {defaultChecked ? (
                              <img alt="" src={checkedimg} style={{ width: "20px" }}></img>
                            ) : (
                              <img alt="" src={uncheckedimg} style={{ width: "20px" }}></img>
                            )}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div onClick={() => allSelect(true, false)}>
                              <Dropdown.Item href="#/action-1">
                                <img alt="" src={checkedimg}></img> {t("TABLES-SELECT-ALL")}
                              </Dropdown.Item>
                            </div>
                            <div onClick={() => allSelect(false, true)}>
                              <Dropdown.Item href="#/action-1">
                                <img alt="" src={uncheckedpage}></img> {t("TABLES-SELECT-PAGE")}
                              </Dropdown.Item>
                            </div>
                            <div onClick={() => allSelect(false, false)}>
                              <Dropdown.Item href="#/action-3">
                                <img alt="" src={uncheckedimg}></img> {t("TABLES-UNSELECT-PAGE")}
                              </Dropdown.Item>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Container>
                    </th>

                    {tabla?.map(createHeader)}
                  </tr>

                  <GroupTableFilters handleSearch={props.handleSearch} />
                </thead>
                <tbody style={{ cursor: "pointer !important" }}>
                  {/* TABLE BODY */}
                  {groupArraySort?.map((row: IRowGroupFromAPI, index: number) => createRow(row, index, tabla))}
                </tbody>
              </Table>
            ) : (
              <GroupTableResponsive render={groupArraySort} Create={props.Create} handleGroup={handleGroup} />
            )}
          </>
        ) : (
          <LoadingSkeletonTables />
        )}
        <div className="pb-2" style={{ display: "flex", justifyContent: "space-around" }}>
          <Pagination
            style={{ paddingTop: 10 }}
            count={Math.ceil(lastPage / pageSize)}
            page={currentPage}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
            color="primary"
            boundaryCount={3}
            siblingCount={4}
          />
        </div>
        {/* count -- numero de páginas
                    page -- pagina actual
                    onchange cambio de paginacion */}
      </Card>
    </div>
  );
}
