import { t } from "i18next";
import { statusExchangesE } from "../components/pages/ExchangesInfo/ExchangesInfo";

export const fromInfoToTranslatedStatus = (status: string) => {
  if (status === statusExchangesE.Draft) return `${t("HOME.MENU.EXCHANGES-DRAFT")}`;
  if (status === statusExchangesE.Abandonned) return `${t("HOME.MENU.EXCHANGES-ABANDONNED")}`;
  if (status === statusExchangesE.Submitted) return `${t("HOME.MENU.EXCHANGES-SUBMITTED")}`;
  if (status === statusExchangesE.Analysis) return `${t("HOME.MENU.EXCHANGES-ANALYSIS")}`;
  if (status === statusExchangesE.Accepted) return `${t("HOME.MENU.EXCHANGES-ACCEPTED")}`;
  if (status === statusExchangesE.Available) return `${t("HOME.MENU.EXCHANGES-AVAILABLE")}`;
  if (status === statusExchangesE.Exchanged) return `${t("HOME.MENU.EXCHANGES-EXCHANGED")}`;
  if (status === statusExchangesE.Refused) return `${t("HOME.MENU.EXCHANGES-REFUSED")}`;
  if (status === statusExchangesE.Recycled) return `${t("HOME.MENU.EXCHANGES-RECYCLED")}`;
  return "";
};

export const fromInfoToTranslatedStatusButton = (status: string) => {
    if(status === `${t("HOME.MENU.EXCHANGES-DRAFT")}`) return `${t("EXCHANGES-DRAFT-BUTTON")}`
    if(status === `${t("HOME.MENU.EXCHANGES-ABANDONNED")}`) return `${t("EXCHANGES-ABANDONED-BUTTON")}`
    if(status === `${t("HOME.MENU.EXCHANGES-SUBMITTED")}`) return `${t("EXCHANGES-SUBMITTED-BUTTON")}`
    if(status === `${t("HOME.MENU.EXCHANGES-ANALYSIS")}`) return `${t("EXCHANGES-ANALYZED-BUTTON")}`
    if(status === `${t("HOME.MENU.EXCHANGES-ACCEPTED")}`) return `${t("EXCHANGES-ACCEPT-BUTTON")}`
    if(status === `${t("HOME.MENU.EXCHANGES-AVAILABLE")}`) return `${t("EXCHANGES-AVAILABLE-BUTTON")}`
    if(status === `${t("HOME.MENU.EXCHANGES-EXCHANGED")}`) return `${t("EXCHANGES-EXCHANGE-BUTTON")}`
    if(status === `${t("HOME.MENU.EXCHANGES-REFUSED")}`) return `${t("EXCHANGES-REFUSE-BUTTON")}`
    if(status === `${t("HOME.MENU.EXCHANGES-RECYCLED")}`) return `${t("EXCHANGES-RECYCLE-BUTTON")}`
    return "";
  };

export const fromStatusTranslatedToKey = (status: string) => {
  if (status === `${t("HOME.MENU.EXCHANGES-DRAFT")}`) return statusExchangesE.Draft;
  if (status === `${t("HOME.MENU.EXCHANGES-ABANDONNED")}`) return statusExchangesE.Abandonned;
  if (status === `${t("HOME.MENU.EXCHANGES-SUBMITTED")}`) return statusExchangesE.Submitted;
  if (status === `${t("HOME.MENU.EXCHANGES-ANALYSIS")}`) return statusExchangesE.Analysis;
  if (status === `${t("HOME.MENU.EXCHANGES-ACCEPTED")}`) return statusExchangesE.Accepted;
  if (status === `${t("HOME.MENU.EXCHANGES-AVAILABLE")}`) return statusExchangesE.Available;
  if (status === `${t("HOME.MENU.EXCHANGES-EXCHANGED")}`) return statusExchangesE.Exchanged;
  if (status === `${t("HOME.MENU.EXCHANGES-REFUSED")}`) return statusExchangesE.Refused;
  if (status === `${t("HOME.MENU.EXCHANGES-RECYCLED")}`) return statusExchangesE.Recycled;
  return "";
};
