import InputClear from "../../atoms/InputClear";
import React, { useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../../constants/global.css";
import { faQuestionCircle, faEuroSign, faSearch, faPlus, faTimes, faShare } from "@fortawesome/free-solid-svg-icons";
import ValidationAlert from "../../atoms/ValidationAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropDown from "../../atoms/DropDown";
import Tooltip from "@mui/material/Tooltip";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { Link } from "react-router-dom";
import "../../../constants/responsive.css";
// Translations
import { useTranslation } from "react-i18next";
import ImagesApi from "../../pages/ExchangesInfo/ImagesApi";
import LoadingImageSkeleton from "../LoadingSkeleton/LoadingImageSkeleton";

const listTypeOptions = ["", ""];

export default function ExchangesProductTable(props: any) {
  const {
    setProductId,
    setProductReference,
    setProductBrandName,
    setProductName,
    setProductBrandCode,
    setProductPicture,
    setProductType,
    setProductUnitPrice,
  } = useContext(ApplicationContext);

  const { loadingPicture, picture, extension } = props;

  // Translations
  const { t, i18n } = useTranslation();
  const { warningAlertStatus } = props;

  const RenderWarnings = () => {
    return (
      <div>
        <ValidationAlert message={t("ALERT-GLOBAL-FILL-FIELDS")} variant="warning" />
      </div>
    );
  };

  return (
    <div className="card m-2">
      <div>
        <div className="m-3 sectionBoxTop py-2">
          <h4 className="h4-stl">{t("CREATE.EXCHANGES.TITLE-PRODUCT")}</h4>
        </div>
        {!props.productReference && warningAlertStatus && <RenderWarnings />}
        <div className="flex-inputs-responsive-exchange-input px-3 pb-3">
          <div className="full-width mt-4">
            <h4 className="h4-stl">
              <strong>{t("ALL.GENERAL-EXCHANGE.TITLE-PRODUCT-REFERENCE")} *</strong>
            </h4>
            <div className="flex-inputs-responsive-info ">
              <div className="full-width">
                <InputClear
                  type="text"
                  name=""
                  placeholder=""
                  readOnly
                  style={{
                    background: "var(--color-input-background-secondary)",
                  }}
                  currentValue={props.productReference}
                  handleOnInputValue={props.setProductReference}
                />
              </div>
              <div>
                {props.Create ? (
                  <div style={{ display: "flex" }}>
                    <Tooltip title="Select" placement="bottom">
                      <FontAwesomeIcon
                        className="pointer"
                        icon={faSearch}
                        onClick={props.onClickSearch}
                        style={{
                          width: "2Opx",
                          height: "20px",
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Create" placement="bottom">
                      <FontAwesomeIcon
                        className="pointer"
                        icon={faPlus}
                        onClick={props.onClickCreate}
                        style={{
                          marginLeft: "8px",
                          width: "2Opx",
                          height: "20px",
                        }}
                      />
                    </Tooltip>

                    <Tooltip title="Remove" placement="bottom">
                      <FontAwesomeIcon
                        className="pointer"
                        icon={faTimes}
                        onClick={() => {
                          setProductId("");
                          setProductReference("");
                          setProductBrandName("");
                          setProductName("");
                          setProductBrandCode("");
                          setProductPicture("");
                          setProductType("");
                          setProductUnitPrice("");
                        }}
                        style={{
                          marginLeft: "8px",
                          width: "2Opx",
                          height: "20px",
                        }}
                      />
                    </Tooltip>
                    {props.selectedStatus && (
                      <Tooltip title="Open" placement="bottom">
                        <Link to={`${props.openLink}`} style={{ color: "var(--color-main-text)" }}>
                          <FontAwesomeIcon
                            className="pointer"
                            icon={faShare}
                            style={{
                              marginLeft: "8px",
                              width: "2Opx",
                              height: "20px",
                            }}
                          />
                        </Link>
                      </Tooltip>
                    )}
                  </div>
                ) : (
                  <Tooltip title="Open" placement="bottom">
                    <Link to={`${props.openLink}`} style={{ color: "var(--color-main-text)" }}>
                      <FontAwesomeIcon
                        className="pointer"
                        icon={faShare}
                        style={{
                          marginLeft: "8px",
                          width: "2Opx",
                          height: "20px",
                        }}
                      />
                    </Link>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex-inputs-responsive-exchange-input px-3 pb-3">
          <div className="full-width mt-4">
            <h4 className="h4-stl">
              <strong>{t("CREATE.EXCHANGES.INPUT-PRODUCT-NAME")} *</strong>
            </h4>
            <InputClear
              type="text"
              name=""
              readOnly
              placeholder=""
              style={{ background: "var(--color-input-background-secondary)" }}
              currentValue={props.productName}
              handleOnInputValue={props.setProductName}
            />
          </div>
        </div>
      </div>
      <div className="flex-inputs-responsive-exchange-input px-3 pb-3">
        <div className="full-width mt-4">
          <h4 className="h4-stl">
            <strong>{t("CREATE.EXCHANGES.INPUT-BRAND-CODE")} *</strong>
          </h4>
          <div className="flex-inputs-responsive-info ">
            <div className="full-width">
              <InputClear
                type="text"
                name=""
                readOnly
                placeholder=""
                style={{ background: "var(--color-input-background-secondary)" }}
                currentValue={props.brandCode}
                handleOnInputValue={props.setBrandCode}
              />
            </div>
            <div>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                style={{
                  width: "2Opx",
                  height: "20px",
                }}
              />
            </div>
          </div>
        </div>
        <div className="full-width mt-4">
          <h4 className="h4-stl">
            <strong>{t("ALL.GENERAL-EXCHANGE.TITLE-BRAND-NAME")} *</strong>
          </h4>
          <InputClear
            type="text"
            name=""
            readOnly
            placeholder=""
            style={{ background: "var(--color-input-background-secondary)" }}
            currentValue={props.brandName}
            handleOnInputValue={props.setBrandName}
          />
        </div>
      </div>
      <div className="flex-inputs-responsive-exchange-input px-3 pb-3">
        <div className="full-width mt-4">
          <h4 className="h4-stl">
            <strong>{t("CREATE.EXCHANGES.INPUT-PRODUCT-PICTURE")}</strong>
          </h4>
          <InputClear
            type="text"
            name=""
            readOnly
            placeholder=""
            style={{ background: "var(--color-input-background-secondary)" }}
            currentValue={
              props.productPicture
                ? typeof props.productPicture === "string"
                  ? props.productPicture
                  : props.productPictureFromApi
                : props.productPictureFromApi
            }
            handleOnInputValue={props.setProductPicture}
          />
          {!props.create && (
            <>
              {props.productPicture !== null && (
                <div className="my-3 mx-3">
                  {!loadingPicture ? (
                    <ImagesApi
                      image={
                        props.productPicture
                          ? typeof props.productPicture === "string"
                            ? props.productPicture
                            : props.productPictureFromApi
                          : props.productPictureFromApi
                      }
                      fullWidth
                    />
                  ) : (
                    <LoadingImageSkeleton />
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="flex-inputs-responsive-exchange-input px-3 pb-3 mb-5">
        <div className="full-width">
          <h4 className="h4-stl">
            <strong>{t("CREATE.EXCHANGES.INPUT-PRODUCT-TYPE")} *</strong>
          </h4>
          <DropDown
            disabled
            options={props.listTypeOptions}
            selectedItem={props.selectedType}
            onSelect={props.setSelectedType}
            width="100%"
          />
        </div>
        <div className="full-width ">
          <h4 className="h4-stl">
            <strong>{t("CREATE.EXCHANGES.INPUT-CURRENT-UNIT-PRICE")} *</strong>
          </h4>
          <div className="flex-inputs-responsive-info ">
            <div className="full-width">
              <InputClear
                type="text"
                name=""
                readOnly
                placeholder=""
                style={{ background: "var(--color-input-background-secondary)" }}
                currentValue={props.currentUnitPrice}
                handleOnInputValue={props.setCurrentUnitPrice}
              />
            </div>
            <div>
              <FontAwesomeIcon
                icon={faEuroSign}
                style={{
                  width: "2Opx",
                  height: "20px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
