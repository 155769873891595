import { useState, useEffect, useContext } from "react";
import { IGroupList, IGroupListDOM } from "../../api/interfaces/interfaz_group";
import { getAllGroups, deleteManyGroups, getExportGroup, getExportGroupByID } from "../../api/services/group_services";
import { getAllUsersById } from "../../api/services/user_services";

import { useSelector } from "react-redux";
import { IRootState } from "../../store/redux";
import { ApplicationContext } from "../../context/ApplicationContext";
import useFetchAndLoad from "../useFetchAndLoad";
import useAsync from "../useAsync";
import { createAddapterGroup } from "./adapter/groupAdapter";
import useToastMui from "../Toasts/useToastMui";
import { useTranslation } from "react-i18next";

function useGroups(name: string, code: string) {
  const { setExportToggle, setGroupLength, setGroupsListID, groupsPage, setGroupsPage } = useContext(ApplicationContext);
  const { handleCreateToast } = useToastMui();
  const { t, i18n } = useTranslation();
  //Redux
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected } = profile;
  const { sub } = profile;

  const [groupArray, setGroupArray] = useState<IGroupListDOM[]>();
  const [lastPage, setLastPage] = useState(0);
  const [deletedToogle, setDeletedToogle] = useState(false);
  const [searchToggle, setSearchToggle] = useState(false);
  const limit = 10;
  const [wrongAlertStatus, setWrongAlertStatus] = useState<boolean[]>([false, false, false]);

  const { loading, callEndpoint } = useFetchAndLoad();

  //to sort list by api

  const [countCode, setCountCode] = useState(1);
  const [typeSort, setTypeSort] = useState("");

  const getQuery = (name: string, code: string, sort?: string) => {
    const queryKeys: any = {
      name: name,
      code: code,
      sort: sort,
    };
    let query = {};
    for (let key in queryKeys) {
      if (queryKeys[key]) {
        query = { ...query, [key]: queryKeys[key] };
      }
    }
    return query;
  };

  const loadGroups = async (name: string, code: string, nowPage: number) => {
    let query = getQuery(name, code, typeSort);
    query = {
      ...query,
      limit: limit,
      offset: (nowPage - 1) * limit,
    };

    const response = await callEndpoint(getAllGroups({ query }), () => handleCreateToast(t("ALERT-GROUPS-ERROR-GET"), "error"));
    return response;
  };

  const adaptInfo = (data: IGroupList) => {
    const array: IGroupListDOM[] = createAddapterGroup(data?.result?.items);
    setGroupArray(array);
    setLastPage(data?.result?.total);
    setGroupLength(data?.result?.total);
  };

  useAsync(
    () => loadGroups(name, code, groupsPage),
    adaptInfo,
    () => {},
    [groupsPage, searchToggle, deletedToogle, typeSort]
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setGroupsPage(value);
  };
  const onSearch = () => {
    setSearchToggle((value) => !value);
    setGroupsPage(1);
  };

  const exportGroup = async (checkedType: string, groupsListID: string[], checkNeedToExport: boolean[]) => {
    setExportToggle(true);
    try {
      if (checkNeedToExport[0] === true) {
        await getExportGroup(checkedType, i18n.language === "fr" ? "Groupe" : "Group");
      }
      if (checkNeedToExport[1] === true) {
        let query = getQuery(name, code);
        await getExportGroupByID(checkedType, groupsListID, i18n.language === "fr" ? "Groupe" : "Group", query);
      }
    } catch (error: any) {
      console.log("error export group list");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(t("ALERT-GROUPS-ERROR-EXPORT"), "error");
    } finally {
      console.log("end");
      setExportToggle(false);
    }
  };

  const deleteGroup = async (GroupID: string[]) => {
    try {
      await deleteManyGroups(GroupID);
      handleCreateToast(t("ALERT-GROUPS-SUCCESS-DELETE"), "success");
    } catch (error: any) {
      console.log("error deleted");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(t("ALERT-GROUPS-ERROR-DELETE"), "error");
    } finally {
      console.log("end");
      setGroupsListID([]);
      setGroupsPage(1);
      setDeletedToogle(!deletedToogle);
      //   history.push("/group");
      //   setOpenConfirm(false);
    }
  };

  // * ------------------------SORT----------------------------------//

  // sort

  const incrementCount = (key: string) => {
    setCountCode((prev) => prev + 1);

    if (countCode === 1) {
      setTypeSort(`asc(${key})`);
    }
    if (countCode === 2) {
      setTypeSort(`desc(${key})`);
      setCountCode(1);
    }
  };

  return {
    groupArray,
    loading,
    exportGroup,
    deleteGroup,
    wrongAlertStatus,
    setWrongAlertStatus,
    currentPage: groupsPage,
    lastPage,
    loadGroups,
    handleChange,
    limit,
    onSearch,

    typeSort,
    incrementCount,
  };
}

const service = {
  useGroups,
};
export default service;
export { useGroups };
