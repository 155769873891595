import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
interface ISliderForm {
    disabled?: boolean,
    defaultValue?:number,
}

function valuetext(value: number) {
    return `${value}°C`;
}

export default function SliderTable(props:ISliderForm ) {
    return (
        <Box sx={{ width: 100 }}>
        <Slider
            aria-label="Quantity"
            defaultValue={props.defaultValue}
            getAriaValueText={valuetext}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={1}
            max={10}
            disabled = {props.disabled}
        />
        </Box>
    );
}