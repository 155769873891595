import { useState } from "react";
import { useTranslation } from "react-i18next";
import { resendResetPasswordEmailAPI } from "../../../api/services/user_services";
import InputClear from "../../atoms/InputClear";
import LoadingLogin from "../../atoms/LoadingLogin";
import ValidationAlert from "../../atoms/ValidationAlert";
import "./style.css";
import { LocalStorageKeys, setInLocalStorage } from "../../../helpers/local-storage-manager";
interface IForgotPassword {
  label: string;
}
export default function ForgotPassword(props: IForgotPassword) {
  const [emailToSend, setEmailToSend] = useState("");
  const { t, i18n } = useTranslation();
  const { label } = props;

  const [errorSend, setErrorSend] = useState(false);
  const [successSend, setSuccessSend] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const resendResetPasswordEmail = async () => {
    setLoadingSend(!loadingSend);
    try {
      const response = await resendResetPasswordEmailAPI(emailToSend);
      const data = (await response).data;
      if (!data.result?.migrated) {
        setInLocalStorage(LocalStorageKeys.EMAIL, "");
        setSuccessSend(true);
      } else {
        setInLocalStorage(LocalStorageKeys.USER_ID, data.result?.uuid);

        window.location.replace(`/verify-identity`);
      }
    } catch (error: any) {
      console.log("error sending email");
      console.log(error);
      setErrorSend(true);
      if (i18n.language === "fr") {
        setErrorMessage(
          `${error?.response?.data?.message}` === "user doesn't exists" ? "l'utilisateur n'existe pas" : `${error?.response?.data?.message}`
        );
      } else {
        setErrorMessage(`${error?.response?.data?.message}`);
      }
    } finally {
      console.log("end");
      setLoadingSend(false);
    }
  };

  const handleSubmitReset = (e: any) => {
    e.preventDefault();
    resendResetPasswordEmail();
  };
  return (
    <div className="p-3 background-password">
      <h6 className="forgot-text mb-2">{t("LOGIN-FORGOT-PASSWORD-LABEL")}</h6>
      <form action="/" onSubmit={handleSubmitReset}>
        <InputClear
          type="text"
          name="distributor"
          placeholder={t("LOGIN-FORGOT-PASSWORD-INPUT")}
          currentValue={emailToSend}
          handleOnInputValue={(data: string) => {
            setEmailToSend(data);
          }}
        />
        <div className="py-3">
          <input className="button button-primary button-sm" type="submit" value={`${t("LOGIN-FORGOT-PASSWORD-BUTTON")}`}></input>
          {/* <Button onClick={()=>loginAccount()} size="sm"><h6 style={{fontSize:'15px', margin:'2px 35px'}}><strong>Connexion</strong></h6></Button> */}
        </div>
        {errorSend && (
          <div className="my-3">
            <ValidationAlert message={errorMessage} variant="error" />
          </div>
        )}
        {successSend && (
          <div className="my-3">
            <ValidationAlert
              message={i18n.language === "fr" ? "Le courrier a été envoyé correctement" : "The mail was sent correctly"}
              variant="success"
            />
          </div>
        )}
        <LoadingLogin open={loadingSend} />
      </form>
    </div>
  );
}
