import React, { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHammer, faBars, faSearch } from "@fortawesome/free-solid-svg-icons";
import "../../constants/global.css";
import "./styles/Refusal.css";
import { Breadcrumb, Card, Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import TableReasonForExchanges from "../organisms/TableReasonForExchanges/TableReasonForExchanges";
import { ApplicationContext } from "../../context/ApplicationContext";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import ModalExport from "../atoms/ModalExport";
// bars menu
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import Reloadimg from "../../images/reload.svg";
import deleteImg from "../../images/delete.svg";
import preferencesImg from "../../images/preferences.svg";
import copyImg from "../../images/copy.svg";
import exportImg from "../../images/export.svg";
import { useReasonForExchange } from "../../hooks/ReasonForExchange/useReasonForExchange";
import ReasonForExchangeSearch from "../organisms/Search/ReasonForExchangeSearch";
import bulkimg from "../../images/bulk.svg";
import ReasonForExchangeTableTransfer from "../templates/TableTranfers/ReasonForExchangeTableTransfer";
import { useFilterContext } from "../../context";
import GuardNotAuthorized from "../atoms/ItemPermission/GuardNotAuthorized";
import ItemPermission from "../atoms/ItemPermission/ItemPermission";

interface IRefusalProps {
  Create?: boolean;
}

export default function Refusalreasons(props: IRefusalProps) {
  let history = useHistory();
  //Translations
  const { t } = useTranslation();
  const listLanguajeOptions = ["", `${t("LANGUAGE-LIST-ENGLISH")}`, `${t("LANGUAGE-LIST-FRENCH")}`];
  const listSort: string[] = [
    t("ALL.GENERAL-REASON-EXCHANGE.TITLE-CODE"),
    t("ALL.GENERAL-REASON-EXCHANGE.TITLE-LANGUAJE"),
    t("ALL.GENERAL-REASON-EXCHANGE.TITLE-ELIGIBLE"),
    t("ALL.GENERAL-REASON-EXCHANGE.TITLE-LABEL"),
    t("ALL.GENERAL-REASON-EXCHANGE.TITLE-DISPLAY-ORDER"),
  ];

  const [searchState, setSearchState] = useState<boolean>(false);

  //INPUT
  const {
    inputCodeSearchRR,
    inputLanguageSearch,
    inputDisplayOrderSearch,
    inputFacRreLabelSearch,
    inputEligibleSearch,
    showLateralContainerSearch,
    setShowLateralContainerSearch,
  } = useFilterContext();

  // Lateral container
  const [dropDownWhySort, setDropDownWhySort] = useState<string>(t("ALL.GENERAL-REASON-EXCHANGE.TITLE-CODE"));
  const [showSearchTable, setShowSearchTable] = useState(false);

  const [openTransferModal, setOpenTransferModal] = useState(false);

  const {
    reasonsForExchangeArray,
    loading,
    exportReasonsForExchanges,
    deleteManyReasonExchange,
    errorDeleteAlert,
    setErrorDeleteAlert,
    currentPage,
    lastPage,
    handleChange,
    limit,
    onSearch,
    typeSort,
    incrementCount,
  } = useReasonForExchange(
    inputCodeSearchRR,
    inputLanguageSearch,
    inputDisplayOrderSearch,
    inputEligibleSearch === "" ? "" : inputEligibleSearch === t("TABLE.DISTRIBUTORS-TITLE-YES") ? "true" : "false",
    inputFacRreLabelSearch
  );

  //menu fade
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function copy() {
    const url = document.createElement("input");
    url.value = window.location.href;
    navigator.clipboard.writeText(url.value);
    setAnchorEl(null);
  }

  const { reasonForExchangeAllSelected, reasonForExchangesListID, reasonExchangeLength } = useContext(ApplicationContext);

  // ! -------------------------------- API CALLS-----------------------------------//

  // ! ------------------------END API CALLS-----------------------------------//

  const exportFunction = () => {
    try {
      exportReasonsForExchanges(checkedType, reasonForExchangesListID, checkNeedToExport);
    } finally {
      setOpenConfirm(false);
    }
  };

  const deleteFunction = () => {
    try {
      deleteManyReasonExchange(reasonForExchangesListID);
    } finally {
      setOpenConfirm(false);
    }
  };

  const [searchWindowState, SetSearchWindowState] = useState("filterList");

  const renderLateralContainerSearch = () => {
    return (
      <ReasonForExchangeSearch
        listSort={listSort}
        dropDownWhySort={dropDownWhySort}
        setDropDownWhySort={setDropDownWhySort}
        searchWindowState={searchWindowState}
        SetSearchWindowState={SetSearchWindowState}
        listLanguajeOptions={listLanguajeOptions}
        handleSearch={() => onSearch()}
      />
    );
  };

  //Modal
  const [openExport, setOpenExport] = useState(false);
  // Checkbox
  const [checkNeedToExport, setCheckNeedToExport] = useState<boolean[]>([false, true, false]);
  const [checkFormatToExport, setCheckFormatToExport] = useState<boolean[]>([true, false, false, false]);
  const [checkedType, setCheckedType] = useState<string>("csv");

  const showModalExport = () => {
    return (
      <ModalExport
        openExport={openExport}
        setOpenExport={setOpenExport}
        checkNeedToExport={checkNeedToExport}
        setCheckNeedToExport={setCheckNeedToExport}
        checkFormatToExport={checkFormatToExport}
        setCheckFormatToExport={setCheckFormatToExport}
        checkedType={checkedType}
        setCheckedType={setCheckedType}
        onClick={() => {
          setOpenExport(false);
          exportFunction();
        }}
      />
    );
  };

  const RenderTabla1 = () => {
    return (
      <div>
        <TableReasonForExchanges
          reasonsForExchangeArray={reasonsForExchangeArray}
          searchState={searchState}
          rowsPerPage={limit}
          UsePlanText="true"
          linkTo="/reasons-for-exchange-info"
          Create={props.Create}
          showSearchTable={showSearchTable}
          loading={loading}
          currentPage={currentPage}
          lastPage={lastPage}
          handleChange={handleChange}
          inputToSort={dropDownWhySort}
          incrementCount={incrementCount}
          typeSort={typeSort}
          listLanguajeOptions={listLanguajeOptions}
          handleSearch={() => onSearch()}
          setDropDownWhySort={setDropDownWhySort}
        />
        <div className="row sectionBoxBottom">
          <div className="col-10 col-lg-11">
            <h4 className="h4-stl" style={{ margin: "15px 30px" }}>
              {t("TABLE.REASON-EXCHANGE.HEADER-TOTAL")} {`${reasonExchangeLength}`}
            </h4>
          </div>
        </div>
      </div>
    );
  };

  const [openConfirm, setOpenConfirm] = useState(false);
  const showModal = () => {
    return (
      <>
        <Modal
          show={openConfirm}
          onHide={() => {
            setOpenConfirm(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("INFO.USER.RESET-CONFIRM")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-lg-12 p-4">
                <h4 className="h4-stl">{t("ALL-INFO.DELETE-CONFIRM")}</h4>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                deleteFunction();
              }}
              variant="success"
            >
              {t("INFO.USER.RESET-BUTTON-CONFIRM")}
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setOpenConfirm(false);
              }}
            >
              {t("INFO.USER.RESET-BUTTON-CANCEL")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item href="#">{t("TABLE.REASON-EXCHANGE.HEADER-LINK")}</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header>
          <div className="flex-warranty flex-between">
            <div className="container-warranty flex-start">
              <FontAwesomeIcon icon={faHammer} className="icon_size" />
              <div>
                <h4 className="h4-stl" style={{ margin: "15px 5px" }}>
                  <strong>{t("TABLE.REASON-EXCHANGE.TITLE-HEADER")}</strong>
                </h4>
                <h5 className="h5-stl" style={{ margin: "15px 5px" }}>
                  Total {`${reasonExchangeLength}`}
                </h5>
              </div>
            </div>
            <div className="container-warranty flex-end wrap-warranty">
              <GuardNotAuthorized>
                <Link to="/create-reasons-for-exchange" style={{ textDecoration: "none" }}>
                  <Button variant="primary">{t("LIST.ALL.BUTTON-CREATE")}</Button>
                </Link>
              </GuardNotAuthorized>
              <Tooltip title={`${t("HOVER.ALL.BUTTON-SEARCH")} `} placement="bottom">
                <div
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {!props.Create ? (
                    <FontAwesomeIcon
                      className="grayBackgr iconbar_size"
                      icon={faSearch}
                      onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="grayBackgr iconbar_size"
                      icon={faSearch}
                      onClick={() => setShowSearchTable(!showSearchTable)}
                    />
                  )}
                </div>
              </Tooltip>

              <div onClick={handleClick}>
                <FontAwesomeIcon
                  className="grayBackgr"
                  icon={faBars}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />
              </div>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <MenuItem
                  onClick={() => {
                    history.go(0);
                  }}
                  style={{ fontSize: "15px" }}
                >
                  <img alt="" src={Reloadimg} style={{ width: "20px", marginRight: "10px" }}></img>
                  {t("ALL.MENU.RELOAD")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setOpenTransferModal(true);
                  }}
                  style={{ fontSize: "15px" }}
                >
                  <img alt="" src={preferencesImg} style={{ width: "20px", marginRight: "10px" }}></img>
                  {t("ALL-BUTTON-PREFERENCES")}
                </MenuItem>
                <ItemPermission>
                  {reasonForExchangesListID.length || reasonForExchangeAllSelected ? (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setOpenConfirm(true);
                      }}
                      style={{ fontSize: "15px" }}
                    >
                      <img alt="" src={deleteImg} style={{ width: "20px", marginRight: "10px" }}></img>
                      {t("ALL.MENU.DELETE")}
                      <span
                        style={{
                          background: "var( --color-accepted)",
                          marginLeft: "6px",
                          color: "var(--color-main-text)",
                          borderRadius: "20px",
                          padding: "4px 8px",
                          fontSize: "12px",
                        }}
                      >
                        <b>{reasonForExchangeAllSelected ? reasonExchangeLength : reasonForExchangesListID?.length}</b>
                      </span>
                    </MenuItem>
                  ) : (
                    <MenuItem disabled style={{ fontSize: "15px" }}>
                      <img alt="" src={deleteImg} style={{ width: "20px", marginRight: "10px" }}></img>
                      {t("ALL.MENU.DELETE")}
                    </MenuItem>
                  )}

                  {reasonForExchangesListID.length ? (
                    <MenuItem
                      onClick={() => {
                        history.push("/reasons-exchange-bulk");
                        handleClose();
                      }}
                      style={{ fontSize: "15px" }}
                    >
                      <img
                        alt=""
                        src={bulkimg}
                        style={{
                          width: "20px",
                          marginRight: "10px",
                        }}
                      ></img>
                      {t("TABLE.HEADER-OPTIONS-LIST-BULK")}
                      <span
                        style={{
                          background: "var( --color-accepted)",
                          marginLeft: "6px",
                          color: "var(--color-main-text)",
                          borderRadius: "20px",
                          padding: "4px 8px",

                          fontSize: "12px",
                        }}
                      >
                        <b>{reasonForExchangesListID?.length}</b>
                      </span>
                    </MenuItem>
                  ) : (
                    <MenuItem disabled style={{ fontSize: "15px" }}>
                      <img
                        alt=""
                        src={bulkimg}
                        style={{
                          width: "20px",
                          marginRight: "10px",
                        }}
                      ></img>
                      {t("TABLE.HEADER-OPTIONS-LIST-BULK")}
                    </MenuItem>
                  )}
                </ItemPermission>
                <GuardNotAuthorized>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setOpenExport(true);
                    }}
                    style={{ fontSize: "15px" }}
                  >
                    <img alt="" src={exportImg} style={{ width: "20px", marginRight: "10px" }}></img>
                    {t("NAV-ALL-ITEM3")}
                    <span
                      style={{
                        background: "var( --color-accepted)",
                        marginLeft: "6px",
                        color: "var(--color-main-text)",
                        borderRadius: "20px",
                        padding: "4px 8px",
                        fontSize: "12px",
                      }}
                    >
                      <b>{reasonForExchangeAllSelected ? reasonExchangeLength : reasonForExchangesListID?.length}</b>
                    </span>
                  </MenuItem>
                </GuardNotAuthorized>
                <MenuItem onClick={copy} style={{ fontSize: "15px" }}>
                  <img alt="" src={copyImg} style={{ width: "20px", marginRight: "10px" }}></img>
                  {t("ALL.MENU.COPY-LINK")}
                </MenuItem>
                {/* <MenuItem onClick={handleClose} style={{fontSize:'15px'}}>Logout</MenuItem> */}
              </Menu>
            </div>
          </div>
        </Card.Header>
        <Card.Body>{RenderTabla1()}</Card.Body>
        <ReasonForExchangeTableTransfer open={openTransferModal} setOpen={setOpenTransferModal} />
        {renderLateralContainerSearch()}
        {showModal()}
        {showModalExport()}
      </Card>
    </div>
  );
}
