import { useContext } from "react";
import { ApplicationContext } from "../../../context/ApplicationContext";

import { faThumbtack, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import Tooltip from "@mui/material/Tooltip";
import InputClear from "../../atoms/InputClear";
import DropDown from "../../atoms/DropDown";
import { useFilterContext } from "../../../context";
import useAutocomplete from "../../atoms/AutocopleteInput/useAutocomplete";
import AutoCompleteInput from "../../atoms/AutocopleteInput/AutocompleteInput";
import { createAddapterProduct } from "../../../hooks/Products/adapters/productAdapter";

export interface ITableFilters {
  //other
  handleSearch: any;
}

export default function ProductTableFilters(props: ITableFilters) {
  const {
    inputBrandCodeSearch,
    setInputBrandCodeSearch,
    inputBrandNameSearch,
    setInputBrandNameSearch,
    inputReferenceSearch,
    setInputReferenceSearch,
    inputNameSearchProduct,
    setInputNameSearchProduct,
    inputTypeSearch,
    setInputTypeSearch,
    inputUnitPriceSearch,
    setInputUnitPriceSearch,
    inputAvailableSearch,
    setInputAvailableSearch,
    inputWarrantySearch,
    setInputWarrantySearch,
    showLateralContainerSearch,
  } = useFilterContext();
  const { handleSearch } = props;
  const { typeOfSearch, setTypeOfSearch, productTableTransferData } = useContext(ApplicationContext);
  const listTypeOptionsWarranty = [`${t("TYPE-WARRANTY-1")}`, `${t("TYPE-WARRANTY-2")}`, `${t("TYPE-WARRANTY-3")}`];
  const listTypeOptionsAvailable = ["", t("TABLE.DISTRIBUTORS-TITLE-YES"), t("TABLE.DISTRIBUTORS-TITLE-NO")];
  const listTypeOptions = [
    "",
    `${t("TABLE.PRODUCTS-STATS.TITLE-OPENINGWRENCH")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-PAWLS")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-SOCKETS")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-SCREWDRIVERS")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-MALEWRENCHS")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-PLIERS")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-TRACING")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-HAMMERS")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-SAWING")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-EXTRACTION")}`,
    `${t("TABLE.PRODUCTS-STATS.TITLE-OTHER")}`,
  ];
  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleSearch();
  };

  const brandName = useAutocomplete(
    "/products/get-all?autocomplete=true&brand_name=",
    handleSearch,
    inputBrandNameSearch,
    setInputBrandNameSearch,
    "brandName",
    createAddapterProduct
  );

  const brandCode = useAutocomplete(
    "/products/get-all?autocomplete=true&brand_code=",
    handleSearch,
    inputBrandCodeSearch,
    setInputBrandCodeSearch,
    "brandCode",
    createAddapterProduct
  );
  const reference = useAutocomplete(
    "/products/get-all?autocomplete=true&reference_end_user=",
    handleSearch,
    inputReferenceSearch,
    setInputReferenceSearch,
    "reference"
  );

  const name = useAutocomplete(
    "/products/get-all?autocomplete=true&name=",
    handleSearch,
    inputNameSearchProduct,
    setInputNameSearchProduct,
    "name"
  );
  const unitPrice = useAutocomplete(
    "/products/get-all?autocomplete=true&unit_price=",
    handleSearch,
    inputUnitPriceSearch,
    setInputUnitPriceSearch,
    "unit_price"
  );
  return (
    <>
      {(typeOfSearch === 0 ? showLateralContainerSearch : false) === true && (
        <tr>
          <td>
            <Tooltip title={`${t("FILER-TO-DOCK")}`} placement="bottom">
              <div style={{ width: "60px" }}>
                <FontAwesomeIcon
                  className="grayBackground-icon"
                  size="2x"
                  style={{ cursor: "pointer" }}
                  icon={faThumbtack}
                  onClick={() => {
                    setTypeOfSearch(1);
                  }}
                />
              </div>
            </Tooltip>
          </td>
          {productTableTransferData.includes(`${t("SORT.PRODUCT.INPUT-CODE-BRAND")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <AutoCompleteInput {...brandCode} freeSolo />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputBrandCodeSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {productTableTransferData.includes(`${t("SORT.PRODUCT.INPUT-NAME-BRAND")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <AutoCompleteInput {...brandName} freeSolo />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputBrandNameSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {productTableTransferData.includes(`${t("SORT.PRODUCT.INPUT-REFERENCE")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <AutoCompleteInput {...reference} freeSolo />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputReferenceSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {productTableTransferData.includes(`${t("SORT.PRODUCT.INPUT-NAME")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <AutoCompleteInput {...name} freeSolo />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputNameSearchProduct("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {productTableTransferData.includes(`${t("SORT.PRODUCT.INPUT-TYPE")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <DropDown
                      options={listTypeOptions}
                      selectedItem={inputTypeSearch}
                      onSelect={(data: string) => {
                        setInputTypeSearch(data);
                        handleSearch();
                      }}
                      style={{
                        color: "var(--color-input-text)",
                        borderRadius: "2px",
                        textAlign: "left",
                        fontFamily: "Arial",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "14px",
                        lineHeight: "16px",
                        width: `100%`,
                        maxWidth: "100px",
                        overflow: "hidden",
                        height: "30px",
                        border: "1px solid #ced4da",
                        padding: "4px",
                      }}
                      widthMenu="230px"
                      isCompact
                    />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputTypeSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {productTableTransferData.includes(`${t("ALL.GENERAL.PRODUCT.TITLE-PICTURE")}`) && <td></td>}
          {productTableTransferData.includes(`${t("SORT.PRODUCT.INPUT-UNIT-PRICE")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <AutoCompleteInput {...unitPrice} freeSolo />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputUnitPriceSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {productTableTransferData.includes(`${t("SORT.PRODUCT.INPUT-AVAILABLE")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <DropDown
                      options={listTypeOptionsAvailable}
                      selectedItem={inputAvailableSearch}
                      onSelect={(data: string) => {
                        setInputAvailableSearch(data);
                        handleSearch();
                      }}
                      style={{
                        color: "var(--color-input-text)",
                        borderRadius: "2px",
                        textAlign: "left",
                        fontFamily: "Arial",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "14px",
                        lineHeight: "16px",
                        width: `100%`,
                        maxWidth: "100px",
                        overflow: "hidden",
                        height: "30px",
                        border: "1px solid #ced4da",
                        padding: "4px",
                      }}
                      isCompact
                    />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputAvailableSearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
          {productTableTransferData.includes(`${t("SORT.PRODUCT.INPUT-WARRANTY")}`) && (
            <td>
              <form action="/" onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div className="full-width">
                    <DropDown
                      options={listTypeOptionsWarranty}
                      selectedItem={inputWarrantySearch}
                      onSelect={(data: string) => {
                        setInputWarrantySearch(data);
                        handleSearch();
                      }}
                      style={{
                        color: "var(--color-input-text)",
                        borderRadius: "2px",
                        textAlign: "left",
                        fontFamily: "Arial",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "14px",
                        lineHeight: "16px",
                        width: `100%`,
                        maxWidth: "100px",
                        overflow: "hidden",
                        height: "30px",
                        border: "1px solid #ced4da",
                        padding: "4px",
                      }}
                      isCompact
                      widthMenu="140px"
                    />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="grayBackground-icon"
                      size="2x"
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      onClick={() => {
                        setInputWarrantySearch("");
                        handleSearch();
                      }}
                    />
                  </div>
                </div>
              </form>
            </td>
          )}
        </tr>
      )}
    </>
  );
}
