import "bootstrap/dist/css/bootstrap.css";
import "../../../constants/global.css";
import { faExternalLinkAlt, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropDownCities from "../../atoms/DropDownCities";
import { useTranslation } from "react-i18next";
import InputClearGuard from "../../atoms/InputClearGuard";
import { typeUserData } from "../../../constants/userTypes";

export default function CustomersAddressTable(props: any) {
  const { t } = useTranslation();

  return (
    <div className="my-4 mx-2">
      <div className="row my-2 px-lg-5 px-2">
        <div className="col-12 col-lg-12 sectionBoxTop py-2">
          <h4 className="h4-stl">{t("ALL.GENERAL.CUSTOMER-HEADER-TITLE-ADDRESS")}</h4>
        </div>
        <div className="col-12 col-lg-6 my-4">
          <h4 className="h4-stl">
            <strong>{t("ALL.GENERAL.CUSTOMER-HEADER-TITLE-ADDRESS")}</strong>
          </h4>
          <div style={{}}>
            <InputClearGuard
              type="text"
              name=""
              placeholder="Enter a location"
              style={{ width: "100%" }}
              currentValue={props.address}
              handleOnInputValue={props.setAddress}
              roles={[`${typeUserData.admin}`]}
            />
          </div>
        </div>
        <div className="col-12 col-lg-6 my-4">
          <h4 className="h4-stl">
            <strong>{t("CREATE.CUSTOMER.NAV-INPUT-ADDRESS-COMPLEMENT")}</strong>
          </h4>
          <div style={{}}>
            <InputClearGuard
              type="text"
              name=""
              placeholder=""
              style={{ width: "100%" }}
              currentValue={props.addressComplement}
              handleOnInputValue={props.setAddressComplement}
              roles={[`${typeUserData.admin}`]}
            />
          </div>
        </div>
      </div>
      <div className="row my-2 px-lg-5 px-2">
        <div className="col-12 col-lg-4 my-4">
          <h4 className="h4-stl">
            <strong>{t("ALL.GENERAL.CUSTOMER.TITLE-ZIP-CODE")}</strong>
          </h4>
          <div style={{}}>
            <InputClearGuard
              type="text"
              name=""
              placeholder=""
              style={{ width: "100%" }}
              currentValue={props.zipCode}
              handleOnInputValue={props.setZipCode}
              roles={[`${typeUserData.admin}`]}
            />
          </div>
        </div>
        <div className="col-12 col-lg-4 my-4">
          <h4 className="h4-stl">
            <strong>{t("ALL.GENERAL.CUSTOMER.TITLE-CITY")}</strong>
          </h4>
          <div style={{}}>
            <InputClearGuard
              type="text"
              name=""
              placeholder=""
              style={{ width: "100%" }}
              currentValue={props.city}
              handleOnInputValue={props.setCity}
              roles={[`${typeUserData.admin}`]}
            />
          </div>
        </div>
        <div className="col-12 col-lg-4 my-4">
          <h4 className="h4-stl">
            <strong>{t("ALL.GENERAL.CUSTOMER.TITLE-COUNTRY")}</strong>
          </h4>
          <div style={{}}>
            <DropDownCities
              options={props.listCountry}
              selectedItem={props.selectedCountry}
              onSelect={props.setSelectedCountry}
              width="100%"
              roles={[`${typeUserData.admin}`]}
            />
          </div>
        </div>
      </div>
      <div className="flex-inputs-responsive my-2 px-lg-5 px-2">
        <div className="full-width my-4">
          <h4 className="h4-stl">
            <strong>{t("CREATE.CUSTOMER.NAV-INPUT-COORDINATES")}</strong>
          </h4>
          <div className="flex-inputs-responsive-info">
            <div className="full-width">
              <InputClearGuard
                type="text"
                name="coordinates"
                placeholder=""
                style={{ width: "100%" }}
                currentValue={props.coordinates}
                handleOnInputValue={props.setCoordinates}
                roles={[`${typeUserData.admin}`]}
              />
            </div>
            <div>
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                style={{
                  marginLeft: "8px",
                  width: "2Opx",
                  height: "20px",
                  float: "right",
                }}
              />
            </div>
          </div>
        </div>
        <div className="full-width my-4">
          <h4 className="h4-stl">
            <strong>{t("CREATE.CUSTOMER.NAV-INPUT-MAP-URL")}</strong>
          </h4>
          <div className="flex-inputs-responsive-info">
            <div className="full-width">
              <InputClearGuard
                type="text"
                name="mapUrl"
                placeholder=""
                style={{ width: "100%" }}
                currentValue={props.mapUrl}
                handleOnInputValue={props.setMapUrl}
                roles={[`${typeUserData.admin}`]}
              />
            </div>
            <div>
              <a href={`https://${props.mapUrl}`} target="_blank" style={{ textDecoration: "none", color: "#222" }}>
                <FontAwesomeIcon className="grayBackgr" icon={faExternalLinkAlt} style={{ width: "45px", height: "50px" }} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
