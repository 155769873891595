import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import FileSaver from "file-saver";
import { useEffect, useState } from "react";
interface IDownload {
  image: any;
  name: string;
  style?: any;
}
export default function DownloadImageUrl(props: IDownload) {
  const { image } = props;
  const [blobFile, setBlobFile] = useState("");
  function downloadImage() {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", `${image}.png`);
    xhr.responseType = "arraybuffer";

    xhr.onload = function (error) {
      var blob = new Blob([xhr.response]);
      var blobUrl = URL.createObjectURL(blob);
      setBlobFile(blobUrl);
    };
    xhr.send();
  }
  useEffect(() => {
    downloadImage();
  }, []);

  return (
    <>
      {blobFile && (
        <FontAwesomeIcon
          icon={faDownload}
          style={{
            margin: "0 10px",
            paddingTop: "5px",
            width: "2Opx",
            height: "20px",
            color: "black",
            cursor: "pointer",
          }}
          onClick={() => FileSaver.saveAs(blobFile, "photo.jpg")}
        />
      )}
    </>
  );
}
