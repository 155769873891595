import "bootstrap/dist/css/bootstrap.css";
import "../../../constants/global.css";
import Switch from "react-switch";
import InputClearGuard from "../../atoms/InputClearGuard";
import { typeUserData } from "../../../constants/userTypes";
import { useSelector } from "react-redux";
import { IRootState } from "../../../store/redux";
import DropDownCities from "../../atoms/DropDownCities";
import { useTranslation } from "react-i18next";

export default function CustomersOthersTable(props: any) {
  const { t } = useTranslation();
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected } = profile;

  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  let TodayDate = `${day}/${month}/${year}`;
  if (day <= 9) {
    TodayDate = `0${day}/${month}/${year}`;
  }
  if (month <= 9) {
    TodayDate = `${day}/0${month}/${year}`;
  }
  if (day <= 9 && month <= 9) {
    TodayDate = `0${day}/0${month}/${year}`;
  }

  return (
    <div className="my-4 mx-2">
      <div className="row my-4 px-lg-5 px-2">
        <div className="col-12 col-lg-3 my-4">
          <h4 className="h4-stl">
            <strong>{t("CREATE.CUSTOMER.NAV-INPUT-GENERAL-TERMS-ACCEPTED")}</strong>
          </h4>
          <Switch
            checked={props.checkedAvailableGeneralTermsAccepted}
            onChange={props.handleChangeAvailableGeneralTermsAccepted}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
            id="material-switch"
            disabled={nameRolSelected !== typeUserData.admin}
          />
        </div>
        <div className="col-12 col-lg-3 my-4">
          <h4 className="h4-stl">
            <strong>{t("CREATE.CUSTOMER.NAV-INPUT-PRIVACY-POLICY-ACCEPTED")}</strong>
          </h4>
          <Switch
            checked={props.checkedAvailablePrivacyPolicyAccepted}
            onChange={props.handleChangeAvailablePrivacyPolicyAccepted}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
            id="material-switch"
            disabled={nameRolSelected !== typeUserData.admin}
          />
        </div>
        <div className="col-12 col-lg-3 my-4">
          <h4 className="h4-stl">
            <strong>{t("CREATE.CUSTOMER.NAV-INPUT-REGISTRATION-DATE")}</strong>
          </h4>
          <div style={{}}>
            <InputClearGuard
              type="text"
              name=""
              readOnly
              placeholder="10/26/2021"
              style={{
                width: "100%",
                background: "var(--color-input-disable)",
                height: "40px",
              }}
              currentValue={props.registrationDate.slice(0, 10)}
              handleOnInputValue={props.setRegistratiosDate}
              roles={[`${typeUserData.admin}`]}
            />
          </div>
        </div>
        <div className="col-12 col-lg-3 my-4">
          <h4 className="h4-stl">
            <strong>{t("TABLE.USER.TITLE-LANGUAJE")}</strong>
          </h4>
          <div style={{}}>
            <DropDownCities
              options={props.languajeOptionsList}
              selectedItem={props.currentValueLanguaje}
              onSelect={props.onSelectDropdown}
              width="100%"
              roles={[`${typeUserData.admin}`]}
            />
          </div>
        </div>
      </div>
      <div className="row mb-4 px-lg-5 px-2">
        <div className="col-12 col-lg-12 my-4">
          <h4 className="h4-stl">
            <strong>{t("CREATE.CUSTOMER.NAV-INPUT-COMMENTS")}</strong>
          </h4>
          <div style={{}}>
            <InputClearGuard
              type="text"
              name=""
              placeholder=""
              style={{ width: "100%" }}
              currentValue={props.comments}
              handleOnInputValue={props.setComments}
              roles={[`${typeUserData.admin}`]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
