import { ICustomerListDOM, ICustomerListPage } from "../../../api/interfaces/interfaz_customer";
export const createAddapterCustomer = (item: ICustomerListPage[]): ICustomerListDOM[] => {
  const array: ICustomerListDOM[] = [];
  item?.map((item: ICustomerListPage) => {
    return array.push({
      account_type: item.account_type,
      birth_date: item.birth_date,
      business: item.business,
      changelogs: item.changelogs,
      comments: item.comments,
      exchanges_data: item.exchanges_data,
      id: item.id,
      privacy_policy_accepted: item.privacy_policy_accepted,
      company_name: item.company_name,
      terms_accepted: item.terms_accepted,
      type: item.type,
      user: item.user,
      user_data: item.user_data,
      userUsername: item.user_data.username,
      userFirstName: item.user_data.first_name,
      userLastName: item.user_data.last_name,
      userZipCode: item.user_data.address.zipcode,
      userCity: item.user_data.address.city,
      userCountry: item.user_data.address.country,
      userEmail: item.user_data.email,
      userMobile: item.user_data.company_phone_number,
      userLanguaje: item.user_data.users_settings.lenguageActive,
    });
  });
  return array;
};
