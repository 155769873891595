import { IGroupListDOM, IGroupListPage } from "../../../api/interfaces/interfaz_group";

export const createAddapterGroup = (item: IGroupListPage[]): IGroupListDOM[] => {
  const array: IGroupListDOM[] = [];
  item?.map((item: IGroupListPage) => {
    return array.push({
      id: item.id,
      name: item.name,
      code: item.code,
      description: item.description,
      distributors_data: item.distributors_data,
      users_data: item.users_data,
      logo: item.logo,
    });
  });
  return array;
};
