import React, { useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faEnvelope, faKey, faUsers, faSearch } from "@fortawesome/free-solid-svg-icons";

import Stadistics from "../../../images/stadistics.svg";
import grid from "../../../images/GridIcon.png";
import "../../../constants/global.css";
import { Breadcrumb, Card, Modal } from "react-bootstrap";

import CustomersTable from "../../organisms/CustomersTable/CustomersTable";
import InputClear from "../../atoms/InputClear";
import "./Customers.css";
import { Link, useHistory } from "react-router-dom";
import preferencesImg from "../../../images/preferences.svg";

import { ApplicationContext } from "../../../context/ApplicationContext";
// bars menu
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import Reloadimg from "../../../images/reload.svg";
import deleteImg from "../../../images/delete.svg";
import copyImg from "../../../images/copy.svg";
import ModalExport from "../../atoms/ModalExport";
import exportImg from "../../../images/export.svg";

//API
import { generateNewPassword } from "../../../api/services/user_services";
import { useTranslation } from "react-i18next";

//Redux
import { useSelector } from "react-redux";
import { IRootState } from "../../../store/redux";
import CustomerSearch from "../../organisms/Search/CustomerSearch";
import { useCustomers } from "../../../hooks/Customers/useCustomers";
import { typeUserData } from "../../../constants/userTypes";
import bulkimg from "../../../images/bulk.svg";
import { fromCountryTranslatedToKey } from "../../../utilities/countryTraslations";
import { fromBusinessTranslatedToKey } from "../../../utilities/usetBussinessTransalte";
import CustomerTableTransfer from "../../templates/TableTranfers/CustomerTableTransfer";
import { useMediaQuery } from "@mui/material";
import { useFilterContext } from "../../../context";
import GuardNotAuthorized from "../../atoms/ItemPermission/GuardNotAuthorized";

interface ICustomersProps {
  Create?: boolean;
  distributorId?: string;
  fromInfo?: boolean;
}

export default function Customers(props: ICustomersProps) {
  const { t, i18n } = useTranslation();
  const matches = useMediaQuery("(max-width:500px)");
  const { customersLength, customerListID, alertDeleteCustomer, customerAllSelected } = useContext(ApplicationContext);
  const listTypeOptions = ["", `${t("TABLE-CUSTOMER-STATS-TITLE-ARTISAN")}`, `${t("TABLE-CUSTOMER-STATS-TITLE-INDIVIDUAL")}`];
  const listBusinessOptions = [
    "",
    `${t("OPTIONS-PROFESSION-1")}`,
    `${t("OPTIONS-PROFESSION-2")}`,
    `${t("OPTIONS-PROFESSION-3")}`,
    `${t("OPTIONS-PROFESSION-4")}`,
    `${t("OPTIONS-PROFESSION-5")}`,
    `${t("OPTIONS-PROFESSION-6")}`,
    `${t("OPTIONS-PROFESSION-7")}`,
    `${t("OPTIONS-PROFESSION-8")}`,
    `${t("OPTIONS-PROFESSION-9")}`,
    `${t("OPTIONS-PROFESSION-10")}`,
    `${t("OPTIONS-PROFESSION-11")}`,
    `${t("OPTIONS-PROFESSION-12")}`,
    `${t("OPTIONS-PROFESSION-13")}`,
    `${t("OPTIONS-PROFESSION-14")}`,
    `${t("OPTIONS-PROFESSION-15")}`,
    `${t("OPTIONS-PROFESSION-16")}`,
    `${t("OPTIONS-PROFESSION-17")}`,
    `${t("OPTIONS-PROFESSION-18")}`,
    `${t("OPTIONS-PROFESSION-19")}`,
    `${t("OPTIONS-PROFESSION-20")}`,
    `${t("OPTIONS-PROFESSION-21")}`,
    `${t("OPTIONS-PROFESSION-22")}`,
    `${t("OPTIONS-PROFESSION-23")}`,
  ];

  let history = useHistory();
  //Redux
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected } = profile;
  //Modal
  const [openSearch, setOpenSearch] = React.useState(false);
  const [openSearchPassword, setOpenSearchPassword] = React.useState(false);

  const [searchState, setSearchState] = useState<boolean>(false);
  const [showSearchTable, setShowSearchTable] = useState(false);

  const [email, setEmail] = useState<string>("");
  const listSort: string[] = [
    t("SORT.CUSTOMER.INPUT-USERNAME"),
    t("SORT.CUSTOMER.INPUT-FIRST-NAME"),
    t("SORT.CUSTOMER.INPUT-LAST-NAME"),
    t("SORT.CUSTOMER.INPUT-TYPE"),
    t("SORT.CUSTOMER.INPUT-BUSINESS"),
    t("SORT.CUSTOMER.INPUT-COMPANY-NAME"),
    t("SORT.CUSTOMER.INPUT-ZIPCODE"),
    t("SORT.CUSTOMER.INPUT-CITY"),
    t("SORT.CUSTOMER.INPUT-COUNTRY"),
    t("SORT.CUSTOMER.INPUT-EMAIL"),
    t("SORT.CUSTOMER.INPUT-MOBILE-PHONE"),
    t("TABLE.USER.TITLE-LANGUAJE"),
  ];

  //Filter Search
  const {
    inputUsernameSearch,
    inputFirstnameSearch,
    inputLastnameSearch,
    inputTypeSearchCustomer,
    inputBusinessSearch,
    inputZipCodeSearch,
    inputCitySearch,
    inputCountrySearch,
    inputMapUrlSearch,
    inputGeneralTermsAcceptedSearch,
    inputPrivacyPolicyAcceptedSearch,
    inputRegistrationDateSearch,
    inputCompanyNameSearch,
    inputEmailSearchCustomer,
    inputPhoneSearch,
    inputLanguajeSearch,
    showLateralContainerSearch,
    setShowLateralContainerSearch,
  } = useFilterContext();

  const [dropDownWhySort, setDropDownWhySort] = useState<string>(listSort[0]);
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [searchWindowState, SetSearchWindowState] = useState("filterList");

  const [modalTime, setModalTime] = useState<boolean>(false);

  //menu fade
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function copy() {
    const url = document.createElement("input");
    url.value = window.location.href;
    navigator.clipboard.writeText(url.value);
    setAnchorEl(null);
  }

  const {
    customerArray,
    loading,
    exportCustomer,
    deleteManyCustomer,
    errorDeleteAlert,
    setErrorDeleteAlert,
    currentPage,
    lastPage,
    handleChange,
    limit,
    onSearch,
    typeSort,
    incrementCount,
  } = useCustomers(
    inputUsernameSearch,
    inputFirstnameSearch,
    inputLastnameSearch,
    inputZipCodeSearch,
    inputCitySearch,
    fromCountryTranslatedToKey(inputCountrySearch),
    inputTypeSearchCustomer
      ? inputTypeSearchCustomer === `${t("TABLE-CUSTOMER-STATS-TITLE-INDIVIDUAL")}`
        ? "Individual"
        : "Professional"
      : "",
    fromBusinessTranslatedToKey(inputBusinessSearch),
    inputGeneralTermsAcceptedSearch ? (inputGeneralTermsAcceptedSearch === t("TABLE.DISTRIBUTORS-TITLE-YES") ? "true" : "false") : "",
    inputPrivacyPolicyAcceptedSearch ? (inputPrivacyPolicyAcceptedSearch === t("TABLE.DISTRIBUTORS-TITLE-YES") ? "true" : "false") : "",
    inputCompanyNameSearch,
    inputEmailSearchCustomer,
    inputPhoneSearch,
    inputLanguajeSearch,
    inputRegistrationDateSearch,
    "",
    props.distributorId ? props.distributorId : ""
  );
  //? ----------------------------------- ALERTS -----------------------------------

  const exportFunction = () => {
    try {
      exportCustomer(checkedType, customerListID, checkNeedToExport);
    } finally {
      setOpenConfirm(false);
    }
  };

  const deleteFunction = () => {
    try {
      deleteManyCustomer(customerListID);
    } finally {
      setOpenConfirm(false);
    }
  };

  //? ----------------------------------- END ALERTS ----------------------------------- //

  const [newPassword, setNewPassword] = useState<string>("");

  const generatePassword = async () => {
    try {
      const response = generateNewPassword(email);
      const data = (await response).data;
      setNewPassword(data.result?.generated_password);
      setModalTime(false);
      return data;
    } catch (error: any) {
      console.log("error generated new password");
      console.log(error);
      setModalTime(true);
    } finally {
      console.log("end");
      setOpenSearch(false);
      setOpenSearchPassword(true);
    }
  };

  const renderLateralContainerSearch = () => {
    return (
      <CustomerSearch
        handleSearch={() => onSearch()}
        showLateralContainerSearch={showLateralContainerSearch}
        setShowLateralContainerSearch={setShowLateralContainerSearch}
        searchWindowState={searchWindowState}
        SetSearchWindowState={SetSearchWindowState}
        listSort={listSort}
        dropDownWhySort={dropDownWhySort}
        setDropDownWhySort={setDropDownWhySort}
        listTypeOptions={listTypeOptions}
        listBusinessOptions={listBusinessOptions}
      />
    );
  };

  const RenderTabla1 = () => {
    return (
      <div>
        <CustomersTable
          searchState={searchState}
          rowsPerPage={limit}
          UsePlanText="true"
          inputToSort={dropDownWhySort}
          Create={props.Create}
          showSearchTable={showSearchTable}
          customerArray={customerArray}
          loading={loading}
          currentPage={currentPage}
          lastPage={lastPage}
          handleChange={handleChange}
          incrementCount={incrementCount}
          typeSort={typeSort}
          listTypeOptions={listTypeOptions}
          listBusinessOptions={listBusinessOptions}
          handleSearch={() => onSearch()}
          setDropDownWhySort={setDropDownWhySort}
        />
        <div className="row sectionBoxBottom">
          <div className="col-10 col-lg-11">
            <h4 className="h4-stl" style={{ margin: "15px 30px" }}></h4>
          </div>
        </div>
      </div>
    );
  };

  const showModal = () => {
    return (
      <>
        <Modal
          show={openSearch}
          onHide={() => {
            setOpenSearch(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("MODAL.CUSTOMER-TITLE")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-lg-12 p-4">
                <h4 className="h4-stl">
                  <strong>{t("MODAL.CUSTOMER-INPUT-EMAIL-ADDRESS")}</strong>
                </h4>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "90% 10%",
                    alignItems: "center",
                  }}
                >
                  <InputClear
                    type="text"
                    name=""
                    placeholder=""
                    style={{ width: "100%" }}
                    currentValue={email}
                    handleOnInputValue={(data: string) => {
                      setEmail(data);
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{
                      marginLeft: "8px",
                      width: "2Opx",
                      height: "20px",
                      float: "right",
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => generatePassword()} variant="success">
              {t("MODAL.CUSTOMER-BUTTON-CONFIRM")}
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setOpenSearch(false);
              }}
            >
              {t("MODAL.CUSTOMER-BUTTON-CANCEL")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const ShowModalPassword = () => {
    return (
      <>
        <Modal
          show={openSearchPassword}
          onHide={() => {
            setOpenSearchPassword(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("MODAL.CUSTOMER-TITLE")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-lg-12 p-4">
                <h4 className="h4-stl alet-warning alert">
                  <strong>your new password is: {newPassword}</strong>
                </h4>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setOpenSearchPassword(false)} variant="success">
              {t("MODAL.CUSTOMER-BUTTON-CONFIRM")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const [openConfirm, setOpenConfirm] = useState(false);
  const ShowModalDel = () => {
    return (
      <>
        <Modal
          show={openConfirm}
          onHide={() => {
            setOpenConfirm(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("INFO.USER.RESET-CONFIRM")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-lg-12 p-4">
                <h4 className="h4-stl">{t("ALL-INFO.DELETE-CONFIRM")}</h4>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                deleteFunction();
              }}
              variant="success"
            >
              {t("INFO.USER.RESET-BUTTON-CONFIRM")}
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setOpenConfirm(false);
              }}
            >
              {t("INFO.USER.RESET-BUTTON-CANCEL")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  //Modal
  const [openExport, setOpenExport] = useState(false);
  // Checkbox
  const [checkNeedToExport, setCheckNeedToExport] = useState<boolean[]>([false, true, false]);
  const [checkFormatToExport, setCheckFormatToExport] = useState<boolean[]>([true, false, false, false]);
  const [checkedType, setCheckedType] = useState<string>("csv");

  const showModalExport = () => {
    return (
      <ModalExport
        openExport={openExport}
        setOpenExport={setOpenExport}
        checkNeedToExport={checkNeedToExport}
        setCheckNeedToExport={setCheckNeedToExport}
        checkFormatToExport={checkFormatToExport}
        setCheckFormatToExport={setCheckFormatToExport}
        checkedType={checkedType}
        setCheckedType={setCheckedType}
        onClick={() => {
          setOpenExport(false);
          exportFunction();
        }}
      />
    );
  };

  return (
    <div>
      {!props.fromInfo && (
        <Breadcrumb>
          <Breadcrumb.Item href="#">{t("TABLE.CUSTOMER.TITLE.HEADER-LINK")}</Breadcrumb.Item>
        </Breadcrumb>
      )}
      <Card>
        <Card.Header>
          <div className="flex-warranty flex-between">
            <div className="container-warranty flex-start">
              <FontAwesomeIcon icon={faUsers} className="icon_size" />

              <div>
                <h4 className="h4-stl" style={{ margin: "15px 5px" }}>
                  <strong>{t("TABLE.CUSTOMER.TITLE-HEADER")}</strong>
                </h4>
                <h5 className="h5-stl" style={{ margin: "15px 5px" }}>
                  {t("TABLE.CUSTOMER.HEADER-TOTAL")} {customersLength}
                </h5>
              </div>
            </div>
            <div className="container-warranty flex-end wrap-warranty">
              <div
                style={{
                  cursor: "pointer",
                }}
              >
                {!props.Create ? (
                  <FontAwesomeIcon
                    className="grayBackgr iconbar_size"
                    icon={faSearch}
                    onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="grayBackgr iconbar_size"
                    icon={faSearch}
                    onClick={() => setShowLateralContainerSearch(!showLateralContainerSearch)}
                  />
                )}
              </div>
              {/* {nameRolSelected === typeUserData.admin && (
                <div>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setOpenSearch(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faKey} style={{ width: "15px", height: "15px" }} />{" "}
                    {t("TABLE.CUSTOMER.HEADER-BUTTON-GENERATE-A-NEW-PASSWORD")}
                  </Button>
                </div>
              )} */}
              {!matches && (
                <GuardNotAuthorized>
                  <Link
                    to="/customer-stats"
                    style={{
                      display: "flex",
                      textDecoration: "none",
                      color: "var(--color-main-text)",
                    }}
                  >
                    <div className="grayBackgr" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                      <div>
                        <img alt="" src={Stadistics} style={{ width: "20px", height: "20px" }}></img>
                      </div>
                      <div style={{ paddingTop: "10px" }}>
                        <h4 className="h4-stl">{t("CUSTOMER-STATS-BUTTON")}</h4>
                      </div>
                    </div>
                  </Link>
                </GuardNotAuthorized>
              )}

              <div onClick={handleClick}>
                <FontAwesomeIcon
                  className="grayBackgr"
                  icon={faBars}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />
              </div>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <MenuItem
                  onClick={() => {
                    history.go(0);
                  }}
                  style={{ fontSize: "15px" }}
                >
                  <img alt="" src={Reloadimg} style={{ width: "20px", marginRight: "10px" }}></img>
                  {t("ALL.MENU.RELOAD")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setOpenTransferModal(true);
                  }}
                  style={{ fontSize: "15px" }}
                >
                  <img alt="" src={preferencesImg} style={{ width: "20px", marginRight: "10px" }}></img>
                  {t("ALL-BUTTON-PREFERENCES")}
                </MenuItem>
                {nameRolSelected === typeUserData.admin && (
                  <div>
                    {customerListID.length || customerAllSelected ? (
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          setOpenConfirm(true);
                        }}
                        style={{ fontSize: "15px" }}
                      >
                        <img alt="" src={deleteImg} style={{ width: "20px", marginRight: "10px" }}></img>
                        {t("ALL.MENU.DELETE")}
                        <span
                          style={{
                            background: "var( --color-accepted)",
                            marginLeft: "6px",
                            color: "var(--color-main-text)",
                            borderRadius: "20px",
                            padding: "4px 8px",
                            fontSize: "12px",
                          }}
                        >
                          <b>{customerAllSelected ? customersLength : customerListID?.length}</b>
                        </span>
                      </MenuItem>
                    ) : (
                      <MenuItem disabled style={{ fontSize: "15px" }}>
                        <img alt="" src={deleteImg} style={{ width: "20px", marginRight: "10px" }}></img>
                        {t("ALL.MENU.DELETE")}
                      </MenuItem>
                    )}
                  </div>
                )}
                {nameRolSelected === typeUserData.admin && (
                  <div>
                    {customerListID.length ? (
                      <MenuItem
                        onClick={() => {
                          history.push("/customer-bulk");
                          handleClose();
                        }}
                        style={{ fontSize: "15px" }}
                      >
                        <img
                          alt=""
                          src={bulkimg}
                          style={{
                            width: "20px",
                            marginRight: "10px",
                          }}
                        ></img>
                        {t("TABLE.HEADER-OPTIONS-LIST-BULK")}
                        <span
                          style={{
                            background: "var( --color-accepted)",
                            marginLeft: "6px",
                            color: "var(--color-main-text)",
                            borderRadius: "20px",
                            padding: "4px 8px",

                            fontSize: "12px",
                          }}
                        >
                          <b>{customerListID?.length}</b>
                        </span>
                      </MenuItem>
                    ) : (
                      <MenuItem disabled style={{ fontSize: "15px" }}>
                        <img
                          alt=""
                          src={bulkimg}
                          style={{
                            width: "20px",
                            marginRight: "10px",
                          }}
                        ></img>
                        {t("TABLE.HEADER-OPTIONS-LIST-BULK")}
                      </MenuItem>
                    )}
                  </div>
                )}
                <GuardNotAuthorized>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setOpenExport(true);
                    }}
                    style={{ fontSize: "15px" }}
                  >
                    <img alt="" src={exportImg} style={{ width: "20px", marginRight: "10px" }} />
                    {t("NAV-ALL-ITEM3")}
                    <span
                      style={{
                        background: "var( --color-accepted)",
                        marginLeft: "6px",
                        color: "var(--color-main-text)",
                        borderRadius: "20px",
                        padding: "4px 8px",
                        fontSize: "12px",
                      }}
                    >
                      <b>{customerAllSelected ? customersLength : customerListID?.length}</b>
                    </span>
                  </MenuItem>
                </GuardNotAuthorized>
                <MenuItem onClick={copy} style={{ fontSize: "15px" }}>
                  <img alt="" src={copyImg} style={{ width: "20px", marginRight: "10px" }}></img>
                  {t("ALL.MENU.COPY-LINK")}
                </MenuItem>
                {/* <MenuItem onClick={handleClose} style={{fontSize:'15px'}}>Logout</MenuItem> */}
              </Menu>
            </div>
          </div>
        </Card.Header>
        <Card.Body>{RenderTabla1()}</Card.Body>
      </Card>
      <CustomerTableTransfer open={openTransferModal} setOpen={setOpenTransferModal} />
      {showModal()}
      {renderLateralContainerSearch()}
      {ShowModalDel()}
      {showModalExport()}
    </div>
  );
}
