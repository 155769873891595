import Star from "../../../images/Star.png";
export default function ImageStar() {
  return (
<img
                          alt=""
                          src={Star}
                          style={{
                            paddingRight: "5px",
                            width: "25px",
                          }}
                        ></img>
  )
}